import React, { Component } from 'react'
import "../../styles/confirm_update.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import confirm from "../../imgs/checked.png";
import arrow from "../../imgs/Group 224.png";
import { Link } from 'react-router-dom';
export class ConfirmAddOfferForServicesScreen extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       back : ''
    }
  }


  componentDidMount(){
    
  }
  
    render() {
        return (
          <div>
            <ControlPanelHeader
            exit1={true}
            notifi={true}
            msg={true}
            files={false}
            stat={false}
            msgDest={"/Messages"}
            notifidest={"/Notifications"}
            exitDest={"/services"}
            fileDest={"/FacilityFiles"}
            statDest = {'/collectServices'}
            style={"threetwo_section"}
          />
            <div className="confirm-content text-center">
              <div
                className="confirm-update"
                style={{ height: "361px", margin: "94px auto 50px" }}
              >
                <img
                  className="confirm"
                  src={confirm}
                  alt=""
                  style={{ marginTop: "36px" }}
                />
                <p className="confirm-title">تم تنفيذ الطلب</p>
                <p
                  className="hint"
                  style={{ width: "508px", margin: "20px auto 0" }}
                >
                  سيتم  إنزال عرضكم بعت أن تتم مراجعته من مراجعت من قبل إدارة المنصة
                </p>
              </div>
    
              <Link to='services'>
              <button className="back-btn">
                <img src={arrow} alt="" />
                الرجوع
              </button>
              </Link>
            </div>
          </div>
        );
      }
}

export default ConfirmAddOfferForServicesScreen
