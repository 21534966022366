import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { AddMedicalTeam } from '../../actions/serviceActions';
import ControlPanelHeader from "../../component/ControlPanelHeader";
import arrow from "../../imgs/arrow.png";
import complete from "../../imgs/check1.png";
import date from "../../imgs/date.png";
import rarrow from "../../imgs/right-arrow.png";
import upload from "../../imgs/upload (1).png";
import "../../styles/add_evaluationdata.css";


function AddMedicalTeamScreen({ history }) {

  const [name, setName] = useState('')

  const [id, setID] = useState('')

  const [en_Name, setEn_Name] = useState('')

  const [licence_No, setLicence_No] = useState('')

  const [photo1, setPhoto1] = useState(false)

  const [path1, setPath1] = useState('')

  const [wrong1, setWrong1] = useState('')

  const [photo2, setPhoto2] = useState(false)

  const [path2, setPath2] = useState('')

  const [wrong2, setWrong2] = useState('')

  const [photo3, setPhoto3] = useState(false)

  const [path3, setPath3] = useState('')

  const [wrong3, setWrong3] = useState('')

  const [start_Date, setStart_Date] = useState("")

  const [end_Date, setEnd_Date] = useState('')

  const [specialization, setSpecialization] = useState('')

  const [birth_Date, setBirth_Date] = useState("")

  const [id_endDate, setId_endDate] = useState('')

  const [phone_No, setPhone_No] = useState('')

  const [gender, setGender] = useState('')

  const [nationality, setNationality] = useState('');

  const [address, setAddress] = useState('');

  const [city, setCity] = useState('');

  const [IsDisplayed, setIsDisplayed] = useState(false)
  const [IsGenderDisplayed, setIsGenderDisplayed] = useState(false)
  const [IsRegionDisplayed, setIsRegionDisplayed] = useState(false)

  const [palceHolder, setPalceHolder] = useState(true)
  const [genderPlaceHolder, setGenderPlaceHolder] = useState(true)
  const [regionPlaceHolder, setRegionPlaceHolder] = useState(true)

  const [IsStatusDisplayed, setIsStatusDisplayed] = useState(false)

  const [statusPlaceholder, setStatusPlaceholder] = useState(true)

  const [postal_Code, setPostal_Code] = useState('')

  const [region, setRegion] = useState('')

  const [email, setEmail] = useState(null)

  const [emcertificate_Specializationail, setEmcertificate_Specializationail] = useState('')

  const [certifi_StartDate, setCertifi_StartDate] = useState('')

  const [certifi_EndDate, setCertifi_EndDate] = useState('')

  const [cover_Period, setCover_Period] = useState('')

  const [cover_Price, setCover_Price] = useState('')

  const [university, setUniversity] = useState('')

  const [organization_Name, setOrganization_Name] = useState('')

  const [status, setStatus] = useState('')

  const [have_Certifi, setHave_Certifi] = useState('')

  const [graduated_Date, setGraduated_Date] = useState('')

  const [Insurance_CertificateEndDate, setInsurance_CertificateEndDate] = useState('')

  const [errors] = useState({
    allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",
  })

  const [errorsFlags, setErrorsFlags] = useState([false])

  const dispatch = useDispatch()

  const medicalTeamAdd
    = useSelector(state => state.medicalTeamAdd)

  const { error, medicalTeamData, success } = medicalTeamAdd

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    } else {
      if (!userInfo.is_activated) {
        history.push('/services')
      } else {
        if (userInfo.is_evaluated) {
          //history.push('/services')
        }
      }
    }

  }, [dispatch, history, userInfo])

  const handleDate = (d) => {
    if (d) {
      const offset = d.getTimezoneOffset();
      let newDate = new Date(d.getTime() - (offset * 60 * 1000));
      return newDate.toISOString().split('T')[0];
    }
  }

  const changeSelect = (title) => {
    setIsDisplayed(false);
    setPalceHolder(false);
    setCity(title);
  };
  const changeGenderSelect = (title) => {
    console.log('YAY');
    setIsGenderDisplayed(false)
    setGenderPlaceHolder(false)
    setGender(title)
  };

  const changeRegionSelect = (title) => {
    setIsRegionDisplayed(false)
    setRegionPlaceHolder(false)
    setRegion(title)
  };


  const changeSelect1 = (title) => {
    setIsStatusDisplayed(false)
    setStatusPlaceholder(false)
    setStatus(title)
  };


  const selectClick = () => {
    setIsDisplayed(IsDisplayed === true ? false : true);
  };

  const selectGenderClick = () => {
    setIsGenderDisplayed(IsGenderDisplayed === true ? false : true);
  };
  const selectRegionClick = () => {
    setIsRegionDisplayed(IsRegionDisplayed === true ? false : true);
  };

  const selectClick1 = () => {
    setIsStatusDisplayed(IsStatusDisplayed === true ? false : true);
  };

  const focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  const focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const regions = [
    "الرياض",
    "مكة",
    "المدينة المنورة",
    "القصيم",
    "الشرقية",
    "عسير",
    "تبوك",
    "حائل",
    "الحدود الشمالية",
    "جازان",
    "نجران",
    "الباحة",
    "الجوف",
  ];

  const cities = [
    "الرياض",
    "جدة",
    "مكة",
    "المدينة المنورة",
    "الدمام",
    "الطائف",
    "بريدة",
    "تبوك",
    "ابها",
    "خميس مشيط",
    "الأحساء",
    "القطيف",
    "الخبر",
    "حفر الباطن",
    "الجبيل",
    "الخرج",
    "حائل",
    "نجران",
    "ينبع",
    "صبيا",
    "الدوادمي",
    "بيشة",
    "أبو عريش",
    "القنفذة",
    "محايل",
    "سكاكا",
    "عرعر",
    "عنيزة",
    "القريات",
    "صامطة",
    "جازان",
    "المجمعة",
    "القويعية",
    "رأس تنورة	",
    "النماص	",
    "الوجه	",
    "خيبر	",
    "النبهانية	",
  ];

  const genders = [
    'ذكر',
    'أنثى'
  ];

  let chooseStatus = ["اعزب", "متزوج"];

  const onUpload = (e) => {
    if (e.target.value) {
      const ext = getExtension(e.target.value);
      if (ext === "png" || ext === "jpg" || ext === "pdf") {
        const path = e.target.getAttribute("data-path")
        if (path === "path1") {
          setPhoto1(e.target.value)
          setPath1(e.target.files[0])
          setWrong1(false)
        } else if (path === "path2") {
          setPhoto2(e.target.value)
          setPath2(e.target.files[0])
          setWrong2(false)
        } else if (path === "path3") {
          setPhoto3(e.target.value)
          setPath3(e.target.files[0])
          setWrong3(false)
        }
      } else {
        const path = e.target.getAttribute("data-path")
        if (path === "path1") {
          setPhoto1(false)
          setWrong1(true)
        } else if (path === "path2") {
          setPhoto2(false)
          setWrong2(true)
        } else if (path === "path3") {
          setPhoto3(false)
          setWrong3(true)
        }
      }
    }
  };


  const formValidation = () => {
    let isValid = true;
    const flags = [false];

    const nodes = document.querySelectorAll("input");
    nodes.forEach((e) => {
      if (e.value === '') {
        console.log(e)
        flags[0] = true;
        isValid = false;
      }

    });

    setErrorsFlags(flags);
    return isValid;
  };

  const sendData = (e) => {
    e.preventDefault()
    const isValid = formValidation();
    if (isValid) {

      let data = new FormData();

      data.append('ar_applicant_name', name);
      data.append('en_applicant_name', en_Name);
      data.append('id_num', id);
      data.append('practical_license', licence_No);
      data.append('practical_license_image', path1);
      data.append('license_issue_date', handleDate(start_Date));
      data.append('license_expire_date', handleDate(end_Date));
      data.append('medical_specialist', specialization);
      data.append('applicant_image', path2);
      data.append('birth_date', handleDate(birth_Date));
      data.append('id_expire_date', handleDate(id_endDate));
      data.append('phone', phone_No);
      data.append('gender', gender);
      data.append('nationality', nationality);
      data.append('address', address);
      data.append('city', city);
      data.append('postal_code', postal_Code);
      data.append('state', region);
      data.append('applicant_email', email);
      data.append('specialize_certificate_image', emcertificate_Specializationail);
      data.append('certificate_issue_date', handleDate(certifi_StartDate));
      data.append('certificate_expire_date', handleDate(certifi_EndDate));
      data.append('coverage_duration', cover_Period);
      data.append('coverage_price', cover_Price);
      data.append('university_name', university);
      data.append('employer_name', organization_Name);
      data.append('marital_status', status);
      data.append('graduation_date', handleDate(graduated_Date));
      data.append('insurance_certificate_expire_date', handleDate(Insurance_CertificateEndDate));
      data.append('insurance_certificate_image', path3);

      dispatch(AddMedicalTeam(data))

      history.push('/thanksMedicalTeam')

    }
  }

  return (
    <div>
      <ControlPanelHeader
        exit={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest={'/collectServices'}
        style={"three_section"}
      />
      <div className="data-form">
        <div className="desc-title text-center">إضافة طلب جديد</div>
        <div className="sub-title">فضلا اكمل البيانات الخاصه بالموظف</div>

        <form>
          <div className="name-form">
            <div className="input-lable">
              <label htmlFor="name">إسم الفني (ة) / الدكتور(ة) بالعربي</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                placeholder="اكتب إسم الفني (ة) / الدكتور(ة) بالعربي"
                onChange={(e) => setName(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="name">رقم الهوية/ الأقامة</label>
              <input
                type="text"
                id="id"
                name="id"
                value={id}
                placeholder="اكتب رقم الهوية/ الأقامة"
                onChange={(e) => setID(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="name">إسم الفني (ة) / الدكتور(ة)  بالإنجليزي</label>
              <input
                type="text"
                id="en_Name"
                name="en_Name"
                value={en_Name}
                onChange={(e) => setEn_Name(e.target.value)}
                placeholder="إسم الفني (ة) / الدكتور(ة)  بالإنجليزي"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="name">رقم رخصة المزاولة</label>
              <input
                type="number"
                id="licence_No"
                name="licence_No"
                value={licence_No}
                onChange={(e) => setLicence_No(e.target.value)}
                placeholder="اكتب رقم رخصة المزاولة"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-content">
              <p>صورة رخصة المزاولة</p>
              <label for="upload-photo1">
                {" "}
                <div className="input" style={{ height: "41px" }}>
                  <img src={upload} alt="" />
                  <div>{photo1}</div>
                </div>{" "}
              </label>
              <input
                onChange={onUpload}
                type="file"
                name="photo1"
                id="upload-photo1"
                data-path="path1"
                data-wrong="wrong1"
              />
              {photo1 && (
                <img
                  className="complete"
                  src={complete}
                  alt=""
                  style={{ left: "-50px", right: "unset" }}
                />
              )}
              {wrong1 && (
                <AiOutlineCloseCircle
                  style={{ left: "-50px", right: "unset" }}
                />
              )}
            </div>

            <div className="input-lable">
              <label htmlFor="date2">تاريخ اصدار رخصة المزاولة</label>
              <DatePicker style={{ height: "41px" }}
                selected={start_Date}
                onChange={(date) => setStart_Date(date)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                placeholderText="اكتب تاريخ الاصدار"
                popperPlacement="top"
              />
              <img src={date} alt="" />
            </div>

            <div className="input-lable">
              <label htmlFor="date2">تاريخ انتهاء رخصة المزاولة</label>
              <DatePicker style={{ height: "41px" }}
                selected={end_Date}
                onChange={(date) => setEnd_Date(date)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                placeholderText="اكتب تاريخ الانتهاء"
                popperPlacement="top"
              />
              <img src={date} alt="" />
            </div>

            <div className="input-lable">
              <label htmlFor="specialization">التخصص الطبي او المهني</label>
              <input
                type="text"
                id="specialization"
                name="specialization"
                value={specialization}
                onChange={(e) => setSpecialization(e.target.value)}
                placeholder="اكتب التخصص الطبي او المهني"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-content">
              <p>صورة  إسم الفني (ة) / الدكتور(ة) </p>
              <label for="upload-photo2">
                {" "}
                <div className="input" style={{ height: "41px" }}>
                  <img src={upload} alt="" />
                  <div>{photo2}</div>
                </div>{" "}
              </label>
              <input
                onChange={onUpload}
                type="file"
                name="photo2"
                id="upload-photo2"
                data-path="path2"
                data-wrong="wrong2"
              />
              {photo2 && (
                <img
                  className="complete"
                  src={complete}
                  alt=""
                  style={{ left: "-50px", right: "unset" }}
                />
              )}
              {wrong2 && (
                <AiOutlineCloseCircle
                  style={{ left: "-50px", right: "unset" }}
                />
              )}
            </div>

            <div className="input-lable">
              <label htmlFor="date2">تاريخ الميلاد</label>
              <DatePicker style={{ height: "41px" }}
                selected={birth_Date}
                onChange={(date) => setBirth_Date(date)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                placeholderText="اكتب تاريخ الميلاد"
                popperPlacement="top"
              />
              <img src={date} alt="" />
            </div>

            <div className="input-lable">
              <label htmlFor="date2">تاريخ إنتهاء الهوية</label>
              <DatePicker style={{ height: "41px" }}
                selected={id_endDate}
                onChange={(date) => setId_endDate(date)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                placeholderText="اكتب تاريخ إنتهاء الهوية"
                popperPlacement="top"
              />
              <img src={date} alt="" />
            </div>

            <div className="input-lable">
              <label htmlFor="name">الهاتف</label>
              <input
                type="text"
                id="phone_No"
                name="phone_No"
                value={phone_No}
                placeholder="اكتب رقم الهاتف"
                onChange={(e) => setPhone_No(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

          
            <div className="input-lable">
              <label htmlFor="gender">الجنس</label>
              <div className="select-city" style={{ width: "391px", height: "41px" }}>
                <div className="select-btn" onClick={selectGenderClick}>
                  {genderPlaceHolder && (
                    <span style={{ color: "var(--lightgray-color)", marginRight: "26px" }}>
                      اختر الجنس
                    </span>
                  )}
                  <span>{gender}</span>
                  <img src={rarrow} />
                </div>
                {IsGenderDisplayed && (
                  <div className="select-content" style={{ height: "100px" }}>
                    {genders.map((option) => (
                      <div className="select-item" onClick={(e) => {
                        changeGenderSelect(e.target.textContent);
                      }}>{" "}{option}{" "}</div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="input-lable">
              <label htmlFor="name">الجنسية</label>
              <input
                type="text"
                id="nationality"
                name="nationality"
                value={nationality}
                placeholder="اكتب الجنسية"
                onChange={(e) => setNationality(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="name">العنوان</label>
              <input
                type="text"
                id="address"
                name="address"
                value={address}
                placeholder="اكتب العنوان"
                onChange={(e) => setAddress(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="date1">المدينة</label>
              <div className="select-city" style={{ width: "391px", height: "41px" }}>
                <div className="select-btn" onClick={selectClick}>
                  {palceHolder && (
                    <span style={{ color: "var(--lightgray-color)", marginRight: "26px" }}>
                      اختر المدينة
                    </span>
                  )}
                  <span>{city}</span>
                  <img src={rarrow} />
                </div>
                {IsDisplayed && (
                  <div className="select-content">
                    {cities.map((option) => (
                      <div
                        className="select-item"
                        onClick={(e) => {
                          changeSelect(e.target.textContent);
                        }}
                      >
                        {" "}
                        {option}{" "}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="input-lable">
              <label htmlFor="name">الرمز البريدي</label>
              <input
                type="text"
                id="postal_Code"
                name="postal_Code"
                value={postal_Code}
                placeholder="اكتب الرمز البريدي"
                onChange={(e) => setPostal_Code(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            {/* <div className="input-lable">
              <label htmlFor="name">المنطقة</label>
              <input
                type="text"
                id="region"
                name="region"
                value={region}
                placeholder="اكتب المنطقة"
                onChange={(e) => setRegion(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div> */}
            <div className="input-lable">
              <label htmlFor="gender">المنطقة</label>
              <div className="select-city" style={{ width: "391px", height: "41px" }}>
                <div className="select-btn" onClick={selectRegionClick}>
                  {regionPlaceHolder && (
                    <span style={{ color: "var(--lightgray-color)", marginRight: "26px" }}>
                      اختر المنطقة
                    </span>
                  )}
                  <span>{region}</span>
                  <img src={rarrow} />
                </div>
                {IsRegionDisplayed && (
                  <div className="select-content">
                    {regions.map((option) => (
                      <div className="select-item" onClick={(e) => {
                        changeRegionSelect(e.target.textContent);
                      }}>{" "}{option}{" "}</div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="input-lable">
              <label htmlFor="name">البريد الالكتروني</label>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                placeholder="اكتب البريد الالكتروني"
                onChange={(e) => setEmail(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="name">شهادة هيئة التخصصات</label>
              <input
                type="text"
                id="emcertificate_Specializationail"
                name="emcertificate_Specializationail"
                value={emcertificate_Specializationail}
                placeholder="اكتب شهادة هيئة التخصصات"
                onChange={(e) => setEmcertificate_Specializationail(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="date2">تاريخ الاصدار</label>
              <DatePicker style={{ height: "41px" }}
                selected={certifi_StartDate}
                onChange={(date) => setCertifi_StartDate(date)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                placeholderText="اكتب   تاريخ الاصدار"
                popperPlacement="top"
              />
              <img src={date} alt="" />
            </div>

            <div className="input-lable">
              <label htmlFor="date2">تاريخ الانتهاء</label>
              <DatePicker style={{ height: "41px" }}
                selected={certifi_EndDate}
                onChange={(date) => setCertifi_EndDate(date)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                placeholderText="اكتب تاريخ الانتهاء    "
                popperPlacement="top"
              />
              <img src={date} alt="" />
            </div>

            <div className="input-lable">
              <label htmlFor="name">مدة التغطية</label>
              <input
                type="text"
                id="cover_Period"
                name="cover_Period"
                value={cover_Period}
                placeholder="اكتب مدة التغطية"
                onChange={(e) => setCover_Period(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="name">قيمة التغطية</label>
              <input
                type="text"
                id="cover_Price"
                name="cover_Price"
                value={cover_Price}
                placeholder="اكتب قيمة التغطية بالريال السعودي"
                onChange={(e) => setCover_Price(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="name">أسم الجامعة أو المعهد</label>
              <input
                type="text"
                id="university"
                name="university"
                value={university}
                placeholder="اكتب أسم الجامعة أو المعهد"
                onChange={(e) => setUniversity(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="name">أسم الجهة التي يعمل بها</label>
              <input
                type="text"
                id="organization_Name"
                name="organization_Name"
                value={organization_Name}
                placeholder="اكتب أسم الجهة التي يعمل بها"
                onChange={(e) => setOrganization_Name(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="date1">الحالة</label>
              <div className="select-city" style={{ width: "391px", height: "41px" }}>
                <div className="select-btn" onClick={selectClick1}>
                  {statusPlaceholder && (
                    <span style={{ color: "var(--lightgray-color)" }}>
                      اختر الحالة
                    </span>
                  )}
                  <span>{status}</span>
                  <img src={rarrow} />
                </div>
                {IsStatusDisplayed && (
                  <div
                    className="select-content"
                    style={{
                      height: "100px",
                      overflowY: "unset",
                      overflow: "hidden",
                    }}
                  >
                    {chooseStatus.map((option) => (
                      <div
                        className="select-item"
                        onClick={(e) => {
                          changeSelect1(e.target.textContent);
                        }}
                      >
                        {" "}
                        {option}{" "}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>


            <div className="radio_input">
              <p> هل لديك شهادة تأمين سارية الصلاحية</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="have_Certifi"
                    value="نعم"
                    checked={have_Certifi}
                    onChange={(e) => setHave_Certifi(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="have_Certifi"
                    onChange={(e) => setHave_Certifi(e.target.value === 'نعم')}
                  />
                  لا
                </label>
              </div>
            </div>

            <div className="input-lable">
              <label htmlFor="date2">تاريخ التخرج</label>
              <DatePicker style={{ height: "41px" }}
                selected={graduated_Date}
                onChange={(date) => setGraduated_Date(date)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                placeholderText="اكتب تاريخ التخرج   "
                popperPlacement="top"
              />
              <img src={date} alt="" />
            </div>

            <div className="input-lable">
              <label htmlFor="date2">تاريخ إنتهاء شهادة التأمين</label>
              <DatePicker style={{ height: "41px" }}
                selected={Insurance_CertificateEndDate}
                onChange={(date) =>
                  setInsurance_CertificateEndDate(date)
                }
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                placeholderText="اكتب تاريخ التخرج   "
                popperPlacement="top"
              />
              <img src={date} alt="" />
            </div>

            <div className="input-content">
              <p>رفع شهادة التأمين</p>
              <label for="upload-photo3">
                {" "}
                <div className="input" style={{ height: "41px" }}>
                  <img src={upload} alt="" />
                  <div>{photo3}</div>
                </div>{" "}
              </label>
              <input
                onChange={onUpload}
                type="file"
                name="photo3"
                id="upload-photo3"
                data-path="path3"
                data-wrong="wrong3"
              />
              {photo3 && (
                <img
                  className="complete"
                  src={complete}
                  alt=""
                  style={{ left: "-50px", right: "unset" }}
                />
              )}
              {wrong3 && (
                <AiOutlineCloseCircle
                  style={{ left: "-50px", right: "unset" }}
                />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end" style={{ marginRight: "75px" }}>{errorsFlags[0] && <div className='error'>{errors.allFieldRequired}</div>}
          </div>
        </form>

      </div>

      <button onClick={sendData} className="btnNext">
        <img src={arrow} alt="" />
        إرسال
      </button>
    </div>
  )
}

export default AddMedicalTeamScreen
