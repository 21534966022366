import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/negotiation.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import "../../styles/collect_service.css";
import { Link } from "react-router-dom";
import SearchBox from '../../component/SearchBox'
import { Button, Table, Row, Form } from "react-bootstrap";
import axios from 'axios';

import { listCollectInvoicesOld } from '../../actions/serviceActions'

const fileDownload = require('js-file-download');

const titles = [
	"رقم الفاتورة",
	"نوع العملية",
	"الخدمة",
	"تاريخ الدفع",
	"طريقة الدفع",
	"قيمة الفاتورة",
	"تاريخ السداد",
	"حالة الفاتورة",
];

const more = {
	title: "الاجراءات",
	actions: {
		"اضافة سعر": "negotiate",
		تفاوض: "Negotiation",
	},
};


function OldBills({ history }) {

	const [selectValue, setSelectValue] = useState("");

	const dispatch = useDispatch()

	const collectInvoiceOldList = useSelector(state => state.collectInvoiceOldList)
	const { error, loading, collectInvoices, page, pages } = collectInvoiceOldList

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	let keyword = history.location.search

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			if (!userInfo.is_activated) {
				history.push('/services')
			} else {
				dispatch(listCollectInvoicesOld(keyword))
			}
		}

	}, [dispatch, history, userInfo, keyword])

	const handleChange = (e) => {
		setSelectValue(e.target.value);
	}

	const handleDownload = (filename) => {
		axios.post('management/download/', {
            responseType: 'blob',
            filename: filename
        }).then(res => {
            fileDownload(res.data, filename);
        }).catch(err => {
			
        })
	}

	const handleMore = (id) => {
		return Object.keys(more.actions).map((key, index) => {
			let path = Object.values(more.actions)[index] + '/' + id;
			return <option value={path} key={index}>{key}</option>;
		});
	}

	return (
		<div className="negotiation_page">
			<ControlPanelHeader
				exit1={true}
				notifi={true}
				msg={true}
				files={false}
				stat={false}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				exitDest={"/services"}
				fileDest={"/FacilityFiles"}
				statDest={'/collectServices'}
				style={"threetwo_section"}
			/>

			<div
				style={{
					position: "relative",
					backgroundColor: "#eee",
					height: "134px",
					marginTop: "0",
					paddingBottom: "20px",
					marginBottom: "0",
				}}
				className="header_title"
			>
				<div className="input_group">
					<SearchBox link='/collectServices' />

				</div>
				<div className="button_new">
					<Link to="/OldBills">
						<Button className="button_new_1" style={{ backgroundColor: "var(--main-color)" }}>
							قائمة الفواتير القديمة
						</Button>
					</Link>
					<Link to="/NewBills">
						<Button className="button_new_2 " style={{ backgroundColor: "#ababab" }}>قائمة الفواتير الجديدة</Button>
					</Link>
				</div>
			</div>

			<div
				className="nego_list"
				style={{ width: "1600px", height: "446px", margin: "55px auto 35px" }}
			>
				<div className="heading text-center">قائمة الفواتير القديمة</div>

				<div
					className="nego_table scroll"
					style={{ width: "1525px", height: "339px" }}
				>
					<Table bordered hover className="TableNewDesign">
						<thead>
							<tr>
								{titles.map((title, x) => (
									<th key={x + 1}>{title}</th>
								))
								}
								{more ? <th>{more.title}</th> : ""}

							</tr>
						</thead>

						<tbody>
							{collectInvoices.map((obj) => (
								<tr key={obj.id}>
									<td>IN-{obj.invoice_num}</td>
									<td>
										{
											obj.operation_type === "B" ?
											'شراء' : obj.operation_type === "S" ?
												'بيع' 
												: 'تأجير'
										}</td>
									<td>{obj.service}</td>
									<td>{obj.invoice_pay_date}</td>
									<td>
										{
											obj.collect_method === "P" ?
												'دفعة واحدة' : 'دفعتين'
										}
									</td>
									<td>{obj.invoice_price} SR</td>
									<td>{obj.negotiation_duration} سنوات</td>
									<td>
										{
											obj.invoice_paid === true ?
												' مدفوعة' : ' غير مدفوعة'
										}
									</td>
									<td className="actions">
										<button className="btn btn-success" onClick={() => handleDownload(obj.invoice_image)}>تنزيل الفاتورة</button>
									</td>
								</tr>
							))
							}
						</tbody>
					</Table>
				</div>
			</div>
		</div>
	)
}

export default OldBills
