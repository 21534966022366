import {
    EVALUATION_DATA_ADD_REQUEST,
    EVALUATION_DATA_ADD_SUCCESS,
    EVALUATION_DATA_ADD_FAIL,

    EVALUATION_DETAILS_REQUEST,
    EVALUATION_DETAILS_SUCCESS,
    EVALUATION_DETAILS_FAIL,

    EVALUATION_UPGRADE_REQUEST,
    EVALUATION_UPGRADE_SUCCESS,
    EVALUATION_UPGRADE_FAIL,

    MEDICAL_DEVICE_ADD_REQUEST,
    MEDICAL_DEVICE_ADD_SUCCESS,
    MEDICAL_DEVICE_ADD_FAIL,

    MEDICAL_DEVICE_LIST_REQUEST,
    MEDICAL_DEVICE_LIST_SUCCESS,
    MEDICAL_DEVICE_LIST_FAIL,

    MEDICAL_DEVICE_DETAILS_REQUEST,
    MEDICAL_DEVICE_DETAILS_SUCCESS,
    MEDICAL_DEVICE_DETAILS_FAIL,

    MEDICAL_DEVICE_ADD_OFFER_REQUEST,
    MEDICAL_DEVICE_ADD_OFFER_SUCCESS,
    MEDICAL_DEVICE_ADD_OFFER_FAIL,

    DOCTORS_CLINICS_ADD_REQUEST,
    DOCTORS_CLINICS_ADD_SUCCESS,
    DOCTORS_CLINICS_ADD_FAIL,

    DOCTORS_CLINICS_LIST_REQUEST,
    DOCTORS_CLINICS_LIST_SUCCESS,
    DOCTORS_CLINICS_LIST_FAIL,

    DOCTORS_CLINICS_DETAILS_REQUEST,
    DOCTORS_CLINICS_DETAILS_SUCCESS,
    DOCTORS_CLINICS_DETAILS_FAIL,

    DOCTORS_CLINICS_ADD_OFFER_REQUEST,
    DOCTORS_CLINICS_ADD_OFFER_SUCCESS,
    DOCTORS_CLINICS_ADD_OFFER_FAIL,

    LABORATORIES_ADD_REQUEST,
    LABORATORIES_ADD_SUCCESS,
    LABORATORIES_ADD_FAIL,

    LABORATORIES_LIST_REQUEST,
    LABORATORIES_LIST_SUCCESS,
    LABORATORIES_LIST_FAIL,

    LABORATORIES_DETAILS_REQUEST,
    LABORATORIES_DETAILS_SUCCESS,
    LABORATORIES_DETAILS_FAIL,

    LABORATORIES_ADD_OFFER_REQUEST,
    LABORATORIES_ADD_OFFER_SUCCESS,
    LABORATORIES_ADD_OFFER_FAIL,

    RAYS_LIST_REQUEST,
    RAYS_LIST_SUCCESS,
    RAYS_LIST_FAIL,

    RAYS_ADD_REQUEST,
    RAYS_ADD_SUCCESS,
    RAYS_ADD_FAIL,

    RAYS_DETAILS_REQUEST,
    RAYS_DETAILS_SUCCESS,
    RAYS_DETAILS_FAIL,

    RAYS_ADD_OFFER_REQUEST,
    RAYS_ADD_OFFER_SUCCESS,
    RAYS_ADD_OFFER_FAIL,

    MEDICAL_TEAM_DATA_ADD_REQUEST,
    MEDICAL_TEAM_DATA_ADD_SUCCESS,
    MEDICAL_TEAM_DATA_ADD_FAIL,

    MEDICAL_TEAM_DATA_LIST_REQUEST,
    MEDICAL_TEAM_DATA_LIST_SUCCESS,
    MEDICAL_TEAM_DATA_LIST_FAIL,

    MEDICAL_TEAM_DATA_LIST_ALL_REQUEST,
    MEDICAL_TEAM_DATA_LIST_ALL_SUCCESS,
    MEDICAL_TEAM_DATA_LIST_ALL_FAIL,

    MEDICAL_TEAM_DETAILS_REQUEST,
    MEDICAL_TEAM_DETAILS_SUCCESS,
    MEDICAL_TEAM_DETAILS_FAIL,

    MEDICAL_TEAM_UPDATE_REQUEST,
    MEDICAL_TEAM_UPDATE_SUCCESS,
    MEDICAL_TEAM_UPDATE_FAIL,
    MEDICAL_TEAM_UPDATE_RESET,

    MEDICAL_TEAM_DELETE_REQUEST,
    MEDICAL_TEAM_DELETE_SUCCESS,
    MEDICAL_TEAM_DELETE_FAIL,

    MEDICAL_TEAM_INSURRENCE_REQUEST,
    MEDICAL_TEAM_INSURRENCE_SUCCESS,
    MEDICAL_TEAM_INSURRENCE_FAIL,

    COLLECT_INVOICES_LIST_REQUEST,
    COLLECT_INVOICES_LIST_SUCCESS,
    COLLECT_INVOICES_LIST_FAIL,

    COLLECT_INVOICES_OLD_LIST_REQUEST,
    COLLECT_INVOICES_OLD_LIST_SUCCESS,
    COLLECT_INVOICES_OLD_LIST_FAIL,

    COLLECT_INVOICES_NEW_LIST_REQUEST,
    COLLECT_INVOICES_NEW_LIST_SUCCESS,
    COLLECT_INVOICES_NEW_LIST_FAIL,

    INSURRENCE_ADD_OFFER_REQUEST,
    INSURRENCE_ADD_OFFER_SUCCESS,
    INSURRENCE_ADD_OFFER_FAIL,

    INSURRENCE_OFFER_UPDATE_REQUEST,
    INSURRENCE_OFFER_UPDATE_SUCCESS,
    INSURRENCE_OFFER_UPDATE_FAIL,
    INSURRENCE_OFFER_UPDATE_RESET,

    INSURRENCE_OFFERS_LIST_REQUEST,
    INSURRENCE_OFFERS_LIST_SUCCESS,
    INSURRENCE_OFFERS_LIST_FAIL,

    INSURRENCE_OFFER_DETAILS_REQUEST,
    INSURRENCE_OFFER_DETAILS_SUCCESS,
    INSURRENCE_OFFER_DETAILS_FAIL,
    INSURRENCE_OFFER_DETAILS_RESET,

    INVOICE_ADD_OFFER_REQUEST,
    INVOICE_ADD_OFFER_SUCCESS,
    INVOICE_ADD_OFFER_FAIL,
    EVALUATION_UPDATE_REQUEST,
    EVALUATION_UPDATE_FAIL,
    EVALUATION_UPDATE_SUCCESS,

} from '../constants/serviceConstants'

import axios from 'axios'


export const AddEvaluation = (evaluationData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EVALUATION_DATA_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/request_evaluation/`, evaluationData,
            config

        )

        dispatch({
            type: EVALUATION_DATA_ADD_SUCCESS,
            payload: data
        })

        //localStorage.setItem('evaluationData', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: EVALUATION_DATA_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const AddMedicalDevice = (medicalDeviceAddData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_DEVICE_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/medical_device_request/`, medicalDeviceAddData,
            config

        )

        dispatch({
            type: MEDICAL_DEVICE_ADD_SUCCESS,
            payload: data
        })

        //localStorage.setItem('evaluationData', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: MEDICAL_DEVICE_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getEvaluationDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: EVALUATION_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            `/services/evaluation_details/`,
            config
        )

        dispatch({
            type: EVALUATION_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: EVALUATION_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const UpdateEvaluation = (upgradeData, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EVALUATION_UPDATE_REQUEST
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.patch(
            `/services/review_evaluation/${id}`,
            upgradeData,
            config
        )

        dispatch({
            type: EVALUATION_UPDATE_SUCCESS,
            payload: data
        })

        //localStorage.setItem('evaluationData', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: EVALUATION_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const AddUpgradeEvaluation = (upgradeData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EVALUATION_UPGRADE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/request_upgrade_evaluation/`, upgradeData,
            config

        )

        dispatch({
            type: EVALUATION_UPGRADE_SUCCESS,
            payload: data
        })

        //localStorage.setItem('evaluationData', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: EVALUATION_UPGRADE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listMedicalDevices = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: MEDICAL_DEVICE_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/medical_devices${keyword}`, config)

        dispatch({
            type: MEDICAL_DEVICE_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_DEVICE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getMedicalDeviceDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: MEDICAL_DEVICE_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/medical_devices/${id}/`, config)

        dispatch({
            type: MEDICAL_DEVICE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_DEVICE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deviceAddOffer = (offerData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_DEVICE_ADD_OFFER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/medical_device_offer/`, offerData,
            config

        )

        dispatch({
            type: MEDICAL_DEVICE_ADD_OFFER_SUCCESS,
            payload: data
        })

        //localStorage.setItem('evaluationData', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: MEDICAL_DEVICE_ADD_OFFER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const AddDoctorsClinics = (doctorsClinicsAddData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DOCTORS_CLINICS_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/clinic_request/`, doctorsClinicsAddData,
            config

        )

        dispatch({
            type: DOCTORS_CLINICS_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DOCTORS_CLINICS_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listDoctorsClinics = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: DOCTORS_CLINICS_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/doctors_clinics${keyword}`, config)

        dispatch({
            type: DOCTORS_CLINICS_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DOCTORS_CLINICS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getDoctorsClinicsDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: DOCTORS_CLINICS_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/doctors_clinics/${id}/`, config)

        dispatch({
            type: DOCTORS_CLINICS_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DOCTORS_CLINICS_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addDoctorClinicOffer = (offerData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DOCTORS_CLINICS_ADD_OFFER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/clinic_offer/`, offerData,
            config

        )

        dispatch({
            type: DOCTORS_CLINICS_ADD_OFFER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DOCTORS_CLINICS_ADD_OFFER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addLaboratories = (laboratoriesAddData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LABORATORIES_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/add_lab_request/`, laboratoriesAddData,
            config

        )

        dispatch({
            type: LABORATORIES_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LABORATORIES_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listLaboratories = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: LABORATORIES_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/laboratories${keyword}`, config)

        dispatch({
            type: LABORATORIES_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LABORATORIES_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getLaboratoryDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: LABORATORIES_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/laboratories/${id}/`, config)

        dispatch({
            type: LABORATORIES_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LABORATORIES_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addLaboratoriesOffer = (offerData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LABORATORIES_ADD_OFFER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/add_lab_offer/`, offerData,
            config

        )

        dispatch({
            type: LABORATORIES_ADD_OFFER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LABORATORIES_ADD_OFFER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listRays = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: RAYS_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/rays${keyword}`, config)

        dispatch({
            type: RAYS_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RAYS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addRays = (raysAddData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RAYS_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/add_radio_request/`, raysAddData,
            config

        )

        dispatch({
            type: RAYS_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RAYS_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getRayDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: RAYS_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/rays/${id}/`, config)

        dispatch({
            type: RAYS_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RAYS_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addRaysOffer = (offerData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RAYS_ADD_OFFER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/add_radio_offer/`, offerData,
            config

        )

        dispatch({
            type: RAYS_ADD_OFFER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RAYS_ADD_OFFER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const AddMedicalTeam = (medicalTeamData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_TEAM_DATA_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/medical_staff_insurance/`, medicalTeamData,
            config
        )

        dispatch({
            type: MEDICAL_TEAM_DATA_ADD_SUCCESS,
            payload: data
        })

        //localStorage.setItem('evaluationData', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_DATA_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listMedicalTeam = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: MEDICAL_TEAM_DATA_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/medical_team${keyword}`, config)

        dispatch({
            type: MEDICAL_TEAM_DATA_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_DATA_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listAllMedicalTeam = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: MEDICAL_TEAM_DATA_LIST_ALL_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/all_medical_team${keyword}`, config)

        dispatch({
            type: MEDICAL_TEAM_DATA_LIST_ALL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_DATA_LIST_ALL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getMedicalTeamDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: MEDICAL_TEAM_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/medical_staff_insurance/get/${id}`, config)


        dispatch({
            type: MEDICAL_TEAM_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateMedicalTeam = (medicalTeam, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_TEAM_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/services/update_medical_staff_insurance/${id}`,
            medicalTeam,
            config
        )

        dispatch({
            type: MEDICAL_TEAM_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteMedicalTeam = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_TEAM_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/services/delete_medical_staff_insurance/${id}`,
            id,
            config
        )

        dispatch({
            type: MEDICAL_TEAM_DELETE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const requestInsurrenceMedicalTeam = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_TEAM_INSURRENCE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/services/request_medical_staff_insurance/${id}`,
            id,
            config
        )

        dispatch({
            type: MEDICAL_TEAM_INSURRENCE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_INSURRENCE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listCollectInvoices = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: COLLECT_INVOICES_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/collect_invoices${keyword}`, config)

        dispatch({
            type: COLLECT_INVOICES_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: COLLECT_INVOICES_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listCollectInvoicesOld = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: COLLECT_INVOICES_OLD_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/collect_invoices_old${keyword}`, config)

        dispatch({
            type: COLLECT_INVOICES_OLD_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: COLLECT_INVOICES_OLD_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listCollectInvoicesNew = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: COLLECT_INVOICES_NEW_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/collect_invoices_new${keyword}`, config)

        dispatch({
            type: COLLECT_INVOICES_NEW_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: COLLECT_INVOICES_NEW_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addInsurrenceOffer = (offerData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INSURRENCE_ADD_OFFER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.post(
            `/services/medical_staff_insurance_offer/`, offerData,
            config
        )

        dispatch({
            type: INSURRENCE_ADD_OFFER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: INSURRENCE_ADD_OFFER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateInsurrenceOffer = (insurrenceOffer) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INSURRENCE_OFFER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/services/update_insurance_offer/`,
            insurrenceOffer,
            config
        )

        dispatch({
            type: INSURRENCE_OFFER_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: INSURRENCE_OFFER_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listInsurrenceOffers = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: INSURRENCE_OFFERS_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/insurrence_offers/${id}`, config)

        dispatch({
            type: INSURRENCE_OFFERS_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: INSURRENCE_OFFERS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getInsurrenceOfferDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: INSURRENCE_OFFER_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/insurrence_offer/${id}`, config)

        dispatch({
            type: INSURRENCE_OFFER_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: INSURRENCE_OFFER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addInvoiceForOffer = (id, typeId, offerData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INVOICE_ADD_OFFER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        let data = {};

        if (typeId === "1") {
            data = await axios.post(
                `/services/finalize_DevicesOffer/${id}/`, offerData,
                config

            )
        } else if (typeId === "2") {
            data = await axios.post(
                `/services/finalize_ClinicsOffer/${id}/`, offerData,
                config

            )

        } else if (typeId === "3") {
            data = await axios.post(
                `/services/finalize_LabsOffer/${id}/`, offerData,
                config

            )

        } else if (typeId === "4") {
            data = await axios.post(
                `/services/finalize_RadiologyOffer/${id}/`, offerData,
                config

            )

        } else if (typeId === "5") {
            data = await axios.post(
                `/services/finalize_TenderOffer/${id}/`, offerData,
                config

            )
        }




        dispatch({
            type: INVOICE_ADD_OFFER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: INVOICE_ADD_OFFER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}




