import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css";
import Paginate from '../../component/Paginate'
import ControlPanelHeader from "../../component/ControlPanelHeader";
import { Button, Table } from "react-bootstrap";
import "../../styles/TableNewDesign.css";
import Loader from '../../component/Loader'
import Message from '../../component/Message'
import { Link } from "react-router-dom";
import "../../styles/Medical_Technical_TeamList.css";
import "../../styles/MedicalNetwork_list.css";
import "tippy.js/dist/tippy.css";
import {listAllMedicalTeam} from '../../actions/serviceActions' 

function MedicalNetworkListScreen({ history }) {

    const dispatch = useDispatch()

    const medicalTeamAllList = useSelector(state => state.medicalTeamAllList)
    const {error, loading, medicalTeam, page, pages} = medicalTeamAllList

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    let keyword = history.location.search

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/ServiceInsuranceCompany')
        } else {
          dispatch(listAllMedicalTeam(keyword))
        }     
      }
    
    }, [dispatch, history, userInfo, keyword])

    const titles = [
      "رقم الطلب",
      "اسم الطبيب أو الفني",
      "التخصص",
      "الجهة التابعة له",
    ];

    const more = {
      title: "نوع الإجراء و التنفيذ",
    };

    return (
      <div className="list_page">
        <ControlPanelHeader
          exit1={true}
          notifi={true}
          msg={true}
          files={false}
          stat={false}
          msgDest={"/Messages"}
          notifidest={"/Notifications"}
          exitDest={"/services"}
          fileDest={"/FacilityFiles"}
          statDest = {'/collectServices'}
          style={"threetwo_section"}
        />
        <div className="bills_list">
          <div className="heading text-center">الطلبات</div>
          <div className="bills_table scroll">
            { loading
            ? (<Loader/>) 
            : error 
            ? (<Message variant='danger'>{error}</Message>)
            : (
                <Table bordered hover className="TableNewDesign">
                  <thead>
                    <tr>
                      {titles.map((title, x) => (
                        <th key={x+1}>{title}</th>
                      ))}
                      {more ? <th>{more.title}</th> : ""}  
                    </tr>
                  </thead>
                  <tbody>
                    {medicalTeam.map((obj) => (
                      <tr key={obj.id}>
                        <td>{obj.id}</td>
                        <td>{obj.ar_applicant_name}</td>
                        <td>{obj.medical_specialist}</td>
                        <td>{obj.employer_name}</td>
                        <td>
                          { obj.insurance_requests.length === 0 ?
                            (
                              <Link to={`/OrderDetailsInsurance/${obj.id}`} className="fw-bolder w-75 btn btn-outline-dark">
                              عرض الطلب 
                              </Link>
                            ) : (
                              obj.insurance_requests.map(item => (
                            
                                (item.offer_status === "C" && item.approved === true && item.accepted === true )  ? (
                                  <Link to={`/AttachPaiedLink/${obj.id}`} className="fw-bolder w-75 btn btn-outline-danger">
                                  إتمام عملية تنفيذ الطلب 
                                  </Link>
                                ) : (item.offer_status === "C" && item.approved === false) ? (
                                  <Button className="fw-bolder w-75 btn btn-warning">
                                  قيد الدراسة 
                                  </Button>
                                ) : (item.offer_status === "S" && item.approved === false) ? (
                                  <Button className="fw-bolder w-75 btn btn-warning">
                                  قيد الدراسة 
                                  </Button>
                                ) : item.offer_status === "S" ? (
                                  <Link to={`/InsuranceCertificate/${obj.id}`} className="fw-bolder w-75 btn btn-outline-secondary">
                                   إرسال الشهادة والفاتورة 
                                  </Link>
                                ) : item.offer_status === "P" ? (
                                  <button disabled={true} className="fw-bolder w-75 btn btn-outline-secondary ">
                                   العميل مؤمن 
                                  </button>
                                ) : (<Button className="fw-bolder w-75 btn btn-warning">
                                قيد الدراسة 
                                </Button>) 
                            )))
                          }     
                        </td>
                      </tr>
                    ))}                
                  </tbody> 
                </Table>
              )
            }    
          </div>
          <Paginate page = {page} pages = {pages} keyword= {keyword} link="/Orders" />  
        </div>
      </div>
    );       
}

export default MedicalNetworkListScreen
