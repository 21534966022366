import axios from 'axios'
import {
    SECTOR_TYPE_LIST_REQUEST,
    SECTOR_TYPE_LIST_SUCCESS,
    SECTOR_TYPE_LIST_FAIL,


    SECTOR_LIST_REQUEST,
    SECTOR_LIST_SUCCESS,
    SECTOR_LIST_FAIL,
} from '../constants/sectorConstants'


export const listSectors = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SECTOR_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.get(`/management/sectors/`, config)

        dispatch ({
            type: SECTOR_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({ 
            type: SECTOR_LIST_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listSectorTypes = (selectedTag) => async (dispatch, getState) => {
    try {

        dispatch({ type: SECTOR_TYPE_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.get(
            `/management/sectors/${selectedTag}`, config)

        dispatch ({
            type: SECTOR_TYPE_LIST_SUCCESS,
            payload: data.types
        })

    } catch (error) {
        dispatch({ 
            type: SECTOR_TYPE_LIST_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
