import InvoiceTable from "./InvoiceTable";
import { Container, Button, Row, Col } from "react-bootstrap";
import "./invoiceAllType.css";
import QRCode from "react-qr-code";
import Hdate from "./Hdate";
import logo from "./logo.svg";
import social from "./social.png";
import sign from "./sign.png";

function InvoiceAllType() {
  let title1 = ["من"];
  let title2 = ["إلى"];
  let title3 = ["الاسم", "العمر", "الجنس", "البلد"];
  let data1 = {
    الاسم: "أحمد",
    العمر: 29,
    الجنس: "ذكر",
    البلد: "السعودية",
  };

  let data2 = {
    الاسم: "أحمد",
    العمر: 29,
    الجنس: "ذكر",
    البلد: "السعودية",
    الاسم1: "أحمد",
    العمر1: 29,
    الجنس1: "ذكر",
    البلد1: "السعودية",
    الاسم2: "أحمد",
    العمر2: 29,
    الجنس2: "ذكر",
    البلد2: "السعودية",
  };
  function handleClick() {
    window.print();
  }

  return (
    <div className="w-100">
      <Container className="inv_a4 screen">
        <Button
          onClick={() => handleClick()}
          className="print_hide m-4 inv_button-print bg-secondary"
        >
          طباعة
        </Button>
        <div className="header_c" />
        <h1 className="inv_h1">فاتورة أشتراك</h1>
        <img className="inv_img" alt="logo" src={logo} />
        <Hdate
          className="inv_Hdate_table"
          date="22/5/2021"
          code="25G54"
          codeTitle="كود العميل:"
        />
        <p className="inv_QR_title">كود التسجيل:</p>
        <QRCode className="inv_QR" value={window.location.href} size="75" />
        <div className="inv_data">
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
        </div>
        <div className="inv_table">
          <Row>
            <Col lg="4">
              <InvoiceTable
                className="inv_table_from"
                title={title1}
                data={data1}
                type="1"
              />
            </Col>
            <Col lg="4" />
            <Col lg="4">
              <InvoiceTable
                className="inv_table_to"
                title={title2}
                data={data1}
                type="1"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <InvoiceTable
                className="inv_table_details"
                title={title3}
                data={data2}
                type="2"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="inv_pay">
                <h1 className="inv_pay_header">طريقة الدفع</h1>
                <p>طريقة الدفع</p>
                <p>طريقة الدفع</p>
                <p>طريقة الدفع</p>
              </div>
            </Col>
            <Col lg="6">
              <InvoiceTable
                className="inv_table_total"
                title={title2}
                data={data1}
                type="3"
              />
            </Col>
          </Row>
          <div className="inv_terms">
            <p className="inv_terms_h">سياسة الإرجاع والاأستبدال:</p>
            <p className="inv_terms_p">
              من اجل الوصول الى سياسة الارجاع والتبديل, يمكنكم زيارة قسم خدمة
              العملاء او يمكنكم تصفحها على
              (http://www.extrastores.com/ar-sa/returnexchange) الالكتروني
              موقعنا شكرا للتسوق فى اكسترا, لمزيد من المعلومات عن شروط مبيعات
              المنتجات, والخدمات ... http://www.extrastores.com زيارة نرج
            </p>
            <p className="inv_terms_h mt-4">
              الشروط و الأحكام للأجهزه المستخدمه:
            </p>
            <p className="inv_terms_p">
              24 ساعه للارجاع. لايوجد استبدال, فتره الضمان 30 يوم (ضمان أكسترا),
              فى حاله عدم التمكن من اصلاح الجهاز خلال فتره الضمان, سيتم ارجاع
              المبلغ المدفوع بالكامل ولن يتم الإستبدال.
            </p>
          </div>
          <div className="inv_sign_l">
            <h3 className="inv_h3_sign">معتمدة من قبل الرئيس التنفيذي</h3>
            <h2 className="inv_h2_sign">أحمد محمد السلمي</h2>
            <img className="inv_img_sign" alt="sign" src={sign} />
          </div>
        </div>
        <div className="inv_footer">
          <span className="footer_text">الإيميل : info@bayan.med.sa</span>
          <img className="social" alt="social" src={social} />
        </div>
      </Container>
      <page size="A4" className="print">
        <div className="header_c" />
        <h1 className="inv_h1">فاتورة أشتراك</h1>
        <img className="inv_img" alt="logo" src={logo} />
        <Hdate
          className="inv_Hdate_table"
          date="22/5/2021"
          code="25G54"
          codeTitle="كود العميل:"
        />
        <p className="inv_QR_title">كود التسجيل:</p>
        <QRCode
          className="inv_QR"
          //   fgColor="#1bb1aa"
          value={window.location.href}
          size="75"
        />
        <div className="inv_data">
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
        </div>
        <div className="inv_table">
          <Row>
            <Col lg="4">
              <InvoiceTable
                className="inv_table_from"
                title={title1}
                data={data1}
                type="1"
              />
            </Col>
            <Col lg="4" />
            <Col lg="4">
              <InvoiceTable
                className="inv_table_to"
                title={title2}
                data={data1}
                type="1"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <InvoiceTable
                className="inv_table_details"
                title={title3}
                data={data2}
                type="2"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="inv_pay">
                <h1 className="inv_pay_header">طريقة الدفع</h1>
                <p>طريقة الدفع</p>
                <p>طريقة الدفع</p>
                <p>طريقة الدفع</p>
              </div>
            </Col>
            <Col lg="6">
              <InvoiceTable
                className="inv_table_total"
                title={title2}
                data={data1}
                type="3"
              />
            </Col>
          </Row>
          <div className="inv_terms">
            <p className="inv_terms_h">سياسة الإرجاع والاأستبدال:</p>
            <p className="inv_terms_p">
              من اجل الوصول الى سياسة الارجاع والتبديل, يمكنكم زيارة قسم خدمة
              العملاء او يمكنكم تصفحها على
              (http://www.extrastores.com/ar-sa/returnexchange) الالكتروني
              موقعنا شكرا للتسوق فى اكسترا, لمزيد من المعلومات عن شروط مبيعات
              المنتجات, والخدمات ... http://www.extrastores.com زيارة نرج
            </p>
            <p className="inv_terms_h mt-4">
              الشروط و الأحكام للأجهزه المستخدمه:
            </p>
            <p className="inv_terms_p">
              24 ساعه للارجاع. لايوجد استبدال, فتره الضمان 30 يوم (ضمان أكسترا),
              فى حاله عدم التمكن من اصلاح الجهاز خلال فتره الضمان, سيتم ارجاع
              المبلغ المدفوع بالكامل ولن يتم الإستبدال.
            </p>
          </div>
          <div className="inv_sign_l">
            <h3 className="inv_h3_sign">معتمدة من قبل الرئيس التنفيذي</h3>
            <h2 className="inv_h2_sign">أحمد محمد السلمي</h2>
            <img className="inv_img_sign" alt="sign" src={sign} />
          </div>
        </div>
        <div className="inv_footer">
          <span className="footer_text">الإيميل : info@bayan.med.sa</span>
          <img className="social" alt="social" src={social} />
        </div>
      </page>
    </div>
  );
}
export default InvoiceAllType;
