import axios from 'axios'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESS,
    USER_CHANGE_PASSWORD_FAIL,


} from '../constants/userConstants'


export const login = (commercialNum, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }   

        const isEmployer = commercialNum.endsWith('@bayan.md.sa');

        const { data } = await axios.post(
            isEmployer ? '/employer_login/' : '/login/',
            (
                isEmployer ?
                {
                    'email': commercialNum,
                    'password': password
                } : 
                {
                    'commercial_registration_num': commercialNum,
                    'password': password
                }
            ),
            config

        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const userLogout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('facility')
    localStorage.removeItem('facilityOwner')
    localStorage.removeItem('facilityEmployee')
    dispatch({ type: USER_LOGOUT })
}

export const register = (commercialNum, email) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/register/',
            { 'commercial_registration_num': commercialNum, 'email': email, 'user_type': 'facility' },
            config

        )

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const changePassword = (oldPassword, password, confirmPassword) => async (dispatch) => {
    try {
        dispatch({
            type: USER_CHANGE_PASSWORD_REQUEST
        })

        const token = localStorage.getItem('token')

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const { data } = await axios.post(
            '/change_password/',
            { 'old_password': oldPassword, 'password': password, 'password2': confirmPassword },
            config

        )

        dispatch({
            type: USER_CHANGE_PASSWORD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_CHANGE_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
