import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import "./stat/insurance_rates.css";
import { ProgressBarLine } from "react-progressbar-line";
import CountUp from "react-countup";

function InsuranceRates(props) {
  const [state, setState] = useState({
    haveInsurance: 20000,
    nothaveInsurance: 10000,
  });
  return (
    <div className="insurance_stat">
      <div className="header" style={{ width: "390px" }}>
        مقارنة أسعار تأمين أخطاء ممارسة المهن الطبية
      </div>
      <div className="chart">
        <div className="nothave_insurance">
          <div
            className="stat_content"
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            <div className="total_number">
              <CountUp
                start={0}
                end={state.nothaveInsurance}
                duration={2}
                separator=","
              />
            </div>
            <ProgressBarLine
              value={state.nothaveInsurance}
              min={0}
              max={30000}
              strokeWidth={5}
              trailWidth={5}
              styles={{
                path: {
                  stroke: "#e64f39",
                },
                trail: {
                  stroke: "#d6d6d6",
                },
                text: {
                  fill: "transparent",
                },
              }}
            />
          </div>
          <div className="info_item">
            <p className="title">
              عدد الموظفين الذين ليس لديهم تأمين ضد أخطاء الممارسة
            </p>
            <span className="list" style={{ background: "#e64f39" }}></span>
          </div>
        </div>

        <div className="have_insurance">
          <div
            className="stat_content"
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            <div className="total_number">
              <CountUp
                start={0}
                end={state.haveInsurance}
                duration={2}
                separator=","
              />
            </div>
            <ProgressBarLine
              value={state.haveInsurance}
              min={0}
              max={30000}
              strokeWidth={5}
              trailWidth={5}
              styles={{
                path: {
                  stroke: "#0fb0a9",
                },
                trail: {
                  stroke: "#d6d6d6",
                },
                text: {
                  fill: "transparent",
                },
              }}
            />
          </div>
          <div className="info_item">
            <p className="title">
              عدد الموظفين الذين لديهم تأمين ضد أخطاء الممارسة
            </p>
            <span className="list"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

function ComoarisonMedicalInsurance({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div style={{ width: "300px" }} className="heading text-center">
              الأحصائيات
            </div>

            <InsuranceRates />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default ComoarisonMedicalInsurance;
