import axios from "axios";

export const DevicesRequest_list = async (user) => {
  const request = await axios.get("/services/userDevicesRequest_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const DevicesOffer_list = async (user) => {
  const request = await axios.get("/services/DevicesOffer_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const ClinicsRequest_list = async (user) => {
  const request = await axios.get("/services/userClinicsRequest_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const ClinicsOffer_list = async (user) => {
  const request = await axios.get("/services/ClinicsOffer_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const LabsRequests_list = async (user) => {
  const request = await axios.get("/services/userLabsRequests_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const LabsOffer_list = async (user) => {
  const request = await axios.get("/services/LabsOffer_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const RadiologyRequestsService_list = async (user) => {
  const request = await axios.get(
    "/services/userRadiologyRequestsService_list",
    {
      headers: { Authorization: `Bearer ${user.access}` },
    }
  );
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const RadiologyOffer_list = async (user) => {
  const request = await axios.get("/services/RadiologyOffer_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const Tender_list = async (user) => {
  const request = await axios.get("/services/userTender_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const CollectingInvoice_list = async (user) => {
  const request = await axios.get("/services/userTenderOffer_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const UpgradeEvaluation_list = async (user) => {
  const request = await axios.get("/services/userInvoice_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const Invoice_list = async (user) => {
  const request = await axios.get("/services/Invoice_list", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};
