import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import rarrow from "../assets/images/right-arrow1.png";
import arrow from "../assets/images/arrow.png";
import "../styles/Manger_ControlPanle.css";
import "../styles/AdminPowerSpecialCases.css";
import AdminLayout from "./layouts/AdminLayout";
import search from "./../imgs/search1.png";
import { Button, Form } from "react-bootstrap";
import {
    fetchSearchData,
    fetchOneFacility,
    fetchFacilityLevels,
    updateAllowedLevels,
} from "./api/powers/MainPowersEndPPoints";
import { useSelector } from "react-redux";
import "./../styles/change_entereddata.css";

const AdminPowerSpecialCases = ({ history }) => {
    const [rate, setRate] = useState("");

    const [theFacility, setTheFacility] = useState({});
    const [levels, setLevels] = useState([]);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const [state, setState] = useState({
        name: "",
        code: "",
        showInfo: false,
        showModal: false,
    });

    const [searchData, setSearchData] = useState([]);

    const [resultFacility, setResultFacility] = useState({});

    const rates = ["VIP", "A", "B", "C", "D"];

    const [chosenLevel, setChosenLevel] = useState({});

    const categories = {
        VIP: "VIP, A, B",
        A: "A, B, C",
        B: "B, C, D",
        C: "C, D",
        D: "D",
    };

    const onClick = () => {
        setState({
            ...state,
            showInfo: true,
        });

        const results = [];
        searchData.forEach((f) => {
            if (f.id == state.code ||
                f.id == state.code.split("-")[0] ||
                f.id == state.code.split("-")[1] ||
                f.client_code == state.code ||
                f.client_code == state.code.split("-")[0] ||
                f.client_code == state.code.split("-")[1] ||
                f.facility_name == state.name ||
                f.facility_name == state.name.split("-")[0] ||
                f.facility_name == state.name.split("-")[1])
                results.push(f);
        });

        if (results.length > 0) {
            setResultFacility(results[0]);
        } else {
            setResultFacility({});
        }

        if (results.length > 0)
            fetchOneFacility(results[0].id, userInfo).then((data) =>
                setTheFacility(data)
            );
    };

    const searchTermChanged = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    const focusHandler = (e) => {
        e.target.parentNode.style.color = "#0fb0a9";
        e.target.style.color = "#0fb0a9";
        e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
        e.target.style.borderColor = "#0fb0a9";
    };

    const focusoutHandler = (e) => {
        e.target.parentNode.style.color = "";
        e.target.style.color = "";
        e.target.style.boxShadow = "";
        e.target.style.borderColor = "";
    };

    const newLevelChange = (id) => {

        setRate(levels.filter((l) => l.level == id)[0]?.level);

        setChosenLevel(levels.filter((l) => l.level == id)[0]);
    };

    useEffect(() => {
        fetchSearchData(userInfo).then((data) => setSearchData(data));
        fetchFacilityLevels(userInfo).then((data) => setLevels(data));
    }, []);

    const onSubmit = () => {
        if (resultFacility && chosenLevel) {
            updateAllowedLevels(
                resultFacility.id,
                chosenLevel.id,
                categories[rate],
                userInfo
            ).then((data) =>
                history.push(`/evaluation-certificate/${resultFacility.id}`)
            );
        }
    };

    const { code, name, showInfo } = state;
    return (
        <AdminLayout>
            <div className="manger_control">
                <div
                    className="special_name stat"
                    style={{ direction: "rtl", height: "1000px" }}
                >
                    <div className="change_entereddata">
                        <div className="item reverse">
                            <div className="d-flex justify-content-center align-items-center"
                                style={{ marginTop: "-10px" }}>
                                <Button className="text-center btnNext" onClick={onClick}>
                                    <img src={arrow} alt="" />
                                    بحث
                                </Button>
                            </div>

                            <div className="input-data">
                                <label htmlFor="name">كود العميل</label>
                                <input
                                    type="text"
                                    id="code"
                                    name="code"
                                    value={code}
                                    onChange={searchTermChanged}
                                    placeholder="اكتب كود العميل"
                                    onFocus={focusHandler}
                                    onBlur={focusoutHandler}
                                />
                                <img src={search} alt="" />
                            </div>

                            <div className="input-data">
                                <label htmlFor="name">اسم الجهة</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={searchTermChanged}
                                    placeholder=" ابحث اسم الجهة"
                                    onFocus={focusHandler}
                                    onBlur={focusoutHandler}
                                />
                                <img src={search} alt="" />
                            </div>
                        </div>

                        {showInfo && (
                            <div
                                className="item reverse"
                                style={{
                                    direction: "rtl",
                                    justifyContent: "flex-start",
                                    marginLeft: "-111px",
                                    marginTop: "66px",
                                    flexDirection: "row",
                                }}
                            >
                                <p className="info_title">اسم الجهة :</p>
                                <div className="info_data">
                                    {resultFacility.facility_name &&
                                        resultFacility.facility_name.length > 0
                                        ? resultFacility.facility_name
                                        : "غير موجود"}
                                </div>
                                <p className="info_title"> كود العميل :</p>
                                <div className="info_data">
                                    {resultFacility.client_code &&
                                        resultFacility.client_code.length > 0
                                        ? resultFacility.client_code
                                        : "غير موجود"}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="heading">الحالات الاستثنائية</div>
                    {showInfo && (
                        <>
                            <div
                                className="rate_info"
                                style={{
                                    flexDirection: "row-reverse",
                                    justifyContent: "space-between",
                                    padding: "20px",
                                }}
                            >
                                <p
                                    className="rate_hint"
                                    style={{
                                        fontSize: "100%",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        marginRight: "0px",
                                    }}
                                >
                                    <span
                                        style={{
                                            marginBottom: "8px",
                                            display: "inline-block",
                                            width: "100%",
                                        }}
                                    >
                                        حصلت على تقييم
                                    </span>
                                    <span className="rate">
                                        {theFacility.facility_level
                                            ? levels.filter(
                                                (l) => l.id == theFacility.facility_level
                                            )[0].level
                                            : "None"}
                                    </span>
                                </p>

                                <p className="rate_title" style={{ width: "100%" }}>
                                    التقييم الاصلي
                                </p>
                            </div>
                            <div className="input-lable" style={{ width: "50%" }}>
                                <label htmlFor="name" style={{ marginBottom: "20px" }}>
                                    التقييم الجديد
                                </label>

                                <Form.Select style={{ paddingRight: "30px" }} onChange={(e) => {
                                    newLevelChange(e.target.value);
                                }}>
                                    <option>أختر</option>
                                    {levels.reverse().map((option) => (
                                        <option
                                            key={option.id}
                                            data-value={option.id}
                                            onClick={(e) => {
                                                newLevelChange(option);
                                            }}
                                        >
                                            {" "}
                                            {option.level}{" "}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                            <div
                                className="rate_info"
                                style={{
                                    flexDirection: "row-reverse",
                                    justifyContent: "space-between",
                                    padding: "20px",
                                }}
                            >
                                <p className="rate">{categories[rate]}</p>
                                <p className="rate_title">الفئة الجديدة</p>
                            </div>
                            <p className="sub_title2">
                                سيتم توجيه نسخة على البريد الالكتروني التابع للمنشاة من قبل
                                إدارة المنصة
                            </p>
                            <div className="group_btn">
                                {/* <Link to="/evaluation-certificate">
                            <button className="control-btn">
                                <img className="icon" src={arrow} alt="" />
                                تنفيذ
                            </button>
                            </Link> */}
                                <Button className="btnNext" onClick={onSubmit}>
                                    <img className="icon" src={arrow} alt="" />
                                    تنفيذ
                                </Button>
                            </div>
                        </>
                    )}
                    </div>
            </div>
        </AdminLayout>
    );
};

export default AdminPowerSpecialCases;
