import React, { Component } from "react";
import "../styles/confirm_update.css";
import ControlPanelHeader from "./ControlPanelHeader";
import confirm from "../imgs/checked.png";
import arrow from "../imgs/Group 224.png";
import { Link } from "react-router-dom";
class Confirm_RequestInsurance extends Component {
  render() {
    return (
      <div>
        <ControlPanelHeader
            exit1={true}
            notifi={true}
            msg={true}
            files={false}
            stat={false}
            msgDest={"/Messages"}
            notifidest={"/Notifications"}
            exitDest={"/services"}
            fileDest={"/FacilityFiles"}
            statDest = {'/collectServices'}
            style={"threetwo_section"}
        />
        <div className="confirm-content text-center">
          <div
            className="confirm-update"
            style={{ height: "416px", margin: "110px auto 80px" }}
          >
            <img
              className="confirm"
              src={confirm}
              alt=""
              style={{ marginTop: "36px" }}
            />
            <p className="confirm-title">تم تنفيذ الطلب </p>
            <p className="hint">نشكركم على إرسال المعلومات, ستقوم إدارة بيان بمراجعة المعلومات والملفات المرفقة ومن ثم إرسالها إلى شركة التأمين وسيصلكم إشعار لاحقا منهم</p>
          </div>

          <Link to="/comparingMedicalMistakeWork">
            <button className="back-btn">
              <img src={arrow} alt="" />
              الرجوع إلى القائمة 
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Confirm_RequestInsurance;
