import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ConfigContext } from "../../../../../contexts/ConfigContext";
import * as actionType from "../../../../../constants/adminConstants";
import { useSelector } from "react-redux";

const NavLogo = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;

  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const title = userInfo.user_type == 'employee' ? 'لوحة تحكم موظفين منصة بيان' : 'لوحة تحكم مدراء منصة بيان';

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo">
        <Link to="#" className="b-brand">
          {/* <div className="b-bg">
            <i className="feather icon-trending-up" />
          </div> */}
          <span className="b-title">{title}</span>
        </Link>
        <Link
          to="#"
          className={toggleClass.join(" ")}
          id="mobile-collapse"
          onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}
        >
          <span />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
