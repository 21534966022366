import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import "react-datepicker/dist/react-datepicker.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import "../../styles/add_request_laboratories.css";
import { addNotification } from '../../actions/notificationActions'
import { updateInsurrenceOffer } from '../../actions/serviceActions'



function AttachLinkInsuranceCompScreen({ history, match }) {

  const teamId = match.params.id

  const [link, setLink] = useState('')

  const [errors] = useState({
    allFieldRequired: "الرجاء ملئ كل البيانات  ",
  })

  const [errorsFlags, setErrorsFlags] = useState([false])

  const dispatch = useDispatch()

  const notificationAdd
    = useSelector(state => state.notificationAdd)

  const { error, notification, success } = notificationAdd

  const insurrenceOfferUpdate
    = useSelector(state => state.insurrenceOfferUpdate)

  const { error: errorUpdate, insurrenceOffer, success: successupdate } = insurrenceOfferUpdate

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    } else {
      if (!userInfo.is_activated) {
        history.push('/services')
      }
    }

  }, [dispatch, history, userInfo])

  const focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  const focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  const formValidation = () => {
    let isValid = true;
    const flags = [false];

    const nodes = document.querySelectorAll("input");
    nodes.forEach((e) => {
      if (e.value === '') {
        console.log(e)
        flags[0] = true;
        isValid = false;
      }

    });

    setErrorsFlags(flags);
    return isValid;
  };

  const onSubmit = (e, link) => {
    e.preventDefault()
    const formvalid = formValidation();
    if (formvalid) {

      let data = new FormData();
      data.append("medical_insurance_request", teamId);
      data.append("offer_status", 'S');
      data.append("approved", false);
      data.append("sending_payment_link", true);
      data.append("link", link);

      dispatch(updateInsurrenceOffer(data))

      history.push("/ConfirmSentOfferInsuranceComp");
    }
  }

  return (
    <div className="add_request">
      <ControlPanelHeader
        exit1={true}
        files={false}
        notifi={true}
        msg={true}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        style={"three_section"}
      />
      <form onSubmit={(e) => onSubmit(e, link)}>
        <div className="add_request_content" style={{ height: '335px' }}>
          <div className="heading text-center" style={{ width: '670px' }}>ارفق رابط الدفع</div>

          <div className="input_group">
            <div className="input-lable" style={{ marginTop: '70px' }}>
              <label htmlFor="mailBox">ارفق الرابط</label>
              <input
                type="text"
                id="link"
                name="link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="فضلا ضع الرابط هنا لإتمام عملية الدفع"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>
            {errorsFlags[0] && <div className='error'><img src={error} />{errors.allFieldRequired}</div>}
          </div>
          <button type='submit' className="enter_service my-2">
            <img className='icon' src={arrow} alt="" />
            إرسال
          </button>
        </div>
      </form>
    </div>
  )
}

export default AttachLinkInsuranceCompScreen
