import { Table } from "react-bootstrap";
function InvoiceTable({ title, data, type, className }) {
  function Jdata() {
    let rows = [];
    let numrows = Object.values(data).length / title.length;
    console.log(numrows);
    if (type == 4) {
      numrows = Object.values(data).length;
      for (let i = 0; i < numrows; i += 3) {
        rows.push(<tr key={i}>{Jcells(i)}</tr>);
      }
    } else {
      for (let i = 0; i < numrows; i++) {
        rows.push(<tr key={i}>{Jcells(i)}</tr>);
      }
    }
    return rows;
  }
  function Jcells(i) {
    let cells = [];
    let numcells = title.length;
    if (type == 4) {
      for (let j = 0; j < numcells; j++) {
        cells.push(<td className="td grayBG">{Object.keys(data)[i]}</td>);
        cells.push(<td className="td table_small">{Object.values(data)[i]}</td>);
        cells.push(<td className="td grayBG">{Object.keys(data)[i + 1]}</td>);
        cells.push(<td className="td table_small">{Object.values(data)[i + 1]}</td>);
        cells.push(<td className="td grayBG">{Object.keys(data)[i + 2]}</td>);
        cells.push(<td className="td table_small">{Object.values(data)[i + 2]}</td>);
      }
    } else {
      for (let j = 0; j < numcells; j++) {
        if (type == 1) {
          cells.push(
            <td className="td grayBG">
              {Object.keys(data)[i]} : {Object.values(data)[i]}
            </td>
          );
        } else if (type == 2) {
          cells.push(<td className="td grayBG">{Object.values(data)[j]}</td>);
        } else if (type == 3) {
          cells.push(<td className="td grayBG">{Object.keys(data)[i]}</td>);
          cells.push(<td className="td grayBG">{Object.values(data)[i]}</td>);
        }
      }
    }

    return cells;
  }
  function Jtitle() {
    return title.map((key) => {
      return (
        <th
          className={type == 4 ? "text-center" : ""}
          colSpan={type == 3 ? "2" : type == 4 ? "6" : ""}
        >
          {key}
        </th>
      );
    });
  }

  return (
    <Table borderless className={className ? className : ""}>
      <thead className="thead">
        <tr>{Jtitle()}</tr>
      </thead>
      <tbody>{Jdata()}</tbody>
    </Table>
  );
}
export default InvoiceTable;
