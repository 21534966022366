import { Row, Col, ToggleButton, Card, Form, Table } from "react-bootstrap";

function NewCard({
  img,
  title,
  price,
  weight,
  descreption,
  bigImage,
  radioValue,
  radioValueN,
  handleButtons,
  handleButtonsN,
  handleImages,
}) {
  return (
    <Card>
      <Row>
        <Col
          lg="12"
          className="border-start text-end new_card"
          style={{ margin: 0, padding: 0 }}
        >
          <Card.Body>
            <Card.Title>
              <h4 class="price text-small" style={{ fontSize: "1.1rem" }}>
                العنوان: {title}
              </h4>
            </Card.Title>
            {/* <div class="">
              <h4 class="price">
                السعر: {price}
                <span class="text-muted"> ريال </span>
                الوزن: {weight}
                <span class="text-muted"> كغ</span>
              </h4>
            </div> */}
            <p class="new_descreption" style={{ height: "200px" }}>
              {descreption}
            </p>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}
export default NewCard;
