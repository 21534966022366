import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../styles/create_account.css";
import Message from '../component/Message'
import { changePassword } from '../actions/userActions'
import LoginName from "../component/LoginName";
import "../styles/change_password.css";



function ChangePasswordScreen({ location, history }) {
    const [oldPassword, setOldPassword] = useState('')

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [errors] = useState({
        passwordLen: "كلمة المرور يجب ان لا تقل عن 8 احرف/ارقام ",
        passwordConfirm : "غير متطابقة لكلمة المرور الجديدة",
    })

    const [errorsFlags, setErrorsFlags] = useState([ false, false, false])
    
    const dispatch = useDispatch()

    const token = location.search ? location.search.split('=')[1] : '/'

    localStorage.setItem('token',  token);


    const userChangePassword = useSelector(state => state.userChangePassword)

    const { error, userInfo } = userChangePassword

    useEffect(() => {
        if(userInfo) {
            if (!userInfo.is_registered) {
                history.push("/confirmChangePassword")
            } else {
                history.push("/services")
            }
        }
        
    }, [history, userInfo, token])

    const formValidation = () => {
        let isValid = true;
        const flags = [false, false, false];
        
        if (oldPassword.trim().length < 8) {
            flags[0] = true;
            isValid = false;
        } else {
            flags[0] = false;
        }
        if (password.trim().length < 8) {
            flags[1] = true;
            isValid = false;
        } else {
            flags[1] = false;
        }
        if (!(confirmPassword === password)) {
            flags[2] = true;
      
            isValid = false;
        } else {
            flags[2] = false;
        }
    
        setErrorsFlags(flags);
        return isValid;
    };

    const submitHandler = (e) => {
        e.preventDefault()
        const isValid = formValidation();
        if (isValid) {
            dispatch(changePassword(oldPassword, password, confirmPassword))
        }
        
    }

    return (
        <div className="change_password_page">
            <div className="login text-center">
                <div className="title">تغيير كلمة المرور</div>
                <form onSubmit={submitHandler}>
                    <input
                    className="commercial-No"
                    type="password"
                    name="oldPassword"
                    value={oldPassword}
                    placeholder="كلمة المرور المؤقتة"
                    onChange= {(e) => setOldPassword(e.target.value)}
                    />
                    {errorsFlags[0] && <div className='error'>{errors.passwordLen}</div> }
                    <div className="password-info">
                        <input
                        className="password"
                        type="password"
                        name="password"
                        value={password}
                        placeholder="كلمة المرور الجديدة"
                        onChange= {(e) => setPassword(e.target.value)}
                        />
                        {errorsFlags[1] && <div className='error'>{errors.passwordLen}</div> }
                        <input
                        className="password"
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        placeholder="تأكيد كلمة المرور الجديدة"
                        onChange= {(e) => setConfirmPassword(e.target.value)}
                        />
                        {errorsFlags[2] && <div className='error'> {errors.passwordConfirm}</div> }
                    </div>
                    <button className="login-btn">حفظ</button>{" "}
                    { error && <Message variant='danger'>{error}</Message>}
                </form>
            </div>
            <LoginName Home={true} Facility={false} />
        </div>
    )
}

export default ChangePasswordScreen
