import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import InvoiceTable from "./InvoiceTable";
import "./last_invoice.css"
import { Container, Button, Row, Col, Table } from "react-bootstrap";
import QRCode from "react-qr-code";
import Hdate from "./Hdate";
import NewCard from "./newCard";
import logo from "./logo.svg";
import social1 from "./social1.svg";
import social2 from "./social2.svg";
import social3 from "./social3.svg";
import social4 from "./social4.svg";
import { fetchInvoices } from "../api/invoicesApi";
import sign from "./ahmed.png";



function NewOrder() {
  const params = useParams();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [offer, setOffer] = useState({});
  const [info, setInfo] = useState({});
  const [fac_info, set_facInfo] = useState({});
  const [img, setImage] = useState([]);

  useEffect(() => {
    fetchInvoices(userInfo).then((data) => {
      const theOffer = data.filter((d) => d.id == params.id)[0];
      // const isClinic = theOffer.request_type.includes("العيادات");
      setOffer(theOffer);
      const ourInfo = {
        // القطاع: theOffer.facility.sector.sector_name,
        // "نوع القطاع": theOffer.facility.sector_type.type_name,
        // "نوع العرض": theOffer.request_type,
        // "سنوات خبرة الطبيب": theOffer.experience_years,
        // //" اسم الطبيب او الفني": theOffer.doctor_name,
        // "عدد المرضي المعاجين": theOffer.num_patients,
        // "مدة التاجير": `${theOffer.request_duration} يوم`,
        // " قيمة التأجير بالريال": theOffer.price,
        // "طريقة الدفع": theOffer.payment_method,
        // "المدة بالآجل": theOffer.pay_days,
        // "وقت التأجير": theOffer.receive_date,
        // "عدد العمليات اذا جراح ": theOffer.doctor_num_operations,
      };
      // if (isClinic) {
      //   ourInfo["اسم العيادة"] = theOffer.doctor_name;
      // } else {
      //   ourInfo["اسم الطبيب او الفني"] = theOffer.doctor_name;
      // }
      setInfo(ourInfo);
      set_facInfo({
        "كود المنشأة": theOffer.client_code,
      });
      const i = [];
      if (theOffer.doctor_profile > 0) i.push(theOffer.doctor_profile);
      if (theOffer.clinic_image > 0) i.push(theOffer.clinic_image);
      setImage(i);
    });
  }, []);
  const [radioValue, setRadioValue] = useState("sell");
  const [radioValueN, setRadioValueN] = useState("new");
  const [bigImage, setBigImage] = useState(img[0]);

  let title1 = ["بيانات مقدم العرض"];
  let descreption =
    "  وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية";

  let data1 = {
    الاسم: "أحمد",
    العمر: 29,
    الجنس: "ذكر",
    البلد: "السعودية",
    الاسم1: "أحمد",
    العمر1: 29,
    الجنس1: "ذكر",
    البلد1: "السعودية",
    الاسم2: "أحمد",
    العمر2: 29,
    الجنس2: "ذكر",
    البلد2: "السعودية",
  };
  function handleClick() {
    window.print();
  }

  return (
    <div className="w-100" style={{ direction: "rtl" }}>
      {offer != null ? (
        <>
          <Container className="inv_a4 screen">
            <Button
              onClick={() => handleClick()}
              className="print_hide m-4 inv_button-print bg-secondary"
            >
              طباعة
            </Button>
            <div className="header_c" />
            <h1 className="inv_h1">فاتورة {offer.order_num}</h1>
            <img className="inv_img" alt="logo" src={logo} />
            <Hdate
              className="inv_Hdate_table"
              date={`${new Date(offer.created_at).getDate()}/${new Date(
                offer.created_at
              ).getMonth()}/${new Date(offer.created_at).getFullYear()}`}
              code={offer.id}
              codeTitle="كود العميل:"
            />
            <p className="inv_QR_title">رمز الفاتورة:</p>
            <QRCode className="inv_QR" value={window.location.href} size="75" />
            {offer.facility && (
              <div className="inv_data mt-3">
                <p>الخدمة:</p>
                <p>{offer.service}</p>
                <p></p>
              </div>
            )}

            <Row style={{ marginTop: "305px" }}>
              <Col lg="12" className="p-0">
                <Table borderless>
                  <thead className="thead">
                    <tr>
                      <th className="text-center" colSpan="6" style={{fontSize: "21px"}}>
                      البيانات  
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td grayBG">نوع القطاع</td>
                      <td className="td table_small">{ offer.sector }</td>
                      <td className="td grayBG">يحتاج للشحن </td>
                      <td className="td table_small">{offer.shipping_required ? "لا" : "نعم"}</td>
                    </tr>

                    <tr>
                      <td className="td grayBG">نوع الدفع</td>
                      <td className="td table_small">{offer.direct_payment ? "دفع مباشر" : "آجل"}</td>
                      {!offer.direct_payment && 
                        (
                        <td className="td grayBG">المدة </td>
                        )}

                        {!offer.direct_payment && 
                        (
                        <td className="td table_small">{offer.number_of_days}</td>
                        )}
                      
                    </tr>

                    <tr>
                      <td className="td grayBG">الحالة</td>
                      <td className="td table_small">{offer.bayan_interest === 0 ? "عمولة بيان" : "فاتور عميل"}</td>                      
                    </tr>
                  
                    
                    
                  </tbody>
                </Table>
              </Col>
          </Row>

            
            <Row>


              <div className="page-content container" dir="rtl" style={{marginTop: "20px"}}>
                  <div className="container px-0">
                      <div className="row mt-4">
                          <div className="col-12 col-lg-12">
                              
                              

                              <div className="mt-4">
                                  <div className="row text-600 text-white py-25" style={{ backgroundColor: "#1bb1aa" }}>
                                      <div className="col-9 col-sm-9">التفاصيل</div>
                                      <div className="col-2">المبلغ</div>
                                  </div>

                                  <div className="text-95 text-secondary-d3">
                                      <div className="row mb-2 mb-sm-0 py-25">
                                          <div className="d-none d-sm-block col-9">{offer.details}</div>
                                          <div className="col-2 text-secondary-d2">{offer.service_cost} ريال</div>
                                      </div>

                                      <div className="row mb-2 mb-sm-0 py-25">
                                          <div className="d-none d-sm-block col-9" style={{ direction: 'ltr'}}> (15%) قيمة ضريبة القيمة المضافة</div>
                                          <div className="col-2 text-secondary-d2">  {offer.vat} ريال</div>
                                      </div>

                                      <div className="row mb-2 mb-sm-0 py-25">
                                          <div className="d-none d-sm-block col-9"  style={{ direction: 'ltr'}} >اجمالي الفاتورة شاملة الضريبة</div>
                                          <div className="col-2 text-secondary-d2"> {offer.total} ريال</div>
                                      </div>

                                      

                                      

                                      
                                  </div>

                                  <hr/>
                       
                                                                  
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
            </Row>

            <div className="inv_terms" style={{ marginTop: "210px", padding: "37px" }}>
              <p className="inv_terms_h">سياسة الإرجاع والاأستبدال:</p>
              <p className="inv_terms_p">
              من اجل الوصول الى سياسة الارجاع والتبديل, يمكنكم زيارة قسم خدمة
              العملاء او يمكنكم تصفحها على
              (http://www.extrastores.com/ar-sa/returnexchange) الالكتروني
              موقعنا شكرا للتسوق فى اكسترا, لمزيد من المعلومات عن شروط مبيعات
              المنتجات, والخدمات ... http://www.extrastores.com زيارة نرج
              </p>
              <p className="inv_terms_h mt-4">الشروط و الأحكام للأجهزه المستخدمه:</p>
              <p className="inv_terms_p">
              24 ساعه للارجاع. لايوجد استبدال, فتره الضمان 30 يوم (ضمان أكسترا),
              فى حاله عدم التمكن من اصلاح الجهاز خلال فتره الضمان, سيتم ارجاع
              المبلغ المدفوع بالكامل ولن يتم الإستبدال.
              </p>
            </div>
            <div className="inv_sign_l" style={{ marginTop: "210px" }}>
              <h3 className="inv_h3_sign">معتمدة من قبل الرئيس التنفيذي</h3>
              <h2 className="inv_h2_sign">أحمد محمد السلمي</h2>
              <img className="inv_img_sign" style={{ width: "151px" }} alt="sign" src={sign} />
            </div>

            <div className="inv_footer">
              <span className="footer_text">الإيميل : info@bayan.med.sa</span>
              <div className="social_neworder_div">
                <a href="http://www.bayan.med.sa/">
                  <img className="social_neworder" alt="social" src={social4} />
                </a>
                <a href="http://www.bayan.med.sa/">
                  <img className="social_neworder" alt="social" src={social3} />
                </a>
                <a href="http://www.bayan.med.sa/">
                  <img className="social_neworder" alt="social" src={social2} />
                </a>
                <a href="http://www.bayan.med.sa/">
                  <img className="social_neworder" alt="social" src={social1} />
                </a>
              </div>
            </div>
          </Container>
          <page size="A4" className="print">
            <div className="header_c" />
            <h1 className="inv_h1">إضافة طلب</h1>
            <img className="inv_img" alt="logo" src={logo} />
            <Hdate
              className="inv_Hdate_table"
              date="22/5/2021"
              code="25G54"
              codeTitle="كود العرض:"
            />
            <p className="inv_QR_title">كود التسجيل:</p>
            <QRCode className="inv_QR" value={window.location.href} size="75" />
            <div className="inv_data">
              <p>بيانات الشركة</p>
              <p>بيانات الشركة</p>
              <p>بيانات الشركة</p>
            </div>
            <div className="inv_table">
              <Row>
                <Col lg="12">
                  {info && (
                    <InvoiceTable
                      className="inv_table_from"
                      title={title1}
                      data={info}
                      type="4"
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg="12" className="mt-4">
                  <NewCard
                    img={img}
                    title="العنوان"
                    descreption={descreption}
                    price="800"
                    weight="50"
                    radioValue={radioValue}
                    radioValueN={radioValueN}
                    bigImage={bigImage}
                    handleButtons={(e) => setRadioValue(e)}
                    handleButtonsN={(e) => setRadioValueN(e)}
                    handleImages={(e) => setBigImage(e)}
                  />
                </Col>
              </Row>
              <div className="new_boxs">
                <Row>
                  <Col lg="6">
                    <p className="new_box_h">
                      ملاحظة موظف العمليات بعد المراجعة:
                    </p>
                    <div className="new_box"></div>
                  </Col>
                  <Col lg="6">
                    <p className="new_box_h">
                      ملاحظة مدير العمليات بعد المراجعة:
                    </p>
                    <div className="new_box"></div>
                  </Col>
                </Row>
                <Row className="new_sign">
                  <Col lg="1">التوقيع:</Col>
                  <Col lg="5">
                    <div className="border-bottom mt-4" />
                  </Col>
                  <Col lg="1">التوقيع:</Col>
                  <Col lg="5">
                    <div className="border-bottom mt-4" />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="inv_footer">
              <span className="footer_text">الإيميل : info@bayan.med.sa</span>
              <div className="social_neworder_div">
                <a href="http://www.bayan.med.sa/">
                  <img className="social_neworder" alt="social" src={social4} />
                </a>
                <a href="http://www.bayan.med.sa/">
                  <img className="social_neworder" alt="social" src={social3} />
                </a>
                <a href="http://www.bayan.med.sa/">
                  <img className="social_neworder" alt="social" src={social2} />
                </a>
                <a href="http://www.bayan.med.sa/">
                  <img className="social_neworder" alt="social" src={social1} />
                </a>
              </div>
            </div>
          </page>
        </>
      ) : null}
    </div>
  );
}
export default NewOrder;
