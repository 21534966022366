import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/add_evaluationdata.css";
import arrow from "../../imgs/arrow.png";
import { Link } from "react-router-dom";
import rarrow from "../../imgs/right-arrow.png";
import date from "../../imgs/date.png";
import DatePicker from "react-datepicker";
import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import Select from 'react-select'

import { getEvaluationDetails } from '../../actions/serviceActions'

import { Row, Col } from 'react-bootstrap'


function EvaluationDataDetailsScreen({ history }) {

	const [IsDisplayed, setIsDisplayed] = useState(false)

	const [palceHolder, setPalceHolder] = useState(true)

	const dispatch = useDispatch()

	const evaluationDetails
		= useSelector(state => state.evaluationDetails)

	const { evaluationInfo } = evaluationDetails

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			if (!userInfo.is_activated || !userInfo.is_evaluated) {
				history.push('/services')
			} else {
				dispatch(getEvaluationDetails())
			}
		}

	}, [dispatch, history, userInfo])

	const changeSelect = (title) => {
		setIsDisplayed(false)
		setPalceHolder(false)
	};


	const selectClick = () => {
		setIsDisplayed(IsDisplayed === true ? false : true);
	};

	const focusHandler = (e) => {
		e.target.parentNode.style.color = "#0fb0a9";
		e.target.style.color = "#0fb0a9";
		e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
		e.target.style.borderColor = "#0fb0a9";
	};

	const focusoutHandler = (e) => {
		e.target.parentNode.style.color = "";
		e.target.style.color = "";
		e.target.style.boxShadow = "";
		e.target.style.borderColor = "";
	};




	return (
		<div>
			<ControlPanelHeader
				exit1={true}
				notifi={true}
				msg={true}
				files={false}
				stat={false}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				exitDest={"/services"}
				fileDest={"/FacilityFiles"}
				statDest={'/collectServices'}
				style={"three_section"}
			/>
			<div className="data-form">
				<div className="desc-title text-center">إضافة بيانات التقييم</div>
				<div className="sub-title">فضلا اكمل البيانات الخاصه بالمنشأة</div>
				<form>
					<div className="name-form">
						<div className="input-lable">
							<label htmlFor="name">مساحة المنشاة</label>
							<input
								type="number"
								id="facility_Area"
								name="facility_Area"
								value={evaluationInfo?.facility_area}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
								readonly
							/>
						</div>
						<div className="input-lable">
							<label htmlFor="mailBox">مكان المنشأة بالمنطقة</label>
							<input
								type="text"
								id="facility_region"
								name="facility_region"
								value={evaluationInfo?.facility_state}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
								readonly
							/>
						</div>
						<div className="input-lable">
							<label htmlFor="postalCode">مكان المنشاة بالمدينة</label>
							<input
								type="text"
								id="facility_Location"
								name="facility_Location"
								value={evaluationInfo?.facility_city}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
								readonly
							/>
						</div>
						<div className="input-lable">
							<label htmlFor="postalCode">عدد الغرف الغير خاصة</label>
							<input
								type="number"
								id="nonSpecial_Room"
								name="nonSpecial_Room"
								value={evaluationInfo?.num_rooms}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
								readonly
							/>
						</div>
						<div className="input-lable">
							<label htmlFor="postalCode">عدد الكادر الإداري للمنشأة</label>
							<input
								type="number"
								id="administrative_StaffNo"
								name="administrative_StaffNo"
								value={evaluationInfo?.num_admin_staff}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
								readonly
							/>
						</div>

						<div className="radio_input">
							<p> هل يوجد غرف خاصة</p>
							<div className="radio_group">
								<label>
									<input
										type="radio"
										name="special_room"
										value="نعم"
										checked={evaluationInfo?.num_private_rooms !== 0}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="special_room"
										checked={evaluationInfo?.num_private_rooms === 0}
									/>
									لا
								</label>
							</div>
						</div>

						<div className="input-lable">
							<label htmlFor="postalCode">عدد الغرف الخاصة</label>
							<input
								type="number"
								id="speical_RoomNo"
								name="speical_RoomNo"
								value={evaluationInfo?.num_pharmacies}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
								readonly
							/>
						</div>
						<div className="input-lable">
							<label htmlFor="postalCode">عدد غرف العمليات</label>
							<input
								type="number"
								id="operation_RoomNo"
								name="operation_RoomNo"
								value={evaluationInfo?.num_operations_rooms}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
								readonly
							/>
						</div>
						<div className="radio_input">
							<p> هل الأجهزة الطبية للمنشأة جديدة</p>
							<div className="radio_group">
								<label>
									<input
										type="radio"
										name="devices_Status"
										value="نعم"
										checked={evaluationInfo?.is_medical_devices_new === true}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="devices_Status"
										checked={evaluationInfo?.is_medical_devices_new === false}
									/>
									لا
								</label>
							</div>
						</div>

						<div className="radio_input">
							<p> هل يوجد صيدليات</p>
							<div className="radio_group">
								<label>
									<input
										type="radio"
										name="pharmacies"
										value="نعم"
										checked={evaluationInfo?.num_pharmacies !== 0}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="pharmacies"
										checked={evaluationInfo?.num_pharmacies === 0}
									/>
									لا
								</label>
								<input
									type="number"
									id="pharmacies_no"
									name="pharmacies_no"
									value={evaluationInfo?.num_pharmacies}
									onFocus={focusHandler}
									onBlur={focusoutHandler}
									className="radioInput"
								/>
							</div>
						</div>

						<div className="radio_input">
							<p> هل لديكم فرع</p>
							<div className="radio_group">
								<label>
									<input
										type="radio"
										name="branch"
										value="نعم"
										checked={evaluationInfo?.num_branches !== 0}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="branch"
										checked={evaluationInfo?.num_branches === 0}
									/>
									لا
								</label>
								<input
									type="number"
									id="branches_no"
									name="branches_no"
									value={evaluationInfo?.num_branches}
									onFocus={focusHandler}
									onBlur={focusoutHandler}
									className="radioInput"
								/>
							</div>
						</div>

						<div className="input-lable">
							<label htmlFor="postalCode">عدد الممرضات</label>
							<input
								type="number"
								id="nurses_No"
								name="nurses_No"
								value={evaluationInfo?.num_of_nurses}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>

						<div className="input-lable">
							<label htmlFor="postalCode">عدد الاسرة</label>
							<input
								type="number"
								id="bedsNo"
								name="bedsNo"
								value={evaluationInfo?.num_of_beds}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>
						<div className="input-lable">
							<label htmlFor="postalCode">عدد العيادات</label>
							<input
								type="number"
								id="clinics_No"
								name="clinics_No"
								value={evaluationInfo?.num_of_clinics}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>

						<div className="input-lable">
							<label htmlFor="postalCode">عدد الاطباء</label>
							<input
								type="number"
								id="doctor_No"
								name="doctor_No"
								value={evaluationInfo?.num_of_doctors}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>

						<div className="radio_input">
							<p> هل يوجد طوارئ</p>
							<div className="radio_group">
								<label>
									<input
										type="radio"
										name="emergency"
										value="نعم"
										checked={evaluationInfo?.is_emergency === true}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="emergency"
										checked={evaluationInfo?.is_emergency === false}
									/>
									لا
								</label>
							</div>
						</div>

						<div className="radio_input">
							<p> هل يوجد اشعة</p>
							<div className="radio_group">
								<label>
									<input
										type="radio"
										name="ray"
										value="نعم"
										checked={evaluationInfo?.is_rays === true}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="ray"
										checked={evaluationInfo?.is_rays === false}
									/>
									لا
								</label>
							</div>
						</div>

						<div className="radio_input">
							<p> هل يوجد مختبر</p>
							<div className="radio_group">
								<label>
									<input
										type="radio"
										name="lab"
										value="نعم"
										checked={evaluationInfo?.is_lab === true}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="lab"
										checked={evaluationInfo?.is_lab === false}
									/>
									لا
								</label>
							</div>
						</div>

						<div className="radio_input">
							<p> هل يوجد مواقف للمرضى</p>
							<div className="radio_group">
								<label>
									<input
										type="radio"
										name="parking_forPatients"
										value="نعم"
										checked={evaluationInfo?.num_of_parking_spots !== 0}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="parking_forPatients"
										checked={evaluationInfo?.num_of_parking_spots === 0}
									/>
									لا
								</label>
							</div>
						</div>

						<div className="input-lable">
							<label htmlFor="postalCode">عدد الكادر الفني للاشعة</label>
							<input
								type="number"
								id="ray_StaffNo"
								name="ray_StaffNo"
								value={evaluationInfo?.num_of_technical_staff_rays}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>

						<div className="input-lable">
							<label htmlFor="postalCode">عدد الكادر الفني للمختبر</label>
							<input
								type="number"
								id="lab_StaffNo"
								name="lab_StaffNo"
								value={evaluationInfo?.num_of_technical_staff_lab}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>

						<div className="input-lable">
							<label htmlFor="postalCode">عدد مواقف المرضى</label>
							<input
								type="number"
								id="parking_forPatients_No"
								name="parking_forPatients_No"
								value={evaluationInfo?.num_of_parking_spots}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>

						<div className="input-lable">
							<label htmlFor="postalCode">مساحة الموقف</label>
							<input
								type="text"
								id="parking_Area"
								name="parking_Area"
								value={evaluationInfo?.parking_lot_area}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>

						<div className="input-lable" style={{ width: "1234px", height: "121px" }}>
							<label htmlFor="date1">نوع العيادة</label>

							<textarea
								style={{ paddingTop: "10px" }}
								type="text"
								id="notes"
								name="notes"
								value={evaluationInfo?.clinic_type}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>

						</div>

						<div
							className="input-lable"
							style={{ width: "1234px", height: "121px" }}
						>
							<label htmlFor="analysisDetails">ملاحظات</label>
							<textarea
								style={{ paddingTop: "10px" }}
								type="text"
								id="notes"
								name="notes"
								value={evaluationInfo?.notes}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export default EvaluationDataDetailsScreen
