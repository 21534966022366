import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import CountUp from "react-countup";
import { Pie } from "@ant-design/charts";
import "./stat/Doctor_Clincs.css";

function Doctor_Clincs_Stat(props) {
  const [data1, setData1] = useState([
    {
      type: "عدد الأطباء المرفوضين سنويًا",
      value: 27000,
    },
    {
      type: "عدد الأطباء المرفوضين شهريًا",
      value: 2500,
    },
  ]);
  const [data2, setData2] = useState([
    {
      type: "عدد خدمات العيادة المرفوضة سنويًا",
      value: 25000,
    },
    {
      type: "عدد خدمات العيادة المرفوضة شهريًا",
      value: 6000,
    },
  ]);
  const [data3, setData3] = useState([
    {
      type: "عدد خدمات العيادة المؤجرة سنويًا",
      value: 22000,
    },
    {
      type: "عدد خدمات العيادة المؤجرة شهريًا",
      value: 5000,
    },
  ]);
  const [data4, setData4] = useState([
    {
      type: "عدد الأطباء المستأجرين سنويًا",
      value: 27000,
    },
    {
      type: "عدد خدمات العيادة المستأجرين شهريًا",
      value: 2500,
    },
  ]);
  const [config1, setConfig1] = useState({
    appendPadding: 10,
    data: data1,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    legend: false,
    color: ["#0fb0a9", "#e64f39"],
    interactions: [{ type: "element-active" }],
  });
  const [config2, setConfig2] = useState({
    appendPadding: 10,
    data: data2,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    legend: false,
    color: ["#0fb0a9", "#e64f39"],
    interactions: [{ type: "element-active" }],
  });
  const [config3, setConfig3] = useState({
    appendPadding: 10,
    data: data3,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    legend: false,
    color: ["#0fb0a9", "#e64f39"],
    interactions: [{ type: "element-active" }],
  });
  const [config4, setConfig4] = useState({
    appendPadding: 10,
    data: data4,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    legend: false,
    color: ["#0fb0a9", "#e64f39"],
    interactions: [{ type: "element-active" }],
  });

  return (
    <div className="docotor_stat">
      <div className="header">تأجير خدمات الأطباء او العيادات</div>

      <div className="chart">
        <div className="stat_item">
          <div className="chart_item">
            <Pie {...config1} />
          </div>
          <div className="stat_info">
            <div className="monthly">
              <div className="monthly_info">
                <p className="type_title">شهري</p>
                <span className="list" style={{ background: "#e64f39" }}></span>
              </div>
              <div className="total_number">
                <CountUp start={0} end={2500} duration={2} separator="," />
              </div>
            </div>
            <div className="yearly">
              <div className="yearly_info">
                <p className="type_title">سنوي</p>
                <span className="list" style={{ background: "#0fb0a9" }}></span>
              </div>
              <div className="total_number">
                <CountUp start={0} end={27000} duration={2} separator="," />
              </div>
            </div>
          </div>

          <div className="info_item">
            <p className="title">عدد الأطباء المرفوضين شهريًا وسنويًا</p>
            <span className="list"></span>
          </div>
        </div>
        <div className="stat_item">
          <div className="chart_item">
            <Pie {...config2} />
          </div>
          <div className="stat_info">
            <div className="monthly">
              <div className="monthly_info">
                <p className="type_title">شهري</p>
                <span className="list" style={{ background: "#e64f39" }}></span>
              </div>
              <div className="total_number">
                <CountUp start={0} end={6000} duration={2} separator="," />
              </div>
            </div>
            <div className="yearly">
              <div className="yearly_info">
                <p className="type_title">سنوي</p>
                <span className="list" style={{ background: "#0fb0a9" }}></span>
              </div>
              <div className="total_number">
                <CountUp start={0} end={25000} duration={2} separator="," />
              </div>
            </div>
          </div>
          <div className="info_item">
            <p className="title">عدد خدمات العياة المرفوضة شهريًا وسنويًا</p>
            <span className="list"></span>
          </div>
        </div>
        <div className="stat_item">
          <div className="chart_item">
            <Pie {...config3} />
          </div>
          <div className="stat_info">
            <div className="monthly">
              <div className="monthly_info">
                <p className="type_title">شهري</p>
                <span className="list" style={{ background: "#e64f39" }}></span>
              </div>
              <div className="total_number">
                <CountUp start={0} end={5000} duration={2} separator="," />
              </div>
            </div>
            <div className="yearly">
              <div className="yearly_info">
                <p className="type_title">سنوي</p>
                <span className="list" style={{ background: "#0fb0a9" }}></span>
              </div>
              <div className="total_number">
                <CountUp start={0} end={22000} duration={2} separator="," />
              </div>
            </div>
          </div>
          <div className="info_item">
            <p className="title">عدد خدمات العيادة المؤجرة شهريًا وسنويًا</p>
            <span className="list"></span>
          </div>
        </div>
        <div className="stat_item">
          <div className="chart_item">
            <Pie {...config4} />
          </div>
          <div className="stat_info">
            <div className="monthly">
              <div className="monthly_info">
                <p className="type_title">شهري</p>
                <span className="list" style={{ background: "#e64f39" }}></span>
              </div>
              <div className="total_number">
                <CountUp start={0} end={2500} duration={2} separator="," />
              </div>
            </div>
            <div className="yearly">
              <div className="yearly_info">
                <p className="type_title">سنوي</p>
                <span className="list" style={{ background: "#0fb0a9" }}></span>
              </div>
              <div className="total_number">
                <CountUp start={0} end={27000} duration={2} separator="," />
              </div>
            </div>
          </div>
          <div className="info_item">
            <p className="title">عدد الأطباء المستأجرين شهريًا وسنويًا</p>
            <span className="list"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

function RentingDoctorsClinics({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div style={{ width: "300px" }} className="heading text-center">
              الأحصائيات
            </div>

            <Doctor_Clincs_Stat />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default RentingDoctorsClinics;
