import React, { Component } from "react";
import logout from "../imgs/Group 2306.png";
import file from "../imgs/file.png";
import message from "../imgs/Group 191.png";
import notification from "../imgs/Path 2243.png";
import home from "../imgs/home.png";
import user from "../imgs/user (1).png";
import logout1 from "../imgs/Solid.png";
import barchart from "../imgs/bar-chart.png";
import settings from "../imgs/settings.png";
import bills from "../imgs/bill.png";
import employees from "../imgs/employee.png";
import clients from "../imgs/value.png";
import power from "../imgs/Group 2312.png";
import "../styles/control_panel_headr.css";
import { BrowserRouter, Link } from "react-router-dom";

class Control_panel_Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const exit = this.props.exit;
    const exit1 = this.props.exit1;
    const files = this.props.files;
    const notifi = this.props.notifi;
    const msg = this.props.msg;
    const userInfo = this.props.userInfo;

    const stat = this.props.stat;
    const setting = this.props.setting;
    const bill = this.props.bill;
    const employee = this.props.employee;
    const client = this.props.client;
    const powers = this.props.powers;
    const special = this.props.special;
    const change = this.props.change;
    const system = this.props.system;
    const style = this.props.style;
    const controlpanle = this.props.controlpanle;

    const notifidest = this.props.notifidest;
    const systemdest = this.props.systemDest;
    const statDest = this.props.statDest;
    const settingDest = this.props.settingDest;
    const billDest = this.props.billDest;
    const employeeDest = this.props.employeeDest;
    const clientDest = this.props.clientDest;
    const specialDest = this.props.specialDest;
    const changeDest = this.props.changeDest;
    const fileDest = this.props.fileDest;
    const userDest = this.props.userDest;
    const exitDest = this.props.exitDest;
    const msgDest = this.props.msgDest;
    const controlpanleDest = this.props.controlpanleDest;

    return (
      <div className="cp_header">
        <div className={style}>
          {exit && (
            <div className="link_item">
              <div style={{ width: "83px" }}>تسجيل خروج</div>
              <img src={logout} alt="" />
            </div>
          )}

          {exit1 && (
            <div className="link_item">
              <Link to={exitDest}>
                <div style={{ width: "83px" }}>تسجيل خروج</div>
                <img src={logout1} alt="" />
              </Link>
            </div>
          )}

          {msg && (
            <div className="link_item">
              <Link to={msgDest}>
                <div>الرسائل</div>
                <img src={message} alt="" />
              </Link>
            </div>
          )}

          {notifi && (
              <Link className="link_item" to={notifidest}>
                <div>الاشعارات</div>
                <img src={notification} alt="" />
              </Link>
          )}

          {change && (
            <div className="link_item">
              <Link to={changeDest}>
                <div>تغيير تصنيف</div>
                <img src={file} alt="" />
              </Link>
            </div>
          )}

          {setting && (
            <div className="link_item">
              <Link to={settingDest}>
                <div>الاعدادات</div>
                <img src={settings} alt="" />
              </Link>
            </div>
          )}

          {system && (
            <div className="link_item">
              <Link to={systemdest}>
                <div>ملف النظام</div>
                <img src={file} alt="" />
              </Link>
            </div>
          )}

          {powers && (
            <div className="link_item">
              <Link to="/powers">
                <div>الصلاحيات</div>
                <img src={power} alt="" />
              </Link>
            </div>
          )}

          {stat && (
            <div className="link_item">
              <Link to={statDest}>
                <div>الاحصائيات</div>
                <img src={barchart} alt="" />
              </Link>
            </div>
          )}
          {bill && (
            <div className="link_item">
              <Link to={billDest}>
                <div>الفواتير</div>
                <img src={bills} alt="" />
              </Link>
            </div>
          )}

          {employee && (
            <div className="link_item">
              <Link to={employeeDest}>
                <div>الموظفين</div>
                <img src={employees} alt="" />
              </Link>
            </div>
          )}

          {client && (
            <div className="link_item">
              <Link to={clientDest}>
                <div>العملاء</div>
                <img src={clients} alt="" />
              </Link>
            </div>
          )}

          {files && (
            <div className="link_item">
              <Link to={fileDest}>
                <div>ملف المنشأة</div>
                <img src={file} alt="" />
              </Link>
            </div>
          )}

          {controlpanle && (
            <div className="link_item">
              <Link to={controlpanleDest} style={{ width: "160px" }}>
                <div>لوحة تحكم العميل</div>
                <img src={file} alt="" />
              </Link>
            </div>
          )}

          {userInfo && (
            <div className="link_item">
              <Link to={userDest}>
                <div>بيانات العميل</div>
                <img src={user} alt="" />
              </Link>
            </div>
          )}

          {special && (
            <div className="link_item">
              <Link to={specialDest}>
                <div>حالةلااستثنائية</div>
                <img src={clients} alt="" />
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Control_panel_Header;
