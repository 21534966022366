import React, { Component } from "react";
import "../../styles/add_newsection.css";
import arrow from "../../imgs/arrow.png";
import upload from "../../imgs/upload (1).png";
import search from "../../imgs/search1.png";
import complete from "../../imgs/check1.png";
import check from "../../imgs/check.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";

class Add_NewSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      path: "إضافة ايقونة القطاع",
      photo: false,
      wrong: false,
      msg: "",
      showModal: false,
    };
  }

  getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  submitHandler = () => {
    this.setState({ showModal: true });
  };

  onUpload = (e) => {
    if (e.target.value) {
      const ext = this.getExtension(e.target.value);
      if (ext === "png" || ext === "pdf" || ext === "jpg") {
        this.setState({
          photo: true,
          path: e.target.value,
          wrong: false,
        });
      } else {
        this.setState({
          photo: false,
          path: "إضافة ايقونة الخدمة",
          wrong: true,
        });
      }
    }
  };

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };
  render() {
    const { name, path, photo, wrong, msg } = this.state;
    return (
      <div className="add_newsection">
        <div className="item reverse">
          <div className="input-content">
            <p>اضافة ايقونة القطاع</p>
            <label for="upload-photo1">
              {" "}
              <div className="input" style={{ paddingLeft: "276px" }}>
                <img src={upload} alt="" />
                <div>{path}</div>
              </div>{" "}
            </label>
            <input
              onChange={this.onUpload}
              type="file"
              name="photo1"
              id="upload-photo1"
            />
            {photo && <img src={complete} alt="" className="complete" />}
            {wrong && <AiOutlineCloseCircle className="complete" />}
          </div>
          <div className="input-data" style={{ marginRight: "24px" }}>
            <label htmlFor="name">اسم القطاع الرئيسي المضاف</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={this.changeHandler}
              placeholder="إضافة القطاع"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
            <img src={search} alt="" />
          </div>
        </div>

        <div className="item reverse">
          <div className="input-data" style={{ marginRight: "24px" }}>
            <label htmlFor="name">القطاع الفرعي</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={this.changeHandler}
              placeholder="اضف القطاع الفرعي"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
            <img src={search} alt="" />
          </div>
        </div>

        <div className="item">
          <div
            className="input-data"
            style={{
              marginLeft: "205px",
              width: "800px",
              height: "165px",
              marginBottom: "70px",
            }}
          >
            <label htmlFor="msg">رسالة الادارة</label>
            <textarea
              style={{ paddingTop: "10px", height: "100%" }}
              type="text"
              id="msg"
              name="msg"
              value={msg}
              onChange={this.changeHandler}
              placeholder="رسالة تنبيه لكل المشتركين بإضافة الخدمة"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
        </div>
        <div className="text-center d-flex justify-content-center">
          <Button className="text-center btnNext" onClick={this.submitHandler}>
            <img src={arrow} alt="" />
            تنفيذ
          </Button>
        </div>
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Add_NewSection;
