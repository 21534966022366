import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import arrow from "../../imgs/arrow.png";
import complete from "../../imgs/check1.png";
import date from "../../imgs/date.png";
import rarrow from "../../imgs/right-arrow.png";
import "../../styles/add_evaluationdata.css";

import Message from "../../component/Message";

import { Button, Modal } from "react-bootstrap";

const error_text = "الرجاء ملئ كل البيانات لإكمال التسجيل";

const operationTypes = {
	'B': 'شراء',
	'S': 'بيع',
	'R': 'تأجير',
};

const paymentMethods = {
	'P': 'دفعة واحدة',
	'T': 'دفعتين'
};

const isPaidOptions = {
	true: 'نعم',
	false: 'لا'
};

const handleDate = (d) => {
	if (d) {
		const offset = d.getTimezoneOffset();
		let newDate = new Date(d.getTime() - (offset * 60 * 1000));
		return newDate.toISOString().split('T')[0];
	}
}



function AddOldBill({ history }) {

	const [isError, setIsError] = useState(false);
	const [showError, setShowError] = useState(false);

	const [doneAdding, setDoneAdding] = useState(false);
	//#region Form props

	const [sourceFacility, setSourceFacility] = useState('');

	const [invoiceNumber, setInvoiceNumber] = useState('');

	const [operationType, setOperationType] = useState('B');
	const [operationTypeShown, setOperationTypeShown] = useState(false);
	const [service, setService] = useState('');

	const [paymentMethod, setPaymentMethod] = useState('P');
	const [paymentMethodShown, setPaymentMethodShown] = useState(false);

	const [invoicePrice, setInvoicePrice] = useState('');

	const [isInvoicePaid, setIsInvoicePaid] = useState(false);
	const [isInvoicePaidShown, setIsInvoicePaidShown] = useState(false);

	const [billDate, setBillDate] = useState('');
	const [originalPaymentDate, setOriginalPaymentDate] = useState(null);
	const [negotiationDuration, setNegotiationDuration] = useState('');
	const [notes, setNotes] = useState('');

	const [oldBillFileName, setOldBillFileName] = useState('لم يتم تحديد ملف');
	const [oldBillFile, setOldBillFile] = useState(null);
	const [isOldBillValid, setIsOldBillValid] = useState(null);

	//#endregion

	const [message, setMessage] = useState(null);

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		axios.get('')
	});

	const focusHandler = (e) => {
		e.target.parentNode.style.color = "#0fb0a9";
		e.target.style.color = "#0fb0a9";
		e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
		e.target.style.borderColor = "#0fb0a9";
	};

	const focusoutHandler = (e) => {
		e.target.parentNode.style.color = "";
		e.target.style.color = "";
		e.target.style.boxShadow = "";
		e.target.style.borderColor = "";
	};

	const getExtension = (filename) => {
		var parts = filename.split(".");
		return parts[parts.length - 1];
	};

	const onUpload = (e) => {
		if (e.target.value) {
			const ext = getExtension(e.target.value);
			if (ext === "jpg" || ext === "png") {
				setOldBillFileName(e.target.value);
				setOldBillFile(e.target.files[0]);
				setIsOldBillValid(true);
			} else {
				setOldBillFile(false);
				setIsOldBillValid(false);
				setMessage('صورة غير صالحة');
			}
		}
	};

	const validateInput = () => {
		const nodes = document.querySelectorAll("input");

		for (const n in Array.from(nodes.keys())) {
			const node = nodes[n];
			if (node.getAttribute('invalid') !== null || (node.getAttribute('not-required') === null && node.value.trim() === "")) {
				setIsError(true);
				return;
			}
		}

		setIsError(false);
	};

	const sendData = (e) => {
		e.preventDefault();
		validateInput();

		if (!isError) {
			let data = new FormData();
			data.append('source_facility', sourceFacility);
			data.append('source_facility', sourceFacility);
			data.append('operation_type', operationType);
			data.append('service', service);
			data.append('collect_method', paymentMethod);
			data.append('invoice_price', invoicePrice);
			data.append('invoice_paid', isInvoicePaid);
			data.append('invoice_date', handleDate(billDate));
			data.append('invoice_pay_date', handleDate(originalPaymentDate));
			data.append('negotiation_duration', negotiationDuration);
			data.append('notes', notes);
			data.append('invoice_image', oldBillFile);
			data.append('invoice_num', invoiceNumber);

			// we're adding an old bill
			data.append('collect_invoice_type', 'O');


			axios.post('/services/collect_invoices/', data, {
				headers: {
					Authorization: `Bearer ${userInfo.access}`,
				}
			}).then(d => {
				setDoneAdding(true);
				setMessage('تمت إضافة الفاتورة بنجاح');
			}).catch(d => {
				setMessage('حدث خطأ ما');
			});

		}
	};

	const onModalHide = (e) => {
		setMessage(null);
		if (doneAdding)
			history.push('/oldBills');
	}

	return (
		<div>
			<ControlPanelHeader
				exit1={true}
				notifi={true}
				msg={true}
				files={false}
				stat={false}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				exitDest={"/services"}
				fileDest={"/FacilityFiles"}
				statDest={"/collectServices"}
				style={"three_section"} />

			<Modal
				style={{ direction: "rtl" }}
				show={message !== null}
				onHide={onModalHide}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header style={{
					backgroundColor: "var(--main-color)",
					justifyContent: "center",
				}}>
					<Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
				</Modal.Header>
				<Modal.Body className="h4">{message}</Modal.Body>
				<Modal.Footer>
					<Button variant="danger"
						className="btn-red"
						onClick={onModalHide}>
						<span className="h4">موافق</span>
					</Button>
				</Modal.Footer>
			</Modal>


			<div className="data-form">
				<div className="desc-title text-center">إضافة فاتورة قديمة</div>
				<form style={{ marginTop: '30px' }}>
					<div className="name-form">
						<div className="input-group">

							<div className="input-lable">
								<label>رقم الفاتورة</label>
								<input
									type="text"
									value={invoiceNumber}
									placeholder=" اكتب رقم الفاتورة"
									onChange={(e) => setInvoiceNumber(e.target.value)}
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
							</div>

							<div className="input-lable">
								<label>أسم المنشأة المراد تحصيل الفاتورة منها</label>
								<input
									type="text"
									id="facility_CollectFrom"
									name="facility_CollectFrom"
									value={sourceFacility}
									placeholder=" اكتب أسم المنشأة المراد تحصيل الفاتورة منها"
									onChange={(e) => setSourceFacility(e.target.value)}
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
							</div>

							<div className="input-lable">
								<label htmlFor="date2">تاريخ الفاتورة</label>
								<DatePicker
									style={{ height: "41px" }}
									selected={billDate}
									onChange={(date) => setBillDate(date)}
									dateFormat="dd/MM/yyyy"
									showYearDropdown
									scrollableMonthYearDropdown
									placeholderText="حدد تاريخ الفاتورة"
									popperPlacement="top"
								/>
								<img src={date} alt="" />
							</div>

							<div className="input-lable">
								<label htmlFor="date2">موعد السداد الأصلي</label>
								<DatePicker
									style={{ height: "41px" }}
									selected={originalPaymentDate}
									onChange={(date) => setOriginalPaymentDate(date)}
									dateFormat="dd/MM/yyyy"
									showYearDropdown
									scrollableMonthYearDropdown
									placeholderText="موعد السداد الأصلي"
									popperPlacement="top"
								/>
								<img src={date} alt="" />
							</div>

							<div className="input-lable">
								<label htmlFor="name">مدة التفاوض</label>
								<input
									type="text"
									id="nego_Period"
									name="nego_Period"
									value={negotiationDuration}
									onChange={(e) => setNegotiationDuration(e.target.value)}
									placeholder=" حدد مدة التفاوض بالايام"
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
							</div>

							<div className="input-lable">
								<label htmlFor="analysisDetails">ملاحظات</label>
								<input
									not-required=""
									type="text"
									id="licence_No"
									name="licence_No"
									value={notes}
									onChange={(e) => setNotes(e.target.value)}
									placeholder="اكتب الملاحظات"
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
							</div>

							<div className="input-content">
								<p>رفع صورة الفاتورة القديمة</p>
								<label for="upload-photo1">
									{" "}
									<div className="input image-file-input" style={{ height: "41px" }}>
										<img src={oldBillFile} alt="" />
										<div>{oldBillFileName}</div>
									</div>{" "}
								</label>
								<input
									onChange={onUpload}
									type="file"
									name="photo1"
									id="upload-photo1"
								/>
								{isOldBillValid && (
									<img
										className="complete"
										src={complete}
										alt=""
										style={{ left: "-50px", right: "unset" }}
									/>
								)}
								{oldBillFileName && (
									<AiOutlineCloseCircle
										style={{ left: "-50px", right: "unset" }}
									/>
								)}
							</div>


							<div className="input-lable">
								<label htmlFor="gender">نوع العملية</label>
								<div className="select-city" style={{ width: "391px", height: "41px" }}>
									<div className="select-btn" onClick={() => setOperationTypeShown(!operationTypeShown)}>
										<span>{operationTypes[operationType]}</span>
										<img src={rarrow} />
									</div>
									{operationTypeShown && (
										<div className="select-content" style={{ height: "100px" }}>
											{Object.entries(operationTypes).map(([option, text]) => (
												<div className="select-item" option={option} onClick={(e) => {
													setOperationType(e.target.getAttribute('option'));
													setOperationTypeShown(false);
												}}>{text}</div>
											))}
										</div>
									)}
								</div>
							</div>

							<div className="input-lable">
								<label>الخدمة</label>
								<input
									type="text"
									value={service}
									placeholder="اكتب الخدمة"
									onChange={(e) => setService(e.target.value)}
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
							</div>

							<div className="input-lable">
								<label htmlFor="gender">طريقة الدفع</label>
								<div className="select-city" style={{ width: "391px", height: "41px" }}>
									<div className="select-btn" onClick={() => setPaymentMethodShown(!paymentMethodShown)}>
										<span>{paymentMethods[paymentMethod]}</span>
										<img src={rarrow} />
									</div>
									{paymentMethodShown && (
										<div className="select-content" style={{ height: "100px" }}>
											{Object.entries(paymentMethods).map(([option, text]) => (
												<div className="select-item" option={option} onClick={(e) => {
													setPaymentMethod(e.target.getAttribute('option'));
													setPaymentMethodShown(false);
												}}>{text}</div>
											))}
										</div>
									)}
								</div>
							</div>

							<div className="input-lable">
								<label>قيمة الفاتورة</label>
								<input
									type="text"
									value={invoicePrice}
									placeholder="اكتب قيمة الفاتورة"
									onChange={(e) => setInvoicePrice(e.target.value)}
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
							</div>

							<div className="input-lable">
								<label htmlFor="gender">هل تم تسديد الفاتورة؟</label>
								<div className="select-city" style={{ width: "391px", height: "41px" }}>
									<div className="select-btn" onClick={() => setIsInvoicePaidShown(!isInvoicePaidShown)}>
										<span>{isPaidOptions[isInvoicePaid]}</span>
										<img src={rarrow} />
									</div>
									{isInvoicePaidShown && (
										<div className="select-content" style={{ height: "100px" }}>
											{Object.entries(isPaidOptions).map(([option, text]) => (
												<div className="select-item" option={option} onClick={(e) => {
													setIsInvoicePaid(e.target.getAttribute('option'));
													setIsInvoicePaidShown(false);
												}}>{text}</div>
											))}
										</div>
									)}
								</div>
							</div>

						</div>
					</div>
				</form>
				{isError &&
					<div style={{ margin: '50px', marginTop: '0' }}>
						<Message variant="danger" >تأكد من البيانات</Message>
					</div>
				}
			</div>
			<button onClick={sendData} className="btnNext">
				<img src={arrow} alt="" />
				إرسال
			</button>
		</div>
	);
}

export default AddOldBill;
