import { Card } from "react-bootstrap";
function H1({ title, type, size, gray, className,line_hieght }) {
  let width = 26 / size;
  let fontSize = 2 / size;
  return (
    <div className={` ${className ? className : ""}`}>
      {type?<p className="pb-0 h1_type">{type}</p>:''}
      <Card
        style={{ width: `${width}rem`, textAlign: "center" }}
        className={`my-1 ${gray ? "grayBG" : ""}`}
      >
        <Card.Body style={{ padding: "1rem 0" }}>
          <Card.Title className={line_hieght?'':'line'} style={{ fontSize: `${fontSize}rem` }}>
            {title}
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}
export default H1;
