import axios from "axios";

export const accept_DevicesOffer = async (id, user) => {
  const request = await axios.get(`/services/accept_DevicesOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const accept_ClinicsOffer = async (id, user) => {
  const request = await axios.get(`/services/accept_ClinicsOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const accept_LabsOffer = async (id, user) => {
  const request = await axios.get(`/services/accept_LabsOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const accept_RadiologyOffer = async (id, user) => {
  const request = await axios.get(`/services/accept_RadiologyOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const accept_TenderOffer = async (id, user) => {
  const request = await axios.get(`/services/accept_TenderOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};
