import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminLayout from "../adminScreen/layouts/AdminLayout";
import SvgComponent from "../adminScreen/LogoBayan";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function Dashboard({ history }) {

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
      
    } else {
      history.push("/");
    }
  }, [dispatch, userInfo]);
  return (
    <AdminLayout>      
      <div
        className="photo-cont"
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <SvgComponent style={{ height: "100%" }} />
      </div>
    </AdminLayout>
  );
}

export default Dashboard;
