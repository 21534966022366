import { Container, Button } from "react-bootstrap";
import logo from "./logo.svg";
import sign from "./sign.png";
import ahmed from "./ahmed.png";
import ayman from "./ayman.png";
import social from "./social.png";
import "./certificate.css";
import QRCode from "react-qr-code";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { listAllFacilitiesForAdmin } from "../actions/adminActions";
import { useParams } from "react-router-dom";
import axios from "axios";

function Certificate() {
  const dispatch = useDispatch();
  const params = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleClick = () => {
    window.print();
  };

  const [fac, setFac] = useState({});

  useEffect(() => {
    axios
      .get(`/api/users/all_facilities_for_admin/?no-page=true`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setFac(data.facilities.filter((f) => f.id == params.id)[0]);
      });
  }, []);

  // const facility = facilities.filter((f) => f.id == params.id)[0];
  return (
    <div>
      {fac == null && <div>المنشأة غير موجودة</div>}
      {fac != null && (
        <>
          <Container className="a4_l p-4 mb-4 a4_h">
            <Button
              onClick={() => handleClick()}
              className="print_hide m-4 button-print bg-secondary"
            >
              طباعة
            </Button>
            <div className="header_c" />
            <img className="vip_img" alt="logo" src={logo} />
            <h1 className="vip">
              <span className="vip_m">معتمد</span>
              <span className="vip_a">Accredited</span>
              {fac.facility_level && (
                <span
                  className="vip_v"
                  style={{
                    right:
                      fac.facility_level.level.split("").length < 2
                        ? "65px"
                        : "21px",
                  }}
                >
                  {fac.facility_level.level}
                </span>
              )}
            </h1>
            <QRCode
              className="QR_C"
              fgColor="#1bb1aa"
              value={window.location.href}
              size="130"
            />
            <div className="vip_div">
              <h1 className="vip_h1"> شهادة تقييم المنشأة</h1>
              <h1 className="vip_h1_e ">Facility Evaluation Certificate</h1>
              <h2 className="vip_h2 m_t t_c line_hight">
                تشهد منصة بيان بأنَ المنشأة أدناه
              </h2>
              <h3 className="vip_h3" style={{ direction: "rtl" }}>
                <span className="small"> اسم المنشأة: </span>{" "}
                {fac.facility_name}
                <span className="small p_r">رقم السجل</span>:{" "}
                {fac.user != null && fac.user.commercial_registration_num}
              </h3>
              <h3 className="vip_h3">
                <span className="small">
                  المنطقة<span className="p_pr"> : </span>
                </span>
                {fac.location}
                <span className="small p_m" style={{ paddingRight: "175px" }}>
                  {" "}
                  المدينة:{" "}
                </span>{" "}
                {fac.state}
              </h3>
              <h2
                className="vip_h2 t_c orange line_hight"
                style={{ direction: "rtl" }}
              >
                حاصلة على تقييم{" "}
                {fac.facility_level != null && fac.facility_level.level}
                <br /> يضمن لها تواجدها في قائمة {fac.allowed_level}
              </h2>
              <h3 className="vip_h3 line_hight date_m">
                تاريخ الانتهاء:{" "}
                <span className="text-bold">{fac.expiration_date}</span>
              </h3>
            </div>
            <div className="vip_sign_m line_hight">
              <h3 className="vip_h3_sign">توقيع المدير العام</h3>
              <h2 className="vip_h2_sign">أيمن حاتم فران</h2>
              <img alt="sign" src={ayman} style={{ width: "178px" }} />
            </div>
            <div className="vip_sign_r line_hight">
              <h3 className="vip_h3_sign">توقيع الرئيس التنفيذي</h3>
              <h2 className="vip_h2_sign">أحمد محمد السلمي</h2>
              <img alt="sign" src={ahmed} style={{ width: "178px" }} />
            </div>
            <div className="footer_c">
              <span className="footer_text">
                جميع الحقوق محفوظة لدى منصة بيان الصحة &copy;2021
              </span>
              <img className="social" alt="social" src={social} />
            </div>
          </Container>
          <style media="print">
            {
              "@page {size: landscape;}"
            }
          </style>

          <page size="A4" layout="landscape">
            <div className="a4_l a4_p" style={{ overflow: 'hidden', top: 0, marginTop: '0', height: '229mm' }}>
              <div className="header_c" />
              <img className="vip_img" alt="logo" src={logo} />
              <h1 className="vip">
                <span className="vip_m">معتمد</span>
                <span className="vip_a">Accredited</span>
                {fac.facility_level && (
                  <span
                    className="vip_v"
                    style={{
                      right:
                        fac.facility_level.level.split("").length < 2
                          ? "65px"
                          : "21px",
                    }}
                  >
                    {fac.facility_level.level}
                  </span>
                )}
              </h1>
              <QRCode className="QR_C" fgColor="#1bb1aa" value={window.location.href} size="130" />
              <div className="vip_div">
                <h1 className="vip_h1"> شهادة تقييم المنشأة</h1>
                <h1 className="vip_h1_e ">Facility Evaluation Certificate</h1>
                <h2 className="vip_h2 m_t t_c line_hight">
                  تشهد منصة بيان بأنَ المنشأة أدناه
                </h2>
                <h3 className="vip_h3" style={{ direction: "rtl" }}>
                  <span className="small"> اسم المنشأة: </span>{" "}
                  {fac.facility_name}
                  <span className="small p_r">رقم السجل</span>:{" "}
                  {fac.user && fac.user.commercial_registration_num}
                </h3>
                <h3 className="vip_h3">
                  <span className="small">
                    المنطقة<span className="p_pr"> : </span>
                  </span>
                  {fac.location}
                  <span className="small p_m" style={{ paddingRight: "175px" }}>
                    {" "}
                    المدينة:{" "}
                  </span>{" "}
                  {fac.state}
                </h3>
                <h2 className="vip_h2 t_c orange line_hight">
                  حاصلة على تقييم VIP
                  <br /> يضمن لها تواجدها في قائمة
                </h2>
                <h3 className="vip_h3 line_hight date_m">
                  للفترة من ديسمبر 2021 إلى نوفمبر 2022
                </h3>
              </div>
              <div className="vip_sign_m line_hight">
                <h3 className="vip_h3_sign">توقيع المدير العام</h3>
                <h2 className="vip_h2_sign">أيمن حاتم فران</h2>
                <img alt="sign" src={ayman} style={{ width: "178px" }} />
              </div>
              <div className="vip_sign_r line_hight">
                <h3 className="vip_h3_sign">توقيع الرئيس التنفيذي</h3>
                <h2 className="vip_h2_sign">أحمد محمد السلمي</h2>
                <img alt="sign" src={ahmed} style={{ width: "178px" }} />
              </div>
              <div className="footer_c">
                <span className="footer_text">
                  جميع الحقوق محفوظة لدى منصة بيان الصحة &copy;2021
                </span>
                <img className="social" alt="social" src={social} />
              </div>
            </div>
          </page>
        </>
      )}
    </div>
  );
}
export default Certificate;
