import { Link, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import "../styles/cp_services_online.css";
import DescriptionStartServicesScreen from "../screens/DescriptionStartServicesScreen"

import Loader from '../component/Loader'
import Message from '../component/Message'
import medicalreport from "../imgs/medical-report.svg"
import doctor from "../imgs/doctor (1).svg"
import xray from "../imgs/x-rays.svg"
import lab from "../imgs/Path 2178.svg"
import bar from "../imgs/bars.svg"
import exchange from "../imgs/exchange.svg"
import collective from "../imgs/hand.svg"
import medicaldevice from "../imgs/ct-scan.svg"
import ControlPanelHeader from '../component/ControlPanelHeader'
import { getFacilityDetails } from '../actions/facilityActions'


function CPServicesOnlineScreen({ history }) {

	const [title, setTitle] = useState('')

	const [subTitle, setSubTitle] = useState('')

	const [sections, setSections] = useState(false)

	const [servBtn, setServBtn] = useState(false)

	const [choosePara, setChoosePara] = useState('')

	const [dest, setDest] = useState('')

	const [goals, setGoals] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,])

	const dispatch = useDispatch()

	const facilityDetails = useSelector(state => state.facilityDetails)

	const { success, facilityInfo } = facilityDetails

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			dispatch(getFacilityDetails())
		}

	}, [dispatch, history, userInfo])


	const changeDesign = (e) => {
		const nodes = e.target.parentNode.parentNode.querySelectorAll(".card-item");

		nodes.forEach((element) => {
			element.classList.remove("active");
			element.querySelector("object").contentDocument.querySelectorAll("path").forEach((ele) => {
				ele.setAttribute("fill", "#0fb0a9");
			});
		});

		e.target.querySelector("object").contentDocument.querySelectorAll("path").forEach((ele) => {
			ele.setAttribute("fill", "#fff");
		});

		e.target.classList.add("active");

		if (e.target.getAttribute("data-serv") === "1") {
			setTitle(e.target.childNodes[1].outerText)

			setGoals([
				true,
				true,
				true,
				true,
				true,
				true,
				true,
				true,
				true,
				true])
			setSubTitle("الفئات المستهدفة")

			setSections(false)

			setServBtn(e.target.getAttribute("data-enterserv") === "true" ? true : false)

			setChoosePara(e.target.getAttribute("data-para"))

			setDest(e.target.getAttribute("data-dest"))

		} else if (e.target.getAttribute("data-serv") === "2") {

			setTitle(e.target.childNodes[1].outerText)

			setGoals([
				true,
				true,
				true,
				true,
				true,
				true,
				true,
				true,
				false,
				true])
			setSubTitle("الفئات المستهدفة")

			setSections(false)

			setServBtn(true)

			setChoosePara(e.target.getAttribute("data-para"))
			setDest(e.target.getAttribute("data-dest"))


		} else {
			setTitle(e.target.childNodes[1].outerText)

			setGoals([
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false])
			setSubTitle("الفئات المستهدفة")

			setSections(true)

			setServBtn(true)

			setChoosePara(e.target.getAttribute("data-para"))
			setDest(e.target.getAttribute("data-dest"))

		}
	};


	return (
		<div>
			<ControlPanelHeader
				exit1={true}
				controlpanle={true}
				notifi={true}
				msg={true}
				exitDest={"/"}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				fileDest={"/client"}
				style={"three_section"}
				controlpanleDest={"/client"}
			/>
			{success &&
				<div className="cp_services_online">
					<div className="container">
						<h1 className="heading text-center">الخدمات الإلكترونية</h1>
						<div className="cards text-center">
							{(facilityInfo?.sector_type?.pk !== 28) &&
								<Link to="/services/service/8">
									<div
										onClick={changeDesign}
										className="card-item"
										data-serv="3"
										data-para="0"
										data-dest="/TendersList"
									>
										<object data={bar} />
										<p className="pt-3">تنظيم مناقصات التوريد</p>
									</div>
								</Link>
							}

							{((facilityInfo.sector_type.pk !== 28) && (facilityInfo.sector.id === 7)) &&


								<Link to="/services/service/7">
									<div
										onClick={changeDesign}
										className="card-item"
										data-serv="2"
										data-para="1"
										data-dest="/ListRayContract"
									>
										<object data={xray} />
										<p className="pt-3">تنظيم وتشغيل عقود الأشعة</p>
									</div>
								</Link>
							}

							{((facilityInfo.sector_type.pk !== 28) && (facilityInfo.sector.id === 7)) &&

								<Link to="/services/service">
									<div
										onClick={changeDesign}
										className="card-item"
										data-serv="2"
										data-para="2"
										data-dest="/ListLabsContracts"
									>
										<object data={lab} />
										<p className="pt-3">تنظيم وتشغيل عقود المختبرات</p>
									</div>
								</Link>
							}
							{(facilityInfo.sector_type.pk !== 28) &&

								<Link to="/services/service">
									<div
										onClick={changeDesign}
										className="card-item"
										data-serv="1"
										data-enterserv="true"
										data-para="3"
										data-dest="/collectServices"
									>
										<object data={collective} />

										<p className="pt-3">التحصيل</p>
									</div>
								</Link>
							}

							{facilityInfo.sector.id === 7 &&

								<Link to="/services/service">
									<div
										onClick={changeDesign}
										className="card-item"
										data-serv="1"
										data-enterserv="true"
										data-para="4"
										data-dest="/comparingMedicalMistakeWork"
									>
										<object data={exchange} />
										<p>مقارنة أسعار تامين اخطاء ممارسة المهن الطبية</p>
									</div>
								</Link>
							}

							{(facilityInfo.sector.id === 7 && facilityInfo.sector_type.pk !== 28) &&

								<Link to="/services/service">
									<div
										onClick={changeDesign}
										className="card-item"
										data-serv="2"
										data-para="5"
										data-dest="/ListRentDoctorsClincsServices"
									>
										<object data={doctor} />

										<p className="pt-3">تأجير خدمات الأطباء والعيادات</p>
									</div>
								</Link>
							}
							{((facilityInfo.sector_type.pk !== 28) && (facilityInfo.sector.id === 7)) &&


								<Link to="/services/service">
									<div
										onClick={changeDesign}
										className="card-item"
										data-serv="2"
										data-para="6"
										data-dest="/ListSellMedicalDevices"
									>
										<object data={medicaldevice} />

										<p className="pt-3">تأجير أو بيع الأجهزة الطبية</p>
									</div>
								</Link>
							}

							{facilityInfo.sector.id === 7 &&

								<Link to="/services/service">
									<div
										onClick={changeDesign}
										className="card-item"
										data-serv="1"
										data-para="7"
										data-dest=" "
									>
										<object data={medicalreport} />

										<p className="pt-3">تقييم الشبكات الطبية</p>
									</div>
								</Link>
							}
						</div>
						<div className="content" style={{ marginTop: "90px" }}>
							<Route
								path="/services/service"
								render={() => (
									<DescriptionStartServicesScreen
										title={title}
										goals={goals}
										subTitle={subTitle}
										sections={sections}
										servBtn={servBtn}
										choosePara={choosePara}
										dest={dest}
										video={'video1'}
										poster={'poster'}
									/>
								)}
							/>
						</div>
					</div>
				</div>
			}
		</div>
	)
}

export default CPServicesOnlineScreen
