import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import Rate_Network_Stat from "../component/stat/Rate_Network_Stat";

function MedicalNetworkAssessment({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div
              style={{ width: "300px", textAlign: "center" }}
              className="heading text-center"
            >
              الأحصائيات
            </div>

            <Rate_Network_Stat />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default MedicalNetworkAssessment;
