import { Link, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../styles/choose_section_type.css";

import Loader from '../component/Loader'
import Message from '../component/Message'
import { listSectors } from '../actions/sectorActions'
import { Container } from 'react-bootstrap';
import MainChooseSectionTypeScreen1 from "./MainChooseSectionTypeScreen1";
import ControlPanelHeader from '../component/ControlPanelHeader'
import { useHistory } from 'react-router-dom'


function MainChooseSectionTypeScreen({  }) {

    const [selectedTags, setSelectedTags] = useState('')

    const dispatch = useDispatch()

    let history = useHistory()

    const sectorList = useSelector(state => state.sectorList)

    const {loading, error, sectors } = sectorList

    const facilityInfoRegister
    = useSelector(state => state.facilityInfoRegister)

    const {facility, success } = facilityInfoRegister

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
      if(userInfo){
        if (userInfo.is_registered){
            history.push('/services')
        } else {
          dispatch(listSectors())
          if(facility) {
            setSelectedTags(facility.sector)
          }
        }
          
      } else {
          history.push('/')
      }
      
  }, [dispatch, history, userInfo, listSectors])

  
    const changeDesign = (e) => {
      const nodes = e.target.parentNode.parentNode.querySelectorAll(".card-item");
      nodes.forEach((element) => {
        element.classList.remove("active");
        element.querySelectorAll("path").forEach((ele) => {
          ele.setAttribute("fill", "#0fb0a9");
        });
      });

      e.target.classList.add("active");

      e.target.querySelectorAll("path").forEach((ele) => {
        ele.setAttribute("fill", "#fff");
      });

      setSelectedTags(e.target.getAttribute("data-tag"));

    }
    
    return (
      <div className="choose_section">
        <ControlPanelHeader exit1={true} notifi={true} msg={true} msgDest={"/Messages"}
            notifidest={"/Notifications"} exitDest={"/"} style={"three_section"} />
        { loading
          ? (<Loader/>) 
          : error 
          ? (<Message variant='danger'>{error}</Message>)
          : (
              <Container fluid className="p-5">  
                <div className="head-title">القطاعات</div>
                <div className="cards justify-content-center">
                  {sectors.map((sector) => (
                    <Link to="/MainChooseEnterprise/EnterpriseTags" key={sector.id}>
                      <div onClick={changeDesign} className="card-item" data-tag={sector.id} data-name={sector.sector_name}>
                        <span dangerouslySetInnerHTML={{__html: sector.svg}} style={{pointerEvents: 'none'}}/>
                      <p>{sector.sector_name}</p>
                      </div>
                    </Link>
                  ))}
                  
                  <Link to="/MainChooseEnterprise/EnterpriseTags">
                    <div
                      onClick={changeDesign}
                      className="card-item"
                      data-tag="11"
                      data-name='غير ذلك'
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="64.629"
                        height="64"
                        viewBox="0 0 64.629 64"
                      >
                        <g id="paper" transform="translate(0 -2.493)">
                          <path
                            id="Path_2312"
                            data-name="Path 2312"
                            d="M64.1,10.335l-7.63-7.276a1.548,1.548,0,0,0-1.151-.566H24.754a1.621,1.621,0,0,0-1.619,1.619V9.083L12.363,10.93a1.6,1.6,0,0,0-1.294,1.84l.547,3.188c-3.5,1.178-7.1,2.393-10.566,3.639a1.6,1.6,0,0,0-.958,2.036l6.9,19.189a.947.947,0,0,0,1.782-.641l-6.8-18.905c3.269-1.171,6.655-2.316,9.964-3.43l3.253,18.965,3.743,21.842a1.6,1.6,0,0,0,1.84,1.3l10.138-1.74,7.922-1.358L26.921,61.136l-9.4,3.378-7.3-20.3a.947.947,0,0,0-1.782.641l7.4,20.585a1.6,1.6,0,0,0,1.5,1.05,1.566,1.566,0,0,0,.538-.1l9.678-3.48,21.976-7.9,4.2-.72H63.01a1.623,1.623,0,0,0,1.619-1.623V11.4a1.362,1.362,0,0,0-.532-1.067Zm-2.508.224H56.734c-.227,0-.758.005-.893-.128a.533.533,0,0,1-.067-.326L55.763,5Zm1.147,41.846H25.028V33.955a.947.947,0,0,0-1.893,0V52.677A1.623,1.623,0,0,0,24.754,54.3H42.528L30.594,56.345,20.75,58.034,17.059,36.491,12.985,12.744,23.135,11V29.563a.947.947,0,1,0,1.893,0V4.386h28.84l.012,5.721a2.254,2.254,0,0,0,.627,1.667,2.9,2.9,0,0,0,2.1.679h6.125Z"
                            transform="translate(0)"
                            fill="#0fb0a9"
                          />
                          <path
                            id="Path_2313"
                            data-name="Path 2313"
                            d="M234.43,112.4a.947.947,0,0,0,.947.947h27.068a.947.947,0,0,0,0-1.893H235.377A.947.947,0,0,0,234.43,112.4Z"
                            transform="translate(-204.838 -95.203)"
                            fill="#0fb0a9"
                          />
                          <path
                            id="Path_2314"
                            data-name="Path 2314"
                            d="M262.445,164.731H235.377a.947.947,0,1,0,0,1.893h27.068a.947.947,0,0,0,0-1.893Z"
                            transform="translate(-204.838 -141.759)"
                            fill="#0fb0a9"
                          />
                          <path
                            id="Path_2315"
                            data-name="Path 2315"
                            d="M262.445,218.013H235.377a.947.947,0,0,0,0,1.893h27.068a.947.947,0,0,0,0-1.893Z"
                            transform="translate(-204.838 -188.315)"
                            fill="#0fb0a9"
                          />
                          <path
                            id="Path_2316"
                            data-name="Path 2316"
                            d="M262.445,271.3H235.377a.947.947,0,1,0,0,1.893h27.068a.947.947,0,0,0,0-1.893Z"
                            transform="translate(-204.838 -234.871)"
                            fill="#0fb0a9"
                          />
                          <path
                            id="Path_2317"
                            data-name="Path 2317"
                            d="M262.445,324.578H235.377a.947.947,0,1,0,0,1.893h27.068a.947.947,0,0,0,0-1.893Z"
                            transform="translate(-204.838 -281.429)"
                            fill="#0fb0a9"
                          />
                        </g>
                      </svg>

                      <p>غير ذلك </p>
                    </div>
                  </Link>
                </div>

                <div>
                <Route
                path="/MainChooseEnterprise/EnterpriseTags"
                render={() => (
                  <MainChooseSectionTypeScreen1
                    selectedTag={parseInt(selectedTags)}
                  />
                )}
              />
            </div>                
          </Container>
        )}
      </div>
    )
}

export default MainChooseSectionTypeScreen
