import '../../styles/description_start_services.css'
import arrow from '../../imgs/Group 224.png'

import { Link, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import email from '../../imgs/email.png'
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";
import axios from 'axios';
import { addNotification } from '../../actions/notificationActions'
import { updateInsurrenceOffer } from '../../actions/serviceActions'
import Loader from '../../component/Loader'
import Message from '../../component/Message'
import H1 from '../../adminScreen/components/print/h1';
import { getInsurrenceOfferDetails } from '../../actions/serviceActions'
import { useHistory } from 'react-router-dom'

const fileDownload = require('js-file-download');

function PriceListDescription({ insurrenceId, teamId }) {

	const dispatch = useDispatch()

	const insurrenceOfferDetails = useSelector(state => state.insurrenceOfferDetails)
	const { offer } = insurrenceOfferDetails

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin;

	const history = useHistory()

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			if (!userInfo.is_activated) {
				history.push('/services')
			} else if (insurrenceId) {
				dispatch(getInsurrenceOfferDetails(insurrenceId))
			}
		}

	}, [dispatch, history, userInfo, insurrenceId])

	const handlePDFDownload = (filename) => {
		axios.post('management/download/', {
			responseType: 'blob', filename: filename
		}).then(res => {
			fileDownload(res.data, filename);
		}).catch(err => {
			console.log(err);
		})
	}

	const onSubmit = (e) => {

		e.preventDefault();

		let data = new FormData();
		data.append("medical_insurance_request", teamId);
		data.append("medical_insurance_request_id", insurrenceId);

		console.log(teamId)

		data.append("accepted", true)

		dispatch(updateInsurrenceOffer(data));

		let notification_data = new FormData();
		notification_data.append("teamId", teamId);
		notification_data.append("title", "تم قبول عرضك");
		notification_data.append("content", 'لقد تم قبول عرضك');

		// dispatch(addNotification(notification_data));

		history.push("/ConfirmAcceptOfferInsurance");
	}

	return (
		<div>
			
			{insurrenceId &&
				<div>
					<Row dir="rtl">
						<Col lg="2">
							<button className="detail_btn mx-auto my-5" onClick={() => handlePDFDownload(offer?.offer?.uploaded_file)}>تحميل تفاصيل شركة التأمين</button>
						</Col>
					</Row>

					<Row>
						<div
							className="button_botom"
							style={{ width: "170px", height: "100px" }}
						>
							<Link to="/ConfirmAcceptInsurance">
								<button className="detail_btn mx-auto my-5" onClick={onSubmit}>
									قبول العرض
								</button>
							</Link>
						</div>
					</Row>

				</div>

			}
		</div>

	)
}

export default PriceListDescription
