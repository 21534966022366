export const EVALUATION_DATA_ADD_REQUEST = 'EVALUATION_DATA_ADD_REQUEST'
export const EVALUATION_DATA_ADD_SUCCESS = 'EVALUATION_DATA_ADD_SUCCESS'
export const EVALUATION_DATA_ADD_FAIL = 'EVALUATION_DATA_ADD_FAIL'

export const EVALUATION_DETAILS_REQUEST = 'EVALUATION_DETAILS_REQUEST'
export const EVALUATION_DETAILS_SUCCESS = 'EVALUATION_DETAILS_SUCCESS'
export const EVALUATION_DETAILS_FAIL = 'EVALUATION_DETAILS_FAIL'

export const EVALUATION_UPGRADE_REQUEST = 'EVALUATION_UPGRADE_REQUEST'
export const EVALUATION_UPGRADE_SUCCESS = 'EVALUATION_UPGRADE_SUCCESS'
export const EVALUATION_UPGRADE_FAIL = 'EVALUATION_UPGRADE_FAIL'

export const EVALUATION_UPDATE_REQUEST = 'EVALUATION_UPGRADE_REQUEST'
export const EVALUATION_UPDATE_SUCCESS = 'EVALUATION_UPGRADE_SUCCESS'
export const EVALUATION_UPDATE_FAIL = 'EVALUATION_UPGRADE_FAIL'

export const MEDICAL_DEVICE_ADD_REQUEST = 'MEDICAL_DEVICE_ADD_REQUEST'
export const MEDICAL_DEVICE_ADD_SUCCESS = 'MEDICAL_DEVICE_ADD_SUCCESS'
export const MEDICAL_DEVICE_ADD_FAIL = 'MEDICAL_DEVICE_ADD_FAIL'

export const MEDICAL_DEVICE_LIST_REQUEST = 'MEDICAL_DEVICE_LIST_REQUEST'
export const MEDICAL_DEVICE_LIST_SUCCESS = 'MEDICAL_DEVICE_LIST_SUCCESS'
export const MEDICAL_DEVICE_LIST_FAIL = 'MEDICAL_DEVICE_LIST_FAIL'

export const MEDICAL_DEVICE_DETAILS_REQUEST = 'MEDICAL_DEVICE_DETAILS_REQUEST'
export const MEDICAL_DEVICE_DETAILS_SUCCESS = 'MEDICAL_DEVICE_DETAILS_SUCCESS'
export const MEDICAL_DEVICE_DETAILS_FAIL = 'MEDICAL_DEVICE_DETAILS_FAIL'

export const MEDICAL_DEVICE_ADD_OFFER_REQUEST = 'MEDICAL_DEVICE_ADD_OFFER_REQUEST'
export const MEDICAL_DEVICE_ADD_OFFER_SUCCESS = 'MEDICAL_DEVICE_ADD_OFFER_SUCCESS'
export const MEDICAL_DEVICE_ADD_OFFER_FAIL = 'MEDICAL_DEVICE_ADD_OFFER_FAIL'

export const DOCTORS_CLINICS_ADD_REQUEST = 'DOCTOTS_CLINICS_ADD_REQUEST'
export const DOCTORS_CLINICS_ADD_SUCCESS = 'DOCTOTS_CLINICS_ADD_SUCCESS'
export const DOCTORS_CLINICS_ADD_FAIL = 'DOCTOTS_CLINICS_ADD_FAIL'

export const DOCTORS_CLINICS_LIST_REQUEST = 'DOCTORS_CLINICS_LIST_REQUEST'
export const DOCTORS_CLINICS_LIST_SUCCESS = 'DOCTORS_CLINICS_LIST_SUCCESS'
export const DOCTORS_CLINICS_LIST_FAIL = 'DOCTORS_CLINICS_LIST_FAIL'

export const DOCTORS_CLINICS_DETAILS_REQUEST = 'DOCTORS_CLINICS_DETAILS_REQUEST'
export const DOCTORS_CLINICS_DETAILS_SUCCESS = 'DOCTORS_CLINICS_DETAILS_SUCCESS'
export const DOCTORS_CLINICS_DETAILS_FAIL = 'DOCTORS_CLINICS_DETAILS_FAIL'

export const DOCTORS_CLINICS_ADD_OFFER_REQUEST = 'DOCTORS_CLINICS_ADD_OFFER_REQUEST'
export const DOCTORS_CLINICS_ADD_OFFER_SUCCESS = 'DOCTORS_CLINICS_ADD_OFFER_SUCCESS'
export const DOCTORS_CLINICS_ADD_OFFER_FAIL = 'DOCTORS_CLINICS_ADD_OFFER_FAIL'

export const LABORATORIES_ADD_REQUEST = 'LABORATORIES_ADD_REQUEST'
export const LABORATORIES_ADD_SUCCESS = 'LABORATORIES_ADD_SUCCESS'
export const LABORATORIES_ADD_FAIL = 'LABORATORIES_ADD_FAIL'

export const LABORATORIES_LIST_REQUEST = 'LABORATORIES_LIST_REQUEST'
export const LABORATORIES_LIST_SUCCESS = 'LABORATORIES_LIST_SUCCESS'
export const LABORATORIES_LIST_FAIL = 'LABORATORIES_LIST_FAIL'

export const LABORATORIES_DETAILS_REQUEST = 'LABORATORIES_DETAILS_REQUEST'
export const LABORATORIES_DETAILS_SUCCESS = 'LABORATORIES_DETAILS_SUCCESS'
export const LABORATORIES_DETAILS_FAIL = 'LABORATORIES_DETAILS_FAIL'

export const LABORATORIES_ADD_OFFER_REQUEST = 'LABORATORIES_ADD_OFFER_REQUEST'
export const LABORATORIES_ADD_OFFER_SUCCESS = 'LABORATORIES_ADD_OFFER_SUCCESS'
export const LABORATORIES_ADD_OFFER_FAIL = 'LABORATORIES_ADD_OFFER_FAIL'

export const RAYS_LIST_REQUEST = 'RAYS_LIST_REQUEST'
export const RAYS_LIST_SUCCESS = 'RAYS_LIST_SUCCESS'
export const RAYS_LIST_FAIL = 'RAYS_LIST_FAIL'

export const RAYS_ADD_REQUEST = 'RAYS_ADD_REQUEST'
export const RAYS_ADD_SUCCESS = 'RAYS_ADD_SUCCESS'
export const RAYS_ADD_FAIL = 'RAYS_ADD_FAIL'

export const RAYS_DETAILS_REQUEST = 'RAYS_DETAILS_REQUEST'
export const RAYS_DETAILS_SUCCESS = 'RAYS_DETAILS_SUCCESS'
export const RAYS_DETAILS_FAIL = 'RAYS_DETAILS_FAIL'

export const RAYS_ADD_OFFER_REQUEST = 'RAYS_ADD_OFFER_REQUEST'
export const RAYS_ADD_OFFER_SUCCESS = 'RAYS_ADD_OFFER_SUCCESS'
export const RAYS_ADD_OFFER_FAIL = 'RAYS_ADD_OFFER_FAIL'

export const MEDICAL_TEAM_DATA_ADD_REQUEST = 'MEDICAL_TEAM_DATA_ADD_REQUEST'
export const MEDICAL_TEAM_DATA_ADD_SUCCESS = 'MEDICAL_TEAM_DATA_ADD_SUCCESS'
export const MEDICAL_TEAM_DATA_ADD_FAIL = 'MEDICAL_TEAM_DATA_ADD_FAIL'

export const MEDICAL_TEAM_DATA_LIST_REQUEST = 'MEDICAL_TEAM_DATA_LIST_REQUEST'
export const MEDICAL_TEAM_DATA_LIST_SUCCESS = 'MEDICAL_TEAM_DATA_LIST_SUCCESS'
export const MEDICAL_TEAM_DATA_LIST_FAIL = 'MEDICAL_TEAM_DATA_LIST_FAIL'

export const MEDICAL_TEAM_DATA_LIST_ALL_REQUEST = 'MEDICAL_TEAM_DATA_LIST_ALL_REQUEST'
export const MEDICAL_TEAM_DATA_LIST_ALL_SUCCESS = 'MEDICAL_TEAM_DATA_LIST_ALL_SUCCESS'
export const MEDICAL_TEAM_DATA_LIST_ALL_FAIL = 'MEDICAL_TEAM_DATA_LIST_ALL_FAIL'

export const MEDICAL_TEAM_DETAILS_REQUEST = 'MEDICAL_TEAM_DETAILS_REQUEST'
export const MEDICAL_TEAM_DETAILS_SUCCESS = 'MEDICAL_TEAM_DETAILS_SUCCESS'
export const MEDICAL_TEAM_DETAILS_FAIL = 'MEDICAL_TEAM_DETAILS_FAIL'

export const MEDICAL_TEAM_UPDATE_REQUEST = 'MEDICAL_TEAM_UPDATE_REQUEST'
export const MEDICAL_TEAM_UPDATE_SUCCESS = 'MEDICAL_TEAM_UPDATE_SUCCESS'
export const MEDICAL_TEAM_UPDATE_FAIL = 'MEDICAL_TEAM_UPDATE_FAIL'
export const MEDICAL_TEAM_UPDATE_RESET = 'MEDICAL_TEAM_UPDATE_RESET'

export const MEDICAL_TEAM_DELETE_REQUEST = 'MEDICAL_TEAM_DELETE_REQUEST'
export const MEDICAL_TEAM_DELETE_SUCCESS = 'MEDICAL_TEAM_DELETE_SUCCESS'
export const MEDICAL_TEAM_DELETE_FAIL = 'MEDICAL_TEAM_DELETE_FAIL'

export const MEDICAL_TEAM_INSURRENCE_REQUEST = 'MEDICAL_TEAM_INSURRENCE_REQUEST'
export const MEDICAL_TEAM_INSURRENCE_SUCCESS = 'MEDICAL_TEAM_INSURRENCE_SUCCESS'
export const MEDICAL_TEAM_INSURRENCE_FAIL = 'MEDICAL_TEAM_INSURRENCE_FAIL'

export const COLLECT_INVOICES_LIST_REQUEST = 'COLLECT_INVOICES_LIST_REQUEST'
export const COLLECT_INVOICES_LIST_SUCCESS = 'COLLECT_INVOICES_LIST_SUCCESS'
export const COLLECT_INVOICES_LIST_FAIL = 'COLLECT_INVOICES_LIST_FAIL'

export const COLLECT_INVOICES_OLD_LIST_REQUEST = 'COLLECT_INVOICES_OLD_LIST_REQUEST'
export const COLLECT_INVOICES_OLD_LIST_SUCCESS = 'COLLECT_INVOICES_OLD_LIST_SUCCESS'
export const COLLECT_INVOICES_OLD_LIST_FAIL = 'COLLECT_INVOICES_OLD_LIST_FAIL'

export const COLLECT_INVOICES_NEW_LIST_REQUEST = 'COLLECT_INVOICES_NEW_LIST_REQUEST'
export const COLLECT_INVOICES_NEW_LIST_SUCCESS = 'COLLECT_INVOICES_NEW_LIST_SUCCESS'
export const COLLECT_INVOICES_NEW_LIST_FAIL = 'COLLECT_INVOICES_NEW_LIST_FAIL'

export const INSURRENCE_ADD_OFFER_REQUEST = 'INSURRENCE_ADD_OFFER_REQUEST'
export const INSURRENCE_ADD_OFFER_SUCCESS = 'INSURRENCE_ADD_OFFER_SUCCESS'
export const INSURRENCE_ADD_OFFER_FAIL = 'INSURRENCE_ADD_OFFER_FAIL'

export const INSURRENCE_OFFER_UPDATE_REQUEST = 'INSURRENCE_OFFER_UPDATE_REQUEST'
export const INSURRENCE_OFFER_UPDATE_SUCCESS = 'INSURRENCE_OFFER_UPDATE_SUCCESS'
export const INSURRENCE_OFFER_UPDATE_FAIL = 'INSURRENCE_OFFER_UPDATE_FAIL'
export const INSURRENCE_OFFER_UPDATE_RESET = 'INSURRENCE_OFFER_UPDATE_RESET'

export const INSURRENCE_OFFERS_LIST_REQUEST = 'INSURRENCE_OFFERS_LIST_REQUEST'
export const INSURRENCE_OFFERS_LIST_SUCCESS = 'INSURRENCE_OFFERS_LIST_SUCCESS'
export const INSURRENCE_OFFERS_LIST_FAIL = 'INSURRENCE_OFFERS_LIST_FAIL'

export const INSURRENCE_OFFER_DETAILS_REQUEST = 'INSURRENCE_OFFER_DETAILS_REQUEST'
export const INSURRENCE_OFFER_DETAILS_SUCCESS = 'INSURRENCE_OFFER_DETAILS_SUCCESS'
export const INSURRENCE_OFFER_DETAILS_FAIL = 'INSURRENCE_OFFER_DETAILS_FAIL'
export const INSURRENCE_OFFER_DETAILS_RESET = 'INSURRENCE_OFFER_DETAILS_RESET'

export const INVOICE_ADD_OFFER_REQUEST = 'INVOICE_ADD_OFFER_REQUEST'
export const INVOICE_ADD_OFFER_SUCCESS = 'INVOICE_ADD_OFFER_SUCCESS'
export const INVOICE_ADD_OFFER_FAIL = 'INVOICE_ADD_OFFER_FAIL'
