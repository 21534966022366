import "../styles/thanks_msg.css";
import "../styles/thanks_subscriptionamount.css";

import ControlPanelHeader from "./ControlPanelHeader";
import arrow from "../imgs/arrow.png";
import { Link } from "react-router-dom";

function ThanksMedicalTeam ({}) {

     
    return (
      <div className="thanks_SA">
      <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest = {'/collectServices'}
        style={"three_section"}
      />
       <div className='thans_SA_content'>
         <div className="msg_content text-center" style ={{ height:"300px" }}>
             <p className="thanks">
             ! شكرًا 
             </p>
             <p className="txt" style={{ width: "540px", left: "131px" }}>نشكركم على إرسال المعلومات, ستقوم إدارة بيان بمراجعة المعلومات والملفات المرفقة ومن ثم عرض البيانات في قائمة الموظفين
             </p>
             <Link to='/comparingMedicalMistakeWork'>
              <button className="back-btn">
                      <img className='icon' src={arrow} alt="" />
                      عودة إلى صفحة الموظفين
                  </button>
              </Link>
           </div>
       </div>
     </div>
    );
  }

export default ThanksMedicalTeam;

