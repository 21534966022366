import { useState } from "react";
import { useParams } from "react-router-dom";
import InvoiceTable from "./InvoiceTable";
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import "./newOrder.css";
import QRCode from "react-qr-code";
import Hdate from "./Hdate";
import NewCard from "./newCard";
import logo from "./logo.svg";
import social1 from "./social1.svg";
import social2 from "./social2.svg";
import social3 from "./social3.svg";
import social4 from "./social4.svg";

function NewOrder() {
  let img = [
    "https://via.placeholder.com/70x70/",
    "https://via.placeholder.com/71x71/",
    "https://via.placeholder.com/72x72/",
    "https://via.placeholder.com/73x73/",
  ];
  const [radioValue, setRadioValue] = useState("sell");
  const [radioValueN, setRadioValueN] = useState("new");
  const [bigImage, setBigImage] = useState(img[0]);

  let title1 = ["بيانات مقدم العرض"];
  let descreption =
    "  وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية";

  let data1 = {
    الاسم: "أحمد",
    العمر: 29,
    الجنس: "ذكر",
    البلد: "السعودية",
    الاسم1: "أحمد",
    العمر1: 29,
    الجنس1: "ذكر",
    البلد1: "السعودية",
    الاسم2: "أحمد",
    العمر2: 29,
    الجنس2: "ذكر",
    البلد2: "السعودية",
  };
  function handleClick() {
    window.print();
  }

  return (
    <div className="w-100">
      <Container className="inv_a4 screen">
        <Button
          onClick={() => handleClick()}
          className="print_hide m-4 inv_button-print bg-secondary"
        >
          طباعة
        </Button>
        <div className="header_c" />
        <h1 className="inv_h1">إضافة عرض</h1>
        <img className="inv_img" alt="logo" src={logo} />
        <Hdate
          className="inv_Hdate_table"
          date="22/5/2021"
          code="25G54"
          codeTitle="كود العرض:"
        />
        <p className="inv_QR_title">رمز العرض:</p>
        <QRCode className="inv_QR" value={window.location.href} size="75" />
        <div className="inv_data">
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
        </div>
        <div className="inv_table">
          <Row>
            <Col lg="12">
              <InvoiceTable
                className="inv_table_from"
                title={title1}
                data={data1}
                type="4"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4">
              <NewCard
                img={img}
                title="العنوان"
                descreption={descreption}
                price="800"
                weight="50"
                radioValue={radioValue}
                radioValueN={radioValueN}
                bigImage={bigImage}
                handleButtons={(e) => setRadioValue(e)}
                handleButtonsN={(e) => setRadioValueN(e)}
                handleImages={(e) => setBigImage(e)}
              />
            </Col>
          </Row>
          <div className="new_boxs">
            <Row>
              <Col lg="6">
                <p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
              <Col lg="6">
                <p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
            </Row>
            <Row className="new_sign">
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="inv_footer">
          <span className="footer_text">الإيميل : info@bayan.med.sa</span>
          <div className="social_neworder_div">
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social4} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social3} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social2} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social1} />
            </a>
          </div>
        </div>
      </Container>
      <page size="A4" className="print">
        <div className="header_c" />
        <h1 className="inv_h1">إضافة طلب</h1>
        <img className="inv_img" alt="logo" src={logo} />
        <Hdate
          className="inv_Hdate_table"
          date="22/5/2021"
          code="25G54"
          codeTitle="كود العرض:"
        />
        <p className="inv_QR_title">كود التسجيل:</p>
        <QRCode className="inv_QR" value={window.location.href} size="75" />
        <div className="inv_data">
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
        </div>
        <div className="inv_table">
          <Row>
            <Col lg="12">
              <InvoiceTable
                className="inv_table_from"
                title={title1}
                data={data1}
                type="4"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4">
              <NewCard
                img={img}
                title="العنوان"
                descreption={descreption}
                price="800"
                weight="50"
                radioValue={radioValue}
                radioValueN={radioValueN}
                bigImage={bigImage}
                handleButtons={(e) => setRadioValue(e)}
                handleButtonsN={(e) => setRadioValueN(e)}
                handleImages={(e) => setBigImage(e)}
              />
            </Col>
          </Row>
          <div className="new_boxs">
            <Row>
              <Col lg="6">
                <p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
              <Col lg="6">
                <p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
            </Row>
            <Row className="new_sign">
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="inv_footer">
          <span className="footer_text">الإيميل : info@bayan.med.sa</span>
          <div className="social_neworder_div">
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social4} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social3} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social2} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social1} />
            </a>
          </div>
        </div>
      </page>
    </div>
  );
}
export default NewOrder;
