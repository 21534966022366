import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css";
import Paginate from '../../component/Paginate'
import SearchBox from '../../component/SearchBox'
import ControlPanelHeader from "../../component/ControlPanelHeader";
import { Table, Button, Form, Row, Modal } from "react-bootstrap";
import "../../styles/TableNewDesign.css";

import "../../styles/Medical_Technical_TeamList.css";
import "tippy.js/dist/tippy.css";
import { Link } from "react-router-dom";
import {listMedicalTeam, deleteMedicalTeam, requestInsurrenceMedicalTeam} from '../../actions/serviceActions' 

function MedicalTechnicalTeamListScreen({ history }) {

    const [selectValue, setSelectValue] = useState("");
    const [show, setShow] = useState(false)

    const dispatch = useDispatch()

    const medicalTeamList = useSelector(state => state.medicalTeamList)
    const {error, loading, medicalTeam, page, pages} = medicalTeamList

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const medicalTeamDelete = useSelector(state => state.medicalTeamDelete)
    const {error:errorDelete, loading:loadingDelete, success:successDelete} = medicalTeamDelete

    const medicalTeamRequestInsurrence = useSelector(state => state.medicalTeamRequestInsurrence)
    const {error:errorRequest, loading:loadingRequest, success:successRequest} = medicalTeamRequestInsurrence

    let keyword = history.location.search

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        } else {
          dispatch(listMedicalTeam(keyword))        
        }     
      }
    
    }, [dispatch, history, userInfo, keyword, successDelete, successRequest])

    let titles = [
        "الرقم التعريفي",
        "رقم الهويه / الاقامة",
        "اسم الدكتور",
        "التخصص",
        "الجنسية",
        "الجنس",
        "المدينة",
        "تاريخ إنتهاء التأمين",
        "حالة التأمين",
    ]

    
    const more = {
      title: "الاجراءات",
      actions: {
          "طلب تأمين": "confirmRequestInsurance",
          "إضهار العروض": "/priceList",
          تعديل: "/editMedicalOrTechnicalTeam",
          حذف: "delete",
      },
    }

    const handleChange = (e) => {
      setSelectValue(e.target.value);
    }

    const handleMore = (id) => {
      return Object.keys(more.actions).map((key, index) => {
        let path = Object.values(more.actions)[index] + '/' + id;
        
        return <option value={path} key={index}>{key}</option>;
      });
    }

    const handleDelete = (id) => {
      dispatch(deleteMedicalTeam(id))
      setShow(false)
    }

    const InsurrenceRequestHandle = (id) => {
      dispatch(requestInsurrenceMedicalTeam(id))
      history.push("/confirmRequestInsurance")
    }

    const showModel = () => {
      let teamId = (selectValue.split('/'))[1]
      return(
      <Modal
          style={{ direction: "rtl" }}
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
          <Modal.Header style={{backgroundColor: 'var(--main-color)', justifyContent:'center'}}>
              <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h4">
          يرجى تأكيد الحذف
          </Modal.Body>
          <Modal.Footer>
              <Button
                  variant="danger"
                  className="btn-red"
                  onClick={() => setShow(false)}
              >
                  <span className="h4">إلغاء</span>
              </Button>
              <Button
              variant="success"
              className="btn-main px-5"
              onClick={() => handleDelete(teamId)}
              >
              <span className="h4">تأكيد</span>
              </Button>
          </Modal.Footer>
      </Modal>
      )
      
    }
  
    return (
        <div className="list_page">
            <ControlPanelHeader
                exit1={true}
                notifi={true}
                msg={true}
                files={false}
                stat={false}
                msgDest={"/Messages"}
                notifidest={"/Notifications"}
                exitDest={"/services"}
                fileDest={"/FacilityFiles"}
                statDest = {'/collectServices'}
                style={"threetwo_section"}
            />

            <div className="box_serach">
                <Link to="/addMedicalOrTechnicalTeam">
                    <button className="add_btn">إضافة الطاقم الطبي والفني</button>
                </Link>
                <div className="input_group">
                <SearchBox link='/comparingMedicalMistakeWork'/>
                </div>
            </div>
            <div className="bills_list">
                <div className="heading text-center">قائمة الطاقم الطبي والفني</div>
                <div className="bills_table scroll">
                <Table bordered hover className="TableNewDesign">
                <thead>
                  <tr>
                  {titles.map((title, x) => (
                      <th key={x+1}>{title}</th>
                    ))
                  }
                  {more ? <th>{more.title}</th> : ""}
                    
                  </tr>
                </thead>
                <tbody>
                  {medicalTeam.map((obj) => (
                      <tr key={obj.id}>
                        <td>ID-{obj._id}</td>
                        <td>{obj.id_num}</td>
                        <td>{obj.ar_applicant_name}</td>  
                        <td>{obj.medical_specialist}</td>
                        <td>{obj.nationality}</td>
                        <td>{obj.gender}</td>
                        <td>{obj.city}</td>
                        <td>{obj.insurance_certificate_expire_date}</td>
                        <td>
                          <Button
                            variant={
                              obj.insurance_status === "A"
                                ? "success"
                                : obj.insurance_status === "N"
                                ? "warning"
                                : "danger"
                            }
                            className={
                              obj.insurance_status === "A"
                                ? "btn-main px-5"
                                :  obj.insurance_status === "N"
                                ? "btn-y"
                                : "btn-red px-5"
                            }
                          >
                            {obj.insurance_status === "A"
                                ? "نشط"
                                :  obj.insurance_status === "N"
                                ? "قارب على الأنتهاء"
                                : "أنتهى"
                            
                            }
                          </Button>
                        </td>

                        {more ? (
                          <td className="actions">
                            <Row className="m-2">
                              <Form.Control as="select"
                                onChange={handleChange}
                                className="text-center px-1 pe-2"
                              >
                                <option>
                                  أختر
                                </option>
                                {handleMore(obj.id)}
                              </Form.Control>
                              {(selectValue.includes("/editMedicalOrTechnicalTeam") || selectValue.includes("/priceList"))  ?
                                (
                                  <Link to={selectValue} className="btn-outline-main btn-outline-info btn" style={{ marginRight: "0", marginTop: "10px" }}>
                                  تنفيذ
                                  </Link>
                                ) : selectValue.includes("delete") ?  (
                                  <Button onClick={()=> setShow(true)} className="btn-outline-main btn-outline-info btn" style={{ marginRight: "0", marginTop: "10px" }}>
                                    تنفيذ   
                                  </Button>
                                ) : (<Button onClick={()=> InsurrenceRequestHandle(obj.id)} className="btn-outline-main btn-outline-info btn" style={{ marginRight: "0", marginTop: "10px" }}>
                                تنفيذ   
                              </Button>)
                              }                             
                            </Row>
                          </td>                          
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  }                
                </tbody>
                
              </Table>
              <div>{showModel()}</div>
              
                </div>
                <Paginate page = {page} pages = {pages} keyword= {keyword} link="/comparingMedicalMistakeWork" />
                
            </div>
            

        </div>
        );       
    }

export default MedicalTechnicalTeamListScreen
