import { Row, Col, Card } from "react-bootstrap";

function NewCard({
  img,
  dataType,
  title,
  price,
  weight,
  type,
  descreption,
  bigImage,
  smallPhoto,
  radioValue,
  radioValueN,
  handleButtons,
  handleButtonsN,
  handleImages,
}) {
  return (
    <Card className="direction h-100 w_costom ">
      <Row>
        <Col
          lg={(type !== "تأجير خدمة الأطباء" && type !== "laboratory" && type !== "ray")  ? 6 : !img ? 6 : 12}
          className={type !== "تأجير خدمة الأطباء" && type !== "laboratory" && type !== "ray"  ? (`border-start text-end new_card`): (`text-end new_card`) }
        >
          <Card.Body>
            <Card.Title>
              {title} <br />
              {dataType === "1" ? (
                <span className="h5">نوع الخدمة: {type}</span>
              ) : (
                ""
              )}
              {dataType === "2" ? (
                <span className="h5">نوع القطاع: {type}</span>
              ) : (
                ""
              )}
            </Card.Title>
            <div className="">
              <h4 className="price h5">
                {price ? (
                  <span>
                    السعر: {price}
                    <span className="text-muted">
                      {!isNaN(parseInt(price)) ? " ريال " : ""}
                    </span>
                  </span>
                ) : (
                  ""
                )}
                {weight ? (
                  <span>
                    الوزن: {weight}
                    <span className="text-muted"> كغ</span>
                  </span>
                ) : (
                  ""
                )}
              </h4>
            </div>
            <p className="new_descreption">{descreption}</p>
          </Card.Body>
        </Col>
        {(type !== "تأجير خدمة الأطباء" && type !== "laboratory" && type !== "ray") ? (
           <Col lg={smallPhoto ? 2 : 6}>
            <div className="gallery-wrap">
              {!smallPhoto ? (
                <img className="img-big-wrap" alt="img" src={bigImage} />
              ) : (
                <img className="img-small-wrap" alt="img" src={bigImage} />
              )}
              {(type !== "تأجير خدمة الأطباء" && type !== "تأجير خدمة العيادات" && type !== "laboratory" && type !== "ray") ? (
                <div className="thumbs-wrap">
                  {img.map((key, x) => {
                    return (
                      <a onClick={() => handleImages(key)} key={x+1}>
                        <img className="item-thumb" alt="img" src={key} />
                      </a>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Card>
  );
}
export default NewCard;
