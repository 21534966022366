const menuItems = {
	items: [
		{
			id: "navigation",
			title: "",
			type: "group",
			icon: "icon-navigation",
			children: [
				{
					id: "العروض و الطلبات",
					title: "العروض و الطلبات",
					type: "collapse",
					icon: "feather icon-layout",
					children: [
						{
							id: "تأجير أو بيع الاجهزة الطبية",
							title: "تأجير أو بيع الاجهزة الطبية",
							type: "item",
							url: "/client/offer/selling-medical-devices",
						},
						{
							id: "تأجير خدمات الاطباء و العيادات",
							title: "تأجير خدمات الاطباء و العيادات",
							type: "item",
							url: "/client/offer/renting-medical",
						},
						{
							id: "التحصيل",
							title: "التحصيل",
							type: "item",
							url: "/client/offer/collecting",
						},
						{
							id: "تنظيم وتشغيل عقود المختبرات",
							title: "تنظيم وتشغيل عقود المختبرات",
							type: "item",
							url: "/client/offer/labs",
						},
						{
							id: "تنظيم وتشغيل عقود الأشعة",
							title: "تنظيم وتشغيل عقود الأشعة",
							type: "item",
							url: "/client/offer/rays",
						},
						{
							id: "تنظيم مناقصات التوريد",
							title: "تنظيم مناقصات التوريد",
							type: "collapse",
							children: [
								{
									id: "الغذائية",
									title: "الغذائية",
									type: "item",
									url: "/client/offers/tenders/food",
								},
								{
									id: "القرطاسية المكتبية",
									title: "القرطاسية المكتبية",
									type: "item",
									url: "/client/offer/tenders/library",
								},
								{
									id: "النظافة",
									title: "النظافة",
									type: "item",
									url: "/client/offers/tenders/cleaning",
								},
								{
									id: "الأثاث المكتبي",
									title: "الأثاث المكتبي",
									type: "item",
									url: "/client/offer/tenders/office",
								},
								{
									id: "النقل",
									title: "النقل",
									type: "item",
									url: "/client/offer/tenders/transportation",
								},
								{
									id: "الغسيل",
									title: "الغسيل",
									type: "item",
									url: "/client/offer/tenders/laundry",
								},
								{
									id: "ملابس الزي الموحد",
									title: "ملابس الزي الموحد",
									type: "item",
									url: "/client/offer/tenders/uniform",
								},
								{
									id: "التشغيل والصيانة",
									title: "التشغيل والصيانة",
									type: "item",
									url: "/client/offer/tenders/operating",
								},
								{
									id: "التوظيف والخدمات",
									title: "التوظيف والخدمات",
									type: "item",
									url: "/client/offer/tenders/jobs",
								},
							],
						},
					],
					forbidden: ['company']
				},
				{
					id: "التأمينات",
					title: "التأمينات",
					type: "collapse",
					icon: "feather icon-layout",
					forbidden: ['company', 'client'],
					children: [
						{
							id: "طلبات التأمين",
							title: "طلبات التأمين",
							type: "item",
							url: "/client/medical-team-insurrence-request"
						},
						{
							id: "الطاقم الفني والإداري",
							title: "الطاقم الفني والإداري",
							type: "item",
							url: "/client/all-medical-team"
						},
						{
							id: "طلبات تقيم الشبكات الطبية",
							title: "طلبات تقيم الشبكات",
							type: "item",
							url: "/client/rate-medical-networks"
						},
					],
				},
				{
					id: "الفواتير",
					title: "الفواتير",
					type: "collapse",
					icon: "feather icon-layout",
					forbidden: ['company'],
					children: [
						{
							id: "فواتير جديدة",
							children: [
								{
									id: "تقييم الشبكات الطبية",
									title: "تقييم الشبكات الطبية",
									type: "item",
									url: "/client/new-bills/rate-network",
								},
								{
									id: "تأجير أو بيع الاجهزة الطبية",
									title: "تأجير أو بيع الاجهزة الطبية",
									type: "item",
									url: "/client/new-bills/medical-devices",
								},
								{
									id: "تأجير خدمات الاطباء و العيادات",
									title: "تأجير خدمات الاطباء و العيادات",
									type: "item",
									url: "/client/new-bills/medical-rent",
								},
								{
									id: "مقارنة أسعار تامين اخطاء ممارسة المهن الطبية",
									title: "مقارنة أسعار تامين اخطاء ممارسة المهن الطبية",
									type: "item",
									url: "/client/new-bills/comparing-prices",
								},
								{
									id: "التحصيل",
									title: "التحصيل",
									type: "item",
									url: "/client/new-bills/collecting",
								},
								{
									id: "تنظيم وتشغيل عقود المختبرات",
									title: "تنظيم وتشغيل عقود المختبرات",
									type: "item",
									url: "/client/new-bills/labs",
								},
								{
									id: "تنظيم وتشغيل عقود الأشعة",
									title: "تنظيم وتشغيل عقود الأشعة",
									type: "item",
									url: "/client/new-bills/rays",
								},
								{
									id: "تنظيم مناقصات التوريد",
									title: "تنظيم مناقصات التوريد",
									type: "collapse",
									children: [
										{
											id: "الغذائية",
											title: "الغذائية",
											type: "item",
											url: "/client/new-bills/tenders/food",
										},
										{
											id: "القرطاسية المكتبية",
											title: "القرطاسية المكتبية",
											type: "item",
											url: "/client/new-bills/tenders/library",
										},
										{
											id: "النظافة",
											title: "النظافة",
											type: "item",
											url: "/client/new-bills/tenders/cleaning",
										},
										{
											id: "الأثاث المكتبي",
											title: "الأثاث المكتبي",
											type: "item",
											url: "/client/new-bills/tenders/office",
										},
										{
											id: "النقل",
											title: "النقل",
											type: "item",
											url: "/client/new-bills/tenders/transportation",
										},
										{
											id: "الغسيل",
											title: "الغسيل",
											type: "item",
											url: "/client/new-bills/tenders/laundry",
										},
										{
											id: "ملابس الزي الموحد",
											title: "ملابس الزي الموحد",
											type: "item",
											url: "/client/new-bills/tenders/uniform",
										},
										{
											id: "التشغيل والصيانة",
											title: "التشغيل والصيانة",
											type: "item",
											url: "/client/new-bills/tenders/operating",
										},
										{
											id: "التوظيف والخدمات",
											title: "التوظيف والخدمات",
											type: "item",
											url: "/client/new-bills/tenders/job",
										},
									],
								},
							],
							title: "فواتير جديدة",
							type: "collapse",
						},
						{
							id: "فواتير قديمة",
							title: "فواتير قديمة",
							type: "item",
							url: "/client/old-bills-admin",
						},
						{
							id: "النقاش",
							title: "النقاش",
							type: "item",
							url: "/client/discussion",
							state: "soon",
						},
					],
				},
				{
					id: "الرسائل",
					title: "الرسائل",
					type: "item",
					url: "/messages",
					icon: "feather icon-message-square",
				},
				{
					id: "الاشعارات",
					title: "الاشعارات",
					type: "item",
					url: "/notifications",
					icon: "feather icon-bell",
				},
				{
					state: "soon",
					id: "الإحصائيات",
					title: "الإحصائيات",
					type: "collapse",
					icon: "feather icon-home",
					forbidden: ['company'],
					children: [
						{
							id: "تقيم الشبكات الطبية",
							title: "تقيم الشبكات الطبية",
							type: "item",
							url: "/client/medical-network-assessment",
						},
						{
							id: "تأجير أوبيع الأجهزة الطبية",
							title: "تأجير أوبيع الأجهزة الطبية",
							type: "item",
							url: "/client/rental-medical-devices",
						},
						{
							id: "تأجير خدمات الأطباء او العيادات",
							title: "تأجير خدمات الأطباء او العيادات",
							type: "item",
							url: "/client/renting-doctors-clinics",
						},
						{
							id: "مقارنة أسعار تأمين أخطاء ممارسة المهن الطبية",
							title: "مقارنة أسعار تأمين أخطاء ممارسة المهن الطبية",
							type: "item",
							url: "/client/comoarison-medical-insurance",
						},
						{
							id: "التحصيل",
							title: "التحصيل",
							type: "item",
							url: "/client/achievement",
						},
						{
							id: "تنظيم وتشغيل عقود المختبرات",
							title: "تنظيم وتشغيل عقود المختبرات",
							type: "item",
							url: "/client/operating-laboratory-contracts",
						},
						{
							id: "تنظيم وتشغيل عقود الأشعة",
							title: "تنظيم وتشغيل عقود الأشعة",
							type: "item",
							url: "/client/operating-radiology-contracts",
						},
						{
							id: "تنظيم مناقصات التوريد",
							title: "تنظيم مناقصات التوريد",
							type: "item",
							url: "/client/organizing-supply-tenders",
						},
					],
				},
			],
		},
	],
};

export default menuItems;
