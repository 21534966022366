import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logout from "../imgs/Group 2306.png";
import file from "../imgs/file.png";
import message from "../imgs/Group 191.png";
import notification from "../imgs/Path 2243.png";
import home from "../imgs/home.png";
import user from "../imgs/user (1).png";
import barchart from '../imgs/bar-chart.png'
import settings from '../imgs/settings.png'
import bills from '../imgs/bill.png'
import employees from '../imgs/employee.png'
import clients from '../imgs/value.png'
import power from '../imgs/Group 2312.png'
import "../styles/control_panel_headr.css";
import { BrowserRouter, Link } from "react-router-dom";
import { userLogout } from '../actions/userActions'
import axios from 'axios';

function ControlPanelHeader({ exit, exit1, files, style, exitDest, msg, msgDest, notifi, notifidest, change, changeDest, setting, settingDest, stat, statDest, system, systemdest, powers, bill, billDest, employee, employeeDest, client, clientDest, file, fileDest, controlpanle, controlpanleDest, userDest, special, specialDest }) {

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const [notificationsCount, setNotificationsCount] = useState(0);


    useEffect(() => {

        axios
            .get("/notifications_count/", {
                headers: { Authorization: `Bearer ${userInfo.access}` },
            })
            .then(({ data }) => {
                setNotificationsCount(parseInt(data?.count) ?? 0);
            });
    }, []);

    const logoutHandler = () => {
        dispatch(userLogout())
    }

    return (
        <div className="cp_header">
            <div className={style}>
                {(exit || exit1) && (
                    <div className="link_item">
                        <div style={{ width: "83px" }} onClick={logoutHandler}>تسجيل خروج</div>
                        <img src={logout} alt="" />
                    </div>
                )}

                {msg && (
                    <Link className="link_item" to={msgDest}>
                        <div>الرسائل</div>
                        <img src={message} alt="" />
                    </Link>
                )}

                {notifi && (
                    <Link className="link_item" to={notifidest}>
                        <div>الاشعارات</div>
                        <img src={notification} alt="" />
                        {notificationsCount > 0 && (
                            <span class="notification-count">{notificationsCount}</span>
                        )
                        }
                    </Link>
                )}

                {change && (
                    <div className="link_item">
                        <Link to={changeDest}>
                            <div>تغيير تصنيف</div>
                            <img src={file} alt="" />
                        </Link>
                    </div>
                )}



                {setting && (
                    <div className="link_item">
                        <Link to={settingDest}>
                            <div>الاعدادات</div>
                            <img src={settings} alt="" />
                        </Link>
                    </div>
                )}

                {stat && (
                    <div className="link_item">
                        <Link to={statDest}>
                            <div>الاحصائيات</div>
                            <img src={barchart} alt="" />
                        </Link>
                    </div>
                )}

                {system && (
                    <div className="link_item">
                        <Link to={systemdest}>
                            <div>ملف النظام</div>
                            <img src={file} alt="" />
                        </Link>
                    </div>
                )}

                {powers && (
                    <div className="link_item">
                        <Link to="/powers">
                            <div>الصلاحيات</div>
                            <img src={power} alt="" />
                        </Link>
                    </div>
                )}
                {bill && (
                    <div className="link_item">
                        <Link to={billDest}>
                            <div>الفواتير</div>
                            <img src={bills} alt="" />
                        </Link>
                    </div>
                )}

                {employee && (
                    <div className="link_item">
                        <Link to={employeeDest}>
                            <div>الموظفين</div>
                            <img src={employees} alt="" />
                        </Link>
                    </div>
                )}

                {client && (
                    <div className="link_item">
                        <Link to={clientDest}>
                            <div>العملاء</div>
                            <img src={clients} alt="" />
                        </Link>
                    </div>
                )}

                {files && (
                    <div className="link_item">
                        <Link to={fileDest}>
                            <div>ملف المنشأة</div>
                            <img src={file} alt="" />
                        </Link>
                    </div>
                )}

                {controlpanle && (
                    <div className="link_item">
                        <Link to={controlpanleDest} style={{  }}>
                            <div>لوحة تحكم العميل</div>
                            <img src={file} alt="" />
                        </Link>
                    </div>
                )}
                <div className="link_item">
                    <Link to="/" style={{ margin: 0 }}>
                        <div>الصفحة الرئيسية</div>
                        <img src={file} alt="" />
                    </Link>
                </div>


                {special && (
                    <div className="link_item">
                        <Link to={specialDest}>
                            <div>حالةلااستثنائية</div>
                            <img src={clients} alt="" />
                        </Link>
                    </div>
                )}


            </div>

        </div>
    );
}

export default ControlPanelHeader;
