import "../../styles/confirm_update.css";
import Control_panel_Header from "../Control_panel_Header";
import confirm from "../../imgs/checked.png";
import arrow from "../../imgs/Group 224.png";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function ConfirmSinup ({history}) {

    const [path, setPath] = useState('SubscriptionPayment')

    const dispatch = useDispatch()

    const facilityInfoRegister = useSelector(state => state.facilityInfoRegister)

    const {facility } = facilityInfoRegister

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
      if (!userInfo) {
        history.push('/')
      } else {
        if (facility.sector_type === 28) {
          setPath('/ThanksSubscriptionForInsuranceCompany')
        } 
      }

    }, [dispatch, history, userInfo])


    
    
  
    return (
        <div>
          <Control_panel_Header
            exit1={true} notifi={true} msg={true}  msgDest={"/Messages"}
            notifidest={"/Notifications"} exitDest={"/"} style={"three_section"}
          />
          <div className="confirm-content text-center">
            <div className="confirm-update text-center">
              <img className="confirm" src={confirm} alt="" />
              <p className="confirm-title">تم إكمال التسجيل بنجاح</p>
            </div>
            <Link to={path}>
              <button className="back-btn">
                <img src={arrow} alt="" />
                الذهاب لعملية الدفع
              </button>
            </Link>
          </div>
        </div>
    );
  }

export default ConfirmSinup;
