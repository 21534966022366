import axios from "axios";

export const addEmployee = async (employee, user) => {
  const request = await axios.post(
    "/employees_of_bayan/",
    createFormDataFromObject(employee),
    {
      headers: {
        Authorization: `Bearer ${user.access}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

const createFormDataFromObject = (obj) => {
  const formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

export default addEmployee;
