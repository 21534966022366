import { Row, Col, ToggleButton, Card, Form, Table } from "react-bootstrap";

function NewCard({
  img,
  title,
  price,
  weight,
  descreption,
  bigImage,
  radioValue,
  radioValueN,
  handleButtons,
  handleButtonsN,
  handleImages,
}) {
  return (
    <Card>
      <Row>
        <Col lg="6" className="border-start text-end new_card">
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <div class="">
              <h4 class="price">
                السعر: {price}
                <span class="text-muted"> ريال </span>
                الوزن: {weight}
                <span class="text-muted"> كغ</span>
              </h4>
            </div>
            <p class="new_descreption">{descreption}</p>
            <hr />
            <Row>
              <Col lg="7">
                <ToggleButton
                  className="px-4"
                  key="1"
                  id="sell"
                  type="radio"
                  variant={
                    radioValue === "sell" ? "outline-success" : "outline-danger"
                  }
                  name="radio"
                  value="sell"
                  checked={radioValue === "sell"}
                  onChange={(e) => handleButtons(e.currentTarget.value)}
                >
                  بيع
                </ToggleButton>
                <ToggleButton
                  className="me-1 px-3"
                  key="2"
                  id="rent"
                  type="radio"
                  variant={
                    radioValue === "rent" ? "outline-success" : "outline-danger"
                  }
                  name="radio"
                  value="rent"
                  checked={radioValue === "rent"}
                  onChange={(e) => handleButtons(e.currentTarget.value)}
                >
                  تأجير
                </ToggleButton>
              </Col>
              <Col lg="5">
                <Row>
                  <Form.Check
                    className="new_checkbox1"
                    type="checkbox"
                    checked="true"
                    id={1}
                    label="المنتج سليم"
                  />
                </Row>
                <Row>
                  <Form.Check
                    className="new_checkbox2"
                    type="checkbox"
                    checked="true"
                    id={2}
                    label="تحتاج توصيل بشحن"
                  />
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Col>
        <Col lg="6">
          <div class="gallery-wrap">
            <img class="img-big-wrap" alt="img" src={bigImage} />
            <div class="thumbs-wrap">
              {img.map((key) => {
                return (
                  <a onClick={() => handleImages(key)}>
                    <img class="item-thumb" alt="img" src={key} />
                  </a>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="col-lg-N">
          <ToggleButton
            className="me-3 px-3"
            key="1"
            id="new"
            type="radio"
            variant={
              radioValueN === "new" ? "outline-success" : "outline-danger"
            }
            name="radio"
            value="new"
            checked={radioValueN === "new"}
            onChange={(e) => handleButtonsN(e.currentTarget.value)}
          >
            جديد
          </ToggleButton>
          <ToggleButton
            className="me-1 px-1 fs-6"
            key="2"
            id="used"
            type="radio"
            variant={
              radioValueN === "used" ? "outline-success" : "outline-danger"
            }
            name="radio"
            value="used"
            checked={radioValueN === "used"}
            onChange={(e) => handleButtonsN(e.currentTarget.value)}
          >
            مستخدم
          </ToggleButton>
        </Col>
        <Col className="col-lg-NN">
          <div className="new_card_box ms-3">
            <Table>
              <tbody>
                <tr className="border rounded">
                  <td className="w-25 table_small grayBG">اسم الموظف</td>
                  <td className="w-25 table_small">احمد</td>
                  <td className="w-25 table_small grayBG">مدة العرض</td>
                  <td className="w-25 table_small">5 يوم</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
export default NewCard;
