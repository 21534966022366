import axios from "axios";

export const fetchSearchData = async (user) => {
	const request = await axios.get("/management/facility_search", {
		headers: { Authorization: `Bearer ${user.access}` },
	});
	if (request.status === 200 || 201) {
		return request.data;
	} else {
		return new Error("ليس لديك الصلاحيات");
	}
};

export const fetchFacilityLevels = async (user) => {
	const request = await axios.get("/management/levels", {
		headers: { Authorization: `Bearer ${user.access}` },
	});
	if (request.status === 200 || 201) {
		return request.data;
	} else {
		return new Error("ليس لديك الصلاحيات");
	}
};

export const fetchOneFacility = async (id, user) => {
	const request = await axios.get(`/api/users/facility/${id}`, {
		headers: { Authorization: `Bearer ${user.access}` },
	});
	if (request.status === 200 || 201) {
		return request.data;
	} else {
		return new Error("ليس لديك الصلاحيات");
	}
};

export const fetchSectors = async (user) => {
	const request = await axios.get("/management/sectors", {
		headers: { Authorization: `Bearer ${user.access}` },
	});
	if (request.status === 200 || 201) {
		return request.data;
	} else {
		return new Error("ليس لديك الصلاحيات");
	}
};

export const updateAllowedLevels = async (id, level, allowed_level, user) => {

	const formData = new FormData();
	formData.append("facility_level", level);
	formData.append("allowed_level", allowed_level);

	const request = await axios.patch(
		`/exception_update_facility/${id}/`,
		formData,
		{
			headers: {
				Authorization: `Bearer ${user.access}`
			},
		}
	);
	if (request.status === 200 || 201) {
		return request.data;
	} else {
		return new Error("ليس لديك الصلاحيات");
	}
};

export const updateSectors = async (id, sector1, sector2, user) => {
	const formData = new FormData();
	formData.append("sector1", sector1[0]);
	formData.append("sector_type1", sector1[1]);
	formData.append("sector2", sector2[0]);
	formData.append("sector_type2", sector2[1]);
	const request = await axios.patch(`/update_sectors/${id}/`, formData, {
		headers: {
			Authorization: `Bearer ${user.access}`,
			"Content-Type": "multipart/form-data",
		},
	});
	if (request.status === 200 || 201) {
		return request.data;
	} else {
		return new Error("ليس لديك الصلاحيات");
	}
};
