import '../styles/description_start_services.css'
import arrow from '../imgs/Group 224.png'

import { Link, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import email from '../imgs/email.png'


import Loader from '../component/Loader'
import Message from '../component/Message'


// import org from '../imgs/NetworkOrganisation.mp4'
// import ListSellMedicalDevices from '../imgs/ListSellMedicalDevices.mp4'
// import ListRentDoctorsClincsServices from '../imgs/ListRentDoctorsClincsServices.mp4'
// import comparingMedicalMistakeWork from '../imgs/comparingMedicalMistakeWork.mp4'
// import collectServices from '../imgs/collectServices.mp4'
// import ListLabsContracts from '../imgs/ListLabsContracts.mp4'
// import ListRayContract from '../imgs/ListRayContract.mp4'
// import TendersList from '../imgs/TendersList.mp4'

// import orgPoster from '../imgs/videos/cover/تنظيم و تقييم الشبكات الطبيه_1.jpg'
// import ListSellMedicalDevicesPoster from '../imgs/videos/cover/تاجير و بيع الاجهزه الطبيه_1.jpg'
// import ListRentDoctorsClincsServicesPoster from '../imgs/videos/cover/تنظيم وتشغيل عقود المختبرات_1.jpg'
// import comparingMedicalMistakeWorkPoster from '../imgs/videos/cover/مقارنة تأمين أخطاء المهنة الطبية (1).jpg'
// import collectServicesPoster from '../imgs/videos/cover/خدمة التحصيل_1.jpg'
// import ListLabsContractsPoster from '../imgs/videos/cover/تاجير خدمات العيادات و الاطباء_1.jpg'
// import ListRayContractPoster from '../imgs/videos/cover/تنظيم و تشغيل عقود الاشعه_1.jpg'
// import TendersListPoster from '../imgs/videos/cover/تنظيم مناقصات التوريد_1.jpg'

import orgPoster from '../imgs/videos/cover/medicalNetworks.jpg'
import ListSellMedicalDevicesPoster from '../imgs/videos/cover/ListSellMedicalDevicesPoster.jpg'
import ListRentDoctorsClincsServicesPoster from '../imgs/videos/cover/ListRentDoctorsClincsServicesPoster.jpg'
import comparingMedicalMistakeWorkPoster from '../imgs/videos/cover/comparingMedicalMistakeWorkPoster.jpg'
import collectServicesPoster from '../imgs/videos/cover/collectServicesPoster.jpg'
import ListLabsContractsPoster from '../imgs/videos/cover/ListLabsContractsPoster.jpg'
import ListRayContractPoster from '../imgs/videos/cover/ListRayContractPoster.jpg'
import TendersListPoster from '../imgs/videos/cover/TendersListPoster.jpg'

const org = "none";
const ListSellMedicalDevices = "none";
const ListRentDoctorsClincsServices = "none";
const comparingMedicalMistakeWork = "none";
const collectServices = "none";
const ListLabsContracts = "none";
const ListRayContract = "none";
const TendersList = "none";

// const orgPoster = "none";
// const ListSellMedicalDevicesPoster = "none";
// const ListRentDoctorsClincsServicesPoster = "none";
// const comparingMedicalMistakeWorkPoster = "none";
// const collectServicesPoster = "none";
// const ListLabsContractsPoster = "none";
// const ListRayContractPoster = "none";
// const TendersListPoster = "none";
const videos = {
    ' ': org,
    "/ListSellMedicalDevices": ListSellMedicalDevices,
    "/ListRentDoctorsClincsServices": ListRentDoctorsClincsServices,
    "/comparingMedicalMistakeWork": comparingMedicalMistakeWork,
    "/collectServices": collectServices,
    "/ListLabsContracts": ListLabsContracts,
    "/ListRayContract": ListRayContract,
    "/TendersList": TendersList,
};
const posters = {
    ' ': orgPoster,
    "/ListSellMedicalDevices": ListSellMedicalDevicesPoster,
    "/ListRentDoctorsClincsServices": ListLabsContractsPoster,
    "/comparingMedicalMistakeWork": comparingMedicalMistakeWorkPoster,
    "/collectServices": collectServicesPoster,
    "/ListLabsContracts": ListRentDoctorsClincsServicesPoster,
    "/ListRayContract": ListRayContractPoster,
    "/TendersList": TendersListPoster,
};


function DescriptionStartServicesScreen({ history, title, goals, subTitle, sections, servBtn, choosePara, dest, video, poster }) {

    console.log(dest)

    const [path, setPath] = useState('')

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		}
	}, [dispatch, history, userInfo]);


    useEffect(() => {
        if (userInfo.is_evaluated) {
            setPath('/evaluationDataForMedicalNetworkDetails')
        } else {
            setPath('/evaluationDataForMedicalNetwork')
        }

    }, [dispatch, history, userInfo])

    return (
        <div>
            {userInfo && userInfo.is_activated ? (
                <div className='desc_services'>
                    <div className='heading text-center'>الوصف وبدأ الخدمة</div>
                    <div className='description'>
                        <h2 className='title'>{title}</h2>
                        {choosePara === '0' && <p className='desc'>تنظيم عملية التعاقد ضمن مناقصات مُعَدَّة من قبل المنشآت الصحية؛ لإتاحة فرص تسويقية كُبرَى لها بين 9 قطاعات مختلفه</p>}
                        {choosePara === '1' && <p className='desc'>تنظيم عملية التعاقد بين الشبكات الصحية إلكترونيًّا لزيادة المنافع التسويقية، وتنظيم العمليات التجارية المتبادلة، وتوقيع العقود بشروط عادلة ومُنصِفة وتسهيل الإجراءات اللازمة لإتمام التعاقد </p>}
                        {choosePara === '2' && <p className='desc'>تنظيم عملية التعاقد بين الشبكات الصحية إلكترونيًّا لزيادة المنافع التسويقية، وتنظيم العمليات التجارية المتبادلة، وتوقيع العقود بشروط عادلة ومُنصِفة وتسهيل الإجراءات اللازمة لإتمام التعاقد</p>}
                        {choosePara === '3' && <p className='desc'> تنظيم عمليات الدفع الإلكتروني، وضمان الحماية من فقدان الفواتير أو التأخر في الدفعات المستحقة، لضمان الاستقرار المالي للقطاع الصحي سواء كانت فواتير قديمة او جديدة</p>}
                        {choosePara === '4' && <p className='desc'>  تهدف إلى تقليل أخطاء المهنة الطبية، وضمان حصول الكوادر الصحية العاملة في القطاع الصحي على تغطية تأمينية لها، وضمان عدم انتهائها بدون علم إدارة المنشآت</p>}
                        {choosePara === '5' && <p className='desc'>تنظيم وتسهيل تأجير العيادات وتقديم خدمات الأطباء للمنشآت والشبكات الصحية حسب الفئة التي تنتمي إليها، وبالشروط والاتفاقيات والوسائل التي تضمن حقوق جميع الأطراف</p>}
                        {choosePara === '6' && <p className='desc'>تنظيم عملية بيع وتأجير الأجهزة المُستخدَمة عبر المنصة بطرق ووسائل متطورة، وتنسيق عمليات التبادل التجاري عبر لوائح تنظيمية لضمان حقوق جميع الأطراف، تهدف لزيادة المنافع التسويقية بين القطاع الصحي والقطاعات الأخرى</p>}
                        {choosePara === '7' && <p className='desc'>تعمل هذه الخدمة على أساس تنظيم شبكة موحدة خاصة بقطاع التأمين ومتوافقة مع رؤيته؛ يتم تنظيمها وفق معايير تقييمية حسب بيانات كل منشأة صحية لضمان حصولها على الفئة المستهدفة لها</p>}
                        <h3 className='goal-txt'>{subTitle}</h3>
                        {sections === false && <div className='goals'>
                            {goals[0] && <p>مستشفيات</p>}
                            {goals[1] && <p>عيادات اليوم الواحد</p>}
                            {goals[2] && <p>مستوصف</p>}
                            {goals[3] && <p>مختبر</p>}
                            {goals[4] && <p>مجمع عيادات</p>}
                            {goals[5] && <p>اشعة</p>}
                            {goals[6] && <p>مركز طبي</p>}
                            {goals[7] && <p>مركز تاهيل</p>}
                            {goals[8] && <p>شركات التأمين</p>}
                            {goals[9] && <p>غير ذلك</p>}
                        </div>}
                        {sections === true && <div style={{ gridTemplateColumns: '190px 185px 190px' }} className='goals'>
                            <p>القطاع الصحي</p>
                            <p>القطاع الغذائي</p>
                            <p>قطاع القرطاسية المكتبية</p>
                            <p>قطاع النظافة</p>
                            <p>قطاع الأثاث المكتبي</p>
                            <p>قطاع النقل</p>
                            <p>قطاع الغسيل</p>
                            <p>قطاع ملابس الزي الموحد</p>
                            <p>قطاع التشغيل والصيانة </p>
                            <p>قطاع التوظيف والخدمات </p>
                            <p>غير ذلك</p>
                        </div>}
                    </div>
                    <div className='service'>
                        {servBtn === false &&
                            <Link to={path}>
                                <div className='start-service'><img className='icon' src={arrow} alt="" />
                                    بدأ الخدمة</div>

                            </Link>
                        }
                        {servBtn === false && <div className='btn-groub'>
                            <Link to='/promotion'>
                                <div className='upgrade'><img className='icon' src={arrow} alt="" />
                                    ترقية
                                </div>
                            </Link>
                            <Link to='/RenewSubscriptionPayment'>
                                <div className='renew'><img className='icon' src={arrow} alt="" />
                                    تجديد
                                </div>
                            </Link>
                        </div>}
                        {servBtn === true &&
                            <Link to={dest}>
                                <div className='enter-service'><img className='icon' src={arrow} alt="" />
                                    دخول الخدمة</div>
                            </Link>
                        }
                        <div className="text-center" style={{ margin: '20px 0 0 68px', width: '580px', height: '208px', border: '5px solid #eee', overflow: 'hidden', position: 'relative' }}>
                            {/* <video width='580px' height='208px' poster={posters[dest]} style={{}} >
                                {
                                    () => {
                                        return (<source src={videos[dest]} type="video/mp4" />)
                                    }
                                }
                            </video> */}
                             <img src={posters[dest]} style={{top: '50%', left: '50%', width: '150%', height: '170%', position: 'absolute', transform: 'translate(-50%, -50%)'}} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='thank-msg' style={{ height: "232px" }}>
                    <div className='msg-content'>
                        <p className='thanks' style={{ position: "initial", marginBottom: '20px' }}>! شكرًا </p>
                        <p className='txt text-center px-2' style={{ position: "initial" }}>سيتم تفعيل الخدمات بعد الإطلاع على البيانات ومراعتها من قبل إدارة بيان</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DescriptionStartServicesScreen
