import { Card, Alert, Button } from "react-bootstrap";
import "../styles/messages.css";
import AdminLayout from "./layouts/AdminLayout";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";

import Notification from './Notification';
import '../styles/notification.css';

const Notifications = () => {
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const [notifications, setNotifications] = useState([]);
	const [error, setError] = useState("");
	const [notificationsChanged, setNotificationsChanged] = useState(true)

	const config = {
		headers: {
			Authorization: `Bearer ${userInfo?.access}`
		}
	}

	useEffect(() => {

		if (!notificationsChanged)
			return;

		setError("");

		axios
			.get("/notifications/", {
				headers: { Authorization: `Bearer ${userInfo.access}` },
			})
			.then(({ data }) => {
				if (data.detail) {
					setError(data.detail);
				} else if (data.notifications.length > 0) {
					setNotifications(data.notifications);
				}
			});

		setNotificationsChanged(false);
	}, [notificationsChanged]);

	return (
		<AdminLayout>
			{error.length > 0 && <Alert variant="primary">{error}</Alert>}
			<div className="notifications">
				<div className="notification" style={{ height: "800px" }}>
					<div className="heading text-center">الاشعارات</div>
					<div className="scroll" style={{ marginTop: "55px" }}>
						<div className="notification_items text-end" style={{ marginRight: 0 }}>
							{notifications.length > 0 ? (
								notifications.reverse().map((m) => <Notification setNotificationsChanged={setNotificationsChanged} config={config} notification={m} />)
							) : (
								<div className="text-center">لا توجد اشعارات</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</AdminLayout>
	);
};

export default Notifications;
