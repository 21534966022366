import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import "react-datepicker/dist/react-datepicker.css";
import complete from "../../imgs/check1.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import "../../styles/add_request_laboratories.css";
import { updateInsurrenceOffer } from '../../actions/serviceActions';


function AttachInsuranceCertificateScreen({ history, match }) {

	const teamId = match.params.id

	const [photo, setPhoto] = useState(false)

	const [path, setPath] = useState('ارفق ملف ')

	const [wrong, setWrong] = useState('')

	const [photo2, setPhoto2] = useState(false)

	const [path2, setPath2] = useState('ارفق ملف ')

	const [wrong2, setWrong2] = useState('')

	const [errors] = useState({
		allFieldRequired: "الرجاء ملئ كل البيانات  ",
	})

	const [errorsFlags, setErrorsFlags] = useState([false])

	const dispatch = useDispatch()

	const notificationAdd
		= useSelector(state => state.notificationAdd)

	const { error, notification, success } = notificationAdd

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			if (!userInfo.is_activated) {
				history.push('/services')
			}
		}

	}, [dispatch, history, userInfo])

	const getExtension = (filename) => {
		var parts = filename.split(".");
		return parts[parts.length - 1];
	};

	const onUpload = (e) => {
		if (e.target.value) {
			const ext = getExtension(e.target.value);
			if (ext === "doc" || ext === "pdf" || ext === "xlsx") {
				setPhoto(e.target.value)
				setPath(e.target.files[0])
				setWrong(false)
			} else {
				setPhoto(false)
				setPath('ارفق ملف')
				setWrong(true)
			}
		}
	};

	const onUpload2 = (e) => {
		if (e.target.value) {
			const ext = getExtension(e.target.value);
			if (ext === "doc" || ext === "pdf" || ext === "xlsx") {
				setPhoto2(e.target.value)
				setPath2(e.target.files[0])
				setWrong2(false)
			} else {
				setPhoto2(false)
				setPath2('ارفق ملف')
				setWrong2(true)
			}
		}
	};

	const formValidation = () => {
		let isValid = true;
		const flags = [false];

		const nodes = document.querySelectorAll("input");
		nodes.forEach((e) => {
			if (e.value === '') {
				console.log(e)
				flags[0] = true;
				isValid = false;
			}

		});

		setErrorsFlags(flags);
		return isValid;
	};

	const onSubmit = (e) => {
		e.preventDefault()
		const formvalid = formValidation();
		if (formvalid) {

			let data = new FormData();
			data.append("medical_insurance_request", teamId);
			data.append("sending_certificate_and_bill", true);
			data.append("certificate", path);
			data.append("bill", path2);
			data.append("offer_status", 'P');

			dispatch(updateInsurrenceOffer(data));

			history.push("/ConfirmCertifi");
		}
	}

	return (
		<div className="add_request">
			<ControlPanelHeader
				exit1={true}
				files={false}
				notifi={true}
				msg={true}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				exitDest={"/services"}
				fileDest={"/FacilityFiles"}
				style={"three_section"}
			/>
			<form onSubmit={onSubmit}>
				<div className="add_request_content" style={{ height: '335px' }}>
					<div className="heading text-center" style={{ width: '670px' }}>أرفق الشهادة والفاتورة</div>

					<div className="input_group">

						<p style={{ textAlign: 'right', fontSize: '12px', fontWeight: 'bold', marginTop: '40px', marginBottom: '10px' }}>أرفق الشهادة التأمين</p>
						<div className="input-content">
							<label for="upload-photo1" className='w-100'>
								{" "}
								<div className="input">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="30.706"
										viewBox="0 0 24 30.706"
									>
										<g id="upload" opacity="0.83">
											<path
												id="Path_2318"
												data-name="Path 2318"
												d="M79.775,7.088l-2.3-2.3V1.365A1.366,1.366,0,0,0,76.113,0H57.255a1.349,1.349,0,0,0-1.348,1.348V23.959a1.349,1.349,0,0,0,1.348,1.348h.762v.751a1.349,1.349,0,0,0,1.348,1.348h2.924a.45.45,0,1,0,0-.9H59.365a.449.449,0,0,1-.448-.448V7.974a.45.45,0,1,0-.9,0V24.407h-.762a.449.449,0,0,1-.448-.448V1.348A.449.449,0,0,1,57.255.9H76.113a.466.466,0,0,1,.465.465V3.892L74.918,2.231A.45.45,0,0,0,74.6,2.1H59.365a1.349,1.349,0,0,0-1.348,1.348V5.88a.45.45,0,1,0,.9,0V3.447A.449.449,0,0,1,59.365,3H74.15V7.405a.45.45,0,0,0,.45.45h4.408v18.2a.449.449,0,0,1-.448.448H75.67a.45.45,0,1,0,0,.9h2.889a1.349,1.349,0,0,0,1.348-1.348V7.406a.45.45,0,0,0-.132-.318Zm-4.726-.132V3.635l3.322,3.322Z"
												transform="translate(-55.907 0)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2319"
												data-name="Path 2319"
												d="M187.778,175.456h-10.76a.45.45,0,0,0,0,.9h10.76a.45.45,0,0,0,0-.9Z"
												transform="translate(-169.332 -164.934)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2320"
												data-name="Path 2320"
												d="M187.778,219.688h-10.76a.45.45,0,0,0,0,.9h10.76a.45.45,0,0,0,0-.9Z"
												transform="translate(-169.332 -206.513)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2321"
												data-name="Path 2321"
												d="M323.119,264.8h-2.126a.45.45,0,1,0,0,.9h2.126a.45.45,0,0,0,0-.9Z"
												transform="translate(-304.672 -248.921)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2322"
												data-name="Path 2322"
												d="M183.535,265.7a.45.45,0,1,0,0-.9h-6.518a.45.45,0,0,0,0,.9Z"
												transform="translate(-169.332 -248.921)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2323"
												data-name="Path 2323"
												d="M237.548,367.735l-1.2-1.2v4.348a.45.45,0,1,1-.9,0v-4.348l-1.2,1.2a.45.45,0,0,1-.636-.636l1.967-1.967a.45.45,0,0,1,.636,0l1.967,1.967a.45.45,0,0,1-.636.636Z"
												transform="translate(-222.833 -343.11)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2324"
												data-name="Path 2324"
												d="M185.229,323.61a5.649,5.649,0,1,0,5.649,5.649A5.656,5.656,0,0,0,185.229,323.61Zm0,10.4a4.749,4.749,0,1,1,4.75-4.749A4.755,4.755,0,0,1,185.229,334.009Z"
												transform="translate(-172.163 -304.202)"
												fill="#1e1e1e"
											/>
										</g>
									</svg>
									<div>{photo}</div>
								</div>{" "}
							</label>
							<input
								onChange={onUpload}
								type="file"
								name="photo1"
								id="upload-photo1"
							/>
							{photo && <img src={complete} alt="" />}
							{wrong && <AiOutlineCloseCircle className="complete" />}
						</div>

						{errorsFlags[0] && <div className='error'><img src={error} />{errors.allFieldRequired}</div>}
					</div>

					<div className="input_group" style={{ marginTop: '10px' }}>

						<p style={{ textAlign: 'right', fontSize: '12px', fontWeight: 'bold', marginBottom: '10px' }}>أرفق الفاتورة</p>
						<div className="input-content">
							<label for="upload-photo2" className='w-100'>
								{" "}
								<div className="input">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="30.706"
										viewBox="0 0 24 30.706"
									>
										<g id="upload" opacity="0.83">
											<path
												id="Path_2318"
												data-name="Path 2318"
												d="M79.775,7.088l-2.3-2.3V1.365A1.366,1.366,0,0,0,76.113,0H57.255a1.349,1.349,0,0,0-1.348,1.348V23.959a1.349,1.349,0,0,0,1.348,1.348h.762v.751a1.349,1.349,0,0,0,1.348,1.348h2.924a.45.45,0,1,0,0-.9H59.365a.449.449,0,0,1-.448-.448V7.974a.45.45,0,1,0-.9,0V24.407h-.762a.449.449,0,0,1-.448-.448V1.348A.449.449,0,0,1,57.255.9H76.113a.466.466,0,0,1,.465.465V3.892L74.918,2.231A.45.45,0,0,0,74.6,2.1H59.365a1.349,1.349,0,0,0-1.348,1.348V5.88a.45.45,0,1,0,.9,0V3.447A.449.449,0,0,1,59.365,3H74.15V7.405a.45.45,0,0,0,.45.45h4.408v18.2a.449.449,0,0,1-.448.448H75.67a.45.45,0,1,0,0,.9h2.889a1.349,1.349,0,0,0,1.348-1.348V7.406a.45.45,0,0,0-.132-.318Zm-4.726-.132V3.635l3.322,3.322Z"
												transform="translate(-55.907 0)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2319"
												data-name="Path 2319"
												d="M187.778,175.456h-10.76a.45.45,0,0,0,0,.9h10.76a.45.45,0,0,0,0-.9Z"
												transform="translate(-169.332 -164.934)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2320"
												data-name="Path 2320"
												d="M187.778,219.688h-10.76a.45.45,0,0,0,0,.9h10.76a.45.45,0,0,0,0-.9Z"
												transform="translate(-169.332 -206.513)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2321"
												data-name="Path 2321"
												d="M323.119,264.8h-2.126a.45.45,0,1,0,0,.9h2.126a.45.45,0,0,0,0-.9Z"
												transform="translate(-304.672 -248.921)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2322"
												data-name="Path 2322"
												d="M183.535,265.7a.45.45,0,1,0,0-.9h-6.518a.45.45,0,0,0,0,.9Z"
												transform="translate(-169.332 -248.921)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2323"
												data-name="Path 2323"
												d="M237.548,367.735l-1.2-1.2v4.348a.45.45,0,1,1-.9,0v-4.348l-1.2,1.2a.45.45,0,0,1-.636-.636l1.967-1.967a.45.45,0,0,1,.636,0l1.967,1.967a.45.45,0,0,1-.636.636Z"
												transform="translate(-222.833 -343.11)"
												fill="#1e1e1e"
											/>
											<path
												id="Path_2324"
												data-name="Path 2324"
												d="M185.229,323.61a5.649,5.649,0,1,0,5.649,5.649A5.656,5.656,0,0,0,185.229,323.61Zm0,10.4a4.749,4.749,0,1,1,4.75-4.749A4.755,4.755,0,0,1,185.229,334.009Z"
												transform="translate(-172.163 -304.202)"
												fill="#1e1e1e"
											/>
										</g>
									</svg>
									<div>{photo2}</div>
								</div>{" "}
							</label>
							<input
								onChange={onUpload2}
								type="file"
								name="photo2"
								id="upload-photo2"
							/>
							{photo2 && <img src={complete} alt="" />}
							{wrong2 && <AiOutlineCloseCircle className="complete" />}
						</div>

						{errorsFlags[0] && <div className='error'><img src={error} />{errors.allFieldRequired}</div>}
					</div>
					<button type='submit' className="enter_service my-2">
						<img className='icon' src={arrow} alt="" style={{ top: "9px" }} />
						إرسال                 </button>
				</div>
			</form>
		</div>
	)
}

export default AttachInsuranceCertificateScreen
