import axios from "axios";

export const cancel_DevicesRequest = async (id, user) => {
  const request = await axios.get(`/services/cancel_DevicesRequest/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const cancel_ClinicsRequest = async (id, user) => {
  const request = await axios.get(`/services/cancel_ClinicsRequest/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const cancel_LabsRequests = async (id, user) => {
  const request = await axios.get(`/services/cancel_LabsRequests/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const cancel_RadiologyRequestsService = async (id, user) => {
  const request = await axios.get(
    `/services/cancel_RadiologyRequestsService/${id}/`,
    {
      headers: { Authorization: `Bearer ${user.access}` },
    }
  );
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const cancel_Tender = async (id, user) => {
  const request = await axios.get(`/services/cancel_Tender/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};
