import React, { useRef } from 'react';
import { Card, CardGroup, Col, Form, Row } from "react-bootstrap";
function Jimage({ title, array, owner, employee, map }) {
  const renderCounter  = useRef(1);
function createCard() {
    return array.map((key) => {
      renderCounter.current = renderCounter.current + 1
      return (
        <Col lg="3" className="my-2 break">
          <Card.Img src={key} />
          <Form.Check className="check_hide text-center" type="checkbox" />
          <div class="page-number">{renderCounter.current}</div>
        </Col>
      );
    });
  }
  function createMap() {
    renderCounter.current = renderCounter.current + 1
    return (
      <Col lg="3" className="my-2 break">
        <Card.Img src={map} />
        <p className="text_small text-center">موقع المنشأة على الخريطة</p>
        <Form.Check className="check_hide text-center" type="checkbox" />
        <div class="page-number">{renderCounter.current}</div>
      </Col>
    );
  }

  function createOwner() {
    renderCounter.current = renderCounter.current + 1
    return (
      <Col lg="3" className="my-2 break">
        <Card.Img src={owner} />
        <p className="text_small text-center">صورة هوية صاحب المنشأة</p>
        <Form.Check className="check_hide text-center" type="checkbox" />
        <div class="page-number">{renderCounter.current}</div>
      </Col>
    );
  }
  function createEmployee() {
    renderCounter.current = renderCounter.current + 1
    return (
      <Col lg="3" className="my-2 break">
        <Card.Img src={employee} />
        <p className="text_small text-center">صورة هوية الموظف</p>
        <Form.Check className="check_hide text-center" type="checkbox" />
        <div class="page-number">{renderCounter.current}</div>
      </Col>
    );
  }

  return (
    <CardGroup>
      <Row>
        <Card className="border_hide">
          <Card.Body>
            <Card.Title className="print_hide">{title}</Card.Title>
            <Row>
              {map ? createMap() : ""}
              {owner ? createOwner() : ""}
              {employee ? createEmployee() : ""}
              {createCard()}
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </CardGroup>
  );
}
export default Jimage;
