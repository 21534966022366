import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { 
    userLoginReducer,
    userRegisterReducer,
    userChangePasswordReducer

} from './reducers/userReducers'

import { 
    sectorListReducer,
    sectorTypeListReducer,

} from './reducers/sectorReducers'

import {
    facilityInfoRegisterReducer,
    facilityImageRegisterReducer,
    facilityOwnerRegisterReducer,
    facilityEmployeeRegisterReducer,
    facilityTypeDetailsReducer,
    facilityPaymentBankReducer,
    facilityDetailsReducer,
    facilityListReducer,

} from './reducers/facilityReducers'

import {
    serviceAddEvaluationReducer,
    medicalDeviceAddReducer,
    evaluationDetailsReducer,
    evaluationUpgradeReducer,
    medicalDeviceListReducer,
    medicalDeviceDetailsReducer,
    medicalDoctorsClinicsListReducer,
    medicalDeviceAddOfferReducer,
    doctorsClinicsAddReducer,
    doctorsClinicsDetailsReducer,
    doctorsClinicsAddOfferReducer,
    laboratoriesAddReducer,
    laboatoriesListReducer,
    laboratoryDetailsReducer,
    laboratoriesAddOfferReducer,
    raysListReducer,
    raysAddReducer,
    rayDetailsReducer,
    raysAddOfferReducer,
    medicalTeamAddReducer,
    medicalTeamListReducer,
    medicalTeamAllListReducer,
    medicalTeamDetailsReducer,
    medicalTeamUpdateReducer,
    medicalTeamDeleteReducer,
    medicalTeamRequestInsurrenceReducer,
    collectInvoiceListReducer,
    collectInvoiceOldListReducer,
    collectInvoiceNewListReducer,
    insurrenceAddOfferReducer,
    insurrenceOfferUpdateReducer,
    InsurrenceOffersListReducer,
    insurrenceOfferDetailsReducer,
    invoiceAddForOfferReducer,
    
} from './reducers/serviceReducers'

import {
    tenderAddReducer,
    tenderListReducer,
    tenderDetailsReducer,
    tenderAddOfferReducer,
} from './reducers/tenderReducers'

import {
    notificationAddReducer,
} from './reducers/notificationReducers'

import {
    medicalTeamAllListForAdminReducer,
    medicalTeamInsurrenceRequestForAdminReducer,
    facilityListForAdminReducer,
    facilityDetailsForAdminReducer,
    facilityUpdateForAdminReducer,
    insurrenceRequestUpdateForAdminReducer,
    offerDetailsForAdminReducer,
    evaluationDetailsForAdminReducer,
    evaluationAllListForAdminReducer
} from './reducers/adminReducers'

const reducer = combineReducers({ 
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userChangePassword:userChangePasswordReducer,

    sectorList: sectorListReducer,
    sectorTypeList: sectorTypeListReducer,

    facilityInfoRegister: facilityInfoRegisterReducer,
    facilityImageRegister: facilityImageRegisterReducer,
    facilityOwnerRegister: facilityOwnerRegisterReducer,
    facilityEmployeeRegister: facilityEmployeeRegisterReducer,
    facilityTypeDetails: facilityTypeDetailsReducer,
    facilityPaymentBank: facilityPaymentBankReducer,
    facilityDetails: facilityDetailsReducer,
    facilityList: facilityListReducer,

    serviceAddEvaluation:serviceAddEvaluationReducer,
    medicalDeviceAdd:medicalDeviceAddReducer,
    evaluationDetails: evaluationDetailsReducer,
    upgradeReducer: evaluationUpgradeReducer,
    medicalDeviceList: medicalDeviceListReducer,
    medicalDeviceDetails: medicalDeviceDetailsReducer,
    medicalDoctorsClinicsList: medicalDoctorsClinicsListReducer,
    medicalDeviceAddOffer: medicalDeviceAddOfferReducer,
    doctorsClinicsAdd: doctorsClinicsAddReducer,
    doctorsClinicsDetails: doctorsClinicsDetailsReducer,
    doctorsClinicsAddOffer: doctorsClinicsAddOfferReducer,
    laboratoriesAdd: laboratoriesAddReducer,
    laboatoriesList: laboatoriesListReducer,
    laboratoryDetails: laboratoryDetailsReducer,
    laboratoriesAddOffer: laboratoriesAddOfferReducer,
    raysList: raysListReducer,
    raysAdd: raysAddReducer,
    rayDetails: rayDetailsReducer,
    raysAddOffer: raysAddOfferReducer,
    medicalTeamAdd: medicalTeamAddReducer,
    medicalTeamList:medicalTeamListReducer,
    medicalTeamAllList: medicalTeamAllListReducer,
    medicalTeamDetails: medicalTeamDetailsReducer,
    medicalTeamUpdate: medicalTeamUpdateReducer,
    medicalTeamDelete: medicalTeamDeleteReducer,
    medicalTeamRequestInsurrence: medicalTeamRequestInsurrenceReducer,
    collectInvoiceList: collectInvoiceListReducer,
    collectInvoiceOldList: collectInvoiceOldListReducer,
    collectInvoiceNewList: collectInvoiceNewListReducer,
    insurrenceAddOffer: insurrenceAddOfferReducer,
    insurrenceOfferUpdate: insurrenceOfferUpdateReducer,
    InsurrenceOffersList: InsurrenceOffersListReducer,
    insurrenceOfferDetails: insurrenceOfferDetailsReducer,

    tenderAdd: tenderAddReducer,
    tenderList: tenderListReducer,
    tenderDetails: tenderDetailsReducer,
    tenderAddOffer: tenderAddOfferReducer,

    notificationAdd: notificationAddReducer,
    invoiceAddForOffer : invoiceAddForOfferReducer,


    /****************** Start Admin *******************/
    medicalTeamAllListForAdmin: medicalTeamAllListForAdminReducer,
    medicalTeamInsurrenceRequestForAdmin: medicalTeamInsurrenceRequestForAdminReducer,
    facilityListForAdmin: facilityListForAdminReducer,
    facilityDetailsForAdmin: facilityDetailsForAdminReducer,
    facilityUpdateForAdmin: facilityUpdateForAdminReducer,
    insurrenceRequestUpdateForAdmin: insurrenceRequestUpdateForAdminReducer,
    offerDetailsForAdmin: offerDetailsForAdminReducer,
    evaluationDetailsForAdmin: evaluationDetailsForAdminReducer,
    evaluationAllListForAdmin: evaluationAllListForAdminReducer,
    
    /****************** End Admin *******************/







})

const userInfoFromLocalStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

const facilityFromLocalStorage = localStorage.getItem('facility') ?
    JSON.parse(localStorage.getItem('facility')) : {}

const facilityOwnerFromLocalStorage = localStorage.getItem('facilityOwner') ?
    JSON.parse(localStorage.getItem('facilityOwner')) : {}

const facilityOEmployeeFromLocalStorage = localStorage.getItem('facilityEmployee') ?
    JSON.parse(localStorage.getItem('facilityEmployee')) : {}

const initialState = {
    userLogin : { userInfo: userInfoFromLocalStorage},
    facilityInfoRegister : { facility: facilityFromLocalStorage},
    facilityOwnerRegister : { facilityOwner: facilityOwnerFromLocalStorage},
    facilityEmployeeRegister : { facilityEmployee: facilityOEmployeeFromLocalStorage},
}

const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store