import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import "../../styles/change_entereddata.css";
import arrow from "../../imgs/arrow.png";
import search from "../../imgs/search1.png";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";
import {
  fetchSearchData,
  fetchSectors,
  updateSectors,
  fetchOneFacility,
} from "../../adminScreen/api/powers/MainPowersEndPPoints";
export const Add_MultiSectors = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [state, setState] = useState({
    name: "",
    code: "",
    showInfo: false,
    showModal: false,
  });
  const [searchData, setSearchData] = useState([]);

  const [resultFacility, setResultFacility] = useState({});

  const [sectors, setSectors] = useState([]);

  const [sector1, setSector1] = useState([]);
  const [sector2, setSector2] = useState([]);

  const [sectorChoice1, setSectorChoice1] = useState([]);
  const [sectorChoice2, setSectorChoice2] = useState([]);

  const [mainSector, setMainSector] = useState({});
  const [mainSectorType, setMainSectorType] = useState({});

  const [otherSectors, setOtherSectors] = useState({
    sector1: null,
    sector_type1: null,
    sector2: null,
    sector_type2: null,
  });

  const changeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  const focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  const onClick = () => {
    setState({
      ...state,
      showInfo: true,
    });

    const results = [];
    if(state.code) {
        searchData.forEach((f) =>
        f.id == state.code ||
        f.id == state.code.split("-")[0] ||
        f.id == state.code.split("-")[1]
          ? results.push(f)
          : null
      );
    }
    
    if(state.code) {
    searchData.forEach((f) =>
      f.client_code == state.code ||
      f.client_code == state.code.split("-")[0] ||
      f.client_code == state.code.split("-")[1]
        ? results.push(f)
        : null
    );
    }
    if(state.name) {
      searchData.forEach((f) =>
      f.facility_name == state.name ||
      f.facility_name == state.name.split("-")[0] ||
      f.facility_name == state.name.split("-")[1]
        ? results.push(f)
        : null
    );
    }
    

    if (results.length > 0) {
      setResultFacility(results[0]);
      fetchOneFacility(results[0].id, userInfo).then((data) => {
        setMainSector(sectors.filter((s) => s.id == data.sector)[0]);
        setMainSectorType(
          sectors
            .filter((s) => s.id == data.sector)[0]
            .types.filter((t) => t.pk == data.sector_type)[0]
        );
      });
    } else {
      setResultFacility({});
      setMainSector({});
      setMainSectorType({});
    }
  };

  const sector1Change = (id) => {
    setSector1(sectors.filter((s) => s.id == id)[0]);
  };

  const sector2Change = (id) => {
    setSector2(sectors.filter((s) => s.id == id)[0]);
  };

  const submitHandler = () => {
    setState({ ...state, showModal: true });
    const S_sector1 = sectors.filter((s) => s.id == sectorChoice1[0])[0];
    const S_sectortype1 = S_sector1.types.filter(
      (s) => s.pk == sectorChoice1[1]
    );

    const S_sector2 = sectors.filter((s) => s.id == sectorChoice2[0])[0];

    let S_sectortype2= []

    if(S_sector2) {      
      S_sectortype2 = S_sector2.types.filter(
        (s) => s.pk == sectorChoice2[1]
      );
    }

    if (S_sector2) {
      updateSectors(
        resultFacility.id,
        [S_sector1.id, S_sectortype1[0].pk],
        [S_sector2.id, S_sectortype2[0].pk],
        userInfo
      ).then((data) => console.log(data));
    } else {
      updateSectors(
        resultFacility.id,
        [S_sector1.id, S_sectortype1[0].pk],
        [S_sector1.id, S_sectortype1[0].pk],
        userInfo
      ).then((data) => console.log(data));
    }
    
  };

  useEffect(() => {
    fetchSearchData(userInfo).then((data) => setSearchData(data));
    fetchSectors(userInfo).then((data) => setSectors(data));
  }, []);

  const { name, code, showInfo } = state;
  return (
    <div className="change_entereddata">
      <div className="item reverse">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: "-10px" }}
        >
          <Button className="text-center btnNext" onClick={onClick}>
            <img src={arrow} alt="" />
            بحث
          </Button>
        </div>
        <div className="input-data">
          <label htmlFor="name">كود العميل</label>
          <input
            type="text"
            id="code"
            name="code"
            value={code}
            onChange={changeHandler}
            placeholder="اكتب كود العميل"
            onFocus={focusHandler}
            onBlur={focusoutHandler}
          />
          <img src={search} alt="" />
        </div>
        <div className="input-data">
          <label htmlFor="name">اسم الجهة</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={changeHandler}
            placeholder=" ابحث اسم الجهة"
            onFocus={focusHandler}
            onBlur={focusoutHandler}
          />
          <img src={search} alt="" />
        </div>
      </div>

      {showInfo && (
        <div
          className="item reverse"
          style={{
            direction: "rtl",
            justifyContent: "flex-start",
            marginLeft: "-111px",
            marginTop: "66px",
            flexDirection: "row",
          }}
        >
          <p className="info_title">اسم الجهة :</p>
          <div className="info_data">
            {resultFacility.facility_name &&
            resultFacility.facility_name.length > 0
              ? resultFacility.facility_name
              : "غير موجود"}
          </div>
          <p className="info_title"> كود العميل :</p>
          <div className="info_data">
            {resultFacility.client_code && resultFacility.client_code.length > 0
              ? resultFacility.client_code
              : "غير موجود"}
          </div>
        </div>
      )}
      {showInfo && (
        <div
          className="item reverse"
          style={{
            direction: "rtl",
            justifyContent: "flex-start",
            marginLeft: "-111px",
            marginTop: "66px",
            flexDirection: "row",
          }}
        >
          <p className="info_title"> القطاع الرئيسي :</p>
          <div className="info_data">
            {mainSector.sector_name && mainSector.sector_name.length > 0
              ? mainSector.sector_name
              : "غير موجود"}
          </div>
          <p className="info_title"> نوع القطاع الرئيسي :</p>
          <div className="info_data">
            {mainSectorType.type_name && mainSectorType.type_name.length > 0
              ? mainSectorType.type_name
              : "غير موجود"}
          </div>
        </div>
      )}

      {showInfo && sectors.length > 0 && (
        <div
          className="item reverse"
          style={{
            direction: "rtl",
            justifyContent: "space-between",
            marginLeft: "-111px",
            marginTop: "66px",
            flexDirection: "row",
          }}
        >
          <div style={{ flexGrow: 1, margin: "10px" }}>
            <label style={{ margin: "10px" }}>القطاع الاول</label>
            <Form.Select
              onChange={(e) => sector1Change(e.target.value)}
              style={{ padding: "10px 30px" }}
            >
              <option>القطاع</option>
              {sectors.map((s) => (
                <option value={s.id} key={s.id}>
                  {s.sector_name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div style={{ flexGrow: 1, margin: "10px" }}>
            <label style={{ margin: "10px" }}>نوع القطاع الاول</label>
            <Form.Select
              style={{ padding: "10px 30px" }}
              onChange={(e) =>
                setSectorChoice1([
                  +e.target.children[1].dataset.sector,
                  +e.target.value,
                ])
              }
            >
              <option>نوع القطاع</option>
              {sector1.types &&
                sector1.types.map((s) => (
                  <option value={s.pk} key={s.pk} data-sector={sector1.id}>
                    {s.type_name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      )}

      {showInfo && sectors.length > 0 && (
        <div
          className="item reverse"
          style={{
            direction: "rtl",
            justifyContent: "space-between",
            marginLeft: "-111px",
            marginTop: "66px",
            flexDirection: "row",
          }}
        >
          <div style={{ flexGrow: 1, margin: "10px" }}>
            <label style={{ margin: "10px" }}>القطاع الثاني</label>
            <Form.Select
              onChange={(e) => sector2Change(e.target.value)}
              style={{ padding: "10px 30px" }}
            >
              <option>القطاع</option>
              {sectors.map((s) => (
                <option value={s.id} key={s.id}>
                  {s.sector_name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div style={{ flexGrow: 1, margin: "10px" }}>
            <label style={{ margin: "10px" }}>نوع القطاع الثاني</label>
            <Form.Select
              style={{ padding: "10px 30px" }}
              onChange={(e) => {
                console.log(e);
                setSectorChoice2([
                  +e.target.children[1].dataset.sector,
                  +e.target.value,
                ]);
              }}
            >
              <option>نوع القطاع</option>
              {sector2.types &&
                sector2.types.map((s) => (
                  <option value={s.pk} key={s.pk} data-sector={sector2.id}>
                    {s.type_name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      )}

      {showInfo && (
        <div className="text-center d-flex justify-content-center">
          <Button className="text-center btnNext" onClick={submitHandler}>
            <img src={arrow} alt="" />
            تنفيذ
          </Button>
        </div>
      )}

      {state.showModal ? (
        <ConfirmationModal
          title="الصلاحيات"
          body="تم انهاء العملية بنجاح"
          onClosing={() => setState({ showModal: false })}
        />
      ) : null}
    </div>
  );
};

export default Add_MultiSectors;
