import React, { Component } from 'react'
import '../styles/thanks_msg.css'
import email from '../imgs/email.png'
import LoginName from './LoginName'

export class ThanksMsg extends Component {
    constructor(props){
        super(props)

        this.state = {
            dest: ''
        }
    }

    componentDidMount(){
        if(this.props.location.state){
          localStorage.setItem('dest', this.props.location.dest);
          this.setState({
            dest: localStorage.getItem('dest')
          })
        } else{
          this.setState({
            dest: localStorage.getItem('dest')
          })
        }
    }

    render() {
        
        return (
            <div className='thank-msg'>
                <div className='msg-content text-center' style={{height: "531px", borderRadius:0}}>
                    <img src={email} alt="" />
                    <p className='thanks'>! شكرًا </p>
                    <p className='txt'>سيتم إرسال رابط به بيانات تسجيل الدخول المؤقت لحسابك، ويمكنك بعدها تغيير كلمة المرور بعدها لحماية حسابك</p>
                </div>
                <LoginName   Home={false} Facility={true} dest={this.state.dest} pathname={'loginfacility1'}/>
            </div>
        )
    }
}

export default ThanksMsg
