import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import InvoiceTable from "./InvoiceTable";
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import QRCode from "react-qr-code";
import Hdate from "./Hdate";
import NewCard from "./newCard";
import logo from "./logo.svg";
import social1 from "./social1.svg";
import social2 from "./social2.svg";
import social3 from "./social3.svg";
import social4 from "./social4.svg";
import { Tender_list } from "../../../api/offers/listOffers";

function NewOrder() {
	const params = useParams();
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const [offer, setOffer] = useState({});
	const [info, setInfo] = useState({});
	const [fac_info, set_facInfo] = useState({});
	const [img, setImage] = useState([]);

	useEffect(() => {
		Tender_list(userInfo).then((data) => {
			const theOffer = data.filter((d) => d.id == params.id)[0];
			setOffer(theOffer);

			const ourInfo = {
				القطاع: theOffer.facility.sector.sector_name,
				"نوع القطاع": theOffer.facility.sector_type.type_name,
				"نوع العرض": "توريد",
				"مدة العرض": theOffer.period,
				"مدة العقد": theOffer.offer_period,
				"نوع العقد": theOffer.offer_type,
				"عدد الموظفين": theOffer.employee_num,
				"قسم الموظف": theOffer.employee_type,
			};
			// const finalObj = {};
			// Object.entries(ourInfo)
			//   .filter((e) => !e[1] == 0)
			//   .map((a) => (finalObj[a[0]] = a[1]));
			setInfo(ourInfo);
			set_facInfo({
				"كود المنشأة": theOffer.facility.client_code,
				المدينة: theOffer.facility.state,
				المنطقة: theOffer.facility.location,
			});
			setImage([
				theOffer.image,
				theOffer.image1,
				theOffer.image2,
				theOffer.image3,
			]);
		});
	}, []);
	const [radioValue, setRadioValue] = useState("sell");
	const [radioValueN, setRadioValueN] = useState("new");
	const [bigImage, setBigImage] = useState(img[0]);

	let title1 = ["بيانات مقدم العرض"];
	let descreption =
		"  وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية";

	let data1 = {
		الاسم: "أحمد",
		العمر: 29,
		الجنس: "ذكر",
		البلد: "السعودية",
		الاسم1: "أحمد",
		العمر1: 29,
		الجنس1: "ذكر",
		البلد1: "السعودية",
		الاسم2: "أحمد",
		العمر2: 29,
		الجنس2: "ذكر",
		البلد2: "السعودية",
	};
	function handleClick() {
		window.print();
	}

	return (
		<div className="w-100" style={{ direction: "rtl" }}>
			{offer != null ? (
				<>
					<Container className="inv_a4 screen">
						<Button
							onClick={() => handleClick()}
							className="print_hide m-4 inv_button-print bg-secondary"
						>
							طباعة
						</Button>
						<div className="header_c" />
						<h1 className="inv_h1">اضافة عرض</h1>
						<img className="inv_img" alt="logo" src={logo} />
						<Hdate
							className="inv_Hdate_table"
							date={`${new Date(offer.created_at).getDate()}/${new Date(
								offer.created_at
							).getMonth()}/${new Date(offer.created_at).getFullYear()}`}
							code={offer.id}
							codeTitle="كود العرض:"
						/>
						<p className="inv_QR_title">رمز العرض:</p>
						<QRCode className="inv_QR" value={window.location.href} size="75" />
						{offer.facility && (
							<div className="inv_data mt-3">
								<p>الخدمة:</p>
								<p>مناقصات التوريد</p>
								<p>{offer.service}</p>
							</div>
						)}
						<div className="inv_table">
							<Row style={{ direction: "rtl" }}>
								<Col lg="12">
									{info && (
										<InvoiceTable
											style={{ direction: "rtl" }}
											className="inv_table_from"
											title={["بيانات مقدم العرض"]}
											data={fac_info}
											type="4"
										/>
									)}
								</Col>
							</Row>
							<Row style={{ direction: "rtl" }}>
								<Col lg="12">
									{info && (
										<InvoiceTable
											style={{ direction: "rtl" }}
											className="inv_table_from"
											title={["بيانات العرض"]}
											data={info}
											type="4"
										/>
									)}
								</Col>
							</Row>
							<Row>
								<Col lg="12" className="mt-4">
									<NewCard
										style={{ margin: 0, padding: 0 }}
										img={img}
										title={[offer.title]}
										descreption={offer.details}
										price="800"
										weight="50"
										radioValue={radioValue}
										radioValueN={radioValueN}
										bigImage={bigImage}
										handleButtons={(e) => setRadioValue(e)}
										handleButtonsN={(e) => setRadioValueN(e)}
										handleImages={(e) => setBigImage(e)}
									/>
								</Col>
							</Row>
							<div className="new_boxs">
								<Row>
									<Col lg="6">
										<p className="new_box_h">
											ملاحظة موظف العمليات بعد المراجعة:
										</p>
										<div className="new_box"></div>
									</Col>
									<Col lg="6">
										<p className="new_box_h">
											ملاحظة مدير العمليات بعد المراجعة:
										</p>
										<div className="new_box"></div>
									</Col>
								</Row>
								<Row className="new_sign">
									<Col lg="1">التوقيع:</Col>
									<Col lg="5">
										<div className="border-bottom mt-4" />
									</Col>
									<Col lg="1">التوقيع:</Col>
									<Col lg="5">
										<div className="border-bottom mt-4" />
									</Col>
								</Row>
							</div>
						</div>
						<div className="inv_footer">
							<span className="footer_text">الإيميل : info@bayan.med.sa</span>
							<div className="social_neworder_div">
								<a href="http://www.bayan.med.sa/">
									<img className="social_neworder" alt="social" src={social4} />
								</a>
								<a href="http://www.bayan.med.sa/">
									<img className="social_neworder" alt="social" src={social3} />
								</a>
								<a href="http://www.bayan.med.sa/">
									<img className="social_neworder" alt="social" src={social2} />
								</a>
								<a href="http://www.bayan.med.sa/">
									<img className="social_neworder" alt="social" src={social1} />
								</a>
							</div>
						</div>
					</Container>
					<page size="A4" className="print">
						<div className="header_c" />
						<h1 className="inv_h1">إضافة طلب</h1>
						<img className="inv_img" alt="logo" src={logo} />
						<Hdate
							className="inv_Hdate_table"
							date="22/5/2021"
							code="25G54"
							codeTitle="كود العرض:"
						/>
						<p className="inv_QR_title">كود التسجيل:</p>
						<QRCode className="inv_QR" value={window.location.href} size="75" />
						<div className="inv_data">
							<p>بيانات الشركة</p>
							<p>بيانات الشركة</p>
							<p>بيانات الشركة</p>
						</div>
						<div className="inv_table">
							<Row>
								<Col lg="12">
									{info && (
										<InvoiceTable
											className="inv_table_from"
											title={title1}
											data={info}
											type="4"
										/>
									)}
								</Col>
							</Row>
							<Row>
								<Col lg="12" className="mt-4">
									<NewCard
										img={img}
										title="العنوان"
										descreption={descreption}
										price="800"
										weight="50"
										radioValue={radioValue}
										radioValueN={radioValueN}
										bigImage={bigImage}
										handleButtons={(e) => setRadioValue(e)}
										handleButtonsN={(e) => setRadioValueN(e)}
										handleImages={(e) => setBigImage(e)}
									/>
								</Col>
							</Row>
							<div className="new_boxs">
								<Row>
									<Col lg="6">
										<p className="new_box_h">
											ملاحظة موظف العمليات بعد المراجعة:
										</p>
										<div className="new_box"></div>
									</Col>
									<Col lg="6">
										<p className="new_box_h">
											ملاحظة مدير العمليات بعد المراجعة:
										</p>
										<div className="new_box"></div>
									</Col>
								</Row>
								<Row className="new_sign">
									<Col lg="1">التوقيع:</Col>
									<Col lg="5">
										<div className="border-bottom mt-4" />
									</Col>
									<Col lg="1">التوقيع:</Col>
									<Col lg="5">
										<div className="border-bottom mt-4" />
									</Col>
								</Row>
							</div>
						</div>
						<div className="inv_footer">
							<span className="footer_text">الإيميل : info@bayan.med.sa</span>
							<div className="social_neworder_div">
								<a href="http://www.bayan.med.sa/">
									<img className="social_neworder" alt="social" src={social4} />
								</a>
								<a href="http://www.bayan.med.sa/">
									<img className="social_neworder" alt="social" src={social3} />
								</a>
								<a href="http://www.bayan.med.sa/">
									<img className="social_neworder" alt="social" src={social2} />
								</a>
								<a href="http://www.bayan.med.sa/">
									<img className="social_neworder" alt="social" src={social1} />
								</a>
							</div>
						</div>
					</page>
				</>
			) : null}
		</div>
	);
}
export default NewOrder;
