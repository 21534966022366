import {
    FACILITY_INFO_REGISTER_REQUEST,
    FACILITY_INFO_REGISTER_SUCCESS,
    FACILITY_INFO_REGISTER_FAIL,

    FACILITY_IMAGE_REGISTER_REQUEST,
    FACILITY_IMAGE_REGISTER_SUCCESS,
    FACILITY_IMAGE_REGISTER_FAIL,

    FACILITY_OWNER_REGISTER_REQUEST,
    FACILITY_OWNER_REGISTER_SUCCESS,
    FACILITY_OWNER_REGISTER_FAIL,
    FACILITY_OWNER_REGISTER_RESET,

    FACILITY_EMPLOYEE_REGISTER_REQUEST,
    FACILITY_EMPLOYEE_REGISTER_SUCCESS,
    FACILITY_EMPLOYEE_REGISTER_FAIL,
    FACILITY_EMPLOYEE_REGISTER_RESET,

    FACILITY_TYPE_REQUEST,
    FACILITY_TYPE_SUCCESS,
    FACILITY_TYPE_FAIL,

    FACILITY_DETAILS_REQUEST,
    FACILITY_DETAILS_SUCCESS,
    FACILITY_DETAILS_FAIL,

    FACILITY_PAYMENT_BANK_REQUEST,
    FACILITY_PAYMENT_BANK_SUCCESS,
    FACILITY_PAYMENT_BANK_FAIL,

    FACILITY_LIST_REQUEST,
    FACILITY_LIST_SUCCESS,
    FACILITY_LIST_FAIL,
} from '../constants/facilityConstants'


export const facilityInfoRegisterReducer = (state = {}, action) => {
    switch (action.type) {

        case FACILITY_INFO_REGISTER_REQUEST:
            return { loading: true }

        case FACILITY_INFO_REGISTER_SUCCESS:
            return { loading: false, success: true, facility: action.payload }

        case FACILITY_INFO_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const facilityImageRegisterReducer = (state = {}, action) => {
    switch (action.type) {

        case FACILITY_IMAGE_REGISTER_REQUEST:
            return { loading: true }

        case FACILITY_IMAGE_REGISTER_SUCCESS:
            return { loading: false, success: true, facilityImages: action.payload }

        case FACILITY_IMAGE_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const facilityOwnerRegisterReducer = (state = {}, action) => {
    switch (action.type) {

        case FACILITY_OWNER_REGISTER_REQUEST:
            return { loading: true }

        case FACILITY_OWNER_REGISTER_SUCCESS:
            return { loading: false, success: true, facilityOwner: action.payload }

        case FACILITY_OWNER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case FACILITY_OWNER_REGISTER_RESET:
            return {}

        default:
            return state
    }
}

export const facilityEmployeeRegisterReducer = (state = {}, action) => {
    switch (action.type) {

        case FACILITY_EMPLOYEE_REGISTER_REQUEST:
            return { loading: true }

        case FACILITY_EMPLOYEE_REGISTER_SUCCESS:
            return { loading: false, success: true, facilityEmployee: action.payload }

        case FACILITY_EMPLOYEE_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case FACILITY_EMPLOYEE_REGISTER_RESET:
            return {}

        default:
            return state
    }
}

export const facilityTypeDetailsReducer = (state = { facilityType: {} }, action) => {
    switch (action.type) {

        case FACILITY_TYPE_REQUEST:
            return { loading: true, facilityType: {} }

        case FACILITY_TYPE_SUCCESS:
            return { loading: false, facilityType: action.payload }

        case FACILITY_TYPE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const facilityPaymentBankReducer = (state = {}, action) => {
    switch (action.type) {

        case FACILITY_PAYMENT_BANK_REQUEST:
            return { loading: true }

        case FACILITY_PAYMENT_BANK_SUCCESS:
            return { loading: false, success: true, facilityBankPaymentSent: action.payload }

        case FACILITY_PAYMENT_BANK_FAIL:
            return { loading: false, error: action.payload }

        case FACILITY_OWNER_REGISTER_RESET:
            return {}

        default:
            return state
    }
}

export const facilityDetailsReducer = (state = { loading: true, facilityInfo: {} }, action) => {
    switch (action.type) {
        case FACILITY_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FACILITY_DETAILS_SUCCESS:
            return {
                loading: false,
                success: true,
                facilityInfo: action.payload
            }

        case FACILITY_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}


export const facilityListReducer = (state = { facilities: [] }, action) => {
    switch (action.type) {

        case FACILITY_LIST_REQUEST:
            return { loading: true }

        case FACILITY_LIST_SUCCESS:
            return {
                loading: false,
                facilities: action.payload.facilities,
                page: action.payload.page,
                pages: action.payload.pages,
            }

        case FACILITY_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

