
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Row, Col, Card, Table, Form, Button, Modal} from 'react-bootstrap';
import AdminLayout from "./layouts/AdminLayout";
import "../styles/newOrderAdmin.css";
import "../styles/invoiceAllType.css";
import "../styles/certificateAdmin.css";
import "../styles/clientInformation.css";

import "../styles/data_form.css";
import arrow from '../imgs/arrow.png'
import date from "../imgs/date.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import rarrow from "../imgs/right-arrow.png";

import axios from 'axios';
import {getFacilityDetailsForAdmin, updateFacilityInfoForAdmin} from '../actions/adminActions'


function UpdateFacilityInformation({match, history}) {

    const facilityId = match.params.id

    const [show, setShow] = useState(false)

    const [sec, setSec ] = useState("")
    
    const [secType, setSecType] = useState("")

    const [IsDisplayed, setIsDisplayed] = useState(false)

    const [IsDisplayed2, setIsDisplayed2] = useState(false)

    const [IsDisplayed4, setIsDisplayed4] = useState(false)

    const [IsDisplayed3, setIsDisplayed3] = useState(false)

    const [IsDisplayed1, setIsDisplayed1] = useState(false)

    const [placeHolder2, setPlaceHolder2] = useState(true)

    const [palceHolder, setPalceHolder] = useState(true)

    const [name, setName] = useState('')

    const [mailBox, setMailBox] = useState("")

    const [postalCode, setPostalCode] = useState('')

    const [selectTitle, setSelectTitle] = useState('')

    const [startDateCommercialRegister, setStartDateCommercialRegister] = useState('')

    const [endDateCommercialRegister, setEndDateCommercialRegister] = useState('')

    const [medicalLicenseNumber, setMedicalLicenseNumber] = useState('')

    const [startDateMedicalLicense, setStartDateMedicalLicense] = useState('')

    const [endDateMedicalLicense, setEndDateMedicalLicense] = useState('')

    const [licenseNumber, setLicenseNumber] = useState('')

    const [startDateLicense, setStartDateLicense] = useState('')

    const [endDateLicense, setEndDateLicense] = useState('')

    const [warrantyBoardNumber, setWarrantyBoardNumber] = useState('')

    const [startDateWarrantyBoard, setStartDateWarrantyBoard] = useState('')

    const [endDateWarrantyBoard, setEndDateWarrantyBoard] = useState('')

    const [foodAuthorityNumber, setfoodAuthorityNumber] = useState('')

    const [startDateFoodAuthority, setstartDateFoodAuthority] = useState('')

    const [endDateFoodAuthority, setEndDateFoodAuthority] = useState('')

    const [centralBankNumber, setCentralBankNumber] = useState('')

    const [startDateBank, setStartDateBank] = useState('')

    const [endDateBank, setEndDateBank] = useState('')

    const [bankName, setBankName] = useState('')

    const [ibanNumber, setIbanNumber] = useState('')

    const [financialManagerName, setFinancialManagerName] = useState('')

    const [fmEmail, setFmEmail] = useState('')

    const [fmPhoneNumber, setFmPhoneNumber] = useState('')

    const [salesMangerName, setSalesMangerName] = useState('')

    const [smEmail, setSmEmail] = useState('')

    const [smPhoneNumber, setSmPhoneNumber] = useState('')

    const [valueAddNumber, setValueAddNumber] = useState('')

    const [employeeNumber, setEmployeeNumber] = useState('')

    const [ownerName, setOwnerName] = useState('')

    const [ownerPhone, setOwnerPhone] = useState('')

    const [ownerAddress, setOwnerAddress] = useState('')

    const [ownerRegion, setOwnerRegion] = useState('')

    const [ownerPostalCode, setOwnerPostalCode] = useState('')

    const [ownerId, setOwnerId] = useState('')

    const [ownerEmail, setOwnerEmail] = useState('')

    const [ownerMailBox, setOwnerMailBox] = useState('')

    const [ownerCity, setOwnerCity] = useState('')

    const [employeeName, setEmployeeName] = useState('')

    const [employeePhone, setEmployeePhone] = useState('')

    const [employeeAddress, setEmployeeAddress] = useState('')

    const [employeeRegion, setEmployeeRegion] = useState('')

    const [employeePostalCode, setEmployeePostalCode] = useState('')

    const [employeeId, setEmployeeId] = useState('')

    const [employeeEmail, setEmployeeEmail] = useState('')

    const [employeeMailBox, setEmployeeMailBox] = useState('')

    const [employeeCity, setEmployeeCity] = useState('')

    const regions = [
        "الرياض",
        "مكة",
        "المدينة المنورة",
        "القصيم",
        "الشرقية",
        "عسير",
        "تبوك",
        "حائل",
        "الحدود الشمالية",
        "جازان",
        "نجران",
        "الباحة",
        "الجوف",
    ];


    const cities = [
        "الرياض",
        "جدة",
        "مكة",
        "المدينة المنورة",
        "الدمام",
        "الطائف",
        "بريدة",
        "تبوك",
        "ابها",
        "خميس مشيط",
        "الأحساء",
        "القطيف",
        "الخبر",
        "حفر الباطن",
        "الجبيل",
        "الخرج",
        "حائل",
        "نجران",
        "ينبع",
        "صبيا",
        "الدوادمي",
        "بيشة",
        "أبو عريش",
        "القنفذة",
        "محايل",
        "سكاكا",
        "عرعر",
        "عنيزة",
        "القريات",
        "صامطة",
        "جازان",
        "المجمعة",
        "القويعية",
        "رأس تنورة	",
        "النماص	",
        "الوجه	",
        "خيبر	",
        "النبهانية	",
      ];

  const dispatch = useDispatch()

  const facilityDetailsForAdmin = useSelector(state => state.facilityDetailsForAdmin)
  const {facilityInfo, success, loading} = facilityDetailsForAdmin

  const facilityUpdateForAdmin = useSelector(state => state.facilityUpdateForAdmin)
  const {facility} = facilityUpdateForAdmin

  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  useEffect(() => {

    if((userInfo.user).startsWith("50")) {
        dispatch(getFacilityDetailsForAdmin(match.params.id))
        if(success)  { 
            setName(facilityInfo?.facility_name)
            setSelectTitle(facilityInfo?.state)
            setStartDateCommercialRegister(retrieveDate(facilityInfo?.issue_date))
            setEndDateCommercialRegister(retrieveDate(facilityInfo?.expiration_date))
            setMailBox(facilityInfo?.post_box)
            setPostalCode(facilityInfo?.postal_code)
            setSelectTitle(facilityInfo?.location)
            setMedicalLicenseNumber(facilityInfo?.medical_permit_num)
            setStartDateMedicalLicense(retrieveDate(facilityInfo?.permit_issue_date))
            setEndDateMedicalLicense(retrieveDate(facilityInfo?.permit_expiry_date))
            setWarrantyBoardNumber(facilityInfo?.health_insurance_council_num)
            setStartDateWarrantyBoard(retrieveDate(facilityInfo?.insurance_issue_date))
            setEndDateWarrantyBoard(retrieveDate(facilityInfo?.insurance_expiry_date))
            setfoodAuthorityNumber(facilityInfo?.food_drug_authority_licence_num)
            setstartDateFoodAuthority(retrieveDate(facilityInfo?.food_drug_authority_issue_date))
            setEndDateFoodAuthority(retrieveDate(facilityInfo?.food_drug_authority_expiry_date))
            setCentralBankNumber(facilityInfo?.central_bank_number)
            setStartDateBank(retrieveDate(facilityInfo?.central_bank_issue_date))
            setEndDateBank(retrieveDate(facilityInfo?.central_bank_expiry_date))
            setIbanNumber(facilityInfo?.iban_num)
            setBankName(facilityInfo?.bank_name)
            setFinancialManagerName(facilityInfo?.financial_manager_name)
            setFmEmail(facilityInfo?.financial_manager_email)
            setFmPhoneNumber(facilityInfo?.financial_manager_phone_num)
            setValueAddNumber(facilityInfo?.tax_number)
            setSalesMangerName(facilityInfo?.purchasing_manager_name)
            setSmEmail(facilityInfo?.purchasing_manager_email)
            setSmPhoneNumber(facilityInfo?.purchasing_manager_phone_num)
            setEmployeeNumber(facilityInfo?.num_of_employees)
            setSec(facilityInfo?.sector)
            setSecType(facilityInfo?.sector_type) 

            {/************** Owner *************/}
            setOwnerName(facilityInfo?.facility_owners[0]?.name)
            setOwnerPhone(facilityInfo?.facility_owners[0]?.phone_number) 
            setOwnerAddress(facilityInfo?.facility_owners[0]?.address) 
            setOwnerRegion(facilityInfo?.facility_owners[0]?.state)
            setOwnerPostalCode(facilityInfo?.facility_owners[0]?.postal_code)
            setOwnerId(facilityInfo?.facility_owners[0]?.identity_number) 
            setOwnerEmail(facilityInfo?.facility_owners[0]?.user.email) 
            setOwnerMailBox(facilityInfo?.facility_owners[0]?.post_box) 
            setOwnerCity(facilityInfo?.facility_owners[0]?.city)
            
             {/************** Employee *************/}
             setEmployeeName(facilityInfo?.facility_employees[0]?.name)
             setEmployeePhone(facilityInfo?.facility_employees[0]?.phone_number) 
             setEmployeeAddress(facilityInfo?.facility_employees[0]?.address) 
             setEmployeeRegion(facilityInfo?.facility_employees[0]?.state)
             setEmployeePostalCode(facilityInfo?.facility_employees[0]?.postal_code)
             setEmployeeId(facilityInfo?.facility_employees[0]?.identity_number) 
             setEmployeeEmail(facilityInfo?.facility_employees[0]?.user.email) 
             setEmployeeMailBox(facilityInfo?.facility_employees[0]?.post_box) 
             setEmployeeCity(facilityInfo?.facility_employees[0]?.city)





          }
    } else {
      history.push('/')
    }

  }, [dispatch, history, userInfo, success])

  const retrieveDate = (d) => {
    let date = new Date(d);
    return date
  }

  const selectClick1 = () => {
    setIsDisplayed1(IsDisplayed1 === true ? false : true);
  };

  const selectClick3 = () => {
    setIsDisplayed3(IsDisplayed3 === true ? false : true);
  };

  const selectClick2 = () => {
    setIsDisplayed2(IsDisplayed2 === true ? false : true);
};

const selectClick4 = () => {
    setIsDisplayed4(IsDisplayed4 === true ? false : true);
};

  const changeSelect1 = (title) => {
    setIsDisplayed1(false)
    setOwnerRegion(title)
};

  var fileDownload = require('js-file-download');


  const handlePDFDownload = (filename) => {
    axios.post('management/download/', { 
        responseType: 'blob', filename: filename
    }).then(res => {
        fileDownload(res.data, filename);
    }).catch(err => {
    })
}

const changeSelect = (title) => {
    setIsDisplayed(false)
    setPalceHolder(false)
    setSelectTitle(title)
};

const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
};



const changeSelect2 = (title) => {
    setIsDisplayed2(false)
    setOwnerCity(title)
};

const changeSelect3 = (title) => {
    setIsDisplayed3(false)
    setEmployeeRegion(title)
};

const changeSelect4 = (title) => {
    setIsDisplayed4(false)
    setEmployeeCity(title)
};

const focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
};

const focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
};

const handleDate = (d) => {
    if (d) {
      const offset = d.getTimezoneOffset();
      let newDate = new Date(d.getTime() - (offset*60*1000));
      return newDate.toISOString().split('T')[0];
    }
}

const selectClick = () => {
    setIsDisplayed(IsDisplayed === true ? false : true);
};

const showModel = () => {
  return(
  <Modal
      style={{ direction: "rtl" }}
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
  >
      <Modal.Header style={{backgroundColor: 'var(--main-color)', justifyContent:'center'}}>
          <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
      </Modal.Header>
      <Modal.Body className="h4">
      تم التعديل بنجاح
      </Modal.Body>
      <Modal.Footer>
          <Button
              variant="danger"
              className="btn-red"
              onClick={() => setShow(false)}
          >
              <span className="h4">تم</span>
          </Button>
      </Modal.Footer>
  </Modal>
  )
}


const submitHandler = (e) => {
    e.preventDefault()
    
      let data = JSON.stringify({
        "bayan_type": "",
        "facility_name": name,
        "state": selectTitle,
        "issue_date": handleDate(startDateCommercialRegister),
        "expiration_date": handleDate(endDateCommercialRegister),
        "post_box": mailBox,
        "postal_code": postalCode,
        "location": selectTitle,
        "medical_permit_num": medicalLicenseNumber,
        "permit_issue_date": handleDate(startDateMedicalLicense),
        "permit_expiry_date": handleDate(endDateMedicalLicense),
        "health_insurance_council_num": warrantyBoardNumber,
        "insurance_issue_date": handleDate(startDateWarrantyBoard),
        "insurance_expiry_date": handleDate(endDateWarrantyBoard),
        "food_drug_authority_licence_num": foodAuthorityNumber,
        "food_drug_authority_issue_date": handleDate(startDateFoodAuthority),
        "food_drug_authority_expiry_date": handleDate(endDateFoodAuthority),
        "central_bank_number":centralBankNumber,
        "central_bank_issue_date": handleDate(startDateBank),
        "central_bank_expiry_date": handleDate(endDateBank),
        "iban_num": ibanNumber,
        "bank_name": bankName,
        "financial_manager_name": financialManagerName,
        "financial_manager_email": fmEmail,
        "financial_manager_phone_num": fmPhoneNumber,
        "tax_number": valueAddNumber,
        "purchasing_manager_name": salesMangerName,
        "purchasing_manager_email": smEmail,
        "purchasing_manager_phone_num": smPhoneNumber,
        "agreeing_to_entered_data_correct": true,
        "num_of_employees": parseInt(employeeNumber),
        "sector":sec,
        "sector_type": secType,

      });
      dispatch(updateFacilityInfoForAdmin(data, facilityId))
      setShow(true)

}

const submitHandler1 = (e) => {
  e.preventDefault()
  let data = JSON.stringify({
    "bayan_type": "owner",
  
    "name": ownerName,
    "phone_number" :ownerPhone,
    "identity_number": ownerId,
    "address" : ownerAddress,
    "state": ownerRegion,
    "postal_code" : ownerPostalCode,
    "email": ownerEmail,
    "post_box": ownerMailBox, 
    "city" : ownerCity  
  })

  dispatch(updateFacilityInfoForAdmin(data, facilityId))
  setShow(true)

}


const submitHandler2 = (e) => {
  e.preventDefault()
  let data = JSON.stringify({
    "bayan_type": "employee",
  
    "name": employeeName,
    "phone_number" :employeePhone,
    "identity_number": employeeId,
    "address" : employeeAddress,
    "state": employeeRegion,
    "postal_code" : employeePostalCode,
    "email": employeeEmail,
    "post_box": employeeMailBox, 
    "city" : employeeCity  
  })

  dispatch(updateFacilityInfoForAdmin(data, facilityId))
  setShow(true)

}

return (
    <AdminLayout>
      
      {!loading &&
    (<div>
    <div className="data-form p-3">
      <div className="desc-title text-center">تعديل معلومات المنشأة</div>
      <div className="sub-title">فضلا اكمل البيانات الخاصه بالمنشأة</div>
      <form onSubmit={submitHandler}>

        <div className="name-form">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="name">اسم الجهة</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange= {(e) => setName(e.target.value)}
                  placeholder="اكتب اسم الجهة"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>                
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="mailBox">رقم ص.ب</label>
                <input
                  type="text"
                  id="mailBox"
                  name="mailBox"
                  value={mailBox}
                  onChange= {(e) => setMailBox(e.target.value)}
                  placeholder="ادخل رقم ص.ب"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="postalCode">الرمز البريدى</label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={postalCode}
                  onChange= {(e) => setPostalCode(e.target.value)}
                  placeholder="ادخل الرمز البريدى"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date1">موقع المنشأة</label>
                <div className="select-city">
                  <div className="select-btn" onClick={selectClick}>
                    
                    <span>{selectTitle}</span>
                    <img src={rarrow} alt='' />
                  </div>
                  {IsDisplayed && (
                    <div className="select-content">
                      {cities.map((option, i) => (
                        <div key={i}
                          className="select-item"
                          onClick={(e) => {changeSelect(e.target.textContent);
                          }}
                        >
                          {" "}
                          {option}{" "}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">تاربخ اصدار السجل التجاري</label>
                <DatePicker
                  selected={startDateCommercialRegister}
                  onChange= {(date) => setStartDateCommercialRegister(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الاصدار"
                  popperPlacement="top"
                />
                <img src={date} alt="" style={{left: "18px"}}/>
              </div>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">تاريخ انتهاء السجل التجارى</label>
                <DatePicker
                  selected={endDateCommercialRegister}
                  onChange= {(date) => setEndDateCommercialRegister(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الانتهاء"
                  popperPlacement="top"
                />
                <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>             
          </Row>
        </div>
        
        {sec !== 7 && sec !== 8 &&
          <div className="medical-licence-no">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">رقم الترخيص </label>
                <input
                  dir="rtl"
                  type="text"
                  id="licenseNumber"
                  name="licenseNumber"
                  value={licenseNumber}
                  onChange= {(e) => setLicenseNumber(e.target.value)}
                  placeholder="اكتب رقم الترخيص"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">تاريخ اصدار الترخيص </label>
                <DatePicker
                  selected={startDateLicense}
                  onChange= {(date) => setStartDateLicense(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الاصدار"
                  popperPlacement="top"
                />
                <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>              
              <div className="input-lable w-100">
                <label htmlFor="date2">تاربخ انتهاء الترخيص </label>
                <DatePicker
                  selected={endDateLicense}
                  onChange= {(date) => setEndDateLicense(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الانتهاء"
                  popperPlacement="top"
                />
                <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
          </Row>
        </div>            
        }

        {((sec === 7)) &&
          <div className="medical-licence-no">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">رقم الترخيص الطبي</label>
                <input
                  dir="rtl"
                  type="text"
                  id="medicalLicenseNumber"
                  name="medicalLicenseNumber"
                  value={medicalLicenseNumber}
                  onChange= {(e) => setMedicalLicenseNumber(e.target.value)}
                  placeholder="اكتب رقم الترخيص"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">تاريخ اصدار الترخيص الطبي</label>
                <DatePicker
                  selected={startDateMedicalLicense}
                  onChange= {(date) => setStartDateMedicalLicense(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الاصدار"
                  popperPlacement="top"
                />
                <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>              
              <div className="input-lable w-100">
                <label htmlFor="date2">تاربخ انتهاء الترخيص الطبي</label>
                <DatePicker
                  selected={endDateMedicalLicense}
                  onChange= {(date) => setEndDateMedicalLicense(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الانتهاء"
                  popperPlacement="top"
                />
                <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
          </Row>
        </div>
        }
        
        {(sec === 7) &&
        <div className="warranty-board-no">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">رقم مجلس الضمان</label>
                <input
                  dir="rtl"
                  type="text"
                  id="warrantyBoardNumber"
                  name="warrantyBoardNumber"
                  value={warrantyBoardNumber}
                  onChange= {(e) => setWarrantyBoardNumber(e.target.value)}
                  placeholder="اكتب رقم مجلس الضمان"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>  
              <div className="input-lable w-100">
                <label htmlFor="date2">تاريخ اصدار الرقم </label>
                <DatePicker
                  selected={startDateWarrantyBoard}
                  onChange= {(date) => setStartDateWarrantyBoard(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الاصدار"
                  popperPlacement="top"
                />
                <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}> 
              <div className="input-lable w-100">
                <label htmlFor="date2">تاربخ انتهاء الرقم </label>
                <DatePicker
                  selected={endDateWarrantyBoard}
                  onChange= {(date) => setEndDateWarrantyBoard(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الانتهاء"
                  popperPlacement="top"
                />
                <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
          </Row>
        </div>
        }
        {(((sec !== 15) && ((sec === 7) && (secType !== 28))) || (sec === 8))  &&
        
        <div className="food-licence-no">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                  <label htmlFor="date2">رقم ترخيص هيئة الغذاء والدواء</label>
                  <input
                  dir="rtl"
                  type="text"
                  id="foodAuthorityNumber"
                  name="foodAuthorityNumber"
                  value={foodAuthorityNumber}
                  onChange= {(e) => setfoodAuthorityNumber(e.target.value)}
                  placeholder="اكتب رقم ترخيص هيئة الغذاء والدواء"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                  <label htmlFor="date2">تاريخ اصدار الترخيص </label>
                  <DatePicker
                  selected={startDateFoodAuthority}
                  onChange= {(date) => setstartDateFoodAuthority(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الاصدار"
                  popperPlacement="top"
                  />
                  <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                  <label htmlFor="date2">تاربخ انتهاء الترخيص </label>
                  <DatePicker
                  selected={endDateFoodAuthority}
                  onChange= {(date) => setEndDateFoodAuthority(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الانتهاء"
                  popperPlacement="top"
                  />
                  <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
          </Row>
        </div>
        }
        {((sec === 7)) &&
        <div className="bank-no">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                  <label htmlFor="date2">رقم ترخيص البنك المركزى السعودى</label>
                  <input
                  dir="rtl"
                  type="text"
                  id="centralBankNumber"
                  name="centralBankNumber"
                  value={centralBankNumber}
                  onChange= {(e) => setCentralBankNumber(e.target.value)}
                  placeholder="اكتب رقم ترخيص البنك المركزى السعودى"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                  <label htmlFor="date2">
                  تاربخ اصدار البنك المركزى السعودى
                  </label>
                  <DatePicker
                  selected={startDateBank}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  onChange= {(date) => setStartDateBank(date)}
                  
                  placeholderText="اختر تاريخ الاصدار"
                  popperPlacement="top"
                  />
                  <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                  <label htmlFor="date2">
                  تاربخ انتهاء البنك المركزى السعودى
                  </label>
                  <DatePicker
                  selected={endDateBank}
                  onChange= {(date) => setEndDateBank(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  
                  placeholderText="اختر تاريخ الاصدار"
                  popperPlacement="top"
                  />
                  <img src={date} alt="" style={{left: "18px"}} />
              </div>
            </Col>
          </Row>
        </div>
        }
        <div className="bank-name">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">اسم البنك</label>
                <input
                  dir="rtl"
                  type="text"
                  id="bankName"
                  name="bankName"
                  value={bankName}
                  onChange= {(e) => setBankName(e.target.value)}
                  placeholder="اكتب اسم البنك"
                  onFocus={focusHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">رقم IBN</label>
                <input
                  dir="rtl"
                  type="text"
                  id="ibanNumber"
                  name="ibanNumber"
                  value={ibanNumber}
                  onChange= {(e) => setIbanNumber(e.target.value)}
                  placeholder="اكتب رقم IBN"
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="manager-name">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">اسم المدير المالي</label>
                <input
                  dir="rtl"
                  type="text"
                  id="financialManagerName"
                  name="financialManagerName"
                  value={financialManagerName}
                  onChange= {(e) => setFinancialManagerName(e.target.value)}
                  placeholder="اكتب اسم المدير المالي"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">البريد الالكتروني</label>
                <input
                  dir="rtl"
                  type="text"
                  id="fmEmail"
                  name="fmEmail"
                  value={fmEmail}
                  onChange= {(e) => setFmEmail(e.target.value)}
                  placeholder="اكتب البريد الالكترونى"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">رقم الهاتف</label>
                <input
                  dir="rtl"
                  type="text"
                  id="fmPhoneNumber"
                  name="fmPhoneNumber"
                  value={fmPhoneNumber}
                  onChange= {(e) => setFmPhoneNumber(e.target.value)}
                  placeholder="اكتب رقم الهاتف"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="sales-manager">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">اسم مدير المشتريات</label>
                <input
                  dir="rtl"
                  type="text"
                  id="salesManagerName"
                  name="salesManagerName"
                  value={salesMangerName}
                  onChange= {(e) => setSalesMangerName(e.target.value)}
                  placeholder="اكتب اسم مدير المشتريات"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">البريد الالكتروني</label>
                <input
                  dir="rtl"
                  type="text"
                  id="smEmail"
                  name="smEmail"
                  value={smEmail}
                  onChange= {(e) => setSmEmail(e.target.value)}
                  placeholder="اكتب البريد الالكترونى"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">رقم الهاتف</label>
                <input
                  dir="rtl"
                  type="text"
                  id="smPhoneNumber"
                  name="smPhoneNumber"
                  value={smPhoneNumber}
                  onChange= {(e) => setSmPhoneNumber(e.target.value)}
                  placeholder="اكتب رقم الهاتف"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="enterprise-info">
          <Row className="w-100">
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">رقم ضريبة القيمة المضافة</label>
                <input
                  dir="rtl"
                  id="{valueAddNumber}"
                  name="valueAddNumber"
                  value={valueAddNumber}
                  onChange= {(e) => setValueAddNumber(e.target.value)}
                  placeholder="اكتب رقم ضريبة القيمة المضافة"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="input-lable w-100">
                <label htmlFor="date2">عدد موظفين المنشأة</label>
                <input
                  dir="rtl"
                  type="number"
                  id="{employeeNumber}"
                  name="employeeNumber"
                  value={employeeNumber}
                  onChange= {(e) => setEmployeeNumber(e.target.value)}
                  placeholder="اكتب عدد موظفين المنشأة"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
            </Col>
          </Row>
        </div>
        <button className="btnNext" type="submit">
          <img src={arrow} alt="" />
          تعديل 
        </button>
      </form>      
    </div>

    {/************ OWner *************/}
    <div className="data-form p-3">
        <div className="desc-title text-center">تعديل  معلومات  صاحب المنشأة أو المدير</div>
        <div className="sub-title">فضلا اكمل البيانات الخاصه بالمنشأة</div>
        <form onSubmit={submitHandler1}>
            <div className="name-form">
                <Row className="w-100">
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="owner-name">الاسم</label>
                            <input 
                                type="text"
                                id="owner-name"
                                name="ownerName"
                                value={ownerName}
                                onChange={(e) => setOwnerName(e.target.value)}
                                placeholder="اكتب الاسم هنا"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="owner-phone">رقم الهاتف</label>
                            <input
                                type="text"
                                id="owner-phone"
                                name="ownerPhone"
                                value={ownerPhone}
                                onChange={(e) => setOwnerPhone(e.target.value)}
                                placeholder="ادخل الرقم هنا"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="owner-address">العنوان</label>
                            <input
                                type="text"
                                id="owner-address"
                                name="ownerAddress"
                                value={ownerAddress}
                                onChange={(e) => setOwnerAddress(e.target.value)}
                                placeholder="اكتب العنوان هنا"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable w-100">
                            <label htmlFor="name">المنطقة</label>
                            <div className="select-city">
                                <div className="select-btn" onClick={selectClick1}>
                                    <span>{ownerRegion}</span>
                                    <img src={rarrow} />
                                </div>
                                {IsDisplayed1 && (
                                    <div className="select-content">
                                    {regions.map((option) => (
                                        <div
                                            data-name='region'
                                            className="select-item"
                                            onClick={(e) => {changeSelect1(e.target.textContent);}}
                                        >
                                            {" "}
                                            {option}{" "}
                                        </div>
                                    ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="owner-postal-code">الرمز البريدى</label>
                            <input
                                type="text"
                                id="owner-postal-code"
                                name="ownerPostalCode"
                                value={ownerPostalCode}
                                onChange={(e) => setOwnerPostalCode(e.target.value)}
                                placeholder="ادخل الرمز البريدى"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="owner-id">رقم الهوية</label>
                            <input
                            type="text"
                            id="owner-id"
                            name="ownerId"
                            value={ownerId}
                            onChange={(e) => setOwnerId(e.target.value)}
                            placeholder="اكتب رقم الهوية"
                            onFocus={focusHandler}
                            onBlur={focusoutHandler}
                        />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="owner-email">الايميل الرسمي</label>
                            <input
                            type="text"
                            id="owner-email"
                            name="ownerEmail"
                            value={ownerEmail}
                            onChange={(e) => setOwnerEmail(e.target.value)}
                            placeholder="اكتب الايميل الرسمى"
                            onFocus={focusHandler}
                            onBlur={focusoutHandler}
                            />                            
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="owner-mail-box">رقم ص.ب</label>
                            <input
                            type="text"
                            id="owner-mail-box"
                            name="ownerMailBox"
                            value={ownerMailBox}
                            onChange={(e) => setOwnerMailBox(e.target.value)}
                            placeholder="اكتب رقم ص . ب"
                            onFocus={focusHandler}
                            onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable w-100">
                            <label htmlFor="name">المدينة</label>
                            <div className="select-city">
                                <div className="select-btn" onClick={selectClick2}>
                                    <span>{ownerCity}</span>
                                    <img src={rarrow} />
                                </div>
                                {IsDisplayed2 && (
                                    <div className="select-content">
                                    {cities.map((option) => (
                                        <div
                                            data-name='city'
                                            className="select-item"
                                            onClick={(e) => {changeSelect2(e.target.textContent);}}
                                        >
                                            {" "}
                                            {option}{" "}
                                        </div>
                                    ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>                      
                </Row>
            </div>
            <button className="btnNext" type="submit">
          <img src={arrow} alt="" />
          تعديل 
        </button>
        </form>
    </div>

    {/************ Employee *************/}
    <div className="data-form p-3">
        <div className="desc-title text-center">تعديل بيانات الموظف المصرح له بأستخدام المنصة</div>
        <div className="sub-title">فضلا اكمل البيانات الخاصه بالمنشأة</div>
        <form onSubmit={submitHandler2}>
            <div className="name-form">
                <Row className="w-100">
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="employee-name">الاسم</label>
                            <input 
                                type="text"
                                id="employee-name"
                                name="employeeName"
                                value={employeeName}
                                onChange={(e) => setEmployeeName(e.target.value)}
                                placeholder="اكتب الاسم هنا"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="employee-phone">رقم الهاتف</label>
                            <input
                                type="text"
                                id="employee-phone"
                                name="employeePhone"
                                value={employeePhone}
                                onChange={(e) => setEmployeePhone(e.target.value)}
                                placeholder="ادخل الرقم هنا"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="employee-address">العنوان</label>
                            <input
                                type="text"
                                id="employee-address"
                                name="employeeAddress"
                                value={employeeAddress}
                                onChange={(e) => setEmployeeAddress(e.target.value)}
                                placeholder="اكتب العنوان هنا"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable w-100">
                            <label htmlFor="name">المنطقة</label>
                            <div className="select-city">
                                <div className="select-btn" onClick={selectClick3}>
                                    <span>{employeeRegion}</span>
                                    <img src={rarrow} />
                                </div>
                                {IsDisplayed3 && (
                                    <div className="select-content">
                                    {regions.map((option) => (
                                        <div
                                            data-name='region'
                                            className="select-item"
                                            onClick={(e) => {changeSelect3(e.target.textContent);}}
                                        >
                                            {" "}
                                            {option}{" "}
                                        </div>
                                    ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="employee-postal-code">الرمز البريدى</label>
                            <input
                                type="text"
                                id="employee-postal-code"
                                name="employeePostalCode"
                                value={employeePostalCode}
                                onChange={(e) => setEmployeePostalCode(e.target.value)}
                                placeholder="ادخل الرمز البريدى"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="employee-id">رقم الهوية</label>
                            <input
                            type="text"
                            id="employee-id"
                            name="employeeId"
                            value={employeeId}
                            onChange={(e) => setEmployeeId(e.target.value)}
                            placeholder="اكتب رقم الهوية"
                            onFocus={focusHandler}
                            onBlur={focusoutHandler}
                        />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="employee-email">الايميل الرسمي</label>
                            <input
                            type="text"
                            id="employee-email"
                            name="employeeEmail"
                            value={employeeEmail}
                            onChange={(e) => setEmployeeEmail(e.target.value)}
                            placeholder="اكتب الايميل الرسمى"
                            onFocus={focusHandler}
                            onBlur={focusoutHandler}
                            />
                            
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable">
                            <label htmlFor="employee-mail-box">رقم ص.ب</label>
                            <input
                            type="text"
                            id="employee-mail-box"
                            name="employeeMailBox"
                            value={employeeMailBox}
                            onChange={(e) => setEmployeeMailBox(e.target.value)}
                            placeholder="اكتب رقم ص . ب"
                            onFocus={focusHandler}
                            onBlur={focusoutHandler}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="input-lable w-100">
                            <label htmlFor="name">المدينة</label>
                            <div className="select-city">
                                <div className="select-btn" onClick={selectClick4}>
                                    <span>{employeeCity}</span>
                                    <img src={rarrow} />
                                </div>
                                {IsDisplayed4 && (
                                    <div className="select-content">
                                    {cities.map((option) => (
                                        <div
                                            data-name='city'
                                            className="select-item"
                                            onClick={(e) => {changeSelect4(e.target.textContent);}}
                                        >
                                            {" "}
                                            {option}{" "}
                                        </div>
                                    ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>                      
                </Row>
            </div>
            <button className="btnNext" type="submit">
          <img src={arrow} alt="" />
          تعديل 
        </button>
        </form>
        <div>{showModel()}</div>
    </div>
    </div>
    )} 
    </AdminLayout>
)
}
export default UpdateFacilityInformation;
