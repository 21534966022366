import React, { Component } from "react";
import "../../styles/change_video.css";
import rarrow from "../../imgs/right-arrow1.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import complete from "../../imgs/check1.png";
import check from "../../imgs/check.png";
import arrow from "../../imgs/arrow.png";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";
export class Change_InsideVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IsDisplayed: false,
      palceHolder: true,
      service: "",
      path: "ارفق الملف",
      photo: false,
      wrong: false,
      showModal: false,
    };
  }

  submitHandler = () => {
    this.setState({ showModal: true });
  };

  getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  onUpload = (e) => {
    if (e.target.value) {
      const ext = this.getExtension(e.target.value);
      if (ext === "doc" || ext === "pdf") {
        this.setState({
          photo: true,
          path: e.target.value,
          wrong: false,
        });
      } else {
        this.setState({
          photo: false,
          path: "ارفق الملف",
          wrong: true,
        });
      }
    }
  };

  changeSelect = (title) => {
    this.setState({
      IsDisplayed: false,
      palceHolder: false,
      service: title,
    });
  };

  selectClick = () => {
    this.setState((prevState) => {
      return { IsDisplayed: prevState.IsDisplayed === true ? false : true };
    });
  };

  render() {
    const { IsDisplayed, palceHolder, service, path, photo, wrong } =
      this.state;

    const services = [
      "تقييم الشبكات الطبية",
      "تأجير أو بيع الأجهزة الطبية",
      "تأجير خدمات الأطباء والعيادات",
      "مقارنة أسعار تامين اخطاء ممارسة المهن الطبية",
      "التحصيل",
      "تنظيم وتشغيل عقود المختبرات",
      "تنظيم وتشغيل عقود الأشعة ",
      "تنظيم مناقصات التوريد",
    ];

    return (
      <div className="change_video">
        <div className="input-lable">
          <label htmlFor="name">اسم الخدمة</label>
          <div className="select-city">
            <div className="select-btn" onClick={this.selectClick}>
              {palceHolder && (
                <span style={{ color: "var(--lightgray-color)" }}>
                  اختر الخدمة
                </span>
              )}
              <span>{service}</span>
              <img src={rarrow} />
            </div>
            {IsDisplayed && (
              <div className="select-content">
                {services.map((option) => (
                  <div
                    className="select-item"
                    onClick={(e) => {
                      this.changeSelect(e.target.textContent);
                    }}
                  >
                    {" "}
                    {option}{" "}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="input-content">
          <p>ارفق ملف التعديل</p>
          <label for="upload-photo1">
            {" "}
            <div className="input pad">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="30.706"
                viewBox="0 0 24 30.706"
              >
                <g id="upload" opacity="0.83">
                  <path
                    id="Path_2318"
                    data-name="Path 2318"
                    d="M79.775,7.088l-2.3-2.3V1.365A1.366,1.366,0,0,0,76.113,0H57.255a1.349,1.349,0,0,0-1.348,1.348V23.959a1.349,1.349,0,0,0,1.348,1.348h.762v.751a1.349,1.349,0,0,0,1.348,1.348h2.924a.45.45,0,1,0,0-.9H59.365a.449.449,0,0,1-.448-.448V7.974a.45.45,0,1,0-.9,0V24.407h-.762a.449.449,0,0,1-.448-.448V1.348A.449.449,0,0,1,57.255.9H76.113a.466.466,0,0,1,.465.465V3.892L74.918,2.231A.45.45,0,0,0,74.6,2.1H59.365a1.349,1.349,0,0,0-1.348,1.348V5.88a.45.45,0,1,0,.9,0V3.447A.449.449,0,0,1,59.365,3H74.15V7.405a.45.45,0,0,0,.45.45h4.408v18.2a.449.449,0,0,1-.448.448H75.67a.45.45,0,1,0,0,.9h2.889a1.349,1.349,0,0,0,1.348-1.348V7.406a.45.45,0,0,0-.132-.318Zm-4.726-.132V3.635l3.322,3.322Z"
                    transform="translate(-55.907 0)"
                    fill="#1e1e1e"
                  />
                  <path
                    id="Path_2319"
                    data-name="Path 2319"
                    d="M187.778,175.456h-10.76a.45.45,0,0,0,0,.9h10.76a.45.45,0,0,0,0-.9Z"
                    transform="translate(-169.332 -164.934)"
                    fill="#1e1e1e"
                  />
                  <path
                    id="Path_2320"
                    data-name="Path 2320"
                    d="M187.778,219.688h-10.76a.45.45,0,0,0,0,.9h10.76a.45.45,0,0,0,0-.9Z"
                    transform="translate(-169.332 -206.513)"
                    fill="#1e1e1e"
                  />
                  <path
                    id="Path_2321"
                    data-name="Path 2321"
                    d="M323.119,264.8h-2.126a.45.45,0,1,0,0,.9h2.126a.45.45,0,0,0,0-.9Z"
                    transform="translate(-304.672 -248.921)"
                    fill="#1e1e1e"
                  />
                  <path
                    id="Path_2322"
                    data-name="Path 2322"
                    d="M183.535,265.7a.45.45,0,1,0,0-.9h-6.518a.45.45,0,0,0,0,.9Z"
                    transform="translate(-169.332 -248.921)"
                    fill="#1e1e1e"
                  />
                  <path
                    id="Path_2323"
                    data-name="Path 2323"
                    d="M237.548,367.735l-1.2-1.2v4.348a.45.45,0,1,1-.9,0v-4.348l-1.2,1.2a.45.45,0,0,1-.636-.636l1.967-1.967a.45.45,0,0,1,.636,0l1.967,1.967a.45.45,0,0,1-.636.636Z"
                    transform="translate(-222.833 -343.11)"
                    fill="#1e1e1e"
                  />
                  <path
                    id="Path_2324"
                    data-name="Path 2324"
                    d="M185.229,323.61a5.649,5.649,0,1,0,5.649,5.649A5.656,5.656,0,0,0,185.229,323.61Zm0,10.4a4.749,4.749,0,1,1,4.75-4.749A4.755,4.755,0,0,1,185.229,334.009Z"
                    transform="translate(-172.163 -304.202)"
                    fill="#1e1e1e"
                  />
                </g>
              </svg>
              <div>{path}</div>
            </div>{" "}
          </label>
          <input
            onChange={this.onUpload}
            type="file"
            name="photo1"
            id="upload-photo1"
          />
          {photo && <img src={complete} alt="" />}
          {wrong && <AiOutlineCloseCircle className="complete" />}
        </div>

        <div className="text-center d-flex justify-content-center">
          <Button className="text-center btnNext" onClick={this.submitHandler}>
            <img src={arrow} alt="" />
            تنفيذ
          </Button>
        </div>
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Change_InsideVideo;
