import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEvaluationDetailsForAdmin } from "../../actions/adminActions";
import { Container, Button, Row, Col, Table } from "react-bootstrap";
import QRCode from "react-qr-code";
import Hdate from "./Hdate";
import logo from "./logo.svg";
import social1 from "./social1.svg";
import social2 from "./social2.svg";
import social3 from "./social3.svg";
import social4 from "./social4.svg";
import axios from "axios";
import "../../styles/newOrderAdmin.css";
import "../../styles/invoiceAllType.css";
import "../../styles/certificateAdmin.css";


function NewOrder({ match }) {
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const dispatch = useDispatch();

	const evaluationDetailsForAdmin = useSelector(
		(state) => state.evaluationDetailsForAdmin
	);
	const { evaluation, loading } = evaluationDetailsForAdmin;

	useEffect(() => {
		dispatch(getEvaluationDetailsForAdmin(match.params.id));
	}, [userInfo, dispatch]);

	var fileDownload = require("js-file-download");

	const [pdfBtn, setPdfBtn] = useState(false);

	const handlePDFDownload = (filename) => {
		setPdfBtn(true);
		axios
			.post("management/download/", {
				responseType: "blob",
				filename: filename,
			})
			.then((res) => {
				fileDownload(res.data, filename);
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};


	function handleClick() {
		window.print();
	}

	return (
		<div>
			{!loading &&
				<div className="w-100" style={{ direction: "rtl" }}>
					{evaluation != null ? (
						<>
							<Container className="inv_a4 screen">
								<Button
									onClick={() => handleClick()}
									className="print_hide m-4 inv_button-print bg-secondary"
								>
									طباعة
								</Button>
								<div className="header_c" />
								<h1 className="inv_h1" style={{ fontSize: "1.4rem" }}>
									إضافة بيانات التقييم
								</h1>
								<img className="inv_img" alt="logo" src={logo} />
								<Hdate
									className="inv_Hdate_table"
									date={evaluation.created_at.substring(0, 10)}
									code={evaluation.id}
									codeTitle="كود العرض:"
								/>
								<p className="inv_QR_title">رمز العرض:</p>
								<QRCode className="inv_QR" value={window.location.href} size="75" />

								<div className="inv_data mt-3">
									<p>الخدمة:</p>
									<p>تقييم الشبكات الطبية</p>
									<p></p>
								</div>

								<div className="inv_table">
									<Row>
										<Col lg="12" className="p-0">
											<Table borderless>
												<thead className="thead">
													<tr>
														<th className="text-center" colSpan="6">
															بيانات موظف المنشأة
														</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className="td grayBG">كود المنشأة</td>
														<td className="td table_small">{evaluation.facility.client_code}</td>
														<td className="td grayBG">المدينة</td>
														<td className="td table_small">{evaluation.facility.state}</td>
														<td className="td grayBG">المنطقة</td>
														<td className="td table_small">{evaluation.facility.location}</td>
													</tr>

													<tr>
														<td className="td grayBG">مساحة المنشأة</td>
														<td className="td table_small"> M2 {evaluation.facility_area} </td>
														<td className="td grayBG">عدد الغرف الغير خاصة</td>
														<td className="td table_small">{evaluation.num_rooms}</td>
														<td className="td grayBG">عدد الغرف الخاصة</td>
														<td className="td table_small">{evaluation.num_private_rooms}</td>
													</tr>

													<tr>
														<td className="td grayBG">عدد الكادر الاداري</td>
														<td className="td table_small">{evaluation.num_admin_staff}</td>
														<td className="td grayBG">عدد الممرضات</td>
														<td className="td table_small">{evaluation.num_of_nurses}</td>
														<td className="td grayBG">عدد الكادر الفني للاشعة</td>
														<td className="td table_small">{evaluation.num_of_clinics}</td>


													</tr>

													<tr>
														<td className="td grayBG">عدد غرف العمليات</td>
														<td className="td table_small">{evaluation.num_operations_rooms}</td>
														<td className="td grayBG">عدد الاطباء</td>
														<td className="td table_small">{evaluation.num_of_doctors}</td>
														<td className="td grayBG">عدد الاسرة</td>
														<td className="td table_small">{evaluation.num_of_beds}</td>

													</tr>

													<tr>
														<td className="td grayBG">عدد العيادات</td>
														<td className="td table_small">{evaluation.num_of_clinics}</td>
														<td className="td grayBG">عدد مواقف المرضى</td>
														<td className="td table_small">{evaluation.num_of_parking_spots}</td>
														<td className="td grayBG">عدد الكادر الفني للمختبر</td>
														<td className="td table_small">{evaluation.num_of_technical_staff_lab}</td>
													</tr>

													<tr>
														<td className="td grayBG">مساحة الموقف</td>
														<td className="td table_small">{evaluation.parking_lot_area}</td>
														<td className="td grayBG">عدد النقاط</td>
														<td className="td table_small">{evaluation.evaluated_points}</td>
													</tr>
												</tbody>
											</Table>
										</Col>
									</Row>
									<Row>
										<Col lg="12" className="mt-4 p-0">
											<Row>
												<Col lg="12" className="text-end new_card" style={{ height: "83px" }}>
													<Row>
														<Col
															lg="12"
															className="d-flex flex-column align-items-center"
														>
															<Button
																onClick={() => handlePDFDownload(evaluation.services_prices_image)}
																className="btnNext m-0 my-2"
																style={{ width: "93%" }}
															>
																{" "}
																تحميل ملف الأسعار{" "}
															</Button>
														</Col>

													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col lg="12">
											<div>
												<p>العيادات:</p>
												<p>{evaluation.clinic_type}</p>
											</div>
										</Col>
									</Row>
									<div className="new_boxs mt-3">
										<Row>
											<Col lg="6" className="p-0">
												<p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
												<div className="new_box"></div>
											</Col>
											<Col lg="6" className="p-0">
												<p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
												<div className="new_box"></div>
											</Col>
										</Row>
										<Row className="new_sign">
											<Col lg="1">التوقيع:</Col>
											<Col lg="5">
												<div className="border-bottom mt-4" />
											</Col>
											<Col lg="1">التوقيع:</Col>
											<Col lg="5">
												<div className="border-bottom mt-4" />
											</Col>
										</Row>
									</div>
								</div>
								<div className="inv_footer">
									<span className="footer_text">الإيميل : info@bayan.med.sa</span>
									<div className="social_neworder_div">
										<a href="http://www.bayan.med.sa/">
											<img className="social_neworder" alt="social" src={social4} />
										</a>
										<a href="http://www.bayan.med.sa/">
											<img className="social_neworder" alt="social" src={social3} />
										</a>
										<a href="http://www.bayan.med.sa/">
											<img className="social_neworder" alt="social" src={social2} />
										</a>
										<a href="http://www.bayan.med.sa/">
											<img className="social_neworder" alt="social" src={social1} />
										</a>
									</div>
								</div>
							</Container>
							<page size="A4" className="print">
								<div className="header_c" />
								<h1 className="inv_h1" style={{ fontSize: "1.4rem" }}>
									إضافة بيانات التقييم
								</h1>
								<img className="inv_img" alt="logo" src={logo} />
								<Hdate
									className="inv_Hdate_table"
									date={evaluation.created_at.substring(0, 10)}
									code={evaluation.id}
									codeTitle="كود العرض:"
								/>
								<p className="inv_QR_title">رمز العرض:</p>
								<QRCode className="inv_QR" value={window.location.href} size="75" />

								<div className="inv_data mt-3">
									<p>الخدمة:</p>
									<p>تقييم الشبكات الطبية</p>
									<p></p>
								</div>

								<div className="inv_table">
									<Row>
										<Col lg="12" className="p-0">
											<Table borderless>
												<thead className="thead">
													<tr>
														<th className="text-center" colSpan="6">
															بيانات موظف المنشأة
														</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className="td grayBG">كود المنشأة</td>
														<td className="td table_small">{evaluation.facility.client_code}</td>
														<td className="td grayBG">المدينة</td>
														<td className="td table_small">{evaluation.facility.state}</td>
														<td className="td grayBG">المنطقة</td>
														<td className="td table_small">{evaluation.facility.location}</td>
													</tr>

													<tr>
														<td className="td grayBG">مساحة المنشأة</td>
														<td className="td table_small"> M2 {evaluation.facility_area} </td>
														<td className="td grayBG">عدد الغرف الغير خاصة</td>
														<td className="td table_small">{evaluation.num_rooms}</td>
														<td className="td grayBG">عدد الغرف الخاصة</td>
														<td className="td table_small">{evaluation.num_private_rooms}</td>
													</tr>

													<tr>
														<td className="td grayBG">عدد الكادر الاداري</td>
														<td className="td table_small">{evaluation.num_admin_staff}</td>
														<td className="td grayBG">عدد الممرضات</td>
														<td className="td table_small">{evaluation.num_of_nurses}</td>
														<td className="td grayBG">عدد الكادر الفني للاشعة</td>
														<td className="td table_small">{evaluation.num_of_clinics}</td>


													</tr>

													<tr>
														<td className="td grayBG">عدد غرف العمليات</td>
														<td className="td table_small">{evaluation.num_operations_rooms}</td>
														<td className="td grayBG">عدد الاطباء</td>
														<td className="td table_small">{evaluation.num_of_doctors}</td>
														<td className="td grayBG">عدد الاسرة</td>
														<td className="td table_small">{evaluation.num_of_beds}</td>

													</tr>

													<tr>
														<td className="td grayBG">عدد العيادات</td>
														<td className="td table_small">{evaluation.num_of_clinics}</td>
														<td className="td grayBG">عدد مواقف المرضى</td>
														<td className="td table_small">{evaluation.num_of_parking_spots}</td>
														<td className="td grayBG">عدد الكادر الفني للمختبر</td>
														<td className="td table_small">{evaluation.num_of_technical_staff_lab}</td>
													</tr>

													<tr>

														<td className="td grayBG">مساحة الموقف</td>
														<td className="td table_small">{evaluation.parking_lot_area}</td>
														<td className="td grayBG">عدد النقاط</td>
														<td className="td table_small">{evaluation.evaluated_points}</td>
													</tr>
												</tbody>
											</Table>
										</Col>
									</Row>
									<Row>
										<Col lg="12" className="mt-4 p-0">
											<Row>
												<Col lg="12" className="text-end new_card" style={{ height: "83px" }}>
													<Row>
														<Col
															lg="12"
															className="d-flex flex-column align-items-center"
														>
															<Button
																onClick={() => handlePDFDownload(evaluation.services_prices_image)}
																className="btnNext m-0 my-2"
																style={{ width: "93%" }}
															>
																{" "}
																تحميل ملف الأسعار{" "}
															</Button>

														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col lg="12">
											<div>
												<p>العيادات:</p>
												<p>{evaluation.clinic_type}</p>
											</div>
										</Col>
									</Row>
									<div className="new_boxs mt-3" style={{ marginTop: "60px" }}>
										<Row>
											<Col lg="6" className="p-0">
												<p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
												<div className="new_box"></div>
											</Col>
											<Col lg="6" className="p-0">
												<p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
												<div className="new_box"></div>
											</Col>
										</Row>
										<Row className="new_sign">
											<Col lg="1">التوقيع:</Col>
											<Col lg="5">
												<div className="border-bottom mt-4" />
											</Col>
											<Col lg="1">التوقيع:</Col>
											<Col lg="5">
												<div className="border-bottom mt-4" />
											</Col>
										</Row>
									</div>
								</div>
								<div className="inv_footer">
									<span className="footer_text">الإيميل : info@bayan.med.sa</span>
									<div className="social_neworder_div">
										<a href="http://www.bayan.med.sa/">
											<img className="social_neworder" alt="social" src={social4} />
										</a>
										<a href="http://www.bayan.med.sa/">
											<img className="social_neworder" alt="social" src={social3} />
										</a>
										<a href="http://www.bayan.med.sa/">
											<img className="social_neworder" alt="social" src={social2} />
										</a>
										<a href="http://www.bayan.med.sa/">
											<img className="social_neworder" alt="social" src={social1} />
										</a>
									</div>
								</div>
							</page>
						</>
					) : null}
				</div>
			}
		</div>
	);
}
export default NewOrder;
