import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import "../../styles/subscription_payment.css";
import card from '../../imgs/Path 2330.png'
import bank from '../../imgs/bank.png'

import ControlPanelHeader from '../../component/ControlPanelHeader'
import Message from '../../component/Message'
import Loader from '../../component/Loader'

import {getOfferDetailsForAdmin} from '../../actions/adminActions'


function OfferPaymentScreen({ match,history }) {

    const offerId = match.params.id

    const typeId = match.params.type

    const dispatch = useDispatch()

    const offerDetailsForAdmin
    = useSelector(state => state.offerDetailsForAdmin)

    const {loading, error, success, offer } = offerDetailsForAdmin
   
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
        if (!userInfo) {
            history.push('/')
        } else {
            dispatch(getOfferDetailsForAdmin(offerId, typeId))

        }
    }, [dispatch, history, userInfo, success])



    return (
        <div>
            { loading
                ? (<Loader/>) 
                : error 
                ? (<Message variant='danger'>{error}</Message>)
                : (
                    <div>
                        <ControlPanelHeader exit1={true} notifi={true} msg={true} msgDest={"/Messages"}
                        notifidest={"/Notifications"} exitDest={"/"} style={"three_section"} />
                        <div className="subscription_payment_content">
                            <p className="heading"> اختيار عمليه الدفع </p>
                            <p className="sub-title text-center" style={{ width:"350px" }}>{offer.request_device.title}</p>
                            <div style={{display:"flex"}}>
                                <div className="price-content">
                                    <p className="price text-center">المبلغ</p>
                                    <p className="price_no p-1">{(offer.offer_price + offer.offer_price *0.15).toFixed(2)}</p>
                                    <p className="price_type text-center">ريال سعودي</p>
                                </div>
                                <div className="price_info">
                                    <div className="price_onfo_title">
                                    تفاصيل الطلب
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">العرض</div>
                                        <div className="info_value">{offer.request_device.title}</div>
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">نوع الطلب</div>
                                        <div className="info_value">{offer.request_device.request_type}</div>
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">السعر</div>
                                        <div className="info_value"><span>{offer.offer_price} </span> <span className="coin"> ريال</span>   </div>
                                    </div>
                                

                                    <div className="price_info_item">
                                        <div className="info_title text-end">VAT ضريبة القيمة المضافة</div>
                                        <div className="info_value"><span>{(offer.offer_price * 0.15).toFixed(2)} </span> <span className="coin"> ريال</span></div>
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">المجموع</div>
                                        <div className="info_value"><span>{(offer.offer_price + offer.offer_price *0.15).toFixed(2)} </span> <span className="coin"> ريال</span></div>
                                    </div>
                                </div>
                            </div>
                            <p className="choose text-center">اختر طريقة الدفع المناسبه</p>
                            <div className="group-btn">
                                <Link to={`/offerPaymentBank/${offerId}/${typeId}`}>
                                    <button className="nextBtn">
                                        <img src={bank} alt="" />
                                        حساب بنكي
                                    </button>
                                </Link>

                        {/*<Link to="/SubscriptionPaymentCard">
                        <button className="prevBtn">
                            <img src={card} alt="" />
                            دفع الكتروني
                        </button>
                </Link>*/}
                    </div>
                    </div>
                    </div>
                )}
      </div>
    )
}

export default OfferPaymentScreen
