import React, { Component, useState } from "react";
import "../../styles/stat/laboratory_stat.css";
import CountUp from "react-countup";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "../control_panel/animation/AnimatedProgressProvider";
import { easeQuadInOut } from "d3-ease";
// export class Laboratory_ontracts extends Component {
//   render() {
//     return (
//       <div className="laboratory_stat">
//         <div className="header">تنظيم وتشغيل عقود المختبرات</div>
//         <div className="chart">
//           <div className="refused_contract">
//             <div className="info_item" style={{ marginBottom: "50px" }}>
//               <p className="title">عدد العقود المرفوضة شهري / سنوي</p>
//               <span className="list">
//                 <svg
//                   height="40"
//                   viewBox="-46 0 511 512"
//                   width="40"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path d="m415.867188 76.234375c-.09375-.117187-.207032-.242187-.34375-.378906l-71.449219-71.445313c-.1875-.1875-.367188-.347656-.539063-.488281-2.761718-2.507813-6.355468-3.921875-10.105468-3.921875h-266.4375c-8.3125 0-15.054688 6.742188-15.054688 15.054688v305.875c-30.714844 17.679687-51.4375 50.839843-51.4375 88.75 0 56.417968 45.902344 102.320312 102.320312 102.320312 36.304688 0 68.25-19.007812 86.417969-47.589844h215.636719c8.316406 0 15.058594-6.742187 15.058594-15.054687v-362.851563c0-3.824218-1.464844-7.484375-4.066406-10.269531zm-47.453126-4.898437-19.925781-.0625v-19.867188l3.894531 3.894531zm-265.59375 410.550781c-39.8125 0-72.207031-32.390625-72.207031-72.207031 0-39.816407 32.394531-72.207032 72.207031-72.207032 39.816407 0 72.210938 32.390625 72.210938 72.207032 0 39.816406-32.390625 72.207031-72.210938 72.207031zm99.320313-47.589844c1.957031-7.886719 3.003906-16.132813 3.003906-24.617187 0-56.421876-45.902343-102.320313-102.324219-102.320313-7.113281 0-14.0625.730469-20.769531 2.121094v-279.367188h236.324219v56.171875c0 8.296875 6.710938 15.027344 15.007812 15.054688l56.4375.171875v332.785156zm0 0" />
//                   <path d="m145.871094 367.582031c-5.878906-5.878906-15.410156-5.878906-21.289063 0l-21.761719 21.757813-21.757812-21.757813c-5.878906-5.878906-15.410156-5.878906-21.292969 0-5.878906 5.878907-5.878906 15.414063 0 21.292969l21.761719 21.757812-21.761719 21.757813c-5.878906 5.878906-5.878906 15.414063 0 21.292969 2.941407 2.941406 6.796875 4.410156 10.648438 4.410156 3.851562 0 7.707031-1.46875 10.644531-4.410156l21.757812-21.757813 21.757813 21.757813c2.941406 2.941406 6.792969 4.410156 10.648437 4.410156 3.851563 0 7.707032-1.46875 10.644532-4.410156 5.878906-5.878906 5.878906-15.414063 0-21.292969l-21.757813-21.757813 21.757813-21.757812c5.882812-5.878906 5.882812-15.414062 0-21.292969zm0 0" />
//                   <path d="m137.339844 167.667969h112.078125c8.316406 0 15.058593-6.738281 15.058593-15.054688 0-8.316406-6.742187-15.054687-15.058593-15.054687h-112.078125c-8.316406 0-15.058594 6.738281-15.058594 15.054687 0 8.316407 6.742188 15.054688 15.058594 15.054688zm0 0" />
//                   <path d="m318.679688 197.78125h-181.339844c-8.316406 0-15.058594 6.742188-15.058594 15.054688 0 8.316406 6.742188 15.058593 15.058594 15.058593h181.339844c8.3125 0 15.054687-6.742187 15.054687-15.058593 0-8.3125-6.742187-15.054688-15.054687-15.054688zm0 0" />
//                   <path d="m318.679688 258.007812h-181.339844c-8.316406 0-15.058594 6.738282-15.058594 15.054688s6.742188 15.054688 15.058594 15.054688h181.339844c8.3125 0 15.054687-6.738282 15.054687-15.054688s-6.742187-15.054688-15.054687-15.054688zm0 0" />
//                 </svg>
//               </span>
//             </div>
//             <div className="contract_stat">
//               <div className="yearly">
//                 <div className="title"> سنوي</div>
//                 <div className="total_number">
//                   <CountUp start={0} end={12700} duration={2} separator="," />
//                 </div>
//                 <div className="progress">
//                   <AnimatedProgressProvider
//                     valueStart={0}
//                     valueEnd={40}
//                     duration={1.4}
//                     easingFunction={easeQuadInOut}
//                   >
//                     {(value) => {
//                       const roundedValue = Math.round(12700);
//                       return (
//                         <CircularProgressbar
//                           value={value}
//                           text={`${roundedValue}`}
//                           styles={buildStyles({
//                             pathColor: `#e64f39`,
//                             textColor: "#e64f39",
//                             trailColor: "#d6d6d6",
//                             textSize: "16px",
//                             strokeLinecap: "round",
//                             pathTransition: "none",
//                           })}
//                         />
//                       );
//                     }}
//                   </AnimatedProgressProvider>
//                 </div>
//               </div>
//               <div className="monthly">
//                 <div className="title"> شهري</div>
//                 <div className="total_number">
//                   <CountUp start={0} end={5700} duration={2} separator="," />
//                 </div>
//                 <div className="progress">
//                   <AnimatedProgressProvider
//                     valueStart={0}
//                     valueEnd={60}
//                     duration={1.4}
//                     easingFunction={easeQuadInOut}
//                   >
//                     {(value) => {
//                       const roundedValue = Math.round(5700);
//                       return (
//                         <CircularProgressbar
//                           value={value}
//                           text={`${roundedValue}`}
//                           styles={buildStyles({
//                             pathColor: `#e64f39`,
//                             textColor: "#e64f39",
//                             trailColor: "#d6d6d6",
//                             textSize: "16px",
//                             strokeLinecap: "round",
//                             pathTransition: "none",
//                           })}
//                         />
//                       );
//                     }}
//                   </AnimatedProgressProvider>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="accept_contract">
//             <div className="info_item" style={{ marginBottom: "50px" }}>
//               <p className="title">عدد العقود المتفق عليها شهري / سنوي</p>
//               <span className="list">
//                 <svg
//                   height="40"
//                   viewBox="0 0 512 512"
//                   width="40"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path d="m410.222656 64.058594v398.175781l43.03125 49.765625h15.714844l43.03125-49.769531v-398.171875zm71.777344 30v40.09375h-41.777344v-40.09375zm-41.777344 70.089844h41.777344v214.894531h-41.777344zm20.886719 311.070312-20.886719-24.15625v-42.019531h41.777344v42.019531zm0 0" />
//                   <path d="m0 87.984375v424.015625h383v-512h-295.019531zm92.714844-50.289063v55.023438h-55.023438zm260.285156 444.304688h-323v-359.28125h92.714844v-92.71875h230.285156zm0 0" />
//                   <path d="m92.285156 184.054688h198.429688v30h-198.429688zm0 0" />
//                   <path d="m92.285156 248.667969h198.429688v30h-198.429688zm0 0" />
//                   <path d="m92.285156 313.28125h198.429688v30h-198.429688zm0 0" />
//                   <path d="m92.285156 377.894531h198.429688v30h-198.429688zm0 0" />
//                 </svg>
//               </span>
//             </div>
//             <div className="contract_stat">
//               <div className="yearly">
//                 <div className="title"> سنوي</div>
//                 <div className="total_number">
//                   <CountUp start={0} end={12700} duration={2} separator="," />
//                 </div>
//                 <div className="progress">
//                   <AnimatedProgressProvider
//                     valueStart={0}
//                     valueEnd={40}
//                     duration={1.4}
//                     easingFunction={easeQuadInOut}
//                   >
//                     {(value) => {
//                       const roundedValue = Math.round(12700);
//                       return (
//                         <CircularProgressbar
//                           value={value}
//                           text={`${roundedValue}`}
//                           styles={buildStyles({
//                             pathColor: `#0fb0a9`,
//                             textColor: "#0fb0a9",
//                             trailColor: "#d6d6d6",
//                             textSize: "16px",
//                             strokeLinecap: "round",
//                             pathTransition: "none",
//                           })}
//                         />
//                       );
//                     }}
//                   </AnimatedProgressProvider>
//                 </div>
//               </div>
//               <div className="monthly">
//                 <div className="title"> شهري</div>
//                 <div className="total_number">
//                   <CountUp start={0} end={5700} duration={2} separator="," />
//                 </div>
//                 <div className="progress">
//                   <AnimatedProgressProvider
//                     valueStart={0}
//                     valueEnd={60}
//                     duration={1.4}
//                     easingFunction={easeQuadInOut}
//                   >
//                     {(value) => {
//                       const roundedValue = Math.round(5700);
//                       return (
//                         <CircularProgressbar
//                           value={value}
//                           text={`${roundedValue}`}
//                           styles={buildStyles({
//                             pathColor: `#0fb0a9`,
//                             textColor: "#0fb0a9",
//                             trailColor: "#d6d6d6",
//                             textSize: "16px",
//                             strokeLinecap: "round",
//                             pathTransition: "none",
//                           })}
//                         />
//                       );
//                     }}
//                   </AnimatedProgressProvider>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//       </div>
//     );
//   }
// }

// export default Laboratory_ontracts;

function Laboratory_ontracts() {
  const [data, setData] = useState({
    contract_rejected_yearly: 12700,
    contract_rejected_monthly: 5700,
    contract_accepted_yearly: 12700,
    contract_accepted_monthly: 5700,
  });

  return (
    <div className="laboratory_stat">
      <div className="header">تنظيم وتشغيل عقود المختبرات</div>
      <div className="chart">
        <div className="refused_contract">
          <div className="info_item" style={{ marginBottom: "50px" }}>
            <p className="title">عدد العقود المرفوضة شهري / سنوي</p>
            <span className="list">
              <svg
                height="40"
                viewBox="-46 0 511 512"
                width="40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m415.867188 76.234375c-.09375-.117187-.207032-.242187-.34375-.378906l-71.449219-71.445313c-.1875-.1875-.367188-.347656-.539063-.488281-2.761718-2.507813-6.355468-3.921875-10.105468-3.921875h-266.4375c-8.3125 0-15.054688 6.742188-15.054688 15.054688v305.875c-30.714844 17.679687-51.4375 50.839843-51.4375 88.75 0 56.417968 45.902344 102.320312 102.320312 102.320312 36.304688 0 68.25-19.007812 86.417969-47.589844h215.636719c8.316406 0 15.058594-6.742187 15.058594-15.054687v-362.851563c0-3.824218-1.464844-7.484375-4.066406-10.269531zm-47.453126-4.898437-19.925781-.0625v-19.867188l3.894531 3.894531zm-265.59375 410.550781c-39.8125 0-72.207031-32.390625-72.207031-72.207031 0-39.816407 32.394531-72.207032 72.207031-72.207032 39.816407 0 72.210938 32.390625 72.210938 72.207032 0 39.816406-32.390625 72.207031-72.210938 72.207031zm99.320313-47.589844c1.957031-7.886719 3.003906-16.132813 3.003906-24.617187 0-56.421876-45.902343-102.320313-102.324219-102.320313-7.113281 0-14.0625.730469-20.769531 2.121094v-279.367188h236.324219v56.171875c0 8.296875 6.710938 15.027344 15.007812 15.054688l56.4375.171875v332.785156zm0 0" />
                <path d="m145.871094 367.582031c-5.878906-5.878906-15.410156-5.878906-21.289063 0l-21.761719 21.757813-21.757812-21.757813c-5.878906-5.878906-15.410156-5.878906-21.292969 0-5.878906 5.878907-5.878906 15.414063 0 21.292969l21.761719 21.757812-21.761719 21.757813c-5.878906 5.878906-5.878906 15.414063 0 21.292969 2.941407 2.941406 6.796875 4.410156 10.648438 4.410156 3.851562 0 7.707031-1.46875 10.644531-4.410156l21.757812-21.757813 21.757813 21.757813c2.941406 2.941406 6.792969 4.410156 10.648437 4.410156 3.851563 0 7.707032-1.46875 10.644532-4.410156 5.878906-5.878906 5.878906-15.414063 0-21.292969l-21.757813-21.757813 21.757813-21.757812c5.882812-5.878906 5.882812-15.414062 0-21.292969zm0 0" />
                <path d="m137.339844 167.667969h112.078125c8.316406 0 15.058593-6.738281 15.058593-15.054688 0-8.316406-6.742187-15.054687-15.058593-15.054687h-112.078125c-8.316406 0-15.058594 6.738281-15.058594 15.054687 0 8.316407 6.742188 15.054688 15.058594 15.054688zm0 0" />
                <path d="m318.679688 197.78125h-181.339844c-8.316406 0-15.058594 6.742188-15.058594 15.054688 0 8.316406 6.742188 15.058593 15.058594 15.058593h181.339844c8.3125 0 15.054687-6.742187 15.054687-15.058593 0-8.3125-6.742187-15.054688-15.054687-15.054688zm0 0" />
                <path d="m318.679688 258.007812h-181.339844c-8.316406 0-15.058594 6.738282-15.058594 15.054688s6.742188 15.054688 15.058594 15.054688h181.339844c8.3125 0 15.054687-6.738282 15.054687-15.054688s-6.742187-15.054688-15.054687-15.054688zm0 0" />
              </svg>
            </span>
          </div>
          <div className="contract_stat">
            <div className="yearly">
              <div className="title"> سنوي</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data.contract_rejected_yearly}
                  duration={2}
                  separator=","
                />
              </div>
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={40}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(
                      data.contract_rejected_yearly
                    );
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#e64f39`,
                          textColor: "#e64f39",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>
            <div className="monthly">
              <div className="title"> شهري</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data.contract_rejected_monthly}
                  duration={2}
                  separator=","
                />
              </div>
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={60}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(
                      data.contract_rejected_monthly
                    );
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#e64f39`,
                          textColor: "#e64f39",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>
          </div>
        </div>
        <div className="accept_contract">
          <div className="info_item" style={{ marginBottom: "50px" }}>
            <p className="title">عدد العقود المتفق عليها شهري / سنوي</p>
            <span className="list">
              <svg
                height="40"
                viewBox="0 0 512 512"
                width="40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m410.222656 64.058594v398.175781l43.03125 49.765625h15.714844l43.03125-49.769531v-398.171875zm71.777344 30v40.09375h-41.777344v-40.09375zm-41.777344 70.089844h41.777344v214.894531h-41.777344zm20.886719 311.070312-20.886719-24.15625v-42.019531h41.777344v42.019531zm0 0" />
                <path d="m0 87.984375v424.015625h383v-512h-295.019531zm92.714844-50.289063v55.023438h-55.023438zm260.285156 444.304688h-323v-359.28125h92.714844v-92.71875h230.285156zm0 0" />
                <path d="m92.285156 184.054688h198.429688v30h-198.429688zm0 0" />
                <path d="m92.285156 248.667969h198.429688v30h-198.429688zm0 0" />
                <path d="m92.285156 313.28125h198.429688v30h-198.429688zm0 0" />
                <path d="m92.285156 377.894531h198.429688v30h-198.429688zm0 0" />
              </svg>
            </span>
          </div>
          <div className="contract_stat">
            <div className="yearly">
              <div className="title"> سنوي</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data.contract_accepted_yearly}
                  duration={2}
                  separator=","
                />
              </div>
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={40}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(
                      data.contract_accepted_yearly
                    );
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#0fb0a9`,
                          textColor: "#0fb0a9",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>
            <div className="monthly">
              <div className="title"> شهري</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data.contract_accepted_monthly}
                  duration={2}
                  separator=","
                />
              </div>
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={60}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(
                      data.contract_accepted_monthly
                    );
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#0fb0a9`,
                          textColor: "#0fb0a9",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Laboratory_ontracts;
