import React, { Component } from "react";
import arrow from "../../imgs/arrow.png";
import "../../styles/change_insidePrices.css";
import "../../styles/change_bamkData.css";
import axiosInstance from "../../axios";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";
class Change_BankData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bank_Name: "",
      company_Name: "",
      IBN: "",
      showModal: false,
    };
  }

  submitHandler = () => {
    this.setState({ showModal: true });
  };

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  sendData = () => {
    const { bank_Name, company_Name, IBN } = this.state;
    let data = {
      bank_name: bank_Name,
      company_name: company_Name,
      iban_number: IBN,
    };
    axiosInstance.post("/management​/banking_data​/", data).then((res) => {
      console.log(res);
      this.props.history.push("/ConfirmUpdatePower");
    });
  };

  render() {
    const { bank_Name, company_Name, IBN } = this.state;
    return (
      <div className="change_bankdata">
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">اسم البنك</label>
            <input
              type="text"
              id="bank_Name"
              name="bank_Name"
              value={bank_Name}
              onChange={this.changeHandler}
              placeholder=" ادخل اسم البنك"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="input-data">
            <label htmlFor="name">اسم الشركة</label>
            <input
              type="text"
              id="company_Name"
              name="company_Name"
              value={company_Name}
              onChange={this.changeHandler}
              placeholder="ادخل اسم الشركة"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">رقم حساب الايبان (IBN)</label>
            <input
              type="text"
              id="IBN"
              name="IBN"
              value={IBN}
              onChange={this.changeHandler}
              placeholder=" ادخل رقم حساب الايبان (IBN)"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
        </div>
        <div className="text-center d-flex justify-content-center">
          <Button className="text-center btnNext" onClick={this.submitHandler}>
            <img src={arrow} alt="" />
            تنفيذ
          </Button>
        </div>
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Change_BankData;
