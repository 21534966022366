import {
  Row,
  Col,
  ToggleButton,
  Card,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./bestCard.css";
function BestCard({
  title,
  price,
  weight,
  descreption,
  bigImage,
  radioValue,
  link,
  type,
  type1,
}) {
  function desc() {
    if (descreption.length > 345) {
      return descreption.slice(0, 227);
    } else {
      return descreption
    }
  }
  console.log(parseInt(price));
  return (
    <Card className="best_size">
      <Row>
        <Col lg="3" className="mt-4">
          {price ? (
            <Row>
              <p class="price text-center">
                السعر: {price}
                {!isNaN(parseInt(price)) ? (
                  <span class="text-muted"> ريال </span>
                ) : (
                  ""
                )}
              </p>
            </Row>
          ) : (
            ""
          )}
          {weight ? (
            <Row>
              <p class="price text-center">
                الوزن: {weight}
                <span class="text-muted kg"> ك.جرام </span>
              </p>
            </Row>
          ) : (
            ""
          )}
          {radioValue ? (
            <Row className="m-auto justify-content-center">
              <Button
                className="bestButton"
                key="1"
                id="sell"
                type="radio"
                variant={
                  radioValue === "sell" ? "outline-success" : "outline-danger"
                }
              >
                {radioValue === "sell" ? "بيع" : "تأجير"}
              </Button>
            </Row>
          ) : (
            ""
          )}
          <Row className="m-auto justify-content-center">
            <Link to={`/${link}`} className="text-center">
              <Button
                className="bestButton"
                key="2"
                id="rent"
                variant={"secondary"}
              >
                تفاصيل أكثر
              </Button>
            </Link>
          </Row>
        </Col>
        {type !== "تأجير خدمة الأطباء" && type1 !== "laboratory" && type1 !== "ray"  ?
        (<Col lg="5" className=" text-end best_card">
          <Card.Body>
              <Card.Title>
                {title} {type ? `/ نوع الخدمة: ${type}` : ""}
              </Card.Title>
              <Card.Text className="best_descreption text-end">{desc()}</Card.Text>
            </Card.Body>
          </Col>
        ) : (
        <Col lg="" className=" text-end best_card">
        <Card.Body>
            <Card.Title>
              {title} {type ? `/ نوع الخدمة: ${type}` : ""}
            </Card.Title>
            <Card.Text className="best_descreption text-end">{desc()}</Card.Text>
          </Card.Body>
        </Col>
        )}
          
        {type !== "تأجير خدمة الأطباء" && type1 !== "laboratory" && type1 !== "ray"  ?
        (<Col lg="4">
          <div class="gallery-wrap_best">
            <img class="img-big-wrap_best" alt="img" src={bigImage} />
          </div>
        </Col>)
        : ('')
        }
      </Row>
    </Card>
  );
}
export default BestCard;
