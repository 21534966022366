import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import rarrow from "../../imgs/right-arrow.png";
import date from "../../imgs/date.png";
import check from "../../imgs/check.png";
import DatePicker from "react-datepicker";
import upload from "../../imgs/upload (1).png";
import { Card, Col, Row, Table, Button } from "react-bootstrap";
import "../../styles/tender_list.css";
import "../../styles/order.css";
import { Link } from "react-router-dom";
import ControlPanelHeader from "../../component/ControlPanelHeader";


import "../../component/new/pages/newOrder/newOrder.css";
import InvoiceTable from "../../component/new/components/InvoiceTable";
import { AiOutlineCloseCircle } from "react-icons/ai";
import NewCard from "../../component/new/components/newCard";
import complete from "../../imgs/check1.png";
import error from "../../imgs/381599_error_icon.png";
import Jmodal from "../../component/new/components/Jmodal";
import {getRayDetails, addRaysOffer } from '../../actions/serviceActions'
import Loader from '../../component/Loader'


function OrderDetailsRayScreen({ match, history }) {

    const [photo, setPhoto] = useState(false)

    const [path, setPath] = useState('ارفاق')

    const [image, setImage] = useState('https://via.placeholder.com/70x70/')
    const [wrong, setWrong] = useState(false)
    const [search, setSearch] = useState('')
    const [need, setNeed] = useState(false)

    const [img, setImg] = useState([
        "https://via.placeholder.com/70x70/",
        "https://via.placeholder.com/71x71/",
        "https://via.placeholder.com/72x72/",
        "https://via.placeholder.com/73x73/",
      ])

    const [descreption, setDecreption] = useState('hello') 

    const [radioValue, setRadioValue] = useState('sell') 
    
    const [radioValueN, setRadioValueN] = useState('sell')

    const [bigImage, setBigImage] = useState('https://via.placeholder.com/70x70/') 

    const [title1, setTitle1] = useState(["بيانات مقدم العرض"]) 
    
    const [transfer, setTransfer] = useState(true) 
      
    const [title2, setTitle2] = useState(["أعلى خمسة عروض"]) 

    const [data2, setData2] = useState({})

    const [clientCode, setClientCode] = useState({})  

    const [offerValue, setOfferValue] = useState("") 

    const [dueAmount, setdueAmount] = useState("") 

    const [offerType, setOfferType] = useState("")

    const [deferredPayment, setDeferredPayment] = useState(0) 

    const [offerDetails, setOfferDetails] = useState("") 

    const [offerAttachment, setOfferAttachment] = useState("") 
    
    const [checkBox, setCheckBox] = useState(false)


    const [errors, setErrors] = useState({
        allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",
        check_msg: " يرجى الموافقة على الأقرار من فضلك ",

      })

    const [errorsFlags, setErrorsFlags]= useState([false, false])

    const [isValid, setIsValid] = useState(true)

    const dispatch = useDispatch()

    const rayDetails = useSelector(state => state.rayDetails)
    const {error, loading, ray} = rayDetails

    const raysAddOffer = useSelector(state => state.raysAddOffer)
    const {error: offerError, loading: offerLoading, offerData} = raysAddOffer

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        } else {
          dispatch(getRayDetails(match.params.id)) 
          //setImg([device.image, device.image1, device.image2, device.image3])      
        }     
      }
    
    }, [dispatch, history, userInfo])

    const checkBoxHandler = () => {
        setCheckBox(checkBox ? false : true);
    };

    
    const getExtension = (filename) => {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    };

    const onUpload = (e) => {
        if (e.target.value) {
            const ext = getExtension(e.target.value);
            if (ext === "png" || ext === "jpg" || ext === "pdf") {
                setPhoto(e.target.value)
                setPath(e.target.files[0])
                setWrong(false)
            } else {
                setPhoto(false)
                setWrong(true)
            }
        }
    };

    const focusHandler = (e) => {
        e.target.parentNode.style.color = "#0fb0a9";
        e.target.style.color = "#0fb0a9";
        e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
        e.target.style.borderColor = "#0fb0a9";
    };

    const focusoutHandler = (e) => {
        e.target.parentNode.style.color = "";
        e.target.style.color = "";
        e.target.style.boxShadow = "";
        e.target.style.borderColor = "";
    };

    const checkStyle = checkBox ? { display: "block" } : {};


    const formValidation = () => {
      let isValid = true;
      const flags = [false, false];

      const nodes = document.querySelectorAll("input");
      nodes.forEach((e) => {
          if(e.value === '') {
          flags[0] = true;
          isValid = false;
      }
        
      });

      if (isValid === true) {
        if (!checkBox) {
          flags[1] = true;
          isValid = true;
        } else {
          flags[1] = true;
        } 

      }       
    
      setErrorsFlags(flags);
      return isValid;
    };

     const sendData = (e) => {
        e.preventDefault()
        const formvalid = formValidation();
        console.log(formvalid)
        if (formvalid) {
        
            let data = new FormData();
            data.append("request_radiology", ray.id);            
            data.append("offer_price", offerValue);
            data.append("offer_receive_duration", deferredPayment);
            data.append("offer_details", offerDetails);
            data.append("attachments", path);
      
            dispatch(addRaysOffer(data))

            history.push('/confirmAddOffersForServices')

        }
    }

    return (
      <div>
      { loading ? <Loader/>
      :
      <div className="tender_page">
        
        <ControlPanelHeader
          exit1={true}
          files={false}
          notifi={true}
          msg={true}
          msgDest={"/Messages"}
          notifidest={"/Notifications"}
          exitDest={"/services"}
          fileDest={"/FacilityFiles"}
          style={"three_section"}
        />
        <div className="tender_list mt-5" style={{ height: "820px" }}>
          <div className="heading text-center">تفاصيل العروض</div>
          <div className="scroll noscroll" style={{ marginTop: "55px", maxHeight: "750px" }}>
            <Row>
              <Col lg="7">
                <Row className="height_max">
                  <NewCard
                    img={[]}
                    title={ray.reports_offers_details}
                    descreption={ray.notes}
                    price='حسب البيانات المذكورة في المرفق'
                    type = "ray"
                    radioValue={radioValue}
                    radioValueN={radioValueN}
                    handleButtons={(e) => setRadioValue(e)}
                    handleButtonsN={(e) => setRadioValueN(e)}
                    handleImages={(e) => setBigImage(e)}
                  />
                </Row>
              </Col>

              <Col lg="5">
                <Table borderless className="inv_table_from direction">
                  <thead className="thead">
                    <tr>
                    <th className="text-center"
                      colSpan={"4"}
                    >
                      {title1}
                    </th>
                                
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                  <td className="td grayBG">رمز المؤسسة</td>
                    <td className="td table_small">{ray.facility.client_code}</td>
                    <td className="td grayBG">نوع المنشأة</td>
                    <td className="td table_small">{ray.sector_type.type_name}</td>
                    </tr>
                    <tr>
                  <td className="td grayBG">المنطقة</td>
                    <td className="td table_small">{ray.facility.state}</td>
                    <td className="td grayBG">المدينة</td>
                    <td className="td table_small">{ray.facility.location}</td>
                    </tr>
                    </tbody>
                </Table>
              </Col>
            </Row>
            
             <Row>
              <Col className="p-0">
                <div style={{ height: "389px" }} className="tender_page_content">
                  <form>
                    <p className="title-info">إضافة عرض</p>
                    <Row className="mt-5">
                      <Col lg="6">
                        <div className="input-group">
                          <div className="input-lable">
                            <label htmlFor="name">قيمة العرض</label>
                            <input
                              type="number"
                              id="offerValue"
                              name="offerValue"
                              onChange={(e)=> setOfferValue(e.target.value)}
                              placeholder=" الرجاء كتابة قيمة العرض"
                              onFocus={focusHandler}
                              onBlur={focusoutHandler}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "40px",
                                left: "-70px",
                                color: "#ababab",
                              }}
                            >ريال
                            </div>
                          </div>
                        </div>
                        <Row className="mt-4">
                          <Col lg="5">
                            <div className="radio_input">
                              <p>هل تريد دفع المبلغ بالآجل</p>
                              <div className="radio_group">
                                <label>
                                  <input
                                    type="radio"
                                    name="need"
                                    value="نعم"
                                    checked={need}
                                    onChange={(e)=> setNeed(e.target.value === 'نعم')}
                                  />
                                  نعم
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    value="لا"
                                    name="need"
                                    onChange={(e)=> setNeed(e.target.value === 'نعم')}
                                  />
                                  لا
                                </label>
                              </div>
                            </div>
                          </Col>

                          <Col lg="6">
                              <div className="input-group">
                                {need === true && (
                                  <div className="input-lable">
                                    <label htmlFor="offerPeriod">
                                      البيع بالآجل
                                    </label>
                                    <select style={{width:"163px"}} value={deferredPayment}
                                      type="text"
                                      id="type"
                                      name="deferredPayment"
                                      onChange={(e)=> setDeferredPayment(e.target.value)}
                                      placeholder="مدة العرض"
                                      onFocus={focusHandler}
                                      onBlur={focusoutHandler}
                                    >
                                      <option value="30">30 يوم</option>
                                      <option value="60">60 يوم</option>
                                      <option value="90">90 يوم</option>
                                      <option value="120">120 يوم</option>
                                    </select>
                                  </div>
                                )}
                              </div>
                            </Col>
                        </Row>
                        {errorsFlags[0] && (
                      <div className="error">
                        <img src={error} />
                        {errors.allFieldRequired}
                      </div>
                    )}                        
                      </Col>
                      <Col lg="6">
                        <div className="input-group">
                          <div className="right-data">
                            <div className="input-lable" style={{ height: "180px" }}>
                              <label htmlFor="name"> تفاصيل العرض </label>
                              <textarea
                                rows="4"
                                cols="50"
                                type="text"
                                id="offerDetails"
                                name="offerDetails"
                                onChange={(e)=> setOfferDetails(e.target.value)}
                                placeholder="اكتب تفاصيل العرض"
                                onFocus={focusHandler}
                                onBlur={focusoutHandler}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="input-group" style={{ marginTop: "75px" }}>
                          <div className="input-content">
                            <p>إضافة عرض</p>
                            <label htmlFor="upload-photo">
                              <div className="input">
                                <img src={upload} alt="" />
                                <div>{photo}</div>
                              </div>
                            </label>
                            <input
                              onChange={onUpload}
                              type="file"
                              name="photo"
                              id="upload-photo"
                            />
                            {photo && (
                              <img className="complete" src={complete} alt="" />
                            )}
                            {wrong && <AiOutlineCloseCircle />}
                          </div>
                        </div>
                      </Col>
                    </Row>                                               
                    <button className="enter_service m-auto" onClick={sendData}>
                      <img className="icon" src={arrow} alt="" />
                      اضف العرض
                    </button>
                  </form>
                </div>
              </Col>
            </Row>

          </div>
        </div>
        <Jmodal link="ListSellMedicalDevices"/>
        
      </div>
      }
      </div>
       
    )
}

export default OrderDetailsRayScreen
