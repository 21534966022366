import React, { Component } from "react";
import "../../styles/suspend_account.css";
import arrow from "../../imgs/arrow.png";
import search from "../../imgs/search1.png";
import { Link } from "react-router-dom";
class Suspend_Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      code: "",
      showInfo: false,
      msg: "",
      suspendType: "",
    };
  }

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  onClick = () => {
    this.setState({
      showInfo: true,
    });
  };

  onChooseSuspendType = (suspend) => {
    this.setState({
      suspendType: suspend,
    });
  };

  render() {
    const { name, code, showInfo, msg } = this.state;

    return (
      <div className="suspend_account">
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">اسم الجهة</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={this.changeHandler}
              placeholder=" ابحث اسم الجهة"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
            <img src={search} alt="" />
          </div>
          <div className="input-data">
            <label htmlFor="name">كود العميل</label>
            <input
              type="text"
              id="code"
              name="code"
              value={code}
              onChange={this.changeHandler}
              placeholder="اكتب كود العميل"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
            <img src={search} alt="" />
          </div>
        </div>
        <div className="text-center d-flex justify-content-center">
          {showInfo && (
            <div>
              <div className="info">
                <p className="info_title">اسم الجهة :</p>
                <div className="info_data">{code}</div>
                <p className="info_title"> كود العميل :</p>
                <div className="info_data">{name}</div>
              </div>
              <div className="suspend">
                <div className="suspend_types">
                  <div
                    onClick={() => {
                      this.onChooseSuspendType("إيقاف دائم");
                    }}
                    className="suspend_type"
                  >
                    إيقاف دائم
                  </div>
                  <div
                    onClick={() => {
                      this.onChooseSuspendType("إيقاف مؤقت");
                    }}
                    className="suspend_type"
                    style={{ backgroundColor: "var(--red-color)" }}
                  >
                    إيقاف مؤقت
                  </div>
                  <div
                    onClick={() => {
                      this.onChooseSuspendType("تعديل الرسالة");
                    }}
                    className="suspend_type"
                    style={{ backgroundColor: "#ababab" }}
                  >
                    تعديل الرسالة
                  </div>
                </div>
                <div
                  className="input-data"
                  style={{
                    marginLeft: "205px",
                    width: "710px",
                    height: "165px",
                  }}
                >
                  <label htmlFor="msg">رسالة الادارة</label>
                  <textarea
                    style={{ paddingTop: "10px" }}
                    type="text"
                    id="msg"
                    name="msg"
                    value={msg}
                    onChange={this.changeHandler}
                    placeholder="رسالة تنبيه للمشتركين بإيقاف الاشتراك الدائم أو المؤقت "
                    onFocus={this.focusHandler}
                    onBlur={this.focusoutHandler}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showInfo && (
          <div className="text-center d-flex justify-content-center">
            <Link to="/ConfirmOperation">
              <div className="btnNext">
                <img src={arrow} alt="" />
                تنفيذ
              </div>
            </Link>
          </div>
        )}

        {showInfo === false && (
          <div className="text-center d-flex justify-content-center">
            <div onClick={this.onClick} className="btnNext">
              <img src={arrow} alt="" />
              التالي
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Suspend_Account;
