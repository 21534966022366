import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import { Row, Col, Card, Table, Form, Button, Modal } from "react-bootstrap";
import "../styles/TableNewDesign.css";
import Paginate from "../component/Paginate";
import { Link } from "react-router-dom";
import Message from "../component/Message";
import Loader from "../component/Loader";

import { listAllFacilitiesForAdmin, updateFacilityInfoForAdmin } from "../actions/adminActions";
import axios from "axios";

function HealthFacilies({ history }) {
	const [selectValue, setSelectValue] = useState("");
	const [show, setShow] = useState(false);

	const dispatch = useDispatch();

	const facilityListForAdmin = useSelector((state) => state.facilityListForAdmin);
	const { loading, error, facilities, page, pages } = facilityListForAdmin;

	const facilityUpdateForAdmin = useSelector((state) => state.facilityUpdateForAdmin);
	const { success } = facilityUpdateForAdmin;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	let keyword = history.location.search;

	useEffect(() => {
		if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
			dispatch(listAllFacilitiesForAdmin(keyword));
		} else {
			history.push("/");
		}
	}, [dispatch, history, userInfo, keyword, success]);

	const more = {
		title: "الاجراءات",
		actions: {
			تفعيل: "approve",
			"إيقاف التفعيل": "disapprove",
			طباعة: "/admin/print-facility",
			تعديل: "/admin/update-facility-info",
		},
	};

	const handleChange = (e) => {
		setSelectValue(e.target.value);
	};

	const handleDelete = (id) => {
		setShow(false);
		axios.delete(`/api/users/facility/${id}/`, {
			headers: {
				Authorization: `Bearer ${userInfo.access}`,
			},
		});
	};

	const handleUpdate = (id) => {
		let data = new FormData();
		data.append("approved", true);
		data.append("bayan_type", '');
		dispatch(updateFacilityInfoForAdmin(data, id));
	};

	const handleDisapprove = (id) => {
		let data = new FormData();
		data.append("approved", false);
		data.append("bayan_type", '');
		dispatch(updateFacilityInfoForAdmin(data, id));
	};

	const showModel = () => {
		let teamId = selectValue.split("/")[1];
		return (
			<Modal
				style={{ direction: "rtl" }}
				show={show}
				onHide={() => setShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header
					style={{
						backgroundColor: "var(--main-color)",
						justifyContent: "center",
					}}
				>
					<Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
				</Modal.Header>
				<Modal.Body className="h4">يرجى تأكيد الحذف</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						className="btn-red"
						onClick={() => setShow(false)}
					>
						<span className="h4">إلغاء</span>
					</Button>
					<Button
						variant="success"
						className="btn-main px-5"
						onClick={() => handleDelete(teamId)}
					>
						<span className="h4">تأكيد</span>
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	return (
		<AdminLayout>
			<Row>
				<Col xl={12} md={12}>
					{loading ? (
						<Loader />
					) : error ? (
						<Message varient="danger">{error}</Message>
					) : (
						<Card className="code-table">
							<Card.Header>
								<Card.Title as="h5">قائمة المنشآت</Card.Title>
							</Card.Header>
							<Card.Body className="pb-0">
								<Table responsive hover>
									<thead>
										<tr>
											<th>كود المنشأة</th>
											<th>إسم المنشأة</th>
											<th>القطاع</th>
											<th>نوع القطاع</th>
											<th> الحالة</th>
											<th>الإجراء</th>
										</tr>
									</thead>
									<tbody>
										{facilities
											.filter((f) => f.sector.sector_name.includes("صحي"))
											.map((obj) => (
												<tr key={obj.id}>
													<td>
														<p className="mb-1">{obj.client_code}</p>
													</td>
													<td>
														<p className="mb-1">{obj.facility_name}</p>
													</td>
													<td>
														<p className="mb-1">{obj.sector.sector_name}</p>
													</td>
													<td>
														<p className="mb-1">{obj.sector_type.type_name}</p>
													</td>

													<td>
														<Button
															variant={
																obj.is_deleted === true
																	? "danger"
																	: obj.approved === true
																		? "success"
																		: "warning"
															}
															className={
																obj.is_deleted === true
																	? "label btn-danger btn-sm f-12 text-white"
																	: obj.approved === true
																		? "label btn-success btn-sm f-12 text-white"
																		: "label btn-warning btn-sm f-12 text-white"
															}
														>
															{obj.is_deleted === true
																? "تم حذفه"
																: obj.approved === true
																	? "مفعل"
																	: "غير مفعل"}
														</Button>
													</td>

													{more && !obj.is_deleted ? (
														<td>
															<Row className="m-2 w-100">
																<Form.Select
																	as="select"
																	onChange={handleChange}
																	className="text-center px-1 pe-2"
																>
																	<option>أختر</option>
																	{!obj.approved && (
																		<option value={`approve/${obj.id}`}>
																			تفعيل
																		</option>
																	)}
																	{obj.approved && (
																		<option value={`stop/${obj.id}`}>
																			إيقاف التفعيل
																		</option>
																	)}
																	<option
																		value={`/admin/update-facility-info/${obj.id}`}
																	>
																		تعديل
																	</option>
																	<option
																		value={`/admin/print-facility/${obj.id}`}
																	>
																		طباعة
																	</option>
																	{!obj.is_deleted && (
																		<option value={`delete/${obj.id}`}>
																			حذف
																		</option>
																	)}
																</Form.Select>
																{selectValue.includes(
																	"/editMedicalOrTechnicalTeam"
																) ? (
																	<Button
																		onClick={() => setShow(true)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : selectValue.includes(
																	"/admin/update-facility-info"
																) ? (
																	<Link
																		to={selectValue}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Link>
																) : selectValue.includes(
																	"/admin/print-facility"
																) ? (
																	<Link
																		to={selectValue}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Link>
																) : selectValue.includes("delete") ? (
																	<Button
																		onClick={() => setShow(true)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : selectValue.includes("approve") ? (
																	<Button
																		onClick={() => handleUpdate(obj.id)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : selectValue.includes("stop") ? (
																	<Button
																		onClick={() => handleDisapprove(obj.id)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : selectValue.includes("update") ? (
																	<Button
																		onClick={() => handleUpdate(obj.id)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : (
																	""
																)}
															</Row>
														</td>
													) : (
														""
													)}
												</tr>
											))}
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					)}
				</Col>
			</Row>
			<div>{showModel()}</div>
			<Paginate
				page={page}
				pages={pages}
				keyword={keyword}
				link="/comparingMedicalMistakeWork"
			/>
		</AdminLayout>
	);
}

export default HealthFacilies;
