import React from 'react'
import close from '../imgs/close.png'
import block from '../imgs/block.png'

import '../styles/login_name.css'
import { Link } from 'react-router-dom'

function LoginName(props) {
    const closeWindow = () => {
        window.open("about:blank", "_self");
        window.close();
    }

    const dest = {
        pathname : props.pathname,
        param1 : props.dest
    }

    return (
       
        <div className='name-content text-center'>
            {props.Home && <Link to='/'><img src={close} alt='' className='closeBtn'/></Link>}
            {props.Facility && <Link to={dest}><img src={close} alt='' className='closeBtn'/></Link>}



            {/* {props.Facility && <img onClick={closeWindow} src={close} alt='' className='closeBtn'/>} */}


            
            <img src= {block} alt=''/>
            <p className='arName'>بيان الصحة للخدمات التسويقية</p>
            <p className='enName'>Bayan Health Marketing Services Company</p>
        </div>
        
    )
}

export default LoginName
