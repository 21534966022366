import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../styles/data_form.css";
import arrow from '../imgs/arrow.png'
import date from "../imgs/date.png";
import DatePicker from "react-datepicker";
import { Row, Col } from 'react-bootstrap'
import err from '../imgs/381599_error_icon.png';
import "react-datepicker/dist/react-datepicker.css";
import rarrow from "../imgs/right-arrow.png";

import { useHistory } from 'react-router-dom'

import { facilityRegister } from '../actions/facilityActions'

function MainDataFormScreen({ sector, sectorType }) {

    const [sec, setSec ] = useState(sector)
    const [secType, setSecType] = useState(sectorType)

    const [IsDisplayed, setIsDisplayed] = useState(false)

    const [palceHolder, setPalceHolder] = useState(true)

    const [name, setName] = useState('')

    const [mailBox, setMailBox] = useState('')

    const [postalCode, setPostalCode] = useState('')

    const [selectTitle, setSelectTitle] = useState('')

    const [startDateCommercialRegister, setStartDateCommercialRegister] = useState('')

    const [endDateCommercialRegister, setEndDateCommercialRegister] = useState('')

    const [medicalLicenseNumber, setMedicalLicenseNumber] = useState('')

    const [startDateMedicalLicense, setStartDateMedicalLicense] = useState('')

    const [endDateMedicalLicense, setEndDateMedicalLicense] = useState('')

    const [licenseNumber, setLicenseNumber] = useState('')

    const [startDateLicense, setStartDateLicense] = useState('')

    const [endDateLicense, setEndDateLicense] = useState('')

    const [warrantyBoardNumber, setWarrantyBoardNumber] = useState('')

    const [startDateWarrantyBoard, setStartDateWarrantyBoard] = useState('')

    const [endDateWarrantyBoard, setEndDateWarrantyBoard] = useState('')

    const [foodAuthorityNumber, setfoodAuthorityNumber] = useState('')

    const [startDateFoodAuthority, setstartDateFoodAuthority] = useState('')

    const [endDateFoodAuthority, setEndDateFoodAuthority] = useState('')

    const [centralBankNumber, setCentralBankNumber] = useState('')

    const [startDateBank, setStartDateBank] = useState('')

    const [endDateBank, setEndDateBank] = useState('')

    const [bankName, setBankName] = useState('')

    const [ibanNumber, setIbanNumber] = useState('')

    const [financialManagerName, setFinancialManagerName] = useState('')

    const [fmEmail, setFmEmail] = useState('')

    const [fmPhoneNumber, setFmPhoneNumber] = useState('')

    const [salesMangerName, setSalesMangerName] = useState('')

    const [smEmail, setSmEmail] = useState('')

    const [smPhoneNumber, setSmPhoneNumber] = useState('')

    const [valueAddNumber, setValueAddNumber] = useState('')

    const [employeeNumber, setEmployeeNumber] = useState('')

    const cities = [
        "الرياض",
        "جدة",
        "مكة",
        "المدينة المنورة",
        "الدمام",
        "الطائف",
        "بريدة",
        "تبوك",
        "ابها",
        "خميس مشيط",
        "الأحساء",
        "القطيف",
        "الخبر",
        "حفر الباطن",
        "الجبيل",
        "الخرج",
        "حائل",
        "نجران",
        "ينبع",
        "صبيا",
        "الدوادمي",
        "بيشة",
        "أبو عريش",
        "القنفذة",
        "محايل",
        "سكاكا",
        "عرعر",
        "عنيزة",
        "القريات",
        "صامطة",
        "جازان",
        "المجمعة",
        "القويعية",
        "رأس تنورة	",
        "النماص	",
        "الوجه	",
        "خيبر	",
        "النبهانية	",
      ];

      const [errors] = useState({
        allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",
        emailMsg: "يرجى كتابة البريد الألكترونى الرسمى ",
        emailMsg1: "@gmail, @yahoo,@hotmail , etc.. غير مسموح ب ",
        iban: "هذا المدخل  يجب أن لا يتجاوز 25 رمزا"
    })

    const [errorsFlags, setErrorsFlags] = useState([ false, false, false, false, false])

    const dispatch = useDispatch()

    let history = useHistory()

    const facilityInfoRegister = useSelector(state => state.facilityInfoRegister)
    const {error, facility, success } = facilityInfoRegister

    const retrieveDate = (d) => {
      let date = new Date(d);
      return date
    }

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

  useEffect(() => {
    if(!userInfo) {
      history.push('/')
    } else {
      if (userInfo.is_registered){
        history.push('/services')
      } else {
        if(success)  { 
          setName(facility.facility_name)
          setSelectTitle(facility.state)
          setStartDateCommercialRegister(retrieveDate(facility.issue_date))
          setEndDateCommercialRegister(retrieveDate(facility.expiration_date))
          setMailBox(facility.post_box)
          setPostalCode(facility.postal_code)
          setSelectTitle(facility.location)
          setMedicalLicenseNumber(facility.medical_permit_num)
          setStartDateMedicalLicense(retrieveDate(facility.permit_issue_date))
          setEndDateMedicalLicense(retrieveDate(facility.permit_expiry_date))
          setWarrantyBoardNumber(facility.health_insurance_council_num)
          setStartDateWarrantyBoard(retrieveDate(facility.insurance_issue_date))
          setEndDateWarrantyBoard(retrieveDate(facility.insurance_expiry_date))
          setfoodAuthorityNumber(facility.food_drug_authority_licence_num)
          setstartDateFoodAuthority(retrieveDate(facility.food_drug_authority_issue_date))
          setEndDateFoodAuthority(retrieveDate(facility.food_drug_authority_expiry_date))
          setCentralBankNumber(facility.central_bank_number)
          setStartDateBank(retrieveDate(facility.central_bank_issue_date))
          setEndDateBank(retrieveDate(facility.central_bank_expiry_date))
          setIbanNumber(facility.iban_num)
          setBankName(facility.bank_name)
          setFinancialManagerName(facility.financial_manager_name)
          setFmEmail(facility.financial_manager_email)
          setFmPhoneNumber(facility.financial_manager_phone_num)
          setValueAddNumber(facility.tax_number)
          setSalesMangerName(facility.purchasing_manager_name)
          setSmEmail(facility.purchasing_manager_email)
          setSmPhoneNumber(facility.purchasing_manager_phone_num)
          setEmployeeNumber(facility.num_of_employees)
          setSec(facility.sector)
          setSecType(facility.sector_type) 
        }
      }
    }
    
  }, [dispatch, history, userInfo, facility])

    const changeSelect = (title) => {
        setIsDisplayed(false)
        setPalceHolder(false)
        setSelectTitle(title)
    };

    const focusHandler = (e) => {
        e.target.parentNode.style.color = "#0fb0a9";
        e.target.style.color = "#0fb0a9";
        e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
        e.target.style.borderColor = "#0fb0a9";
    };

    const focusoutHandler = (e) => {
        e.target.parentNode.style.color = "";
        e.target.style.color = "";
        e.target.style.boxShadow = "";
        e.target.style.borderColor = "";
    };

    const handleDate = (d) => {
        if (d) {
          const offset = d.getTimezoneOffset();
          let newDate = new Date(d.getTime() - (offset*60*1000));
          return newDate.toISOString().split('T')[0];
        }
    }

    const selectClick = () => {
        setIsDisplayed(IsDisplayed === true ? false : true);
    };

    const formValidation = () => {
      let isValid = true;
      const flags = [false, false, false, false, false];

      const nodes = document.querySelectorAll("input");
      nodes.forEach((e) => {
        if(e.value === '') {
          console.log(e.value)
          flags[0] = true;
          isValid = false;
        }
      });
      
      if (ibanNumber.trim().length > 25) {
        flags[3] = true;
        isValid = false;
      } else {
        flags[3] = false;
      }
      if ((startDateCommercialRegister && startDateCommercialRegister === '') ||
      (endDateCommercialRegister && endDateCommercialRegister === '') || (startDateMedicalLicense && startDateMedicalLicense === '') || (endDateMedicalLicense && endDateMedicalLicense === '') || ( startDateWarrantyBoard && startDateWarrantyBoard === '') || (endDateWarrantyBoard && endDateWarrantyBoard === '') || (startDateFoodAuthority && startDateFoodAuthority === '') || (endDateFoodAuthority && endDateFoodAuthority === '') || ( startDateBank && startDateBank === '') || ( endDateBank && endDateBank === '') || (selectTitle === '')) {
        flags[0] = true;
        isValid = false;
      }
      if (fmEmail.includes("@yahoo") || fmEmail.includes("@hotmail") || fmEmail==='' || fmEmail.includes("@gmail") || smEmail.includes("@yahoo") || smEmail.includes("@hotmail") || smEmail==='' || smEmail.includes("@gmail") ) {
          flags[2] = true;
          isValid = false;
        } else {
          flags[2] = false;
        }
  
      setErrorsFlags(flags);
      console.log(flags);
      return isValid;
    };

    const submitHandler = (e) => {
        e.preventDefault()
        const isValid = formValidation();
        if (isValid) {
        
          let data = JSON.stringify({
            "facility_name": name,
            "state": selectTitle,
            "issue_date": handleDate(startDateCommercialRegister),
            "expiration_date": handleDate(endDateCommercialRegister),
            "post_box": mailBox,
            "postal_code": postalCode,
            "location": selectTitle,
            "medical_permit_num": medicalLicenseNumber,
            "permit_issue_date": handleDate(startDateMedicalLicense),
            "permit_expiry_date": handleDate(endDateMedicalLicense),
            "health_insurance_council_num": warrantyBoardNumber,
            "insurance_issue_date": handleDate(startDateWarrantyBoard),
            "insurance_expiry_date": handleDate(endDateWarrantyBoard),
            "food_drug_authority_licence_num": foodAuthorityNumber,
            "food_drug_authority_issue_date": handleDate(startDateFoodAuthority),
            "food_drug_authority_expiry_date": handleDate(endDateFoodAuthority),
            "central_bank_number":centralBankNumber,
            "central_bank_issue_date": handleDate(startDateBank),
            "central_bank_expiry_date": handleDate(endDateBank),
  
            "iban_num": ibanNumber,
            "bank_name": bankName,
            "financial_manager_name": financialManagerName,
            "financial_manager_email": fmEmail,
            "financial_manager_phone_num": fmPhoneNumber,
            "tax_number": valueAddNumber,
            "purchasing_manager_name": salesMangerName,
            "purchasing_manager_email": smEmail,
            "purchasing_manager_phone_num": smPhoneNumber,
            "agreeing_to_entered_data_correct": true,
            "num_of_employees": parseInt(employeeNumber),
            "sector":sec,
            "sector_type": secType
          });

          dispatch(facilityRegister(data))

          history.push('/FilesAndLocation')
        }
    }

    return (
        <>
        <div className="data-form p-3">
          <div className="desc-title text-center">اكمل البيانات</div>
          <div className="sub-title">فضلا اكمل البيانات الخاصه بالمنشأة</div>
          <form onSubmit={submitHandler}>

            <div className="name-form">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="name">اسم الجهة</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange= {(e) => setName(e.target.value)}
                      placeholder="اكتب اسم الجهة"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>                
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="mailBox">رقم ص.ب</label>
                    <input
                      type="text"
                      id="mailBox"
                      name="mailBox"
                      value={mailBox}
                      onChange= {(e) => setMailBox(e.target.value)}
                      placeholder="ادخل رقم ص.ب"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="postalCode">الرمز البريدى</label>
                    <input
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      value={postalCode}
                      onChange= {(e) => setPostalCode(e.target.value)}
                      placeholder="ادخل الرمز البريدى"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date1">موقع المنشأة</label>
                    <div className="select-city">
                      <div className="select-btn" onClick={selectClick}>
                        {palceHolder && (
                          <span style={{ color: "var(--lightgray-color)" }}>
                            اختر موقع المنشأة
                          </span>
                        )}
                        <span>{selectTitle}</span>
                        <img src={rarrow} alt='' />
                      </div>
                      {IsDisplayed && (
                        <div className="select-content">
                          {cities.map((option, i) => (
                            <div key={i}
                              className="select-item"
                              onClick={(e) => {changeSelect(e.target.textContent);
                              }}
                            >
                              {" "}
                              {option}{" "}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">تاربخ اصدار السجل التجاري</label>
                    <DatePicker
                      selected={startDateCommercialRegister}
                      onChange= {(date) => setStartDateCommercialRegister(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الاصدار"
                      popperPlacement="top"
                    />
                    <img src={date} alt="" style={{left: "18px"}}/>
                  </div>
                </Col>

                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">تاريخ انتهاء السجل التجارى</label>
                    <DatePicker
                      selected={endDateCommercialRegister}
                      onChange= {(date) => setEndDateCommercialRegister(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الانتهاء"
                      popperPlacement="top"
                    />
                    <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>             
              </Row>
            </div>
            
            {sec !== 7 && sec !== 8 &&
              <div className="medical-licence-no">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">رقم الترخيص </label>
                    <input
                      dir="rtl"
                      type="text"
                      id="licenseNumber"
                      name="licenseNumber"
                      value={licenseNumber}
                      onChange= {(e) => setLicenseNumber(e.target.value)}
                      placeholder="اكتب رقم الترخيص"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">تاريخ اصدار الترخيص </label>
                    <DatePicker
                      selected={startDateLicense}
                      onChange= {(date) => setStartDateLicense(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الاصدار"
                      popperPlacement="top"
                    />
                    <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>              
                  <div className="input-lable w-100">
                    <label htmlFor="date2">تاربخ انتهاء الترخيص </label>
                    <DatePicker
                      selected={endDateLicense}
                      onChange= {(date) => setEndDateLicense(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الانتهاء"
                      popperPlacement="top"
                    />
                    <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
              </Row>
            </div>            
            }

            {((sec === 7)) &&
              <div className="medical-licence-no">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">رقم الترخيص الطبي</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="medicalLicenseNumber"
                      name="medicalLicenseNumber"
                      value={medicalLicenseNumber}
                      onChange= {(e) => setMedicalLicenseNumber(e.target.value)}
                      placeholder="اكتب رقم الترخيص"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">تاريخ اصدار الترخيص الطبي</label>
                    <DatePicker
                      selected={startDateMedicalLicense}
                      onChange= {(date) => setStartDateMedicalLicense(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الاصدار"
                      popperPlacement="top"
                    />
                    <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>              
                  <div className="input-lable w-100">
                    <label htmlFor="date2">تاربخ انتهاء الترخيص الطبي</label>
                    <DatePicker
                      selected={endDateMedicalLicense}
                      onChange= {(date) => setEndDateMedicalLicense(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الانتهاء"
                      popperPlacement="top"
                    />
                    <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
              </Row>
            </div>
            }
            
            {(sec === 7) &&
            <div className="warranty-board-no">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">رقم مجلس الضمان</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="warrantyBoardNumber"
                      name="warrantyBoardNumber"
                      value={warrantyBoardNumber}
                      onChange= {(e) => setWarrantyBoardNumber(e.target.value)}
                      placeholder="اكتب رقم مجلس الضمان"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>  
                  <div className="input-lable w-100">
                    <label htmlFor="date2">تاريخ اصدار الرقم </label>
                    <DatePicker
                      selected={startDateWarrantyBoard}
                      onChange= {(date) => setStartDateWarrantyBoard(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الاصدار"
                      popperPlacement="top"
                    />
                    <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}> 
                  <div className="input-lable w-100">
                    <label htmlFor="date2">تاربخ انتهاء الرقم </label>
                    <DatePicker
                      selected={endDateWarrantyBoard}
                      onChange= {(date) => setEndDateWarrantyBoard(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الانتهاء"
                      popperPlacement="top"
                    />
                    <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
              </Row>
            </div>
            }
            {(((sec !== 15) && ((sec === 7) && (secType !== 28))) || (sec === 8))  &&
            
            <div className="food-licence-no">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                      <label htmlFor="date2">رقم ترخيص هيئة الغذاء والدواء</label>
                      <input
                      dir="rtl"
                      type="text"
                      id="foodAuthorityNumber"
                      name="foodAuthorityNumber"
                      value={foodAuthorityNumber}
                      onChange= {(e) => setfoodAuthorityNumber(e.target.value)}
                      placeholder="اكتب رقم ترخيص هيئة الغذاء والدواء"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                      />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                      <label htmlFor="date2">تاريخ اصدار الترخيص </label>
                      <DatePicker
                      selected={startDateFoodAuthority}
                      onChange= {(date) => setstartDateFoodAuthority(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الاصدار"
                      popperPlacement="top"
                      />
                      <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                      <label htmlFor="date2">تاربخ انتهاء الترخيص </label>
                      <DatePicker
                      selected={endDateFoodAuthority}
                      onChange= {(date) => setEndDateFoodAuthority(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الانتهاء"
                      popperPlacement="top"
                      />
                      <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
              </Row>
            </div>
            }
            {((sec === 7)) &&
            <div className="bank-no">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                      <label htmlFor="date2">رقم ترخيص البنك المركزى السعودى</label>
                      <input
                      dir="rtl"
                      type="text"
                      id="centralBankNumber"
                      name="centralBankNumber"
                      value={centralBankNumber}
                      onChange= {(e) => setCentralBankNumber(e.target.value)}
                      placeholder="اكتب رقم ترخيص البنك المركزى السعودى"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                      />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                      <label htmlFor="date2">
                      تاربخ اصدار البنك المركزى السعودى
                      </label>
                      <DatePicker
                      selected={startDateBank}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      onChange= {(date) => setStartDateBank(date)}
                      
                      placeholderText="اختر تاريخ الاصدار"
                      popperPlacement="top"
                      />
                      <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                      <label htmlFor="date2">
                      تاربخ انتهاء البنك المركزى السعودى
                      </label>
                      <DatePicker
                      selected={endDateBank}
                      onChange= {(date) => setEndDateBank(date)}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      
                      placeholderText="اختر تاريخ الاصدار"
                      popperPlacement="top"
                      />
                      <img src={date} alt="" style={{left: "18px"}} />
                  </div>
                </Col>
              </Row>
            </div>
            }
            <div className="bank-name">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">اسم البنك</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="bankName"
                      name="bankName"
                      value={bankName}
                      onChange= {(e) => setBankName(e.target.value)}
                      placeholder="اكتب اسم البنك"
                      onFocus={focusHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">رقم IBN</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="ibanNumber"
                      name="ibanNumber"
                      value={ibanNumber}
                      onChange= {(e) => setIbanNumber(e.target.value)}
                      placeholder="اكتب رقم IBN"
                      onBlur={focusoutHandler}
                    />
                  </div>
                  {errorsFlags[3] && <div className='error'>{errors.iban}</div> }
                </Col>
              </Row>
            </div>

            <div className="manager-name">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">اسم المدير المالي</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="financialManagerName"
                      name="financialManagerName"
                      value={financialManagerName}
                      onChange= {(e) => setFinancialManagerName(e.target.value)}
                      placeholder="اكتب اسم المدير المالي"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">البريد الالكتروني</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="fmEmail"
                      name="fmEmail"
                      value={fmEmail}
                      onChange= {(e) => setFmEmail(e.target.value)}
                      placeholder="اكتب البريد الالكترونى"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                  {errorsFlags[2] && <div className='error'>{errors.emailMsg}</div> }
                  {errorsFlags[2] && <div className='error'>{errors.emailMsg1}</div> }
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">رقم الهاتف</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="fmPhoneNumber"
                      name="fmPhoneNumber"
                      value={fmPhoneNumber}
                      onChange= {(e) => setFmPhoneNumber(e.target.value)}
                      placeholder="اكتب رقم الهاتف"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="sales-manager">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">اسم مدير المشتريات</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="salesManagerName"
                      name="salesManagerName"
                      value={salesMangerName}
                      onChange= {(e) => setSalesMangerName(e.target.value)}
                      placeholder="اكتب اسم مدير المشتريات"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">البريد الالكتروني</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="smEmail"
                      name="smEmail"
                      value={smEmail}
                      onChange= {(e) => setSmEmail(e.target.value)}
                      placeholder="اكتب البريد الالكترونى"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                  {errorsFlags[2] && <div className='error'>{errors.emailMsg}</div> }
                  {errorsFlags[2] && <div className='error'>{errors.emailMsg1}</div> }
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">رقم الهاتف</label>
                    <input
                      dir="rtl"
                      type="text"
                      id="smPhoneNumber"
                      name="smPhoneNumber"
                      value={smPhoneNumber}
                      onChange= {(e) => setSmPhoneNumber(e.target.value)}
                      placeholder="اكتب رقم الهاتف"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="enterprise-info">
              <Row className="w-100">
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">رقم ضريبة القيمة المضافة</label>
                    <input
                      dir="rtl"
                      id="{valueAddNumber}"
                      name="valueAddNumber"
                      value={valueAddNumber}
                      onChange= {(e) => setValueAddNumber(e.target.value)}
                      placeholder="اكتب رقم ضريبة القيمة المضافة"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div className="input-lable w-100">
                    <label htmlFor="date2">عدد موظفين المنشأة</label>
                    <input
                      dir="rtl"
                      type="number"
                      id="{employeeNumber}"
                      name="employeeNumber"
                      value={employeeNumber}
                      onChange= {(e) => setEmployeeNumber(e.target.value)}
                      placeholder="اكتب عدد موظفين المنشأة"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
              </Row>
              {errorsFlags[0] && <div className='error' style={{marginRight:"12px"}} >{errors.allFieldRequired}</div> }

            </div>
            <button className="btnNext" type="submit">
              <img src={arrow} alt="" />
              التالي
            </button>
          </form>
          {error && <div className='error'><img src={err} alt="" /> {error}</div> }
        </div>
            
        </>
    )
}

export default MainDataFormScreen
