import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import { Pie } from "@ant-design/charts";
import "./stat/collective_stat.css";
import CountUp from "react-countup";
import "./stat/Manger_ControlPanle.css";

function Collective_stat(props) {
  const [data, setData] = useState([
    {
      type: "عدد الفواتير المستحقة للدفع فى الشهر /السنة",
      value: 27000,
    },
    {
      type: "عدد الفواتير المستحقة للدفع حالياً",
      value: 25000,
    },
    {
      type: "عدد الفواتير التى تجاوزت وقت السداد",
      value: 10008,
    },
    {
      type: "عدد الفواتير المدفوعة فى اللشهر / السنة",
      value: 18000,
    },
  ]);
  const [config, setConfig] = useState({
    appendPadding: 10,
    legend: false,
    data: data,
    angleField: "value",
    colorField: "type",
    color: ["#0fb0a9", "#e64f39", "rgb(4,187,245)", "#7a7a7a"],
    radius: 0.75,

    label: {
      type: "spider",
      labelHeight: 28,
      content: "\n{name}\t\t\t:\t\t{value}",
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
  });
  return (
    <div className="collective_stat">
      <div className="header">تحصيل</div>
      <div className="chart">
        <Pie {...config} />
      </div>
      <div className="stat_container">
        <div className="stat_item">
          <div className="info_item">
            <p className="title">عدد الفواتير المستحقة للدفع فى الشهر /السنة</p>
            <span className="list" style={{ background: "#7a7a7a" }}></span>
          </div>
          <div className="yearly" style={{ marginRight: "45px" }}>
            <div className="total_number">
              <CountUp start={0} end={15000} duration={2} separator="," />
            </div>
            <div className="title"> سنوي </div>
          </div>
          <div className="monthly" style={{ marginRight: "185px" }}>
            <div className="month_No">
              <CountUp start={0} end={1300} duration={2} separator="," />
            </div>
            <div className="title"> شهري </div>
          </div>
        </div>
        <div className="stat_item">
          <div className="info_item">
            <p className="title">عدد الفواتير المستحقة للدفع حالياً</p>
            <span className="list" style={{ background: "#e64f39" }}></span>
          </div>
          <div className="total_number">
            <CountUp start={0} end={15000} duration={2} separator="," />
          </div>
        </div>
        <div className="stat_item">
          <div className="info_item">
            <p className="title">عدد الفواتير التى تجاوزت وقت السداد</p>
            <span
              className="list"
              style={{ background: "rgb(4,187,245)" }}
            ></span>
          </div>
          <div className="total_number">
            <CountUp start={0} end={15000} duration={2} separator="," />
          </div>
        </div>
        <div className="stat_item">
          <div className="info_item">
            <p className="title">عدد الفواتير المدفوعة فى الشهر / السنة</p>
            <span className="list"></span>
          </div>
          <div className="yearly" style={{ marginRight: "24px" }}>
            <div className="total_number">
              <CountUp start={0} end={15000} duration={2} separator="," />
            </div>
            <div className="title"> سنوي </div>
          </div>
          <div className="monthly" style={{ marginRight: "160px" }}>
            <div className="month_No">
              <CountUp start={0} end={1300} duration={2} separator="," />
            </div>
            <div className="title"> شهري </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Achievement({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div style={{ width: "300px" }} className="heading text-center">
              الأحصائيات
            </div>

            <Collective_stat />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default Achievement;
