import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/negotiation.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import "../../styles/collect_service.css";
import { Link } from "react-router-dom";
import SearchBox from "../../component/SearchBox";
import { Button, Table, Row, Form } from "react-bootstrap";

import { listCollectInvoices } from "../../actions/serviceActions";

function CollectServiceScreen({ history }) {
  const [selectValue, setSelectValue] = useState("");

  const titles = [
    "رقم الفاتورة",
    "أسم المنشأة صاحبة الفاتورة",
    "أسم المنشأة المراد تحصيل الفاتورة منها",
    "طريقة الدفع",
    "تاريخ الدفع",
    "نوع الفاتورة",
    "قيمة الفاتورة",
    "مدة عدم السداد",
    "قيمة الخصم",
    "حالة الفاتورة",
    "المبلغ الذي تم الإتفاق عليه",
  ];

  const more = {
    title: "الاجراءات",
    actions: {
      عرض: "",
      طباعة: "",
      تعديل: "",
    },
  };

  const dispatch = useDispatch();

  const collectInvoiceList = useSelector((state) => state.collectInvoiceList);
  const {
    error,
    loading,
    collectInvoices,
    page,
    pages,
    collectInvoicesNewNotPaid,
    collectInvoicesOldPaid,
    collectInvoicesOldNotPaid,
    collectInvoicesNewPaid,
    collectInvoiceCount,
  } = collectInvoiceList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let keyword = history.location.search;

  // useEffect(() => {
  //   if(!userInfo) {
  //     history.push('/')
  //   } else {
  //     if (!userInfo.is_activated){
  //       history.push('/services')
  //     } else {
  //       dispatch(listCollectInvoices(keyword))
  //     }
  //   }

  // }, [dispatch, history, userInfo, keyword])

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  const handleMore = (id) => {
    return Object.keys(more.actions).map((key, index) => {
      let path = Object.values(more.actions)[index] + "/" + id;

      return (
        <option value={path} key={index}>
          {key}
        </option>
      );
    });
  };

  return (
    <div className="negotiation_page">
      <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest={"/collectServices"}
        style={"threetwo_section"}
      />

      <div
        style={{
          position: "relative",
          backgroundColor: "#eee",
          height: "134px",
          marginTop: "0",
          paddingBottom: "20px",
          marginBottom: "0",
        }}
        className="header_title"
      >
        <div className="button_new">
          <Link to="/OldBills">
            <Button className="button_new_1">
              قائمة الفواتير الجديدة و القديمة
            </Button>
          </Link>

          <Link to="/addOldBill">
            <Button className="button_new_2">إضافة فاتورة قديمة</Button>
          </Link>
        </div>
      </div>
      <div
        className="nego_list"
        style={{ width: "1600px", height: "446px", margin: "55px auto 35px" }}
      >
        <div className="heading text-center">إحصائيات لجميع الفواتير</div>

        <div
          className="nego_table scroll"
          style={{ width: "1525px", height: "339px" }}
        >
          <div className="stat">
            <div className="stat_item">
              <div className="parent">
                <div className="child">
                  <span>{collectInvoiceCount ?? 0}</span>
                </div>
              </div>
              <div className="stat_title text-center">جميع الفواتير</div>
            </div>
            <div className="stat_item">
              <div className="parent">
                <div className="child">
                  <span>{collectInvoicesNewNotPaid ?? 0}</span>
                </div>
              </div>
              <div className="stat_title text-center">
                الفواتير الآجلة الغير مدفوعة
              </div>
            </div>
            <div className="stat_item">
              <div className="parent">
                <div className="child">
                  <span>{collectInvoicesNewPaid ?? 0}</span>
                </div>
              </div>
              <div className="stat_title text-center">
                الفواتير الآجلة المدفوعة
              </div>
            </div>
            <div className="stat_item">
              <div className="parent">
                <div className="child">
                  <span>{collectInvoicesOldNotPaid ?? 0}</span>
                </div>
              </div>
              <div className="stat_title text-center">
                الفواتير القديمة الغير محصلة
              </div>
            </div>
            <div className="stat_item">
              <div className="parent">
                <div className="child">
                  <span>{collectInvoicesOldPaid ?? 0}</span>
                </div>
              </div>
              <div className="stat_title text-center">
                الفواتير القديمة محصلة
              </div>
            </div>
          </div>

          {/* <Table bordered hover className="TableNewDesign">
              <thead>
                <tr>
                {titles.map((title, x) => (
                    <th key={x+1}>{title}</th>
                  ))
                }
                {more ? <th>{more.title}</th> : ""}
                  
                </tr>
              </thead>

              <tbody>
                  {collectInvoices.map((obj) => (
                      <tr key={obj.id}>
                        <td>IN-{obj.invoice_num}</td>
                        <td>{obj.collector_facility.facility_name}</td>
                        <td>{obj.payer_facility.facility_name}</td>
                        <td>
                          {
                            obj.collect_method === "P" ?
                            'دفعة واحدة' : 'دفعتين'
                          }
                          
                        </td>
                        <td>{obj.invoice_pay_date}</td>
                        <td>
                          {
                            obj.collect_invoice_type === "O" ?
                            ' قديمة' : 'جديدة'
                          }
                          
                        </td>
                        
                        <td>{obj.invoice_price} SR</td>
                        <td>{obj.negotiation_duration} سنوات</td>
                        <td>{obj.invoice_discount} SR</td>
                        <td>
                          {
                            obj.collect_invoice_type === true ?
                            ' مدفوعة' : ' غير مدفوعة'
                          }
                          
                        </td>
                        <td>{obj.invoice_price - obj.invoice_discount}  SR</td>
                        {more ? (
                          <td className="actions">
                            <Row className="m-2">
                              <Form.Select
                                onChange={handleChange}
                                className="text-center px-1 pe-2"
                              >
                                <option>
                                  أختر
                                </option>
                                {handleMore(obj.id)}
                              </Form.Select>                              
                              <Link to={selectValue} className="btn-outline-main btn-outline-info btn">
                              تنفيذ
                              </Link>                                               
                            </Row>
                          </td>                          
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  }                
                </tbody>
                </Table> */}

          {/*<TableNewDesign titles={titles} data={negos} more={more} />*/}
        </div>
      </div>
    </div>
  );
}

export default CollectServiceScreen;
