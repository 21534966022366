export const FACILITY_INFO_REGISTER_REQUEST = 'FACILITY_INFO_REGISTER_REQUEST'
export const FACILITY_INFO_REGISTER_SUCCESS = 'FACILITY_INFO_REGISTER_SUCCESS'
export const FACILITY_INFO_REGISTER_FAIL = 'FACILITY_INFO_REGISTER_FAIL'

export const FACILITY_IMAGE_REGISTER_REQUEST = 'FACILITY_IMAGE_REGISTER_REQUEST'
export const FACILITY_IMAGE_REGISTER_SUCCESS = 'FACILITY_IMAGE_REGISTER_SUCCESS'
export const FACILITY_IMAGE_REGISTER_FAIL = 'FACILITY_IMAGE_REGISTER_FAIL'

export const FACILITY_OWNER_REGISTER_REQUEST = 'FACILITY_OWNER_REGISTER_REQUEST'
export const FACILITY_OWNER_REGISTER_SUCCESS = 'FACILITY_OWNER_REGISTER_SUCCESS'
export const FACILITY_OWNER_REGISTER_FAIL = 'FACILITY_OWNER_REGISTER_FAIL'
export const FACILITY_OWNER_REGISTER_RESET = 'FACILITY_OWNER_REGISTER_RESET'

export const FACILITY_EMPLOYEE_REGISTER_REQUEST = 'FACILITY_EMPLOYEE_REGISTER_REQUEST'
export const FACILITY_EMPLOYEE_REGISTER_SUCCESS = 'FACILITY_EMPLOYEE_REGISTER_SUCCESS'
export const FACILITY_EMPLOYEE_REGISTER_FAIL = 'FACILITY_EMPLOYEE_REGISTER_FAIL'
export const FACILITY_EMPLOYEE_REGISTER_RESET = 'FACILITY_EMPLOYEE_REGISTER_RESET'

export const FACILITY_TYPE_REQUEST = 'FACILITY_TYPE_REQUEST'
export const FACILITY_TYPE_SUCCESS = 'FACILITY_TYPE_SUCCESS'
export const FACILITY_TYPE_FAIL = 'FACILITY_TYPE_FAIL'

export const FACILITY_PAYMENT_BANK_REQUEST = 'FACILITY_PAYMENT_BANK_REQUEST'
export const FACILITY_PAYMENT_BANK_SUCCESS = 'FACILITY_PAYMENT_BANK_SUCCESS'
export const FACILITY_PAYMENT_BANK_FAIL = 'FACILITY_PAYMENT_BANK_FAIL'

export const FACILITY_DETAILS_REQUEST = 'FACILITY_DETAILS_REQUEST'
export const FACILITY_DETAILS_SUCCESS = 'FACILITY_DETAILS_SUCCESS'
export const FACILITY_DETAILS_FAIL = 'FACILITY_DETAILS_FAIL'

export const FACILITY_LIST_REQUEST = 'FACILITY_LIST_REQUEST'
export const FACILITY_LIST_SUCCESS = 'FACILITY_LIST_SUCCESS'
export const FACILITY_LIST_FAIL = 'FACILITY_LIST_FAIL'


