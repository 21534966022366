const menuItems = {
  items: [
    {
      id: "navigation",
      title: "",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "المنشآت",
          title: "المنشآت المسجلة",
          type: "collapse",
          icon: "feather icon-home",
          children: [
            {
              id: "الصحية",
              title: "الصحية",
              type: "item",
              url: "/admin/health-facilies",
            },
            {
              id: "الغذائية",
              title: "الغذائية",
              type: "item",
              url: "/admin/food-facilies",
            },
            {
              id: "القرطاسية المكتبية",
              title: "القرطاسية المكتبية",
              type: "item",
              url: "/admin/library-facilies",
            },
            {
              id: "النظافة",
              title: "النظافة",
              type: "item",
              url: "/admin/cleaning-facilies",
            },
            {
              id: "الأثاث المكتبي",
              title: "الأثاث المكتبي",
              type: "item",
              url: "/admin/office-facilies",
            },
            {
              id: "النقل",
              title: "النقل",
              type: "item",
              url: "/admin/transportaion-facilies",
            },
            {
              id: "الغسيل",
              title: "الغسيل",
              type: "item",
              url: "/admin/laundary-facilies",
            },
            {
              id: "ملابس الزي الموحد",
              title: "ملابس الزي الموحد",
              type: "item",
              url: "/admin/uniform-facilies",
            },
            {
              id: "التشغيل والصيانة",
              title: "التشغيل والصيانة",
              type: "item",
              url: "/admin/opearting-facilies",
            },
            {
              id: "التوظيف والخدمات",
              title: "التوظيف والخدمات",
              type: "item",
              url: "/admin/jobs-facilies",
            },
          ],
        },
        {
          id: "العروض و الطلبات",
          title: "العروض و الطلبات",
          type: "collapse",
          icon: "feather icon-layout",
          children: [
            {
              id: "تأجير أو بيع الاجهزة الطبية",
              title: "تأجير أو بيع الاجهزة الطبية",
              type: "item",
              url: "/admin/offer/selling-medical-devices",
            },
            {
              id: "تأجير خدمات الاطباء و العيادات",
              title: "تأجير خدمات الاطباء و العيادات",
              type: "item",
              url: "/admin/offer/renting-medical",
            },
            {
              id: "تنظيم وتشغيل عقود المختبرات",
              title: "تنظيم وتشغيل عقود المختبرات",
              type: "item",
              url: "/admin/offer/labs",
            },
            {
              id: "تنظيم وتشغيل عقود الأشعة",
              title: "تنظيم وتشغيل عقود الأشعة",
              type: "item",
              url: "/admin/offer/rays",
            },
            {
              id: "تنظيم مناقصات التوريد",
              title: "تنظيم مناقصات التوريد",
              type: "collapse",
              children: [
                {
                  id: "الغذائية",
                  title: "الغذائية",
                  type: "item",
                  url: "/admin/offers/tenders/food",
                },
                {
                  id: "القرطاسية المكتبية",
                  title: "القرطاسية المكتبية",
                  type: "item",
                  url: "/admin/offer/tenders/library",
                },
                {
                  id: "النظافة",
                  title: "النظافة",
                  type: "item",
                  url: "/admin/offers/tenders/cleaning",
                },
                {
                  id: "الأثاث المكتبي",
                  title: "الأثاث المكتبي",
                  type: "item",
                  url: "/admin/offer/tenders/office",
                },
                {
                  id: "النقل",
                  title: "النقل",
                  type: "item",
                  url: "/admin/offer/tenders/transportation",
                },
                {
                  id: "الغسيل",
                  title: "الغسيل",
                  type: "item",
                  url: "/admin/offer/tenders/laundry",
                },
                {
                  id: "ملابس الزي الموحد",
                  title: "ملابس الزي الموحد",
                  type: "item",
                  url: "/admin/offer/tenders/uniform",
                },
                {
                  id: "التشغيل والصيانة",
                  title: "التشغيل والصيانة",
                  type: "item",
                  url: "/admin/offer/tenders/operating",
                },
                {
                  id: "التوظيف والخدمات",
                  title: "التوظيف والخدمات",
                  type: "item",
                  url: "/admin/offer/tenders/jobs",
                },
              ],
            },
          ],
        },
        {
          id: "التأمينات",
          title: "التأمينات",
          type: "collapse",
          icon: "feather icon-layout",
          children: [
            {
              id: "طلبات التأمين",
              title: "طلبات التأمين",
              type: "item",
              url: "/admin/medical-team-insurrence-request",
            },
            {
              id: "الطاقم الفني والإداري",
              title: "الطاقم الفني والإداري",
              type: "item",
              url: "/admin/all-medical-team",
            },
            {
              id: "طلبات تقيم الشبكات الطبية",
              title: "طلبات تقيم الشبكات",
              type: "item",
              url: "/admin/rate-medical-networks",
            },
          ],
        },
        {
          id: "الموظفين",
          title: "الموظفين",
          type: "collapse",
          icon: "feather icon-layout",
          children: [
            {
              id: "إضافة موظف",
              title: "إضافة موظف",
              type: "item",
              url: "/admin/add-employee",
            },
            {
              id: "صلاحيات الموظف",
              title: "صلاحيات الموظف",
              type: "item",
              url: "/admin/bayan-employees-permessions",
            },
          ],
        },
        {
          id: "الفواتير",
          title: "الفواتير و التحصيل",
          type: "collapse",
          icon: "feather icon-layout",
          children: [
            {
              id: "فواتير جديدة",
              children: [
                {
                  id: "فواتير المنشآت المسجلة",
                  title: "فواتير المنشآت المسجلة",
                  type: "item",
                  url: "/admin/new-bills/subscription",
                },
                {
                  id: "تقييم الشبكات الطبية",
                  title: "تقييم الشبكات الطبية",
                  type: "item",
                  url: "/admin/new-bills/rate-network",
                },
                {
                  id: "تأجير أو بيع الاجهزة الطبية",
                  title: "تأجير أو بيع الاجهزة الطبية",
                  type: "item",
                  url: "/admin/new-bills/medical-devices",
                },
                {
                  id: "تأجير خدمات الاطباء و العيادات",
                  title: "تأجير خدمات الاطباء و العيادات",
                  type: "item",
                  url: "/admin/new-bills/medical-rent",
                },
                {
                  id: "مقارنة أسعار تامين اخطاء ممارسة المهن الطبية",
                  title: "مقارنة أسعار تامين اخطاء ممارسة المهن الطبية",
                  type: "item",
                  url: "/admin/new-bills/comparing-prices",
                },
                {
                  id: "التحصيل",
                  title: "التحصيل",
                  type: "item",
                  url: "/admin/new-bills/collecting",
                },
                {
                  id: "تنظيم وتشغيل عقود المختبرات",
                  title: "تنظيم وتشغيل عقود المختبرات",
                  type: "item",
                  url: "/admin/new-bills/labs",
                },
                {
                  id: "تنظيم وتشغيل عقود الأشعة",
                  title: "تنظيم وتشغيل عقود الأشعة",
                  type: "item",
                  url: "/admin/new-bills/rays",
                },
                {
                  id: "تنظيم مناقصات التوريد",
                  title: "تنظيم مناقصات التوريد",
                  type: "collapse",
                  children: [
                    {
                      id: "الغذائية",
                      title: "الغذائية",
                      type: "item",
                      url: "/admin/new-bills/tenders/food",
                    },
                    {
                      id: "القرطاسية المكتبية",
                      title: "القرطاسية المكتبية",
                      type: "item",
                      url: "/admin/new-bills/tenders/library",
                    },
                    {
                      id: "النظافة",
                      title: "النظافة",
                      type: "item",
                      url: "/admin/new-bills/tenders/cleaning",
                    },
                    {
                      id: "الأثاث المكتبي",
                      title: "الأثاث المكتبي",
                      type: "item",
                      url: "/admin/new-bills/tenders/office",
                    },
                    {
                      id: "النقل",
                      title: "النقل",
                      type: "item",
                      url: "/admin/new-bills/tenders/transportation",
                    },
                    {
                      id: "الغسيل",
                      title: "الغسيل",
                      type: "item",
                      url: "/admin/new-bills/tenders/laundry",
                    },
                    {
                      id: "ملابس الزي الموحد",
                      title: "ملابس الزي الموحد",
                      type: "item",
                      url: "/admin/new-bills/tenders/uniform",
                    },
                    {
                      id: "التشغيل والصيانة",
                      title: "التشغيل والصيانة",
                      type: "item",
                      url: "/admin/new-bills/tenders/operating",
                    },
                    {
                      id: "التوظيف والخدمات",
                      title: "التوظيف والخدمات",
                      type: "item",
                      url: "/admin/new-bills/tenders/job",
                    },
                  ],
                },
              ],
              title: "فواتير جديدة",
              type: "collapse",
            },
            {
              id: "فواتير قديمة",
              title: "فواتير قديمة",
              type: "item",
              url: "/admin/old-bills-admin",
              state: "soon",
            },
            {
              id: "النقاش",
              title: "النقاش",
              type: "item",
              url: "/admin/discussion",
              state: "soon",
            },
          ],
        },
        {
          icon: "feather icon-home",
          id: "صلاحية الحالات الإستثنائية",
          title: "صلاحية الحالات الإستثنائية",
          type: "item",
          url: "/admin/special-cases",
        },
        {
          icon: "feather icon-home",
          id: "صلاحية اضافة اكتر من نوع قطاع",
          title: "صلاحية اضافة اكتر من نوع قطاع",
          type: "item",
          url: "/admin/multi-sectors",
        },
        {
          id: "الرسائل",
          title: "الرسائل",
          type: "item",
          url: "/admin/messages",
          icon: "feather icon-message-square",
        },
        {
          id: "الاشعارات",
          title: "الاشعارات",
          type: "item",
          url: "/admin/notifications",
          icon: "feather icon-bell",
        },
        {
          id: "الصلاحيات",
          title: "الصلاحيات",
          type: "collapse",
          icon: "feather icon-home",
          state: "soon",
          children: [
            {
              id: "صلاحيه تغيير المبالغ الداخلية",
              title: "صلاحيه تغيير المبالغ الداخلية",
              type: "item",
              url: "/admin/admin-change-inside-pices",
            },
            {
              id: "صلاحيه تغيير البيانات البنكية",
              title: "صلاحيه تغيير البيانات البنكية",
              type: "item",
              url: "/admin/admin-change-bank-data",
            },
            {
              id: "صلاحيه تغيير الاقرار",
              title: "صلاحيه تغيير الاقرار",
              type: "item",
              url: "/admin/admin-change-endorsement",
            },
            {
              id: "صلاحيه تعديل البيانات المدخلة",
              title: "صلاحيه تعديل البيانات المدخلة",
              type: "item",
              url: "/admin/admin-change-entered-data",
            },
            {
              id: "صلاحيه تغيير الفيديوهات الداخلية",
              title: "صلاحيه تغيير الفيديوهات الداخلية",
              type: "item",
              url: "/admin/admin-change-inside-video",
            },
            {
              id: "صلاحيه ايقاف الخدمات",
              title: "صلاحيه ايقاف الخدمات",
              type: "item",
              url: "/admin/admin-stop-service",
            },
            {
              id: "صلاحيه اضافة الخدمات",
              title: "صلاحيه اضافة الخدمات",
              type: "item",
              url: "/admin/admin-add-service",
            },
            {
              id: "صلاحيه اضافة اسم قطاع جديد",
              title: "صلاحيه اضافة اسم قطاع جديد",
              type: "item",
              url: "/admin/admin-add-section",
            },
            {
              id: "صلاحيه ايقاف قطاع",
              title: "صلاحيه ايقاف قطاع",
              type: "item",
              url: "/admin/admin-suspend-section",
            },
            {
              id: "صلاحيه تغيير الاهداف",
              title: "صلاحيه تغيير الاهداف",
              type: "item",
              url: "/admin/admin-change-targets",
            },
          ],
        },
        {
          state: "soon",
          id: "الإحصائيات",
          title: "الإحصائيات",
          type: "collapse",
          icon: "feather icon-home",
          children: [
            {
              id: "تقيم الشبكات الطبية",
              title: "تقيم الشبكات الطبية",
              type: "item",
              url: "/admin/medical-network-assessment",
            },
            {
              id: "تأجير أوبيع الأجهزة الطبية",
              title: "تأجير أوبيع الأجهزة الطبية",
              type: "item",
              url: "/admin/rental-medical-devices",
            },
            {
              id: "تأجير خدمات الأطباء او العيادات",
              title: "تأجير خدمات الأطباء او العيادات",
              type: "item",
              url: "/admin/renting-doctors-clinics",
            },
            {
              id: "مقارنة أسعار تأمين أخطاء ممارسة المهن الطبية",
              title: "مقارنة أسعار تأمين أخطاء ممارسة المهن الطبية",
              type: "item",
              url: "/admin/comoarison-medical-insurance",
            },
            {
              id: "التحصيل",
              title: "التحصيل",
              type: "item",
              url: "/admin/achievement",
            },
            {
              id: "تنظيم وتشغيل عقود المختبرات",
              title: "تنظيم وتشغيل عقود المختبرات",
              type: "item",
              url: "/admin/operating-laboratory-contracts",
            },
            {
              id: "تنظيم وتشغيل عقود الأشعة",
              title: "تنظيم وتشغيل عقود الأشعة",
              type: "item",
              url: "/admin/operating-radiology-contracts",
            },
            {
              id: "تنظيم مناقصات التوريد",
              title: "تنظيم مناقصات التوريد",
              type: "item",
              url: "/admin/organizing-supply-tenders",
            },
          ],
        },
      ],
    },
  ],
};

export default menuItems;