import React, { Component } from "react";
import "../../styles/suspend_section.css";
import arrow from "../../imgs/arrow.png";
import search from "../../imgs/search1.png";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";
class Suspend_Section extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionName: "",
      msg: "",
      showInfo: false,
      sectionType: "",
      suspendType: "",
      showModal: false,
    };
  }

  submitHandler = () => {
    this.setState({ showModal: true });
  };

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  onChooseType = (type) => {
    this.setState({
      sectionType: type,
    });
  };

  onClick = () => {
    this.setState({
      showInfo: true,
    });
  };

  onChooseSuspendType = (suspend) => {
    this.setState({
      suspendType: suspend,
    });
  };

  render() {
    const { sectionName, msg, showInfo } = this.state;
    console.log(this.state.suspendType);
    console.log(this.state.sectionType);
    return (
      <div className="suspend_sections">
        <div className="item">
          <div className="input-data">
            <label htmlFor="sectionName">اسم القطاع</label>
            <input
              type="text"
              id="sectionName"
              name="sectionName"
              value={sectionName}
              onChange={this.changeHandler}
              placeholder=" اختر القطاع"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
            <img src={search} alt="" />
          </div>

          <div
            onClick={() => {
              this.onChooseType("قطاع رئيسي");
            }}
            className="section_type"
          >
            قطاع رئيسي
          </div>
          <div
            onClick={() => {
              this.onChooseType("قطاع فرعي");
            }}
            className="section_type"
            style={{ backgroundColor: "var(--red-color)" }}
          >
            قطاع فرعي
          </div>
        </div>
        <div className="item">
          {showInfo && (
            <div>
              <div className="info">
                <p className="info_title">اسم الجهة :</p>
                <div className="info_data">{sectionName}</div>
              </div>
              <div className="suspend">
                <div className="suspend_types">
                  <div
                    onClick={() => {
                      this.onChooseSuspendType("إيقاف دائم");
                    }}
                    className="suspend_type"
                  >
                    إيقاف دائم
                  </div>
                  <div
                    className="suspend_type"
                    style={{ backgroundColor: "var(--red-color)" }}
                    onClick={() => {
                      this.onChooseSuspendType("إيقاف مؤقت");
                    }}
                  >
                    إيقاف مؤقت
                  </div>
                  <div
                    className="suspend_type"
                    style={{ backgroundColor: "#ababab" }}
                    onClick={() => {
                      this.onChooseSuspendType("تعديل الرسالة");
                    }}
                  >
                    تعديل الرسالة
                  </div>
                </div>
                <div
                  className="input-data"
                  style={{
                    marginLeft: "205px",
                    width: "710px",
                    height: "165px",
                  }}
                >
                  <label htmlFor="msg">رسالة الادارة</label>
                  <textarea
                    style={{ paddingTop: "10px" }}
                    type="text"
                    id="msg"
                    name="msg"
                    value={msg}
                    onChange={this.changeHandler}
                    placeholder="رسالة تنبيه للمشتركين بإيقاف الاشتراك الدائم أو المؤقت "
                    onFocus={this.focusHandler}
                    onBlur={this.focusoutHandler}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showInfo && (
          <div className="text-center d-flex justify-content-center">
            <Button
              className="text-center btnNext"
              onClick={this.submitHandler}
            >
              <img src={arrow} alt="" />
              تنفيذ
            </Button>
          </div>
        )}

        {showInfo === false && (
          <div className="text-center d-flex justify-content-center">
            <Button className="text-center btnNext" onClick={this.onClick}>
              <img src={arrow} alt="" />
              التالي
            </Button>
          </div>
        )}
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Suspend_Section;
