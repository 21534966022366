import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../../styles/description_start_services.css'
import arrow from '../../imgs/Group 224.png'


import orgPoster from '../../imgs/videos/cover/medicalNetworks.jpg'
import comparingMedicalMistakeWorkPoster from '../../imgs/videos/cover/comparingMedicalMistakeWorkPoster.jpg'



const posters = {
    '/MedicalNetworks': orgPoster,
    "/Orders": comparingMedicalMistakeWorkPoster,
};


function DescriptionServiceScreen({ title, subTitle, choosePara, dest }) {

    const [path, setPath] = useState('')

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin


    return (
        <div>
            {userInfo && userInfo.is_activated ? (
                <div className='desc_services'>
                    <div className='heading text-center'>الوصف وبدأ الخدمة</div>
                    <div className='description'>
                        <h2 className='title'>{title}</h2>
                        {choosePara === '1' && <p className='desc'> تنظيم عمليات الدفع الإلكتروني، وضمان الحماية من فقدان الفواتير أو التأخر في الدفعات المستحقة، لضمان الاستقرار المالي للقطاع الصحي سواء كانت فواتير قديمة او جديدة</p>}
                        {choosePara === '2' && <p className='desc'>  تهدف إلى تقليل أخطاء المهنة الطبية، وضمان حصول الكوادر الصحية العاملة في القطاع الصحي على تغطية تأمينية لها، وضمان عدم انتهائها بدون علم إدارة المنشآت</p>}
                        {choosePara === '3' && <p className='desc'>تعمل هذه الخدمة على أساس تنظيم شبكة موحدة خاصة بقطاع التأمين ومتوافقة مع رؤيته؛ يتم تنظيمها وفق معايير تقييمية حسب بيانات كل منشأة صحية لضمان حصولها على الفئة المستهدفة لها</p>}
                        <h3 className='goal-txt'>{subTitle}</h3>
                        <div className='goals'>
                            <p>مستشفيات</p>
                            <p>عيادات اليوم الواحد</p>
                            <p>مستوصف</p>
                            <p>مختبر</p>
                            <p>مجمع عيادات</p>
                            <p>اشعة</p>
                            <p>مركز طبي</p>
                            <p>مركز تاهيل</p>
                            <p>شركات التأمين</p>
                            <p>غير ذلك</p>
                        </div>

                    </div>
                    <div className='service text-center'>
                        <Link to={dest}>
                            <div className='enter-service'><img className='icon' src={arrow} alt="" />
                                دخول الخدمة</div>
                        </Link>
                        <div className="text-center" style={{ margin: '20px 0 0 68px', width: '580px', height: '208px', border: '5px solid #eee', overflow: 'hidden', position: 'relative' }}>
                            {/* <video width='580px' height='208px' poster={posters[dest]} style={{}} >
                                {
                                    () => {
                                        return (<source src={videos[dest]} type="video/mp4" />)
                                    }
                                }
                            </video> */}
                             <img src={posters[dest]} style={{top: '50%', left: '50%', width: '150%', height: '170%', position: 'absolute', transform: 'translate(-50%, -50%)'}} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='thank-msg' style={{ height: "232px" }}>
                    <div className='msg-content'>
                        <p className='thanks' style={{ position: "initial" }}>! شكرًا </p>
                        <p className='txt text-center px-2' style={{ position: "initial" }}>سيتم تفعيل الخدمات بعد الإطلاع على البيانات ومراعتها من قبل إدارة بيان</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DescriptionServiceScreen
