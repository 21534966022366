import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ControlPanelHeader from "../../component/ControlPanelHeader";
import date from "../../imgs/date.png";

import "../../styles/tender_list.css";
import searchicon from "../../imgs/search.png";
import menu from "../../imgs/menu.png";
import { Link, Route } from "react-router-dom";
import { Card, Row, Col, ToggleButton } from "react-bootstrap";
import PriceListDescription from "./PriceListDescription"
import { listInsurrenceOffers, updateInsurrenceOffer } from '../../actions/serviceActions'


function PriceListScreen({ match, history }) {

  const [search, setSearch] = useState("");

  const [statId, setStatId] = useState("");

  const [teamId, setTeamId] = useState("");

  const [currentOffer, setCurrentOffer] = useState({})


  const [insurrenceId, setInsurrenceId] = useState("");

  const dispatch = useDispatch()

  const InsurrenceOffersList = useSelector(state => state.InsurrenceOffersList)
  const { error, loading, offers } = InsurrenceOffersList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const onClick = (e) => {
    setStatId(e.target.getAttribute("data-stat"));
    setInsurrenceId(e.target.getAttribute("data-id"))
    setTeamId(e.target.getAttribute("data-team"))
  };

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    } else {
      if (!userInfo.is_activated) {
        history.push('/services')
      } else {
        dispatch(listInsurrenceOffers(match.params.id))
      }
    }

  }, [dispatch, history, userInfo])

  const arr =
    search === ""
      ? offers
      : offers.filter(
        (offer) =>
          offer.facility.client_code.includes(search) || offer.offer_price.includes(search)
      );


  return (
    <div className="tender_page">
      <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        fileDest={"/FacilityFiles"}
        exitDest={"/services"}
        style={"three_section"}
      />

      <div
        className="box_serach"
        style={{ justifyContent: "flex-end", paddingRight: "115px" }}
      >
      </div>
      <div className="tender_list">
        <div className="heading text-center">قائمة الأسعار</div>

        <div className="scroll" style={{ minHeight: "430px" }}>
          <Row className="">
            <Col lg="3" className="search_bar_parent">
              {/* search */}
              <Card className="search_bar">
                <div className="input_group">
                  <input
                    type="text"
                    name="search"
                    value={search}
                    placeholder="البحث "
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img src={searchicon} className="icon" alt="" />
                </div>
              </Card>

            </Col>

            <Col lg="9">
              <div className="cards scroll_container text-center">

                {offers?.map((obj) => (
                    <div
                      key={obj.id}
                      onClick={onClick}
                      className={statId == obj.id ? "card-item card-hover" : "card-item"}
                      data-id={obj.id}
                      data-stat={obj.id}
                      data-team={obj.medical_insurance_request}>
                      <div className="cards_top">قيمة العرض</div>
                      <h2 className={statId == obj.id ? "card-hover" : "cards_h2"}>
                        {new Intl.NumberFormat('en').format(obj.offer_price)}
                      </h2>
                      <small className="fs-5 ">ريال سعودي </small>
                      <div className="cards_bottom">{obj.facility.client_code}</div>
                    </div>
                  ))}

              </div>
            </Col>
          </Row>

          <Row>
            <div className="content">
              <PriceListDescription
                offers={offers}
                statId={statId}
                insurrenceId={insurrenceId}
                teamId={teamId} />
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default PriceListScreen;
