import {
    SECTOR_TYPE_LIST_REQUEST,
    SECTOR_TYPE_LIST_SUCCESS,
    SECTOR_TYPE_LIST_FAIL,


    SECTOR_LIST_REQUEST,
    SECTOR_LIST_SUCCESS,
    SECTOR_LIST_FAIL,
} from '../constants/sectorConstants'


export const sectorListReducer = (state = { sectors: [] }, action) => {
    switch (action.type) {

        case SECTOR_LIST_REQUEST:
            return { loading: true, sectors:[] }
        
        case SECTOR_LIST_SUCCESS:
            return { 
                loading: false, sectors: action.payload
            }
        
        case SECTOR_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const sectorTypeListReducer = (state = { sectorTypes: [] }, action) => {
    switch (action.type) {

        case SECTOR_TYPE_LIST_REQUEST:
            return {loading: true, sectorTypes: [] }
        
        case SECTOR_TYPE_LIST_SUCCESS:
            return { 
                loading: false, sectorTypes: action.payload,
            }
        
        case SECTOR_TYPE_LIST_FAIL:
            return {  loading: false, error: action.payload }
        
        default:
            return state
    }
}



