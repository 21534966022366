import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import { Row, Col, Card, Table, Form, Button, Modal } from "react-bootstrap";
import "../styles/TableNewDesign.css";
import Paginate from "../component/Paginate";
import { Link } from "react-router-dom";
import Message from "../component/Message";
import Loader from "../component/Loader";
import {
  deleteMedicalTeam,
  updateMedicalTeam,
} from "../actions/serviceActions";

function OldBillsAdmin({ history }) {
  const [selectValue, setSelectValue] = useState("");
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const facilityListForAdmin = useSelector(
    (state) => state.facilityListForAdmin
  );
  const { loading, error, facilities, page, pages } = facilityListForAdmin;

  const medicalTeamDelete = useSelector((state) => state.medicalTeamDelete);
  const {
    error: errorDelete,
    loading: loadingDelete,
    success: successDelete,
  } = medicalTeamDelete;

  const medicalTeamUpdate = useSelector((state) => state.medicalTeamUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = medicalTeamUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let keyword = history.location.search;

  const [bills, setBills] = useState([
    {
      payNo: "HP-0001",
      opertaionType: "شراء",
      service: "خدمات بيع الاجهزة الطبية",
      paymentDate: "1 / 2 / 2021",
      paymentMethod: "أجل",
      billAmount: "150.00 SR",
      paymentPeriod: "90 يوم",
      billCondition: "غير مدفوعة",
    },
    {
      payNo: "HP-0001",
      opertaionType: "شراء",
      service: "خدمات بيع الاجهزة الطبية",
      paymentDate: "1 / 2 / 2021",
      paymentMethod: "أجل",
      billAmount: "150.00 SR",
      paymentPeriod: "90 يوم",
      billCondition: "غير مدفوعة",
    },
    {
      payNo: "HP-0001",
      opertaionType: "شراء",
      service: "خدمات بيع الاجهزة الطبية",
      paymentDate: "1 / 2 / 2021",
      paymentMethod: "أجل",
      billAmount: "150.00 SR",
      paymentPeriod: "90 يوم",
      billCondition: "غير مدفوعة",
    },
  ]);

  // useEffect(() => {
  //   if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
  //     dispatch(listAllFacilitiesForAdmin(keyword));
  //   } else {
  //     history.push("/");
  //   }
  // }, [dispatch, history, userInfo, keyword, successDelete, successUpdate]);

  const more = {
    title: "الاجراءات",
    actions: {
      تفعيل: "approve",
      طباعة: "/admin/print-facility",
      تعديل: "/admin/update-facility-info",
      حذف: "delete",
    },
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  const handleDelete = (id) => {
    dispatch(deleteMedicalTeam(id));
    setShow(false);
  };

  const handleUpdate = (id) => {
    let data = new FormData();
    data.append("approved", true);
    dispatch(updateMedicalTeam(data, id));
  };

  const showModel = () => {
    let teamId = selectValue.split("/")[1];
    return (
      <Modal
        style={{ direction: "rtl" }}
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "var(--main-color)",
            justifyContent: "center",
          }}
        >
          <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h4">يرجى تأكيد الحذف</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-red"
            onClick={() => setShow(false)}
          >
            <span className="h4">إلغاء</span>
          </Button>
          <Button
            variant="success"
            className="btn-main px-5"
            onClick={() => handleDelete(teamId)}
          >
            <span className="h4">تأكيد</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <AdminLayout>
      <Row>
        <Col xl={12} md={12}>
          {errorUpdate && <Message varient="danger">{errorUpdate}</Message>}
          {errorDelete && <Message varient="danger">{errorDelete}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message varient="danger">{error}</Message>
          ) : (
            <Card className="code-table">
              <Card.Header>
                <Card.Title as="h5">قائمة الفواتير القديمة</Card.Title>
              </Card.Header>
              <Card.Body className="pb-0">
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>كود المنشأة</th>
                      <th>مبلغ الفاتورة</th>
                      <th>نوع الفاتورة</th>
                      <th>نوع الخدمة</th>
                      <th>الحالة</th>
                      <th>اجراءات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bills.map((obj) => (
                      <tr key={obj.id}>
                        <td>
                          <p className="mb-1">{obj.payNo}</p>
                        </td>
                        <td>
                          <p className="mb-1">{obj.opertaionType}</p>
                        </td>
                        <td>
                          <p className="mb-1">{obj.paymentPeriod}</p>
                        </td>
                        <td>
                          <p className="mb-1">{obj.billCondition}</p>
                        </td>

                        <td>
                          <Button
                            variant={
                              obj.is_deleted === true
                                ? "danger"
                                : obj.approved === true
                                ? "success"
                                : "warning"
                            }
                            className={
                              obj.is_deleted === true
                                ? "label btn-danger btn-sm f-12 text-white"
                                : obj.approved === true
                                ? "label btn-success btn-sm f-12 text-white"
                                : "label btn-warning btn-sm f-12 text-white"
                            }
                          >
                            {obj.is_deleted === true
                              ? "تم حذفه"
                              : obj.approved === true
                              ? "مفعل"
                              : "غير مفعل"}
                          </Button>
                        </td>

                        {more && !obj.is_deleted ? (
                          <td>
                            <Row className="m-2 w-100">
                              <Form.Select
                                as="select"
                                onChange={handleChange}
                                className="text-center px-1 pe-2"
                              >
                                <option>أختر</option>
                                <option value={`/invoice-all-type/${obj.id}`}>
                                  طباعة
                                </option>
                                {!obj.is_deleted && (
                                  <option value={`delete/${obj.id}`}>
                                    حذف
                                  </option>
                                )}
                              </Form.Select>
                              {selectValue.includes(
                                "/editMedicalOrTechnicalTeam"
                              ) ? (
                                <Button
                                  onClick={() => setShow(true)}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Button>
                              ) : selectValue.includes("/invoice-all-type/") ? (
                                <Link
                                  to={selectValue}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Link>
                              ) : selectValue.includes("delete") ? (
                                <Button
                                  onClick={() => setShow(true)}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Button>
                              ) : selectValue.includes("approve") ? (
                                <Button
                                  onClick={() => handleUpdate(obj.id)}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Button>
                              ) : selectValue.includes("update") ? (
                                <Button
                                  onClick={() => handleUpdate(obj.id)}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Button>
                              ) : (
                                ""
                              )}
                            </Row>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <div>{showModel()}</div>
      <Paginate
        page={page}
        pages={pages}
        keyword={keyword}
        link="/comparingMedicalMistakeWork"
      />
    </AdminLayout>
  );
}

export default OldBillsAdmin;
