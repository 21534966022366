import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/tshirt_logo.css";
import check from "../../imgs/check.png";
import back_logo from "../../imgs/back_logo.png";
import frontleft_logo from "../../imgs/frontleft_logo.png";
import frontright_logo from "../../imgs/frontright_logo.png";
import leftside_logo from "../../imgs/leftside_logo.png";
import rightside_logo from "../../imgs/rightside_logo.png";
import arrow from "../../imgs/arrow.png";
import axios from 'axios';
// import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
// import complete from "../../imgs/check1.png";
import "../../styles/info-ownr.css";
import "../../styles/tender_clothes.css";
// import { AiOutlineCloseCircle } from "react-icons/ai";
// import {
//   Row,
//   Col,
//   Card,
// } from "react-bootstrap";
import ControlPanelHeader from "../../component/ControlPanelHeader";
// import menu from "../../imgs/menu.png";
import { Link } from "react-router-dom";

// import {AddTender} from '../../actions/tenderActions'
// import {useLocation} from "react-router-dom";


function tShirtLogoIntToArray(num) {
	const fLeft = (num & (1 << 0)) == (1 << 0);
	const fRight = (num & (1 << 1)) == (1 << 1);
	const left = (num & (1 << 2)) == (1 << 2);
	const right = (num & (1 << 3)) == (1 << 3);
	const bck = (num & (1 << 4)) == (1 << 4);

	return [fLeft, fRight, left, right, bck];
}

function tShirtLogoArrayToInt(arr) {
	const fLeft = Number(arr[0]) << 0;
	const fRight = Number(arr[1]) << 1;
	const left = Number(arr[2]) << 2;
	const right = Number(arr[3]) << 3;
	const bck = Number(arr[4]) << 4;

	return fLeft | fRight | left | right | bck;
}

export {tShirtLogoArrayToInt, tShirtLogoIntToArray};

function TshirtLogoScreen({ history, location }) {


	const [frontLeft, setFrontLeftLogo] = useState(false);

	const [frontRight, setFronRightLogo] = useState(false);

	const [leftSide, setLeftSideLogo] = useState(false);

	const [rightSide, setRightSideLogo] = useState(false);

	const [back, setBackLogo] = useState(false);

	const dispatch = useDispatch()

	// const tenderAdd
	// = useSelector(state => state.tenderAdd)


	const userLogin = useSelector(state => state.userLogin)

	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			if (!userInfo.is_activated) {
				history.push('/services')
			}
		}

	}, [dispatch, history, userInfo])


	const checkboxHandler = (e) => {
		const img = e.target.parentNode.querySelector("img");
		const position = img.getAttribute("data-postion");

		img.style.display = img.style.display === "block" ? "none" : "block";

		if (position === "frontLeftLogo") {
			setFrontLeftLogo(img.style.display === "block" ? true : false)
		} else if (position === "frontRightLogo") {
			setFronRightLogo(img.style.display === "block" ? true : false)
		} else if (position === "leftSideLogo") {
			setLeftSideLogo(img.style.display === "block" ? true : false)
		} else if (position === "rightSideLogo") {
			setRightSideLogo(img.style.display === "block" ? true : false)
		} else {
			setBackLogo(img.style.display === "block" ? true : false)
		}
	};

	const sendData = (e) => {
		e.preventDefault();

		let data = new FormData();

		let tshirtLogoPosNumber = tShirtLogoArrayToInt([frontLeft, frontRight, leftSide, rightSide, back]);
		data.append('tshirt_logo_position', tshirtLogoPosNumber);
		data.append('pk', location.state.pk);
		
		axios.post(
			'/services/update_tender_logo_position/',
			data,
			{
				headers: { Authorization: `Bearer ${userInfo.access}` }
			}
		).then(() => {
			history.push('/confirmAddRequestForServices');
		}).catch(() => {
			alert('خطأ');
		})



	}

	return (
		<div className="tshirt_logo">
			<ControlPanelHeader
				exit1={true}
				notifi={true}
				msg={true}
				files={false}
				stat={false}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				exitDest={"/services"}
				fileDest={"/FacilityFiles"}
				statDest={'/collectServices'}
				style={"threetwo_section"}
			/>

			<div className="tshirt_logo_content">
				<p className="title-info text-center">تحديد مكان الشعار</p>
				<div className="logo_content">
					<div className="logo_item">
						<div className="logo_img">
							<img src={frontleft_logo} alt="" />
							{/* <img src={Logo} alt="" className="logo_custom5"/> */}
						</div>
						<div className="logo_title">الشعار على اليسار بالواجهه</div>

						<div className="remember-me">
							<span onClick={checkboxHandler}></span>
							<img
								data-postion="frontLeftLogo"
								onClick={checkboxHandler}
								src={check}
								alt="check"
							/>
						</div>
					</div>
					<div className="logo_item">
						<div className="logo_img">
							<img src={frontright_logo} alt="" />
							{/* <img src={Logo} alt="" className="logo_custom4"/> */}
						</div>
						<div className="logo_title">الشعار على اليمين بالواجهه</div>
						<div className="remember-me">
							<span onClick={checkboxHandler}></span>
							<img
								data-postion="frontRightLogo"
								onClick={checkboxHandler}
								src={check}
								alt="check"
							/>
						</div>
					</div>
					<div className="logo_item">
						<div className="logo_img">
							<img src={leftside_logo} alt="" />
							{/* <img src={Logo} alt="" className="logo_custom3"/> */}
						</div>
						<div className="logo_title">الشعار على الكتف الايسر</div>
						<div className="remember-me">
							<span onClick={checkboxHandler}></span>
							<img
								data-postion="leftside_logo"
								onClick={checkboxHandler}
								src={check}
								alt="check"
							/>
						</div>
					</div>
					<div className="logo_item">
						<div className="logo_img">
							<img src={rightside_logo} alt="" />
							{/* <img src={Logo} alt="" className="logo_custom2"/> */}
						</div>
						<div className="logo_title">الشعار على الكتف الايمين</div>
						<div className="remember-me">
							<span onClick={checkboxHandler}></span>
							<img
								data-postion="leftSideLogo"
								onClick={checkboxHandler}
								src={check}
								alt="check"
							/>
						</div>
					</div>
					<div className="logo_item">
						<div className="logo_img">
							<img src={back_logo} alt="" />
							{/* <img src={Logo} alt="" className="logo_custom"/> */}
						</div>
						<div className="logo_title">الشعار على الخلفية</div>
						<div className="remember-me">
							<span onClick={checkboxHandler}></span>
							<img
								data-postion="back_logo"
								onClick={checkboxHandler}
								src={check}
								alt="check"
							/>
						</div>
					</div>
				</div>
				<div
					className="button_botom"
					style={{ width: "170px", height: "100px" }}
				>
					<button onClick={sendData} className="enter_service">
						<img className="icon" src={arrow} alt="" />
						اضف العرض
					</button>
				</div>
			</div>
		</div>


	);
}

export default TshirtLogoScreen

