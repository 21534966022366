import axios from 'axios'
import {
    FACILITY_INFO_REGISTER_REQUEST,
    FACILITY_INFO_REGISTER_SUCCESS,
    FACILITY_INFO_REGISTER_FAIL,

    FACILITY_IMAGE_REGISTER_REQUEST,
    FACILITY_IMAGE_REGISTER_SUCCESS,
    FACILITY_IMAGE_REGISTER_FAIL,

    FACILITY_OWNER_REGISTER_REQUEST,
    FACILITY_OWNER_REGISTER_SUCCESS,
    FACILITY_OWNER_REGISTER_FAIL,

    FACILITY_EMPLOYEE_REGISTER_REQUEST,
    FACILITY_EMPLOYEE_REGISTER_SUCCESS,
    FACILITY_EMPLOYEE_REGISTER_FAIL,

    FACILITY_TYPE_REQUEST,
    FACILITY_TYPE_SUCCESS,
    FACILITY_TYPE_FAIL,

    FACILITY_DETAILS_REQUEST,
    FACILITY_DETAILS_SUCCESS,
    FACILITY_DETAILS_FAIL,

    FACILITY_PAYMENT_BANK_REQUEST,
    FACILITY_PAYMENT_BANK_SUCCESS,
    FACILITY_PAYMENT_BANK_FAIL,

    FACILITY_LIST_REQUEST,
    FACILITY_LIST_SUCCESS,
    FACILITY_LIST_FAIL,
} from '../constants/facilityConstants'


export const facilityRegister = (facilityData) => async (dispatch, getState) => {
    try{
        dispatch({
            type: FACILITY_INFO_REGISTER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.post(
            `/api/users/facility/`,facilityData,
            config
            
        )

        dispatch({
            type: FACILITY_INFO_REGISTER_SUCCESS,
            payload: data
        })

        localStorage.setItem('facility', JSON.stringify(data))

    }catch(error){
        dispatch({ 
            type: FACILITY_INFO_REGISTER_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const facilityImageAdd = (facilityImageData) => async (dispatch, getState) => {
    try{
        dispatch({
            type: FACILITY_IMAGE_REGISTER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.post(
            `/api/users/add_facility_files/`,facilityImageData,
            config
            
        )

        dispatch({
            type: FACILITY_IMAGE_REGISTER_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({ 
            type: FACILITY_IMAGE_REGISTER_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const facilityOwnerAdd = (facilityOwnerData) => async (dispatch, getState) => {
    try{
        dispatch({
            type: FACILITY_OWNER_REGISTER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.post(
            `/api/users/add_facility_owner/`,facilityOwnerData,
            config
            
        )

        dispatch({
            type: FACILITY_OWNER_REGISTER_SUCCESS,
            payload: data
        })

        localStorage.setItem('facilityOwner', JSON.stringify(data))

    }catch(error){
        dispatch({ 
            type: FACILITY_OWNER_REGISTER_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const facilityEmployeeAdd = (facilityEmployeeData) => async (dispatch, getState) => {
    try{
        dispatch({
            type: FACILITY_EMPLOYEE_REGISTER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.post(
            `/api/users/add_facility_employee/`,facilityEmployeeData,
            config
            
        )

        dispatch({
            type: FACILITY_EMPLOYEE_REGISTER_SUCCESS,
            payload: data
        })

        localStorage.setItem('facilityEmployee', JSON.stringify(data))


    }catch(error){
        dispatch({ 
            type: FACILITY_EMPLOYEE_REGISTER_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getFacilityType = () => async (dispatch, getState) => {
    try {
        dispatch({ type: FACILITY_TYPE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }


        const { data } = await axios.get(`/api/users/facility_type/`, config)

        dispatch ({
            type: FACILITY_TYPE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({ 
            type: FACILITY_TYPE_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const facilityPaymentBankAdd = (facilityPayementBankData) => async (dispatch, getState) => {
    try{
        dispatch({
            type: FACILITY_PAYMENT_BANK_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.post(
            `/api/users/add_facility_subscribe/`,facilityPayementBankData,
            config
            
        )

        dispatch({
            type: FACILITY_PAYMENT_BANK_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({ 
            type: FACILITY_PAYMENT_BANK_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const getFacilityDetails = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: FACILITY_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.get(
            `/api/users/facility_details/`,
            config            
        )

        dispatch({
            type: FACILITY_DETAILS_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({ 
            type: FACILITY_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listfacilities = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: FACILITY_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.get(`/api/users/facilities/${keyword}`, config)

        dispatch ({
            type: FACILITY_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({ 
            type: FACILITY_LIST_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}