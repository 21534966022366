import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import "../styles/subscription_payment.css";
import saveMoney from "../imgs/save-money.png";
import "../styles/subscription_payment_bank.css";
import complete from "../imgs/check1.png";
import upload from "../imgs/upload (1).png";
import arrow from "../imgs/arrow.png";
import wrongicon from "../imgs/wrong.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import err from '../imgs/381599_error_icon.png'
import { Modal, Button } from "react-bootstrap";



import ControlPanelHeader from '../component/ControlPanelHeader'
import Message from '../component/Message'
import {getFacilityType, facilityPaymentBankAdd} from '../actions/facilityActions'
import Loader from '../component/Loader'


function RenewSubscriptionPaymenBanktScreen({ history }) {

    const [photo, setPhoto] = useState(false)

    const [path, setPath] = useState('بعد اجراء التحويل ارجوا ارفاق صورة التحويل لإتمام عملية التسجيل')

    const [wrong, setWrong] = useState(false)

    const [fType, setFType] = useState({})

    const [show, setShow] = useState(true)
    
    const [errorsFlags, setErrorsFlags] = useState([ false])

    const [errors, setErrors] = useState("يرجى ارفاق صورة التحويل من فضلك"
    )

    const dispatch = useDispatch()

    const facilityTypeDetails
    = useSelector(state => state.facilityTypeDetails)

    const {facilityType } = facilityTypeDetails

    const facilityPaymentBank
    = useSelector(state => state.facilityPaymentBank)

    const {loading, error, success } = facilityPaymentBank
   
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
        if (!userInfo) {
            history.push('/')
        } else {
            dispatch(getFacilityType()) 
        }

    }, [dispatch, history, userInfo, success])

    const getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
      }
    
    const onUpload = (e) => {
        if (e.target.value) {
            const ext = getExtension(e.target.value);
            if (ext === "png" || ext === "jpg" || ext === "pdf") {
                setPhoto(true)
                setPath(e.target.files[0])
                setWrong(false)
            } else {
                setPhoto(false)
                setWrong(true)
            }
        }
      };

    const formValidation = () => {
    
        let isValid = true;
        const flags = [false];
    
        setErrorsFlags(flags)
        return isValid;
    };

    const onSubmit = (e) => {
        e.preventDefault()

        let data = new FormData();
        data.append("package_amount", (facilityType.amount).toFixed(2));

        data.append("payment_type", "تجديد");

        data.append("vat", (facilityType.amount * 0.15).toFixed(2));

        data.append("total_price", (facilityType.amount + 5 + facilityType.amount *0.15).toFixed(2));

        data.append("banking_pic", path);
        data.append("payment_method", "account_banking");
        data.append("facility_type", facilityType.facility_type);
        
        const isValid = formValidation();
        if (isValid) {
            setErrorsFlags(false);
            dispatch(facilityPaymentBankAdd(data))
            history.push('/ThanksSubscriptionAmount')
        }
    }

    return (
        <div>
            { loading
                ? (<Loader/>) 
                : error 
                ? (<Message variant='danger'>{error}</Message>)
                : (
                    <div>
                        <div className="bank">
                            <ControlPanelHeader exit1={true} notifi={true} msg={true} msgDest={"/Messages"}
                            notifidest={"/Notifications"} exitDest={"/"} style={"three_section"} />
                            <div className="subscription_payment_content">
                                <p className="heading"> اختيار عمليه الدفع </p>
                                <p className="sub-title">{facilityType.facility_type}</p>
                                <div style={{display:"flex"}}>
                                    <div className="price-content">
                                        <p className="price text-center">المبلغ</p>
                                        <p className="price_no p-1">{(facilityType.amount + facilityType.amount *0.15).toFixed(2)}</p>
                                        <p className="price_type text-center">ريال سعودي</p>
                                    </div>
                                    <div className="price_info">
                                        <div className="price_onfo_title">
                                        تفاصيل الطلب
                                        </div>

                                        <div className="price_info_item">
                                            <div className="info_title">اسم الباقة</div>
                                            <div className="info_value">{facilityType.facility_type}</div>
                                        </div>

                                        <div className="price_info_item">
                                            <div className="info_title">حالة الطلب</div>
                                            <div className="info_value">تجديد</div>
                                        </div>

                                        <div className="price_info_item">
                                            <div className="info_title">مبلغ الإشتراك</div>
                                            <div className="info_value"><span>{facilityType.amount} </span> <span className="coin"> ريال</span>   </div>
                                        </div>
                                    

                                        <div className="price_info_item">
                                            <div className="info_title text-end">VAT ضريبة القيمة المضافة</div>
                                            <div className="info_value"><span>{(facilityType.amount * 0.15).toFixed(2)} </span> <span className="coin"> ريال</span></div>
                                        </div>

                                        <div className="price_info_item">
                                            <div className="info_title">المجموع</div>
                                            <div className="info_value"><span>{(facilityType.amount + facilityType.amount *0.15).toFixed(2)} </span> <span className="coin"> ريال</span></div>
                                        </div>
                                    </div>
                                </div>
                                <p className="choose text-center">الدفع من خلال</p>
                            </div>
                            
                            <form onSubmit={onSubmit}>
                                <div className="bank_details">
                                    <div className="bank_title">
                                        <img src={saveMoney} alt="" />
                                        <p>حساب بنكي</p>
                                    </div>
                                    <div className="bank_desc">
                                    <p>
                                يمكنكم الان التحويل لحساب شركة بيان الصحة للخدمات التسويقيه أدناه
                                    </p>
                                    <p>اسم البنك مصرف الانماء</p>
                                    <p>شركة بيان الصحة للخدمات التسويقية</p>
                                    <p>
                                    {" "}
                                    SA02 0500 0068 2033 2621 0000{"\u00A0 "}:{"\u00A0 "}
                                    {"\u00A0 "}(IBN) {"\u00A0 "} رقم حساب الايبان{" "}
                                    </p>
                                    <p style={{ color: "red" }}>
                                بعد إتمام العملية سيتم ايصال استلام مبلغ تأكيد التحويل إلى بريدكم
                                الالكتروني
                                    </p>
                                </div>
                                <div className="input-content">
                                    <label htmlFor="upload-photo">
                                    {" "}
                                    <div className="input">
                                        <img src={upload} alt="" />
                                        <div></div>
                                    </div>{" "}
                                    </label>
                                    <input
                                    onChange={onUpload}
                                    type="file"
                                    name="photo"
                                    id="upload-photo"
                                    />
                                    {photo && <img className="complete" src={complete} alt="" />}
                                    {wrong && <AiOutlineCloseCircle  />}
                                </div>
                                {errorsFlags && <div className='error'><img src={error} />{errors}</div> }
                        
                                <button type="submit" className="send-btn">
                                    <img src={arrow} alt="" />
                                    ارسال
                                </button>
                            </div>
                        </form>
                        <Modal
                            style={{ direction: "rtl" }}
                            show={show}
                            onHide={() => setShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header style={{backgroundColor: 'var(--main-color)', justifyContent:'center'}}>
                                <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="h4">
                                سيتم إضافة 5 ريال إلى المجموع في حال الدفع عن طريق حساب بنكي
                            </Modal.Body>
                            <Modal.Footer>
                                <Link to="SubscriptionPayment">
                                <Button
                                    variant="danger"
                                    className="btn-red"
                                    onClick={() => setShow(false)}
                                >
                                    <span className="h4">تغيير طريقة الدفع</span>
                                </Button>
                                </Link>
                                <Button
                                variant="success"
                                className="btn-main px-5"
                                onClick={() => setShow(false)}
                                >
                                <span className="h4">مـوافـق</span>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            )}
      </div>
    )
}

export default RenewSubscriptionPaymenBanktScreen
