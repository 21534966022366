import { Row, Col, ToggleButton, Card, Form, Table } from "react-bootstrap";

function NewCard({
  img,
  title,
  price,
  weight,
  descreption,
  bigImage,
  radioValue,
  radioValueN,
  handleButtons,
  handleButtonsN,
  handleImages,
}) {
  return (
    <Card style={{ height: "250px" }}>
      <Row>
        <Col
          lg="12"
          className="border-start text-end new_card"
          style={{ margin: 0, padding: 0 }}
        >
          <Card.Body>
            <Card.Title>
              <h4 class="price text-small" style={{ fontSize: "1.1rem" }}>
                العنوان: {title}
              </h4>
            </Card.Title>
            {/* <div class="">
              <h4 class="price">
                السعر: {price}
                <span class="text-muted"> ريال </span>
                الوزن: {weight}
                <span class="text-muted"> كغ</span>
              </h4>
            </div> */}
            <Row className="mt-4">
              <Col lg="6">
                <Card.Title>
                  <h4 class="price text-small" style={{ fontSize: "0.9rem" }}>
                    متطلبات الدكتور بحال خارج منطقته:
                  </h4>
                </Card.Title>
                <hr />
                <p class="new_descreption" style={{ height: "120px" }}>
                  {descreption}
                </p>
              </Col>
              <Col lg="6" className="border-right">
                <Card.Title>
                  <h4 class="price text-small" style={{ fontSize: "0.9rem" }}>
                    المميزات:
                  </h4>
                </Card.Title>
                <hr />
                <p class="new_descreption" style={{ height: "120px" }}>
                  {descreption}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Col>
        {/* <Col lg="6" style={{ margin: 0, padding: 0 }}>
          <div class="gallery-wrap">
            <img
              class="img-big-wrap"
              alt="img"
              src={bigImage}
              style={{ left: 0 }}
            />
            <div class="thumbs-wrap">
              {img.map((key) => {
                return (
                  <a onClick={() => handleImages(key)}>
                    <img class="item-thumb" alt="img" src={key} />
                  </a>
                );
              })}
            </div>
          </div>
        </Col> */}
      </Row>
    </Card>
  );
}
export default NewCard;
