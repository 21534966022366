import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

const NavGroup = ({ layout, group }) => {
    let navItems = '';
    
	const { userInfo } = useSelector(state => state.userLogin);


    if (group.children) {
        const groups = group.children;
        navItems = Object.keys(groups).map(item => {
            item = groups[item];

            // if user is a client
            if ((userInfo.user.startsWith('4') && item?.forbidden?.includes('client')) ||
                (userInfo.user.startsWith('1') && item?.forbidden?.includes('company'))) {
                return false;
            }
            switch (item.type) {
                case 'collapse':
                    return <NavCollapse key={item.id} collapse={item} type="main" />;
                case 'item':
                    return <NavItem layout={layout} key={item.id} item={item} />;
                default:
                    return false;
            }
        });
    }

    return (
        <React.Fragment>
            <ListGroup.Item as='li' bsPrefix=' ' key={group.id} className="nav-item pcoded-menu-caption"><label>{group.title}</label></ListGroup.Item>
            {navItems}
        </React.Fragment>
    );
};

export default NavGroup;
