import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import { Row, Col, Card, Table, Form, Button, Modal } from "react-bootstrap";
import "../styles/TableNewDesign.css";
import Paginate from "../component/Paginate";
import { Link } from "react-router-dom";
import Message from "../component/Message";
import Loader from "../component/Loader";

import { AddUpgradeEvaluation, UpdateEvaluation, updateMedicalTeam } from '../actions/serviceActions';

import { listAllEvaluationTeamForAdmin } from "../actions/adminActions";


function RateMedicalNetworks({ history }) {
    const [selectValue, setSelectValue] = useState("");
    const [show, setShow] = useState(false);
    const [health, setHealth] = useState([
        {
            client_code: "OP-0001",
            facility_name: "شراء",
            sector: { sector_name: "خدمات بيع الاجهزة الطبية" },
            sector_type: { type_name: "أجل" },
            facility_owners: [{ name: "غير مدفوعة" }],
            is_deleted: false,
            approved: false,
        },
        {
            client_code: "OP-0001",
            facility_name: "شراء",
            sector: { sector_name: "خدمات بيع الاجهزة الطبية" },
            sector_type: { type_name: "أجل" },
            facility_owners: [{ name: "غير مدفوعة" }],
            is_deleted: false,
            approved: false,
        },
        {
            client_code: "OP-0001",
            facility_name: "شراء",
            sector: { sector_name: "خدمات بيع الاجهزة الطبية" },
            sector_type: { type_name: "أجل" },
            facility_owners: [{ name: "غير مدفوعة" }],
            is_deleted: false,
            approved: false,
        },
    ]);

    const dispatch = useDispatch();

    const evaluationAllListForAdmin = useSelector(
        (state) => state.evaluationAllListForAdmin
    );
    const { loading, error, evaluations, page, pages } = evaluationAllListForAdmin;

    const medicalTeamUpdate = useSelector((state) => state.medicalTeamUpdate);
    const {
        error: errorUpdate,
        loading: loadingUpdate,
        success: successUpdate,
    } = medicalTeamUpdate;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    let keyword = history.location.search;

    useEffect(() => {
        if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
            dispatch(listAllEvaluationTeamForAdmin(keyword));
        } else {
            history.push("/");
        }
    }, [dispatch, history, userInfo, keyword, successUpdate]);

    const more = {
        title: "الاجراءات",
        actions: {
            تفعيل: "approve",
            طباعة: "/admin/print-facility",
            تعديل: "/admin/update-facility-info",
            حذف: "delete",
        },
    };

    const handleChange = (e) => {
        setSelectValue(e.target.value);
    };


    const handleUpdate = (id, approved) => {
        let data = new FormData();
        data.append("approved", approved);
        dispatch(UpdateEvaluation(data, id));
    };


    return (
        <AdminLayout>
            <Row>
                <Col xl={12} md={12}>
                    {errorUpdate && <Message varient="danger">{errorUpdate}</Message>}
                    {loading ? (
                        <Loader />
                    ) : error ? (
                        <Message varient="danger">{error}</Message>
                    ) : (
                        <Card className="code-table">
                            <Card.Header>
                                <Card.Title as="h5">تقييم الشبكات الطبية</Card.Title>
                            </Card.Header>
                            <Card.Body className="pb-0">
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th>كود المنشأة</th>
                                            <th>إسم المنشأة</th>
                                            <th>القطاع</th>
                                            <th>نوع القطاع</th>
                                            <th> الحالة</th>
                                            <th>الإجراء</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {evaluations.map((obj) => (
                                            <tr key={obj.id}>
                                                <td>
                                                    <p className="mb-1">{obj.facility.client_code}</p>
                                                </td>
                                                <td>
                                                    <p className="mb-1">{obj.facility.facility_name}</p>
                                                </td>
                                                <td>
                                                    <p className="mb-1">{obj.facility.sector.sector_name}</p>
                                                </td>
                                                <td>
                                                    <p className="mb-1">{obj.facility.sector_type.type_name}</p>
                                                </td>

                                                <td>
                                                    <Button
                                                        variant={
                                                            obj.is_deleted === true
                                                                ? "danger"
                                                                : obj.approved === true
                                                                    ? "success"
                                                                    : "warning"
                                                        }
                                                        className={
                                                            obj.is_deleted === true
                                                                ? "label btn-danger btn-sm f-12 text-white"
                                                                : obj.approved === true
                                                                    ? "label btn-success btn-sm f-12 text-white"
                                                                    : "label btn-warning btn-sm f-12 text-white"
                                                        }
                                                    >
                                                        {obj.is_deleted === true
                                                            ? "تم حذفه"
                                                            : obj.approved === true
                                                                ? "مفعل"
                                                                : "غير مفعل"}
                                                    </Button>
                                                </td>

                                                {more && !obj.is_deleted ? (
                                                    <td>
                                                        <Row className="m-2 w-100">
                                                            <Form.Select
                                                                as="select"
                                                                onChange={handleChange}
                                                                className="text-center px-1 pe-2"
                                                            >
                                                                <option>أختر</option>
                                                                {!obj.approved && (
                                                                    <option value={`approve/${obj.id}`}>
                                                                        تفعيل
                                                                    </option>
                                                                )}
                                                                <option
                                                                    value={`/admin/update-facility-info/${obj.id}`}
                                                                >
                                                                    ايقاف
                                                                </option>
                                                                <option
                                                                    value={`/admin/networks/print/${obj.id}`}
                                                                >
                                                                    طباعة التفاصيل
                                                                </option>
                                                                <option
                                                                    value={`/evaluation-certificate/${obj.facility.id}`}
                                                                >
                                                                    طباعة الشهادة
                                                                </option>
                                                                {!obj.is_deleted && (
                                                                    <option value={`delete/${obj.id}`}>
                                                                        حذف
                                                                    </option>
                                                                )}
                                                            </Form.Select>
                                                            {selectValue.includes(
                                                                "/editMedicalOrTechnicalTeam"
                                                            ) ? (
                                                                <Button
                                                                    onClick={() => setShow(true)}
                                                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                                                >
                                                                    تنفيذ
                                                                </Button>
                                                            ) : selectValue.includes(
                                                                "/admin/networks/print/"
                                                            ) ? (
                                                                <Link
                                                                    to={selectValue}
                                                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                                                >
                                                                    تنفيذ
                                                                </Link>
                                                            ) : selectValue.includes(
                                                                "/evaluation-certificate"
                                                            ) ? (
                                                                <Link
                                                                    to={selectValue}
                                                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                                                >
                                                                    تنفيذ
                                                                </Link>
                                                            ) : selectValue.includes("delete") ? (
                                                                <Button
                                                                    onClick={() => setShow(true)}
                                                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                                                >
                                                                    تنفيذ
                                                                </Button>
                                                            ) : selectValue.includes("approve") ? (
                                                                <Button
                                                                    onClick={() => handleUpdate(obj.id, true)}
                                                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                                                >
                                                                    تنفيذ
                                                                </Button>
                                                            ) : selectValue.includes("update") ? (
                                                                <Button
                                                                    onClick={() => handleUpdate(obj.id, false)}
                                                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                                                >
                                                                    تنفيذ
                                                                </Button>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Row>
                                                    </td>
                                                ) : (
                                                    ""
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>
            <Paginate
                page={page}
                pages={pages}
                keyword={keyword}
                link="/comparingMedicalMistakeWork"
            />
        </AdminLayout>
    );
}

export default RateMedicalNetworks;
