import "../../styles/thanks_msg.css";
import "../../styles/thanks_subscriptionamount.css";

import ControlPanelHeader from "../../component/ControlPanelHeader";
import email from "../../imgs/email.png";
import arrow from "../../imgs/arrow.png";
import { Link } from "react-router-dom";

function ThanksSubscribInsuranceCompanyScreen ({history}) {
  
    return (
      <div className="thanks_SA">
        <ControlPanelHeader exit1={true} notifi={true} msg={true} msgDest={"/Messages"} notifidest={"/Notifications"} exitDest={"/"} style={"three_section"} />
        <div className='thans_SA_content'>
          <div className="msg_content text-center">
            <img className='email-img' src={email} alt="" />
            <p style={{marginTop:'27px' , color:'var(--red-color)',fontSize:'36px',fontWeight:'bold'}}>مجاني</p>
            <p className="thanks" style={{ width: "380px" }}>! شكرًا لإشتراككم</p>
            <p className="txt" style={{ width: "382px", left: "131px" }}>يسر منصة بيان ان تقدم خدمه الاشتراك لشركات التامين بلا مقابل </p>
            <Link to='/ServiceInsuranceCompany'>
              <button className="back-btn">
                <img className='icon' src={arrow} alt="" />
                  الذهاب لوحة التحكم
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
}

export default ThanksSubscribInsuranceCompanyScreen;
