import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import Add_ClientData_Manual from "../component/powers/Add_ClientData_Manual";

function AdminClientManualData({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div
              style={{ width: "300px", textAlign: "center" }}
              className="heading"
            >
              الصلاحيات
            </div>

            <Add_ClientData_Manual />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminClientManualData;
