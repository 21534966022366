import React, { Component } from 'react'
import '../styles/thanks_msg.css'
import email from '../imgs/email.png'
import LoginName from './LoginName'
import { Link } from 'react-router-dom'

class ThankPassword extends Component {
    constructor(props){
        super(props)

        this.state = {
            dest: ''
        }
    }

    componentDidMount(){
        if(this.props.location.state){
          localStorage.setItem('dest', this.props.location.state.dest);
          this.setState({
            dest: localStorage.getItem('dest')
          })
        } else{
          this.setState({
            dest: localStorage.getItem('dest')
          })
        }
    }

    render() {
        const obj ={
            pathname : '/',
            param1: this.state.dest
        }
        return (
            <div className='thank-msg'>
                <div className='msg-content text-center' style={{height: "531px", borderRadius:0}}>
                    <img src={email} alt="" />
                    <p className='thanks text-center'>! شكرًا </p>
                    <p className='txt'>تم تغير كلمة المرور بنجاح</p>
                    <Link to={obj}>
                    <button className="login-btn">الدخول لاكمال عملية التسجيل</button>
                    </Link>
                </div>             
                <LoginName Home={false} Facility={false}/>
           
            </div>
        )
    }
}

export default ThankPassword
