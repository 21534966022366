import "../styles/thanks_msg.css";
import "../styles/thanks_subscriptionamount.css";

import ControlPanelHeader from "./ControlPanelHeader";
import email from "../imgs/email.png";
import arrow from "../imgs/arrow.png";
import { Link } from "react-router-dom";

function ThanksEvaluation ({}) {

     
    return (
      <div className="thanks_SA">
      <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={true}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest = {'/collectServices'}
        style={"three_section"}
      />
       <div className='thans_SA_content'>
         <div className="msg_content text-center" style ={{ height:"300px" }}>
             <p className="thanks">
             ! شكرًا 
             </p>
             <p className="txt" style={{ width: "540px", left: "131px" }}>
             نشكركم على إرسال تقييمكم, سنقوم بمراجعة التقييم والملفات المرفقة ومن ثم إرسال رابط على بريدكم الإلكتروني للإطلاع على شهادتكم وتحميلها
             </p>
             <Link to='/services'>
              <button className="back-btn">
                      <img className='icon' src={arrow} alt="" />
                      عودة إلى صفحة الخدمات
                  </button>
              </Link>
           </div>
       </div>
     </div>
    );
  }

export default ThanksEvaluation;

