import axios from "axios";

export const fetchInvoices = async (user) => {
  const request = await axios.get("/management/invoices/", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const deleteInvoice = async (id, user) => {
  const request = await axios.delete(`/management/invoices/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};
