import { HashRouter as Router, Route } from "react-router-dom";

import InvoiceAllType from "./invoiceAllType/invoiceAllType";
import AdminNewBillsCleaning from "./AdminNewBillsCleaning";
import AdminNewBillsFood from "./AdminNewBillsFood";
import AdminNewBillsJob from "./AdminNewBillsJob";
import AdminNewBillsLabs from "./AdminNewBillsLabs";
import AdminNewBillsLaundry from "./AdminNewBillsLaundry";
import AdminNewBillsLibrary from "./AdminNewBillsLibrary";
import AdminNewBillsMedicalDevices from "./AdminNewBillsMedicalDevices";
import AdminNewBillsOffice from "./AdminNewBillsOffice";
import AdminNewBillsOperating from "./AdminNewBillsOperating";
import AdminNewBillsRateNetwrok from "./AdminNewBillsRateNetwrok";
import AdminNewBillsRays from "./AdminNewBillsRays";
import AdminNewBillsTransportation from "./AdminNewBillsTransportation";
import AdminNewBillsUniform from "./AdminNewBillsUniform";
import AdminNewBillsComparigPrices from "./AdminNewBillsComparigPrices";
import AdminNewBillsCollecting from "./AdminNewBillsCollecting";
import AdminNewBillsMedicalRent from "./AdminNewMedicalRent";
import Dashboard from "./Dashboard";
import BayanEmployeesPermessions from "./BayanEmployeesPermessions";
import MedicalTeamInsurrenceRequest from "./MedicalTeamInsurrenceRequest";
import MedicalTeamInformation from "./MedicalTeamInformation";
import FacilityInformation from "./FacilityInformation";
import MedicalNetworkAssessment from "./StatMedicalNetworkAssessment";
import Rentalmedicaldevices from "./StatRentalmedicaldevices";
import RentingDoctorsClinics from "./StatRentingDoctorsClinics";
import NewBillsAdmin from "./AdminNewBills";
import OldBillsAdmin from "./AdminOldBills";
import ComoarisonMedicalInsurance from "./StatComparisonMedicalInsurance";
import Achievement from "./StatAchievement";
import OperatingLaboratoryContracts from "./StatOperatingLaboratoryContracts";
import OperatingRadiologyContracts from "./StatOrganizingRadiologyContracts";
import OrganizingSupplyTenders from "./StatOrganizingSupplyTenders";
import AddEmployee from "./AdminAddEmployee";
import AdminAddNewSection from "./AdminAddNewSection";
import Messages from "./Messages";
import Notifications from "./Notifications";
import AllMedicalTeam from "./AllMedicalTeam";
import AdminEditEmployee from "./AdminEditEmployee";
import AdminManagersPage from "./AdminMangers";
import RateMedicalNetworks from "./RateMedicalNetworks";

import OffersCleaning from "./offers/tenders/OffersCleaning";
import OffersFood from "./offers/tenders/OffersFood";
import OffersJobs from "./offers/tenders/OffersJobs";
import OffersLaundry from "./offers/tenders/OffersLaundry";
import OffersLibrary from "./offers/tenders/OffersLibrary";
import OffersOffice from "./offers/tenders/OffersOffice";
import OffersOperating from "./offers/tenders/OffersOperating";
import OffersTransportation from "./offers/tenders/OffersTransportation";
import OffersUniform from "./offers/tenders/OffersUniform";

import OffersCollecting from "./offers/OffersCollecting";
import OffersComparingPrices from "./offers/OffersComparingPrices";
import OffersLabs from "./offers/OffersLabs";
import OffersRateNetworks from "./offers/OffersRateNetworks";
import OffersRay from "./offers/OffersRay";
import OffersRentingMedical from "./offers/OffersRentingMedical";
import OffersSellingMedicalDevices from "./offers/OffersSellingMedicalDevices";

import ReqDevices from "./offers/request-tables/devices";
import ReqClinics from "./offers/request-tables/clinics";
import reqRay from "./offers/request-tables/ray";
import ReqLab from "./offers/request-tables/lab";
import ReqCollecting from "./offers/request-tables/collecting";
import ReqTender from "./offers/request-tables/tender";
import PrintReqDevices from "./offers/print/devices/newOrder";
import DownloadClientEvaluationCertificate from './DownloadClientEvaluationCertificate';

const Routes = () => (
  <Router>
    {/* *****************Admin Dashboard ******************* */}

    <Route exact path="/client" component={Dashboard} />

    <Route exact path='/client/download-evaluation-certificate' component={DownloadClientEvaluationCertificate} />

    <Route
      exact
      path="/client/bayan-employees-permessions"
      component={BayanEmployeesPermessions}
    />
    <Route exact path="/client/all-medical-team" component={AllMedicalTeam} />
    <Route
      exact
      path="/client/medical-team-insurrence-request"
      component={MedicalTeamInsurrenceRequest} // done by morad (طلبات التأمين)
    />
    <Route
      exact
      path="/client/print-medical-technical-team/:id" // done by morad (الطاقم الفني والإداري)
      component={MedicalTeamInformation}
    />
    <Route
      exact
      path="/client/print-facility/:id"
      component={FacilityInformation}
    />
    <Route
      exact
      path="/client/medical-network-assessment"
      component={MedicalNetworkAssessment}
    />
    <Route
      exact
      path="/client/rental-medical-devices"
      component={Rentalmedicaldevices}
    />
    <Route
      exact
      path="/client/renting-doctors-clinics"
      component={RentingDoctorsClinics}
    />
    <Route
      exact
      path="/client/comoarison-medical-insurance"
      component={ComoarisonMedicalInsurance}
    />
    <Route exact path="/client/achievement" component={Achievement} />
    <Route
      exact
      path="/client/operating-laboratory-contracts"
      component={OperatingLaboratoryContracts}
    />
    <Route
      exact
      path="/client/operating-radiology-contracts"
      component={OperatingRadiologyContracts}
    />
    <Route
      exact
      path="/client/organizing-supply-tenders"
      component={OrganizingSupplyTenders}
    />
    <Route exact path="/client/new-bills-admin" component={NewBillsAdmin} />
    <Route exact path="/client/old-bills-admin" component={OldBillsAdmin} />
    <Route exact path="/client/add-employee" component={AddEmployee} />
    <Route exact path="/client/managers" component={AdminManagersPage} />
    <Route
      exact
      path="/client/rate-medical-networks"
      component={RateMedicalNetworks}
    />

    {/* ***************** Offers of the admin Page ****************** */}
    <Route
      exact
      path="/client/offers/tenders/cleaning"
      component={OffersCleaning}
    />
    <Route exact path="/client/offers/tenders/food" component={OffersFood} />
    <Route exact path="/client/offer/tenders/jobs" component={OffersJobs} />
    <Route
      exact
      path="/client/offer/tenders/laundry"
      component={OffersLaundry}
    />
    <Route
      exact
      path="/client/offer/tenders/library"
      component={OffersLibrary}
    />
    <Route exact path="/client/offer/tenders/office" component={OffersOffice} />
    <Route
      exact
      path="/client/offer/tenders/operating"
      component={OffersOperating}
    />
    <Route
      exact
      path="/client/offer/tenders/transportation"
      component={OffersTransportation}
    />
    <Route
      exact
      path="/client/offer/tenders/uniform"
      component={OffersUniform}
    />
    <Route exact path="/client/offer/collecting" component={OffersCollecting} />
    <Route
      exact
      path="/client/offer/comparing-prices"
      component={OffersComparingPrices}
    />
    <Route exact path="/client/offer/labs" component={OffersLabs} />
    <Route
      exact
      path="/client/offer/rate-networks"
      component={OffersRateNetworks}
    />
    <Route exact path="/client/offer/rays" component={OffersRay} />
    <Route
      exact
      path="/client/offer/renting-medical"
      component={OffersRentingMedical}
    />
    <Route
      exact
      path="/client/offer/selling-medical-devices"
      component={OffersSellingMedicalDevices}
    />
 

    <Route
      exact
      path="/client/edit-employee/:id"
      component={AdminEditEmployee}
    />

    <Route
      exact
      path="/client/new-bills/comparing-prices"
      component={AdminNewBillsComparigPrices}
    />
    <Route exact path="/client/new-bills/labs" component={AdminNewBillsLabs} />
    <Route
      exact
      path="/client/new-bills/medical-devices"
      component={AdminNewBillsMedicalDevices}
    />
    <Route
      exact
      path="/client/new-bills/rate-network"
      component={AdminNewBillsRateNetwrok}
    />
    <Route exact path="/client/new-bills/rays" component={AdminNewBillsRays} />
    <Route
      exact
      path="/client/new-bills/collecting"
      component={AdminNewBillsCollecting}
    />
    <Route
      exact
      path="/client/new-bills/medical-rent"
      component={AdminNewBillsMedicalRent}
    />
    <Route
      exact
      path="/client/new-bills/tenders/food"
      component={AdminNewBillsFood}
    />
    <Route
      exact
      path="/client/new-bills/tenders/cleaning"
      component={AdminNewBillsCleaning}
    />
    <Route
      exact
      path="/client/new-bills/tenders/job"
      component={AdminNewBillsJob}
    />
    <Route
      exact
      path="/client/new-bills/tenders/laundry"
      component={AdminNewBillsLaundry}
    />
    <Route
      exact
      path="/client/new-bills/tenders/library"
      component={AdminNewBillsLibrary}
    />
    <Route
      exact
      path="/client/new-bills/tenders/office"
      component={AdminNewBillsOffice}
    />
    <Route
      exact
      path="/client/new-bills/tenders/operating"
      component={AdminNewBillsOperating}
    />
    <Route
      exact
      path="/client/new-bills/tenders/transportation"
      component={AdminNewBillsTransportation}
    />
    <Route
      exact
      path="/client/new-bills/tenders/uniform"
      component={AdminNewBillsUniform}
    />
    <Route
      exact
      path="/client/request-table/devices/:id"
      component={ReqDevices}
    />
    <Route
      exact
      path="/client/request-table/clinics/:id"
      component={ReqClinics}
    />
    <Route exact path="/client/request-table/ray/:id" component={reqRay} />

    <Route exact path="/client/request-table/lab/:id" component={ReqLab} />
    <Route
      exact
      path="/client/request-table/collecting/:id"
      component={ReqCollecting}
    />
    <Route
      exact
      path="/client/request-table/tender/:id"
      component={ReqTender}
    />

    <Route
      exact
      path="/client/offers/print/devices/:id"
      component={PrintReqDevices}
    />
  </Router>
);

export default Routes;
