import { Table } from "react-bootstrap";

function Hdate({ date, code, className, codeTitle }) {
  return (
    <Table
      borderless
      className={`${className ? className : "Hdate_table"} text-start`}
    >
      <tbody>
        <tr>
          <td>التاريخ:</td>
          <td className="border-bottom border-dark">{date}</td>
        </tr>
        <tr>
          <td>{codeTitle}</td>
          <td className="border-bottom border-dark">{code}</td>
        </tr>
      </tbody>
    </Table>
  );
}
export default Hdate;
