import {
    MEDICAL_TEAM_LIST_FOR_ADMIN_REQUEST,
    MEDICAL_TEAM_LIST_FOR_ADMIN_SUCCESS,
    MEDICAL_TEAM_LIST_FOR_ADMIN_FAIL,

    MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_REQUEST,
    MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_SUCCESS,
    MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_FAIL,

    FACILITY_LIST_FOR_ADMIN_REQUEST,
    FACILITY_LIST_FOR_ADMIN_SUCCESS,
    FACILITY_LIST_FOR_ADMIN_FAIL,

    FACILITY_DETAILS_FOR_ADMIN_REQUEST,
    FACILITY_DETAILS_FOR_ADMIN_SUCCESS,
    FACILITY_DETAILS_FOR_ADMIN_FAIL,

    FACILITY_UPDATE_FOR_ADMIN_REQUEST,
    FACILITY_UPDATE_FOR_ADMIN_SUCCESS,
    FACILITY_UPDATE_FOR_ADMIN_FAIL,

    INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_REQUEST,
    INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_SUCCESS,
    INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_FAIL,

    OFFER_DETAILS_FOR_ADMIN_REQUEST,
    OFFER_DETAILS_FOR_ADMIN_SUCCESS,
    OFFER_DETAILS_FOR_ADMIN_FAIL,

    EVALUATION_DETAILS_FOR_ADMIN_REQUEST,
    EVALUATION_DETAILS_FOR_ADMIN_SUCCESS,
    EVALUATION_DETAILS_FOR_ADMIN_FAIL,

    EVALUATION_LIST_FOR_ADMIN_REQUEST,
    EVALUATION_LIST_FOR_ADMIN_SUCCESS,
    EVALUATION_LIST_FOR_ADMIN_FAIL,

} from '../constants/adminConstants'

import axios from 'axios'

export const listAllMedicalTeamForAdmin = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: MEDICAL_TEAM_LIST_FOR_ADMIN_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/all_medical_team_for_admin${keyword}`, config)

        dispatch({
            type: MEDICAL_TEAM_LIST_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_LIST_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listInsurrenceRequestForAdmin = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/medical_team_insurrence_request_for_admin/${keyword}`, config)

        dispatch({
            type: MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listAllFacilitiesForAdmin = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: FACILITY_LIST_FOR_ADMIN_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/api/users/all_facilities_for_admin/${keyword}`, config)

        dispatch({
            type: FACILITY_LIST_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FACILITY_LIST_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getFacilityDetailsForAdmin = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FACILITY_DETAILS_FOR_ADMIN_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            `/api/users/facility_details_for_admin/${id}`,
            config
        )

        dispatch({
            type: FACILITY_DETAILS_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FACILITY_DETAILS_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateFacilityInfoForAdmin = (facility, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FACILITY_UPDATE_FOR_ADMIN_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/api/users/update_facility_info_for_admin/${id}`,
            facility,
            config
        )

        dispatch({
            type: FACILITY_UPDATE_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FACILITY_UPDATE_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateInsurrenceRequestForAdmin = (insurrenceRequest, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.put(
            `/services/update_insurrence_request_for_admin/${id}`,
            insurrenceRequest,
            config
        )

        dispatch({
            type: INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getOfferDetailsForAdmin = (id, typeId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OFFER_DETAILS_FOR_ADMIN_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            `/services/offer_details_for_admin/${id}/${typeId}`,
            config
        )

        dispatch({
            type: OFFER_DETAILS_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: OFFER_DETAILS_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getEvaluationDetailsForAdmin = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EVALUATION_DETAILS_FOR_ADMIN_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(
            `/services/evaluation_details_for_admin/${id}`,
            config
        )

        dispatch({
            type: EVALUATION_DETAILS_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: EVALUATION_DETAILS_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listAllEvaluationTeamForAdmin = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: EVALUATION_LIST_FOR_ADMIN_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const { data } = await axios.get(`/services/all_evaluation_for_admin${keyword}`, config)

        dispatch({
            type: EVALUATION_LIST_FOR_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: EVALUATION_LIST_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
