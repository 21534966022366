import { Link, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import "../../styles/cp_services_online.css";
import DescriptionServiceScreen from "./DescriptionServiceScreen"

import ControlPanelHeader from '../../component/ControlPanelHeader'
import { getFacilityDetails } from '../../actions/facilityActions'


function OnlineServiceInsuranceCompanyScreen({ history }) {

  const [title, setTitle] = useState('')

  const [subTitle, setSubTitle] = useState('')

  const [choosePara, setChoosePara] = useState('')

  const [dest, setDest] = useState('')

  const dispatch = useDispatch()

  const facilityDetails = useSelector(state => state.facilityDetails)

  const { facilityInfo, success, loading } = facilityDetails

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch(getFacilityDetails())
    if (userInfo) {

      if (!loading) {
        console.log(facilityInfo)
        if (userInfo) {
          userInfo.is_activated = facilityInfo?.user?.is_verified;
          userInfo.is_registered = facilityInfo?.user?.is_registered;
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        if (facilityInfo?.sector_type?.pk !== 28 || !userInfo?.is_activated) {

          history.push('/services')
        }
      }
    } else {
      history.push('/')
    }



  }, [dispatch, history, userInfo, success])


  const changeDesign = (e) => {
    const nodes = e.target.parentNode.parentNode.querySelectorAll(".card-item");

    nodes.forEach((element) => {
      element.classList.remove("active");
      element.querySelectorAll('path').forEach(ele => {
        ele.setAttribute('fill', '#0fb0a9')
      });
    });

    e.target.classList.add("active");

    e.target.querySelectorAll('path').forEach(ele => {
      ele.setAttribute('fill', '#fff')
    })

    setTitle(e.target.childNodes[1].outerText)

    setSubTitle("الفئات المستهدفة")

    setChoosePara(e.target.getAttribute("data-para"))

    setDest(e.target.getAttribute("data-dest"))

  };


  return (
    <div>
      <ControlPanelHeader
        exit1={true}
        controlpanle={false}
        notifi={true}
        msg={true}
        exitDest={"/"}
        msgDest={"/messages"}
        notifidest={"/Notifications"}
        fileDest={"/client"}
        style={"three_section"}
      />
      <div className="cp_services_online">
        <div className="container">
          <h1 className="heading text-center">الخدمات الإلكترونية لشركة التأمين</h1>
          <div className="cards text-center">

            <Link to="/ServiceInsuranceCompany/service">
              <div
                onClick={changeDesign}
                className="card-item"
                data-para="2"
                data-dest="/Orders"
              >
                <svg
                  id="exchange"
                  xmlns="http://www.w3.org/2000/svg"
                  width="71.667"
                  height="71.667"
                  viewBox="0 0 71.667 71.667"
                >
                  <g
                    id="Group_102"
                    data-name="Group 102"
                    transform="translate(2.764 38.026)"
                  >
                    <g id="Group_101" data-name="Group 101">
                      <path
                        id="Path_2168"
                        data-name="Path 2168"
                        d="M50.07,299.463c0-.016,0-.031,0-.046s0-.027,0-.04c0-.03-.007-.059-.011-.089,0-.008,0-.016,0-.023a1.024,1.024,0,0,0-.069-.221l-.01-.022c-.013-.027-.026-.053-.041-.079l-.018-.029q-.021-.034-.045-.066l-.021-.029c-.018-.023-.038-.046-.058-.067l-.017-.018a1.038,1.038,0,0,0-.084-.076l0,0-4.91-3.943a1.037,1.037,0,1,0-1.3,1.618l2.417,1.941a27.308,27.308,0,0,1-16.476-7.389,25.079,25.079,0,0,1-7.6-18.178,1.037,1.037,0,1,0-2.075,0A27.138,27.138,0,0,0,27.97,292.37a29.383,29.383,0,0,0,17.806,7.974l-2.421,1.574a1.037,1.037,0,1,0,1.131,1.739l5.111-3.324,0,0a1.041,1.041,0,0,0,.093-.069l.012-.011c.024-.021.048-.043.07-.066l.025-.027c.017-.019.034-.039.05-.06l.022-.03c.016-.022.031-.046.045-.069l.015-.025a1.024,1.024,0,0,0,.09-.212l0-.017c.009-.031.016-.062.022-.093,0-.012,0-.023.005-.035s.007-.052.009-.079c0-.007,0-.014,0-.021s0-.014,0-.021S50.07,299.474,50.07,299.463Z"
                        transform="translate(-19.745 -271.666)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_104"
                    data-name="Group 104"
                    transform="translate(38.64 2.15)"
                  >
                    <g id="Group_103" data-name="Group 103">
                      <path
                        id="Path_2169"
                        data-name="Path 2169"
                        d="M298.151,26.813a29.387,29.387,0,0,0-17.807-7.973l2.421-1.574a1.037,1.037,0,0,0-1.131-1.739l-5.111,3.324,0,0a1.041,1.041,0,0,0-.093.069l-.012.011c-.024.021-.048.043-.07.066l-.024.027c-.017.019-.034.039-.05.06l-.022.03c-.016.022-.031.046-.046.069l-.015.025a1.031,1.031,0,0,0-.091.212l0,.017c-.009.031-.016.062-.022.094,0,.012,0,.023-.005.035s-.007.052-.009.079c0,.007,0,.014,0,.021s0,.014,0,.021,0,.02,0,.031,0,.031,0,.046,0,.027,0,.04c0,.03.007.059.011.089,0,.008,0,.016,0,.023a1.026,1.026,0,0,0,.069.221l.01.022c.013.027.026.053.041.079l.018.029q.021.034.045.066l.021.029c.018.023.038.045.058.067l.017.018a1.038,1.038,0,0,0,.084.076l0,0,4.91,3.943a1.037,1.037,0,0,0,1.3-1.618l-2.417-1.941A27.307,27.307,0,0,1,296.706,28.3a25.079,25.079,0,0,1,7.6,18.178,1.037,1.037,0,0,0,2.075,0A27.137,27.137,0,0,0,298.151,26.813Z"
                        transform="translate(-276.051 -15.358)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g id="Group_106" data-name="Group 106">
                    <g id="Group_105" data-name="Group 105">
                      <path
                        id="Path_2170"
                        data-name="Path 2170"
                        d="M19.427,0A19.427,19.427,0,1,0,38.854,19.427,19.449,19.449,0,0,0,19.427,0Zm0,36.779A17.352,17.352,0,1,1,36.779,19.427,17.372,17.372,0,0,1,19.427,36.779Z"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_108"
                    data-name="Group 108"
                    transform="translate(3.811 21.264)"
                  >
                    <g id="Group_107" data-name="Group 107">
                      <path
                        id="Path_2171"
                        data-name="Path 2171"
                        d="M57.627,151.935a1.036,1.036,0,0,0-1.218.818,13.826,13.826,0,0,1-27.125.019,1.037,1.037,0,1,0-2.035.4,15.9,15.9,0,0,0,31.2-.022A1.037,1.037,0,0,0,57.627,151.935Z"
                        transform="translate(-27.23 -151.915)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_110"
                    data-name="Group 110"
                    transform="translate(4 3.529)"
                  >
                    <g id="Group_109" data-name="Group 109">
                      <path
                        id="Path_2172"
                        data-name="Path 2172"
                        d="M59.4,37.144a15.9,15.9,0,0,0-30.793-.018,1.037,1.037,0,0,0,2.009.518,13.828,13.828,0,0,1,26.775.015,1.037,1.037,0,0,0,2.009-.515Z"
                        transform="translate(-28.574 -25.209)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_112"
                    data-name="Group 112"
                    transform="translate(32.813 32.813)"
                  >
                    <g id="Group_111" data-name="Group 111">
                      <path
                        id="Path_2173"
                        data-name="Path 2173"
                        d="M253.847,234.42a19.427,19.427,0,1,0,19.427,19.427A19.449,19.449,0,0,0,253.847,234.42Zm0,36.779A17.352,17.352,0,1,1,271.2,253.847,17.372,17.372,0,0,1,253.847,271.2Z"
                        transform="translate(-234.42 -234.42)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_114"
                    data-name="Group 114"
                    transform="translate(36.77 36.341)"
                  >
                    <g id="Group_113" data-name="Group 113">
                      <path
                        id="Path_2174"
                        data-name="Path 2174"
                        d="M293.618,271.8a15.9,15.9,0,0,0-30.9-.076,1.037,1.037,0,1,0,2.015.494,13.827,13.827,0,0,1,26.866.066,1.037,1.037,0,0,0,2.017-.484Z"
                        transform="translate(-262.69 -259.628)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_116"
                    data-name="Group 116"
                    transform="translate(36.659 54.205)"
                  >
                    <g id="Group_115" data-name="Group 115">
                      <path
                        id="Path_2175"
                        data-name="Path 2175"
                        d="M292.244,387.269a1.037,1.037,0,0,0-1.225.807,13.826,13.826,0,0,1-27.068.06,1.037,1.037,0,0,0-2.03.426,15.9,15.9,0,0,0,31.131-.069A1.037,1.037,0,0,0,292.244,387.269Z"
                        transform="translate(-261.898 -387.248)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_118"
                    data-name="Group 118"
                    transform="translate(13.697 9.457)"
                  >
                    <g id="Group_117" data-name="Group 117">
                      <path
                        id="Path_2176"
                        data-name="Path 2176"
                        d="M104.284,75.871V70.982c1.686.12,2.312.891,2.89.891.723,0,1.06-.915,1.06-1.373,0-1.18-2.312-1.686-3.95-1.734v-.65c0-.289-.361-.554-.723-.554a.635.635,0,0,0-.7.554v.7c-2.288.241-4.576,1.445-4.576,4.48,0,3.083,2.409,3.95,4.576,4.72v5.66c-2.457-.193-3.107-1.879-3.9-1.879-.6,0-1.108.795-1.108,1.373,0,1.18,2.023,2.794,5.01,2.842v.747a.636.636,0,0,0,.7.554c.361,0,.723-.265.723-.554v-.819a4.666,4.666,0,0,0,4.383-4.961C108.667,77.7,106.379,76.642,104.284,75.871Zm-1.276-.458c-1.277-.482-2.312-.988-2.312-2.36,0-1.252.963-1.855,2.312-2.023Zm1.132,8.213V78.544c1.18.506,2.119,1.18,2.119,2.7A2.291,2.291,0,0,1,104.139,83.626Z"
                        transform="translate(-97.853 -67.562)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_120"
                    data-name="Group 120"
                    transform="translate(47.696 43.552)"
                  >
                    <g id="Group_119" data-name="Group 119">
                      <path
                        id="Path_2177"
                        data-name="Path 2177"
                        d="M349.042,320c1.095-.484,1.789-1.663,1.789-3.41,0-2.208-1.107-3.146-2.6-3.482h.053v-.982a.982.982,0,0,0-1.964,0v.822h-1.074v-.822a.982.982,0,0,0-1.964,0v.822h-1.5a1.038,1.038,0,0,0-.027,2.075v11.239a1.038,1.038,0,0,0,.027,2.075h1.5v.874a.982.982,0,0,0,1.964,0v-.874h1.074v.874a.982.982,0,0,0,1.964,0v-.982h-.1c1.77-.353,3.026-1.611,3.026-4.312v-.4C351.211,321.371,350.348,320.508,349.042,320Zm-5.074-5.073h2.463c1.326,0,2.168.611,2.168,2.021a1.9,1.9,0,0,1-2.126,2.168h-2.505Zm5.01,8.821c0,1.81-.884,2.61-2.379,2.61h-2.632v-5.473H346.6c1.495,0,2.379.674,2.379,2.526Z"
                        transform="translate(-340.747 -311.146)"
                        fill="#0fb0a9"
                      />
                    </g>
                  </g>
                </svg>
                <p>مقارنة أسعار تامين اخطاء ممارسة المهن الطبية</p>
              </div>
            </Link>

            <Link to="/ServiceInsuranceCompany/service">
              <div onClick={changeDesign} className="card-item" data-para='3' data-dest='/MedicalNetworks'>
                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62.166" viewBox="0 0 62 62.166">
                  <g id="medical-report" transform="translate(-1 -0.834)">
                    <path id="Path_2147" data-name="Path 2147" d="M49,9v2H47a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2h2v2a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2V17h2a2,2,0,0,0,2-2V13a2,2,0,0,0-2-2H55V9a2,2,0,0,0-2-2H51A2,2,0,0,0,49,9Zm8,4v2H53v4H51V15H47V13h4V9h2v4Z" fill="#0fb0a9" />
                    <path id="Path_2148" data-name="Path 2148" d="M62,4a17.044,17.044,0,0,1-8.772-2.43L52,.834l-1.228.736A17.044,17.044,0,0,1,42,4H41V9H39V8A7,7,0,0,0,25,8V9H11a2,2,0,0,0-2,2v8.012a7.654,7.654,0,0,0-5.794,2.88,10.15,10.15,0,0,0,.767,13.495L9,40.414V61a2,2,0,0,0,2,2H53a2,2,0,0,0,2-2V25.84a14.916,14.916,0,0,0,8-13.281V4ZM49,38.62,46.885,41H35V53H47V43.88l2-2.25V57H15V46.414l1,1L28.027,35.387A10.158,10.158,0,0,0,29.8,33h9.382a3,3,0,1,0,0-2H30.6a10.282,10.282,0,0,0,.4-2.819,10.1,10.1,0,0,0-2.206-6.289,7.7,7.7,0,0,0-11.465-.635L16,22.586l-1-1V15h26.2A14.883,14.883,0,0,0,49,25.84ZM42.043,49.457,45,46.13V51H37V43h8v.12l-3.043,3.423-2.25-2.25-1.414,1.414ZM41,32a1,1,0,1,1,1,1A1,1,0,0,1,41,32ZM27,8A5,5,0,0,1,37,8V9H35V8a3,3,0,0,0-6,0V9H27Zm6,1H31V8a1,1,0,0,1,2,0Zm8,2v1.56c0,.148.013.293.018.44H13v6.993a7.579,7.579,0,0,0-2-.78V11ZM3,28.181a8.1,8.1,0,0,1,1.769-5.04,5.7,5.7,0,0,1,8.488-.47L16,25.414l2.743-2.743a5.7,5.7,0,0,1,8.488.47A8.119,8.119,0,0,1,28.5,31h-9.12l-2.2,4.394-3.1-9.3L11.323,33H9v2h3.677l1.238-3.094,2.9,8.7L20.618,33h6.816a8.292,8.292,0,0,1-.821.973L16,44.586,5.387,33.973A8.115,8.115,0,0,1,3,28.181ZM53,61H11V42.414l2,2V59H51V26.713c.237.084.47.173.713.245l.287.086.287-.086c.243-.073.476-.162.713-.245Zm8-48.44a12.949,12.949,0,0,1-9,12.393A12.949,12.949,0,0,1,43,12.56V5.974a19.057,19.057,0,0,0,8.8-2.688l.2-.12.2.119A19.043,19.043,0,0,0,61,5.974Z" fill="#0fb0a9" />
                    <path id="Path_2149" data-name="Path 2149" d="M31,51h2v2H31Z" fill="#0fb0a9" />
                    <path id="Path_2150" data-name="Path 2150" d="M27,51h2v2H27Z" fill="#0fb0a9" />
                    <path id="Path_2151" data-name="Path 2151" d="M23,51h2v2H23Z" fill="#0fb0a9" />
                    <path id="Path_2152" data-name="Path 2152" d="M33,25h6v2H33Z" fill="#0fb0a9" />
                    <path id="Path_2153" data-name="Path 2153" d="M33,21h6v2H33Z" fill="#0fb0a9" />
                    <path id="Path_2154" data-name="Path 2154" d="M33,17h6v2H33Z" fill="#0fb0a9" />
                  </g>
                </svg>

                <p>تقييم الشبكات الطبية</p>
              </div>
            </Link>
          </div>
          <div className="content" style={{ marginTop: "90px" }}>
            <Route
              path="/ServiceInsuranceCompany/service"
              render={() => (
                <DescriptionServiceScreen
                  title={title}
                  subTitle={subTitle}
                  choosePara={choosePara}
                  dest={dest}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnlineServiceInsuranceCompanyScreen
