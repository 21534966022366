import { 
    TENDER_ADD_REQUEST,
    TENDER_ADD_SUCCESS,
    TENDER_ADD_FAIL,

    TENDER_LIST_REQUEST, 
    TENDER_LIST_SUCCESS, 
    TENDER_LIST_FAIL,

    TENDER_DETAILS_REQUEST,
    TENDER_DETAILS_SUCCESS,
    TENDER_DETAILS_FAIL, 

    TENDER_ADD_OFFER_REQUEST,
    TENDER_ADD_OFFER_SUCCESS,
    TENDER_ADD_OFFER_FAIL,

} from '../constants/tenderConstants'

export const tenderAddReducer = (state = { }, action) => {
    switch (action.type) {

        case TENDER_ADD_REQUEST:
            return { loading: true }
        
        case TENDER_ADD_SUCCESS:
            return { loading: false, success: true, tenderData:action.payload }
        
        case TENDER_ADD_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const tenderListReducer = (state = { tenders: [] }, action) => {
    switch (action.type) {

        case TENDER_LIST_REQUEST:
            return { loading: true, tenders: [] }
        
        case TENDER_LIST_SUCCESS:
            return { 
                loading: false,
                tenders: action.payload.tenders,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case TENDER_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const tenderDetailsReducer = (state = { loading:true, tender: {}, facility:{} }, action) => {
    switch (action.type) {

        case TENDER_DETAILS_REQUEST:
            return { loading: true, ...state }
        
        case TENDER_DETAILS_SUCCESS:
            return { loading: false, device: action.payload }
        
        case TENDER_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const tenderAddOfferReducer = (state = { }, action) => {
    switch (action.type) {

        case TENDER_ADD_OFFER_REQUEST:
            return { loading: true }
        
        case TENDER_ADD_OFFER_SUCCESS:
            return { loading: false, success: true, offerData:action.payload }
        
        case TENDER_ADD_OFFER_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}