import React, { Component } from 'react'
import "../../styles/confirm_update.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import confirm from "../../imgs/checked.png";
import arrow from "../../imgs/Group 224.png";
import { Link } from "react-router-dom";
class ConfirmAcceptOfferInsurance extends Component {
    render() {
        return (
            <div>
            <ControlPanelHeader
          exit1={true}
          files={false}
          notifi={true}
          msg={true}
          msgDest={"/Messages"}
          notifidest={"/Notifications"}
          exitDest={"/services"}
          fileDest={"/FacilityFiles"}
          style={"three_section"}
        />
           <div className="confirm-content text-center" >
             <div className="confirm-update" style={{height:'361px' , margin :'125px auto 80px'}}>
               <img className="confirm" src={confirm} alt="" style= {{marginTop : '36px'}}  />
               <p className='hint'>قمت بإختيار شركة التأمين سيصلك رابط من الشركة لإتمام عملية الدفع</p>
             </div>
            
               <Link to='/comparingMedicalMistakeWork'>
               <button className="back-btn">
                 <img src={arrow} alt="" />
                 اذهب لصفحة الطلبات
               </button>
               
               </Link>
          
           </div>
         </div>
        )
    }
}

export default ConfirmAcceptOfferInsurance
