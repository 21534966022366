export const TENDER_ADD_REQUEST = 'TENDER_ADD_REQUEST'
export const TENDER_ADD_SUCCESS = 'TENDER_ADD_SUCCESS'
export const TENDER_ADD_FAIL = 'TENDER_ADD_FAIL'

export const TENDER_LIST_REQUEST = 'TENDER_LIST_REQUEST'
export const TENDER_LIST_SUCCESS = 'TENDER_LIST_SUCCESS'
export const TENDER_LIST_FAIL = 'TENDER_LIST_FAIL'

export const TENDER_DETAILS_REQUEST = 'TENDER_DETAILS_REQUEST'
export const TENDER_DETAILS_SUCCESS = 'TENDER_DETAILS_SUCCESS'
export const TENDER_DETAILS_FAIL = 'TENDER_DETAILS_FAIL'

export const TENDER_ADD_OFFER_REQUEST = 'TENDER_ADD_OFFER_REQUEST'
export const TENDER_ADD_OFFER_SUCCESS = 'TENDER_ADD_OFFER_SUCCESS'
export const TENDER_ADD_OFFER_FAIL = 'TENDER_ADD_OFFER_FAIL'