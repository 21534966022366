import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/add_evaluationdata.css";
import arrow from "../../imgs/arrow.png";
import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import Select from 'react-select'

import { AddEvaluation } from '../../actions/serviceActions'

import { AiOutlineCloseCircle } from "react-icons/ai";

import { Row, Col } from 'react-bootstrap'
import axios from 'axios';

const getExtension = (filename) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

export { getExtension };


function AddEvaluationDataScreen({ history }) {

  const [facility_Area, setFacility_Area] = useState('')

  const [facility_region, setFacility_region] = useState('')

  const [facility_Location, setFacility_Location] = useState('')

  const [nonSpecial_Room, setNonSpecial_Room] = useState('')

  const [administrative_StaffNo, setAdministrative_StaffNo] = useState('')

  const [special_room, setSpecial_room] = useState(false)

  const [speical_RoomNo, setSpeical_RoomNo] = useState('')

  const [operation_RoomNo, setOperation_RoomNo] = useState('')

  const [devices_Status, setDevices_Status] = useState(false)

  const [pharmacies, setPharmacies] = useState(false)

  const [pharmacies_no, setPharmacies_no] = useState('')

  const [branch, setBranch] = useState(false)

  const [branches_no, setBranches_no] = useState('')

  const [nurses_No, setNurses_No] = useState('')

  const [bedsNo, setBedsNo] = useState('')

  const [clinics_No, setClinics_No] = useState('')

  const [clinic_type, setClinic_type] = useState('')

  const [doctor_No, setDoctor_No] = useState('')

  const [servicePrice_photo, setServicePrice_photo] = useState(null)

  const [servicePrice_photo_title, setServicePrice_photo_title] = useState('ارفع ملف الاسعار')

  const [emergency, setEmergency] = useState(false)

  const [ray, setRay] = useState(false)

  const [lab, setLab] = useState(false)

  const [parking_forPatients, setParking_forPatients] = useState(false)

  const [ray_StaffNo, setRay_StaffNo] = useState('')

  const [lab_StaffNo, setLab_StaffNo] = useState('')

  const [parking_forPatients_No, setParking_forPatients_No] = useState('')

  const [parking_Area, setParking_Area] = useState('')

  const [notes, setNotes] = useState('')

  const [municipality_photo, setMunicipality_photo] = useState(null)

  const [municipality_photo_title, setMunicipality_photo_title] = useState('ارفع صورة البلدية')

  const [inside_FacilityPhoto, setInside_FacilityPhoto] = useState(null)

  const [inside_FacilityPhoto_title, setInside_FacilityPhoto_title] = useState('ارفع صورة المنشأة من الداخل')

  const [outside_FacilityPhoto, setOutside_FacilityPhoto] = useState(null)

  const [outside_FacilityPhoto_title, setOutside_FacilityPhoto_title] = useState('ارفع صورة المنشأة من الخارج')

  const [IsDisplayed, setIsDisplayed] = useState(false)

  const [palceHolder, setPalceHolder] = useState(true)

  const [errors, setErrors] = useState( "الرجاء ملئ كل البيانات لإكمال التسجيل")

  const [errorsFlags, setErrorsFlags] = useState([false])

  const [clinics, setClinics] = useState([
    { value: 'عيادة القلب', label: 'عيادة القلب' },
    { value: 'عيادات الأنف والأذن و الحنجرة', label: 'عيادات الأنف والأذن و الحنجرة' },
    { value: 'عيادات الأسنان', label: 'عيادات الأسنان' },
    { value: 'عيادة الجلدية', label: 'عيادة الجلدية' },
    { value: 'الطوارئ', label: 'الطوارئ' },
    { value: 'عيادة الجراحة العامة و الأوعية الدموية', label: 'عيادة الجراحة العامة و الأوعية الدموية' },
    { value: 'عيادة النساء و الولادة', label: 'عيادة النساء و الولادة' },
    { value: 'عيادات الأمراض الباطنية', label: 'عيادات الأمراض الباطنية' },
    { value: 'عيادة العظام', label: 'عيادة العظام' },
    { value: 'عيادة العيون', label: 'عيادة العيون' },
    { value: 'عيادة المسالك', label: 'عيادة المسالك' },
    { value: 'معمل التحاليل الطبية', label: 'معمل التحاليل الطبية' },
    { value: 'وحدة التوعية الطبية و الصحة العامة', label: 'وحدة التوعية الطبية و الصحة العامة' },
    { value: 'وحدة طب الطوارئ و دراسات الإسعافات الأولية', label: 'وحدة طب الطوارئ و دراسات الإسعافات الأولية' },
  ])

  const dispatch = useDispatch()

  const serviceAddEvaluation
    = useSelector(state => state.serviceAddEvaluation)

  const { error, EvaluationData, success } = serviceAddEvaluation

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const [wrong, setWrong] = useState(false);

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo.access}`
    }
  };

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    } else {
      if (!userInfo.is_activated) {
        history.push('/services')
      } else {
        if (userInfo.is_evaluated) {
          history.push('/services')
        }
      }
    }

  }, [dispatch, history, userInfo])

  const onChange = (option) => {
    // option.value     -->  "chocolate"
    // option.label     -->  "Chocolate"
    // option.extraInfo -->  "A"
    var array = []
    option.map(item => {
      array.push(item.value)
    })

    setClinic_type(array)

    return option;
  };


  const focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  const focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };


  const onUpload = (e) => {
    if (e.target.value) {
      const ext = getExtension(e.target.value);
      if (ext === "png" || ext === "jpg" || ext === "pdf") {
        const path = e.target.getAttribute("data-path")
        if (path === "servicePrice_photo") {
          //setPhoto1(true)
          setServicePrice_photo(e.target.files[0])
          setServicePrice_photo_title(e.target.value)
        } else if (path === "municipality_photo") {
          //setPhoto2(true)
          setMunicipality_photo(e.target.files[0])
          setMunicipality_photo_title(e.target.value)
        } else if (path === "inside_FacilityPhoto") {
          //setPhoto3(true)
          setInside_FacilityPhoto(e.target.files[0])
          setInside_FacilityPhoto_title(e.target.value)
        } else if (path === "outside_FacilityPhoto") {
          //setPhoto4(true)
          setOutside_FacilityPhoto(e.target.files[0])
          setOutside_FacilityPhoto_title(e.target.value)
        }

      }
    }
  };

  const formValidation = () => {
    let isValid = true;
    const flags = [false];

    const nodes = document.querySelectorAll("input");
    nodes.forEach((e) => {
      if (e.value === '' && e.id !== 'react-select-2-input' && e.id !== 'react-select-3-input' && e.id !== 'react-select-4-input' && e.id !== 'react-select-5-input') {
        console.log(e)
        flags[0] = true;
        isValid = false;
      }

    });

    setErrorsFlags(flags);
    return isValid;
  };

  const sendData = (e) => {
    e.preventDefault()
    const isValid = formValidation();
    if (isValid) {

      let data = new FormData();
      data.append('facility_area', facility_Area);
      data.append('facility_state', facility_region);
      data.append('facility_city', facility_Location);
      data.append('num_rooms', nonSpecial_Room);
      data.append('private_rooms', `${special_room}`);
      data.append('num_private_rooms', speical_RoomNo);
      data.append('num_admin_staff', administrative_StaffNo);
      data.append('num_operations_rooms', operation_RoomNo);
      data.append('is_medical_devices_new', `${devices_Status}`);
      data.append('num_pharmacies', pharmacies_no);
      data.append('num_branches', branches_no);
      data.append('num_of_nurses', nurses_No);
      data.append('num_of_beds', bedsNo);
      data.append('num_of_clinics', clinics_No);
      data.append('clinic_type', clinic_type);
      data.append('num_of_doctors', doctor_No);
      data.append('services_prices_image', servicePrice_photo);
      data.append('is_emergency', `${emergency}`);
      data.append('is_rays', `${ray}`);
      data.append('is_lab', `${lab}`);
      data.append('num_of_technical_staff_rays', ray_StaffNo);
      data.append('num_of_technical_staff_lab', lab_StaffNo);
      data.append('num_of_parking_spots', parking_forPatients_No);
      data.append('parking_lot_area', parking_Area);
      data.append('notes', notes);
      data.append('township_image', municipality_photo);
      //data.append('facility_inner_image', inside_FacilityPhoto);
      //data.append('facility_outer_image', outside_FacilityPhoto);
      data.append('service', '1');

      // dispatch(AddEvaluation(data))

      axios.post(
        `/services/request_evaluation/`, data,
        config
      ).then(() => {
        history.push('/thanksEvaluation');
      }).catch(() => {
        setErrorsFlags([true]);
        setErrors({allFieldRequired: 'خطأ في السيرفر'});
      });
    }
  }

  return (
    <div>
      <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest={'/collectServices'}
        style={"three_section"}
      />
      <div className="data-form">
        <div className="desc-title text-center">إضافة بيانات التقييم</div>
        <div className="sub-title">فضلا اكمل البيانات الخاصه بالمنشأة</div>
        <form>
          <div className="name-form">
            <div className="input-lable">
              <label htmlFor="name">مساحة المنشاة</label>
              <input
                type="number"
                id="facility_Area"
                name="facility_Area"
                value={facility_Area}
                placeholder="اكتب مساحة المنشأة"
                onChange={(e) => setFacility_Area(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>
            <div className="input-lable">
              <label htmlFor="mailBox">مكان المنشأة بالمنطقة</label>
              <input
                type="text"
                id="facility_region"
                name="facility_region"
                value={facility_region}
                placeholder="اكتب مكان المننشاة"
                onChange={(e) => setFacility_region(e.target.value)}
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>
            <div className="input-lable">
              <label htmlFor="postalCode">مكان المنشاة بالمدينة</label>
              <input
                type="text"
                id="facility_Location"
                name="facility_Location"
                value={facility_Location}
                onChange={(e) => setFacility_Location(e.target.value)}
                placeholder="اكتب مكان المنشاة بالمدينة"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>
            <div className="input-lable">
              <label htmlFor="postalCode">عدد الغرف الغير خاصة</label>
              <input
                type="number"
                id="nonSpecial_Room"
                name="nonSpecial_Room"
                value={nonSpecial_Room}
                onChange={(e) => setNonSpecial_Room(e.target.value)}
                placeholder="اكتب عدد الغرف الغير خاصة"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>
            <div className="input-lable">
              <label htmlFor="postalCode">عدد الكادر الإداري للمنشأة</label>
              <input
                type="number"
                id="administrative_StaffNo"
                name="administrative_StaffNo"
                value={administrative_StaffNo}
                onChange={(e) => setAdministrative_StaffNo(e.target.value)}
                placeholder="اكتب عدد الكادر الإداري للمنشأة"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="radio_input">
              <p> هل يوجد غرف خاصة</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="special_room"
                    value="نعم"
                    checked={special_room}
                    onChange={(e) => setSpecial_room(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="special_room"
                    onChange={(e) => setSpecial_room(e.target.value === 'نعم')}
                  />
                  لا
                </label>
              </div>
            </div>

            <div className="input-lable">
              <label htmlFor="postalCode">عدد الغرف الخاصة</label>
              <input
                type="number"
                id="speical_RoomNo"
                name="speical_RoomNo"
                value={speical_RoomNo}
                onChange={(e) => setSpeical_RoomNo(e.target.value)}
                placeholder="اكتب عدد الغرف الخاصة"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>
            <div className="input-lable">
              <label htmlFor="postalCode">عدد غرف العمليات</label>
              <input
                type="number"
                id="operation_RoomNo"
                name="operation_RoomNo"
                value={operation_RoomNo}
                onChange={(e) => setOperation_RoomNo(e.target.value)}
                placeholder="اكتب عدد غرف العمليات"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>
            <div className="radio_input">
              <p> هل الأجهزة الطبية للمنشأة جديدة</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="devices_Status"
                    value="نعم"
                    checked={devices_Status}
                    onChange={(e) => setDevices_Status(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="devices_Status"
                    onChange={(e) => setDevices_Status(e.target.value === 'نعم')}
                  />
                  لا
                </label>
              </div>
            </div>

            <div className="radio_input">
              <p> هل يوجد صيدليات</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="pharmacies"
                    value="نعم"
                    checked={pharmacies}
                    onChange={(e) => setPharmacies(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="pharmacies"
                    onChange={(e) => setPharmacies(e.target.value === 'نعم')}
                  />
                  لا
                </label>
                <input
                  type="number"
                  id="pharmacies_no"
                  name="pharmacies_no"
                  value={pharmacies_no}
                  onChange={(e) => setPharmacies_no(e.target.value)}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  className="radioInput"
                />
              </div>
            </div>

            <div className="radio_input">
              <p> هل لديكم فرع</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="branch"
                    value="نعم"
                    checked={branch}
                    onChange={(e) => setBranch(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="branch"
                    onChange={(e) => setBranch(e.target.value === 'نعم')}
                  />
                  لا
                </label>
                <input
                  type="number"
                  id="branches_no"
                  name="branches_no"
                  value={branches_no}
                  onChange={(e) => setBranches_no(e.target.value)}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  className="radioInput"
                />
              </div>
            </div>

            <div className="input-lable">
              <label htmlFor="postalCode">عدد الممرضات</label>
              <input
                type="number"
                id="nurses_No"
                name="nurses_No"
                value={nurses_No}
                onChange={(e) => setNurses_No(e.target.value)}
                placeholder="اكتب عدد الممرضات"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="postalCode">عدد الاسرة</label>
              <input
                type="number"
                id="bedsNo"
                name="bedsNo"
                value={bedsNo}
                onChange={(e) => setBedsNo(e.target.value)}
                placeholder="اكتب عدد الاسرة"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>
            <div className="input-lable">
              <label htmlFor="postalCode">عدد العيادات</label>
              <input
                type="number"
                id="clinics_No"
                name="clinics_No"
                value={clinics_No}
                onChange={(e) => setClinics_No(e.target.value)}
                placeholder="اكتب عدد العيادات"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            {/*<div className="input-lable" style={{width: "390px"}}>
              <label htmlFor="date1">نوع العيادة</label>
              <div className="select-city" style={{ height: "37px" }}>
                <div className="select-btn" onClick={selectClick}>
                  {palceHolder && (
                    <span style={{ color: "var(--lightgray-color)" }}>
                      اختر نوع العيادة
                    </span>
                  )}
                  <span>{clinic_type}</span>
                  <img src={rarrow} alt="arr" />
                </div>
                {IsDisplayed && (
                  <Select options={options} />
                  
                  <div className="select-content">
                    {clinics.map((option) => (
                      <div
                        className="select-item"
                        onClick={(e) => {
                          changeSelect(e.target.textContent);
                        }}
                      >
                        {" "}
                        {option}{" "}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>*/}

            <div className="input-lable">
              <label htmlFor="postalCode">عدد الاطباء</label>
              <input
                type="number"
                id="doctor_No"
                name="doctor_No"
                value={doctor_No}
                onChange={(e) => setDoctor_No(e.target.value)}
                placeholder="اكتب عدد الاطباء"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-content">
              <p>رفع أسعار الخدمات</p>
              <label htmlFor="upload-photo1">
                {" "}
                <div className="input">
                  <img src={upload} alt="" />
                  <div>{servicePrice_photo_title}</div>
                </div>{" "}
              </label>
              <input
                onChange={onUpload}
                type="file"
                name="photo1"
                id="upload-photo1"
                data-path="servicePrice_photo"
                files
              />
            </div>

            <div className="radio_input">
              <p> هل يوجد طوارئ</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="emergency"
                    value="نعم"
                    checked={emergency}
                    onChange={(e) => setEmergency(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="emergency"
                    onChange={(e) => setEmergency(e.target.value === 'نعم')}
                  />
                  لا
                </label>
              </div>
            </div>

            <div className="radio_input">
              <p> هل يوجد اشعة</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="ray"
                    value="نعم"
                    checked={ray}
                    onChange={(e) => setRay(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="ray"
                    onChange={(e) => setRay(e.target.value === 'نعم')}
                  />
                  لا
                </label>
              </div>
            </div>

            <div className="radio_input">
              <p> هل يوجد مختبر</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="lab"
                    value="نعم"
                    checked={lab}
                    onChange={(e) => setLab(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="lab"
                    onChange={(e) => setLab(e.target.value === 'نعم')}
                  />
                  لا
                </label>
              </div>
            </div>

            <div className="radio_input">
              <p> هل يوجد مواقف للمرضى</p>
              <div className="radio_group">
                <label>
                  <input
                    type="radio"
                    name="parking_forPatients"
                    value="نعم"
                    checked={parking_forPatients}
                    onChange={(e) => setParking_forPatients(e.target.value === 'نعم')}
                  />
                  نعم
                </label>
                <label>
                  <input
                    type="radio"
                    value="لا"
                    name="parking_forPatients"
                    onChange={(e) => setParking_forPatients(e.target.value === 'نعم')}
                  />
                  لا
                </label>
              </div>
            </div>

            <div className="input-lable">
              <label htmlFor="postalCode">عدد الكادر الفني للاشعة</label>
              <input
                type="number"
                id="ray_StaffNo"
                name="ray_StaffNo"
                value={ray_StaffNo}
                onChange={(e) => setRay_StaffNo(e.target.value)}
                placeholder="اكتب عدد عدد الكادر الفني للاشعة"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="postalCode">عدد الكادر الفني للمختبر</label>
              <input
                type="number"
                id="lab_StaffNo"
                name="lab_StaffNo"
                value={lab_StaffNo}
                onChange={(e) => setLab_StaffNo(e.target.value)}
                placeholder="اكتب عدد الكادر الفني للمختبر"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="postalCode">عدد مواقف المرضى</label>
              <input
                type="number"
                id="parking_forPatients_No"
                name="parking_forPatients_No"
                value={parking_forPatients_No}
                onChange={(e) => setParking_forPatients_No(e.target.value)}
                placeholder="اكتب عدد مواقف المرضى"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable">
              <label htmlFor="postalCode">مساحة الموقف</label>
              <input
                type="text"
                id="parking_Area"
                name="parking_Area"
                value={parking_Area}
                onChange={(e) => setParking_Area(e.target.value)}
                placeholder="اكتب مساحة الموقف"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-lable" style={{ width: "1234px", height: "121px" }}>
              <label htmlFor="date1">نوع العيادة</label>

              <Select

                isMulti
                name="clinic_type"
                options={clinics}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                onChange={onChange}

              />
            </div>

            <div
              className="input-lable"
              style={{ width: "1234px", height: "121px" }}
            >
              <label htmlFor="analysisDetails">ملاحظات</label>
              <textarea
                style={{ paddingTop: "10px" }}
                type="text"
                id="notes"
                name="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="اكتب الملاحظات"
                onFocus={focusHandler}
                onBlur={focusoutHandler}
              />
            </div>

            <div className="input-content">
              <p>إرفع صورة رخصة البلدية</p>
              <label htmlFor="upload-photo2">
                {" "}
                <div className="input">
                  <img src={upload} alt="" />
                  <div>{municipality_photo_title}</div>
                </div>{" "}
              </label>
              <input
                onChange={onUpload}
                type="file"
                name="photo2"
                id="upload-photo2"
                data-path="municipality_photo"
              />
            </div>
            {/*}
            <div className="input-content">
              <p>صورة المنشأة من الداخل </p>
              <label htmlFor="upload-photo3">
                {" "}
                <div className="input">
                  <img src={upload} alt="" />
                  <div>{inside_FacilityPhoto_title}</div>
                </div>{" "}
              </label>
              <input
                onChange={onUpload}
                type="file"
                name="photo3"
                id="upload-photo3"
                data-path="inside_FacilityPhoto"
              />
            </div>

            <div className="input-content">
              <p>صورة المنشأة من الخارج </p>
              <label htmlFor="upload-photo4">
                {" "}
                <div className="input">
                  <img src={upload} alt="" />
                  <div>{outside_FacilityPhoto_title}</div>
                </div>{" "}
              </label>
              <input
                onChange={onUpload}
                type="file"
                name="photo4"
                id="upload-photo4"
                data-path="outside_FacilityPhoto"
              />
            </div>
            */}
          </div>
        </form>
        <div className="text-center d-flex justify-content-center">{errorsFlags[0] && <div className='error'>{errors.allFieldRequired}</div>}
        </div>
      </div>

      {wrong && <AiOutlineCloseCircle>ERROR</AiOutlineCloseCircle>}

      <button onClick={sendData} className="btnNext">
        <img src={arrow} alt="" />
        إرسال
      </button>
    </div>
  )
}

export default AddEvaluationDataScreen
