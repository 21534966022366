import React, { Component } from "react";
import arrow from "../../imgs/arrow.png";
import search from "../../imgs/search1.png";
import "../../styles/stop_service.css";
import rarrow from "../../imgs/right-arrow1.png";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";
class Stop_Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: false,
      msg: "",
      suspendType: "",
      IsDisplayed: false,
      palceHolder: true,
      service: "",
      showModal: false,
    };
  }

  submitHandler = () => {
    this.setState({ showModal: true });
  };

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  onClick = () => {
    this.setState({
      showInfo: true,
    });
  };

  onChooseSuspendType = (suspend) => {
    this.setState({
      suspendType: suspend,
    });
  };

  changeSelect = (title) => {
    this.setState({
      IsDisplayed: false,
      palceHolder: false,
      service: title,
    });
  };

  selectClick = () => {
    this.setState((prevState) => {
      return { IsDisplayed: prevState.IsDisplayed === true ? false : true };
    });
  };

  render() {
    const { showInfo, msg, IsDisplayed, palceHolder, service } = this.state;

    const services = [
      "تقييم الشبكات الطبية",
      "تأجير أو بيع الأجهزة الطبية",
      "تأجير خدمات الأطباء والعيادات",
      "مقارنة أسعار تامين اخطاء ممارسة المهن الطبية",
      "التحصيل",
      "تنظيم وتشغيل عقود المختبرات",
      "تنظيم وتشغيل عقود الأشعة ",
      "تنظيم مناقصات التوريد",
    ];

    return (
      <div className="stop_service">
        <div className="item reverse">
          <div className="input-lable">
            <label htmlFor="name">اسم الخدمة</label>
            <div className="select-city">
              <div className="select-btn" onClick={this.selectClick}>
                {palceHolder && (
                  <span style={{ color: "var(--lightgray-color)" }}>
                    اختر الخدمة
                  </span>
                )}
                <span>{service}</span>
                <img src={rarrow} />
              </div>
              {IsDisplayed && (
                <div className="select-content">
                  {services.map((option) => (
                    <div
                      className="select-item"
                      onClick={(e) => {
                        this.changeSelect(e.target.textContent);
                      }}
                    >
                      {" "}
                      {option}{" "}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="item" style={{ display: "block" }}>
          {showInfo && (
            <div>
              <div className="info">
                <p className="info_title">اسم الجهة :</p>

                <div className="info_data">{service}</div>
              </div>
              <div className="suspend">
                <div className="suspend_types">
                  <div
                    onClick={() => {
                      this.onChooseSuspendType("إيقاف دائم");
                    }}
                    className="suspend_type"
                  >
                    إيقاف دائم
                  </div>
                  <div
                    onClick={() => {
                      this.onChooseSuspendType("إيقاف مؤقت");
                    }}
                    className="suspend_type"
                    style={{ backgroundColor: "var(--red-color)" }}
                  >
                    إيقاف مؤقت
                  </div>
                  <div
                    onClick={() => {
                      this.onChooseSuspendType("تعديل الرسالة");
                    }}
                    className="suspend_type"
                    style={{ backgroundColor: "#ababab" }}
                  >
                    تعديل الرسالة
                  </div>
                </div>
                <div
                  className="input-data"
                  style={{
                    marginLeft: "205px",
                    width: "710px",
                    height: "165px",
                  }}
                >
                  <label htmlFor="msg">رسالة الادارة</label>
                  <textarea
                    style={{ paddingTop: "10px" }}
                    type="text"
                    id="msg"
                    name="msg"
                    value={msg}
                    onChange={this.changeHandler}
                    placeholder="رسالة تنبيه للمشتركين بإيقاف الاشتراك الدائم أو المؤقت "
                    onFocus={this.focusHandler}
                    onBlur={this.focusoutHandler}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showInfo && (
          <div className="text-center d-flex justify-content-center">
            <Button
              className="text-center btnNext"
              onClick={this.submitHandler}
            >
              <img src={arrow} alt="" />
              تنفيذ
            </Button>
          </div>
        )}

        {showInfo === false && (
          <div className="text-center d-flex justify-content-center">
            <Button className="text-center btnNext" onClick={this.onClick}>
              <img src={arrow} alt="" />
              التالي
            </Button>
          </div>
        )}
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Stop_Services;
