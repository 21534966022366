import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./App.module.css";
import "./styles/common_styles.css";
import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import CreateAccountScreen from "./screens/CreateAccountScreen";
import ThanksMsg from "./component/ThanksMsg";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import ThankPassword from "./component/ThankPassword";
import MainChooseSectionTypeScreen from "./screens/MainChooseSectionTypeScreen";
import FileLocationScreen from "./screens/FileLocationScreen";
import InfoAboutOwnerScreen from "./screens/InfoAboutOwnerScreen";
import InfoEmployeeHasAccessScreen from "./screens/InfoEmployeeHasAccessScreen";
import EndorsementScreen from "./screens/EndorsementScreen";
import ConfirmSinup from "./component/confirm/ConfirmSinup";
import SubscriptionPaymentScreen from "./screens/SubscriptionPaymentScreen";
import SubscriptionPaymentBankScreen from "./screens/SubscriptionPaymentBankScreen";
import ThanksSubscriptionAmount from "./component/ThanksSubscriptionAmount";
import RenewSubscriptionPaymentScreen from "./screens/RenewSubscriptionPaymentScreen";
import RenewSubscriptionPaymentBankScreen from "./screens/RenewSubscriptionPaymentBankScreen";
import SubscriptionPaymentForSmallFacilityScreen from "./screens/SubscriptionPaymentForSmallFacilityScreen";
import SubscriptionPaymentBankForSmallFacilityScreen from "./screens/SubscriptionPaymentBankForSmallFacilityScreen";

import CPServicesOnlineScreen from "./screens/CPServicesOnlineScreen";
import AddEvaluationDataScreen from "./screens/services/AddEvaluationDataScreen";
import EvaluationDataDetailsScreen from "./screens/services/EvaluationDataDetailsScreen";

import ThanksEvaluation from "./component/ThanksEvaluation";

import ListSellMedicalDevicesScreen from "./screens/services/ListSellMedicalDevicesScreen";
import AddRquestMedicalMachineScreen from "./screens/services/AddRquestMedicalMachineScreen";
import MedicalDeviceOrderScreen from "./screens/services/MedicalDeviceOrderScreen";
import ListRentDoctorClincServicesScreen from "./screens/services/ListRentDoctorClincServicesScreen";
import AddRequestDoctorsScreen from "./screens/services/AddRequestDoctorsScreen";
import OrderDetailsScreen from "./screens/services/OrderDetailsScreen";
import ListLaboratriesContractScreen from "./screens/services/ListLaboratriesContractScreen";
import AddRequestLaboratoriesScreen from "./screens/services/AddRequestLaboratoriesScreen";
import OrderDetailsLaboratoriesScreen from "./screens/services/OrderDetailsLaboratoriesScreen";
import ListRayContractScreen from "./screens/services/ListRayContractScreen";
import AddRequestLaboritriesRayScreen from "./screens/services/AddRequestLaboritriesRayScreen";
import OrderDetailsRayScreen from "./screens/services/OrderDetailsRayScreen";
import TendersListScreen from "./screens/services/TendersListScreen";
import ChooseTenderForSectionScreen from "./screens/services/ChooseTenderForSectionScreen";
import TenderFoodStationeryHyhieneFurnitureScreen from "./screens/services/TenderFoodStationeryHyhieneFurnitureScreen";
import TenderTransferScreen from "./screens/services/TenderTransferScreen";
import TenderWashingScreen from "./screens/services/TenderWashingScreen";
import TenderEmploymentServicesScreen from "./screens/services/TenderEmploymentServicesScreen";
import TenderOperationMaintenanceScreen from "./screens/services/TenderOperationMaintenanceScreen";
import TenderClothesScreen from "./screens/services/TenderClothesScreen";
import TshirtLogoScreen from "./screens/services/TshirtLogoScreen";
import OrderDetailsTenderScreen from "./screens/services/OrderDetailsTenderScreen";
import MedicalTechnicalTeamListScreen from "./screens/services/MedicalTechnicalTeamListScreen";
import AddMedicalTeamScreen from "./screens/services/AddMedicalTeamScreen";
import PriceListScreen from "./screens/services/PriceListScreen";
import EditMedicalTeamScreen from "./screens/services/EditMedicalTeamScreen";
import ThanksMedicalTeam from "./component/ThanksMedicalTeam";
import PrintMedicalTeamScreen from "./screens/services/PrintMedicalTeamScreen";
import ConfirmRequestInsurance from "./component/ConfirmRequestInsurance";
import CollectServiceScreen from "./screens/services/CollectServiceScreen";
import OldBills from "./screens/services/OldBills";
import NewBills from "./screens/services/NewBills";
import AddOldBill from "./screens/services/AddOldBill";
import OfferPaymentScreen from "./screens/services/OfferPaymentScreen";
import OfferPaymentBankScreen from "./screens/services/OfferPaymentBankScreen";

import ConfirmAddRequestForServicesScreen from "./screens/services/ConfirmAddRequestForServicesScreen";
import ConfirmAddOfferForServicesScreen from "./screens/services/ConfirmAddOfferForServicesScreen";

/*********************** Insurrence Company ***********************/
import ThanksSubscribInsuranceCompany from "./screens/insurrenceCompany/ThanksSubscribInsuranceCompany";
import OnlineServiceInsuranceCompanyScreen from "./screens/insurrenceCompany/OnlineServiceInsuranceCompanyScreen";
import MedicalNetworkListScreen from "./screens/insurrenceCompany/MedicalNetworkListScreen";
import OrdersScreen from "./screens/insurrenceCompany/OrdersScreen";
import OrderDetalisInsuranceScreen from "./screens/insurrenceCompany/OrderDetalisInsuranceScreen";
import SentOfferInsuranceCompScreen from "./screens/insurrenceCompany/SentOfferInsuranceCompScreen";
import ConfirmSentOfferInsuranceComp from "./screens/insurrenceCompany/ConfirmSentOfferInsuranceComp";
import ConfirmAcceptOfferInsurance from "./screens/insurrenceCompany/ConfirmAcceptOfferInsurance";

import AttachLinkInsuranceCompScreen from "./screens/insurrenceCompany/AttachLinkInsuranceCompScreen";
import AttachInsuranceCertificateScreen from "./screens/insurrenceCompany/AttachInsuranceCertificateScreen";
import InsurancePaymentInfoScreen from "./screens/insurrenceCompany/InsurancePaymentInfoScreen";
import ConfirmCertifi from "./screens/insurrenceCompany/ConfirmCertifi";

import PromotionScreen from "./screens/services/PromotionScreen";

import clientRoutes from "./client/paths";
import employeeRoutes from "./employee/paths";

import InvoiceAllType from "./adminScreen/invoiceAllType/invoiceAllType";
import AdminNewBillsCleaning from "./adminScreen/AdminNewBillsCleaning";
import AdminNewBillsFood from "./adminScreen/AdminNewBillsFood";
import AdminNewBillsJob from "./adminScreen/AdminNewBillsJob";
import AdminNewBillsLabs from "./adminScreen/AdminNewBillsLabs";
import AdminNewBillsLaundry from "./adminScreen/AdminNewBillsLaundry";
import AdminNewBillsLibrary from "./adminScreen/AdminNewBillsLibrary";
import AdminNewBillsMedicalDevices from "./adminScreen/AdminNewBillsMedicalDevices";
import AdminNewBillsOffice from "./adminScreen/AdminNewBillsOffice";
import AdminNewBillsOperating from "./adminScreen/AdminNewBillsOperating";
import AdminNewBillsRateNetwrok from "./adminScreen/AdminNewBillsRateNetwrok";
import AdminNewBillsRays from "./adminScreen/AdminNewBillsRays";
import AdminNewBillsTransportation from "./adminScreen/AdminNewBillsTransportation";
import AdminNewBillsUniform from "./adminScreen/AdminNewBillsUniform";
import AdminNewBillsComparigPrices from "./adminScreen/AdminNewBillsComparigPrices";
import AdminNewBillsCollecting from "./adminScreen/AdminNewBillsCollecting";
import AdminNewBillsMedicalRent from "./adminScreen/AdminNewMedicalRent";

import EvaluationCertificate from "./certificate/certificate";

/******************************Admin Dashboard*****************************/
import NewOrder from "./adminScreen/newOrder/newOrder";
import Dashboard from "./adminScreen/Dashboard";
import BayanEmployeesPermessions from "./adminScreen/BayanEmployeesPermessions";
import MedicalTeamInsurrenceRequest from "./adminScreen/MedicalTeamInsurrenceRequest";
import MedicalTeamInformation from "./adminScreen/MedicalTeamInformation";
import AllFacility from "./adminScreen/AllFacility";
import FacilityInformation from "./adminScreen/FacilityInformation";
import UpdateFacilityInformation from "./adminScreen/UpdateFacilityInformation"
import MedicalNetworkAssessment from "./adminScreen/StatMedicalNetworkAssessment";
import Rentalmedicaldevices from "./adminScreen/StatRentalmedicaldevices";
import RentingDoctorsClinics from "./adminScreen/StatRentingDoctorsClinics";
import NewBillsAdmin from "./adminScreen/AdminNewBills";
import OldBillsAdmin from "./adminScreen/AdminOldBills";
import ComoarisonMedicalInsurance from "./adminScreen/StatComparisonMedicalInsurance";
import Achievement from "./adminScreen/StatAchievement";
import OperatingLaboratoryContracts from "./adminScreen/StatOperatingLaboratoryContracts";
import OperatingRadiologyContracts from "./adminScreen/StatOrganizingRadiologyContracts";
import OrganizingSupplyTenders from "./adminScreen/StatOrganizingSupplyTenders";
import AddEmployee from "./adminScreen/AdminAddEmployee";
import AdminChangePices from "./adminScreen/AdminPowerChangeInsidePrice";
import AdminChangeBankData from "./adminScreen/AdminPowerChangeBankData";
import AdminChangeEndorsement from "./adminScreen/AdminPowerChangeEndorsement";
import AdminChangeEnteredData from "./adminScreen/AdminPowerChangeEnteredData";
import AdminChangeSuspendAccount from "./adminScreen/AdminPowerSuspenAcount";
import AdminChangeInsideVideo from "./adminScreen/AdminPowerChangeInsideVedio";
import AdminStopService from "./adminScreen/AdminPowerStopSecrvice";
import AdminAddService from "./adminScreen/AdminPowerAddService";
import AdminAddNewSection from "./adminScreen/AdminAddNewSection";
import AdminSuspendSection from "./adminScreen/AdminPowerSuspendSection";
import AdminChangeTargets from "./adminScreen/AdminPowerChangeTargets";
import AdminClientManualData from "./adminScreen/AdminPowerClientManualData";
import HealthFacilies from "./adminScreen/HealthFacilties";
import FoodFacilities from "./adminScreen/FoodFacilities";
import LibraryFacilities from "./adminScreen/LibraruFacilities";
import CleaningFacilities from "./adminScreen/CleaningFacilities";
import OfficeFacilities from "./adminScreen/OfficeFacilities";
import TransportaionFacilities from "./adminScreen/TransportationFacilities";
import LaundaryFacilities from "./adminScreen/LaundaryFacilities";
import UniformFacilities from "./adminScreen/UniformFacilities";
import OpeartingFacilities from "./adminScreen/OpeartingFacilities";
import JobsFacilities from "./adminScreen/JobsFacilities";
import AdminPowerSpecialCases from "./adminScreen/AdminPowerSpecialCases";
import AdminPowerAddMultiSectors from "./adminScreen/AdminPowerAddMultiSectors";
import Messages from "./adminScreen/Messages";
import Notifications from "./adminScreen/Notifications";
import Notifications2 from "./client/Notifications";
import AllMedicalTeam from "./adminScreen/AllMedicalTeam";
import AdminEditEmployee from "./adminScreen/AdminEditEmployee";
import AdminManagersPage from "./adminScreen/AdminMangers";
import RateMedicalNetworks from "./adminScreen/RateMedicalNetworks";
import AdminNewBillsSubscription from "./adminScreen/AdminNewBillsSubscription"

import OffersCleaning from "./adminScreen/offers/tenders/OffersCleaning";
import OffersFood from "./adminScreen/offers/tenders/OffersFood";
import OffersJobs from "./adminScreen/offers/tenders/OffersJobs";
import OffersLaundry from "./adminScreen/offers/tenders/OffersLaundry";
import OffersLibrary from "./adminScreen/offers/tenders/OffersLibrary";
import OffersOffice from "./adminScreen/offers/tenders/OffersOffice";
import OffersOperating from "./adminScreen/offers/tenders/OffersOperating";
import OffersTransportation from "./adminScreen/offers/tenders/OffersTransportation";
import OffersUniform from "./adminScreen/offers/tenders/OffersUniform";

import OffersCollecting from "./adminScreen/offers/OffersCollecting";
import OffersComparingPrices from "./adminScreen/offers/OffersComparingPrices";
import OffersLabs from "./adminScreen/offers/OffersLabs";
import OffersRateNetworks from "./adminScreen/offers/OffersRateNetworks";
import OffersRay from "./adminScreen/offers/OffersRay";
import OffersRentingMedical from "./adminScreen/offers/OffersRentingMedical";
import OffersSellingMedicalDevices from "./adminScreen/offers/OffersSellingMedicalDevices";

import ReqDevices from "./adminScreen/offers/request-tables/devices";
import ReqClinics from "./adminScreen/offers/request-tables/clinics";
import reqRay from "./adminScreen/offers/request-tables/ray";
import ReqLab from "./adminScreen/offers/request-tables/lab";
import ReqCollecting from "./adminScreen/offers/request-tables/collecting";
import ReqTender from "./adminScreen/offers/request-tables/tender";

import EvaluationPrint from "./adminScreen/evaluation-print/newOrder";

import PrintReqDevices from "./adminScreen/offers/print/devices/newOrder";
import PrintReqClinics from "./adminScreen/offers/print/clinics/newOrder";

import printReqLab from "./adminScreen/offers/print/lab/newOrder";
import printReqRay from "./adminScreen/offers/print/xray/newOrder";
import PrintReqTenders from "./adminScreen/offers/print/tenders/newOrder";
import transferTenderPrint from "./adminScreen/offers/print/tender-transfer/newOrder";
import laundryTenderList from "./adminScreen/offers/print/tender-laundry/newOrder";
import JobTenderPrint from "./adminScreen/offers/print/tender-jobs/newOrder";
import OperatingTenderPrint from "./adminScreen/offers/print/tender-operating/newOrder";
import UnifromTenderPrint from "./adminScreen/offers/print/tender-unifrom/newOrder";
import PrivateRoute from './PrivateRoute';


function App() {
	const [popupTrigger, setPopupTrigger] = useState(false);
	const [branchName, setBranchName] = useState({ name: "", Branch_Name: "" });
	const [otherPopupTrigger, setOtherPopupTrigger] = useState(false);
	const [sectionName, setSectionName] = useState("");

	const state = useState();

	const popupHandler = (triggger, branchName) => {
		setPopupTrigger(triggger);
		setBranchName(branchName);
	};

	//popup for choose tender forSection
	const otherPopupHandler = (triggger, sectionName) => {
		setOtherPopupTrigger(triggger);
		setSectionName(sectionName);
	};

	return (
		<Router>
			<Route exact path="/login" component={LoginScreen} />
			<Route exact path="/createaccount" component={CreateAccountScreen} />
			<Route exact path="/thanksMsg" component={ThanksMsg} />
			<Route exact path="/changePassword" component={ChangePasswordScreen} />
			<Route path="/confirmChangePassword" component={ThankPassword} />

			<PrivateRoute authed={state?.userLogin}>
				<Router>
					<Route path="/" component={clientRoutes} />
				</Router>

				<Router>
					<Route path="/" component={employeeRoutes} />
				</Router>

				<Router>
					<Route exact path="/" component={LoginScreen} />
					<Route exact path="/new-bayan-order" component={NewOrder} />

					<Route exact path="/Messages" component={Messages} />
					<Route exact path="/Notifications" component={Notifications2} />

					<Route
						path="/MainChooseEnterprise"
						component={MainChooseSectionTypeScreen}
					/>
					
					<Route path="/FilesAndLocation" component={FileLocationScreen} />
					<Route path="/OwnerInfo" component={InfoAboutOwnerScreen} />
					<Route path="/EmployeeInfo" component={InfoEmployeeHasAccessScreen} />
					<Route path="/EndorsementPage" component={EndorsementScreen} />
					<Route path="/confirmSignUpPage" component={ConfirmSinup} />
					<Route
						path="/SubscriptionPayment"
						component={SubscriptionPaymentScreen}
					/>
					<Route
						path="/SubscriptionPaymentBank"
						component={SubscriptionPaymentBankScreen}
					/>
					<Route
						path="/RenewSubscriptionPayment"
						component={RenewSubscriptionPaymentScreen}
					/>
					<Route
						path="/RenewSubscriptionPaymentBank"
						component={RenewSubscriptionPaymentBankScreen}
					/>
					<Route
						path="/SubscriptionPaymentForSmallFacility"
						component={SubscriptionPaymentForSmallFacilityScreen}
					/>
					<Route
						path="/SubscriptionPaymentBankForSmallFacility"
						component={SubscriptionPaymentBankForSmallFacilityScreen}
					/>
					<Route
						path="/ThanksSubscriptionAmount"
						component={ThanksSubscriptionAmount}
					/>

					<Route
						path="/ConfirmAcceptOfferInsurance"
						component={ConfirmAcceptOfferInsurance}
					/>

					{/* ********************************Services************************ */}
					<Route path="/services" component={CPServicesOnlineScreen} />
					<Route
						path="/evaluationDataForMedicalNetwork"
						component={AddEvaluationDataScreen}
					/>
					
					<Route
						path="/evaluationDataForMedicalNetworkDetails"
						component={EvaluationDataDetailsScreen}
					/>

					<Route exact path="/thanksEvaluation" component={ThanksEvaluation} />
					<Route path="/promotion" component={PromotionScreen} />
					<Route
						path="/ListSellMedicalDevices"
						component={ListSellMedicalDevicesScreen}
					/>
					<Route
						path="/OrderMoreDetails/:id"
						component={MedicalDeviceOrderScreen}
					/>
					<Route
						path="/addRequestForMedicalDevices"
						component={AddRquestMedicalMachineScreen}
					/>

					<Route
						path="/ListRentDoctorsClincsServices"
						component={ListRentDoctorClincServicesScreen}
					/>
					<Route
						path="/addRequestRentDoctorsClincsServices"
						component={AddRequestDoctorsScreen}
					/>
					<Route path="/OrderDetails/:id" component={OrderDetailsScreen} />

					<Route
						path="/ListLabsContracts"
						component={ListLaboratriesContractScreen}
					/>
					<Route
						path="/addLaboratoriesRequest"
						component={AddRequestLaboratoriesScreen}
					/>
					<Route
						path="/OrderDetailsLaboratries/:id"
						component={OrderDetailsLaboratoriesScreen}
					/>

					<Route path="/ListRayContract" component={ListRayContractScreen} />
					<Route
						path="/addLaboratoriesRayRequest"
						component={AddRequestLaboritriesRayScreen}
					/>
					<Route path="/OrderDetailsRay/:id" component={OrderDetailsRayScreen} />
					<Route
						path="/confirmAddRequestForServices"
						component={ConfirmAddRequestForServicesScreen}
					/>
					<Route
						path="/confirmAddOffersForServices"
						component={ConfirmAddOfferForServicesScreen}
					/>
					<Route path="/TendersList" component={TendersListScreen} />
					<Route
						path="/chooseTypeOfTender"
						render={() => (
							<ChooseTenderForSectionScreen
								otherPopupHandler={otherPopupHandler}
								srctionName={sectionName}
							/>
						)}
					/>
					<Route
						path="/AddFSHFTender"
						component={TenderFoodStationeryHyhieneFurnitureScreen}
					/>
					<Route path="/AddTransferTender" component={TenderTransferScreen} />
					<Route path="/AddWashingTender" component={TenderWashingScreen} />
					<Route
						path="/AddEmploymentServicesTender"
						component={TenderEmploymentServicesScreen}
					/>
					<Route
						path="/AddOperationOrMaintainceTender"
						component={TenderOperationMaintenanceScreen}
					/>
					<Route path="/AddClothesTender" component={TenderClothesScreen} />
					<Route path="/tshirtLogo" component={TshirtLogoScreen} />
					<Route
						path="/OrderDetailsTender/:id"
						component={OrderDetailsTenderScreen}
					/>
					<Route
						path="/comparingMedicalMistakeWork"
						component={MedicalTechnicalTeamListScreen}
					/>
					<Route
						path="/addMedicalOrTechnicalTeam"
						component={AddMedicalTeamScreen}
					/>
					<Route path='/priceList/:id' component={PriceListScreen} />

					<Route
						path="/editMedicalOrTechnicalTeam/:id"
						component={EditMedicalTeamScreen}
					/>
					<Route exact path="/thanksMedicalTeam" component={ThanksMedicalTeam} />
					<Route
						exact
						path="/confirmRequestInsurance"
						component={ConfirmRequestInsurance}
					/>

					<Route path="/collectServices" component={CollectServiceScreen} />
					<Route path="/OldBills" component={OldBills} />
					<Route path="/NewBills" component={NewBills} />
					<Route path="/addOldBill" component={AddOldBill} />

					<Route
						path="/offerPayment/:id/:type"
						component={OfferPaymentScreen}
					/>

					<Route
						exact
						path="/offerPaymentBank/:id/:type"
						component={OfferPaymentBankScreen}
					/>

					{/* *****************Insurance Companies******************* */}
					<Route
						path="/ThanksSubscriptionForInsuranceCompany"
						component={ThanksSubscribInsuranceCompany}
					/>
					<Route
						path="/ServiceInsuranceCompany"
						component={OnlineServiceInsuranceCompanyScreen}
					/>
					<Route path="/MedicalNetworks" component={MedicalNetworkListScreen} />
					<Route path="/Orders" component={OrdersScreen} />
					<Route
						path="/OrderDetailsInsurance/:id"
						component={OrderDetalisInsuranceScreen}
					/>
					<Route
						path="/SentOfferInsuranceComp/:id"
						component={SentOfferInsuranceCompScreen}
					/>
					<Route
						path="/ConfirmSentOfferInsuranceComp"
						component={ConfirmSentOfferInsuranceComp}
					/>
					<Route
						path="/AttachPaiedLink/:id"
						component={AttachLinkInsuranceCompScreen}
					/>
					<Route
						path="/InsuranceCertificate/:id"
						component={AttachInsuranceCertificateScreen}
					/>
					<Route path="/ConfirmCertifi" component={ConfirmCertifi} />

					<Route
						exact
						path="/printMedicalTeam/:id"
						component={PrintMedicalTeamScreen}
					/>
					{/* *****************Admin Dashboard ******************* */}
					<Route exact path="/admin" component={Dashboard} />
					<Route
						exact
						path="/admin/bayan-employees-permessions"
						component={BayanEmployeesPermessions}
					/>
					<Route
						exact
						path="/admin/all-medical-team"
						component={AllMedicalTeam}
					/>
					<Route
						exact
						path="/admin/medical-team-insurrence-request"
						component={MedicalTeamInsurrenceRequest} // done by morad (طلبات التأمين)
					/>
					<Route
						exact
						path="/admin/print-medical-technical-team/:id" // done by morad (الطاقم الفني والإداري)
						component={MedicalTeamInformation}
					/>

					<Route
						exact
						path="/admin/all-facility" // done by morad (المنشآت)
						component={AllFacility}
					/>
					<Route
						exact
						path="/admin/print-facility/:id"
						component={FacilityInformation}
					/>
					<Route
						exact
						path="/admin/update-facility-info/:id"
						component={UpdateFacilityInformation}
					/>
					<Route
						exact
						path="/admin/medical-network-assessment"
						component={MedicalNetworkAssessment}
					/>
					<Route
						exact
						path="/admin/rental-medical-devices"
						component={Rentalmedicaldevices}
					/>
					<Route
						exact
						path="/admin/renting-doctors-clinics"
						component={RentingDoctorsClinics}
					/>
					<Route
						exact
						path="/admin/comoarison-medical-insurance"
						component={ComoarisonMedicalInsurance}
					/>
					<Route exact path="/admin/achievement" component={Achievement} />
					<Route
						exact
						path="/admin/operating-laboratory-contracts"
						component={OperatingLaboratoryContracts}
					/>
					<Route
						exact
						path="/admin/operating-radiology-contracts"
						component={OperatingRadiologyContracts}
					/>
					<Route
						exact
						path="/admin/organizing-supply-tenders"
						component={OrganizingSupplyTenders}
					/>
					<Route exact path="/admin/new-bills-admin" component={NewBillsAdmin} />
					<Route exact path="/admin/old-bills-admin" component={OldBillsAdmin} />
					<Route exact path="/admin/add-employee" component={AddEmployee} />
					<Route
						exact
						path="/admin/admin-change-inside-pices"
						component={AdminChangePices}
					/>
					<Route
						exact
						path="/admin/admin-change-bank-data"
						component={AdminChangeBankData}
					/>
					<Route
						exact
						path="/admin/admin-change-endorsement"
						component={AdminChangeEndorsement}
					/>
					<Route
						exact
						path="/admin/admin-change-entered-data"
						component={AdminChangeEnteredData}
					/>
					<Route
						exact
						path="/admin/admin-change-suspend-account"
						component={AdminChangeSuspendAccount}
					/>
					<Route
						exact
						path="/admin/admin-change-inside-video"
						component={AdminChangeInsideVideo}
					/>
					<Route
						exact
						path="/admin/admin-stop-service"
						component={AdminStopService}
					/>
					<Route
						exact
						path="/admin/admin-add-service"
						component={AdminAddService}
					/>
					<Route
						exact
						path="/admin/admin-add-section"
						component={AdminAddNewSection}
					/>
					<Route
						exact
						path="/admin/admin-suspend-section"
						component={AdminSuspendSection}
					/>
					<Route
						exact
						path="/admin/admin-client-manual-data"
						component={AdminClientManualData}
					/>
					<Route
						exact
						path="/admin/admin-change-targets"
						component={AdminChangeTargets}
					/>
					<Route exact path="/admin/health-facilies" component={HealthFacilies} />
					<Route exact path="/admin/food-facilies" component={FoodFacilities} />
					<Route
						exact
						path="/admin/library-facilies"
						component={LibraryFacilities}
					/>
					<Route
						exact
						path="/admin/cleaning-facilies"
						component={CleaningFacilities}
					/>
					<Route
						exact
						path="/admin/office-facilies"
						component={OfficeFacilities}
					/>
					<Route
						exact
						path="/admin/transportaion-facilies"
						component={TransportaionFacilities}
					/>
					<Route
						exact
						path="/admin/laundary-facilies"
						component={LaundaryFacilities}
					/>
					<Route
						exact
						path="/admin/uniform-facilies"
						component={UniformFacilities}
					/>
					<Route
						exact
						path="/admin/opearting-facilies"
						component={OpeartingFacilities}
					/>
					<Route exact path="/admin/jobs-facilies" component={JobsFacilities} />
					<Route
						exact
						path="/admin/special-cases"
						component={AdminPowerSpecialCases}
					/>
					<Route
						exact
						path="/admin/multi-sectors"
						component={AdminPowerAddMultiSectors}
					/>
					<Route
						exact
						path="/evaluation-certificate/:id"
						component={EvaluationCertificate}
					/>
					<Route exact path="/admin/managers" component={AdminManagersPage} />
					<Route
						exact
						path="/admin/rate-medical-networks"
						component={RateMedicalNetworks}
					/>
					{/*#endregion*/}
					{/* ***************** Offers of the admin Page ****************** */}
					<Route
						exact
						path="/admin/offers/tenders/cleaning"
						component={OffersCleaning}
					/>
					<Route exact path="/admin/offers/tenders/food" component={OffersFood} />
					<Route exact path="/admin/offer/tenders/jobs" component={OffersJobs} />
					<Route
						exact
						path="/admin/offer/tenders/laundry"
						component={OffersLaundry}
					/>
					<Route
						exact
						path="/admin/offer/tenders/library"
						component={OffersLibrary}
					/>
					<Route
						exact
						path="/admin/offer/tenders/office"
						component={OffersOffice}
					/>
					<Route
						exact
						path="/admin/offer/tenders/operating"
						component={OffersOperating}
					/>
					<Route
						exact
						path="/admin/offer/tenders/transportation"
						component={OffersTransportation}
					/>
					<Route
						exact
						path="/admin/offer/tenders/uniform"
						component={OffersUniform}
					/>
					<Route
						exact
						path="/admin/offer/collecting"
						component={OffersCollecting}
					/>
					<Route
						exact
						path="/admin/offer/comparing-prices"
						component={OffersComparingPrices}
					/>
					<Route exact path="/admin/offer/labs" component={OffersLabs} />
					<Route
						exact
						path="/admin/offer/rate-networks"
						component={OffersRateNetworks}
					/>
					<Route exact path="/admin/offer/rays" component={OffersRay} />
					<Route
						exact
						path="/admin/offer/renting-medical"
						component={OffersRentingMedical}
					/>
					<Route
						exact
						path="/admin/offer/selling-medical-devices"
						component={OffersSellingMedicalDevices}
					/>
					<Route exact path="/admin/messages" component={Messages} />
					<Route exact path="/admin/notifications" component={Notifications} />

					<Route
						exact
						path="/admin/edit-employee/:id"
						component={AdminEditEmployee}
					/>
					<Route exact path="/invoice-all-type/:id" component={InvoiceAllType} />

					<Route
						exact
						path="/admin/new-bills/comparing-prices"
						component={AdminNewBillsComparigPrices}
					/>
					<Route
						exact
						path="/admin/new-bills/labs"
						component={AdminNewBillsLabs}
					/>
					<Route
						exact
						path="/admin/new-bills/medical-devices"
						component={AdminNewBillsMedicalDevices}
					/>
					<Route
						exact
						path="/admin/new-bills/rate-network"
						component={AdminNewBillsRateNetwrok}
					/>

					<Route
						exact
						path="/admin/new-bills/subscription"
						component={AdminNewBillsSubscription}
					/>

					<Route
						exact
						path="/admin/new-bills/rays"
						component={AdminNewBillsRays}
					/>
					<Route
						exact
						path="/admin/new-bills/collecting"
						component={AdminNewBillsCollecting}
					/>
					<Route
						exact
						path="/admin/new-bills/medical-rent"
						component={AdminNewBillsMedicalRent}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/food"
						component={AdminNewBillsFood}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/cleaning"
						component={AdminNewBillsCleaning}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/job"
						component={AdminNewBillsJob}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/laundry"
						component={AdminNewBillsLaundry}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/library"
						component={AdminNewBillsLibrary}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/office"
						component={AdminNewBillsOffice}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/operating"
						component={AdminNewBillsOperating}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/transportation"
						component={AdminNewBillsTransportation}
					/>
					<Route
						exact
						path="/admin/new-bills/tenders/uniform"
						component={AdminNewBillsUniform}
					/>
					<Route
						exact
						path="/admin/networks/print/:id"
						component={EvaluationPrint}
					/>
					<Route
						exact
						path="/admin/request-table/devices/:id"
						component={ReqDevices}
					/>
					<Route
						exact
						path="/admin/request-table/clinics/:id"
						component={ReqClinics}
					/>
					<Route exact path="/admin/request-table/ray/:id" component={reqRay} />

					<Route exact path="/admin/request-table/lab/:id" component={ReqLab} />
					<Route
						exact
						path="/admin/request-table/collecting/:id"
						component={ReqCollecting}
					/>
					<Route
						exact
						path="/admin/request-table/tender/:id"
						component={ReqTender}
					/>

					<Route
						exact
						path="/offers/print/devices/:id"
						component={PrintReqDevices}
					/>
					<Route
						exact
						path="/offers/print/clinics/:id"
						component={PrintReqClinics}
					/>
					<Route exact path="/offers/print/lab/:id" component={printReqLab} />
					<Route exact path="/offers/print/ray/:id" component={printReqRay} />
					<Route
						exact
						path="/offers/print/tender/:id"
						component={PrintReqTenders}
					/>
					<Route
						exact
						path="/offers/print/tender/transfer/:id"
						component={transferTenderPrint}
					/>
					<Route
						exact
						path="/offers/print/tender/laundry/:id"
						component={laundryTenderList}
					/>
					<Route
						exact
						path="/offers/print/tender/job/:id"
						component={JobTenderPrint}
					/>
					<Route
						exact
						path="/offers/print/tender/operating/:id"
						component={OperatingTenderPrint}
					/>
					<Route
						exact
						path="/offers/print/tender/uniform/:id"
						component={UnifromTenderPrint}
					/>
				</Router>
			</PrivateRoute>
		</Router>
	);
}

export default App;
