import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import "./stat/info-ownr.css";
import arrow from "../imgs/arrow.png";
import rarrow from "../imgs/right-arrow1.png";
import error from "../imgs/381599_error_icon.png";
import check from "../imgs/check.png";
import Modal from "./components/Modal/ConfirmationModal";
import {
  fetchOneEmployee,
  updateEmployee,
  updateProfile,
} from "./api/Users/EmployeesApi";

function EditEmployee({ history }) {
  const params = useParams();

  const [profileID, setProfileID] = useState("");

  const [photo, setPhoto] = useState(false);
  const [path, setPath] = useState("ارفق صورة الهوية");
  const [wrong, setWrong] = useState(false);
  const [IsDisplayed1, setIsDisplayed1] = useState(false);
  const [palceHolder1, setPalceHolder1] = useState(true);
  const [IsDisplayed2, setIsDisplayed2] = useState(false);
  const [palceHolder2, setPalceHolder2] = useState(true);
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [phone_number, setPhone_No] = useState("");
  const [address, setAddress] = useState("");
  const [region, setRegion] = useState("");
  const [postal_code, setPostal_Code] = useState("");
  const [identity_number, setId] = useState("");
  const [email, setEmail] = useState("");
  const [post_box, setMailBox] = useState("");
  const [user_type, setUserType] = useState("admin");
  const [errors, setErrors] = useState({
    id_msg: " رقم الهوية  يجب ان يكون 10 ارقام ",
    email_msg1:
      "بريد الكترونى خاطئ example@bayan.med.sa و غير مسموح ب gmail,yahoo,hotmail,...",
    email_msg2: "",
  });
  const [errorsFlags, setErrorsFlags] = useState([false, false]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [confirmationModel, setConfirmationModel] = useState(false);

  const [submitionError, setsubmitionError] = useState("");

  const [powers, setPowers] = useState({
    tkym_al_shbka_altbya: false,
    t2gyr_wby3_al_aghza: false,
    t2gyr_5dmat_alatba_wal3ydat: false,
    mokrnet_as3ar: false,
    al_t7syl: false,
    tnzym_wtsh8yl_3kodalmo5tbrat: false,
    tnzym_wtsh8yl_3kodalmoash3a: false,
    tnzym_monksat_twryd: false,
    al_kta3_al_8z2y: false,
    kta3_krtsya_mktbya: false,
    kta3_al_nzafa: false,
    kta3_al_asas_al_mktby: false,
    kta3_al_nkl: false,
    kta3_al_8syl: false,
    kta3_mlabes_zy_mow7d: false,
    kta3_tsh8yl_wsyana: false,
    kta3_twzyf_w5dmat: false,
  });

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/");
    }

    fetchOneEmployee(params.id, userInfo).then((data) => {
      const profile = data.employee_profile[0];
      setName(data.name);
      setId(profile.identity_number);
      setPhone_No(profile.phone_number);
      setEmail(data.email);
      setAddress(profile.address);
      setMailBox(profile.post_box);
      setUserType(data.user_type);
      setPalceHolder1(false);
      setPalceHolder2(false);
      setRegion(profile.state);
      setCity(profile.city);
      setPostal_Code(profile.postal_code);
      setProfileID(profile.id);
      setPowers({
        tkym_al_shbka_altbya: data.tkym_al_shbka_altbya,
        t2gyr_wby3_al_aghza: data.t2gyr_wby3_al_aghza,
        t2gyr_5dmat_alatba_wal3ydat: data.t2gyr_5dmat_alatba_wal3ydat,
        mokrnet_as3ar: data.mokrnet_as3ar,
        al_t7syl: data.al_t7syl,
        tnzym_wtsh8yl_3kodalmo5tbrat: data.tnzym_wtsh8yl_3kodalmo5tbrat,
        tnzym_wtsh8yl_3kodalmoash3a: data.tnzym_wtsh8yl_3kodalmoash3a,
        tnzym_monksat_twryd: data.tnzym_monksat_twryd,
        al_kta3_al_8z2y: data.al_kta3_al_8z2y,
        kta3_krtsya_mktbya: data.kta3_krtsya_mktbya,
        kta3_al_nzafa: data.kta3_al_nzafa,
        kta3_al_asas_al_mktby: data.kta3_al_asas_al_mktby,
        kta3_al_nkl: data.kta3_al_nkl,
        kta3_al_8syl: data.kta3_al_8syl,
        kta3_mlabes_zy_mow7d: data.kta3_mlabes_zy_mow7d,
        kta3_tsh8yl_wsyana: data.kta3_tsh8yl_wsyana,
        kta3_twzyf_w5dmat: data.kta3_twzyf_w5dmat,
      });
    });
  }, []);

  const checkboxHandler = (e) => {
    const img = e.target.parentNode.querySelector("img");
    img.style.display = img.style.display === "block" ? "none" : "block";

    let newPowers = Object.assign({}, powers);
    newPowers[e.target.dataset.key] = !powers[e.target.dataset.key];
    setPowers(newPowers);
  };

  const changeSelect1 = (title) => {
    setIsDisplayed1(false);
    setPalceHolder1(false);
    setRegion(title);
  };

  const changeSelect2 = (title) => {
    setIsDisplayed2(false);
    setPalceHolder2(false);
    setCity(title);
  };

  const selectClick1 = () => {
    setIsDisplayed1(!IsDisplayed1);
  };
  const selectClick2 = () => {
    setIsDisplayed2(!IsDisplayed2);
  };

  const focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  const focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  const onChange = (e) => {
    if (e.target.value) {
      const ext = this.getExtension(e.target.value);
      if (ext === "png" || ext === "jpg" || ext === "pdf") {
        setPhoto(true);
        setPath(e.target.value);
        setWrong(false);
      } else {
        setPhoto(false);
        setPath(e.target.value);
        setWrong(true);
      }
    }
  };

  const formValidation = () => {
    let isValid = true;
    const flags = [false, false];
    if (identity_number.toString().trim().length != 10) {
      flags[0] = true;
      isValid = false;
    } else {
      flags[0] = false;
    }
    if (!email.endsWith("@bayan.med.sa")) {
      flags[1] = true;
      isValid = false;
    } else {
      flags[1] = false;
    }
    setErrorsFlags(flags);
    return isValid;
  };

  const onSubmit = (e) => {
    setsubmitionError("");
    console.log(e);
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      setErrorsFlags([false, false]);
      const employee = {
        photo,
        city,
        name,
        phone_number,
        address,
        state: region,
        postal_code,
        identity_number,
        email,
        post_box,
        user_type,
        password: password.length > 0 ? password : null,
      };
      updateEmployee(params.id, employee, userInfo).then(async (data) =>
        updateProfile(profileID, employee, userInfo).then((data) =>
          setConfirmationModel(true)
        )
      );
    }
  };

  const regions = [
    "الرياض",
    "مكة",
    "المدينة المنورة",
    "القصيم",
    "الشرقية",
    "عسير",
    "تبوك",
    "حائل",
    "الحدود الشمالية",
    "جازان",
    "نجران",
    "الباحة",
    "الجوف",
  ];

  const cities = [
    "مكة المكرمة",
    "جدة",
    "الطائف",
    "القنفذة",
    "الجموم",
    "رابغ",
    "الليث",
    "خليص",
    "أضم",
    "تربة",
    "الخرمة",
    "رنية",
    "الكامل",
    "العرضيات",
    "الموية",
    "ميسان",
    "بحرة",
  ];

  return (
    <AdminLayout>
      <div className="owner-info">
        <form onSubmit={onSubmit}>
          <div className="info-owner-content" style={{ height: "910px" }}>
            <p className="title-info">تعديل الموظف</p>
            <p className="title-hint"> عدل البيانات الخاصه بكم</p>
            {submitionError.length > 0 && (
              <Alert variant="primary">{submitionError}</Alert>
            )}
            <div className="input-group" style={{ width: "90%" }}>
              <div className="right-data">
                <div className="input-lable">
                  <label htmlFor="name">الاسم</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(value) => setName(value.target.value)}
                    placeholder="اكتب الاسم هنا"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable">
                  <label htmlFor="phone_number">رقم الهاتف</label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    value={phone_number}
                    onChange={(value) => setPhone_No(value.target.value)}
                    placeholder="ادخل الرقم هنا"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable">
                  <label htmlFor="address">العنوان</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(value) => setAddress(value.target.value)}
                    placeholder="اكتب العنوان هنا"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable">
                  <label>المنطقة</label>
                  <div className="select-city">
                    <div className="select-btn" onClick={selectClick1}>
                      {palceHolder1 && (
                        <span style={{ color: "var(--lightgray-color)" }}>
                          اختر المنطقة
                        </span>
                      )}
                      <span>{region}</span>
                      <img src={rarrow} />
                    </div>
                    {IsDisplayed1 && (
                      <div className="select-content">
                        {regions.map((option) => (
                          <div
                            data-name="region"
                            className="select-item"
                            onClick={(e) => {
                              changeSelect1(e.target.textContent);
                            }}
                          >
                            {" "}
                            {option}{" "}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-lable">
                  <label htmlFor="postal_code">الرمز البريدى</label>
                  <input
                    type="text"
                    id="postal_code"
                    name="postal_code"
                    value={postal_code}
                    onChange={(value) => setPostal_Code(value.target.value)}
                    placeholder="ادخل الرمز البريدى"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable">
                  <label htmlFor="password">كلمة المرور</label>
                  <input
                    type="text"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(value) => setPassword(value.target.value)}
                    placeholder="ادخل كلمة المرور"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable" style={{ margin: "50px 0px" }}>
                  <div
                    className="p-c"
                    style={{ display: "flex", width: "120%" }}
                  ></div>
                </div>
              </div>
              <div className="left-data">
                <div className="input-lable">
                  <label htmlFor="identity_number">رقم الهوية</label>
                  <input
                    type="text"
                    id="identity_number"
                    name="identity_number"
                    value={identity_number}
                    onChange={(value) => setId(value.target.value)}
                    placeholder="اكتب رقم الهوية"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                {errorsFlags[0] && (
                  <div className="error">
                    <img src={error} />
                    {errors.id_msg}
                  </div>
                )}
                <div className="input-lable">
                  <label htmlFor="name">الايميل الرسمي</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(value) => setEmail(value.target.value)}
                    placeholder="اكتب الايميل الرسمى"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                {errorsFlags[1] && (
                  <div className="error">
                    <img src={error} />
                    {errors.email_msg1}
                  </div>
                )}

                <div className="two-inpt" style={{ display: "flex" }}>
                  <div className="input-lable">
                    <label htmlFor="post_box">رقم ص.ب</label>
                    <input
                      type="text"
                      id="post_box"
                      name="post_box"
                      value={post_box}
                      onChange={(value) => setMailBox(value.target.value)}
                      placeholder="اكتب رقم ص . ب"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                  <div className="input-lable">
                    <label>تحديد مهام الموظف</label>
                    <div
                      className="input-group"
                      style={{ margin: "0", display: "flex" }}
                    >
                      <div
                        style={{
                          flexGrow: "1",
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="admin"
                          type="radio"
                          name="user_type"
                          value="admin"
                          style={{ marginLeft: "10px" }}
                          onChange={(value) => setUserType(value.target.value)}
                          checked={user_type === "admin"}
                        />
                        <label htmlFor="admin" style={{ marginTop: "10px" }}>
                          مدير
                        </label>
                      </div>
                      <div
                        style={{
                          flexGrow: "3",
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="employee"
                          type="radio"
                          name="user_type"
                          value="employee"
                          style={{ marginLeft: "10px" }}
                          onChange={(value) => setUserType(value.target.value)}
                          checked={user_type === "employee"}
                        />
                        <label htmlFor="employee" style={{ marginTop: "10px" }}>
                          موظف
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-lable">
                  <label htmlFor="name">المدينة</label>
                  <div className="select-city">
                    <div className="select-btn" onClick={selectClick2}>
                      {palceHolder2 && (
                        <span style={{ color: "var(--lightgray-color)" }}>
                          اختر المدينة
                        </span>
                      )}
                      <span>{city}</span>
                      <img src={rarrow} />
                    </div>
                    {IsDisplayed2 && (
                      <div className="select-content">
                        {cities.map((option) => (
                          <div
                            data-name="city"
                            className="select-item"
                            onClick={(e) => {
                              changeSelect2(e.target.textContent);
                            }}
                          >
                            {" "}
                            {option}{" "}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-lable" style={{ marginTop: "120px" }}>
                  <label htmlFor="password">تأكيد كلمة المرور</label>
                  <input
                    type="text"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(value) => setConfirmPassword(value.target.value)}
                    placeholder="ادخل كلمة المرور"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div
                  className="input-lable"
                  style={{ margin: "50px 0px" }}
                ></div>
              </div>
            </div>
          </div>
          <div className="group-btn" style={{ justifyContent: "center" }}>
            <button
              style={{ margin: "0 auto" }}
              type="submit"
              className="nextBtn"
            >
              <img src={arrow} alt="" />
              تعديل موظف
            </button>
          </div>
        </form>
      </div>
      {confirmationModel && (
        <Modal
          title={"تعديل "}
          visibilty={true}
          body={"تم تعديل الموظف بنجاح!"}
          onClosing={() => setConfirmationModel(false)}
        />
      )}
    </AdminLayout>
  );
}

export default EditEmployee;
