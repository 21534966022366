import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow from "../../imgs/arrow.png";
import "../../styles/change_insidePrices.css";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";

class ChangeTargets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      free_From: "",
      free_To: "",
      smallFacility_From: "",
      smallFacility_to: "",
      mediumFaciliry_From: "",
      mediumFaciliry_To: "",
      bigFacility_From: "",
      bigFacility_To: "",
      xlFacility_From: "",
      xlFacility_To: "",
      showModal: false,
    };
  }

  submitHandler = () => {
    this.setState({ showModal: true });
  };

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  render() {
    const {
      free_From,
      free_To,
      smallFacility_From,
      smallFacility_to,
      mediumFaciliry_From,
      mediumFaciliry_To,
      bigFacility_From,
      bigFacility_To,
      xlFacility_From,
      xlFacility_To,
    } = this.state;

    return (
      <div className="change_inside_prices">
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الهدف</label>
            <input
              type="text"
              id="smallFacility_to"
              name="smallFacility_to"
              value={smallFacility_to}
              onChange={this.changeHandler}
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="title">تقييم الشبكات الطبية</div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الهدف</label>
            <input
              type="text"
              id="mediumFaciliry_To"
              name="mediumFaciliry_To"
              value={mediumFaciliry_To}
              onChange={this.changeHandler}
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="title">تأجير أو بيع الأجهزة الطبية</div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الهدف</label>
            <input
              type="text"
              id="bigFacility_To"
              name="bigFacility_To"
              value={bigFacility_To}
              onChange={this.changeHandler}
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="title">تأجير خدمات الأطباء والعيادات</div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الهدف</label>
            <input
              type="text"
              id="xlFacility_From"
              name="xlFacility_From"
              value={xlFacility_From}
              onChange={this.changeHandler}
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="title">
            مقارنة أسعار تامين اخطاء ممارسة المهن الطبية
          </div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الهدف</label>
            <input
              type="text"
              id="free_To"
              name="free_To"
              value={free_To}
              onChange={this.changeHandler}
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="title">التحصيل</div>
        </div>
        <div className="text-center d-flex justify-content-center">
          <Button className="text-center btnNext" onClick={this.submitHandler}>
            <img src={arrow} alt="" />
            تنفيذ
          </Button>
        </div>
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default ChangeTargets;
