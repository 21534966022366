import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import Laboratory_ontracts from "../component/stat/Laboratory_ontracts";

function OperatingLaboratoryContracts({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div style={{ width: "300px" }} className="heading text-center">
              الأحصائيات
            </div>

            <Laboratory_ontracts />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default OperatingLaboratoryContracts;
