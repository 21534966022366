import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
import complete from "../../imgs/check1.png";
import "../../styles/info-ownr.css";
import "../../styles/tender_washing.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import check from "../../imgs/check.png";
import {
	Col,
	Card,
} from "react-bootstrap";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import "../../styles/tender_list.css";
import menu from "../../imgs/menu.png";
import { Link } from "react-router-dom";

import { AddMedicalDevice } from '../../actions/serviceActions'
import axios from 'axios';


function ListSellMedicalDevicesScreen({ history }) {

	const [photo, setPhoto] = useState(false)

	const [path, setPath] = useState('ارفق صورة')

	const [image, setImage] = useState(null)
	const [image1, setImage1] = useState(null)
	const [image2, setImage2] = useState(null)
	const [image3, setImage3] = useState(null)

	const [wrong, setWrong] = useState('')

	const [photoNumber, setPhotoNumber] = useState(0)

	const [photos, setPhotos] = useState([
		"https://via.placeholder.com/150",
		"https://via.placeholder.com/150",
		"https://via.placeholder.com/150",
		"https://via.placeholder.com/150",
	])

	const [sectors, setSectors] = useState([
		"القطاع الصحي",
		"القطاع الغذائي",
		"قطاع القرطاسية المكتبية",
		"قطاع الأثاث المكتبي",
		"قطاع النقل",
		"قطاع الغسيل",
		"قطاع ملابس الري الموحد",
		"قطاع التشغيل و الصيانة",
		"قطاع التوظيف والخدمات",
	])

	const [requestDetails, setRequestDetails] = useState('')

	const [details, setDetails] = useState('')

	const [deliveryDate, DeliveryDate] = useState('')

	const [offerPeriod, setOfferPeriod] = useState('')

	const [weight, setWeight] = useState('')

	const [requestType, setRequestType] = useState('')

	const [need, setNeed] = useState('')

	const [isNew, setIsNew] = useState('')

	const [price, setPrice] = useState('')

	const [isWeight, setIsWeight] = useState(false)

	const [checkBox, setCheckBox] = useState(false)

	const [errors, setErrors] = useState({
		check_msg: " يرجى الموافقة على الأقرار من فضلك ",
		imgErrors: "يجب عليك تحميل 4 صور للمنتج",
		allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",

	})

	const [errorsFlags, setErrorsFlags] = useState([false, false, false])

	const [isValid, setIsValid] = useState(true)


	const dispatch = useDispatch()

	const medicalDeviceAdd
		= useSelector(state => state.medicalDeviceAdd)

	const { error, medicalDeviceAddData, success } = medicalDeviceAdd

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			if (!userInfo.is_activated) {
				history.push('/services')
			}
		}

	}, [dispatch, history, userInfo])


	const checkboxHandler = () => {
		setCheckBox(checkBox === true ? false : true);
	};


	const focusHandler = (e) => {
		e.target.parentNode.style.color = "#0fb0a9";
		e.target.style.color = "#0fb0a9";
		e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
		e.target.style.borderColor = "#0fb0a9";
	};

	const focusoutHandler = (e) => {
		e.target.parentNode.style.color = "";
		e.target.style.color = "";
		e.target.style.boxShadow = "";
		e.target.style.borderColor = "";
	};

	const getExtension = (filename) => {
		var parts = filename.split(".");
		return parts[parts.length - 1];
	};

	const onUpload = (e) => {
		if (e.target.value) {
			const ext = getExtension(e.target.value);
			if (ext === "png" || ext === "jpg" || ext === "pdf") {
				setPhoto(true)
				setPath(e.target.files[0])
				setWrong(false)
			} else {
				setPhoto(false)
				setWrong(true)
			}
		}
	};

	const onUploadN = (e) => {
		let flags = [false, false, false];
		let valid = false
		const filesNumber = Array.from(e.target.files).length
		if (filesNumber !== 4) {
			flags[1] = true;
			valid = false;
		} else {
			setImage(e.target.files[0])
			setImage1(e.target.files[1])
			setImage2(e.target.files[2])
			setImage3(e.target.files[3])
			flags[1] = false;
			valid = true;
		}
		setErrorsFlags(flags);
		setIsValid(valid)
	};

	const handleDate = (d) => {
		const offset = d.getTimezoneOffset();
		let newDate = new Date(d.getTime() - offset * 60 * 1000);
		return newDate.toISOString().split("T")[0];
	};

	const checkStyle = checkBox ? { display: "block" } : {};

	const formValidation = () => {
		let flags = [false, false, false]
		let valid = true
		const nodes = document.querySelectorAll("input");
		nodes.forEach((e) => {
			if (e.value === '') {
				flags[2] = true;
				valid = false;
				setErrorsFlags(flags);
				console.log("hello")
				return valid;
			}
		});

		if (valid === true) {
			if (!checkBox) {
				console.log("hi")
				flags[0] = true;
				valid = false;
				setErrorsFlags(flags);
				return valid;
			}
			else {
				flags[0] = false;
				valid = true;
				return valid;
			}

		}

	};

	const sendData = (e) => {
		e.preventDefault()
		const formvalid = formValidation();
		console.log(formvalid)
		console.log(errorsFlags)
		if (formvalid) {

			let data = new FormData();
			data.append("title", requestDetails);
			data.append("request_type", requestType);
			data.append("details", details);
			data.append("price", price);
			data.append("receive_date", deliveryDate);
			data.append("offer_duration", offerPeriod);
			data.append("device_status", isNew);
			data.append("shipping", need);
			data.append("image", image);
			data.append("image1", image1);
			data.append("image2", image2);
			data.append("image3", image3);
			if (isWeight) {
				data.append("weight", weight);
			}
			data.append("service", "2");
			const config = {
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${userInfo.access}`
				}
			}
	
			axios.post(
				`/services/medical_device_request/`, data,
				config
			).then(() => {
				history.push('/confirmAddRequestForServices')
			})
			.catch(() => {
				alert('خطأ');
			});

		}
	}

	return (
		<div>
			<ControlPanelHeader
				exit1={true}
				notifi={true}
				msg={true}
				files={false}
				stat={false}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				exitDest={"/services"}
				fileDest={"/FacilityFiles"}
				statDest={'/collectServices'}
				style={"threetwo_section"}
			/>
			<div className="tender_washing">

				<div style={{ height: "720px" }} className="tender_washing_content">
					<p className="title-info text-center">إضافة عرض</p>

					<div className="input-group">
						<div className="right-data">
							<div className="input-lable">
								<label htmlFor="name">العنوان</label>
								<input
									type="text"
									id="requestDetails"
									name="requestDetails"
									value={requestDetails}
									onChange={(e) => setRequestDetails(e.target.value)}
									placeholder=" اكتب العنوان"
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
							</div>

							<div className="input-lable">
								<label htmlFor="name">السعر</label>
								<input
									type="number"
									id="price"
									name="price"
									value={price}
									onChange={(e) => setPrice(e.target.value)}
									placeholder=" اكتب السعر"
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
								<div
									style={{
										position: "absolute",
										top: "35px",
										left: "25px",
										color: "#ababab",
									}}
								>
									ريال
								</div>
							</div>

							<div className="input-lable textaria" style={{ height: "264px" }}>
								<label htmlFor="name"> تفاصيل العرض </label>
								<textarea
									rows="8"
									cols="50"
									type="text"
									id="details"
									name="details"
									value={details}
									onChange={(e) => setDetails(e.target.value)}
									placeholder="اكتب تفاصيل العرض"
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
							</div>
							<div className="input-content">
								<p>إرفاق الصور</p>
								<label for="upload-photo">
									<div className="input">
										<img src={upload} alt="" />
										<div>{path}</div>
									</div>
								</label>
								<input
									onChange={onUploadN}
									type="file"
									name="photo"
									id="upload-photo"
									multiple
								/>
								{photo && <img className="complete" src={complete} alt="" />}
								{wrong && <AiOutlineCloseCircle />}
								{errorsFlags[1] && (
									<div className="error">
										<img src={error} />
										{errors.imgErrors}
									</div>
								)}
								{errorsFlags[2] && (
									<div className="error">
										<img src={error} />
										{errors.allFieldRequired}
									</div>
								)}
							</div>

							{errorsFlags[0] && (
								<div className="error">
									<img src={error} />
									{errors.check_msg}
								</div>
							)}
						</div>
						<div className="left-data">
							<div className="input-lable">
								<label htmlFor="name">نوع العرض</label>
								<select
									type="text"
									id="type"
									name="requestType"
									value={requestType}
									onChange={(e) => setRequestType(e.target.value)}
									placeholder="اكتب نوع العرض"
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								>
									<option value="">إختر</option>
									<option value="بيع">بيع</option>
									<option value="تأجير">تأجير</option>
								</select>
							</div>
							<div className="input-lable">
								<label htmlFor="weight"> الوزن</label>
								<input
									type="number"
									id="weight"
									name="weight"
									value={weight}
									onChange={(e) => setWeight(e.target.value)}
									placeholder=" تحديد الوزن"
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
								<div
									style={{
										position: "absolute",
										top: "35px",
										left: "25px",
										color: "#ababab",
									}}
								>
									كيلو جرام
								</div>
							</div>

							<div className="input-lable">
								<label htmlFor="offerPeriod"> مدة العرض</label>
								<input
									type="number"
									id="offerPeriod"
									name="offerPeriod"
									value={offerPeriod}
									onChange={(e) => setOfferPeriod(e.target.value)}
									placeholder=" مدة العرض"
									onFocus={focusHandler}
									onBlur={focusoutHandler}
								/>
								<div
									style={{
										position: "absolute",
										top: "35px",
										left: "25px",
										color: "#ababab",
									}}
								>
									يوم
								</div>
							</div>

							<div
								style={{
									display: "flex",
									marginTop: "50px",
									justifyContent: "space-between",
								}}
							>
								<div
									style={{ borderLeft: "1px solid black" }}
									className="radio_input"
								>
									<p>هل الشحنة تحتاج شركة توصيل</p>
									<div className="radio_group">
										<label>
											<input
												type="radio"
												name="need"
												value="نعم"
												checked={need}
												onChange={(e) => { setNeed(e.target.value === 'نعم'); setIsWeight(e.target.value === "نعم" ? true : false) }}
											/>
											نعم
										</label>
										<label>
											<input
												type="radio"
												value="لا"
												name="need"
												onChange={(e) => { setNeed(e.target.value === 'نعم'); setIsWeight(e.target.value === "نعم" ? true : false) }}
											/>
											لا
										</label>
									</div>
								</div>
								<div className="radio_input">
									<p>هل المنتج </p>
									<div className="radio_group">
										<label>
											<input
												type="radio"
												name="isNew"
												value="نعم"
												checked={isNew}
												onChange={(e) => setIsNew(e.target.value === 'نعم')}
											/>
											جديد
										</label>
										<label>
											<input
												type="radio"
												value="لا"
												name="isNew"
												onChange={(e) => setIsNew(e.target.value === 'نعم')}
											/>
											مستعمل
										</label>
									</div>
								</div>
							</div>
							<div className="remember-me">
								<p onClick={checkboxHandler}>إقرار بان المنتج سليم</p>
								<span onClick={checkboxHandler}></span>
								<img
									style={checkStyle}
									onClick={checkboxHandler}
									src={check}
									alt="check"
								/>
							</div>
							<div className="images_box">
								{photos.map((value) => {
									return <img className="images_box_img" alt="img" src={value} />;
								})}
							</div>
						</div>
					</div>
				</div>
				<button className="enter_service" onClick={sendData}>
					<img className="icon" src={arrow} alt="" />
					اضف العرض
				</button>
			</div>
		</div>
	)
}

export default ListSellMedicalDevicesScreen
