import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../styles/create_account.css";
import Message from '../component/Message'
import { register } from '../actions/userActions'
import LoginName from "../component/LoginName";


function CreateAccountScreen({ location, history }) {
    const [commercialNum, setcommercialNum] = useState('')
    const [email, setEmail] = useState('')

    const [errors] = useState({
        commercial_No_Start: "رقم السجل التجاري يجب ان يبدأ ب  40 ، 10 أو 11",
        commercial_No_Len: "رقم السجل التجاري يجب ان يكون 10 ارقام",
        emailMsg: "يرجى كتابة البريد الألكترونى الرسمى للمنشأة",
        emailMsg1: "@gmail, @yahoo,@hotmail , etc.. غير مسموح ب ",
    })

    const [errorsFlags, setErrorsFlags] = useState([ false, false, false])
    
    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userRegister = useSelector(state => state.userRegister)

    const { error, userInfo, success, loading } = userRegister

    useEffect(() => {
        if(userInfo) {
            if (!userInfo.is_registered) {
                history.push("/thanksMsg")
            } else {
                history.push("/services")
            }
        }
        
    }, [history, userInfo, redirect])

    const formValidation = () => {
        let isValid = true;
        const flags = [false, false, false];
        
        if (commercialNum.trim().length !== 10) {
          flags[0] = true;
          isValid = false;
        } else {
          flags[0] = false;
        }
        if (!(commercialNum.startsWith("40") || commercialNum.startsWith("10") || commercialNum.startsWith("11"))) {
          flags[1] = true;
          isValid = false;
        } else {
          flags[1] = false;
        }
        if (email.includes("@yahoo") || email.includes("@hotmail") || email==='' || email.includes("@gmail")) {
            flags[2] = true;
            isValid = false;
          } else {
            flags[2] = false;
          }
    
        setErrorsFlags(flags);
        return isValid;
      };

    const submitHandler = (e) => {
        e.preventDefault()
        const isValid = formValidation();
        if (isValid) {
            dispatch(register(commercialNum, email))
            if(success) {
              history.push('/thanksMsg')
            }
        }
        
    }

    return (
        <div className="create-account-page">
        <div className="login">
          <div className="title text-center">إنشاء حساب جديد</div>
          <form onSubmit={submitHandler}>
            <input
              className="commercial-No"
              type="text"
              name="commercialNum"
              value={commercialNum}
              placeholder="رقم السجل التجاري"
              onChange= {(e) => setcommercialNum(e.target.value)}
            />
            {errorsFlags[0] && <div className='error'>{errors.commercial_No_Len}</div> }
            {errorsFlags[1] && <div className='error'>{errors.commercial_No_Start}</div> }
            <div className="password-info">
              <input
                className="password"
                type="email"
                name="email"
                value={email}
                placeholder="البريد الالكتروني الرسمي"
                onChange= {(e) => setEmail(e.target.value)}
              />
              {errorsFlags[2] && <div className='error'>{errors.emailMsg}</div> }
              {errorsFlags[2] && <div className='error'>{errors.emailMsg1}</div> }
            </div>
            <button type='submit' className="login-btn">انشاء حساب جديد</button>
            { error && <Message variant='danger'>{error}</Message>}
          </form>
        </div>
        <LoginName Home={true} Facility={false}/>
      </div>
    )
}

export default CreateAccountScreen
