import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";

import "../styles/info-ownr.css";
import arrow from '../imgs/arrow.png'
import complete from '../imgs/check1.png'
import upload from '../imgs/upload (1).png'
import rarrow from "../imgs/right-arrow1.png";
import { Row, Col } from 'react-bootstrap'
import err from '../imgs/381599_error_icon.png'
import { AiOutlineCloseCircle } from "react-icons/ai";
import ControlPanelHeader from '../component/ControlPanelHeader'
import Message from '../component/Message'
import {FACILITY_OWNER_REGISTER_RESET} from '../constants/facilityConstants'


import { facilityEmployeeAdd } from '../actions/facilityActions'

function InfoEmployeeHasAccessScreen({ history }) {

    const [photo, setPhoto] = useState(false)

    const [path, setPath] = useState('نرجوا ارفاق صورة سجل بجودة عاليه و واضحة')

    const [wrong, setWrong] = useState(false)

    const [IsDisplayed1, setIsDisplayed1] = useState(false)

    const [placeHolder1, setPlaceHolder1] = useState(true)

    const [IsDisplayed2, setIsDisplayed2] = useState(false)

    const [placeHolder2, setPlaceHolder2] = useState(true)

    const [city, setCity] = useState('')

    const [name, setName] = useState('')

    const [phoneNumber, setPhoneNumber] = useState('')

    const [address, setAddress] = useState('')

    const [region, setRegion] = useState('')

    const [postalCode, setPostalCode] = useState('')

    const [id, setId] = useState('')

    const [email, setEmail] = useState('')

    const [mailBox, setMailBox] = useState('')

    const [errorsFlags, setErrorsFlags] = useState([ false, false, false])

    const [errors, setErrors] = useState({
        id_msg: " رقم الهوية  يجب ان يكون 10 ارقام ",
        email_msg1: "بريد الكترونى خاطئ example@bayan.com و غير مسموح ب gmail,yahoo,hotmail,...",
        allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",
    })

    const regions = [
        "الرياض",
        "مكة",
        "المدينة المنورة",
        "القصيم",
        "الشرقية",
        "عسير",
        "تبوك",
        "حائل",
        "الحدود الشمالية",
        "جازان",
        "نجران",
        "الباحة",
        "الجوف",
    ];

    const cities = [
        "مكة المكرمة",
        "جدة",
        "الطائف",
        "القنفذة",
        "الجموم",
        "رابغ",
        "الليث",
        "خليص",
        "أضم",
        "تربة",
        "الخرمة",
        "رنية",
        "الكامل",
        "العرضيات",
        "الموية",
        "ميسان",
        "بحرة",
        
    ];

    const dispatch = useDispatch()

    const facilityEmployeeRegister
    = useSelector(state => state.facilityEmployeeRegister)

    const {error:errorEmployee, success:successEmployee, facilityEmployee } = facilityEmployeeRegister

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
        if (!userInfo) {
            history.push('/')
        } else {

            if (userInfo.is_registered){
                history.push('/services')
            } else {
                if(successEmployee){

                    setName(facilityEmployee.name)
                    setPhoneNumber(facilityEmployee.phone_number)
                    setId(facilityEmployee.identity_number)
                    setAddress(facilityEmployee.address)
                    setRegion(facilityEmployee.state)
                    setPostalCode(facilityEmployee.postal_code)
                    setEmail(facilityEmployee.user.email)
                    setMailBox(facilityEmployee.post_box)
                    setCity(facilityEmployee.city)
                    setPath(facilityEmployee.identity_pic)
                    //dispatch({ type: FACILITY_OWNER_REGISTER_RESET})
                }
            }
            
        }
        
    }, [dispatch, history, userInfo, successEmployee])


    const changeSelect1 = (title) => {
        setIsDisplayed1(false)
        setPlaceHolder1(false)
        setRegion(title)
    };

    const changeSelect2 = (title) => {
        setIsDisplayed2(false)
        setPlaceHolder2(false)
        setCity(title)
    };

    const focusHandler = (e) => {
        e.target.parentNode.style.color = "#0fb0a9";
        e.target.style.color = "#0fb0a9";
        e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
        e.target.style.borderColor = "#0fb0a9";
    };

    const focusoutHandler = (e) => {
        e.target.parentNode.style.color = "";
        e.target.style.color = "";
        e.target.style.boxShadow = "";
        e.target.style.borderColor = "";
    };

    const selectClick1 = () => {
        setIsDisplayed1(IsDisplayed1 === true ? false : true);
    };

    const selectClick2 = () => {
        setIsDisplayed2(IsDisplayed2 === true ? false : true);
    };
    
    const getExtension = (filename) => {
        var parts = filename.split(".");
        return parts[parts.length - 1];
    };
    
    const onChange = (e) => {
        if (e.target.value) {
            const ext = getExtension(e.target.value);
            if (ext === "png" || ext === "jpg" || ext === "pdf") {
                setPhoto(true)
                setPath(e.target.files[0])
                setWrong(false)
            } else {
                setPhoto(false)
                setWrong(true)
            }
        }
    };

    const formValidation = () => {    
        let isValid = true;
        const flags = [false, false, false];

        const nodes = document.querySelectorAll("input");
        nodes.forEach((e) => {
            if(e.value === '') {
            flags[2] = true;
            isValid = false;
            } else {
            flags[2] = false;
            }
        });

        console.log(path)

        if ( (region === '') || (city === '') || (path === '') ) {
            
            flags[2] = true;
            isValid = false;
        } else {
            flags[2] = false;
        }
        
        if ((id.toString()).length != 10) {
          flags[0] = true;
          isValid = false;
        } else {
          flags[0] = false;
        }
        if (email.includes("@yahoo") || email.includes("@hotmail") || email==='' || email.includes("@gmail")) {
            flags[1] = true;
            isValid = false;
        } else {
          flags[1] = false;
        }
    
        setErrorsFlags(flags)
        return isValid;
    };

    const onSubmit = (e) => {
        e.preventDefault()

        let data = new FormData();
        data.append("name", name);
        data.append("phone_number", phoneNumber);
        data.append("identity_number", id);
        data.append("address", address);
        data.append("state", region);
        data.append("postal_code", postalCode);
        data.append("id", id);
        data.append("email", email);
        data.append('post_box', mailBox);
        data.append('city', city);
        data.append("identity_pic",path);
        
        const isValid = formValidation();
        if (isValid) {
            setErrorsFlags(false);
            dispatch(facilityEmployeeAdd(data))
            history.push("/EndorsementPage")
        }
        
    }

    return (
        <div className="owner-info">
            <ControlPanelHeader exit1={true} notifi={true} msg={true} msgDest={"/Messages"}
            notifidest={"/Notifications"} exitDest={"/"}  style={"three_section"}/>

            <form onSubmit={onSubmit}>
                <div className="info-owner-content mx-3 px-3">
                    <p className="title-info text-center" style={{ marginTop: "52px", fontSize:'20px' }}>ادخل بيانات الموظف المصرح له بأستخدام المنصة</p>
                    <p className="title-hint text-center">فضلا اكمل البيانات الخاصه بكم</p>
     
                    <div className="input-group mx-0" style={{ backgroundColor: "white", width: "100%" }}>
                        <div className="right-data w-100">
                            <Row>
                                <Col sm={12} md={6} lg={4}>
                                    <div className="input-lable w-100">
                                        <label htmlFor="name">الاسم</label>
                                        <input 
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="اكتب الاسم هنا"
                                            onFocus={focusHandler}
                                            onBlur={focusoutHandler}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                <div className="input-lable w-100">
                                        <label htmlFor="name">رقم الهاتف</label>
                                        <input
                                            type="text"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            placeholder="ادخل الرقم هنا"
                                            onFocus={focusHandler}
                                            onBlur={focusoutHandler}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                <div className="input-lable w-100">
                                        <label htmlFor="name">العنوان</label>
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            placeholder="اكتب العنوان هنا"
                                            onFocus={focusHandler}
                                            onBlur={focusoutHandler}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={4}>

                                <div className="input-lable w-100">
                                        <label htmlFor="name">المنطقة</label>
                                        <div className="select-city">
                                            <div className="select-btn" onClick={selectClick1}>
                                                {placeHolder1 && (
                                                <span style={{ color: "var(--lightgray-color)" }}>
                                                    اختر المنطقة
                                                </span>
                                            )}
                                                <span>{region}</span>
                                                <img src={rarrow} />
                                            </div>
                                            {IsDisplayed1 && (
                                                <div className="select-content">
                                                {regions.map((option) => (
                                                    <div
                                                        data-name='region'
                                                        className="select-item"
                                                        onClick={(e) => {changeSelect1(e.target.textContent);}}
                                                    >
                                                    {" "}
                                                    {option}{" "}
                                                    </div>
                                                ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                <div className="input-lable w-100">
                                        <label htmlFor="name">الرمز البريدى</label>
                                        <input
                                            type="text"
                                            id="postalCode"
                                            name="postalCode"
                                            value={postalCode}
                                            onChange={(e) => setPostalCode(e.target.value)}
                                            placeholder="ادخل الرمز البريدى"
                                            onFocus={focusHandler}
                                            onBlur={focusoutHandler}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                <div className="input-lable w-100">
                                        <label htmlFor="name">رقم الهوية</label>
                                        <input
                                        type="text"
                                        id="id"
                                        name="id"
                                        value={id}
                                        onChange={(e) => setId(e.target.value)}
                                        placeholder="اكتب رقم الهوية"
                                        onFocus={focusHandler}
                                        onBlur={focusoutHandler}
                                    />
                                    </div>
                                    {errorsFlags[0] && <div className='error'><img src={err} />{errors.id_msg}</div> }
                                </Col>
                                <Col sm={12} md={6} lg={4}>

                                <div className="input-lable w-100">
                                        <label htmlFor="name">الايميل الرسمي</label>
                                        <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="اكتب الايميل الرسمى"
                                        onFocus={focusHandler}
                                        onBlur={focusoutHandler}
                                        />
                                        
                                    </div>
                                    {errorsFlags[1] && <div className='error'><img src={err} />{errors.email_msg1}</div> }
                                </Col>
                                <Col sm={12} md={6} lg={4}>

                                <div className="input-lable w-100">
                                        <label htmlFor="name">رقم ص.ب</label>
                                        <input
                                        type="text"
                                        id="mailBox"
                                        name="mailBox"
                                        value={mailBox}
                                        onChange={(e) => setMailBox(e.target.value)}
                                        placeholder="اكتب رقم ص . ب"
                                        onFocus={focusHandler}
                                        onBlur={focusoutHandler}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={4}>

                                <div className="input-lable w-100">
                                <label htmlFor="name">المدينة</label>
                                <div className="select-city">
                                <div className="select-btn" onClick={selectClick2}>
                                    {placeHolder2 && (
                                    <span style={{ color: "var(--lightgray-color)" }}>
                                        اختر المدينة
                                    </span>
                                    )}
                                    <span>{city}</span>
                                    <img src={rarrow} />
                                </div>
                                {IsDisplayed2 && (
                                    <div className="select-content">
                                    {cities.map((option) => (
                                        <div
                                        data-name ='city'
                                        className="select-item"
                                        onClick={(e) => {
                                            changeSelect2(e.target.textContent);
                                        }}
                                        >
                                        {" "}
                                        {option}{" "}
                                        </div>
                                    ))}
                                    </div>
                                )}
                                </div>
                            </div>
                                </Col>
                                <Col sm={12} md={6} lg={4}>

                                    <div className="input-content" style={{ width: "93%" }}>
                                        <p>رفع صورة الهوية</p>
                                        <label htmlFor="upload-photo" className="w-100">
                                        {" "}
                                        <div className="input">
                                            <img src={upload} alt="" />
                                        </div>{" "}
                                        </label>
                                        <input onChange={onChange} type="file" name="photo" id="upload-photo" />
                                        {photo && <img className='complete' src={complete} alt="" />}
                                        {wrong && <AiOutlineCloseCircle style={{color:'red',position:'absolute' ,left:'-20px',top:'30px',width:'36px',height:'36px'}}/>}
                                    </div>
                                </Col>
                            </Row>
                        </div>

          </div>
          <Row className="mt-5 pt-4">
          {errorsFlags[2] && <div className='error'>{errors.allFieldRequired}</div> }

            { errorEmployee && <Message variant='danger'>{errorEmployee}</Message>}
        </Row>
        </div>
        <div className="group-btn">
        
          <button type='submit' className="nextBtn">
            <img src={arrow} alt="" />
            التالي
          </button>
       
          <Link  to='/OwnerInfo'>
          <button className="prevBtn">
            <img src={arrow} alt="" />
            السابق
          </button>
          </Link>
        </div>
        </form>
      </div>
    )
}

export default InfoEmployeeHasAccessScreen
