import { Link, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../styles/choose_section_type1.css";

import Loader from '../component/Loader'
import Message from '../component/Message'
import { listSectorTypes } from '../actions/sectorActions'
import MainDataFormScreen from "./MainDataFormScreen";
import { useHistory } from 'react-router-dom'




function MainChooseSectionTypeScreen1({ selectedTag }) {

    const [sectionName, setSectionName] = useState('')

    const [sectorTypeSelected, setSectorTypeSelected] = useState('')

    const dispatch = useDispatch()

    const sectorTypeList = useSelector(state => state.sectorTypeList)

    const {loading, error, sectorTypes } = sectorTypeList

    const userLogin = useSelector(state => state.userLogin)
    
    const {userInfo} = userLogin

    const facilityInfoRegister
    = useSelector(state => state.facilityInfoRegister)

    const {facility, success } = facilityInfoRegister

    let history = useHistory()


    useEffect(() => {
      if(userInfo){
        if (userInfo.is_registered){
          history.push('/services')
        } else {
          if (!selectedTag){
            dispatch(listSectorTypes(facility.sector))
          } else {
            dispatch(listSectorTypes(selectedTag))
          }
        }
          
      } else {
          history.push('/')
      }
      
  }, [dispatch, history, userInfo, facility, selectedTag ])


  const changeDesign = (e) => {
    const nodes = e.target.classList[0] === "other" ? e.target.parentNode.parentNode.parentNode.querySelectorAll(".tag-item") : e.target.parentNode.parentNode.querySelectorAll(".tag-item");

    nodes.forEach((element) => {
      element.classList.remove("active");
    });

    e.target.classList.add("active");

    setSectorTypeSelected(e.target.getAttribute("data-tag"))

  }


  if(sectorTypeSelected === '') {
    if (facility) {
      setSectorTypeSelected(facility.sector_type)
    }
    
  }

  
    
    return (
      <>
        <div className="choose_section1"> 
          <div className="desc-title text-center">أختر نوع القطاع</div>
          <div>
            <div className="tags row">
              {sectorTypes.map((sectorType) => (
                <Link className="col-md-3 col-lg-3 col-sm-12" to="/MainChooseEnterprise/EnterpriseTags/DataForm" key={sectorType.pk}>
                  <div onClick={changeDesign} className="tag-item" data-tag={sectorType.pk}>
                  {sectorType.type_name}
                  </div>
                </Link>
              ))}
            </div>                
          </div>
        </div>
        <div className=""> 
          <Route
            path="/MainChooseEnterprise/EnterpriseTags/DataForm"
            render={() => (
              <MainDataFormScreen 
                sector={parseInt(selectedTag)}
                sectorType={parseInt(sectorTypeSelected)}
              />
            )}
          />             
        </div>
      </>
    )
}

export default MainChooseSectionTypeScreen1
