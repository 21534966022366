import { Table } from "react-bootstrap";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
function InvoiceTable({ title, type, className, facility }) {


    const dispatch = useDispatch()
    const [data, setData] = useState()

  useEffect(() => {
    setData(facility)
    
  }, [dispatch, facility])


  function Jdata() {
    let rows = [];
    let numrows = Object.values(data).length / title.length;
    if (type == 4) {
      numrows = Object.values(data).length;
      for (let i = 0; i < numrows; i += 3) {
        rows.push(<tr key={i}>{Jcells(i)}</tr>);
      }
    }else if(type == 5){
      numrows = Object.values(data).length;
      for (let i = 0; i < numrows; i += 2) {
        rows.push(<tr key={i}>{Jcells(i)}</tr>);
      }
    } else {
      for (let i = 0; i < numrows; i++) {
        rows.push(<tr key={i}>{Jcells(i)}</tr>);
      }
    }
    return rows;
  }
  function Jcells(i) {
    let cells = [];
    let numcells = title.length;
    if (type == 4) {
      for (let j = 0; j < numcells; j++) {
        cells.push(<td className="td grayBG">{Object.keys(data)[i]}</td>);
        cells.push(<td className="td table_small">{Object.values(data)[i]}</td>);
        cells.push(<td className="td grayBG">{Object.keys(data)[i + 1]}</td>);
        cells.push(<td className="td table_small">{Object.values(data)[i + 1]}</td>);
        cells.push(<td className="td grayBG">{Object.keys(data)[i + 2]}</td>);
        cells.push(<td className="td table_small">{Object.values(data)[i + 2]}</td>);
      }
    }else if (type == 5){
      for (let j = 0; j < numcells; j++) {
        cells.push(<td className="td grayBG">{Object.keys(data)[i]}</td>);
        cells.push(<td className="td table_small">{Object.values(data)[i]}</td>);
        cells.push(<td className="td grayBG">{Object.keys(data)[i + 1]}</td>);
        cells.push(<td className="td table_small">{Object.values(data)[i + 1]}</td>);
      }

    } else {
      for (let j = 0; j < numcells; j++) {
        if (type == 1) {
          cells.push(
            <td className="td grayBG">
              {Object.keys(data)[i]} : {Object.values(data)[i]}
            </td>
          );
        } else if (type == 2) {
          cells.push(<td className="td grayBG">{Object.values(data)[j]}</td>);
        } else if (type == 3) {
          cells.push(<td className="td grayBG">{Object.keys(data)[i]}</td>);
          cells.push(<td className="td ">{Object.values(data)[i]}</td>);
        }
      }
    }

    return cells;
  }
  function Jtitle() {
    return title.map((key) => {
      return (
        <th
          className={type == 4 ? "text-center" : type == 5? "text-center":""}
          colSpan={type == 3 ? "2" : type == 4 ? "6" : type == 5? "4":''}
        >
          {key}
        </th>
      );
    });
  }

  return (
    <Table borderless className={`${className ? className : ""} direction scrol_max`}>
      <thead className="thead">
        <tr>{Jtitle()}</tr>
      </thead>
      <tbody>
      <tr>
      <td className="td grayBG">رمز المؤسسة</td>
        <td className="td table_small"></td>
        <td className="td grayBG">نوع المنشأة</td>
        <td className="td table_small">sfdfsdfsds</td>
        </tr>
        <tr>
      <td className="td grayBG">المنطقة</td>
        <td className="td table_small">445454</td>
        <td className="td grayBG">المدينة</td>
        <td className="td table_small">565656565</td>
        </tr>
        </tbody>
    </Table>
  );
}
export default InvoiceTable;
