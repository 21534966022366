import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import Add_MultiSectors from "../component/powers/Add_MultiSectors";

function AdminPowerAddMultiSectors({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat" style={{ height: "900px" }}>
            <div
              style={{ width: "300px", textAlign: "center" }}
              className="heading"
            >
              الصلاحيات
            </div>

            <Add_MultiSectors />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminPowerAddMultiSectors;
