import React, { useEffect, useState } from 'react'
import AdminLayout from './layouts/AdminLayout'
import { useSelector } from 'react-redux';
import axios from 'axios';
import fileDownload from 'js-file-download';

import '../styles/AdminPowerSpecialCases.css';
import "../styles/Manger_ControlPanle.css";
import "./../styles/change_entereddata.css";

import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FILE_NOT_FOUND = 'الملف غير موجود';
const FILE_FOUND = 'لإعادة طباعة شهادة تقييم الشبكات انقر أدناه';

export default function DownloadClientEvaluationCertificate() {

	const [message, setMessage] = useState(null);

	const [evaluationId, setEvaluationId] = useState(-1);

	const [level, setLevel] = useState('');
	const [allowedLevel, setAllowedLevel] = useState('');

	const userLogin = useSelector(state => state.userLogin);

	useEffect(() => {

		if (!userLogin || !userLogin.userInfo)
			return;

		axios.get('client_evaluation_certificate/', {
			headers: {
				Authorization: `Bearer ${userLogin.userInfo.access}`
			}
		}).then(response => {
			const data = response.data;

			if (!data)
				return;

			if (data.level)
				setLevel(data.level);

			if (data.allowed)
				setAllowedLevel(data.allowed);

			if (data.id)
				setEvaluationId(data.id);

		}).catch(_e => {
			setEvaluationId(-1);
		});

	}, [userLogin]);

	return (
		<AdminLayout>
			<div className="manger_control">
				<div className="change_entereddata">

					<div className="special_name stat" style={{ width: 'initial' }}>

						<div className="heading">طباعة شهادة التقييم</div>
						{evaluationId > -1 && (
							<>
								<div className="rate_info"
									style={{
										flexDirection: "row-reverse",
										justifyContent: "space-between",
										padding: "20px"
									}}>
									<p className="rate_hint"
										style={{
											fontSize: "100%",
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-end",
											marginRight: "0px"
										}}>

										<span className="rate">{level}</span>
									</p>

									<p className="rate_title" style={{ width: "100%", fontSize: '22px' }}>
										التقييم
									</p>
								</div>

								<div className="rate_info"
									style={{
										flexDirection: "row-reverse",
										justifyContent: "space-between",
										padding: "20px"
									}}>

									<p className="rate">{allowedLevel}</p>
									<p className="rate_title" style={{ fontSize: '22px' }}>الفئة</p>
								</div>

								<div style={{textAlign: 'center', marginTop: '50px'}}>
									<Link to={`/evaluation-certificate/${evaluationId}`} style={{margin: 'auto'}} className="btnNext">
										طباعة
									</Link>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</AdminLayout >
	)
}
