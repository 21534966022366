import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAllMedicalTeamForAdmin } from "../actions/adminActions";
import AdminLayout from "../client/layouts/AdminLayout";
import {
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Modal,
  Dropdown,
} from "react-bootstrap";
import "../styles/TableNewDesign.css";
import Paginate from "../component/Paginate";
import { Link } from "react-router-dom";
import Message from "../component/Message";
import Loader from "../component/Loader";
import check from "../imgs/check.png";

import {
  deleteMedicalTeam,
  updateMedicalTeam,
} from "../actions/serviceActions";

import { fetchUsers } from "./api/Users/EmployeesApi";

function AdminManagersPage({ history }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [apiData, setApiData] = useState([]);

  const [selectValue, setSelectValue] = useState("");
  const [show, setShow] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);

  const dispatch = useDispatch();

  const medicalTeamAllListForAdmin = useSelector(
    (state) => state.medicalTeamAllListForAdmin
  );
  let { loading, error, medicalTeam, page, pages } = medicalTeamAllListForAdmin;

  const medicalTeamDelete = useSelector((state) => state.medicalTeamDelete);
  const {
    error: errorDelete,
    loading: loadingDelete,
    success: successDelete,
  } = medicalTeamDelete;

  const medicalTeamUpdate = useSelector((state) => state.medicalTeamUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = medicalTeamUpdate;

  let keyword = history.location.search;

  const allPreviliges = [
    "تقييم الشبكات الطبية",
    "تأجير و بيع الاجهزة الطبية",
    "تأجير خدمات الاطباء و العيادات",
    "مقارنة أسعار تامين اخطاء ممارسة المهن الطبية",
    "التحصيل",
    "تنظيم وتشغيل عقود المختبرات",
    "تنظيم وتشغيل عقود الأشعة",
    "تنظيم مناقصات التوريد",
    "القطاع الغذائي",
    "قطاع القرطاسية المكتبية",
    "قطاع النظافة",
    "قطاع الأثاث المكتبي",
    "قطاع النقل",
    "قطاع الغسيل",
    "قطاع ملابس الزي الموحد",
    "قطاع التشغيل والصيانة",
    "قطاع التوظيف والخدمات",
  ];

  const more = {
    title: "الاجراءات",
    actions: {
      تفعيل: "approve",
      طباعة: "/admin/print-medical-technical-team",
      حذف: "delete",
    },
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  const handleDelete = (id) => {
    dispatch(deleteMedicalTeam(id));
    setShow(false);
  };

  const handleUpdate = (id) => {
    // TODO: MAKE SURE TO UNCOMMENT THOSE
    let data = new FormData();
    data.append("approved", true);
    dispatch(updateMedicalTeam(data, id));
    setShowActivateModal(true);
  };

  const handleStop = (id) => {
    setShowStopModal(true);
  };

  const showModel = () => {
    let teamId = selectValue.split("/")[1];
    return (
      <Modal
        style={{ direction: "rtl" }}
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "var(--main-color)",
            justifyContent: "center",
          }}
        >
          <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h4">يرجى تأكيد الحذف</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-red"
            onClick={() => setShow(false)}
          >
            <span className="h4">إلغاء</span>
          </Button>
          <Button
            variant="success"
            className="btn-main px-5"
            onClick={() => handleDelete(teamId)}
          >
            <span className="h4">تأكيد</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const activateModal = () => {
    let teamId = selectValue.split("/")[1];
    return (
      <Modal
        style={{ direction: "rtl" }}
        show={showActivateModal}
        onHide={() => setShowActivateModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "var(--main-color)",
            justifyContent: "center",
          }}
        >
          <Modal.Title className="text-white fw-bold">تفعيل الحساب</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h4">تم تفعيل الحساب بنجاح</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-red"
            onClick={() => setShowActivateModal(false)}
          >
            <span className="h4">تم</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const stopModal = () => {
    let teamId = selectValue.split("/")[1];
    return (
      <Modal
        style={{ direction: "rtl" }}
        show={showStopModal}
        onHide={() => setShowStopModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "var(--main-color)",
            justifyContent: "center",
          }}
        >
          <Modal.Title className="text-white fw-bold">ايقاف الحساب</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h4">تم ايقاف الحساب بنجاح</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-red"
            onClick={() => setShowStopModal(false)}
          >
            <span className="h4">تم</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  useEffect(() => {
    if (userInfo && true) {
      dispatch(listAllMedicalTeamForAdmin(keyword));
    } else {
      history.push("/");
    }
  }, [dispatch, history, userInfo, keyword, successDelete, successUpdate]);

  return (
    <AdminLayout>
      <Row>
        <Col xl={12} md={12}>
          {errorUpdate && <Message varient="danger">{errorUpdate}</Message>}
          {errorDelete && <Message varient="danger">{errorDelete}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message varient="danger">{error}</Message>
          ) : (
            <Card className="code-table">
              <Card.Header>
                <Card.Title as="h5">الطاقم الفني و الأداري</Card.Title>
              </Card.Header>
              <Card.Body className="pb-0">
                <Table responsive hover style={{ height: "80vh" }}>
                  <thead>
                    <tr>
                      <th>رقم الطلب</th>
                      <th>القطاع</th>
                      <th>نوع القطاع</th>
                      <th>اسم الدكنور</th>
                      <th>التخصص</th>
                      <th>المدينة</th>
                      <th>الصلاحيات</th>
                      <th>الحالة</th>
                      <th>الاجراءات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medicalTeam.map((obj) => (
                      <tr key={obj.id}>
                        <td>
                          <p className="mb-1">H-{obj.id}</p>
                        </td>
                        <td>
                          <p className="mb-1">{obj.ar_applicant_name}</p>
                        </td>
                        <td>
                          <p className="mb-1">{obj.applicant_email}</p>
                        </td>
                        <td>
                          <p className="mb-1">قيمة وهمية</p>
                        </td>
                        <td>
                          <p className="mb-1">قيمة وهمية</p>
                        </td>
                        <td>
                          <p className="mb-1">قيمة وهمية</p>
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              تعديل الصلاحيات
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {allPreviliges.map((p) => {
                                return (
                                  <Dropdown.Item
                                    className="remember-me"
                                    href="#/action-1"
                                  >
                                    <span
                                      style={{ width: "17px", height: "17px" }}
                                    ></span>
                                    <img
                                      src={check}
                                      alt="check"
                                      style={{
                                        top: "2px",
                                        right: "15px",
                                        width: "15px",
                                        height: "15px",
                                        display: "block",
                                      }}
                                    />
                                    <p style={{ fontSize: "13px" }}>{p}</p>
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          {!obj.is_deleted ? (
                            <Button
                              variant={obj.is_active ? "success" : "danger"}
                              className={
                                obj.is_active
                                  ? "label btn-success btn-sm f-12 text-white"
                                  : "label btn-danger btn-sm f-12 text-white"
                              }
                            >
                              {obj.is_active ? "مفعل" : "غير مفعل"}
                            </Button>
                          ) : (
                            <Button
                              className="label btn-danger btn-sm f-12 text-white"
                              variant="danger"
                            >
                              تم الحذف
                            </Button>
                          )}
                        </td>
                        {more && !obj.is_deleted ? (
                          <td>
                            <Row className="m-2 w-100">
                              <Form.Select
                                as="select"
                                onChange={handleChange}
                                className="text-center px-1 pe-2"
                              >
                                <option>أختر</option>
                                {!obj.approved && (
                                  <option value={`approve/${obj.id}`}>
                                    تفعيل الحساب
                                  </option>
                                )}
                                {!obj.is_deleted && (
                                  <option value={`delete/${obj.id}`}>
                                    حذف الحساب
                                  </option>
                                )}
                                <option value={`edit/${obj.id}`}>
                                  تعديل الحساب
                                </option>
                                {/* // TODO: dummy url, change later */}
                                <option value={`stop/${obj.id}`}>
                                  ايقاف الحساب
                                </option>
                              </Form.Select>
                              {selectValue.includes(
                                "/editMedicalOrTechnicalTeam"
                              ) ? (
                                <Button
                                  onClick={() => setShow(true)}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Button>
                              ) : selectValue.includes(
                                  "/admin/print-medical-technical-team"
                                ) ? (
                                <Link
                                  to={selectValue}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Link>
                              ) : selectValue.includes("delete") ? (
                                <Button
                                  onClick={() => setShow(true)}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Button>
                              ) : selectValue.includes("approve") ? (
                                <Button
                                  onClick={() => handleUpdate(obj.id)}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Button>
                              ) : selectValue.includes("edit") ||
                                selectValue.includes("password") ? (
                                <Link
                                  to="/admin/add-employee"
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Link>
                              ) : selectValue.includes("stop") ? (
                                <Button
                                  onClick={() => handleStop(obj.id)}
                                  className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                >
                                  تنفيذ
                                </Button>
                              ) : (
                                ""
                              )}
                            </Row>
                          </td>
                        ) : (
                          <td>
                            <span className="text-danger">محذوف</span>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <div>{showModel()}</div>
      <div>{activateModal()}</div>
      <div>{stopModal()}</div>
      <Paginate
        page={page}
        pages={pages}
        keyword={keyword}
        link="/comparingMedicalMistakeWork"
      />
    </AdminLayout>
  );
}

export default AdminManagersPage;
