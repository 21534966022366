import axios from "axios";

export const fetchUsers = async (user) => {
  const request = await axios.get("/employees_of_bayan/", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const fetchOneEmployee = async (id, user) => {
  const request = await axios.get(`/employees_of_bayan/${id}`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const deleteEmployee = async (id, user) => {
  const request = await axios.delete(`/employees_of_bayan/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 204) {
    return request.status;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const activateEmployee = async (id, user) => {
  const formData = new FormData();
  formData.append("is_active", true);
  const request = await axios.patch(`/update_employees/${id}/`, formData, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};
export const deactivateEmployee = async (id, user) => {
  const formData = new FormData();
  formData.append("is_active", false);
  const request = await axios.patch(`/update_employees/${id}/`, formData, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const updateEmployeePriveligeApi = async (id, previlge, value, user) => {
  const formData = new FormData();
  formData.append(previlge, value);
  const request = await axios.patch(`/update_employees/${id}/`, formData, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};
