import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/add_evaluationdata.css";
import arrow from "../../imgs/arrow.png";
import rarrow from "../../imgs/right-arrow.png";
import date from "../../imgs/date.png";
import DatePicker from "react-datepicker";
import upload from "../../imgs/upload (1).png";
import complete from "../../imgs/check1.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import {getMedicalTeamDetails} from '../../actions/serviceActions'
import Loader from '../../component/Loader'
import Message from '../../component/Message'
import { Link } from "react-router-dom";

function EditMedicalTeamScreen({ match, history }) {

    const teamId = match.params.id

    const [name, setName] = useState('')

    const [id, setID] = useState('')

    const [en_Name, setEn_Name] = useState('')

    const [licence_No, setLicence_No] = useState('')

    const [photo1, setPhoto1] = useState(false)

    const [path1, setPath1] = useState('')

    const [wrong1, setWrong1] = useState('')

    const [photo2, setPhoto2] = useState(false)

    const [path2, setPath2] = useState('')

    const [wrong2, setWrong2] = useState('')

    const [photo3, setPhoto3] = useState(false)

    const [path3, setPath3] = useState('')

    const [wrong3, setWrong3] = useState('')

    const [start_Date, setStart_Date] = useState("")

    const [end_Date, setEnd_Date] = useState('')

    const [specialization, setSpecialization] = useState('')

    const [birth_Date, setBirth_Date] = useState("")

    const [id_endDate, setId_endDate] = useState('')

    const [phone_No, setPhone_No] = useState('')

    const [gender, setGender] = useState('')

    const [nationality, setNationality] = useState('')

    const [address, setAddress] = useState('')

    const [city, setCity] = useState('')

    const [IsDisplayed, setIsDisplayed] = useState(false)

    const [palceHolder, setPalceHolder] = useState(true)

    const [IsDisplayed1, setIsDisplayed1] = useState(false)

    const [palceHolder1, setPalceHolder1] = useState(true)

    const [postal_Code, setPostal_Code] = useState('')

    const [region, setRegion] = useState('')

    const [email, setEmail] = useState(null)

    const [emcertificate_Specializationail, setEmcertificate_Specializationail] = useState('')

    const [certifi_StartDate, setCertifi_StartDate] = useState('')

    const [certifi_EndDate, setCertifi_EndDate] = useState('')

    const [cover_Period, setCover_Period] = useState('')

    const [cover_Price, setCover_Price] = useState('')

    const [university, setUniversity] = useState('')

    const [organization_Name, setOrganization_Name] = useState('')

    const [status, setStatus] = useState('')

    const [have_Certifi, setHave_Certifi] = useState('')

    const [graduated_Date, setGraduated_Date] = useState('')

    const [Insurance_CertificateEndDate, setInsurance_CertificateEndDate] = useState('') 

    const [errors] = useState({
      allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",
    })

    const [errorsFlags, setErrorsFlags] = useState([false])

    const [uploading1, setUploading1] = useState(false)
    const [uploading2, setUploading2] = useState(false)
    const [uploading3, setUploading3] = useState(false)


    const dispatch = useDispatch()

    const medicalTeamDetails = useSelector(state => state.medicalTeamDetails)
    const {loading, medicalTeam, error } = medicalTeamDetails

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        } else {
          
            if (medicalTeam.id !== Number(teamId)){
              dispatch(getMedicalTeamDetails(match.params.id))
          } else {
              setName(medicalTeam.ar_applicant_name);
              setEn_Name(medicalTeam.en_applicant_name);
              setID(medicalTeam.id_num);
              setLicence_No(medicalTeam.practical_license);
              setPhoto1(medicalTeam.practical_license_image);
              setStart_Date(retrieveDate(medicalTeam.license_issue_date));  
              setEnd_Date(retrieveDate(medicalTeam.license_expire_date));
              setSpecialization(medicalTeam.medical_specialist);
              setPhoto2(medicalTeam.applicant_image);
              setBirth_Date(retrieveDate(medicalTeam.birth_date));
              setId_endDate(retrieveDate(medicalTeam.id_expire_date));
              setPhone_No(medicalTeam.phone);
              setGender(medicalTeam.gender);
              setNationality(medicalTeam.nationality);
              setAddress(medicalTeam.address);
              setCity(medicalTeam.city);
              setPostal_Code(medicalTeam.postal_code);
              setRegion(medicalTeam.state);
              setEmail(medicalTeam.applicant_email);
              setEmcertificate_Specializationail(medicalTeam.specialize_certificate_image);
              setCertifi_StartDate(retrieveDate(medicalTeam.certificate_issue_date));
              setCertifi_EndDate(retrieveDate(medicalTeam.certificate_expire_date));
              setCover_Period(medicalTeam.coverage_duration);
              setCover_Price(medicalTeam.coverage_price);
              setUniversity(medicalTeam.university_name);
              setOrganization_Name(medicalTeam.employer_name);
              setStatus(medicalTeam.marital_status);
              setGraduated_Date(retrieveDate(medicalTeam.graduation_date));
              setInsurance_CertificateEndDate(retrieveDate(medicalTeam.insurance_certificate_expire_date));
              setPhoto3(medicalTeam.insurance_certificate_image);
            }
        }      
    }
    
  }, [dispatch, history, userInfo, match, medicalTeam])

    const retrieveDate = (d) => {
      let date = new Date(d);
      return date
    }

    const handleDate = (d) => {
      if (d) {
        const offset = d.getTimezoneOffset();
        let newDate = new Date(d.getTime() - (offset*60*1000));
        return newDate.toISOString().split('T')[0];
      }
    }

    const focusHandler = (e) => {
      e.target.parentNode.style.color = "#0fb0a9";
      e.target.style.color = "#0fb0a9";
      e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
      e.target.style.borderColor = "#0fb0a9";
    };

    const focusoutHandler = (e) => {
      e.target.parentNode.style.color = "";
      e.target.style.color = "";
      e.target.style.boxShadow = "";
      e.target.style.borderColor = "";
    };

    const getExtension = (filename) => {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    };

    return (
      <div>
        <ControlPanelHeader
          exit1={true}
          notifi={true}
          msg={true}
          files={false}
          stat={false}
          msgDest={"/Messages"}
          notifidest={"/Notifications"}
          exitDest={"/services"}
          fileDest={"/FacilityFiles"}
          statDest = {'/collectServices'}
          style={"three_section"}
        />
        {loading
          ? (<Loader/>) 
          : error 
          ? (<Message variant='danger'>{error}</Message>)
          : (
        <div className="data-form">
          <div className="desc-title text-center">معلومات الموظف</div>
          <div className="sub-title"></div>
          <form>
            <div className="name-form">
              <div className="input-lable">
                <label htmlFor="name">أسم مقدم الطلب بالعربي</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">رقم الهوية/ الأقامة</label>
                <input
                  type="text"
                  id="id"
                  name="id"
                  value={id}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">أسم مقدم الطلب بالإنجليزي</label>
                <input
                  type="text"
                  id="en_Name"
                  name="en_Name"
                  value={en_Name}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">رقم رخصة المزاولة</label>
                <input
                  type="number"
                  id="licence_No"
                  name="licence_No"
                  value={licence_No}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-content">
                <p>صورة رخصة المزاولة</p>
                <Link to={photo1} target="_blank" className="btnNext m-0" style={{ width: "93%" }} download>تحميل</Link>
              </div>

              <div className="input-lable">
                <label htmlFor="date2">تاريخ الاصدار</label>
                <DatePicker style={{ height:"41px" }}
                  selected={start_Date}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  popperPlacement="top"
                  readOnly
                />
                <img src={date} alt="" />
              </div>

              <div className="input-lable">
                <label htmlFor="date2">تاريخ الانتهاء</label>
                <DatePicker style={{ height:"41px" }}
                  selected={end_Date}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  popperPlacement="top"
                  readOnly
                />
                <img src={date} alt="" />
              </div>

              <div className="input-lable">
                <label htmlFor="specialization">التخصص الطبي او المهني</label>
                <input
                  type="text"
                  id="specialization"
                  name="specialization"
                  value={specialization}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-content">
                <p>صورة مقدم الطلب</p>
                <Link to={photo2} target="_blank" className="btnNext m-0" style={{ width: "93%" }} download>تحميل</Link>
              </div>

              <div className="input-lable">
                <label htmlFor="date2">تاريخ الميلاد</label>
                <DatePicker style={{ height:"41px" }}
                  selected={birth_Date}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  popperPlacement="top"
                  readOnly
                />
                <img src={date} alt="" />
              </div>

              <div className="input-lable">
                <label htmlFor="date2">تاريخ إنتهاء الهوية</label>
                <DatePicker style={{ height:"41px" }}
                  selected={id_endDate}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  popperPlacement="top"
                  readOnly
                />
                <img src={date} alt="" />
              </div>

              <div className="input-lable">
                <label htmlFor="name">الهاتف</label>
                <input
                  type="text"
                  id="phone_No"
                  name="phone_No"
                  value={phone_No}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">الجنس</label>
                <input
                  type="text"
                  id="gender"
                  name="gender"
                  value={gender}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">الجنسيية</label>
                <input
                  type="text"
                  id="nationality"
                  name="nationality"
                  value={nationality}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">العنوان</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={address}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="date1">المدينة</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={city}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">الرمز البريدي</label>
                <input
                  type="text"
                  id="postal_Code"
                  name="postal_Code"
                  value={postal_Code}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">المنطقة</label>
                <input
                  type="text"
                  id="region"
                  name="region"
                  value={region}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">البريد الالكتروني</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={email}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">شهادة هيئة التخصصات</label>
                <input
                  type="text"
                  id="emcertificate_Specializationail"
                  name="emcertificate_Specializationail"
                  value={emcertificate_Specializationail}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="date2">تاريخ الاصدار</label>
                <DatePicker style={{ height:"41px" }}
                  selected={certifi_StartDate}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  popperPlacement="top"
                  readOnly
                />
                <img src={date} alt="" />
              </div>

              <div className="input-lable">
                <label htmlFor="date2">تاريخ الانتهاء</label>
                <DatePicker style={{ height:"41px" }}
                  selected={certifi_EndDate}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  popperPlacement="top"
                  readOnly
                />
                <img src={date} alt="" />
              </div>

              <div className="input-lable">
                <label htmlFor="name">مدة التغطية</label>
                <input
                  type="text"
                  id="cover_Period"
                  name="cover_Period"
                  value={cover_Period}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">قيمة التغطية</label>
                <input
                  type="text"
                  id="cover_Price"
                  name="cover_Price"
                  value={cover_Price}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">أسم الجامعة أو المعهد</label>
                <input
                  type="text"
                  id="university"
                  name="university"
                  value={university}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="name">أسم الجهة التي يعمل بها</label>
                <input
                  type="text"
                  id="organization_Name"
                  name="organization_Name"
                  value={organization_Name}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="date1">الحالة</label>
                <input
                  type="text"
                  id="status"
                  name="status"
                  value={status}
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                  readOnly
                />
              </div>

              <div className="input-lable">
                <label htmlFor="date2">تاريخ التخرج</label>
                <DatePicker style={{ height:"41px" }}
                  selected={graduated_Date}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  popperPlacement="top"
                  readOnly
                />
                <img src={date} alt="" />
              </div>

              <div className="input-lable">
                <label htmlFor="date2">تاريخ إنتهاء شهادة التأمين</label>
                <DatePicker style={{ height:"41px" }}
                  selected={Insurance_CertificateEndDate}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  popperPlacement="top"
                  readOnly
                />
                <img src={date} alt="" />
              </div>

              <div className="input-content">
                <p>رفع شهادة التأمين</p>
                <Link to={photo3} target="_blank" className="btnNext m-0" style={{ width: "93%" }} download>تحميل</Link>
                
              </div>
            </div>
            <div className="d-flex justify-content-end" style={{ marginRight:"75px" }}>{errorsFlags[0] && <div className='error'>{errors.allFieldRequired}</div> }
        </div>
          </form>
          
      </div>
      )}
      <Link to={`/SentOfferInsuranceComp/${medicalTeam.id}`}>
          <button style={{ width: "259px" }} className="btnNext">
            <img
              style={{ marginRight: "80px" }}
              className="icon"
              src={arrow}
              alt=""
            />
            اضف عرضك
          </button>
        </Link>

                    
                   
    </div>
    )
}

export default EditMedicalTeamScreen
