import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../styles/files_location.css";
import location from "../imgs/0.png";
import arrow from "../imgs/arrow.png";
import compass from "../imgs/locate.svg";
import complete from "../imgs/check1.png";
import upload from "../imgs/upload (1).png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import err from '../imgs/381599_error_icon.png'
import axios from "axios";
import Loader from '../component/Loader'
import Message from '../component/Message'
import ControlPanelHeader from '../component/ControlPanelHeader'

import { facilityImageAdd, getFacilityDetails } from '../actions/facilityActions'

import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { formatRelative } from "date-fns";

import "@reach/combobox/styles.css";
import mapStyles from "../component/mapStyles";

const mystyle1 = {
  zIndex: "9999",
  top: "5px",
  position: "absolute",
  width: "100%",
};

const mystyle2 = {
  width: "80%",
  height: "45px",
  textAlign: "right",
  padding: "10px"

};

const mystyle3 = {
  position: "absolute",
  zIndex: "99999999",
  right: "32px",
  bottom: "127px",
  width: "141px",
  height: "47px",
  background: "bottom",
  border: "none"
}

const mystyle4 = {
  position: "absolute",
  top: "50%",
  left: "10%",
  color: "wheat",
  background: "black",
  textAlign: "center"
}

const libraries = ["places"];
const mapContainerStyle = {
  height: "80vh",
  width: "100vw",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 43.6532,
  lng: -79.3832,
};


function FileLocationScreen({ history, google }) {

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDyTY8SeEE7bZawEXk_aXhpFylDyDhg6SY",
    libraries,
  });

  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);

  const onMapClick = React.useCallback((e) => {
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
    setUserLocation(true)
    setErrorsFlags([false])
  }, []);


  const userLocat = React.useCallback(({ userLocat }) => {
    setUserLocation(true);
  }, [])

  const [photo1, setPhoto1] = useState(false)
  const [photo2, setPhoto2] = useState(false)
  const [photo3, setPhoto3] = useState(false)
  const [photo4, setPhoto4] = useState(false)
  const [photo5, setPhoto5] = useState(false)
  const [photo6, setPhoto6] = useState(false)

  const [path1, setPath1] = useState('نرجوا ارفاق صورة سجل بجودة عاليه و واضحة')
  const [path2, setPath2] = useState('رفع صورة الترخيص الطبي')
  const [path3, setPath3] = useState('رفع صورة ترخيص مجلس الضمان')
  const [path4, setPath4] = useState('رفع صورة ترخيص هيئة الدواء والغذاء')
  const [path5, setPath5] = useState('رفع صورة المنشأة من الخارج')
  const [path6, setPath6] = useState('رفع صورة المنشأة من الداخل')

  const [wrong1, setWrong1] = useState(false)
  const [wrong2, setWrong2] = useState(false)
  const [wrong3, setWrong3] = useState(false)
  const [wrong4, setWrong4] = useState(false)
  const [wrong5, setWrong5] = useState(false)
  const [wrong6, setWrong6] = useState(false)

  const [sec, setSec] = useState('')
  const [secType, setSecType] = useState('')

  const [loading, setLoading] = useState(true)

  const [latitude, setLatitude] = useState(21.3891)
  const [longitude, setLongitude] = useState(39.8579)

  const [userLocation, setUserLocation] = useState(false)

  const [errorsFlags, setErrorsFlags] = useState([false, false])

  const [errors, setErrors] = useState({
    location_msg: " يرجى تحدد الموقع من فضلك ",
    allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",
  })

  const dispatch = useDispatch()

  const facilityImageRegister = useSelector(state => state.facilityImageRegister)

  const { error, success, facilityImages } = facilityImageRegister

  const facilityInfoRegister = useSelector(state => state.facilityInfoRegister)
  const { facility } = facilityInfoRegister

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    } else {
      if (userInfo.is_registered) {
        history.push('/services')
      } else {
        if (facility) {
          setSec(facility.sector)
          setSecType(facility.sector_type)
        }
      }
    }

  }, [dispatch, history, userInfo, facility])

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const onChange = (e) => {
    if (e.target.value) {
      let ext = getExtension(e.target.value);
      ext = ext.toLowerCase();
      if (ext === "png" || ext === "jpg" || ext === "pdf") {
        const path = e.target.getAttribute("data-path")
        if (path == "path1") {
          setPhoto1(true)
          setPath1(e.target.files[0])
          setWrong1(false)
        } else if (path == "path2") {
          setPhoto2(true)
          setPath2(e.target.files[0])
          setWrong2(false)
        } else if (path == "path3") {
          setPhoto3(true)
          setPath3(e.target.files[0])
          setWrong3(false)
        } else if (path == "path4") {
          setPhoto4(true)
          setPath4(e.target.files[0])
          setWrong4(false)
        } else if (path == "path5") {
          setPhoto5(true)
          setPath5(e.target.files[0])
          setWrong5(false)
        } else if (path == "path6") {
          setPhoto6(true)
          setPath6(e.target.files[0])
          setWrong6(false)
        }

      } /*else {
          const path = e.target.getAttribute("data-path")
          if (path == "path1"){
              setWrong1(true)
          } else if(path == "path2") {
              setWrong2(true)
          } else if(path == "path3") {
              setWrong3(true)
          }else if(path == "path4") {
              setWrong4(true)
          }else if(path == "path5") {
              setWrong5(true)
          }else if(path == "path6") {
              setWrong6(true)
          }
        }*/
    }
  };


  const formValidation = () => {

    let isValid = true;
    const flags = [false, false];
    if (!userLocation) {
      flags[0] = true;
      isValid = false;
    } else {
      flags[0] = false;
    }

    const nodes = document.querySelectorAll("#filesForm input:not(.search input)");
    let i = 0;
    nodes.forEach((e) => {
      i++;
      if (i == 6)
        setWrong6(e.value === '');
      else if (i == 2)
        setWrong2(e.value === '');
      else if (i == 3)
        setWrong3(e.value === '');
      else if (i == 4)
        setWrong4(e.value === '');
      else if (i == 5)
        setWrong5(e.value === '');
        
      if (e.value === '') {
        flags[1] = true;
        isValid = false;
      }
    });

    setErrorsFlags(flags);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    let data = new FormData();
    data.append("cr_image", path1);
    data.append("medical_permit_image", path2);
    data.append("health_insurance_council_image", path3);
    data.append("food_drug_authority_licence_image", path4);
    data.append("outside_image", path5);
    data.append("inside_image", path6);
    console.log(errorsFlags)

    const isValid = formValidation();
    if (isValid) {
      setErrorsFlags(false);

      dispatch(facilityImageAdd(data));
      if (facilityImages) {
        history.push("/OwnerInfo");
      }
    }
  };

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div className="files_Location">
      <ControlPanelHeader exit1={true} files={false} notifi={true} msg={true} exitDest={"/"}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        fileDest={"/FacilityFiles"}
        style={"three_section"}
      />
      <form id="filesForm" onSubmit={e => e.preventDefault()}>
        <div className="fl-content">
          <p className="title-files text-center">مرفقات</p>
          <p className="title-location text-center">حدد موقع المنشأة على الخريطة</p>
          <div className="files">
            <div className="input-content">
              <p>رفع صورة السجل</p>
              <label htmlFor="upload-photo1">
                {" "}
                <div className="input">
                  <img src={upload} className="icon_upload" alt="" />
                  <div></div>
                </div>{" "}
              </label>
              <input
                onChange={onChange}
                type="file"
                name="photo1"
                id="upload-photo1"
                data-wrong="wrong1"
                data-path="path1"
              />
              {photo1 && <img className="valid_icon" src={complete} alt="" />}
              {wrong1 && <AiOutlineCloseCircle />}
            </div>
            <div className="input-content">
              <p>رفع صورة الترخيص الطبي</p>
              <label htmlFor="upload-photo2">
                {" "}
                <div className="input">
                  <img src={upload} className="icon_upload" alt="" />
                  <div></div>
                </div>{" "}
              </label>
              <input
                onChange={onChange}
                type="file"
                name="photo2"
                id="upload-photo2"
                data-wrong="wrong2"
                data-path="path2"
              />
              {photo2 && <img className="valid_icon" src={complete} alt="" />}
              {wrong2 && <AiOutlineCloseCircle />}
            </div>
            <div className="input-content">
              <p>رفع صورة الترخيص مجلس الضمان</p>
              <label htmlFor="upload-photo3">
                {" "}
                <div className="input">
                  <img src={upload} className="icon_upload" alt="" />
                  <div> </div>
                </div>{" "}
              </label>
              <input
                onChange={onChange}
                type="file"
                name="photo3"
                id="upload-photo3"
                data-wrong="wrong3"
                data-path="path3"
              />
              {photo3 && <img className="valid_icon" src={complete} alt="" />}
              {wrong3 && <AiOutlineCloseCircle />}
            </div>
            {((secType !== 28) && (sec === 7)) || (sec === 15) &&
              <div className="input-content">
                <p>رفع صورة الترخيص هيئة الدواء والغذاء</p>
                <label htmlFor="upload-photo4">
                  {" "}
                  <div className="input">
                    <img src={upload} className="icon_upload" alt="" />
                    <div>  </div>
                  </div>{" "}
                </label>
                <input
                  onChange={onChange}
                  type="file"
                  name="photo4"
                  id="upload-photo4"
                  data-wrong="wrong4"
                  data-path="path4"
                />
                {photo4 && <img className="valid_icon" src={complete} alt="" />}
                {wrong4 && <AiOutlineCloseCircle />}
              </div>
            }

            <div className="input-content">
              <p>رفع صورة المنشأة من الخارج</p>
              <label htmlFor="upload-photo5">
                {" "}
                <div className="input">
                  <img src={upload} className="icon_upload" alt="" />
                  <div> </div>
                </div>{" "}
              </label>
              <input
                onChange={onChange}
                type="file"
                name="photo5"
                id="upload-photo5"
                data-wrong="wrong5"
                data-path="path5"
              />
              {photo5 && <img className="valid_icon" src={complete} alt="" />}
              {wrong5 && <AiOutlineCloseCircle />}
            </div>
            <div className="input-content">
              <p>رفع صورة المنشأة من الداخل</p>
              <label htmlFor="upload-photo6">
                {" "}
                <div className="input">
                  <img src={upload} className="icon_upload" alt="" />
                  <div> </div>
                </div>{" "}
              </label>
              <input
                onChange={onChange}
                type="file"
                name="photo6"
                id="upload-photo6"
                data-wrong="wrong6"
                data-path="path6"
              />
              {photo6 && <img className="valid_icon" src={complete} alt="" />}
              {wrong6 && <AiOutlineCloseCircle />}
            </div>
            {errorsFlags[0] && <div className='error' style={{ marginRight: "12px" }} >{errors.allFieldRequired}</div>}
          </div>


          <div className="location">
            <Locate panTo={panTo} />
            <Search panTo={panTo} />
            <GoogleMap
              id="map"
              mapContainerStyle={mapContainerStyle}
              zoom={8}
              center={center}
              options={options}
              onClick={onMapClick}
              onLoad={onMapLoad}
            >
              {markers.map((marker) => (
                <Marker
                  key={`${marker.lat}-${marker.lng}`}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  onClick={() => {
                    setSelected(marker);
                  }}
                  icon={{
                    url: `../imgs/bear.svg`,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                />
              ))}

              {selected ? (
                <InfoWindow
                  position={{ lat: selected.lat, lng: selected.lng }}
                  onCloseClick={() => {
                    setSelected(null);
                  }}
                >
                  <div>
                    <h2>
                      <span role="img" aria-label="bear">
                        <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" xmlnsCc="http://creativecommons.org/ns#" xmlnsDc="http://purl.org/dc/elements/1.1/" xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><g transform="translate(0 -1028.4)"><path d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z" fill="#e74c3c" transform="translate(0 1028.4)" /><path d="m12 3c-2.7614 0-5 2.2386-5 5 0 2.761 2.2386 5 5 5 2.761 0 5-2.239 5-5 0-2.7614-2.239-5-5-5zm0 2c1.657 0 3 1.3431 3 3s-1.343 3-3 3-3-1.3431-3-3 1.343-3 3-3z" fill="#c0392b" transform="translate(0 1028.4)" /></g></svg>
                      </span>{" "}
                      Alert
                    </h2>
                    <p>Spotted {formatRelative(selected.time, new Date())}</p>
                  </div>
                </InfoWindow>
              ) : null}
            </GoogleMap>

            {loading && <div style={{ display: "flex", flexDirection: "column" }}>
              {errorsFlags[0] && <div className='error' style={mystyle4}><img src={err} />{errors.location_msg}</div>}
            </div>}
          </div>
        </div>


        <div className="group-btn">

          <button type="submit" className="nextBtn" onClick={handleSubmit}>
            <img src={arrow} alt="" />
            التالي
          </button>

          <Link to='/MainChooseEnterprise/EnterpriseTags/DataForm'>
            <button className="prevBtn">
              <img src={arrow} alt="" />
              السابق
            </button>
          </Link>
        </div>
      </form>

    </div>
  )
}

export default FileLocationScreen;

function Locate({ panTo }) {
  return (
    <button
      className="locate" style={mystyle3}
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
        //userLocat=false
      }
      }
    >
      <img src={compass} alt="compass" />
    </button>
  );
}


function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
    },
  });


  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <div className="search">
      <Combobox onSelect={handleSelect} className="d-flex justify-content-center" style={mystyle1}>
        <ComboboxInput style={mystyle2}
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="ابحث عن موقعك أو عنوانك المختصر"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
