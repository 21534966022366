import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import "../styles/login.css";
import Message from '../component/Message'
import LoginName from "../component/LoginName";
import { login } from '../actions/userActions'
import { getFacilityDetails } from '../actions/facilityActions'

const SECTOR_TYPE_INSURANCE_COMPANY = 28;

function LoginScreen({ location, history }) {
    const [commercialNum, setcommercialNum] = useState('')

    const [password, setPassword] = useState('')

    const [errors] = useState({
        commercial_No_Start: "رقم السجل التجاري يجب ان يبدأ ب  40 ، 10 أو 11",
        commercial_No_Len: "رقم السجل التجاري يجب ان يكون 10 ارقام",
        passwordLen: "كلمة المرور يجب ان لا تقل عن 8 احرف/ارقام ",
    })

    const [errorsFlags, setErrorsFlags] = useState([false, false, false])

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userLogin = useSelector(state => state.userLogin)

    const facilityDetails = useSelector(state => state.facilityDetails)
    const { facilityInfo, loading } = facilityDetails

    const { error, userInfo } = userLogin

    useEffect(() => {
        if (userInfo) {
            if ((userInfo.user === "5000000000" || userInfo.user === "5000000001" || userInfo.user === "5000000002")) {
                history.push("/admin")

            } else if (userInfo?.user_type == 'employee') {
                history.push("/employee")
            } else {
                if (!userInfo.is_activated) {
                    history.push("/MainChooseEnterprise")
                } else {
                    dispatch(getFacilityDetails())
                    if (!loading) {
                        if (facilityInfo?.sector_type?.pk == SECTOR_TYPE_INSURANCE_COMPANY) {
                            history.push("/ServiceInsuranceCompany");
                        } else if (userInfo.user.startsWith('4')) {
                            history.push("/services");
                        }
                    }
                }
            }
        }

    }, [history, userInfo, redirect, loading])

    const formValidation = () => {
        let isValid = true;
        const flags = [false, false, false];

        if (commercialNum.trim().length !== 10 && !/\@bayan\.md\.sa$/.test(commercialNum)) {
            flags[0] = true;
            isValid = false;
        } else {
            flags[0] = false;
        }
        if (!(commercialNum.startsWith("40") || commercialNum.startsWith("10") || commercialNum.startsWith("11") || commercialNum.startsWith("50")
        || (/\@bayan\.md\.sa$/.test(commercialNum))
        )) {
            flags[1] = true;
            isValid = false;
        } else {
            flags[1] = false;
        }
        if (password.trim().length < 8) {
            flags[2] = true;
            isValid = false;
        } else {
            flags[2] = false;
        }

        setErrorsFlags(flags);
        return isValid;
    };



    const submitHandler = (e) => {
        e.preventDefault()
        const isValid = formValidation();
        if (isValid) {
            dispatch(login(commercialNum, password))
        }

    }

    return (

        <div className="login-page" dir="ltr">
            <div className="login">
                <div className="title text-center">تسجيل دخول</div>
                <form onSubmit={submitHandler}>
                    <input
                        className="commercial-No"
                        type="text"
                        name="commercialNum"
                        value={commercialNum}
                        placeholder="رقم السجل التجاري"
                        onChange={(e) => setcommercialNum(e.target.value)}
                    />
                    {errorsFlags[0] && <Message variant='danger'>{errors.commercial_No_Len}</Message>}
                    {errorsFlags[1] && <Message variant='danger'>{errors.commercial_No_Start}</Message>}
                    <div className="password-info">
                        <input
                            className="password"
                            type="password"
                            name="password"
                            value={password}
                            placeholder="كلمة المرور"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {errorsFlags[2] && <Message variant='danger'>{errors.passwordLen}</Message>}

                        <div className="pass-remember">
                            <Link to=''>
                                <p className="forget-pass">هل نسيت كلمة المرور ؟</p>
                            </Link>
                            <div className="remember-me">
                                <p >تذكرني</p>
                                <span ></span>
                            </div>
                        </div>
                    </div>
                    <button className="login-btn" type="submit">دخول</button>
                    {error && <Message variant='danger'>{error}</Message>}
                </form>
                <div className="sing-up">
                    
                    <div>
                        <div className="branch text-center">الدخول كفرع</div>
                    </div>

                    <Link
                        to={'/createaccount'}>
                        <div className="new-reg text-center">تسجيل جديد</div>
                    </Link>
                </div>
            </div>
            <LoginName Home={true} Facility={false} />
        </div>
    )
}

export default LoginScreen
