import React, { Component, useState } from "react";
import "../../styles/stat/rate_network_stat.css";
import { Column } from "@ant-design/charts";
import "react-sweet-progress/lib/style.css";
import CountUp from "react-countup";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "../control_panel/animation/AnimatedProgressProvider";
import { easeQuadInOut } from "d3-ease";
import arrow from "../../imgs/Group 224.png";
import { Link } from "react-router-dom";
import "../../styles/stat/more-details.css";
// export class Rate_Network_Stat extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       total: 14578,
//       target: 20000,
//     };
//   }

//   render() {
//     const { total, target } = this.state;
//     const percentage =
//       total > target
//         ? ((total / target) * 100).toFixed(3) -
//           (((total / target) * 100) % 100).toFixed(3)
//         : ((total / target) * 100).toFixed(2);
//     var data = [
//       {
//         name: "المجموع",
//         facility: "مستوصف",
//         number: 30,
//       },
//       {
//         name: "المجموع",
//         facility: "عيادات",
//         number: 38,
//       },
//       {
//         name: "المجموع",
//         facility: "مستتتشفى",
//         number: 44,
//       },
//       {
//         name: "المجموع",
//         facility: "مختبرات",
//         number: 82,
//       },
//       {
//         name: "المجموع",
//         facility: "مركز تاهيل",
//         number: 47,
//       },
//       {
//         name: "المجموع",
//         facility: "مركز طبي",
//         number: 20,
//       },
//       {
//         name: "المجموع",
//         facility: "عيادات اليوم الواحد",
//         number: 24,
//       },
//       {
//         name: "المجموع",
//         facility: "اشعة",
//         number: 66,
//       },
//       {
//         name: "المجموع",
//         facility: "مجمع عيادات",
//         number: 55,
//       },
//       {
//         name: "المستهدف",
//         facility: "مستوصف",
//         number: 38,
//       },
//       {
//         name: "المستهدف",
//         facility: "عيادات",
//         number: 88,
//       },
//       {
//         name: "المستهدف",
//         facility: "مستتتشفى",
//         number: 96,
//       },
//       {
//         name: "المستهدف",
//         facility: "مختبرات",
//         number: 99,
//       },
//       {
//         name: "المستهدف",
//         facility: "مركز تاهيل",
//         number: 50,
//       },
//       {
//         name: "المستهدف",
//         facility: "مركز طبي",
//         number: 30,
//       },
//       {
//         name: "المستهدف",
//         facility: "عيادات اليوم الواحد",
//         number: 37,
//       },
//       {
//         name: "المستهدف",
//         facility: "اشعة",
//         number: 42,
//       },
//       {
//         name: "المستهدف",
//         facility: "مجمع عيادات",
//         number: 42,
//       },
//     ];
//     var config = {
//       data: data,
//       isGroup: true,
//       xField: "facility",
//       yField: "number",
//       seriesField: "name",
//       color: ["#e64f39", "#DFE0DF"],

//       maxColumnWidth: 50,
//       columnStyle: {
//         radius: [10, 10, 0, 0],
//       },
//       label: {
//         position: "middle",
//         layout: [
//           { type: "interval-adjust-position" },
//           { type: "interval-hide-overlap" },
//           { type: "adjust-color" },
//         ],
//       },
//     };

//     return (
//       <div className="rate_stat">
//         <div className="header">تقيم الشبكات الطبية </div>

//         <div className="chart">
//           <div className="col">
//             <Column {...config} />
//             <div className="info_item">
//               <p className="title">
//                 العدد الإجمالي لكل فئة منشأة (مستشفى ، مختبرات…. الخ
//               </p>
//               <span
//                 className="list"
//                 style={{ background: "var(--red-color)" }}
//               ></span>
//             </div>
//           </div>

//           <div className="bar">
//             <div className="total_number">
//               <CountUp start={0} end={total} duration={2} separator="," />
//             </div>
//             <div className="target">المستهدف : 20,000</div>

//             <div style={{ display: "flex", marginBottom: "30px" }}>
//               <div className="progress">
//                 <AnimatedProgressProvider
//                   valueStart={0}
//                   valueEnd={percentage}
//                   duration={1.4}
//                   easingFunction={easeQuadInOut}
//                 >
//                   {(value) => {
//                     const roundedValue = Math.round(value);
//                     return (
//                       <CircularProgressbar
//                         value={value}
//                         text={`${roundedValue}%`}
//                         styles={buildStyles({
//                           pathColor: `#0fb0a9`,
//                           textColor: "#0fb0a9",
//                           trailColor: "#d6d6d6",
//                           textSize: "16px",
//                           strokeLinecap: "round",
//                           pathTransition: "none",
//                         })}
//                       />
//                     );
//                   }}
//                 </AnimatedProgressProvider>
//               </div>
//               <div className="info_item" style={{ marginBottom: "50px" }}>
//                 <p className="title">إجمالي عدد العملاء المشتركين</p>
//                 <span className="list"></span>
//               </div>
//             </div>
//             <Link to="/RateNetworkMoreDetails">
//               <button className="back-btn">
//                 <img src={arrow} alt="" />
//                 للمزيد من التفاصيل
//               </button>
//             </Link>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default Rate_Network_Stat;

function Rate_Network_Stat(props) {
  const [state, setState] = useState({
    total: 14578,
    target: 20000,
  });

  const [percentage, setPercentage] = useState(
    state.total > state.target
      ? ((state.total / state.target) * 100).toFixed(3) -
          (((state.total / state.target) * 100) % 100).toFixed(3)
      : ((state.total / state.target) * 100).toFixed(2)
  );

  const [data, setData] = useState([
    {
      name: "المجموع",
      facility: "مستوصف",
      number: 30,
    },
    {
      name: "المجموع",
      facility: "عيادات",
      number: 38,
    },
    {
      name: "المجموع",
      facility: "مستتتشفى",
      number: 44,
    },
    {
      name: "المجموع",
      facility: "مختبرات",
      number: 82,
    },
    {
      name: "المجموع",
      facility: "مركز تاهيل",
      number: 47,
    },
    {
      name: "المجموع",
      facility: "مركز طبي",
      number: 20,
    },
    {
      name: "المجموع",
      facility: "عيادات اليوم الواحد",
      number: 24,
    },
    {
      name: "المجموع",
      facility: "اشعة",
      number: 66,
    },
    {
      name: "المجموع",
      facility: "مجمع عيادات",
      number: 55,
    },
    {
      name: "المستهدف",
      facility: "مستوصف",
      number: 38,
    },
    {
      name: "المستهدف",
      facility: "عيادات",
      number: 88,
    },
    {
      name: "المستهدف",
      facility: "مستتتشفى",
      number: 96,
    },
    {
      name: "المستهدف",
      facility: "مختبرات",
      number: 99,
    },
    {
      name: "المستهدف",
      facility: "مركز تاهيل",
      number: 50,
    },
    {
      name: "المستهدف",
      facility: "مركز طبي",
      number: 30,
    },
    {
      name: "المستهدف",
      facility: "عيادات اليوم الواحد",
      number: 37,
    },
    {
      name: "المستهدف",
      facility: "اشعة",
      number: 42,
    },
    {
      name: "المستهدف",
      facility: "مجمع عيادات",
      number: 42,
    },
  ]);

  const [config, setConfig] = useState({
    data: data,
    isGroup: true,
    xField: "facility",
    yField: "number",
    seriesField: "name",
    color: ["#e64f39", "#DFE0DF"],

    maxColumnWidth: 50,
    columnStyle: {
      radius: [10, 10, 0, 0],
    },
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  });

  const hospitals = [
    {
      hospitalNo: "H-0001",
      hospitalName: "سليمان فقية",
      rate: "VIP",
      endDate: "22 / 8 / 2021",
      btns: [true, true, true, true, false],
    },
    {
      hospitalNo: "H-0001",
      hospitalName: "سليمان فقية",
      rate: "VIP",
      endDate: "22 / 8 / 2021",
      btns: [false, false, false, false, true],
    },

    {
      hospitalNo: "H-0001",
      hospitalName: "سليمان فقية",
      rate: "VIP",
      endDate: "22 / 8 / 2021",
      btns: [true, true, true, true, false],
    },
  ];

  return (
    <>
      <div className="rate_stat">
        <div className="header">تقيم الشبكات الطبية </div>

        <div className="chart revers">
          <div className="col">
            <Column {...config} />
            <div className="info_item">
              <p className="title">
                العدد الإجمالي لكل فئة منشأة (مستشفى ، مختبرات…. الخ
              </p>
              <span
                className="list"
                style={{ background: "var(--red-color)" }}
              ></span>
            </div>
          </div>

          <div className="bar">
            <div className="total_number">
              <CountUp start={0} end={state.total} duration={2} separator="," />
            </div>
            <div className="target">المستهدف : 20,000</div>

            <div
              style={{
                display: "flex",
                marginBottom: "30px",
                flexDirection: "column",
                marginTop: "40px",
              }}
            >
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={percentage}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}%`}
                        styles={buildStyles({
                          pathColor: `#0fb0a9`,
                          textColor: "#0fb0a9",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
              <div className="info_item" style={{ marginBottom: "50px" }}>
                <p className="title">إجمالي عدد العملاء المشتركين</p>
                <span className="list"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="more_details">
        <div className="rate_content">
          <div className="headingRate">عدد العملاء بناء على نوع التصيف</div>
          <div className="rate_items">
            <div className="rate_item">
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={20}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#0fb0a9`,
                          textColor: "#0fb0a9",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
              <div className="rate_title">D</div>
            </div>
            <div className="rate_item">
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={40}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#0fb0a9`,
                          textColor: "#0fb0a9",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
              <div className="rate_title">C</div>
            </div>
            <div className="rate_item">
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={55}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#0fb0a9`,
                          textColor: "#0fb0a9",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
              <div className="rate_title">B</div>
            </div>
            <div className="rate_item">
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={70}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#0fb0a9`,
                          textColor: "#0fb0a9",
                          trailColor: "#d6d6d6",
                          textSize: "16px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
              <div className="rate_title">A</div>
            </div>
            <div className="rate_item">
              <div className="progress">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={85}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <CircularProgressbar
                        value={value}
                        text={`${roundedValue}`}
                        styles={buildStyles({
                          pathColor: `#0fb0a9`,
                          textColor: "#0fb0a9",
                          trailColor: "#d6d6d6",
                          textSize: "18px",
                          strokeLinecap: "round",
                          pathTransition: "none",
                          fontWeight: "bold",
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
              <div className="rate_title">VIP</div>
            </div>
          </div>
        </div>

        <div className="operation_list" style={{ direction: "rtl" }}>
          <div className="heading">قائمة المستشفيات</div>
          <div className="operation_table">
            <div className="header_row" style={{ flexDirection: "row" }}>
              <div style={{ width: "199px", textAlign: "center" }}>
                كود الجهة الصحية
              </div>
              <div style={{ width: "337px", textAlign: "center" }}>
                اسم الجهة الصحية
              </div>
              <div style={{ width: "139px", textAlign: "center" }}>الفئة</div>
              <div style={{ width: "251px", textAlign: "center" }}>
                تاريخ إنتهاء الإشتراك بالخدمة
              </div>
              <div style={{ width: "341px", textAlign: "center" }}>
                الاجراءات
              </div>
            </div>
            {hospitals.map((op) => (
              <div className="row_item">
                <div
                  className="col_item"
                  style={{ width: "199px", textAlign: "center" }}
                >
                  {op.hospitalNo}
                </div>
                <div
                  className="col_item"
                  style={{ width: "337px", textAlign: "center" }}
                >
                  {op.hospitalName}
                </div>
                <div
                  className="col_item"
                  style={{ width: "139px", textAlign: "center" }}
                >
                  {op.rate}
                </div>
                <div
                  className="col_item"
                  style={{ width: "251px", textAlign: "center" }}
                >
                  {op.endDate}
                </div>
                <div
                  style={{ width: "341px", textAlign: "center" }}
                  className="btn_group"
                >
                  {op.btns[0] && (
                    <button style={{ backgroundColor: "var(--main-color)" }}>
                      عرض
                    </button>
                  )}
                  {op.btns[1] && <button>طباعة</button>}
                  {op.btns[2] && <button>إيقاف مؤقت</button>}
                  {op.btns[3] && (
                    <button style={{ backgroundColor: "var(--red-color)" }}>
                      حذف
                    </button>
                  )}
                  {op.btns[4] && (
                    <button
                      style={{
                        backgroundColor: "var(--main-color)",
                        width: "285px",
                        margin: "auto",
                      }}
                    >
                      Excel
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Rate_Network_Stat;
