
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from 'react-redux';
import { getFacilityDetailsForAdmin } from '../actions/adminActions';
import "../styles/certificateAdmin.css";
import "../styles/clientInformation.css";
import "../styles/invoiceAllType.css";
import "../styles/newOrderAdmin.css";
import H1 from "./components/print/h1";
import Hdate from "./components/print/Hdate";
import { Modal } from "react-bootstrap";
import logo from "./evaluation-print/logo.svg";
import social1 from "./evaluation-print/social1.svg";
import social2 from "./evaluation-print/social2.svg";
import social3 from "./evaluation-print/social3.svg";
import social4 from "./evaluation-print/social4.svg";

function FacilityInformation({ match, history }) {

	const [sec, setSec] = useState("")

	const [secType, setSecType] = useState("")

	const [name, setName] = useState('')

	const [mailBox, setMailBox] = useState("")

	const [postalCode, setPostalCode] = useState('')

	const [selectTitle, setSelectTitle] = useState('')

	const [startDateCommercialRegister, setStartDateCommercialRegister] = useState('')

	const [endDateCommercialRegister, setEndDateCommercialRegister] = useState('')

	const [medicalLicenseNumber, setMedicalLicenseNumber] = useState('')

	const [startDateMedicalLicense, setStartDateMedicalLicense] = useState('')

	const [regNum, setRegNum] = useState('');

	const [endDateMedicalLicense, setEndDateMedicalLicense] = useState('')

	const [licenseNumber, setLicenseNumber] = useState('')

	const [startDateLicense, setStartDateLicense] = useState('')

	const [endDateLicense, setEndDateLicense] = useState('')

	const [warrantyBoardNumber, setWarrantyBoardNumber] = useState('')

	const [startDateWarrantyBoard, setStartDateWarrantyBoard] = useState('')

	const [endDateWarrantyBoard, setEndDateWarrantyBoard] = useState('')

	const [foodAuthorityNumber, setfoodAuthorityNumber] = useState('')

	const [startDateFoodAuthority, setstartDateFoodAuthority] = useState('')

	const [endDateFoodAuthority, setEndDateFoodAuthority] = useState('')

	const [centralBankNumber, setCentralBankNumber] = useState('')

	const [startDateBank, setStartDateBank] = useState('')

	const [endDateBank, setEndDateBank] = useState('')

	const [bankName, setBankName] = useState('')

	const [ibanNumber, setIbanNumber] = useState('')

	const [financialManagerName, setFinancialManagerName] = useState('')

	const [fmEmail, setFmEmail] = useState('')

	const [fmPhoneNumber, setFmPhoneNumber] = useState('')

	const [salesMangerName, setSalesMangerName] = useState('')

	const [smEmail, setSmEmail] = useState('')

	const [smPhoneNumber, setSmPhoneNumber] = useState('')

	const [valueAddNumber, setValueAddNumber] = useState('')

	const [employeeNumber, setEmployeeNumber] = useState('')

	const [ownerName, setOwnerName] = useState('')

	const [ownerPhone, setOwnerPhone] = useState('')

	const [ownerAddress, setOwnerAddress] = useState('')

	const [ownerRegion, setOwnerRegion] = useState('')

	const [ownerPostalCode, setOwnerPostalCode] = useState('')

	const [ownerId, setOwnerId] = useState('')

	const [ownerEmail, setOwnerEmail] = useState('')

	const [ownerMailBox, setOwnerMailBox] = useState('')

	const [ownerCity, setOwnerCity] = useState('')

	const [employeeName, setEmployeeName] = useState('')

	const [employeePhone, setEmployeePhone] = useState('')

	const [employeeAddress, setEmployeeAddress] = useState('')

	const [employeeRegion, setEmployeeRegion] = useState('')

	const [employeePostalCode, setEmployeePostalCode] = useState('')

	const [employeeId, setEmployeeId] = useState('')

	const [employeeEmail, setEmployeeEmail] = useState('')

	const [employeeMailBox, setEmployeeMailBox] = useState('')

	const [employeeCity, setEmployeeCity] = useState('')

	const [clientCode, setClientCode] = useState('')

	const [createdAt, setCreatedAt] = useState("");

	const [photo1, setPhoto1] = useState(false);

	const [photo2, setPhoto2] = useState(false);

	const [photo3, setPhoto3] = useState(false);

	const [photo4, setPhoto4] = useState(false);

	const [modalVisible, setModalVisible] = useState(false);

	let images = [
		"https://via.placeholder.com/1748x2480",
		"https://via.placeholder.com/1748x2480",
		"https://via.placeholder.com/1748x2480",
		"https://via.placeholder.com/1748x2480",
		"https://via.placeholder.com/1748x2480",
		"https://via.placeholder.com/1748x2480",
	];

	const teamId = match.params.id

	function handleClick() {
		window.print();
	}

	const dispatch = useDispatch();

	const facilityDetailsForAdmin = useSelector(state => state.facilityDetailsForAdmin);
	const { facilityInfo, success } = facilityDetailsForAdmin;

	const userLogin = useSelector(state => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {

		if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
			dispatch(getFacilityDetailsForAdmin(match.params.id));
			if (success && facilityInfo) {

				if (facilityInfo?.user)
					setRegNum(facilityInfo.user.commercial_registration_num);

				setClientCode(facilityInfo?.client_code);
				setCreatedAt(facilityInfo?.created_at);
				setName(facilityInfo?.facility_name)
				setSelectTitle(facilityInfo?.state)
				setStartDateCommercialRegister(facilityInfo?.issue_date)
				setEndDateCommercialRegister(facilityInfo?.expiration_date)
				setMailBox(facilityInfo?.post_box)
				setPostalCode(facilityInfo?.postal_code)
				setSelectTitle(facilityInfo?.location)
				setMedicalLicenseNumber(facilityInfo?.medical_permit_num)
				setStartDateMedicalLicense(facilityInfo?.permit_issue_date)
				setEndDateMedicalLicense(facilityInfo?.permit_expiry_date)
				setWarrantyBoardNumber(facilityInfo?.health_insurance_council_num)
				setStartDateWarrantyBoard(facilityInfo?.insurance_issue_date)
				setEndDateWarrantyBoard(facilityInfo?.insurance_expiry_date)
				setfoodAuthorityNumber(facilityInfo?.food_drug_authority_licence_num)
				setstartDateFoodAuthority(facilityInfo?.food_drug_authority_issue_date)
				setEndDateFoodAuthority(facilityInfo?.food_drug_authority_expiry_date)
				setCentralBankNumber(facilityInfo?.central_bank_number)
				setStartDateBank(facilityInfo?.central_bank_issue_date)
				setEndDateBank(facilityInfo?.central_bank_expiry_date)
				setIbanNumber(facilityInfo?.iban_num)
				setBankName(facilityInfo?.bank_name)
				setFinancialManagerName(facilityInfo?.financial_manager_name)
				setFmEmail(facilityInfo?.financial_manager_email)
				setFmPhoneNumber(facilityInfo?.financial_manager_phone_num)
				setValueAddNumber(facilityInfo?.tax_number)
				setSalesMangerName(facilityInfo?.purchasing_manager_name)
				setSmEmail(facilityInfo?.purchasing_manager_email)
				setSmPhoneNumber(facilityInfo?.purchasing_manager_phone_num)
				setEmployeeNumber(facilityInfo?.num_of_employees)
				setSec(facilityInfo?.sector.sector_name)
				setSecType(facilityInfo?.sector_type.type_name)

				{/************** Owner *************/ }
				if (facilityInfo?.facility_owners.length > 0) {
					setOwnerName(facilityInfo?.facility_owners[0].name)
					setOwnerPhone(facilityInfo?.facility_owners[0].phone_number)
					setOwnerAddress(facilityInfo?.facility_owners[0].address)
					setOwnerRegion(facilityInfo?.facility_owners[0].state)
					setOwnerPostalCode(facilityInfo?.facility_owners[0].postal_code)
					setOwnerId(facilityInfo?.facility_owners[0].identity_number)
					setOwnerEmail(facilityInfo?.facility_owners[0].user.email)
					setOwnerMailBox(facilityInfo?.facility_owners[0].post_box)
					setOwnerCity(facilityInfo?.facility_owners[0].city)
					setPhoto1(facilityInfo?.facility_owners[0].identity_pic)
				}

				if (facilityInfo?.facility_employees.length > 0) {
					{/************** Employee *************/ }
					setEmployeeName(facilityInfo?.facility_employees[0].name)
					setEmployeePhone(facilityInfo?.facility_employees[0].phone_number)
					setEmployeeAddress(facilityInfo?.facility_employees[0].address)
					setEmployeeRegion(facilityInfo?.facility_employees[0].state)
					setEmployeePostalCode(facilityInfo?.facility_employees[0].postal_code)
					setEmployeeId(facilityInfo?.facility_employees[0].identity_number)
					setEmployeeEmail(facilityInfo?.facility_employees[0].user.email)
					setEmployeeMailBox(facilityInfo?.facility_employees[0].post_box)
					setEmployeeCity(facilityInfo?.facility_employees[0].city)

					setPhoto2(facilityInfo?.facility_employees[0].identity_pic)
				}



				setPhoto3(facilityInfo?.cr_image)
				setPhoto3(facilityInfo?.medical_permit_image)
				setPhoto4(facilityInfo?.health_insurance_council_image)

			}
		} else {
			history.push('/')
		}

	}, [dispatch, history, userInfo, success])

	const retrieveDate = (d) => {
		let date = new Date(d);
		return date
	}

	var fileDownload = require('js-file-download');


	const handlePDFDownload = (filename) => {
		if (!filename) {
			setModalVisible(true);
			return;
		}

		axios.post('management/download/', {
			responseType: 'blob', filename: filename
		}).then(res => {
			fileDownload(res.data, filename);
		}).catch(err => {
		})
	}

	let tableData = {
		"اسم الجهة": name,
		" رقم ص.ب": mailBox,
		"الرمز البريدى": postalCode,
		"موقع المنشأة": selectTitle,

	};

	function getContent() {
		return (
			<>
				<div className="header_c" />

				<h1 className="inv_h1" style={{ fontSize: "1.4rem" }}>بيانات التسجيل</h1>

				<img className="inv_img" alt="logo" src={logo} />

				{/* <h1 className=" h1_name">{name}</h1> */}

				<Hdate
					className="inv_Hdate_table"
					date={createdAt.substring(0, 10)}
					code={clientCode}
					codeTitle="كود العميل:" />

				<p className="inv_QR_title">كود التسجيل:</p>

				<QRCode className="inv_QR" value={window.location.href} size="75" />

				<div className="inv_data" style={{ marginTop: '-30px' }}>
					<p style={{ fontSize: '14px' }}>القطاع : </p>
					<p>{sec}</p>
					<p style={{ fontSize: '14px', marginTop: '10px' }}>نوع القطاع : </p>
					<p>{secType}</p>
				</div>

				<div className="in_table">
					<Row className="m-4">
						<Table className="table table-borderless">
							<thead class="thead">
								<tr>
									<th colSpan="20" className='text-left'>
										بيانات المنشأة
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="fw-bold grayBG">اسم الجهة:</td>
									<td>{name}</td>
									<td className="fw-bold grayBG"> رقم ص.ب:</td>
									<td>{mailBox}</td>

								</tr><tr>

									<td className="fw-bold grayBG"> الرمز البريدى:</td>
									<td>{postalCode}</td>
									<td className="fw-bold grayBG">موقع المنشأة:</td>
									<td>{selectTitle}</td>

								</tr><tr>
									<td className="fw-bold grayBG"> رقم السجل:</td>
									<td>{regNum.substring(0, 10)}</td>
									<td className="fw-bold grayBG"> اصدار السجل:</td>
									<td>{startDateCommercialRegister.substring(0, 10)}</td>
								</tr>
								<tr>

									<td className="fw-bold grayBG"> انتهاء السجل:</td>
									<td>{endDateCommercialRegister.substring(0, 10)}</td>
									{sec !== 7 && sec !== 8 &&
										<>
											<td className="fw-bold grayBG">رقم الترخيص :</td>
											<td>{licenseNumber}</td>
										</>
									}
								</tr>
								<tr>
									{sec !== 7 && sec !== 8 &&
										<>
											<td className="fw-bold grayBG">اصدار الترخيص:</td>
											<td>{startDateLicense.substring(0, 10)}</td>
											<td className="fw-bold grayBG">انتهاء الترخيص :</td>
											<td>{endDateLicense.substring(0, 10)}</td>
										</>
									}

									{((sec === 7)) &&
										<>
											<td className="fw-bold grayBG"> :الترخيص الطبي</td>
											<td>{medicalLicenseNumber}</td>
										</>
									}
								</tr><tr>
									{((sec === 7)) &&
										<>
											<td className="fw-bold grayBG">اصدار الترخيص:</td>
											<td>{startDateMedicalLicense.substring(0, 10)}</td>
											<td className="fw-bold grayBG">انتهاء الترخيص :</td>
											<td>{endDateMedicalLicense.substring(0, 10)}</td>
										</>
									}
								</tr><tr>

									{((sec === 7)) &&
										<>
											<td className="fw-bold grayBG">مجلس الضمان :</td>
											<td>{warrantyBoardNumber}</td>
											<td className="fw-bold grayBG">اصدار الرقم:</td>
											<td>{startDateWarrantyBoard.substring(0, 10)}</td>

											{"</tr><tr>"}
											<td className="fw-bold grayBG">انتهاء الرقم :</td>
											<td>{endDateWarrantyBoard.substring(0, 10)}</td>
										</>
									}

									{(((sec !== 15) && ((sec === 7) && (secType !== 28))) || (sec === 8)) &&
										<>
											<td className="fw-bold grayBG">رقم ترخيص هيئة الغذاء والدواء :</td>
											<td>{foodAuthorityNumber}</td>
										</>
									}

								</tr><tr>

									{(((sec !== 15) && ((sec === 7) && (secType !== 28))) || (sec === 8)) &&
										<>
											<td className="fw-bold grayBG">اصدار الترخيص</td>
											<td>{startDateFoodAuthority.substring(0, 10)}</td>
											<td className="fw-bold grayBG">انتهاء الرقم :</td>
											<td>{endDateFoodAuthority.substring(0, 10)}</td>
										</>
									}
								</tr><tr>

									{((sec === 7)) &&
										<>
											<td className="fw-bold grayBG">رقم ترخيص البنك المركزى السعودى:</td>
											<td>{centralBankNumber}</td>
											<td className="fw-bold grayBG">اصدار البنك المركزى السعودى :</td>
											<td>{startDateBank.substring(0, 10)}</td>
										</>
									}
								</tr><tr>
									{((sec === 7)) &&
										<>
											<td className="fw-bold grayBG">انتهاء البنك المركزى السعودى :</td>
											<td>{endDateBank.substring(0, 10)}</td>
										</>
									}

									<td className="fw-bold grayBG">اسم البنك</td>
									<td>{bankName}</td>

								</tr><tr>

									<td className="fw-bold grayBG">رقم IBN:</td>
									<td>{ibanNumber}</td>

									<td className="fw-bold grayBG">المدير المالي :</td>
									<td>{financialManagerName}</td>

								</tr><tr>

									<td className="fw-bold grayBG">البريد الالكتروني:</td>
									<td>{fmEmail}</td>
									<td className="fw-bold grayBG">الهاتف :</td>
									<td>{fmPhoneNumber}</td>

								</tr><tr>

									<td className="fw-bold grayBG">مدير المشتريات :</td>
									<td>{salesMangerName}</td>
									<td className="fw-bold grayBG">البريد الالكتروني:</td>
									<td>{smEmail}</td>

								</tr><tr>

									<td className="fw-bold grayBG">الهاتف :</td>
									<td>{smPhoneNumber}</td>

									<td className="fw-bold grayBG">ضريبة القيمة المضافة :</td>
									<td>{valueAddNumber}</td>

								</tr><tr>
									<td className="fw-bold grayBG">موظفين المنشأة</td>
									<td>{employeeNumber}</td>
								</tr>
							</tbody>
						</Table>
					</Row>

					{
						facilityInfo && facilityInfo?.facility_owners && facilityInfo?.facility_owners.length > 0 &&
						<Row className="m-4">
							<Table className="table table-borderless">
								<thead class="thead">
									<tr>
										<th colSpan="20" className='text-right'>
											بيانات صاحب المنشأة أو المدير
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="fw-bold grayBG">الاسم:</td>
										<td>{ownerName}</td>
										<td className="fw-bold grayBG">رقم الهاتف :</td>
										<td>{ownerPhone}</td>
										<td className="fw-bold grayBG">العنوان</td>
										<td>{ownerAddress}</td>
									</tr>
									<tr>
										<td className="fw-bold grayBG">المنطقة :</td>
										<td>{ownerRegion}</td>
										<td className="fw-bold grayBG"> الرمز البريدى</td>
										<td>{ownerPostalCode}</td>
										<td className="fw-bold grayBG"> رقم الهوية :</td>
										<td>{ownerId}</td>
									</tr>

									<tr>
										<td className="fw-bold grayBG">الايميل الرسمي :</td>
										<td>{ownerEmail}</td>
										<td className="fw-bold grayBG"> رقم ص.ب</td>
										<td>{ownerMailBox}</td>
										<td className="fw-bold grayBG"> المدينة :</td>
										<td>{ownerCity}</td>
									</tr>
								</tbody>
							</Table>
						</Row>
					}

					{
						facilityInfo?.facility_employees?.length > 0 &&

						<Row className="m-4">
							<Table className="table table-borderless">
								<thead class="thead">
									<tr>
										<th colSpan="20" class="text-right">
											تعديل بيانات الموظف المصرح له بأستخدام المنصة
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="fw-bold grayBG">الاسم:</td>
										<td>{employeeName}</td>
										<td className="fw-bold grayBG">رقم الهاتف :</td>
										<td>{employeePhone}</td>
										<td className="fw-bold grayBG">العنوان</td>
										<td>{employeeAddress}</td>
									</tr>
									<tr>
										<td className="fw-bold grayBG">المنطقة :</td>
										<td>{employeeRegion}</td>
										<td className="fw-bold grayBG"> الرمز البريدى</td>
										<td>{employeePostalCode}</td>
										<td className="fw-bold grayBG"> رقم الهوية :</td>
										<td>{employeeId}</td>
									</tr>

									<tr>
										<td className="fw-bold grayBG">الايميل الرسمي :</td>
										<td>{employeeEmail}</td>
										<td className="fw-bold grayBG"> رقم ص.ب</td>
										<td>{employeeMailBox}</td>
										<td className="fw-bold grayBG"> المدينة :</td>
										<td>{employeeCity}</td>
									</tr>
								</tbody>
							</Table>
						</Row>

					}

					<Row>
						<Col style={{ textAlign: 'center' }}>
							<Form.Check
								style={{ display: 'inline-block' }}
								type="checkbox"
								checked
								label="هل تمت الموافقة على الإقرار"
							/>
							<Form.Check
								style={{ display: 'inline-block', marginRight: '20px' }}
								type="checkbox" checked label={`هل تم الدفع`} />
						</Col>
					</Row>
				</div>

				<div className="inv_footer">
					<span className="footer_text">الإيميل : info@bayan.med.sa</span>
					<div className="social_neworder_div">
						<a href="http://www.bayan.med.sa/">
							<img className="social_neworder" alt="social" src={social4} />
						</a>
						<a href="http://www.bayan.med.sa/">
							<img className="social_neworder" alt="social" src={social3} />
						</a>
						<a href="http://www.bayan.med.sa/">
							<img className="social_neworder" alt="social" src={social2} />
						</a>
						<a href="http://www.bayan.med.sa/">
							<img className="social_neworder" alt="social" src={social1} />
						</a>
					</div>
				</div>
			</>
		)
	}

	function getContent2() {
		return (
			<>
				<div style={{ position: 'absolute', bottom: '20px' }}>

					<div>
						<Row>
							<Col
								lg="12"
								className="d-flex flex-column align-items-center"
							>
								<Button
									onClick={() => handlePDFDownload(photo1)}
									className="btnNext m-0 my-2"
									style={{ width: "93%" }}
								>
									{" "}
									تحميل  صورة هوية صاحب المنشأة{" "}
								</Button>
							</Col>

							<Col
								lg="12"
								className="d-flex flex-column align-items-center"
							>
								<Button
									onClick={() => handlePDFDownload(photo2)}
									className="btnNext m-0 my-2"
									style={{ width: "93%" }}
								>
									{" "}
									تحميل  صورة هوية الموظف{" "}
								</Button>
							</Col>

							<Col
								lg="12"
								className="d-flex flex-column align-items-center"
							>
								<Button
									onClick={() => handlePDFDownload(photo3)}
									className="btnNext m-0 my-2"
									style={{ width: "93%" }}
								>
									{" "}
									تحميل صورة المنشأة من الخارج{" "}
								</Button>
							</Col>

							<Col
								lg="12"
								className="d-flex flex-column align-items-center"
							>
								<Button
									onClick={() => handlePDFDownload(photo4)}
									className="btnNext m-0 my-2"
									style={{ width: "93%" }}
								>
									{" "}
									تحميل صورة المنشأة من الداخل{" "}
								</Button>
							</Col>
						</Row>
					</div>

					<div className="p-5" style={{ marginTop: "60px" }}>
						<Row>
							<Col lg="6" className="p-0">
								<p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
								<div className="new_box"></div>
							</Col>
							<Col lg="6" className="p-0">
								<p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
								<div className="new_box"></div>
							</Col>
						</Row>
						<Row className="new_sign">
							<Col lg="1">التوقيع:</Col>
							<Col lg="5">
								<div className="border-bottom mt-4" />
							</Col>
							<Col lg="1">التوقيع:</Col>
							<Col lg="5">
								<div className="border-bottom mt-4" />
							</Col>
						</Row>
					</div>
				</div>
			</>
		)
	}


	return (
		<div>
			<div className="w-100" style={{ direction: "rtl" }} >
				<>
					<Container className="inv_a4 screen">
						<Modal style={{ direction: "rtl" }} show={modalVisible} onHide={() => setModalVisible(false)}
							size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
							<Modal.Header style={{ backgroundColor: "var(--main-color)", justifyContent: "center" }}>
								<Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
							</Modal.Header>
							<Modal.Body className="h4">الملف غير موجود</Modal.Body>
							<Modal.Footer>
								<Button variant="success" className="btn-main px-5" onClick={() => setModalVisible(false)}>
									<span className="h4">تأكيد</span>
								</Button>
							</Modal.Footer>
						</Modal>
						<Button
							onClick={() => handleClick()}
							className="print_hide m-4 inv_button-print bg-secondary"
						>
							طباعة
						</Button>

						{/* mainContent */}
						{getContent()}

					</Container>

					<Container className="inv_a4 screen">
						{getContent2()}
					</Container>

					<page size="A4" style={{ fontFamily: 'DroidBold' }} className="print">
						{getContent()}
						{getContent2()}
					</page>
				</>
			</div>
		</div>
	);
}
export default FacilityInformation;
