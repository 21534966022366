import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {listAllMedicalTeamForAdmin} from '../actions/adminActions' 
import AdminLayout from "../adminScreen/layouts/AdminLayout";
import {Row, Col, Card, Table, Form, Button, Modal} from 'react-bootstrap';
import "../styles/TableNewDesign.css";
import Paginate from '../component/Paginate'
import { Link } from "react-router-dom";
import Message from '../component/Message'
import Loader from '../component/Loader'


import {deleteMedicalTeam, updateMedicalTeam} from '../actions/serviceActions' 

function AllMedicalTeam({history}) {
    
    const [selectValue, setSelectValue] = useState("");
    const [show, setShow] = useState(false)

    const dispatch = useDispatch()

    const medicalTeamAllListForAdmin = useSelector(state => state.medicalTeamAllListForAdmin)
    const {loading, error, medicalTeam, page, pages} = medicalTeamAllListForAdmin

    const medicalTeamDelete = useSelector(state => state.medicalTeamDelete)
    const {error:errorDelete, loading:loadingDelete, success:successDelete} = medicalTeamDelete

    const medicalTeamUpdate = useSelector(state => state.medicalTeamUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = medicalTeamUpdate

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    let keyword = history.location.search

    useEffect(() => {

        if(userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
          dispatch(listAllMedicalTeamForAdmin(keyword))
        } else {
          history.push('/')
        }

    
    }, [dispatch, history, userInfo, keyword, successDelete, successUpdate ])

    const more = {
      title: "الاجراءات",
      actions: {
          "تفعيل": "approve",
          "طباعة": "/admin/print-medical-technical-team",
          "حذف": "delete",
      },
    }

    const handleChange = (e) => {
      setSelectValue(e.target.value);
    }

    const handleDelete = (id) => {
      dispatch(deleteMedicalTeam(id))
      setShow(false)
    }

    const handleUpdate = (id) => {
      let data = new FormData();
      data.append('approved', true);     
      dispatch(updateMedicalTeam(data, id))
    }

    const showModel = () => {
      let teamId = (selectValue.split('/'))[1]
      return(
      <Modal
          style={{ direction: "rtl" }}
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
          <Modal.Header style={{backgroundColor: 'var(--main-color)', justifyContent:'center'}}>
              <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h4">
          يرجى تأكيد الحذف
          </Modal.Body>
          <Modal.Footer>
              <Button
                  variant="danger"
                  className="btn-red"
                  onClick={() => setShow(false)}
              >
                  <span className="h4">إلغاء</span>
              </Button>
              <Button
              variant="success"
              className="btn-main px-5"
              onClick={() => handleDelete(teamId)}
              >
              <span className="h4">تأكيد</span>
              </Button>
          </Modal.Footer>
      </Modal>
      )
      
    }

    return (
      <AdminLayout>
        <Row>
          <Col xl={12} md={12}>
            {errorUpdate && <Message varient='danger'>{errorUpdate}</Message> }
            {errorDelete && <Message varient='danger'>{errorDelete}</Message> }
            {loading ? <Loader/> : error ? <Message varient='danger'>{error}</Message> 
            :(
              <Card className='code-table'>
                <Card.Header>
                    <Card.Title as='h5'>قائمة الفنيين والإداريين للمنشأة</Card.Title>
                </Card.Header>
                <Card.Body className='pb-0'>
                  <Table responsive hover>
                    <thead>
                    <tr>
                        <th>الرقم</th>
                        <th>المنشأة</th>      
                        <th>اسم الدكتور</th>
                        <th>التخصص</th>
                        <th>الجنسية</th>
                        <th>حالة الموظف</th>
                        <th>طلب التأمين</th>
                        <th>تاريخ إنتهاء التأمين</th>
                        <th>حالة التأمين</th>
                        <th>الإجراء</th>
                    </tr>
                    </thead>
                    <tbody>
                    {medicalTeam.map((obj) => (
                      <tr key={obj.id}>
                      <td><p className="mb-1">ID-{obj._id}</p></td>
                      <td><p className="mb-1">{obj.facility.client_code}</p></td>
                      <td><p className="mb-1">{obj.ar_applicant_name}</p></td>
                      <td><p className="mb-1">{obj.medical_specialist}</p></td>
                      <td><p className="mb-1">{obj.nationality}</p></td>
                      <td>
                        <Button
                        variant={
                          obj.is_deleted === true ?
                          "danger"
                          : (obj.approved === true
                            ? "success"
                            :  "warning"
                            
                          )
                        }
                        className={
                          obj.is_deleted === true ?
                            "label btn-danger btn-sm f-12 text-white"
                          : (obj.approved === true ?
                            "label btn-success btn-sm f-12 text-white"
                            : "label btn-warning btn-sm f-12 text-white"
                          )
                        }
                      >
                        {obj.is_deleted === true ?
                            "تم حذفه"
                          : (obj.approved === true ?
                            "مفعل"
                            : "غير مفعل"
                          )
                        }
                        </Button>
                      </td>
                      <td>
                        {!obj.is_deleted && obj.is_request_insurrence && (
                          <Button variant={"success"} className={"label btn-success btn-sm f-12 text-white"} >
                          قام بطلب تأمين
                        </Button>
                        )}
                      </td>

                      {!obj.is_deleted && <td><p className="mb-1">{obj.insurance_certificate_expire_date}</p></td>}
                      
                      <td>
                      {!obj.is_deleted && (
                        <Button
                        variant={
                          obj.insurance_status === "A"
                            ? "success"
                            : obj.insurance_status === "N"
                            ? "warning"
                            : "danger"
                        }
                        className={
                          obj.insurance_status === "A"
                            ? "label btn-success btn-sm f-12 text-white"
                            :  obj.insurance_status === "N"
                            ? "label btn-warning btn-sm f-12 text-white"
                            : "label btn-danger btn-sm f-12 text-white"
                        }
                      >
                        {obj.insurance_status === "A"
                            ? "نشط"
                            :  obj.insurance_status === "N"
                            ? "قارب على الأنتهاء"
                            : "أنتهى"
                        
                        }
                        </Button>)}
                      </td>                                                
                      
                      {more && !obj.is_deleted  ? (
                      <td>
                        <Row className="m-2 w-100">
                          <Form.Select as="select"
                            onChange={handleChange}
                            className="text-center px-1 pe-2"
                          >
                            <option>
                              أختر
                            </option>
                            {!obj.approved && <option value={`approve/${obj.id}`}>تفعيل</option>}
                            <option value={`/admin/print-medical-technical-team/${obj.id}`}>طباعة</option>
                            {!obj.is_deleted && <option value={`delete/${obj.id}`}>حذف</option>
                            }                          
                          </Form.Select>
                          {selectValue.includes("/editMedicalOrTechnicalTeam")  ?
                          
                            (
                              <Button onClick={()=> setShow(true)} className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100">
                                تنفيذ   
                              </Button>
                            ) : selectValue.includes("/admin/print-medical-technical-team")  ?
                            (
                              <Link to={selectValue} className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100">
                                تنفيذ
                              </Link>
                            ) : selectValue.includes("delete") ?  (
                              <Button onClick={()=> setShow(true)} className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100">
                                تنفيذ   
                              </Button>
                            ) :  selectValue.includes("approve") ? (
                              <Button onClick={()=> handleUpdate(obj.id)} className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100">
                                تنفيذ   
                              </Button>
                            ) : ('')
                          }                             
                        </Row>
                      </td>                          
                      ) : ("")
                      }
                      
                    </tr>
                    ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            )}
          </Col>   
        </Row>
        <div>{showModel()}</div>
        <Paginate page = {page} pages = {pages} keyword= {keyword} link="/comparingMedicalMistakeWork" />
      </AdminLayout>
    );       
}

export default AllMedicalTeam
