import { 
    TENDER_ADD_REQUEST,
    TENDER_ADD_SUCCESS,
    TENDER_ADD_FAIL,

    TENDER_LIST_REQUEST, 
    TENDER_LIST_SUCCESS, 
    TENDER_LIST_FAIL,

    TENDER_DETAILS_REQUEST,
    TENDER_DETAILS_SUCCESS,
    TENDER_DETAILS_FAIL, 

    TENDER_ADD_OFFER_REQUEST,
    TENDER_ADD_OFFER_SUCCESS,
    TENDER_ADD_OFFER_FAIL,

} from '../constants/tenderConstants'

import axios from 'axios'

export const AddTender = (tenderData) => async (dispatch, getState) => {
    try{
        dispatch({
            type: TENDER_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.post(
            `/services/add_tender/`,tenderData,
            config
        )

        dispatch({
            type: TENDER_ADD_SUCCESS,
            payload: data
        })

        localStorage.setItem('tenderData', JSON.stringify(data))

    }catch(error){
        dispatch({ 
            type: TENDER_ADD_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listTenders = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: TENDER_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.get(`/services/tenders${keyword}`, config)

        dispatch ({
            type: TENDER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({ 
            type: TENDER_LIST_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getTenderDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TENDER_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.get(`/services/tenders/${id}/`, config)

        dispatch ({
            type: TENDER_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({ 
            type: TENDER_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addTendersOffer = (offerData) => async (dispatch, getState) => {
    try{
        dispatch({
            type: TENDER_ADD_OFFER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers : {
                'Content-type': 'application/json',
                Authorization: `Bearer ${ userInfo.access }`
            }
        }

        const { data } = await axios.post(
            `/services/add_tender_offer/`,offerData,
            config
            
        )

        dispatch({
            type: TENDER_ADD_OFFER_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({ 
            type: TENDER_ADD_OFFER_FAIL,
            payload:error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}