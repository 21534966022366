import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./../../../styles/TableNewDesign.css";

const ConfirmationModal = ({
  visibilty, // the value that tells if visible or not.
  title, // self descriptive prop, the title of the Modal.
  body, // self descriptive prop, the body of the Modal.
  twoContrastingButtons = false, // check if there is two buttons to confirm or cancel.
  isLink, // check if the confirmtion buttton is a link.
  action, // if `isLink` is false, it binds the button to action.
  to, // the actual route is `isLink` is true.
  onClosing, // to tell the parent component that the modal is closed.
}) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <Modal
      style={{ direction: "rtl" }}
      show={isVisible}
      onHide={() => {
        setIsVisible(false);
        onClosing();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          backgroundColor: "var(--main-color)",
          justifyContent: "center",
        }}
      >
        <Modal.Title className="text-white fw-bold"> {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="h4">{body}</Modal.Body>
      <Modal.Footer>
        {!twoContrastingButtons ? (
          <Button
            variant="success"
            className="btn-main"
            onClick={() => {
              setIsVisible(false);
              onClosing();
            }}
          >
            <span className="h4">تم</span>
          </Button>
        ) : (
          <>
            <Button
              variant="danger"
              className="btn-red"
              onClick={() => {
                setIsVisible(false);
                onClosing();
              }}
            >
              <span className="h4">الغاء</span>
            </Button>
            {isLink ? (
              <Link className="btn-main" to={to}>
                <span className="h4">تأكيد</span>
              </Link>
            ) : (
              <Button
                className="btn-main"
                variant="success"
                onClick={() => action}
              >
                <span className="h4">تأكيد</span>
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
