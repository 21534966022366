import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import rarrow from "../../imgs/right-arrow.png";
import date from "../../imgs/date.png";
import DatePicker from "react-datepicker";
import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
import Paginate from '../../component/Paginate'
import SearchBox from '../../component/SearchBox'
import {
  Col,
  Row,
  Card,
  InputGroup,
  Form,
  FormControl,
  ToggleButton,
} from "react-bootstrap";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import BestCard from "../../component/new/bestCard";
import "../../styles/tender_list.css";
import searchicon from "../../imgs/search.png";
import menu from "../../imgs/menu.png";
import { Link } from "react-router-dom";
import {listLaboratories} from '../../actions/serviceActions'


function ListLaboratriesContractScreen({ history }) {

    const [radioValue, setRadioValue] = useState('sell')

    const [radioValueN, setRadioValueN] = useState('new')

    const [linkTo, setLinkTo] = useState('OrderDetailsLaboratries')

    const [link, setLink] = useState('')

    const [link2, setLink2] = useState('')

    const dispatch = useDispatch()

    const laboatoriesList = useSelector(state => state.laboatoriesList)
    const {error, loading, laboratories, page, pages} = laboatoriesList
    

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin


    let keyword = history.location.search

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        } else {
          dispatch(listLaboratories(keyword))          
        }     
      }
    
    }, [dispatch, history, userInfo, keyword])

    const submitHandler = (e) => {
        e.preventDefault()

        if(keyword){
            history.push(`/?keyword=${keyword}&page=1`)
        } else {
            history.push(history.push(history.location.pathname))
        }
    }



    return (
      <div className="tender_page">
        <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest = {'/collectServices'}
        style={"threetwo_section"}
      />
        <div className="box_serach">
          <Link to="/addLaboratoriesRequest">
            <button className="add_btn">إضافة عرض</button>
          </Link>
        </div>
        <div className="tender_list" style={{ height: "800px" }}>
          <div className="heading text-center">قائمة العروض</div>
          <div className="scroll" style={{ marginTop: "55px" }}>
            <Row>
              <Col lg="3" className="search_bar_parent">
                <Card className="search_bar text-end">
                  <div className="input_group">
                  
                  <SearchBox link='/ListLabsContracts'/>
                  </div>
                </Card>
              </Col>
              <Col lg="9" className="mt-auto">
                {Object.keys(laboratories).length>0 ?
                (laboratories.map((item) => (
                  <BestCard
                    title={item.report_details}
                    type1= "laboratory"
                    descreption={item.notes}
                    price="حسب المرفقات"
                    bigImage={item.clinic_image}
                    link={`OrderDetailsLaboratries/${item.id}`}
                  />
                ))) : <div className="text-danger"><h2 className='text-center'> ! لايوجد نتائج </h2></div>
                }
              </Col>

            </Row>
            <Paginate page = {page} pages = {pages} keyword= {keyword} link="/ListLabsContracts" />

          </div>
        </div>
      </div>
    )
}

export default ListLaboratriesContractScreen
