import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Button, Row, Col, Table, Form } from "react-bootstrap";
import "../styles/newOrderAdmin.css";
import "../styles/invoiceAllType.css";
import "../styles/certificateAdmin.css";
import "../styles/clientInformation.css";

import QRCode from "react-qr-code";
import Hdate from "./components/print/Hdate";
import H1 from "./components/print/h1";
import Jtable from "./components/print/Jtable";
import Jimage from "./components/print/Jimage";

import axios from "axios";

import { getFacilityDetails } from "../actions/facilityActions";

function FacilityInformation({ match, history }) {
  let tableData = {
    الاسم: "أحمد",
    العمر: 29,
    الجنس: "ذكر",
    البلد: "السعودية",
    المدينة: "جدة",
  };
  let images = [
    "https://via.placeholder.com/1748x2480",
    "https://via.placeholder.com/1748x2480",
    "https://via.placeholder.com/1748x2480",
    "https://via.placeholder.com/1748x2480",
    "https://via.placeholder.com/1748x2480",
    "https://via.placeholder.com/1748x2480",
  ];

  const teamId = match.params.id;

  const [sector, setSector] = useState("");

  const [sectorType, setSectorType] = useState("");

  const [en_Name, setEn_Name] = useState("");

  const [facilityName, setFacilityName] = useState("");

  const [code, setCode] = useState("");

  const [createdAt, setCreatedAt] = useState("");

  const [licence_No, setLicence_No] = useState("");

  const [photo1, setPhoto1] = useState(false);

  const [photo2, setPhoto2] = useState(false);

  const [photo3, setPhoto3] = useState(false);

  const [start_Date, setStart_Date] = useState("");

  const [end_Date, setEnd_Date] = useState("");

  const [specialization, setSpecialization] = useState("");

  const [birth_Date, setBirth_Date] = useState("");

  const [id_endDate, setId_endDate] = useState("");

  const [phone_No, setPhone_No] = useState("");

  const [gender, setGender] = useState("");

  const [nationality, setNationality] = useState("");

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");

  const [postal_Code, setPostal_Code] = useState("");

  const [region, setRegion] = useState("");

  const [email, setEmail] = useState(null);

  const [emcertificate_Specializationail, setEmcertificate_Specializationail] =
    useState("");

  const [certifi_StartDate, setCertifi_StartDate] = useState("");

  const [certifi_EndDate, setCertifi_EndDate] = useState("");

  const [cover_Period, setCover_Period] = useState("");

  const [cover_Price, setCover_Price] = useState("");

  const [university, setUniversity] = useState("");

  const [organization_Name, setOrganization_Name] = useState("");

  const [status, setStatus] = useState("");

  const [have_Certifi, setHave_Certifi] = useState("");

  const [graduated_Date, setGraduated_Date] = useState("");

  const [Insurance_CertificateEndDate, setInsurance_CertificateEndDate] =
    useState("");

  function handleClick() {
    window.print();
  }

  const dispatch = useDispatch();

  const facilityDetails = useSelector((state) => state.facilityDetails);
  const { facilityInfo } = facilityDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo.user.startsWith("50")) {
      if (facilityInfo.id !== Number(teamId)) {
        dispatch(getFacilityDetails(match.params.id));
      } else {
        setSector(facilityInfo.sector.sector_name);
        setSectorType(facilityInfo.sector_type.type_name);
        /*setFacilityName(medicalTeam.facility.client_code)
        setID(medicalTeam.id_num);
        setCode(medicalTeam._id);
        setCreatedAt(medicalTeam.created_at);
        setLicence_No(medicalTeam.practical_license);
        setPhoto1(medicalTeam.practical_license_image);
        setStart_Date(medicalTeam.license_issue_date.substring(0,10));  
        setEnd_Date(medicalTeam.license_expire_date.substring(0,10));
        setSpecialization(medicalTeam.medical_specialist);
        setPhoto2(medicalTeam.applicant_image);
        setBirth_Date(medicalTeam.birth_date.substring(0,10));
        setId_endDate(medicalTeam.id_expire_date.substring(0,10));
        setPhone_No(medicalTeam.phone);
        setGender(medicalTeam.gender);
        setNationality(medicalTeam.nationality);
        setAddress(medicalTeam.address);
        setCity(medicalTeam.city);
        setPostal_Code(medicalTeam.postal_code);
        setRegion(medicalTeam.state);
        setEmail(medicalTeam.applicant_email);
        setEmcertificate_Specializationail(medicalTeam.specialize_certificate_image);
        setCertifi_StartDate(medicalTeam.certificate_issue_date.substring(0,10));
        setCertifi_EndDate(medicalTeam.certificate_expire_date.substring(0,10));
        setCover_Period(medicalTeam.coverage_duration);
        setCover_Price(medicalTeam.coverage_price);
        setUniversity(medicalTeam.university_name);
        setOrganization_Name(medicalTeam.employer_name);
        setStatus(medicalTeam.marital_status);
        setGraduated_Date(medicalTeam.graduation_date.substring(0,10));
        setInsurance_CertificateEndDate(medicalTeam.insurance_certificate_expire_date.substring(0,10));
        setPhoto3(medicalTeam.insurance_certificate_image);*/
      }
    } else {
      history.push("/");
    }
  }, [dispatch, history, userInfo, match, facilityInfo]);

  const retrieveDate = (d) => {
    let date = new Date(d);
    return date;
  };

  var fileDownload = require("js-file-download");

  const handlePDFDownload = (filename) => {
    axios
      .post("management/download/", {
        responseType: "blob",
        filename: filename,
      })
      .then((res) => {
        fileDownload(res.data, filename);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div dir="rtl">
      <Button
        onClick={() => handleClick()}
        className="print_hide m-4 button-print_c bg-secondary"
      >
        طباعة
      </Button>
      <Container className="a4 p-4 mb-4">
        <H1
          gray="true"
          size="1"
          className="fw-bold h1_title"
          line_hieght="true"
          title="بيانات التسجيل"
        />
        <h1 className=" h1_name">{facilityInfo.facility_name}</h1>
        <Hdate
          date={""}
          code={facilityInfo.client_code}
          codeTitle="كود العميل:"
        />
        <p className="QR_title">كود التسجيل:</p>
        <QRCode className="QR" value={window.location.href} size="75" />
        <H1
          className="h1_1"
          gray="true"
          size="2"
          title={sector}
          type="القطاع"
        />
        <H1
          className="h1_2"
          gray="true"
          size="2"
          title={sectorType}
          type="نوع القطاع"
        />
        <div className="in_table">
          <Row className="m-4">
            <Jtable title="بيانات المنشأة" array={tableData} />
          </Row>
          <Row className="m-4">
            <Jtable title="بيانات صاحب المنشأة أو المدير" array={tableData} />
          </Row>
          <Row className="m-4">
            <Jtable title="بيانات الموظف المصرح له" array={tableData} />
          </Row>
          <Row>
            <Col className="border checkbox_w mx-4 p-2 col-lg-M">
              <Form.Check
                type="checkbox"
                checked
                label="هل تمت الموافقة على الإقرار"
              />
              <Form.Check type="checkbox" checked label={`هل تم الدفع`} />
            </Col>
          </Row>
          <div className="m-4 p-2 w-100">
            <Jimage
              title="المرفقات"
              array={images}
              map={"https://via.placeholder.com/1748x2480"}
              owner={"https://via.placeholder.com/1748x2480"}
              employee={"https://via.placeholder.com/1748x2480"}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
export default FacilityInformation;
