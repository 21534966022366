import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import complete from "../../imgs/check1.png";
import "../../styles/info-ownr.css";
import "../../styles/tender_clothes.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import check from "../../imgs/check.png";
import {
	Row,
	Col,
	Card,
} from "react-bootstrap";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import menu from "../../imgs/menu.png";
import { Link } from "react-router-dom";

import { AddTender } from '../../actions/tenderActions'
import { useLocation } from "react-router-dom";
import axios from 'axios';


function TenderClothesScreen({ history }) {

	const search = useLocation().search;
	const sector = new URLSearchParams(search).get('sector');

	const [photo1, setPhoto1] = useState(false)

	const [path1, setPath1] = useState('ارفق صورة الشعار')

	const [photo2, setPhoto2] = useState(false)

	const [path2, setPath2] = useState('ارفق   صورة')

	const [wrong1, setWrong1] = useState('')

	const [wrong2, setWrong2] = useState('')

	const [title, setTitle] = useState("")

	const [details, setDetails] = useState('')

	const [quantity, setQuantity] = useState('')

	const [period, setPeriod] = useState('')

	const [deliveryTerm, setDeliveryTerm] = useState('')

	const [offerType, setOfferType] = useState('')

	const [size, setSize] = useState("")

	const [gender, setGender] = useState('')

	const [color, setColor] = useState('#3995E6')

	const [checkBox, setCheckBox] = useState(false)

	const [choose, setChoose] = useState(false)

	const [errors, setErrors] = useState({
		check_msg: " يرجى الموافقة على الأقرار من فضلك ",
		imgErrors: "يجب عليك تحميل 4 صور للمنتج",
		allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",

	})

	const [errorsFlags, setErrorsFlags] = useState([false, false, false])

	const [isValid, setIsValid] = useState(true)

	const dispatch = useDispatch()

	const tenderAdd
		= useSelector(state => state.tenderAdd)

	const { error, tenderData, success } = tenderAdd

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			if (!userInfo.is_activated) {
				history.push('/services')
			}
		}

	}, [dispatch, history, userInfo])


	const focusHandler = (e) => {
		e.target.parentNode.style.color = "#0fb0a9";
		e.target.style.color = "#0fb0a9";
		e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
		e.target.style.borderColor = "#0fb0a9";
	};

	const focusoutHandler = (e) => {
		e.target.parentNode.style.color = "";
		e.target.style.color = "";
		e.target.style.boxShadow = "";
		e.target.style.borderColor = "";
	};

	const getExtension = (filename) => {
		var parts = filename.split(".");
		return parts[parts.length - 1];
	};

	const onUpload = (e) => {
		if (e.target.value) {
			const ext = getExtension(e.target.value);
			if (ext === "png" || ext === "jpg" || ext === "pdf") {
				setPhoto1(e.target.value)
				setPath1(e.target.files[0])
				setWrong1(false)
			} else {
				setPhoto1(false)
				setWrong1(true)
			}
		}
	};

	const onUploadN = (e) => {
		if (e.target.value) {
			const ext = getExtension(e.target.value);
			if (ext === "png" || ext === "jpg" || ext === "pdf") {
				setPhoto2(e.target.value)
				setPath2(e.target.files[0])
				setWrong2(false)
			} else {
				setPhoto2(false)
				setWrong2(true)
			}
		}
	};

	const onChooseSize = (e) => {
		const nodes = e.target.parentNode.parentNode.querySelectorAll(".size_item");
		nodes.forEach((element) => {
			element.classList.remove("active");
		});

		e.target.classList.add("active");

		setSize(e.target.getAttribute("data-size"))
	};

	const radioHandler = (e) => {
		setChoose(e.target.value)
	};

	const onChooseGender = (e) => {
		const nodes =
			e.target.parentNode.parentNode.querySelectorAll(".gender_item");
		nodes.forEach((element) => {
			element.classList.remove("active");
		});

		e.target.classList.add("active");

		setGender(e.target.getAttribute("data-gender"))
	};

	const onChooseColor = (e) => {
		const nodes =
			e.target.parentNode.parentNode.querySelectorAll(".color_item");
		nodes.forEach((element) => {
			element.classList.remove("activecolor");
		});

		e.target.classList.add("activecolor");

		setColor(e.target.getAttribute("data-color"))
	};


	const checkStyle = checkBox ? { display: "block" } : {};

	const formValidation = () => {
		let flags = [false, false, false]
		let valid = true
		const nodes = document.querySelectorAll("input");
		nodes.forEach((e) => {
			if (e.value === '') {
				flags[2] = true;
				valid = false;
				setErrorsFlags(flags);
				console.log("hello")
				return valid;
			}
		});

		/* if (valid === true) {
		   if (!checkBox) {
			 console.log("hi")
			 flags[0] = true;
			 valid = false;
			 setErrorsFlags(flags);
			 return valid;
		   }
		   else {
			 flags[0] = false;
			 valid = true;
			 return valid;
		   }
 
		 } */

		return valid

	};

	const sendData = (e) => {
		e.preventDefault()
		const formvalid = formValidation();
		console.log(formvalid)
		console.log(errorsFlags)
		if (formvalid) {

			let data = new FormData();
			data.append("title", title);
			data.append("size", size);
			data.append("gender", gender);
			data.append("color", color);
			data.append("details", details);
			data.append("image", path1);
			data.append("image1", path2);
			data.append("quantity", quantity);
			data.append("delivery_term", deliveryTerm);
			data.append("period", period);
			data.append("offer_type", offerType);
			data.append("service", 14);

			const config = {
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${userInfo.access}`
				}
			}

			axios.post(
				`/services/add_tender/`, data,
				config
			).then((res) => {
				let tenderPk = res.data['pk'];
				if (choose != 'نعم')
					history.push('/confirmAddRequestForServices');
				else
					history.push({
						pathname: '/tshirtLogo',
						state: {
							pk: tenderPk
						}
					});
			}).catch(() => {
				alert('خطأ');
			})



		}
	}

	return (
		<div className="tender_clothes">
			<ControlPanelHeader
				exit1={true}
				notifi={true}
				msg={true}
				files={false}
				stat={false}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				exitDest={"/services"}
				fileDest={"/FacilityFiles"}
				statDest={'/collectServices'}
				style={"threetwo_section"}
			/>

			<div className="tender_clothes_content" style={{ height: "840px" }}>
				<p className="title-info text-center">إضافة طلب مناقصة</p>

				<div className="input-group">
					<div className="right-data">
						<div className="input-lable">
							<label htmlFor="title">العنوان</label>
							<input
								type="text"
								id="title"
								name="title"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								placeholder="اكتب العنوان"
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>

						<div className="size">
							<div
								onClick={onChooseSize}
								style={{ marginLeft: "160px" }}
								className="size_item"
								data-size="XXL"
							>
								XXL
							</div>
							<div
								onClick={onChooseSize}
								className="size_item"
								data-size="XL"
							>
								XL
							</div>
							<div
								onClick={onChooseSize}
								className="size_item"
								data-size="L"
							>
								L
							</div>
							<div
								onClick={onChooseSize}
								className="size_item"
								data-size="M"
							>
								M
							</div>
							<div
								onClick={onChooseSize}
								style={{ marginRight: "68px" }}
								className="size_item"
								data-size="S"
							>
								S
							</div>
							<div className="title">المقاس</div>
						</div>

						<div className="gender">
							<div
								onClick={onChooseGender}
								style={{ marginLeft: "370px" }}
								className="gender_item"
								data-gender="أنثى"
							>
								أنثى
							</div>
							<div
								onClick={onChooseGender}
								style={{ marginRight: "68px" }}
								className="gender_item"
								data-gender="ذكر"
							>
								ذكر
							</div>

							<div className="title">الجنس</div>
						</div>
						<Row>
							<Col lg="6">
								<div className="input-lable form-label narrow">
									<label htmlFor="color">اللون</label>
									<input
										type="color"
										id="color"
										name="color"
										value={color}
										className="form-control form-control-color color_item"
										onChange={(e) => setColor(e.target.value)}
										placeholder="اختر اللون"
										onFocus={focusHandler}
										onBlur={focusoutHandler}
									/>
								</div>
							</Col>
							<Col lg="6">
								<div className="input-lable narrow">
									<label htmlFor="color2">
										إذا كان لديك كود اللون الرجاء إدخاله
									</label>
									<input
										type="text"
										id="color2"
										name="color"
										value={color}
										onChange={(e) => setColor(e.target.value)}
										placeholder="اكتب كود اللون"
										onFocus={focusHandler}
										onBlur={focusoutHandler}
									/>
								</div>
							</Col>
						</Row>
						<div className="input-lable" style={{ height: "325px" }}>
							<label htmlFor="order_details">تفاصيل العرض</label>
							<textarea
								rows="4"
								cols="50"
								type="text"
								id="order_details"
								name="order_details"
								value={details}
								onChange={(e) => setDetails(e.target.value)}
								placeholder="اكتب تفاصيل العرض"
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>
						{errorsFlags[2] && (
							<div className="error">
								<img src={error} />
								{errors.allFieldRequired}
							</div>
						)}
					</div>
					<div className="left-data">
						<div className="input-content">
							<p>
								رفع الشعار{" "}
								<span className="text-muted">
									(الرجاء رفع الشعار الخاص بكم لتتمكن من تحديد مكانه على
									المنتج النهائي)
								</span>{" "}
							</p>
							<label for="upload-photo1">
								{" "}
								<div className="input">
									<img src={upload} alt="" />
									<div>{photo1}</div>
								</div>{" "}
							</label>
							<input
								onChange={onUpload}
								type="file"
								name="photo1"
								id="upload-photo1"
								data-path="path1"
								data-wrong="wrong1"
							/>
							{photo1 && (
								<img
									className="complete"
									src={complete}
									alt=""
									style={{ left: "-50px", right: "unset" }}
								/>
							)}
							{wrong1 && (
								<AiOutlineCloseCircle
									style={{ left: "-50px", right: "unset" }}
								/>
							)}
						</div>
						<div className="input-content">
							<p> رفع صور (إذا لديك نموذج جاهز) </p>
							<label for="upload-photo2">
								{" "}
								<div className="input">
									<img src={upload} alt="" />
									<div>{photo2}</div>
								</div>{" "}
							</label>
							<input
								onChange={onUploadN}
								type="file"
								name="photo2"
								id="upload-photo2"
								data-path="path2"
								data-wrong="wrong2"
							/>
							{photo2 && (
								<img
									className="complete"
									src={complete}
									alt=""
									style={{ left: "-50px", right: "unset" }}
								/>
							)}
							{wrong2 && (
								<AiOutlineCloseCircle
									style={{ left: "-50px", right: "unset" }}
								/>
							)}
						</div>
						<div className="input-lable">
							<label htmlFor="quantity"> تحديد الكمية المطلوبة </label>
							<input
								type="number"
								id="quantity"
								name="quantity"
								value={quantity}
								onChange={(e) => setQuantity(e.target.value)}
								placeholder="  تحديد الكمية المطلوبة "
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>
						<div className="input-lable">
							<label htmlFor="name"> تحديد وقت تسليم </label>
							<input
								type="text"
								id="deliveryTerm"
								name="deliveryTerm"
								value={deliveryTerm}
								onChange={(e) => setDeliveryTerm(e.target.value)}
								placeholder=" تحديد وقت تسليم الطلب"
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							/>
						</div>
						<div className="input-lable">
							<label htmlFor="date2"> مدة العرض</label>
							<select value={period}
								type="text"
								id="period"
								name="period"
								onChange={(e) => setPeriod(e.target.value)}
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							>
								<option hidden disabled selected>
									أختار
								</option>
								<option value="7"> 7 أيام</option>
								<option value="14"> 14 يوم</option>
								<option value="21"> 21 يوم</option>
								<option value="30"> 30 يوم</option>
							</select>
						</div>
						<div className="input-lable">
							<label htmlFor="offerType">نوع اللباس</label>
							<select
								value={offerType}
								type="text"
								id="offerType"
								name="offerType"
								onChange={(e) => setOfferType(e.target.value)}
								placeholder="مدة العرض"
								onFocus={focusHandler}
								onBlur={focusoutHandler}
							>
								<option value="لباس طبيب">لباس طبيب</option>
								<option value="لباس ممرض">لباس ممرض </option>
								<option value="لباس جراح">لباس جراح</option>
								<option value="لباس النظافة">لباس النظافة</option>
							</select>
						</div>
						<div className="radio_input">
							<p>هل تريد تحديد مكان الشعار </p>
							<div className="radio_group" style={{ width: "181px" }}>
								<label>
									<input
										type="radio"
										name="choose"
										value="نعم"
										checked={choose === "نعم"}
										onChange={radioHandler}
									/>
									نعم
								</label>
								<label>
									<input
										type="radio"
										value="لا"
										name="choose"
										checked={choose === "لا"}
										onChange={radioHandler}
									/>
									لا
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>

			<button className="enter_service" style={{ width: "200px" }} onClick={sendData}>
				<img className="icon" src={arrow} alt="" />
				{choose == 'نعم' ? "تحديد مكان الشعار" : 'اضف العرض'}
			</button>

		</div>
	);
}

export default TenderClothesScreen
