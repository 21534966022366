import { HashRouter as Router, Route } from "react-router-dom";
/******************************Admin Dashboard*****************************/
import InvoiceAllType from "./invoiceAllType/invoiceAllType";
import AdminNewBillsCleaning from "./AdminNewBillsCleaning";
import AdminNewBillsFood from "./AdminNewBillsFood";
import AdminNewBillsJob from "./AdminNewBillsJob";
import AdminNewBillsLabs from "./AdminNewBillsLabs";
import AdminNewBillsLaundry from "./AdminNewBillsLaundry";
import AdminNewBillsLibrary from "./AdminNewBillsLibrary";
import AdminNewBillsMedicalDevices from "./AdminNewBillsMedicalDevices";
import AdminNewBillsOffice from "./AdminNewBillsOffice";
import AdminNewBillsOperating from "./AdminNewBillsOperating";
import AdminNewBillsRateNetwrok from "./AdminNewBillsRateNetwrok";
import AdminNewBillsRays from "./AdminNewBillsRays";
import AdminNewBillsTransportation from "./AdminNewBillsTransportation";
import AdminNewBillsUniform from "./AdminNewBillsUniform";
import AdminNewBillsComparigPrices from "./AdminNewBillsComparigPrices";
import AdminNewBillsCollecting from "./AdminNewBillsCollecting";
import AdminNewBillsMedicalRent from "./AdminNewMedicalRent";
import Dashboard from "./Dashboard";
import BayanEmployeesPermessions from "./BayanEmployeesPermessions";
import MedicalTeamInsurrenceRequest from "./MedicalTeamInsurrenceRequest";
import MedicalTeamInformation from "./MedicalTeamInformation";
import AllFacility from "./AllFacility";
import FacilityInformation from "./FacilityInformation";
import MedicalNetworkAssessment from "./StatMedicalNetworkAssessment";
import Rentalmedicaldevices from "./StatRentalmedicaldevices";
import RentingDoctorsClinics from "./StatRentingDoctorsClinics";
import NewBillsAdmin from "./AdminNewBills";
import OldBillsAdmin from "./AdminOldBills";
import ComoarisonMedicalInsurance from "./StatComparisonMedicalInsurance";
import Achievement from "./StatAchievement";
import OperatingLaboratoryContracts from "./StatOperatingLaboratoryContracts";
import OperatingRadiologyContracts from "./StatOrganizingRadiologyContracts";
import OrganizingSupplyTenders from "./StatOrganizingSupplyTenders";
import AddEmployee from "./AdminAddEmployee";
import AdminChangePices from "./AdminPowerChangeInsidePrice";
import AdminChangeBankData from "./AdminPowerChangeBankData";
import AdminChangeEndorsement from "./AdminPowerChangeEndorsement";
import AdminChangeEnteredData from "./AdminPowerChangeEnteredData";
import AdminChangeSuspendAccount from "./AdminPowerSuspenAcount";
import AdminChangeInsideVideo from "./AdminPowerChangeInsideVedio";
import AdminStopService from "./AdminPowerStopSecrvice";
import AdminAddService from "./AdminPowerAddService";
import AdminAddNewSection from "./AdminAddNewSection";
import AdminSuspendSection from "./AdminPowerSuspendSection";
import AdminChangeTargets from "./AdminPowerChangeTargets";
import AdminClientManualData from "./AdminPowerClientManualData";
import HealthFacilies from "./HealthFacilties";
import FoodFacilities from "./FoodFacilities";
import LibraryFacilities from "./LibraruFacilities";
import CleaningFacilities from "./CleaningFacilities";
import OfficeFacilities from "./OfficeFacilities";
import TransportaionFacilities from "./TransportationFacilities";
import LaundaryFacilities from "./LaundaryFacilities";
import UniformFacilities from "./UniformFacilities";
import OpeartingFacilities from "./OpeartingFacilities";
import JobsFacilities from "./JobsFacilities";
import AdminPowerSpecialCases from "./AdminPowerSpecialCases";
import AdminPowerAddMultiSectors from "./AdminPowerAddMultiSectors";
import Messages from "./Messages";
import Notifications from "./Notifications";
import AllMedicalTeam from "./AllMedicalTeam";
import AdminEditEmployee from "./AdminEditEmployee";
import AdminManagersPage from "./AdminMangers";
import RateMedicalNetworks from "./RateMedicalNetworks";

import OffersCleaning from "./offers/tenders/OffersCleaning";
import OffersFood from "./offers/tenders/OffersFood";
import OffersJobs from "./offers/tenders/OffersJobs";
import OffersLaundry from "./offers/tenders/OffersLaundry";
import OffersLibrary from "./offers/tenders/OffersLibrary";
import OffersOffice from "./offers/tenders/OffersOffice";
import OffersOperating from "./offers/tenders/OffersOperating";
import OffersTransportation from "./offers/tenders/OffersTransportation";
import OffersUniform from "./offers/tenders/OffersUniform";

import OffersCollecting from "./offers/OffersCollecting";
import OffersComparingPrices from "./offers/OffersComparingPrices";
import OffersLabs from "./offers/OffersLabs";
import OffersRateNetworks from "./offers/OffersRateNetworks";
import OffersRay from "./offers/OffersRay";
import OffersRentingMedical from "./offers/OffersRentingMedical";
import OffersSellingMedicalDevices from "./offers/OffersSellingMedicalDevices";

const Routes = () => (
  <Router>
    {/* *****************Admin Dashboard ******************* */}
    <Route exact path="/employee" component={Dashboard} />
    <Route
      exact
      path="/employee/bayan-employees-permessions"
      component={BayanEmployeesPermessions}
    />
    <Route exact path="/employee/all-medical-team" component={AllMedicalTeam} />
    <Route
      exact
      path="/employee/medical-team-insurrence-request"
      component={MedicalTeamInsurrenceRequest} // done by morad (طلبات التأمين)
    />
    <Route
      exact
      path="/employee/print-medical-technical-team/:id" // done by morad (الطاقم الفني والإداري)
      component={MedicalTeamInformation}
    />
    <Route
      exact
      path="/employee/print-facility/:id"
      component={FacilityInformation}
    />
    <Route
      exact
      path="/employee/medical-network-assessment"
      component={MedicalNetworkAssessment}
    />
    <Route
      exact
      path="/employee/rental-medical-devices"
      component={Rentalmedicaldevices}
    />
    <Route
      exact
      path="/employee/renting-doctors-clinics"
      component={RentingDoctorsClinics}
    />
    <Route
      exact
      path="/employee/comoarison-medical-insurance"
      component={ComoarisonMedicalInsurance}
    />
    <Route exact path="/employee/achievement" component={Achievement} />
    <Route
      exact
      path="/employee/operating-laboratory-contracts"
      component={OperatingLaboratoryContracts}
    />
    <Route
      exact
      path="/employee/operating-radiology-contracts"
      component={OperatingRadiologyContracts}
    />
    <Route
      exact
      path="/employee/organizing-supply-tenders"
      component={OrganizingSupplyTenders}
    />
    <Route exact path="/employee/new-bills-admin" component={NewBillsAdmin} />
    <Route exact path="/employee/old-bills-admin" component={OldBillsAdmin} />
    <Route exact path="/employee/add-employee" component={AddEmployee} />
    <Route exact path="/employee/managers" component={AdminManagersPage} />
    <Route
      exact
      path="/employee/rate-medical-networks"
      component={RateMedicalNetworks}
    />

    {/* ***************** Offers of the admin Page ****************** */}
    <Route
      exact
      path="/employee/offers/tenders/cleaning"
      component={OffersCleaning}
    />
    <Route exact path="/employee/offers/tenders/food" component={OffersFood} />
    <Route exact path="/employee/offer/tenders/jobs" component={OffersJobs} />
    <Route
      exact
      path="/employee/offer/tenders/laundry"
      component={OffersLaundry}
    />
    <Route
      exact
      path="/employee/offer/tenders/library"
      component={OffersLibrary}
    />
    <Route
      exact
      path="/employee/offer/tenders/office"
      component={OffersOffice}
    />
    <Route
      exact
      path="/employee/offer/tenders/operating"
      component={OffersOperating}
    />
    <Route
      exact
      path="/employee/offer/tenders/transportation"
      component={OffersTransportation}
    />
    <Route
      exact
      path="/employee/offer/tenders/uniform"
      component={OffersUniform}
    />
    <Route
      exact
      path="/employee/offer/collecting"
      component={OffersCollecting}
    />
    <Route
      exact
      path="/employee/offer/comparing-prices"
      component={OffersComparingPrices}
    />
    <Route exact path="/employee/offer/labs" component={OffersLabs} />
    <Route
      exact
      path="/employee/offer/rate-networks"
      component={OffersRateNetworks}
    />
    <Route exact path="/employee/offer/rays" component={OffersRay} />
    <Route
      exact
      path="/employee/offer/renting-medical"
      component={OffersRentingMedical}
    />
    <Route
      exact
      path="/employee/offer/selling-medical-devices"
      component={OffersSellingMedicalDevices}
    />
    <Route exact path="/employee/messages" component={Messages} />
    <Route exact path="/employee/notifications" component={Notifications} />

    <Route
      exact
      path="/employee/edit-employee/:id"
      component={AdminEditEmployee}
    />

    <Route
      exact
      path="/employee/new-bills/comparing-prices"
      component={AdminNewBillsComparigPrices}
    />
    <Route
      exact
      path="/employee/new-bills/labs"
      component={AdminNewBillsLabs}
    />
    <Route
      exact
      path="/employee/new-bills/medical-devices"
      component={AdminNewBillsMedicalDevices}
    />
    <Route
      exact
      path="/employee/new-bills/rate-network"
      component={AdminNewBillsRateNetwrok}
    />
    <Route
      exact
      path="/employee/new-bills/rays"
      component={AdminNewBillsRays}
    />
    <Route
      exact
      path="/employee/new-bills/collecting"
      component={AdminNewBillsCollecting}
    />
    <Route
      exact
      path="/employee/new-bills/medical-rent"
      component={AdminNewBillsMedicalRent}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/food"
      component={AdminNewBillsFood}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/cleaning"
      component={AdminNewBillsCleaning}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/job"
      component={AdminNewBillsJob}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/laundry"
      component={AdminNewBillsLaundry}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/library"
      component={AdminNewBillsLibrary}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/office"
      component={AdminNewBillsOffice}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/operating"
      component={AdminNewBillsOperating}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/transportation"
      component={AdminNewBillsTransportation}
    />
    <Route
      exact
      path="/employee/new-bills/tenders/uniform"
      component={AdminNewBillsUniform}
    />
  </Router>
);

export default Routes;
