import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css";
import Paginate from '../../component/Paginate'
import SearchBox from '../../component/SearchBox'
import ControlPanelHeader from "../../component/ControlPanelHeader";
import { Table, Button, Row, Badge, OverlayTrigger, Col } from "react-bootstrap";
import "../../styles/TableNewDesign.css";
import Tooltip from "react-bootstrap/Tooltip";
import Loader from '../../component/Loader'
import Message from '../../component/Message'

import "../../styles/Medical_Technical_TeamList.css";
import "../../styles/MedicalNetwork_list.css";

import "tippy.js/dist/tippy.css";
import { listfacilities } from '../../actions/facilityActions'

function MedicalNetworkListScreen({ history }) {

	const [selectValue, setSelectValue] = useState("");
	const [show, setShow] = useState(false)

	const dispatch = useDispatch()

	const facilityList = useSelector(state => state.facilityList)
	const { error, loading, facilities, page, pages } = facilityList

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	let keyword = history.location.search

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		} else {
			if (!userInfo.is_activated) {
				history.push('/ServiceInsuranceCompany')
			} else {
				dispatch(listfacilities(keyword))
			}
		}

	}, [dispatch, history, userInfo, keyword])

	let titles = [
		"كود الجهة الصحية",
		"إسم مقدم الخدمة الصحية",
		"المنطقة",
		"المدينة",
		"الهاتف",
		"الايميل",
		"تقيم المشآة",
		"التصنيف على قائمة الشبكة",
	]

	return (
		<div className="list_page">
			<ControlPanelHeader
				exit1={true}
				notifi={true}
				msg={true}
				files={false}
				stat={false}
				msgDest={"/Messages"}
				notifidest={"/Notifications"}
				exitDest={"/services"}
				fileDest={"/FacilityFiles"}
				statDest={'/collectServices'}
				style={"threetwo_section"}
			/>

			<div className="box_serach px-4">
				<div className="actions1">
					<div>
						<OverlayTrigger
							key={1}
							placement={"top"}
							overlay={<Tooltip id={1}>طباعة</Tooltip>}
						>
							<svg
								id="printer"
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 13.475 13.122"


							>
								<path
									id="Path_2674"
									data-name="Path 2674"
									d="M12.524,12.665H11.015v-2.6a.419.419,0,0,0-.407-.43H2.867a.419.419,0,0,0-.407.43v2.6H.951a.979.979,0,0,0-.951,1v5.519a.979.979,0,0,0,.951,1H2.46v2.134a.419.419,0,0,0,.407.43h7.74a.419.419,0,0,0,.407-.43V20.191h1.509a.979.979,0,0,0,.951-1V13.669A.979.979,0,0,0,12.524,12.665ZM3.275,10.493H10.2v2.172H3.275Zm6.925,11.4H3.275V17.627H10.2Zm2.46-2.707a.142.142,0,0,1-.136.143H11.015V17.2a.419.419,0,0,0-.407-.43H2.867a.419.419,0,0,0-.407.43v2.134H.951a.142.142,0,0,1-.136-.143V13.669a.142.142,0,0,1,.136-.143H12.524a.142.142,0,0,1,.136.143v5.519Z"
									transform="translate(0 -9.633)"
									fill="#0fb0a9"
								/>
								<ellipse
									id="Ellipse_11"
									data-name="Ellipse 11"
									cx="0.579"
									cy="0.612"
									rx="0.579"
									ry="0.612"
									transform="translate(10.028 4.901)"
									fill="#0fb0a9"
								/>
								<path
									id="Path_2675"
									data-name="Path 2675"
									d="M86.521,163.822H82.814a.431.431,0,0,0,0,.86h3.707a.431.431,0,0,0,0-.86Z"
									transform="translate(-77.931 -154.979)"
									fill="#0fb0a9"
								/>
								<path
									id="Path_2676"
									data-name="Path 2676"
									d="M86.521,193.623H82.814a.431.431,0,0,0,0,.86h3.707a.431.431,0,0,0,0-.86Z"
									transform="translate(-77.931 -183.07)"
									fill="#0fb0a9"
								/>
							</svg>
						</OverlayTrigger>
					</div>

					<div>
						<OverlayTrigger
							key={2}
							placement={"top"}
							overlay={<Tooltip id={2}>تصدير إلى ملف أكسل</Tooltip>}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 13.601 15.012"
							>
								<g id="xls" transform="translate(-24.049 0)">
									<path
										id="Path_2775"
										data-name="Path 2775"
										d="M37.448,2.277,35.373.2a.686.686,0,0,0-.488-.2H26.149a.691.691,0,0,0-.69.69V7.521h-.485a.926.926,0,0,0-.925.925v4.231a.926.926,0,0,0,.925.925H32.26a.691.691,0,0,0,.69-.69V9.621a.691.691,0,0,0-.69-.69H24.974a.485.485,0,0,1,0-.97h.485v.25a.22.22,0,0,0,.44,0V.69a.25.25,0,0,1,.25-.25h8.476V2.57a.455.455,0,0,0,.455.455h2.13v11.3a.25.25,0,0,1-.25.25H26.149a.25.25,0,0,1-.25-.25.22.22,0,0,0-.44,0,.691.691,0,0,0,.69.69H36.961a.691.691,0,0,0,.69-.69V2.765A.685.685,0,0,0,37.448,2.277ZM24.974,9.371H32.26a.25.25,0,0,1,.25.25v3.29a.25.25,0,0,1-.25.25H24.974a.486.486,0,0,1-.485-.485V9.233A.919.919,0,0,0,24.974,9.371Zm10.091-6.8V.516l2.07,2.07H35.08A.015.015,0,0,1,35.065,2.57Z"
										fill="#1bb1aa"
									/>
									<path
										id="Path_2776"
										data-name="Path 2776"
										d="M161.787,346.359a1.8,1.8,0,0,0,.239,0l.465,0a.22.22,0,1,0,0-.44l-.444,0v-1.68a.22.22,0,1,0-.44,0v1.9A.22.22,0,0,0,161.787,346.359Z"
										transform="translate(-133.522 -333.937)"
										fill="#1bb1aa"
									/>
									<path
										id="Path_2777"
										data-name="Path 2777"
										d="M214.734,346.365a.766.766,0,0,0,.782-.593.7.7,0,0,0-.541-.768c-.236-.087-.456-.182-.52-.21a.149.149,0,0,1-.055-.141.208.208,0,0,1,.163-.167.65.65,0,0,1,.539.124.22.22,0,0,0,.282-.338,1.088,1.088,0,0,0-.948-.208.651.651,0,0,0-.472.525.586.586,0,0,0,.262.581.215.215,0,0,0,.031.017c.011,0,.272.121.566.23.087.032.288.124.26.278a.338.338,0,0,1-.349.23.679.679,0,0,1-.5-.209.22.22,0,1,0-.326.3A1.125,1.125,0,0,0,214.734,346.365Z"
										transform="translate(-184.238 -333.938)"
										fill="#1bb1aa"
									/>
									<path
										id="Path_2778"
										data-name="Path 2778"
										d="M80.092,346.324a.22.22,0,0,0,.306-.053l.537-.762.536.762a.22.22,0,1,0,.36-.253l-.627-.891.533-.756a.22.22,0,1,0-.36-.253l-.442.627-.441-.627a.22.22,0,0,0-.36.253l.532.756-.628.891A.22.22,0,0,0,80.092,346.324Z"
										transform="translate(-54.309 -333.938)"
										fill="#1bb1aa"
									/>
									<path
										id="Path_2779"
										data-name="Path 2779"
										d="M182.436,128.712a.455.455,0,0,0-.455-.455H176.81a.455.455,0,0,0-.455.455v3.055a.455.455,0,0,0,.455.455h5.171a.455.455,0,0,0,.455-.455v-3.055Zm-3.76,1.9v-.735h1.44v.735Zm1.44.44v.735h-1.44v-.735Zm-3.321-1.175h1.44v.735h-1.44Zm1.88-.44V128.7h1.44v.735Zm1.88.44H182v.735h-1.44Zm1.44-1.16v.72h-1.44V128.7h1.425A.015.015,0,0,1,182,128.712Zm-5.186-.015h1.425v.735h-1.44v-.72A.015.015,0,0,1,176.81,128.7Zm-.015,3.071v-.72h1.44v.735H176.81A.015.015,0,0,1,176.795,131.767Zm5.186.015h-1.425v-.735H182v.72A.015.015,0,0,1,181.981,131.783Z"
										transform="translate(-147.84 -124.497)"
										fill="#1bb1aa"
									/>
								</g>
							</svg>
						</OverlayTrigger>
					</div>

					<div>
						<OverlayTrigger
							key={3}
							placement={"top"}
							overlay={
								<Tooltip id={3}>إرسال عبر البريد الألكتروني</Tooltip>
							}
						>
							<svg
								id="mail"
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 15.848 11.803"
							>
								<g id="Group_282" data-name="Group 282">
									<path
										id="Path_2338"
										data-name="Path 2338"
										d="M15.035,85.333H.813A.867.867,0,0,0,0,86.241v9.987a.867.867,0,0,0,.813.908H15.035a.867.867,0,0,0,.813-.908V86.241A.867.867,0,0,0,15.035,85.333Zm-.3.681L8.391,91.325a.776.776,0,0,1-.935,0L1.118,86.014Zm-3.386,5.662L14.8,96.443l.011.013H1.038l.011-.013L4.5,91.676a.368.368,0,0,0-.045-.479.283.283,0,0,0-.429.05L.61,95.967V86.44L7.091,91.87a1.331,1.331,0,0,0,1.666,0l6.481-5.431v9.528l-3.42-4.72a.283.283,0,0,0-.429-.05A.368.368,0,0,0,11.344,91.676Z"
										transform="translate(0 -85.333)"
										fill="#1bb1aa"
									/>
								</g>
							</svg>
						</OverlayTrigger>
					</div>
				</div>
				<Col className="badges">
					<Row className="badge_text">فئات تقييم المنشأة</Row>
					<Row className="badge_col">
						<Col lg="2">
							<Badge className="px-3 py-2 fs-6" bg="warning">
								VIP
							</Badge>{" "}
						</Col>
						<Col lg="2">
							<Badge className="px-4 py-2 fs-6" bg="success">
								A
							</Badge>
						</Col>
						<Col lg="2">
							<Badge className="px-4 py-2 fs-6" bg="primary">
								B
							</Badge>
						</Col>
						<Col lg="2">
							<Badge className="px-4 py-2 fs-6" bg="secondary">
								C
							</Badge>
						</Col>
						<Col lg="2">
							<Badge className="px-4 py-2 fs-6" bg="danger">
								D
							</Badge>
						</Col>
					</Row>
				</Col>
				<div className="input_group">
					<SearchBox link='/comparingMedicalMistakeWork' />
				</div>
			</div>
			<div className="bills_list">
				<div className="heading text-center">قائمة الشبكات الصحيه</div>
				<div className="bills_table scroll">
					{loading
						? (<Loader />)
						: error
							? (<Message variant='danger'>{error}</Message>)
							: (
								<Table bordered hover className="TableNewDesign">
									<thead>
										<tr>
											{titles.map((title, x) => (
												<th key={x + 1}>{title}</th>
											))
											}
										</tr>
									</thead>
									<tbody>
										{
											facilities.map((obj) => (
												<tr key={obj.id}>
													<td>{obj.client_code}</td>
													<td>{obj.facility_name}</td>
													<td>{obj.state}</td>
													<td>{obj.location}</td>
													<td>{obj.financial_manager_phone_num}</td>
													<td>{obj.user.email}</td>
													<td><strong>
														{obj.facility_level.level === "VIP" ?
															(
																<Badge className="px-3 py-2 fs-6" bg="warning">
																	VIP
																</Badge>
															) : obj.facility_level.level === "A" ?
																(
																	<Badge className="px-3 py-2 fs-6" bg="success">
																		A
																	</Badge>
																) : obj.facility_level.level === "B" ?
																	(
																		<Badge className="px-4 py-2 fs-6" bg="primary">
																			B
																		</Badge>
																	) : obj.facility_level.level === "C" ?
																		(
																			<Badge className="px-4 py-2 fs-6" bg="secondary">
																				C
																			</Badge>
																		) : (
																			<Badge className="px-4 py-2 fs-6" bg="danger">
																				D
																			</Badge>
																		)}
													</strong>
													</td>
													<td><strong>{obj.allowed_level}</strong></td>
													{/*<td>
                            <Button
                              variant={
                                obj.facility_level === "A"
                                  ? "success"
                                  : obj.facility_level === "N"
                                  ? "warning"
                                  : "danger"
                              }
                              className={
                                obj.insurance_status === "A"
                                  ? "btn-main px-5"
                                  :  obj.insurance_status === "N"
                                  ? "btn-y"
                                  : "btn-red px-5"
                              }
                            >
                              {obj.insurance_status === "A"
                                  ? "نشط"
                                  :  obj.insurance_status === "N"
                                  ? "قارب على الأنتهاء"
                                  : "أنتهى"
                              
                              }
                            </Button>
                            </td>*/}
												</tr>
											))
										}
									</tbody>
								</Table>
							)}
				</div>
				<Paginate page={page} pages={pages} keyword={keyword} link="/MedicalNetworks" />

			</div>


		</div>
	);
}

export default MedicalNetworkListScreen
