import React, { useContext, useState } from "react";
import { ListGroup, Dropdown, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userLogout as logoutAction } from "../../../../../actions/userActions";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";

import ChatList from "./ChatList";
import { ConfigContext } from "../../../../../contexts/ConfigContext";
import useAuth from "../../../../../hooks/useAuth";

import avatar1 from "../../../../../assets/images/user/avatar-1.jpg";
import avatar2 from "../../../../../assets/images/user/avatar-2.jpg";
import avatar3 from "../../../../../assets/images/user/avatar-3.jpg";
import avatar4 from "../../../../../assets/images/user/avatar-4.jpg";

const NavRight = () => {
	const configContext = useContext(ConfigContext);
	const { logout } = useAuth();
	const { rtlLayout } = configContext.state;

	const [listOpen, setListOpen] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const handleLogout = async () => {
		try {
			//handleClose();
			logout().then(() => {
				dispatch(logoutAction());
				history.push("/");
			});
		} catch (err) {
			console.error(err);
		}
	};

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	return (
		<React.Fragment>
			<ListGroup
				as="ul"
				bsPrefix=" "
				className="navbar-nav ml-auto"
				id="navbar-right"
			>
				{/* <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout}>
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              id="dropdown-basic"
            >
              <i className="feather icon-bell icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              alignRight
              className="notification notification-scroll"
            >
              <div className="noti-head">
                <h6 className="d-inline-block m-b-0">Notifications</h6>
                <div className="float-right">
                  <Link to="#" className="m-r-10">
                    mark as read
                  </Link>
                  <Link to="#">clear all</Link>
                </div>
              </div>
              <PerfectScrollbar>
                <ListGroup
                  as="ul"
                  bsPrefix=" "
                  variant="flush"
                  className="noti-body"
                >
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">NEW</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Card>
                      <img
                        className="img-radius"
                        src={avatar1}
                        alt="Generic placeholder"
                      />
                      <Card.Body>
                        <p>
                          <strong>John Doe</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>New ticket Added</p>
                      </Card.Body>
                    </Card>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">EARLIER</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Card>
                      <img
                        className="img-radius"
                        src={avatar2}
                        alt="Generic placeholder"
                      />
                      <Card.Body>
                        <p>
                          <strong>Joseph William</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Card.Body>
                    </Card>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Card>
                      <img
                        className="img-radius"
                        src={avatar3}
                        alt="Generic placeholder"
                      />
                      <Card.Body>
                        <p>
                          <strong>Sara Soudein</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>currently login</p>
                      </Card.Body>
                    </Card>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Card>
                      <img
                        className="img-radius"
                        src={avatar4}
                        alt="Generic placeholder"
                      />
                      <Card.Body>
                        <p>
                          <strong>Suzen</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            yesterday
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Card.Body>
                    </Card>
                  </ListGroup.Item>
                </ListGroup>
              </PerfectScrollbar>
              <div className="noti-footer">
                <Link to="#">show all</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item> */}
				<ListGroup.Item as="li" bsPrefix=" ">
					{/* <Dropdown>
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              className="displayChatbox"
              onClick={() => setListOpen(true)}
            >
              <i className="icon feather icon-mail" />
            </Dropdown.Toggle>
          </Dropdown> */}
				</ListGroup.Item>
				<ListGroup.Item as="li" bsPrefix=" ">
					<Dropdown alignRight={!rtlLayout} className="drp-user">
						<Dropdown.Toggle
							as={Link}
							variant="link"
							to="#"
							id="dropdown-basic"
						>
							<i className="icon feather icon-settings" />
						</Dropdown.Toggle>
						<Dropdown.Menu alignRight className="profile-notification">
							<div className="pro-head">
								<span>{userInfo?.email}</span>
							</div>
							<ListGroup
								as="ul"
								bsPrefix=" "
								variant="flush"
								className="pro-body"
							>
								<ListGroup.Item as="li" bsPrefix=" ">
									<Link to="/" className="dropdown-item" onClick={handleLogout}>
										<i className="feather icon-log-out" /> تسجيل خروج
									</Link>
								</ListGroup.Item>
							</ListGroup>
						</Dropdown.Menu>
					</Dropdown>
				</ListGroup.Item>
			</ListGroup>
			{/* <ChatList listOpen={listOpen} closed={() => setListOpen(false)} /> */}
		</React.Fragment>
	);
};

export default NavRight;
