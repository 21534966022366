import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import ChangeTargets from "../component/powers/change_targets";

function AdminChangeTargets({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div
              style={{ width: "300px", textAlign: "center" }}
              className="heading"
            >
              الصلاحيات
            </div>

            <ChangeTargets />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminChangeTargets;
