import React, { Component } from "react";
import "../../styles/change_entereddata.css";
import arrow from "../../imgs/arrow.png";
import search from "../../imgs/search1.png";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";
export class Change_EnteredData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      code: "",
      showInfo: false,
      showModal: false,
    };
  }

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  onClick = () => {
    this.setState({
      showInfo: true,
    });
  };

  submitHandler = () => {
    this.setState({ showModal: true });
  };

  render() {
    const { name, code, showInfo } = this.state;

    const obj = {
      pathname: "/MainChooseEnterprise",
      param1: "change",
    };
    return (
      <div className="change_entereddata">
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">كود العميل</label>
            <input
              type="text"
              id="code"
              name="code"
              value={code}
              onChange={this.changeHandler}
              placeholder="اكتب كود العميل"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
            <img src={search} alt="" />
          </div>
          <div className="input-data">
            <label htmlFor="name">اسم الجهة</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={this.changeHandler}
              placeholder=" ابحث اسم الجهة"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
            <img src={search} alt="" />
          </div>
        </div>

        {showInfo && (
          <div
            className="item reverse"
            style={{
              direction: "rtl",
              justifyContent: "flex-end",
              marginLeft: "-111px",
              marginTop: "66px",
              marginBottom: "253px",
            }}
          >
            <p className="info_title">اسم الجهة :</p>
            <div className="info_data">{name}</div>
            <p className="info_title"> كود العميل :</p>
            <div className="info_data">{code}</div>
          </div>
        )}

        {showInfo && (
          <div className="text-center d-flex justify-content-center">
            <Button
              className="text-center btnNext"
              onClick={this.submitHandler}
            >
              <img src={arrow} alt="" />
              تنفيذ
            </Button>
          </div>
        )}

        {showInfo === false && (
          <div className="text-center d-flex justify-content-center">
            <Button className="text-center btnNext" onClick={this.onClick}>
              <img src={arrow} alt="" />
              التالي
            </Button>
          </div>
        )}
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Change_EnteredData;
