import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import {
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Modal,
  Dropdown,
} from "react-bootstrap";
import "../styles/TableNewDesign.css";
import { Link } from "react-router-dom";
import check from "../imgs/check.png";

import {
  fetchUsers,
  deleteEmployee,
  activateEmployee,
  deactivateEmployee,
  updateEmployeePriveligeApi,
} from "./api/Users/EmployeesApi";

function BayanEmployeesPermission({ history }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [apiData, setApiData] = useState([]);

  const [selectValue, setSelectValue] = useState("");
  const [show, setShow] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);

  const allPreviliges = {
    tkym_al_shbka_altbya: "تقييم الشبكات الطبية",
    t2gyr_wby3_al_aghza: "تأجير و بيع الاجهزة الطبية",
    t2gyr_5dmat_alatba_wal3ydat: "تأجير خدمات الاطباء و العيادات",
    mokrnet_as3ar: "مقارنة أسعار تامين اخطاء ممارسة المهن الطبية",
    al_t7syl: "التحصيل",
    tnzym_wtsh8yl_3kodalmo5tbrat: "تنظيم وتشغيل عقود المختبرات",
    tnzym_wtsh8yl_3kodalmoash3a: "تنظيم وتشغيل عقود الأشعة",
    tnzym_monksat_twryd: "تنظيم مناقصات التوريد",
    al_kta3_al_8z2y: "القطاع الغذائي",
    kta3_krtsya_mktbya: "قطاع القرطاسية المكتبية",
    kta3_al_nzafa: "قطاع النظافة",
    kta3_al_asas_al_mktby: "قطاع الأثاث المكتبي",
    kta3_al_nkl: "قطاع النقل",
    kta3_al_8syl: "قطاع الغسيل",
    kta3_mlabes_zy_mow7d: "قطاع ملابس الزي الموحد",
    kta3_tsh8yl_wsyana: "قطاع التشغيل والصيانة",
    kta3_twzyf_w5dmat: "قطاع التوظيف والخدمات",
  };

  const more = {
    title: "الاجراءات",
    actions: {
      تفعيل: "approve",
      طباعة: "/admin/print-medical-technical-team",
      حذف: "delete",
    },
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  const handleDelete = (id) => {
    setShow(false);
    deleteEmployee(id, userInfo).then(() => {
      let newData = apiData;
      const filtered = newData.filter((o) => o.id !== +id);
      console.log("It's Deleted", filtered);
      setApiData([...filtered]);
    });
  };

  const updatePrevilege = (id, previlege, currentValue) => {
    updateEmployeePriveligeApi(id, previlege, !currentValue, userInfo).then(
      (data) => {
        const copyOfData = apiData;
        const updatedData = copyOfData.map((obj) =>
          obj.id == id ? data : obj
        );
        setApiData([...updatedData]);
      }
    );
  };

  const handleUpdate = (id) => {
    activateEmployee(id, userInfo).then((data) => {
      setShowActivateModal(true);
      const copyOfData = apiData;
      const updatedData = copyOfData.map((obj) => (obj.id == id ? data : obj));
      setApiData([...updatedData]);
    });
  };

  const handleStop = (id) => {
    deactivateEmployee(id, userInfo).then((data) => {
      setShowStopModal(true);
      const copyOfData = apiData;
      const updatedData = copyOfData.map((obj) => (obj.id == id ? data : obj));
      setApiData([...updatedData]);
    });
  };

  const showModel = () => {
    let teamId = selectValue.split("/")[1];
    return (
      <Modal
        style={{ direction: "rtl" }}
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "var(--main-color)",
            justifyContent: "center",
          }}
        >
          <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h4">يرجى تأكيد الحذف</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-red"
            onClick={() => setShow(false)}
          >
            <span className="h4">إلغاء</span>
          </Button>
          <Button
            variant="success"
            className="btn-main px-5"
            onClick={() => handleDelete(teamId)}
          >
            <span className="h4">تأكيد</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const activateModal = () => {
    let teamId = selectValue.split("/")[1];
    return (
      <Modal
        style={{ direction: "rtl" }}
        show={showActivateModal}
        onHide={() => setShowActivateModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "var(--main-color)",
            justifyContent: "center",
          }}
        >
          <Modal.Title className="text-white fw-bold">تفعيل الحساب</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h4">تم تفعيل الحساب بنجاح</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-red"
            onClick={() => setShowActivateModal(false)}
          >
            <span className="h4">تم</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const stopModal = () => {
    let teamId = selectValue.split("/")[1];
    return (
      <Modal
        style={{ direction: "rtl" }}
        show={showStopModal}
        onHide={() => setShowStopModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "var(--main-color)",
            justifyContent: "center",
          }}
        >
          <Modal.Title className="text-white fw-bold">ايقاف الحساب</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h4">تم ايقاف الحساب بنجاح</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-red"
            onClick={() => setShowStopModal(false)}
          >
            <span className="h4">تم</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  useEffect(() => {
    if (userInfo && true) {
      fetchUsers(userInfo).then((data) => setApiData(data.employees));
    } else {
      history.push("/");
    }
  }, []);

  return (
    <AdminLayout>
      <Row>
        <Col xl={12} md={12}>
          <Card className="code-table">
            <Card.Header>
              <Card.Title as="h5">قائمة الموظفين</Card.Title>
            </Card.Header>
            <Card.Body className="pb-0">
              <Table responsive hover style={{ height: "80vh" }}>
                <thead>
                  <tr>
                    <th>رقم الموظف</th>
                    <th>اسم الموظف</th>
                    <th>البريد الالكتروني</th>
                    <th>الصلاحيات</th>
                    <th>الحالة</th>
                    <th>الاجراءات</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.map((obj) => (
                    <tr key={obj.id}>
                      <td>
                        <p className="mb-1">H-{obj.id}</p>
                      </td>
                      <td>
                        <p className="mb-1">{obj.name}</p>
                      </td>
                      <td>
                        <p className="mb-1">{obj.email}</p>
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            تعديل الصلاحيات
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {Object.entries(allPreviliges).map((key) => {
                              return (
                                <Dropdown.Item
                                  className="remember-me"
                                  onClick={() =>
                                    updatePrevilege(obj.id, key[0], obj[key[0]])
                                  }
                                >
                                  <span
                                    style={{ width: "17px", height: "17px" }}
                                  ></span>
                                  <img
                                    src={check}
                                    alt="check"
                                    style={{
                                      top: "2px",
                                      right: "15px",
                                      width: "15px",
                                      height: "15px",
                                      display: obj[key[0]] ? "block" : "none",
                                    }}
                                  />
                                  <p style={{ fontSize: "13px" }}>{key[1]}</p>
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        {!obj.is_deleted ? (
                          <Button
                            variant={obj.is_active ? "success" : "danger"}
                            className={
                              obj.is_active
                                ? "label btn-success btn-sm f-12 text-white"
                                : "label btn-danger btn-sm f-12 text-white"
                            }
                          >
                            {obj.is_active ? "نشط" : "غير مفعل"}
                          </Button>
                        ) : (
                          <Button
                            className="label btn-danger btn-sm f-12 text-white"
                            variant="danger"
                          >
                            تم الحذف
                          </Button>
                        )}
                      </td>
                      {more && !obj.is_deleted ? (
                        <td>
                          <Row className="m-2 w-100">
                            <Form.Select
                              as="select"
                              onChange={handleChange}
                              className="text-center px-1 pe-2"
                            >
                              <option>أختر</option>
                              {!obj.approved && (
                                <option value={`approve/${obj.id}`}>
                                  تفعيل الحساب
                                </option>
                              )}
                              {!obj.is_deleted && (
                                <option value={`delete/${obj.id}`}>
                                  حذف الحساب
                                </option>
                              )}
                              <option value={`edit/${obj.id}`}>
                                تعديل الحساب
                              </option>
                              {/* // TODO: dummy url, change later */}
                              <option value={`stop/${obj.id}`}>
                                ايقاف الحساب
                              </option>
                            </Form.Select>
                            {selectValue.includes(
                              "/editMedicalOrTechnicalTeam"
                            ) ? (
                              <Button
                                onClick={() => setShow(true)}
                                className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                              >
                                تنفيذ
                              </Button>
                            ) : selectValue.includes(
                                "/admin/print-medical-technical-team"
                              ) ? (
                              <Link
                                to={selectValue}
                                className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                              >
                                تنفيذ
                              </Link>
                            ) : selectValue.includes("delete") ? (
                              <Button
                                onClick={() => setShow(true)}
                                className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                              >
                                تنفيذ
                              </Button>
                            ) : selectValue.includes("approve") ? (
                              <Button
                                onClick={() => handleUpdate(obj.id)}
                                className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                              >
                                تنفيذ
                              </Button>
                            ) : selectValue.includes("edit") ||
                              selectValue.includes("password") ? (
                              <Link
                                to={`/admin/edit-employee/${obj.id}`}
                                className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                              >
                                تنفيذ
                              </Link>
                            ) : selectValue.includes("stop") ? (
                              <Button
                                onClick={() => handleStop(obj.id)}
                                className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                              >
                                تنفيذ
                              </Button>
                            ) : (
                              ""
                            )}
                          </Row>
                        </td>
                      ) : (
                        <td>
                          <span className="text-danger">محذوف</span>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div>{showModel()}</div>
      <div>{activateModal()}</div>
      <div>{stopModal()}</div>
    </AdminLayout>
  );
}

export default BayanEmployeesPermission;
