import { Card, Alert, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import upload from "../imgs/upload (1).png";

import { useState } from "react";
import { getExtension } from "../screens/services/AddEvaluationDataScreen";
import { updateInsurrenceOffer } from "../actions/serviceActions";
import { useDispatch } from "react-redux";
import axios from "axios";
const fileDownload = require('js-file-download');

const NOTIFICATION_PAYMENT_LINK = 1;
const NOTIFICATION_INSURANCE_OFFER_NEW = 2;
const NOTIFICATION_PAYMENT_CONFIRMATION_FILE = 3;
const NOTIFICATION_CERTIFICATE_AND_BILL = 4;

const Notification = ({ notification, config, setNotificationsChanged }) => {

    const [confirmationFileText, setConfirmationFileText] = useState("ارفع ملف تأكيد الدفع");

    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');

    const dispatch = useDispatch();

    const [file, setFile] = useState(null);

    const handleFileUpload = (e) => {
        if (e.target.value) {
            const ext = getExtension(e.target.value);
            if (ext === "png" || ext === "jpg" || ext === "pdf") {
                const path = e.target.getAttribute("data-path")
                setFile(e.target.files[0]);
                setConfirmationFileText(e.target.value);
            }
        }
    }

    const handleSubmitFile = (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('sending_payment_confirmation_file', true);
        data.append('offer_status', 'S');
        data.append('notification_id', notification.id);
        data.append('uploaded_file', file);
        data.append('insurance_offer_id', notification.insurance_offer);

        dispatch(updateInsurrenceOffer(data)).then((result) => {
            setModalContent('نجحت العملية');
            setModalTitle('تم ارسال الملف, سيتم حذف الاشعار الان');
            setModalVisible(true);
            setNotificationsChanged(true);
        });
    };

    const handleDownload = (e) => {
        axios.post('management/download/', {
            responseType: 'blob',
            filename: notification.attachment
        }).then(res => {
            fileDownload(res.data, notification.attachment);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleDownload2 = (e) => {
        axios.post('management/download/', {
            responseType: 'blob',
            filename: notification.attachment2
        }).then(res => {
            fileDownload(res.data, notification.attachment2);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleDelete = () => {
        axios.delete(`/notifications/${notification.id}`, config).then((e) => {
            setModalContent('تم حذف الإشعار بنجاح');
            setModalTitle('حذف الإشعار');
            setModalVisible(true);
            setNotificationsChanged(true);
        }).catch((e) => {
            setModalContent('حدث خطأ ما');
            setModalTitle('لم يتم حذف الإشعار');
            setModalVisible(true);
        });
    };

    return (
        <Card className="m-4">
            <Card.Header as="h5">{notification.title}</Card.Header>
            <Card.Body>
                <Card.Title className="text-black py-4">
                    {notification.content}
                </Card.Title>
                <Modal show={modalVisible}>
                    <Modal.Header style={{ backgroundColor: 'var(--main-color)', justifyContent: 'center' }}>
                        <Modal.Title className="text-white fw-bold">{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="h4">
                        {modalContent}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn-main px-5" onClick={() => setModalVisible(false)}>
                            <span className="h4">موافق</span>
                        </Button>
                    </Modal.Footer>
                </Modal>
                <hr />
                <button style={{ bottom: 0, position: 'absolute', left: 0, margin: '20px' }} onClick={handleDelete} className="btn btn-danger">حذف</button>
                {notification.link && (notification.link?.startsWith('/') ? <Link to={notification.link}>{notification.link}</Link> : <a href={notification.link}>{notification.link}</a>)}
                <div style={{ margin: '20px' }}>
                    {notification.not_type == NOTIFICATION_PAYMENT_LINK &&
                        <form onSubmit={handleSubmitFile} className="notification-form">
                            <div style={{ display: 'inline', margin: '5px' }} className="input-content">
                                <label htmlFor="upload-file">
                                    <div className="input">
                                        <img src={upload} alt="" />
                                        <div>{confirmationFileText.slice(-30) + "..."}</div>
                                    </div>
                                </label>
                                <input
                                    onChange={handleFileUpload}
                                    type="file"
                                    name="file"
                                    id="upload-file"
                                    files
                                />
                            </div>
                            <button className="btn btn-primary notification-button">أرسل الملف</button>
                        </form>}
                    {notification.not_type == NOTIFICATION_PAYMENT_CONFIRMATION_FILE &&
                        <button className="btn btn-primary notification-button" onClick={handleDownload}>تنزيل الملف</button>
                    }
                    {notification.not_type == NOTIFICATION_CERTIFICATE_AND_BILL &&
                        <>
                            <button className="btn btn-primary notification-button" onClick={handleDownload}>تنزيل الشهادة</button>
                            {" "}
                            <button className="btn btn-primary notification-button" onClick={handleDownload2}>تنزيل الفاتورة</button>
                        </>
                    }

                </div>
            </Card.Body>
        </Card>);
};

export default Notification;
