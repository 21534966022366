import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import { Row, Col, Card, Table, Form, Button, Modal } from "react-bootstrap";
import "../styles/TableNewDesign.css";
import Paginate from "../component/Paginate";
import { Link } from "react-router-dom";

import { listInsurrenceRequestForAdmin, updateInsurrenceRequestForAdmin } from "../actions/adminActions";


function MedicalTeamInsurrenceRequest({ history }) {
    const [selectValue, setSelectValue] = useState("");
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();

    const medicalTeamInsurrenceRequestForAdmin = useSelector(
        (state) => state.medicalTeamInsurrenceRequestForAdmin
    );

    const { insurrenceRequest, page, pages } =
        medicalTeamInsurrenceRequestForAdmin;

    const insurrenceRequestUpdateForAdmin = useSelector(state => state.insurrenceRequestUpdateForAdmin)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = insurrenceRequestUpdateForAdmin

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    let keyword = history.location.search;

    useEffect(() => {
        if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
            dispatch(listInsurrenceRequestForAdmin(keyword));
        } else {
            history.push("/");
        }
    }, [dispatch, history, userInfo, successUpdate]);

    const more = {
        title: "الاجراءات",
        actions: {
            "تفعيل": "approve",
        },
    };

    const handleChange = (e) => {
        setSelectValue(e.target.value);
    };

    const handleMore = (id) => {
        return Object.keys(more.actions).map((key, index) => {
            let path = Object.values(more.actions)[index] + "/" + id;

            return (
                <option value={path} key={index}>
                    {key}
                </option>
            );
        });
    };


    const handleUpdate = (id) => {
        let data = new FormData();
        data.append('approved', true);
        dispatch(updateInsurrenceRequestForAdmin(data, id))
    }

    const showModel = () => {
        let teamId = selectValue.split("/")[1];
        return (
            <Modal
                style={{ direction: "rtl" }}
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    style={{
                        backgroundColor: "var(--main-color)",
                        justifyContent: "center",
                    }}
                >
                    <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
                </Modal.Header>
                <Modal.Body className="h4">يرجى تأكيد الحذف</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="btn-red"
                        onClick={() => setShow(false)}
                    >
                        <span className="h4">إلغاء</span>
                    </Button>
                    <Button
                        variant="success"
                        className="btn-main px-5"

                    >
                        <span className="h4">تأكيد</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <AdminLayout>
            <Row>
                <Col xl={12} md={12}>
                    <Card className="code-table">
                        <Card.Header>
                            <Card.Title as="h5">عروض التأمين</Card.Title>
                        </Card.Header>
                        <Card.Body className="pb-0">
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>رقم الطلب</th>
                                        <th>المنشأة التابع لها</th>
                                        <th>إسم الموظف</th>
                                        <th>التخصص</th>
                                        <th>السعر</th>
                                        <th>كود العرض</th>
                                        <th>شركة التأمين</th>
                                        <th>تفعيل العرض</th>
                                        <th>تاريخ العرض</th>
                                        <th>حالة العرض</th>
                                        <th>الإجراء</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {insurrenceRequest?.map((obj) => (
                                        <tr key={obj.id}>
                                            <td>
                                                <p className="mb-1">ID-{obj.id}</p>
                                            </td>
                                            <td>
                                                <p className="mb-1">
                                                    {obj.medical_insurance_request.facility.client_code}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="mb-1">
                                                    {obj.medical_insurance_request.ar_applicant_name}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="mb-1">
                                                    {obj.medical_insurance_request.medical_specialist}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="mb-1">{obj.offer_price} ر.س</p>
                                            </td>
                                            <td>
                                                <p className="mb-1">{obj.offer_code}</p>
                                            </td>
                                            <td>
                                                <p className="mb-1">{obj.facility.client_code}</p>
                                            </td>
                                            <td>
                                                <Button
                                                    variant={
                                                        obj.approved === true ? "success" : "warning"
                                                    }
                                                    className={
                                                        obj.approved === true
                                                            ? "label btn-success btn-sm f-12 text-white"
                                                            : "label btn-warning btn-sm f-12 text-white"
                                                    }
                                                >
                                                    {obj.approved === true ? "مفعل" : "غير مفعل"}
                                                </Button>
                                            </td>
                                            <td>
                                                <p className="mb-1">
                                                    {obj.created_at.substring(0, 10)}
                                                </p>
                                            </td>

                                            <td>
                                                <Link
                                                    variant={
                                                        obj.insurance_status === "A"
                                                            ? "success"
                                                            : obj.insurance_status === "N"
                                                                ? "warning"
                                                                : "danger"
                                                    }
                                                    className={
                                                        obj.insurance_status === "A"
                                                            ? "label btn-success f-12 text-white"
                                                            : obj.insurance_status === "N"
                                                                ? "label btn-warning f-12 text-white"
                                                                : "label btn-danger f-12 text-white"
                                                    }
                                                >
                                                    {obj.insurance_status === "A"
                                                        ? "نشط"
                                                        : obj.insurance_status === "N"
                                                            ? "قارب على الأنتهاء"
                                                            : "أنتهى"}
                                                </Link>
                                            </td>

                                            {more && !obj.is_deleted ? (
                                                <td>
                                                    <Row className="m-2 w-100">
                                                        <Form.Select as="select"
                                                            onChange={handleChange}
                                                            className="text-center px-1 pe-2"
                                                        >
                                                            <option>
                                                                أختر
                                                            </option>
                                                            {!obj.approved && <option value={`approve/${obj.id}`}>تفعيل</option>}
                                                        </Form.Select>
                                                        {selectValue.includes("approve") ? (
                                                            <Button onClick={() => handleUpdate(obj.id)} className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100">
                                                                تنفيذ
                                                            </Button>
                                                        ) : ('')
                                                        }
                                                    </Row>
                                                </td>
                                            ) : ("")
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <div>{showModel()}</div>
            <Paginate
                page={page}
                pages={pages}
                keyword={keyword}
                link="/comparingMedicalMistakeWork"
            />
        </AdminLayout>
    );
}

export default MedicalTeamInsurrenceRequest;
