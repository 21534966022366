import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/promotion.css";
import arrow from "../../imgs/arrow.png";
import { Link } from "react-router-dom";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import complete from "../../imgs/check1.png";
import error from '../../imgs/381599_error_icon.png'
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AddUpgradeEvaluation } from "../../actions/serviceActions"


function PromotionScreen({ history }) {

    const [count, setCount] = useState(0)

    const [standards, setStandards] = useState([])

    const [errors] = useState({
      standard_No: " يرجى اختيار 3 معايير من فضلك ",
      file_Upload: "يرجى رفع الملفات المطلوبة من فضلك",

    })

    const [photo, setPhoto] = useState(false)

    const [path, setPath] = useState('ارفق صورة')

    const [wrong, setWrong] = useState(false)

    const [errorsFlags, setErrorsFlags] = useState([false, false])

    const dispatch = useDispatch()

    const upgradeReducer = useSelector(state => state.upgradeReducer)

    const {loading, error, success } = upgradeReducer

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin



    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        }      

      }
      
    }, [dispatch, history, userInfo])


    const onClick = (e) => {

      if (e.target.classList.contains("active")) {

          e.target.classList.remove("active");

          setCount(count - 1)
          setStandards(standards.filter( str =>   str != e.target.getAttribute("data-name"))
        );
      
      } else if (count < 3) {
        e.target.classList.add("active");
          setCount(count + 1)
          setStandards(standards.concat([e.target.getAttribute("data-name")]))
      }
    };
   
    const getExtension = (filename) => {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    };

    const onUpload = (e) => {
      if (e.target.value) {
      const ext = getExtension(e.target.value);
        if (ext === "png" || ext === "jpg" || ext === "pdf") {
            setPhoto(true)
            setPath(e.target.files[0])
            setWrong(false)
        } else {
            setPhoto(false)
            setPath("ارفق ملف او اختاره يدويا")
            setWrong(true)            

        } 
      }
  };
    
    const formValidation = () => {
      let isValid = true;
      const flags = [false, false];

      if (count < 3) {
        flags[0] = true;
        isValid = false;
      } else {
        flags[0] = false;
      }
      if (path === 'ارفق ملف او اختاره يدويا') {
        flags[1] = true;
        isValid = false;
      } else {
        flags[1] = false;
      }     
    
      setErrorsFlags(flags);
      return isValid;
    };

    const onSubmit = (e) => {
        e.preventDefault()
        const isValid = formValidation();

        if (isValid) {
        
          let data = new FormData();

          data.append("standard1", standards[0]);

          data.append("standard2", standards[1]);

          data.append("standard3", standards[2]);

          data.append("proof", path);
          
          dispatch(AddUpgradeEvaluation(data))

          history.push('/SubscriptionPaymentForSmallFacility')

        }
    }

    return (
      <div className="promotion_Page">
      <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={true}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest = {'/collectServices'}
        style={"three_section"}
      />
      
      <div className="heading text-center">الترقية</div>
        <div className="hint text-center">
          نود التوضيح بأن هناك رسوم قدرها 1,000 ريال لترقية من فئتك لفئة آخرى
        </div>
        <form onSubmit = {onSubmit}>
          <div className="standards">
            <div className="sub_heading text-center">أختر 3 من المعايير التالية</div>
            <div className="standard_items">
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "153px" }}
                data-name = "زيادة عدد العيادات"
              >
                زيادة عدد العيادات
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "153px" }}
                data-name = "إنشاء غرفةطوارئ"
              >
                إنشاء غرفةطوارئ
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "166px" }}
                data-name="زيادة مساحة المنشأة"
              >
                زيادة مساحة المنشأة
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "166px" }}
                data-name = "زيادة عدد المواقف"
              >
                زيادة عدد المواقف
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "166px" }}
                data-name = "إنشاء معمل أشعة"
              >
                إنشاء معمل أشعة
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "166px" }}
                data-name = "إنشاء معمل مختبر"
              >
                إنشاء معمل مختبر
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "166px" }}
                data-name = "زيادة غرف التنويم"
              >
                زيادة غرف التنويم{" "}
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "153px" }}
                data-name = "إنشاء فروع للمنشة"
              >
                إنشاء فروع للمنشة
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "226px" }}
                data-name = "زيادة عدد أسرة التنويم الخاصة"
              >
                زيادة عدد أسرة التنويم الخاصة
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "197px" }}
                data-name = "وجود أجهزة جديدة وحديثة"
              >
                وجود أجهزة جديدة وحديثة
              </div>
              <div
                onClick={onClick}
                className="standard_item"
                style={{ width: "153px" }}
                data-name = "زيادة غرف العمليات"
              >
                زيادة غرف العمليات
              </div>
            </div>

            {errorsFlags[0] && <div className='error'><img src={error} />{errors.standard_No}</div>}
            
            <p className="sub_hint text-center">
              بعد إختيار المعايير وتنفيذها لابد من إرفاق البيانات التى تؤكد صحة
              المعلومات
            </p>
            <div className="input-content">
              <p>ارفق ملفات</p>
              <label htmlFor="upload-photo1" style={{ width: "100%" }}>
                {" "}
                <div className="input w-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="30.706"
                    viewBox="0 0 24 30.706"
                  >
                    <g id="upload" opacity="0.83">
                      <path
                        id="Path_2318"
                        data-name="Path 2318"
                        d="M79.775,7.088l-2.3-2.3V1.365A1.366,1.366,0,0,0,76.113,0H57.255a1.349,1.349,0,0,0-1.348,1.348V23.959a1.349,1.349,0,0,0,1.348,1.348h.762v.751a1.349,1.349,0,0,0,1.348,1.348h2.924a.45.45,0,1,0,0-.9H59.365a.449.449,0,0,1-.448-.448V7.974a.45.45,0,1,0-.9,0V24.407h-.762a.449.449,0,0,1-.448-.448V1.348A.449.449,0,0,1,57.255.9H76.113a.466.466,0,0,1,.465.465V3.892L74.918,2.231A.45.45,0,0,0,74.6,2.1H59.365a1.349,1.349,0,0,0-1.348,1.348V5.88a.45.45,0,1,0,.9,0V3.447A.449.449,0,0,1,59.365,3H74.15V7.405a.45.45,0,0,0,.45.45h4.408v18.2a.449.449,0,0,1-.448.448H75.67a.45.45,0,1,0,0,.9h2.889a1.349,1.349,0,0,0,1.348-1.348V7.406a.45.45,0,0,0-.132-.318Zm-4.726-.132V3.635l3.322,3.322Z"
                        transform="translate(-55.907 0)"
                        fill="#1e1e1e"
                      />
                      <path
                        id="Path_2319"
                        data-name="Path 2319"
                        d="M187.778,175.456h-10.76a.45.45,0,0,0,0,.9h10.76a.45.45,0,0,0,0-.9Z"
                        transform="translate(-169.332 -164.934)"
                        fill="#1e1e1e"
                      />
                      <path
                        id="Path_2320"
                        data-name="Path 2320"
                        d="M187.778,219.688h-10.76a.45.45,0,0,0,0,.9h10.76a.45.45,0,0,0,0-.9Z"
                        transform="translate(-169.332 -206.513)"
                        fill="#1e1e1e"
                      />
                      <path
                        id="Path_2321"
                        data-name="Path 2321"
                        d="M323.119,264.8h-2.126a.45.45,0,1,0,0,.9h2.126a.45.45,0,0,0,0-.9Z"
                        transform="translate(-304.672 -248.921)"
                        fill="#1e1e1e"
                      />
                      <path
                        id="Path_2322"
                        data-name="Path 2322"
                        d="M183.535,265.7a.45.45,0,1,0,0-.9h-6.518a.45.45,0,0,0,0,.9Z"
                        transform="translate(-169.332 -248.921)"
                        fill="#1e1e1e"
                      />
                      <path
                        id="Path_2323"
                        data-name="Path 2323"
                        d="M237.548,367.735l-1.2-1.2v4.348a.45.45,0,1,1-.9,0v-4.348l-1.2,1.2a.45.45,0,0,1-.636-.636l1.967-1.967a.45.45,0,0,1,.636,0l1.967,1.967a.45.45,0,0,1-.636.636Z"
                        transform="translate(-222.833 -343.11)"
                        fill="#1e1e1e"
                      />
                      <path
                        id="Path_2324"
                        data-name="Path 2324"
                        d="M185.229,323.61a5.649,5.649,0,1,0,5.649,5.649A5.656,5.656,0,0,0,185.229,323.61Zm0,10.4a4.749,4.749,0,1,1,4.75-4.749A4.755,4.755,0,0,1,185.229,334.009Z"
                        transform="translate(-172.163 -304.202)"
                        fill="#1e1e1e"
                      />
                    </g>
                  </svg>
                </div>{" "}
              </label>
              <input
                onChange={onUpload}
                type="file"
                name="photo"
                id="upload-photo1"
              />
              {photo && <img src={complete} alt="" />}
              {wrong && <AiOutlineCloseCircle className="complete" />}
            </div>
            {errorsFlags[1] && <div style={{marginLeft : '783px',marginTop:'10px'}} className='error'><img src={error} />{errors.file_Upload}</div>}
          </div>

         
          <button type='submit' className="btnNext">
            <img src={arrow} alt="" />
            إرسال المرفق
          </button>
           
        </form>


    </div>
    )
}

export default PromotionScreen
