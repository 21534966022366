import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { ConfigContext } from "../../../../../contexts/ConfigContext";
import * as actionType from "../../../../../constants/adminConstants";
import { useSelector } from "react-redux";
import { useState } from 'react';

const NavLogo = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;
  const [title, setTitle] = useState('');

  const userInfo = useSelector(state => state.userLogin.userInfo);

  useEffect(() => {

    if (userInfo.user.startsWith('1'))
    {
      setTitle('لوحة تحكم شركات التأمين');
    } else if (userInfo.user.startsWith('4')) {
        setTitle('لوحة تحكم عملاء منصة بيان');
    }

  }, userInfo);

  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo">
        <Link to="#" className="b-brand">
          {/* <div className="b-bg">
            <i className="feather icon-trending-up" />
          </div> */}
          <span className="b-title">{title}</span>
        </Link>
        <Link
          to="#"
          className={toggleClass.join(" ")}
          id="mobile-collapse"
          onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}
        >
          <span />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
