import { Card, Alert, Button } from "react-bootstrap";
import "../styles/messages.css";
import { Link } from "react-router-dom";
import AdminLayout from "./layouts/AdminLayout";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";

const Notifications = ({ history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [messages, setMessages] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");

    axios
      .get("/notifications/", {
        headers: { Authorization: `Bearer ${userInfo.access}` },
      })
      .then(({ data }) => {
        if (data.detail) {
          setError(data.detail);
        } else if (data.notifications.length > 0) {
          setMessages(data.notifications);
        }
      });
  }, []);

  const handleApproval = (id) => {
    const data = new FormData();
    data.append("is_approved", "True");
    axios
      .put(`/notifications/${id}/`, data, {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        setMessages(messages.filter((m) => m.id !== id));
      });
  };

  const handleDeletion = (id) => {
    setError("");
    axios
      .delete(`/notifications/${id}/`, {
        headers: { Authorization: `Bearer ${userInfo.access}` },
      })
      .then(({ data }) => {
        setMessages(messages.filter((m) => m.id !== id));
      });
  };

  return (
    <AdminLayout>
      {error.length > 0 && <Alert variant="primary">{error}</Alert>}
      <div className="notifications">
        <div className="notification" style={{ height: "800px" }}>
          <div className="heading text-center">الاشعارات</div>
          <div className="scroll" style={{ marginTop: "55px" }}>
            <div
              className="notification_items text-end"
              style={{ marginRight: 0 }}
            >
              {messages.length > 0 ? (
                messages
                  .filter((x) => x.is_approved === false)
                  .map((m) => (
                    <Card className="m-4">
                      <Card.Header as="h5">{m.title}</Card.Header>
                      <Card.Body>
                        <Card.Title className="text-black py-4">
                          {m.content}
                        </Card.Title>
                        <hr />
                        {!m.is_approved ? (
                          <>
                            <Button
                              onClick={() => handleApproval(m.id)}
                              style={{ background: "#1BB1AA" }}
                            >
                              موافقة
                            </Button>
                            <Button
                              onClick={() => handleDeletion(m.id)}
                              style={{ background: "#E45236" }}
                            >
                              حذف
                            </Button>
                          </>
                        ) : (
                          <p>تمت الموافقة علي الاشعار</p>
                        )}
                      </Card.Body>
                    </Card>
                  ))
              ) : (
                <div className="text-center">لا توجد اشعارات</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Notifications;
