import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Button, Row, Col, Table, Card } from "react-bootstrap";
import "../styles/newOrderAdmin.css";
import "../styles/invoiceAllType.css";
import "../styles/certificateAdmin.css";

import QRCode from "react-qr-code";
import Hdate from "../client/components/print/Hdate";
import logo from "../assets/logo.svg";
import social1 from "../assets/social1.svg";
import social2 from "../assets/social2.svg";
import social3 from "../assets/social3.svg";
import social4 from "../assets/social4.svg";
import { Link } from "react-router-dom";
import axios from "axios";

import { getMedicalTeamDetails } from "../actions/serviceActions";

function MedicalTeamInformation({ match, history }) {
  const teamId = match.params.id;

  const [name, setName] = useState("");

  const [id, setID] = useState("");

  const [en_Name, setEn_Name] = useState("");

  const [facilityName, setFacilityName] = useState("");

  const [code, setCode] = useState("");

  const [createdAt, setCreatedAt] = useState("");

  const [licence_No, setLicence_No] = useState("");

  const [photo1, setPhoto1] = useState(false);

  const [photo2, setPhoto2] = useState(false);

  const [photo3, setPhoto3] = useState(false);

  const [start_Date, setStart_Date] = useState("");

  const [end_Date, setEnd_Date] = useState("");

  const [specialization, setSpecialization] = useState("");

  const [birth_Date, setBirth_Date] = useState("");

  const [id_endDate, setId_endDate] = useState("");

  const [phone_No, setPhone_No] = useState("");

  const [gender, setGender] = useState("");

  const [nationality, setNationality] = useState("");

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");

  const [postal_Code, setPostal_Code] = useState("");

  const [region, setRegion] = useState("");

  const [email, setEmail] = useState(null);

  const [emcertificate_Specializationail, setEmcertificate_Specializationail] =
    useState("");

  const [certifi_StartDate, setCertifi_StartDate] = useState("");

  const [certifi_EndDate, setCertifi_EndDate] = useState("");

  const [cover_Period, setCover_Period] = useState("");

  const [cover_Price, setCover_Price] = useState("");

  const [university, setUniversity] = useState("");

  const [organization_Name, setOrganization_Name] = useState("");

  const [status, setStatus] = useState("");

  const [have_Certifi, setHave_Certifi] = useState("");

  const [graduated_Date, setGraduated_Date] = useState("");

  const [Insurance_CertificateEndDate, setInsurance_CertificateEndDate] =
    useState("");

  function handleClick() {
    window.print();
  }

  const dispatch = useDispatch();

  const medicalTeamDetails = useSelector((state) => state.medicalTeamDetails);
  const { medicalTeam } = medicalTeamDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo.user.startsWith("50")) {
      if (medicalTeam.id !== Number(teamId)) {
        dispatch(getMedicalTeamDetails(match.params.id));
      } else {
        setName(medicalTeam.ar_applicant_name);
        setEn_Name(medicalTeam.en_applicant_name);
        setFacilityName(medicalTeam.facility.client_code);
        setID(medicalTeam.id_num);
        setCode(medicalTeam._id);
        setCreatedAt(medicalTeam.created_at);
        setLicence_No(medicalTeam.practical_license);
        setPhoto1(medicalTeam.practical_license_image);
        setStart_Date(medicalTeam.license_issue_date.substring(0, 10));
        setEnd_Date(medicalTeam.license_expire_date.substring(0, 10));
        setSpecialization(medicalTeam.medical_specialist);
        setPhoto2(medicalTeam.applicant_image);
        setBirth_Date(medicalTeam.birth_date.substring(0, 10));
        setId_endDate(medicalTeam.id_expire_date.substring(0, 10));
        setPhone_No(medicalTeam.phone);
        setGender(medicalTeam.gender);
        setNationality(medicalTeam.nationality);
        setAddress(medicalTeam.address);
        setCity(medicalTeam.city);
        setPostal_Code(medicalTeam.postal_code);
        setRegion(medicalTeam.state);
        setEmail(medicalTeam.applicant_email);
        setEmcertificate_Specializationail(
          medicalTeam.specialize_certificate_image
        );
        setCertifi_StartDate(
          medicalTeam.certificate_issue_date.substring(0, 10)
        );
        setCertifi_EndDate(
          medicalTeam.certificate_expire_date.substring(0, 10)
        );
        setCover_Period(medicalTeam.coverage_duration);
        setCover_Price(medicalTeam.coverage_price);
        setUniversity(medicalTeam.university_name);
        setOrganization_Name(medicalTeam.employer_name);
        setStatus(medicalTeam.marital_status);
        setGraduated_Date(medicalTeam.graduation_date.substring(0, 10));
        setInsurance_CertificateEndDate(
          medicalTeam.insurance_certificate_expire_date.substring(0, 10)
        );
        setPhoto3(medicalTeam.insurance_certificate_image);
      }
    } else {
      history.push("/");
    }
  }, [dispatch, history, userInfo, match, medicalTeam]);

  const retrieveDate = (d) => {
    let date = new Date(d);
    return date;
  };

  var fileDownload = require("js-file-download");

  const [pdfBtn, setPdfBtn] = useState(false);

  const handlePDFDownload = (filename) => {
    setPdfBtn(true);
    axios
      .post("management/download/", {
        responseType: "blob",
        filename: filename,
      })
      .then((res) => {
        fileDownload(res.data, filename);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="w-100" dir="rtl">
      <Container className="inv_a4 screen">
        <Button
          onClick={() => handleClick()}
          className="print_hide m-4 inv_button-print bg-secondary"
        >
          طباعة
        </Button>
        <div className="header_c" />
        <h1 className="inv_h1">إضافة موظف</h1>
        <img className="inv_img" alt="logo" src={logo} />
        <Hdate
          className="inv_Hdate_table"
          date={createdAt.substring(0, 10)}
          code={`ID-${code}`}
          codeTitle="كود الموظف:"
        />
        <p className="inv_QR_title">باركود :</p>
        <QRCode className="inv_QR" value={window.location.href} size="75" />
        <div className="inv_data">
          <h6>الخدمة :</h6>
          <p>مقارنة أسعار تامين اخطاء </p>
          <p> ممارسة المهن الطبية</p>
        </div>
        <div className="inv_table">
          <Row>
            <Col lg="12" className="p-0">
              <Table borderless>
                <thead className="thead">
                  <tr>
                    <th className="text-center" colSpan="6">
                      بيانات موظف المنشأة
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="td grayBG">الإسم بالعربي</td>
                    <td className="td table_small">{name}</td>
                    <td className="td grayBG">الجنس</td>
                    <td className="td table_small">{gender}</td>
                    <td className="td grayBG">إسم المنشأة</td>
                    <td className="td table_small">{facilityName}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">الإسم بالإنجليزي</td>
                    <td className="td table_small">{en_Name}</td>
                    <td className="td grayBG">التخصص</td>
                    <td className="td table_small">{specialization}</td>
                    <td className="td grayBG">تاريخ الميلاد</td>
                    <td className="td table_small">{birth_Date}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">الهاتف</td>
                    <td className="td table_small">{phone_No}</td>
                    <td className="td grayBG">العنوان</td>
                    <td className="td table_small">{address}</td>
                    <td className="td grayBG">المدينة</td>
                    <td className="td table_small">{city}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">المنطقة</td>
                    <td className="td table_small">{region}</td>
                    <td className="td grayBG">الرمز البريدي</td>
                    <td className="td table_small">{postal_Code}</td>
                    <td className="td grayBG">الجنسية</td>
                    <td className="td table_small">{nationality}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">رقم الهوية</td>
                    <td className="td table_small">{id}</td>
                    <td className="td grayBG">تاريخ الإنتهاء</td>
                    <td className="td table_small">{id_endDate}</td>
                    <td className="td grayBG">الإيميل</td>
                    <td className="td table_small">{email}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">رخصة المزاولة</td>
                    <td className="td table_small">{licence_No}</td>
                    <td className="td grayBG">تاريخ الإصدار</td>
                    <td className="td table_small">{start_Date}</td>
                    <td className="td grayBG">تاريخ الإنتهاء</td>
                    <td className="td table_small">{end_Date}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">شهادة التأمين</td>
                    <td className="td table_small">نعم</td>
                    <td className="td grayBG">تاريخ الإنتهاء</td>
                    <td className="td table_small">
                      {Insurance_CertificateEndDate}
                    </td>
                  </tr>
                  <tr>
                    <td className="td grayBG">شهادة هيئة التخصصات</td>
                    <td className="td table_small">
                      {emcertificate_Specializationail}
                    </td>
                    <td className="td grayBG">تاريخ الإصدار</td>
                    <td className="td table_small">{certifi_StartDate}</td>
                    <td className="td grayBG">تاريخ الإنتهاء</td>
                    <td className="td table_small">{certifi_EndDate}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">مدة التغطية</td>
                    <td className="td table_small">{cover_Period} يوم</td>
                    <td className="td grayBG">قيمة التغطية</td>
                    <td className="td table_small">{cover_Price} ريال</td>
                    <td className="td grayBG">أسم الجامعة</td>
                    <td className="td table_small">{university}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">أسم الجهة التي يعمل بها</td>
                    <td className="td table_small">{organization_Name}</td>
                    <td className="td grayBG">الحالة العائلية </td>
                    <td className="td table_small">{status} </td>
                    <td className="td grayBG">تاريخ التخرج</td>
                    <td className="td table_small">{graduated_Date} </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4 p-0">
              <Row>
                <Col lg="12" className="text-end new_card">
                  <Row>
                    <Col
                      lg="12"
                      className="d-flex flex-column align-items-center"
                    >
                      <Button
                        onClick={() => handlePDFDownload(photo2)}
                        className="btnNext m-0 my-2"
                        style={{ width: "93%" }}
                      >
                        {" "}
                        تحميل صورة مقدم الطلب{" "}
                      </Button>
                      <Link
                        to={photo1}
                        target="_blank"
                        className="btnNext m-0 my-2"
                        style={{ width: "93%" }}
                        download
                      >
                        {" "}
                        تحميل صورة رخصة المزاولة{" "}
                      </Link>
                      <Link
                        to={photo3}
                        target="_blank"
                        className="btnNext m-0 my-2"
                        style={{ width: "93%" }}
                        download
                      >
                        تحميل شهادة التأمين{" "}
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="new_boxs mt-3">
            <Row>
              <Col lg="6" className="p-0">
                <p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
              <Col lg="6" className="p-0">
                <p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
            </Row>
            <Row className="new_sign">
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="inv_footer">
          <span className="footer_text">الإيميل : info@bayan.med.sa</span>
          <div className="social_neworder_div">
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social4} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social3} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social2} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social1} />
            </a>
          </div>
        </div>
      </Container>
      <page size="A4" className="print">
        <div className="header_c" />
        <h1 className="inv_h1">إضافة موظف</h1>
        <img className="inv_img" alt="logo" src={logo} />
        <Hdate
          className="inv_Hdate_table"
          date={createdAt.substring(0, 10)}
          code={`ID-${code}`}
          codeTitle="كود الموظف:"
        />
        <p className="inv_QR_title">باركود :</p>
        <QRCode className="inv_QR" value={window.location.href} size="75" />
        <div className="inv_data">
          <h6>الخدمة :</h6>
          <p>مقارنة أسعار تامين اخطاء </p>
          <p> ممارسة المهن الطبية</p>
        </div>
        <div className="inv_table">
          <Row>
            <Col lg="12" className="p-0">
              <Table borderless>
                <thead className="thead">
                  <tr>
                    <th className="text-center" colSpan="6">
                      بيانات موظف المنشأة
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="td grayBG">الإسم بالعربي</td>
                    <td className="td table_small">{name}</td>
                    <td className="td grayBG">الجنس</td>
                    <td className="td table_small">{gender}</td>
                    <td className="td grayBG">إسم المنشأة</td>
                    <td className="td table_small">{facilityName}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">الإسم بالإنجليزي</td>
                    <td className="td table_small">{en_Name}</td>
                    <td className="td grayBG">التخصص</td>
                    <td className="td table_small">{specialization}</td>
                    <td className="td grayBG">تاريخ الميلاد</td>
                    <td className="td table_small">{birth_Date}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">الهاتف</td>
                    <td className="td table_small">{phone_No}</td>
                    <td className="td grayBG">العنوان</td>
                    <td className="td table_small">{address}</td>
                    <td className="td grayBG">المدينة</td>
                    <td className="td table_small">{city}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">المنطقة</td>
                    <td className="td table_small">{region}</td>
                    <td className="td grayBG">الرمز البريدي</td>
                    <td className="td table_small">{postal_Code}</td>
                    <td className="td grayBG">الجنسية</td>
                    <td className="td table_small">{nationality}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">رقم الهوية</td>
                    <td className="td table_small">{id}</td>
                    <td className="td grayBG">تاريخ الإنتهاء</td>
                    <td className="td table_small">{id_endDate}</td>
                    <td className="td grayBG">الإيميل</td>
                    <td className="td table_small">{email}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">رخصة المزاولة</td>
                    <td className="td table_small">{licence_No}</td>
                    <td className="td grayBG">تاريخ الإصدار</td>
                    <td className="td table_small">{start_Date}</td>
                    <td className="td grayBG">تاريخ الإنتهاء</td>
                    <td className="td table_small">{end_Date}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">شهادة التأمين</td>
                    <td className="td table_small">نعم</td>
                    <td className="td grayBG">تاريخ الإنتهاء</td>
                    <td className="td table_small">
                      {Insurance_CertificateEndDate}
                    </td>
                  </tr>
                  <tr>
                    <td className="td grayBG">شهادة هيئة التخصصات</td>
                    <td className="td table_small">
                      {emcertificate_Specializationail}
                    </td>
                    <td className="td grayBG">تاريخ الإصدار</td>
                    <td className="td table_small">{certifi_StartDate}</td>
                    <td className="td grayBG">تاريخ الإنتهاء</td>
                    <td className="td table_small">{certifi_EndDate}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">مدة التغطية</td>
                    <td className="td table_small">{cover_Period} يوم</td>
                    <td className="td grayBG">قيمة التغطية</td>
                    <td className="td table_small">{cover_Price} ريال</td>
                    <td className="td grayBG">أسم الجامعة</td>
                    <td className="td table_small">{university}</td>
                  </tr>
                  <tr>
                    <td className="td grayBG">أسم الجهة التي يعمل بها</td>
                    <td className="td table_small">{organization_Name}</td>
                    <td className="td grayBG">الحالة العائلية </td>
                    <td className="td table_small">{status} </td>
                    <td className="td grayBG">تاريخ التخرج</td>
                    <td className="td table_small">{graduated_Date} </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4 p-0">
              <Row>
                <Col lg="12" className="text-end new_card">
                  <Row>
                    <Col
                      lg="12"
                      className="d-flex flex-column align-items-center"
                    >
                      <Link
                        to={photo2}
                        target="_blank"
                        className="m-0 my-2"
                        style={{
                          width: "93%",
                          background: pdfBtn ? "#1bb1aa" : "#939598",
                        }}
                        download
                        onClick={() => setPdfBtn(true)}
                      >
                        {" "}
                        تحميل صورة مقدم الطلب{" "}
                      </Link>
                      <Link
                        to={photo1}
                        target="_blank"
                        className="btnNext m-0 my-2"
                        style={{ width: "93%" }}
                        download
                      >
                        {" "}
                        تحميل صورة رخصة المزاولة{" "}
                      </Link>
                      <Link
                        to={photo3}
                        target="_blank"
                        className="btnNext m-0 my-2"
                        style={{ width: "93%" }}
                        download
                      >
                        تحميل شهادة التأمين{" "}
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="new_boxs mt-3" style={{ marginTop: "60px" }}>
            <Row>
              <Col lg="6" className="p-0">
                <p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
              <Col lg="6" className="p-0">
                <p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
            </Row>
            <Row className="new_sign">
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="inv_footer">
          <span className="footer_text">الإيميل : info@bayan.med.sa</span>
          <div className="social_neworder_div">
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social4} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social3} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social2} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social1} />
            </a>
          </div>
        </div>
      </page>
    </div>
  );
}
export default MedicalTeamInformation;
