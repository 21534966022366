import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ControlPanelHeader from '../component/ControlPanelHeader'
import "../styles/endorsement.css";
import endorsement from "../imgs/endorsment.png";
import check from "../imgs/check.png";
import arrow from "../imgs/arrow.png";
import { Link } from "react-router-dom";
import error from '../imgs/381599_error_icon.png'



function EndorsementScreen({ location, history }) {
    const [checkBox, setCheckBox] = useState(false)

    const [errors] = useState({
        check_msg: " يرجى الموافقة على الأقرار من فضلك ",
    })

    const [errorsFlags, setErrorsFlags] = useState([ false])
    
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
      if (!userInfo) {
        history.push('/')
      } else {
        if (userInfo.is_registered){
          history.push('/services')
        }
      }
        
    }, [history, userInfo])

    const checkBoxHandler = () => {
        setCheckBox(checkBox ? false : true);
    };

    const checkStyle = checkBox ? { display: "block" } : {};

    const formValidation = () => {
        let isValid = true;
        const flags = [false];
        if (!checkBox) {
        flags[0] = true;
        isValid = false;
        } else {
        flags[0] = false;
        }
        
        setErrorsFlags(flags)
        return isValid;
      };

    const onSubmit = (e) => {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            setErrorsFlags(false);
            history.push("/confirmSignUpPage")
        }
        
    }

    return (
        <div>
        <ControlPanelHeader exit1={true} notifi={true} msg={true} msgDest={"/Messages"}
          notifidest={"/Notifications"} exitDest={"/"} style={"three_section"}/>
        <form onSubmit={onSubmit}>
        <div className="endorsement-content">
          <p className="heading text-center">أقرار و تعهد</p>
          <p className="sub-title text-center">
            فضلا بعد قرأة البيانات أدناه ارجوا تأكيد الموافقه{" "}
          </p>

          <div style={{ padding:"19px 0" }}><p style={{ height:"360px", overflowY: "scroll", textAlign: "right", lineHeight: "1.6", padding: "24px" }}>
          نحن نعلم أنك تهتم بكيفية استخدامنا ومشاركتنا لمعلوماتك الشخصية،  لذا أعددنا سياسة الخصوصية هذه لإعلامكم بكيفية جمعنا وتعاملنا مع البيانات الشخصية لضيوف موقعنا، هذه الوثيقة تم وضعُها من أجل تقديم خدمة أفضل ومراعاة الأساسيات والمعايير العالمية لاستخدام «معلومات التعريف الشخصي» الخاصة بمستخدمي شبكة الإنترنت، ومعلومات التعريف الشخصي هي المعلومات التي يمكن استخدامها بمفردها أو مع غيرها؛ من أجل تحديد شخص ما أو التواصل معه أو تحديد مكانه، أو تحديد شخص بعينه في سياق عام. 
من فضلك، اقرأ سياسة الخصوصية الخاصة بنا بعناية من أجل الحصول على فهم أوضح لكيفية جمعنا أو استخدامنا أو حمايتنا أو تعاملنا مع بياناتك الشخصية،  فيما يتعلق بموقعنا الإلكتروني.  

الموافقة الضمنية: 
إن استخدامك للموقع يعني تلقائيًّا أنك توافق على ما نقوم به من جمع واستخدام لمعلوماتك الشخصية حسب هذه الوثيقة. ولذلك؛ فنحن نحتفظ بحقنا في إجراء أي تغيير على هذه الوثيقة وما يتبع ذلك من تغيير في إجراءاتها في أي وقت، ودون أن نكون مُلزَمِين بإشعارِك بشكل خاص بذلك. 
المعلومات التي نجمعها من زوار منصتنا الإلكترونية أو تطبيقاتنا على الهواتف الذكية تتضمن نوعين من المعلومات؛  

أولًا:  
المعلومات الشخصية التي تختارون الإفصاح عنها والتي يتم جمعها على أساس فردي، وقد يتم استخدام هذه المعلومات لتزويدك بالمنتجات والخدمات التي قمت بطلبها، بالإضافة الى ذلك قد يتم استخدامها لغايات أخرى والتي قد تشمل ولا تقتصر على فهم احتياجات العميل، أو تحديث سجلاتك لدينا، أو إبلاغك بأية منتجات وخدمات أخرى قد ترغب في الحصول عليها، وللأسباب المتطلبة بواسطة القوانين والسلطات ذات العلاقة. 
ثانيًا:  
المعلومات الخاصة باستخدام المنصة والتي يتم جمعها على أساس جماعي من خلال تصفحكم لمنصتنا؛ أنتم وآخرون معكم، وهذا يمكننا من تعقب كيفية تنقل الزائر عبر أقسام المنصة التي يهتم بها. فنحن نجمع معلومات كعدد مرات الدخول إلى المنصة، وزيارات الصفحة، ومدة جلسات المستخدم. وهذا يشبه تقرير الحركة: فهو يتعقب ميول الأفراد والأعضاء وسلوكهم بشكل عام، ولكنه لا يحدد فردًا بعينه. كما إننا نستخدم هذه المعلومات لتقديم خدمة أفضل لكم وهذا يساعد على المحافظة على موقعنا مفيدًا بمساعدتنا على معرفة المعلومات التي يسعى إليها مستخدمونا. 

المعلومات المرسلة للموقع: 
جميع المعلومات أو المواد التي ترسل لنا أو يتم تزويدنا بها عن طريق المنصة تصبح ملكًا للمنصة، بشرط ألَّا تكون تلك المعلومات أو المواد مخالفة أو تحتوي على ما يعتبر خرقًا للقوانين والأنظمة المعمول بها.  
لا تعتبر المنصة مسؤولة عن سرية هذه المعلومات أو المواد إلا في حالات معينة ومن ذلك: وجود اتفاقية مبرمة مع المنصة لعدم إفشاء المعلومات، أو إذا كان ذلك متطلبًا عن طريق القوانين والأنظمة السائدة، أو عن طريق أمر قضائي أو جهة حكومية ذات صلة.  
مع ذلك، فإن المنصة تحتفظ بكامل حقها في استعمال واستخدام جميع المعلومات والمواد، مثال ذلك: الأفكار، التجارب، التقنيات، لأي أغراض وبأي وسائل.  
نود أن نبلغكم أنه وإلى وقتنا هذا لا يوجد بما يعرف «الحماية الكاملة/ الأمان الكامل، لاستخدام الإنترنت» ولذلك فإن المستخدم يتحمل كافة المخاطر وتقع عليه كامل المسؤولية المترتبة على إرسال أية معلومات أو مواد، أو تزويدنا بها عبر استخدام الإنترنت.
استخدام المعلومات: 
قد نستخدم المعلومات التي نجمعها منك عندما تسجل في المنصة الإلكترونية أو تطلب إحدى الخدمات أو تشارك في استطلاع رأي أو إحدى طرق التواصل التسويقية أو تتصفح المنصة، أو تستخدم بعض السمات الأخرى المحددة في المنصة الإلكترونية بالطرق التالية: 
من أجل إضفاء طابع شخصي على تجربة المستخدم والسماح لأنفسنا بتوصيل نوعية المحتويات وعروض الخدمات التي قد تهمك.  
من أجل تحسين منصتنا الإلكترونية لتقديم خدمة أفضل لك. 
من أجل السماح لأنفسنا بتقديم خدمة أفضل من أجلك عن طريق الإجابة عن استفساراتك المقدمة لخدمة العملاء، من أجل تنظيم مسابقات أو عروض خاصة،  
أو استطلاعات رأي أو غيرها من سمات المنصة الإلكترونية. 
من أجل إجراء تعاملاتك بسرعة. 
من أجل إرسال رسائل بريد إلكتروني دورية بخصوص المنتجات والخدمات الأخرى. 
كيف نحمي معلومات الزوار؟ 
يتم عمل مسح شامل للمنصة بصورة دورية من أجل اكتشاف الثغرات الأمنية ونقاط الضعف المعروفة من أجل زيارة آمنة قدر الإمكان لمنصتنا الإلكترونية. 
يتم جمع معلوماتك الشخصية خلف شبكات مؤمَّنة ولا يمكن الدخول إليها إلا بواسطة عدد محدود من الأشخاص الذين يمتلكون تصريح دخول خاص إلى مثل هذه الأنظمة، كما يُطلب منهم الحفاظ على سرية المعلومات، بالإضافة إلى أن جميع معلومات بطاقات الائتمان/المعلومات الحساسة التي تمدنا بها يتم تشفيرها بواسطة تقنية طبقة المنافذ الآمنة. (SSL) 
كما أننا نطبق مجموعة متنوعة من معايير الأمان عندما يضع المستخدم طلبات الشراء أو يسجل اشتراكه بالمنصة أو يدخل إلى معلوماته. 
تتم معالجة جميع التعاملات عبر مزود خدمة البوابات الآمنة، ولا يتم تخزينها أو معالجتها على خوادمنا. 

استخدام سجلات المتصفح  (cookies): 
إن سجلات المتصفح عبارة عن ملفات صغيرة الحجم تنقلها المنصة الإلكترونية أو مزود خدمتها إلى القرص الصلب على حاسبك عبر متصفح الإنترنت الخاص بك (إن سمحت بهذا)، والتي تمكن أنظمة المنصة الإلكترونية أو مزود خدمتها من التعرف على متصفحك وجمع وتخزين معلومات بعينها.  
 على سبيل المثال، نستخدم سجلات المتصفح من أجل مساعدتنا على تذكر ومعالجة المنتجات التي اشتريتها، كما أنها تُستخدم من أجل مساعدتنا على فهم مفضلاتك بناءً على نشاطاتك الحالية أو السابقة على المنصة، الأمر الذي يمكننا من تقديم خدمة محسنة لك. ونستخدم سجلات المتصفح أيضًا لمساعدتنا على تصنيف البيانات التي جمعناها عن حركة تصفح المنصة والتفاعلات التي تحدث عليها حتى نتمكن من تقديم تجربة وأدوات أفضل على المنصة في المستقبل. 
استخدامات سجلات المتصفح:  
المساعدة على تذكر ومعالجة منتجاتنا. 
فهم وتخزين مفضلات المستخدم من أجل الزيارات المستقبلية. 

متابعة الإعلانات 
تصنيف البيانات التي تم جمعها من حركة التصفح والتفاعلات على المنصة من أجل تقديم تجربة وأدوات أفضل في المستقبل، وقد نستخدم أيضًا خدمات أخرى موثوق بها من أجل متابعة هذه المعلومات بالنيابة عنا. 
يمكنك أن تختار أن يحذرك حاسوبك الإلكتروني في كل مرة تُرسل سجلات التتبع، ويمكنك أن تلغي استقبال جميع أنواع هذه السجلات، كما يمكنك أن تفعل هذا عبر إعدادات متصفحك (مثل متصفح إنترنت إكسبلورر).  
 تختلف أنواع المتصفحات فيما بينها اختلافات طفيفة، لذا، ألق نظرة على قائمة المساعدة في متصفحك من أجل أن تتعلم الطريقة الصحيحة لتعديل استقبال جهازك لسجلات التتبع. 
إن أغلقت خاصية استقبال سجلات المتصفح، فستتوقف بعض السمات، والتي لن تؤثر على تجربة المستخدم التي تجعل من زيارتك لمنصتنا أكثر فاعلية، وقد لا تعمل بعض من خدماتنا بشكل جيد. ولكن يمكنك أن ترسل لنا طلبات الشراء. 

الكشف عن المعلومات السرية من قبل طرف خارجي: 
إننا لا نبيع أو نبادل أو ننقل معلومات التعريف الشخصية الخاصة بك إلى أطراف خارجية إلا بعد إرسال إخطار مسبق لك، وهذا لا يتضمن شركاء استضافة المنصة الإلكترونية وغيرهم من الأطراف الذين يساعدوننا على تشغيل المنصة أو إدارة عملنا أو تقديم الخدمات لك، طالما وافقت 
هذه الأطراف على الحفاظ على سرية هذه المعلومات. قد نكشف عن معلوماتك في حال اعتقادنا أن هذا الأمر سيكون إذعانًا للقانون، أو بموجب سياسات منصتنا الإلكترونية أو لحماية حقوقنا أو ملكيتنا أو أمننا والآخرين. ولكن، يمكن تقديم بيانات الزوار غير الشخصية إلى أطراف أخرى من أجل أغراض التسويق أو الدعاية أو غيرها. 

روابط الأطراف الخارجية: 
أحيانًا، وطبقًا لتقديرنا، قد نضع منتجات أو خدمات لأطراف أخرى أو نعلن عنها على منصتنا الإلكترونية، وتمتلك هذه الأطراف الأخرى مواقع إلكترونية خاصة بها ذات سياسات خصوصية منفصلة ومستقلة بذاتها، لذا فإننا لا نتحمل أية مسئولية أو التزام قانوني حيال محتويات أو أنشطة هذه المواقع المرتبطة بمنصتنا الإلكترونية، ومع ذلك، فإننا نسعى لحماية نزاهة موقعنا ونرحب بأية تغذيات راجعة عن هذه المواقع. 

التعامل مع إشارات عدم التتبع: 
إننا نحترم إشارات عدم التتبع، ولا نتتبع أو نرسل سجلات تتبع أو نستخدم الدعاية عندما تكون خاصية عدم التتبع تعمل على المتفصح. 

التتبع السلوكي للأطراف الخارجية: 
من المهم أيضًا أن نذكر أننا لا نسمح بخاصية التتبع السلوكي للأطراف الأخرى. 

ممارسات الاستخدام العادل للمعلومات: 
إن حدث أي خرق أمني للبيانات، فسنخبر جميع المستخدمين بذلك عن طريق البريد الإلكتروني وعبر منصتنا الإلكترونية في خلال سبعة أيام عمل.

للتواصل معنا: 
إن كانت لديك أية استفسارات بخصوص سياسة الخصوصية، يمكنك التواصل معنا عبر أي من طرق التواصل التالية: 
البريد الإلكتروني: info@bayan.med.sa
مركز خدمة العملاء:  
+966 56 664 5122	-	+966 56 610 3136

إن استخدامكم للمنصة يعني تلقائيًّا أنكم توافقون على ما نقوم به من جمع واستخدام معلومات المنشأة الخاصة بكم حسب مقتضيات هذه الوثيقة؛ عليه، فإننا نحتفظ بحقنا في إجراء أي تغيير على هذه الوثيقة وما يتبع ذلك من تغيير في إجراءاتها في أي وقت، ودون أن يترتب علينا إشعارك بشكل خاص بذلك، كما تلتزم المنصة بتأمين خصوصية معلومات المشتركين.. وهذه الصفحة توضح الوجه الذي سيتم عبره استخدام أي معلومات شخصية تزودنا بها خلال تواجدك على المنصة 

انتفاء المسؤولية القانونية: 
يقر المُستخدِم بأنه المسؤول الوحيد عن طبيعة الاستخدام الذي يحدده للمنصة، وتخلي إدارة المنصة طرفها، إلى أقصى مدى يجيزه القانون، من كامل المسؤولية عن أية خسائر أو أضرار أو نفقات أو مصاريف يتكبدها المُستخدِم أو يتعرض لها هو أو أي طرف آخر من جراء استخدام المنصة أو العجز عن استخدامها. 

حالات انقطاع الخدمة والسهو والخطأ في المنصة:  
تبذل إدارة المنصة كافة الجهود لتطوير المنصة بشكل دائم للحفاظ على استمرار وسهولة الاستخدام، وضمان تدفق المعلومات عبر المنصة وعدم انقطاعها، ورغم ذلك قد تقع في أي وقت أخطاء تؤدي لانقطاع الخدمة بشكل مؤقت، وفي مثل هذه الحالات نتوقع من المستخدمين الانتظار حتى تعود الخدمة إلى معدلها الطبيعي. 
حساب المشترك وكلمة السر وأمن المعلومات: 
يختار المشترك كلمة سر / مرور لحسابه، ويُدخل عنوانا بريديًّا خاصًّا بشركته أو شبكته الطبية لمراسلته عليه، وتكون مسؤولية حماية كلمة السر هذه وعدم مشاركتها أو نشرها مسؤولية المشترك، وفي حال حدوث أي معاملات باستخدام كلمة السر هذه يتحمل المشترك كافة المسؤوليات المترتبة على ذلك، دون أدنى مسؤولية على المنصة. 
يتحمل المشترك كامل المسؤولية عن جميع المحتويات الخاصة به، التي يرفعها وينشرها عبر المنصة. 
لا يمكن للمشترك حذف حسابه من المنصة بأي وسيلة ولا تعديل اسم المستخدم لحسابه، بسبب تعلق الحساب بأمور مالية وحقوق مستخدمين آخرين يمكن الرجوع لها في أي وقت. 

التسجيل في المنصة:  
يقر المشترك عند تسجيله مع المنصة بأن المعلومات المقدمة كاملة ودقيقة، ويلتزم بأنه لن يقوم بالتسجيل في الموقع ولن يحاول دخوله منتحلًا اسم مشترك آخر ولن يستخدم اسمًا قد ترى الإدارة أنه غير مناسب، أو غير ملائم، مثل أرقام الهواتف، والأسماء المنتحلة لشخصيات شهيرة، وروابط المواقع، والأسماء غير المفهومة، وما في حكمها. 

استخدام ومشاركة المعلومات: 
يتم التعامل مع جميع المعلومات التي يزودها المستخدمين بسرية تامة ولا يتم بيعها أو مشاركتها مع أي طرف ثالث غير مخوّل بالوصول إليها. 

حالات استخدام المعلومات: 
- تطوير الخدمات التي تقدمها المنصة؛ في حال التعاقد مع طرف ثالث لتطوير الخدمات  
التي نقدمها بعد الالتزام بعدم مشاركة أي معلومات تتعارض مع بيان الخصوصية للمنصة. 
- إذا كنا مُلزَمِين قانونيًّا بأمر قضائي بالكشف عن أي معلومات. 

الرقابة على المحتوى: 
تحتفظ إدارة منصة بيان الإلكترونية بالحق في مراقبة أي محتوى يدخله المشترك، دون أن يكون ذلك لزامًا عليها، لذا تحتفظ بالحق (من دون التزام) في حذف أو إزالة أو تحرير أي مواد مدخلة من شأنها انتهاك شروط وأحكام الموقع دون الرجوع للمستخدم.  
إن قوانين حقوق النشر والتأليف المحلية و العالمية والأجنبية والمعاهدات الدولية تحمي جميع محتويات هذا الموقع، ومن خلال الاشتراك فيه فإن المشترك يوافق ضمنيًّا وبشكل صريح على الالتزام بإشعارات حقوق النشر التي تظهر على صفحاته. 
هذه السياسة محل تغيير دائم وتطوير، مع إشعار المشتركين والزوار بذلك، وعلى المشتركين مراجعة هذه السياسات بشكل دوري. 
شروط الاستخدام 
اتفاقية شروط استخدام المنصة: 
نحن في (بيان) نعلم بأنك تهتم بكيفية استخدامنا ومشاركتنا لمعلوماتك الشخصية، لذا أعددنا سياسة الخصوصية هذه والتي لا تتعارض مع لوائح وأنظمة مؤسسة النقد العربي السعودي. لإعلامكم بكيفية جمعنا وتعاملنا مع البيانات الشخصية لضيوف المنصة. 
من خلال زيارتك منصتنا أو شراء شيء منا، فإنك تكون قد اشتركت في «خدمتنا» ووافقت على أن تكون خاضعًا للشروط والأحكام التالية (شروط الخدمة، الشروط)، بما فيها تلك الشروط والأحكام والسياسات الإضافية المذكورة هنا أو التي ترتبط بالمنصة عن طريق رابط.  
تنطبق شروط الخدمة هذه على جميع مستخدمي المنصة، من متصفحين وموردين وعملاء وتجار أو مساهمين في محتوى المنصة. 
اقرأ من فضلك شروط الخدمة هذه بعناية قبل دخول المنصة أو استخدامه، فمن خلال دخول المنصة أو استخدام أي من الخدمات المدرجة به، فإنك تكون موافقًا على أن تكون خاضعًا لشروط الخدمة هذه، وإن لم تكن موافقًا على جميع الشروط والأحكام المذكورة في هذا الاتفاق فقد لا يسمح لك دخول المنصة أو استخدام أي من خدماته، وإن اعتبرت أن شروط الخدمة هذه عبارة عن عرض، فإن موافقتك ستكون مقصورة على شروط الخدمة هذه فقط. 
كما أن أية سمات أو منتجات أو خدمات تُضاف إلى الحالية ستكون خاضعة أيضًا لشروط الخدمة، ويمكنك أن تطلع على أحدث نسخة من شروط الخدمة في أي وقت عبر دخول هذه الصفحة. إننا نحتفظ بحق تحديث أو تغيير أو تبديل أي جزء من أجزاء شروط الخدمة هذه من خلال نشر تحديثات أو تغييرات على منصتنا الإلكتروني. إنك من يتحمل مسؤولية الاطلاع على هذه الصفحة بصورة دورية لكي ترى ما إذا كانت هناك تغييرات جديدة، كما أن مواصلة استخدامك أو دخولك المنصة الإلكترونية، بعد نشرنا لحدوث أية تغييرات جديدة، يتضمن موافقتك على هذه التغييرات. 

الأهلية:  
يجب أن تكون قد بلغت سن الرشد (السن القانوني) وفقا للنظام القانوني الذي تخضع له، كما يجب أن تكون قادرًا وأهلا وبشكل كامل للموافقة على الشروط والأحكام والالتزامات والتعهدات والضمانات المنصوص عليها في هذه الشروط وكذلك الالتزام والتقيد بها. كما تقر بأنك تلبي جميع المتطلبات الخاصة بالأهلية والمنصوص عليها في هذ القسم من شروط الاستخدام هذه. في أي حال فإنك تؤكد على أن عمرك يبلغ أكثر من (18) عامًا , حيث إن المنصة غير موجهة للأطفال ممن هم دون عمر (18) عامًا. 
في حال كنت تستخدم المنصة أو تقوم بفتح حساب على الموقع بالنيابة عن شركة أو مؤسسة أو منظمة، في هذه الحالة فإنك تقر وتضمن بأنك ممثل مفوض عن مثل هذه الجهة وتملك سلطة إلزام مثل هذه الجهة بهذه الشروط، كما أنك توافق على التزامك بهذه الشروط بالنيابة عن مثل هذه الجهة. 
إذا كنت وسيط تأمين أو مندوب مبيعات فإن لوائح مؤسسة النقد وسياستنا تمنع منعًا باتًّا استخدام المنصة من قبل وسطاء التأمين أو المندوبين، ويعتبر استخدام هذا المنصة من قبل وسطاء التأمين أو المندوبين استخدامًا غير قانوني. وإن منصة بيان  
للاستخدام الشخصي فقط وهي حصرية للفرد صاحب الشأن وغير قابل للتحويل إلى أي طرف ثالث.  
وتحتفظ منصة بيان بحق الملاحقة القانونية والتبليغ للجهات المختصة عن أي وسيط تأميني أو مندوب يستخدم المنصة بشكل غير قانوني. كما تحتفظ المنصة بحق مطالبة أي وسيط تأميني أو مندوب يستخدم المنصة بشكل غير قانوني عن أي عواقب قانونية ومالية تلحق المنصة بسبب هذا الاستخدام غير القانوني.  

شروط عامة: 
نحتفظ بحقنا في رفض تقديم الخدمة لأي شخص لأي سبب من الأسباب، وفي أي وقت نراه مناسبًا. وعليك أن تتفهم أن بياناتك (والتي لا تشمل معلومات بطاقاتك الائتمانية) قد تُنقل دون تشفير وقد تخضع إلى:  
(أ) النقل ما بين شبكات مختلفة على شبكة الإنترنت.   
(ب) تغيرات من أجل توفيقها وتعديلها بما يناسب المتطلبات الفنية للشبكات أو الأجهزة المتصلة فيما بينها، إلا إن معلومات البطاقات الائتمانية دائمًا ما يتم تشفيرها قبل تحويلها عبر الإنترنت. 
لا يمكنك أن تعيد استخدام أو أن تعيد إنشاء أو أن تنسخ أو أن تبيع أو أن تعيد بيع أو أن تستغل أي جزء من خدمات أو محتويات المنصة، أو أن تستخدم الخدمة أو أن تدخل الخدمة أو بيانات أي من مستخدمي المنصة دون الحصول على إذن كتابي منا. 

دقة واكتمال وحداثة المعلومات: 
إننا لا نتحمل مسؤولية كون المعلومات المتوفرة على هذة المنصة غير دقيقة أو كاملة أو محدثة، حيث إن المواد المدرجة على هذا المنصة، قد أُدرجت كمعلومات عامة فقط ولا يجب الاعتماد عليها أو استخدامها كمصدر وحيد لاتخاذ القرارات دون الرجوع إلى مصادر معلومات رئيسية أخرى أكثر دقة واكتمالًا وحداثة، كما أن أي اعتماد من قبلك على المواد المدرجة في هذه المنصة سيكون على مسؤوليتك الخاصة. 
قد يحتوي هذا الموقع على معلومات تاريخية معينة، والتي قد لا تكون محدثة بالضرورة وتم إدراجها بالموقع كمرجع من أجلك فحسب. إننا نحتفظ بحقنا في تعديل محتويات هذه المنصة في أي وقت نراه مناسبًا، ولكننا غير ملتزمين بتحديث أية معلومات على منصتنا، وعليك أن توافق على تحمل مسؤولية مراجعة أية تغييرات تحدث في المنصة. 
تسجيل الحساب: 
قد تتضمن منصة بيان خاصية أو وظيفة من شأنها أن تسمح لك بتسجيل حساب على المنصة؛ وعندما تقوم بتسجيل هذا الحساب يجب أن تقوم بتوفير معلومات صحيحة وكاملة حول شخصك والتي تتضمن توفير عنوان بريد إلكتروني ورقم هاتف محمول صالحين للاستعمال، كما يجب عليك أن تقوم بتحديث هذه المعلومات دون تأخير في حال حصل عليها أي تغيير. تكون مسؤولًا بشكل شخصي عن جميع النشاطات المتعلقة بالحساب الخاص بك على المنصة.  
لكي تحمي الحساب الخاص بك من أي استخدام غير مصرح به , يجب عليك أن تحتفظ بالمعلومات الخاصة بالدخول الى الحساب في مكان آمن. الرجاء أن تقوم بإبلاغنا وبشكل فوري عن أي عملية استخدام غير مصرح بها لحسابك أو عن أي خرق آخر للأمن الخاص بالحساب. 

تعديلات الخدمة والأسعار: 
نحتفظ بحقنا في تعديل الخدمة أو إيقافها في أي وقت (أو أي جزء من محتويات المنصة) دون إشعار مسبق وفي أي وقت نراه مناسبًا، كما إننا لن نكون عرضة للمساءلة القانونية لك أو لأي طرف آخر بموجب أية تعديلات أو تغييرات في الأسعار أو تعليق الخدمة أو إيقافها. 

المنتجات أو الخدمات: 
تقدم المنصة لعملائها منتجات وخدمات تأمينية من خلال شركات التأمين المرخصة من قبل مؤسسة النقد السعودي. 
يحتوي المنتج على صيغة الوثيقة، جداول الوثيقة، التعديلات اللاحقة على المنتج، والتغيير أو التعديل في المنتج بالإضافة إلى الشروط والأحكام الخاصة بشركة التأمين المصدرة لوثيقة التأمين. 
تغطية وثيقة التامين تبدأ بعد ‏اعتماد شركة التأمين لها‎‎.
تحتفظ المنصة لنفسها بحق الموافقة أو ‏رفض طلب الشراء للمنتجات بناء على ‏نتائج الكشف.‎‎ 
طلب المنتجات أو الخدمات: 
يمكنك طلب وشراء المنتجات عبر المنصة لابد من تسجيل حساب لكي يتم الشراء، ولكن إذا كان يتوجب دفع مبلغ على منتج قد قمت بطلبه، فعليك دفع المبلغ الكامل لهذا المنتج كما هو موضح أثناء عملية الطلب، كما أن جميع الطلبات التي نقوم بإتمامها على موقعنا الإلكتروني تخضع للقبول وفقا لهذه الشروط. 
سوف نقوم بإرسال بريد إلكتروني ‏لتأكيد العملية وبه تفصيل للمنتجات التي قمت ‏بطلبها أو شرائها. وللعلم فإن هذا البريد الإلكتروني ‏ليس من أجل تأكيد الطلب أو قبول الطلب. 
يتم إتمام العقد بينك وبين ‏شركة الـتأمين بعد الانتهاء من جميع الإجراءات ‏الخاصة بالطلب وقبوله بما في ذلك التحقق من استلامنا لكامل القيمة/ قسط المنتج.‎ ‎ 
قد تظهر عند عملية ‏إجراء الطلب أخطاء في الأسعار من وقت لآخر أو قد يكون هناك ‏أخطاء تؤدي إلى انقطاع عملية ‏الشراء. وسوف نبذل جهدنا ‏لمحاولة حل أية مشاكل قد تنشأ‏. وأنت ‏توافق على أن يكون لنا الحرية الكاملة ‏للتصرف ولتحديد القرار المناسب في مثل هذه ‏الحالات بما لا يتعارض مع قوانين مؤسسة النقد السعودي.‏ 

الدفع: 
جميع الأسعار الظاهرة في المنصة هي بالريال السعودي وتشمل الرسوم والضرائب المعمول بها في المملكة العربية السعودية. وتكون جميع الرسوم التي تدفعها نهائية وغير قابلة للاسترداد، ما لم نحدد خلاف ذلك.  ونسبة عمولة المنصة وعمولة منصة الدفع الإلكتروني Uway.
الضرائب (حسب الأنظمة المعمول بها في المملكة): 
نقبل الدفع باستخدام بطاقة ‏الآيبان.‏ 
في حالة الدفع ستستلم إشعارًا على الشاشة فور قبول عملية الدفع. لا نتحمل أي مسؤولية لو تم رفض عملية الدفع أو إلغاؤها من قبل مزود خدمة البطاقة الائتمانية لأي سبب من الأسباب. يجب عليك التأكد من البنك المزود لخدمة البطاقة الائتمانية عن سبب الرفض. 

نحن لا نقوم بتخزين معلومات بطاقتك الائتمانية وإن جميع المعلومات والتفاصيل التي تقوم بإدخالها من خلال بوابة الدفع الإلكترونية يتم تشفيرها بغرض الحماية الأمنية، ويتم أيضا تشفير المراسلات المتداولة من وإلى موقع مقدم الخدمة. 

يجب عليك التأكد من المعلومات المتعلقة ببطاقتك الائتمانية التي تستخدمها في عملية الدفع وأن تكون صحيحة وسارية المفعول والتزام الدقة، ويجب عليك استخدام البطاقة الائتمانية التابعة قانونيًّا لك. لن يتم استخدام أو مشاركة معلوماتك الشخصية مع أي طرف ثالث ويستثنى من ذلك التحقق من شرعية المعلومات وعدم تزويرها أو عند طلبها من القانون أو الأنظمة الشرعية أو بطلب من المحكمة. ولن نكون مسؤولين بأي شكل من الأشكال عن تزوير البطاقات الائتمانية. سيتم تحميلك كافة المسؤوليات عن استخدام البطاقات الائتمانية المزورة وستكون مسؤولا بشكل كامل عن إثبات خلاف ذلك. 

إن جميع أسعار المنتجات ‏تم ربطها إلكترونيًّا مع شركات التأمين، إلا إنه ‏قد تحدث بعض الأخطاء. وفي حال اكتشفنا وجود ‏خطأ في أسعار منتجات قمت بطلبها، سوف نقوم ‏بإبلاغك في أقرب وقت ممكن. 

نحن لا نقوم بفرض رسوم على خدمات المنصة ولكن قد نقوم بفرض رسوم بنسب محددة سيتم إطلاعك عليها في حال فرضها. 
يحق لـنا تقديم العروض والخصومات للعملاء من وقت لآخر حسب الخطة التسويقية كما يجب عليك أن تلتزم بالتسعيرة كما هي في العرض التسويقي وأن توافق على أحقيتنا بتغيير الأسعار من وقت إلى آخر. 
في حال عدم تمكننا من توفير الطلب أو جزء منه فسنبلغك مباشرة بهذا الخصوص. وسيتم إرجاع بعض أو كل المبلغ المدفوع بواسطة البطاقة الائتمانية التي استخدمتها لدفع مبلغ الطلب. 

الضمان وتحديد المسئولية: 
إننا لا نضمن أو نصرح بأن استخدامك الخدمة لن ينقطع أو أن يكون مناسبًا لك  
أو أن يكون آمنًا أو خاليًا من الأخطاء. كما لا نضمن أن تكون النتائج التي قد تحصل عليها من استخدام الخدمة دقيقة أو يمكن الاعتماد عليها. 
إنك توافق على أننا من حين لآخر سنوقف الخدمة لأوقات محددة من الوقت  
أو إلغاء الخدمة في أي وقت دون إشعار مسبق بذلك. 
كما توافق على أنك تتحمل مسؤولية استخدامك الخدمة أو عدم قدرتك على استخدامها، حيث أن جميع المنتجات والخدمات المقدمة إليك عبر المنصة قد تم تقديمها لك «كما هي» و «كما توفرت لنا» لاستخدامها دون أية تصريحات أو ضمانات من أي نوع، سواء مصرح بها أو ضمنية. 
الالتزامات الخاصة بك: 
عند استخدامك للخدمات أو وصولك إليها، فأنت توافق وتقر على ما يلي: 
تلتزم وتقر بأنك تفهم وتدرك صيغة الوثيقة التي تنوي شراءها. 
تلتزم وتقر بأن المعلومات التي استخدمتها عند إنشاء ‏الوثيقة حقيقية وصحيحة. 
تلتزم وتقر باستخدام بطاقة الائتمان أو حساب سداد الخاص بك عند عملية الشراء على هذه ‏المنصة. 
تلتزم وتقر بأنك المسؤول عن قراءة وفهم ‏شروط كل وثيقة تأمين تقوم بشرائها من خلال هذه المنصة.‎.‎ 
تلتزم وتقر بإخطارنا على الفور إذا فقدت ‏الوصول إلى اسم المستخدم وكلمة المرور الخاصة ‏بك.‎ 
تلتزم وتقر باستخدامك لهذه المنصة فقط لأغراض ‏أخلاقية وخلقية وقانونية ونزيهة وبطريقة متوافقة ‏مع هذه الشروط والقوانين المعمول بها.‎ 
تلتزم وتقر بأنك لن تقوم بتسويق الخدمات التي يتم تقديمها ‏عبر هذا المنصة الإلكترونية.‎ 
تلتزم وتقر بأن استخدامك منصتنا للاستخدام ‏الشخصي الخاص بك.‎ ‎ 

الترابط مع مواقع أخرى: 
تضم المنصة صلات ربط مع مواقع أخرى، وهنا أيضًا نحن لسنا مسؤولين عن محتوى تلك المواقع أو دقة المعلومات أو الآراء الواردة بها، كما إن تلك المواقع لا تخضع من جانبنا للاستقصاء أو المراقبة من حيث صحة المعلومات أو شموليتها. وإن إضافة رابط على المنصة لأي موقع آخر لا يعتبر موافقة منا على الموقع ذاته، فإذا رغبت في الانتقال من منصتنا إلى أي من تلك المواقع، فإنك تقوم بذلك على مسؤوليتك بمفردك. 

العلامات التجارية وحقوق النشر: 
إنّ جميع حقوق الملكية الفكرية، سواء كانت مسجلة أو غير مسجلة في المنصة، وكافة المعلومات والتصاميم الواردة عليها، تُعتبرُ ملكًا لنا، وإنّ كلّ محتويات المنصة محميةٌ أيضًا بموجب حقوق النشر كونها عملًا جماعيًّا يخضع لقوانين حقوق نشر المملكة العربية السعودية والاتفاقيات الدولية. جميع حقوق النشر محفوظة. 

إخلاء المسؤولية وحدود المسؤولية: 
إن الخدمات أو المعلومات التي يتم الحصول عليها من المنصة أو من خلالها، هي خدمات ومعلومات يتم توفيرها «كما هي» و «حسب توفرها»، وبالتالي لن نكون تحت أي ظرف من الظروف مسؤولين عن أية أضرار بما في ذلك على سبيل الذكر لا الحصر؛ الأضرار المباشرة أو غير المباشرة أو الخاصة أو العرضية أو الناتجة، أو الخسائر أو المصروفات المترتبة فيما يتعلق بهذه المنصة أو استخدامها أو عدم إمكان استخدامها من قبل أي طرف، أو فيما يتعلق بأي فشل في الأداء، أو الخطأ أو السهو أو الانقطاع أو التعطل أو التأخير في التشغيل أو البث أو بسبب فيروسات الكمبيوتر أو تعطل الخطوط أو الأنظمة أو كان ذلك ناتجًا عن الإخلال بهذه الاتفاقية أو الضمانات أو الإهمال أو المسؤولية الخاصة بالخدمات أو المعلومات أو غير ذلك. حتى ولو تبلغنا أو من يمثلنا باحتمال حصول تلك الأضرار أو الخسائر أو المصاريف. وإننا نعتبر التنبيه على الأضرار السابقة الذكر عنصرًا أساسيًّا للتعامل بينك وبيننا. 
الصلاحية: 
في حال ثبوت أن أيًّا من شروط الخدمة هذه غير مطابقة للقانون أو أصبحت باطلة أو لاغية، فستظل الشروط الصالحة سارية طبقًا للحد الأقصى الذي ينص عليه القانون، وسيتم إزالة الشروط التي لم تعد صالحة من شروط الاستخدام، مع عدم التأثير أو المساس بصلاحية أي من الشروط الأخرى. 

الإشعارات: 
يحق لنا أن تقوم بإرسال الاشعارات الموجهة اليك من خلال البريد الالكتروني , أو من خلال وضع الإشعار على موقعنا. في حال تم توفير الإشعار الموجه إليكم من خلال البريد الإلكتروني سيتم اعتبار الإشعار على أنه قد تم تسلمه من قبلكم ما لم يتم تبليغنا بأن البريد الإلكتروني المرسل إليه غير صالح ولا يعمل، وفي حال تم الإشعار من خلال وضعه على منصتنا، فإن الإشعار سيتم اعتباره على أنه قد تم إيصاله عند القيام بوضعه على الموقع. 

القانون المنظم: 
جميع شروط الخدمة وأية اتفاقات منفصلة متعلقة بتقديم الخدمات لك خاضعة ومفسرة بما يتفق مع القوانين المعمول بها في المملكة العربية السعودية. 

تغييرات شروط الخدمة: 
يمكنك مراجعة أحدث نسخة من شروط الخدمة في أي وقت على هذه الصفحة. 
إننا نحتفظ بحق تحديث أو تغيير أو تبديل أي جزء من أجزاء شروط الخدمة هذه من خلال نشر تحديثات وتغييرات على منصتنا الإلكترونية. إنك من يتحمل مسؤولية الاطلاع على هذه الصفحة بصورة دورية لكي ترى ما إذا كانت هناك تغييرات جديدة، كما أن مواصلة استخدامك أو دخولك للمنصة الإلكترونية بعد نشرنا حدوث أي تغييرات جديدة، يتضمن موافقتك على هذه التغييرات. 

للتواصل معنا: 
إن كانت لديك أية استفسارات بخصوص اتفاقية شروط الاستخدام ،  
يمكنك التواصل معنا عبر أي من طرق التواصل التالية: 

البريد الإلكتروني: info@bayan.med.sa

مركز خدمة العملاء: 
+966 56 664 5122	-	+966 56 610 3136

شروط عامة 
باستخدامك لمنصة بيان، تُقِر بالامتناع عما يلي: 

توفير أو تحميل ملفات تحتوي على برمجيات أو مواد أو بيانات أو معلومات أخرى ليست مملوكة لك أو لا تملك ترخيصًا بشأنها. 

استخدام هذه المنصة بأية طريقة لإرسال أي بريد إلكتروني تجاري أو غير مرغوب فيه أو أية إساءة استخدام من هذا النوع لمنصة  بيان. 

توفير أو تحميل ملفات على هذه البوابة تحتوي على فيروسات أو بيانات تالفة. 

نشر أو إعلان أو توزيع أو تعميم مواد أو معلومات تحتوي تشويهًا للسمعة أو انتهاكًا للقوانين  
أو مواد إباحية أو بذيئة أو مخالفة للتعاليم الإسلامية أو للآداب العامة أو أي مواد أو معلومات غير قانونية من خلال منصة بيان. 

الاشتراك من خلال منصة بيان في أنشطة غير مشروعة أو غير قانونية في المملكة العربية السعودية. 

الإعلان على منصة بيان عن أي منتج أو خدمة تجعلنا في وضع انتهاك لأي قانون  
أو نظام مطبق في أي مجال. 
استخدام أية وسيلة أو برنامج أو إجراء لاعتراض أو محاولة اعتراض التشغيل الصحيح لمنصة بيان. 

القيام بأي إجراء يفرض حملًا غير معقول أو كبير أو بصورة غير مناسبة على البنية التحتية للمنصة. 

المعلومات والمواد الواردة في هذا الموقع تخضع لتحديثات وتغييرات مستمرة حسب حاجة العمل 

إن اللغة العربية هي اللغة الأساسية لاستخدام المنصة والاستفادة من كل المواد المنشورة عليها، ويهدف ترجمة أي من هذه المواد لتقديم خدمة مضافة، وعليه فلا يتم الاستناد إلى الترجمة المتوفرة في تفسير أي خلاف حول ما تتضمنه المنصة من محتوى. 

كل اللوائح والقوانين المنشورة على المنصة سواء الخاصة بمنصة بيان أو بجهات أخرى قد تخضع للترجمة لتفسير معانيها بهدف زيادة الفائدة، غير أن النص العربي لكل تلك اللوائح والقوانين يشكل المرجعية الأساسية، وعليه فلا يمكن بأي حال الاعتماد على الترجمة التفسيرية الخاصة بها لاستنباط أية معلومات أو تفاصيل 

نحتفظ بحقنا في رفض تقديم الخدمة لأي كان لأي سبب من الأسباب وفي أي وقت نراه مناسبًا 

عليك أن تتفهم أن بياناتك (والتي لا تشمل معلومات بطاقاتك الائتمانية) قد تُنقل دون تشفير وقد تخضع إلى: (أ) النقل ما بين شبكات مختلفة على شبكة الإنترنت، و (ب) تغيرات من أجل توفيقها
وتعديلها بما يناسب المتطلبات الفنية للشبكات أو الأجهزة المتصلة فيما بينها، إلا أن معلومات البطاقات الائتمانية دائمًا ما يتم تشفيرها قبل تحويلها عبر الإنترنت 

لا يمكنك أن تعيد استخدام أو أن تعيد إنشاء أو أن تنسخ أو أن تبيع أو أن تعيد بيع أو أن تستغل أي جزء من خدمات أو محتويات الموقع، أو أن تستخدم الخدمة أو أن تدخل الخدمة أو بيانات أي من مستخدمي الموقع دون الحصول على إذن كتابي منا  

 استخدام الروابط على منصة بيان: 
باستثناء ما هو وارد أدناه، يمنع نقل أو نسخ أي من محتويات المنصة أو إنشاء أية روابط إلكترونية خاصة بها أو عرض أي منها في إطار. 

يمكن وضع روابط خاصة بالمنصة في أية مواقع أخرى لا تتعارض في أهدافها وتوجهها العام مع أهداف وسياسات وأطر عمل منصة بيان. 

لا تُعتَبر إدارة منصة بيان بأي حال من الأحوال مشاركة أو مرتبطة بأي شكل كان بأية علامات أو شعارات أو رموز تجارية أو خدمية أو أية وسائل أخرى مستخدمة أو تظهر على مواقع ويب المرتبطة بهذه البوابة أو أي من محتوياتها. 

تحتفظ منصة بيان بكامل حقوقها في إيقاف وإعاقة أي ارتباط بأي شكل من الأشكال من أي موقع يحتوي على مواضيع غير ملائمة أو فاضحة أو متعدية أو بذيئة أو إباحية  
أو غير لائقة أو غير مقبولة أو غير قانونية، أو أسماء أو مواد أو معلومات تخالف أي قانون أو تنتهك أية حقوق للملكية الفكرية أو لحقوق الخصوصية أو حقوق العلنية. 

تحتفظ منصة بيان بحق تعطيل أي ارتباط بأي شكل من الأشكال غير مصرح به ولا يتحمل أيةَ مسئوليةٍ عن المحتويات المتوفرة في أي موقع آخر يتم الوصول إليه عبر هذه البوابة أو الوصول منه لهذه البوابة. 

سياسة التعليقات والمشاركة الإلكترونية: 
في سبيل تحقيق الاستفادة القصوى من تطبيق مفهوم المشاركة الإلكترونية المجتمعية، تؤكد على ضرورة التزام مستخدمي قنوات المشاركة الإلكترونية المتوفرة على المنصة، من أجل تقديم آراء بناءة وتعليقات تساهم في تطوير الأعمال وتحسين الخدمات المقدمة؛ ومن هذا المنطلق، فقد حددت المنصة عددًا من المعايير والضوابط التي تخضع لها كل التعليقات المضافة على قنوات المشاركة الإلكترونية، إذ يتم قراءة هذه المشاركات قبل نشرها، ومن حق إدارة المنصة عدم نشر أية تعليقات تراها غير مناسبة. 

أبرز معايير نشر التعليقات والمشاركات: 
يجب على صاحب التعليق أن يتحلى بالآداب العامة وأن يتجنب استخدام أية عبارات غير مناسبة أو ألفاظ خادشة للحياء. 

أن يكون التعليق منصباً على الموضوع المطروح للنقاش وألا يبتعد عن ذلك بأي شكل من الأشكال. 

تجنب الوقوع في أية انتقادات شخصية لا تهدف إلى إثراء الفكرة المطروحة والمنشورة. 
الابتعاد عن النعرات الطائفية وتجنب الوقوع في أية موضوعات عنصرية أو خلافات مذهبية 

التأكد التام عند كتابة أي نصوص قرآنية أو آراء دينية ويفضل عدم الخوض فيها إلا من المختصين.  

يحظر على المشاركين الإساءة للأديان والشعائر وتداول معلومات قد تثير الخلافات المذهبية. 

تجنب كتابة أية معلومات شخصية ومنها أية معلومات خاصة بالتواصل.  


إنهاء الاستخدام 
يجوز لنا وحسب تقديرنا المطلق إنهاء أو تقييد أو إيقاف حقك في الدخول إلى المنصة واستخدامها دون إشعار ولأي سبب بما في ذلك مخالفة شروط وبنود الاستخدام أو أي سلوك آخر قد نعتبره حسب تقديرنا الخاص غير قانوني أو مضرًا بالآخرين، وفي حالة الإنهاء، فإنه لن يكون مصرحا لك بالدخول إلى هذه المنصة. 

المرجعية القضائية 
بهذا توافق على الخضوع حصريًا للسلطات القضائية بالمملكة العربية السعودية فيما يتعلق بكافة المطالبات والخلافات التي تنشأ عن استخدامك لهذه المنصة، علماً بأن اللغة العربية ستكون هي اللغة الرسمية المستخدمة لحل أية خلافات تنشأ عن استخدامك للمنصة أو أي من محتوياتها 
شروط خاصة:  
1- يجب أن تكون قد بلغت سن الرشد (السن القانوني) وفقا للنظام القانوني الذي تخضع له، كما يجب أن تكون قادرًا وأهلًا وبشكل كامل للموافقة على الشروط والأحكام والالتزامات والتعهدات والضمانات المنصوص عليها في هذه الشروط وكذلك الالتزام والتقيد بها. كما تقر بأنك تلبي جميع المتطلبات الخاصة بالأهلية والمنصوص عليها في هذ القسم من شروط الاستخدام، وأنك تؤكد على أن عمرك يبلغ أكثر من (18) عامًا , حيث أن المنصة غير موجهة للأطفال ممن هم دون عمر (18) عامًا. 

2- في حال كنت تستخدم المنصة أو تقوم بفتح حساب عليها بالنيابة عن شركة أو مؤسسة أو منظمة, في هذه الحالة فإنك تقر وتضمن بأنك ممثل مفوض عن هذه الجهة وتملك سلطة إلزام مثل هذه الجهة بهذه الشروط، كما أنك توافق على التزامك بهذه الشروط  



            
            
            
          </p></div>
          <div className="remember-me">
            <p onClick={checkBoxHandler}>
              الموافقة علي الأقرار والتعهد بأن البيانات المدخلة صحيحة
            </p>
            <span onClick={checkBoxHandler}></span>
            <img
              style={checkStyle}
              onClick={checkBoxHandler}
              src={check}
              alt="check"
            />
          </div>
          {errorsFlags[0] && <div className='error'><img src={error} />{errors.check_msg}</div> }
        </div>

        
        <div className="endorsement-group-btn">
          
          
          
            <button type='submit' className="nextBtn">
              <img src={arrow} alt="" />
              التالي
            </button>
          
   
          
          <Link to="/EmployeeInfo">
          <button className="prevBtn">
            <img src={arrow} alt="" />
            السابق
          </button>

          </Link>
         
        </div>
        </form>
          
      </div>
    )
}

export default EndorsementScreen
