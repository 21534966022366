import {
    NOTIFICATION_ADD_REQUEST,
    NOTIFICATION_DATA_ADD_SUCCESS,
    NOTIFICATION_DATA_ADD_FAIL,

} from '../constants/notificationsConstants'


export const notificationAddReducer = (state = { }, action) => {
    switch (action.type) {

        case NOTIFICATION_ADD_REQUEST:
            return { loading: true }
        
        case NOTIFICATION_DATA_ADD_SUCCESS:
            return { loading: false, success: true, notification:action.payload }
        
        case NOTIFICATION_DATA_ADD_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

