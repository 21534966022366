import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "../../layouts/AdminLayout";
import { Row, Col, Card, Table, Form, Button, Modal } from "react-bootstrap";
import "../../../styles/TableNewDesign.css";
import Paginate from "../../../component/Paginate";
import { Link } from "react-router-dom";
import Message from "../../../component/Message";
import Loader from "../../../component/Loader";
import { Tender_list } from "../../api/offers/listOffers";
import { approve_Tender } from "../../api/offers/approveOffers";
import { cancel_Tender } from "../../api/offers/cancelRequests";

function OffersSellingMedicalDevices({ history }) {
	const MODEL = "tender";
	const TENDER_FILTER_VALUE = "الزي";

	const [selectValue, setSelectValue] = useState("");
	const [show, setShow] = useState(false);
	const [offers, setOffers] = useState([]);
	const dispatch = useDispatch();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
			Tender_list(userInfo).then((data) => setOffers(data));
		} else {
			history.push("/");
		}
	}, []);

	const more = {
		title: "الاجراءات",
		actions: {
			تفعيل: "approve",
			طباعة: "/admin/print-facility",
			تعديل: "/admin/update-facility-info",
			حذف: "delete",
		},
	};

	const handleChange = (e) => {
		setSelectValue(e.target.value);
	};

	const handleDelete = (id) => {
		setShow(false);
		cancel_Tender(id, userInfo).then((data) => {
			setOffers(offers.filter((o) => o.id != id));
		});
	};

	const handleUpdate = (id) => {
		let data = new FormData();
		data.append("approved", true);
		approve_Tender(id, userInfo).then((data) => {
			const copyOfData = offers;
			const updatedData = copyOfData.map((obj) =>
				obj.id == id ? { ...obj, approved: true } : obj
			);
			setOffers([...updatedData]);
		});
	};

	const showModel = () => {
		let teamId = selectValue.split("/")[1];
		return (
			<Modal
				style={{ direction: "rtl" }}
				show={show}
				onHide={() => setShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header
					style={{
						backgroundColor: "var(--main-color)",
						justifyContent: "center",
					}}
				>
					<Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
				</Modal.Header>
				<Modal.Body className="h4">يرجى تأكيد الحذف</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						className="btn-red"
						onClick={() => setShow(false)}
					>
						<span className="h4">إلغاء</span>
					</Button>
					<Button
						variant="success"
						className="btn-main px-5"
						onClick={() => handleDelete(teamId)}
					>
						<span className="h4">تأكيد</span>
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	return (
		<AdminLayout>
			<Row>
				<Col xl={12} md={12}>
					<Card className="code-table">
						<Card.Header>
							<Card.Title as="h5">عروض و طلبات</Card.Title>
						</Card.Header>
						<Card.Body className="pb-0">
							<Table responsive hover>
								<thead>
									<tr>
										<th>رقم الطلب</th>
										<th>كود المنشأة</th>
										<th>إسم المنشأة</th>
										<th>العرض</th>
										<th>الكمية</th>
										<th>القطاع</th>
										<th>نوع القطاع</th>
										<th> الحالة</th>
										<th>النوع</th>
										<th>الإجراء</th>
									</tr>
								</thead>
								<tbody>
									{offers.length > 0 &&
										offers
											.filter((o) => o.service.includes(TENDER_FILTER_VALUE))
											.map((obj) => (
												<tr key={obj.id}>
													<td>
														<p className="mb-1">{obj.id}</p>
													</td>
													<td>
														<p className="mb-1">
															{obj.facility != null && obj.facility.client_code}
														</p>
													</td>
													<td>
														<p className="mb-1">
															{obj.facility != null &&
																obj.facility.facility_name}
														</p>
													</td>
													<td>
														<p className="mb-1">{obj.title}</p>
													</td>
													<td>
														<p className="mb-1">{obj.quantity}</p>
													</td>
													<td>
														<p className="mb-1">{obj.service}</p>
													</td>
													<td>
														<p className="mb-1">
															{obj.facility != null &&
																obj.facility.sector_type.type_name}
														</p>
													</td>

													<td>
														<Button
															variant={
																obj.is_deleted === true
																	? "danger"
																	: obj.approved === true
																		? "success"
																		: "warning"
															}
															className={
																obj.is_deleted === true
																	? "label btn-danger btn-sm f-12 text-white"
																	: obj.approved === true
																		? "label btn-success btn-sm f-12 text-white"
																		: "label btn-warning btn-sm f-12 text-white"
															}
														>
															{obj.is_deleted === true
																? "تم حذفه"
																: obj.approved === true
																	? "مفعل"
																	: "غير مفعل"}
														</Button>
													</td>
													<td>{obj.request_type}</td>
													{more && !obj.is_deleted ? (
														<td>
															<Row className="m-2 w-100">
																<Form.Select
																	as="select"
																	onChange={handleChange}
																	className="text-center px-1 pe-2"
																>
																	<option>أختر</option>
																	{!obj.approved && (
																		<option value={`approve/${obj.id}`}>
																			تفعيل
																		</option>
																	)}
																	<option
																		value={`/admin/request-table/${MODEL}/${obj.id}`}
																	>
																		اظهار العروض
																	</option>
																	<option
																		value={`/admin/print-facility/${obj.id}`}
																	>
																		طباعة
																	</option>

																	{!obj.is_deleted && (
																		<option value={`delete/${obj.id}`}>
																			حذف
																		</option>
																	)}
																</Form.Select>
																{selectValue.includes(
																	"/editMedicalOrTechnicalTeam"
																) ? (
																	<Button
																		onClick={() => setShow(true)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : selectValue.includes(
																	"/admin/print-facility"
																) ? (
																	<Link
																		to={`/offers/print/${MODEL}/uniform/${obj.id}`}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Link>
																) : selectValue.includes("delete") ? (
																	<Button
																		onClick={() => setShow(true)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : selectValue.includes("approve") ? (
																	<Button
																		onClick={() => handleUpdate(obj.id)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : selectValue.includes("update") ? (
																	<Button
																		onClick={() => handleUpdate(obj.id)}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Button>
																) : selectValue.includes("request-table") ? (
																	<Link
																		to={`/admin/request-table/${MODEL}/${obj.id}`}
																		className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
																	>
																		تنفيذ
																	</Link>
																) : (
																	""
																)}
															</Row>
														</td>
													) : (
														""
													)}
												</tr>
											))}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<div>{showModel()}</div>
		</AdminLayout>
	);
}

export default OffersSellingMedicalDevices;
