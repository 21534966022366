import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import "../../styles/subscription_payment.css";
import saveMoney from "../../imgs/save-money.png";
import "../../styles/subscription_payment_bank.css";
import complete from "../../imgs/check1.png";
import upload from "../../imgs/upload (1).png";
import arrow from "../../imgs/arrow.png";
import { AiOutlineCloseCircle } from "react-icons/ai";


import ControlPanelHeader from '../../component/ControlPanelHeader'
import Message from '../../component/Message'
import {getOfferDetailsForAdmin} from '../../actions/adminActions'
import {addInvoiceForOffer} from '../../actions/serviceActions'


import Loader from '../../component/Loader'


function OfferPaymentBankScreen({ history, match }) {

    const [photo, setPhoto] = useState(false)

    const [path, setPath] = useState('بعد اجراء التحويل ارجوا ارفاق صورة التحويل لإتمام عملية التسجيل')

    const [wrong, setWrong] = useState(false)

    const [fType, setFType] = useState({})
    
    const [errorsFlags, setErrorsFlags] = useState([ false])

    const [errors, setErrors] = useState("يرجى ارفاق صورة التحويل من فضلك"
    )

    const offerId = match.params.id

    const typeId = match.params.type

    const dispatch = useDispatch()

    const offerDetailsForAdmin = useSelector(state => state.offerDetailsForAdmin)

    const {loading, error, success, offer } = offerDetailsForAdmin


    const invoiceAddForOffer = useSelector(state => state.invoiceAddForOffer)

    const {success: successAdd } = invoiceAddForOffer
   
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
        if (!userInfo) {
            history.push('/')
        } else {
            dispatch(getOfferDetailsForAdmin(offerId, typeId))
        }

    }, [dispatch, history, userInfo, success])

    const getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
      }
    
    const onUpload = (e) => {
        if (e.target.value) {
            const ext = getExtension(e.target.value);
            if (ext === "png" || ext === "jpg" || ext === "pdf") {
                setPhoto(true)
                setPath(e.target.files[0])
                setWrong(false)
            } else {
                setPhoto(false)
                setWrong(true)
            }
        }
      };

    const formValidation = () => {
    
        let isValid = true;
        const flags = [false];
    
        setErrorsFlags(flags)
        return isValid;
    };

    const onSubmit = (e) => {
        e.preventDefault()

        let data = new FormData();

        data.append("cost", (offer.offer_price).toFixed(2));

        data.append("shipping", 0);

        data.append("typeId", typeId);

        data.append("service_id", 1);
        
        data.append("vat_value", (offer.offer_price * 0.15).toFixed(2));

        data.append("direct_pay", '');

        data.append("banking_pic", path);

        data.append("sector_id", offer.request_device.facility.sector.id);

        data.append("order_num", offer.request_device.request_type);

        data.append("details", offer.request_device.title);
        
        const isValid = formValidation();
        if (isValid) {
            setErrorsFlags(false);
            dispatch(addInvoiceForOffer(offerId, typeId, data))
            history.push('/ThanksSubscriptionAmount')
        }
    }

    return (
        <div>
            { loading
                ? (<Loader/>) 
                : error 
                ? (<Message variant='danger'>{error}</Message>)
                : (
                    <div>
                        <div className="bank">
                            <ControlPanelHeader exit1={true} notifi={true} msg={true} msgDest={"/Messages"}
                            notifidest={"/Notifications"} exitDest={"/"} style={"three_section"} />
                            <div className="subscription_payment_content">
                            <p className="heading"> اختيار عمليه الدفع </p>
                            <p className="sub-title text-center" style={{ width:"350px" }}>{offer.request_device.title}</p>
                            <div style={{display:"flex"}}>
                                <div className="price-content">
                                    <p className="price text-center">المبلغ</p>
                                    <p className="price_no p-1">{(offer.offer_price + offer.offer_price *0.15).toFixed(2)}</p>
                                    <p className="price_type text-center">ريال سعودي</p>
                                </div>
                                <div className="price_info">
                                    <div className="price_onfo_title">
                                    تفاصيل الطلب
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">العرض</div>
                                        <div className="info_value">{offer.request_device.title}</div>
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">نوع الطلب</div>
                                        <div className="info_value">{offer.request_device.request_type}</div>
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">السعر</div>
                                        <div className="info_value"><span>{offer.offer_price} </span> <span className="coin"> ريال</span>   </div>
                                    </div>
                                

                                    <div className="price_info_item">
                                        <div className="info_title text-end">VAT ضريبة القيمة المضافة</div>
                                        <div className="info_value"><span>{(offer.offer_price * 0.15).toFixed(2)} </span> <span className="coin"> ريال</span></div>
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">المجموع</div>
                                        <div className="info_value"><span>{(offer.offer_price + offer.offer_price *0.15).toFixed(2)} </span> <span className="coin"> ريال</span></div>
                                    </div>
                                </div>
                            </div>
                                <p className="choose">الدفع من خلال</p>
                            </div>
                            
                            <form onSubmit={onSubmit}>
                                <div className="bank_details">
                                    <div className="bank_title">
                                        <img src={saveMoney} alt="" />
                                        <p>حساب بنكي</p>
                                    </div>
                                    <div className="bank_desc">
                                    <p>
                                يمكنكم الان التحويل لحساب شركة بيان الصحة للخدمات التسويقيه أدناه
                                    </p>
                                    <p>اسم البنك مصرف الانماء</p>
                                    <p>شركة بيان الصحة للخدمات التسويقية</p>
                                    <p>
                                    {" "}
                                    SA02 0500 0068 2033 2621 0000{"\u00A0 "}:{"\u00A0 "}
                                    {"\u00A0 "}(IBN) {"\u00A0 "} رقم حساب الايبان{" "}
                                    </p>
                                    <p style={{ color: "red" }}>
                                بعد إتمام العملية سيتم ايصال استلام مبلغ تأكيد التحويل إلى بريدكم
                                الالكتروني
                                    </p>
                                </div>
                                <div className="input-content">
                                    <label htmlFor="upload-photo">
                                    {" "}
                                    <div className="input">
                                        <img src={upload} alt="" />
                                        <div></div>
                                    </div>{" "}
                                    </label>
                                    <input
                                    onChange={onUpload}
                                    type="file"
                                    name="photo"
                                    id="upload-photo"
                                    />
                                    {photo && <img className="complete" src={complete} alt="" />}
                                    {wrong && <AiOutlineCloseCircle  />}
                                </div>
                                {errorsFlags && <div className='error'><img src={error} />{errors}</div> }
                        
                                <button type="submit" className="send-btn">
                                    <img src={arrow} alt="" />
                                    ارسال
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
      </div>
    )
}

export default OfferPaymentBankScreen
