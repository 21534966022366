import React, { Component } from "react";
import "../styles/thanks_msg.css";
import "../styles/thanks_subscriptionamount.css";
import email from "../imgs/email.png";
import arrow from "../imgs/arrow.png";
import Control_panel_Header from "./Control_panel_Header";
import { Link } from "react-router-dom";
class ThanksSubscriptionAmount extends Component {
  render() {
    return (
      

      <div className="thanks_SA"> 
         <Control_panel_Header exit1={true} notifi={true} msg={true} msgDest={"/Messages"}
          notifidest={"/Notifications"} exitDest={"/"} style={"three_section"} />
          <div className='thans_SA_content'>
            <div className="msg_content text-center">
                <img className='email-img' src={email} alt="" />
                <p className="thanks" style={{ width: "380px" }}>
                  ! شكرًا لدفع مبلغ الإشتراك
                </p>
                <p className="txt" style={{ width: "346px", left: "131px" }}>
                  سيتم إرسال بريد إلكتروني يؤكد إتمام عملية الدفع
                </p>
                <Link to='/services'>
                <button className="back-btn">
                       <img className='icon' src={arrow} alt="" />
                       الذهاب لصفحة الخدمات
                   </button>
                </Link>
              </div>             
          </div>
          
        </div>
    );
  }
}

export default ThanksSubscriptionAmount;
