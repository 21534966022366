import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loader() {
    return (
        <div
            
        >
        <span className='sr-only d-none'></span>
            
        </div>
    )
}

export default Loader
