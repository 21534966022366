import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import rarrow from "../../imgs/right-arrow1.png";
import date from "../../imgs/date.png";
import DatePicker from "react-datepicker";

import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
import complete from "../../imgs/check1.png";
import "../../styles/info-ownr.css";
import "../../styles/Tender_FSHF.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import check from "../../imgs/check.png";
import {
  Col,
  Card,
  Row,
} from "react-bootstrap";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import menu from "../../imgs/menu.png";
import { Link } from "react-router-dom";

import { AddDoctorsClinics } from '../../actions/serviceActions'
import axios from 'axios';


function AddRequestDoctorsScreen({ history }) {

  const [photo1, setPhoto1] = useState(false)

  const [path1, setPath1] = useState('')

  const [wrong1, setWrong1] = useState('')

  const [photo2, setPhoto2] = useState(false)

  const [path2, setPath2] = useState('')

  const [wrong2, setWrong2] = useState('')

  const [price, setPrice] = useState("")

  const [service_Name, setService_Name] = useState("")

  const [sectors, setSectors] = useState()

  const [patient_No, setPatient_No] = useState('')

  const [doctor_Name, setDoctor_Name] = useState('')

  const [deliveryDate, DeliveryDate] = useState('')

  const [period, setPeriod] = useState('')

  const [rent_Period, setRent_Period] = useState('')

  const [doctor_Requirment, setDoctor_Requirment] = useState('')

  const [operation_No, setOperation_No] = useState('')

  const [requestType, setRequestType] = useState('')

  const [experience_Years, setExperience_Years] = useState('')

  const [features, setFeatures] = useState('')

  const [paid_Way, setPaid_Way] = useState("")

  const [rent_Days, setRent_Days] = useState("")

  const [achievements, setAchievements] = useState("")

  const [errors] = useState({
    allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",
  })

  const [errorsFlags, setErrorsFlags] = useState([false])

  const [isValid, setIsValid] = useState(true)

  const [IsDisplayed, setIsDisplayed] = useState(false)

  const [placeHolder, setPlaceHolder] = useState(true)

  const dispatch = useDispatch()

  const doctorsClinicsAdd
    = useSelector(state => state.doctorsClinicsAdd)

  const { error, doctorsClinicsAddData, success } = doctorsClinicsAdd

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    } else {
      if (!userInfo.is_activated) {
        history.push('/services')
      }
    }

  }, [dispatch, history, userInfo])

  const handleDate = (d) => {
    if (d) {
      const offset = d.getTimezoneOffset();
      let newDate = new Date(d.getTime() - (offset * 60 * 1000));
      return newDate.toISOString().split('T')[0];
    }
  }

  const focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  const focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const onUpload = (e) => {
    if (e.target.value) {
      const ext = getExtension(e.target.value);
      if (ext === "png" || ext === "jpg" || ext === "pdf") {
        const path = e.target.getAttribute("data-path")
        if (path === "path1") {
          setPhoto1(e.target.value)
          setPath1(e.target.files[0])
          setWrong1(false)
        } else if (path === "path2") {
          setPhoto2(e.target.value)
          setPath2(e.target.files[0])
          setWrong2(false)
        }
      } else {
        const path = e.target.getAttribute("data-path")
        if (path === "path1") {
          setPhoto1(false)
          setWrong1(true)
        } else if (path === "path2") {
          setPhoto1(false)
          setWrong1(true)
        }
      }
    }
  };

  const changeSelect = (title) => {
    setIsDisplayed(false)
    setPlaceHolder(false)
    setRequestType(title)
  };

  const selectClick = () => {
    setIsDisplayed(IsDisplayed === true ? false : true);
  };

  const formValidation = () => {
    let isValid = true;
    const flags = [false];

    const nodes = document.querySelectorAll("input");
    nodes.forEach((e) => {
      if (e.value === '') {
        console.log(e)
        flags[0] = true;
        isValid = false;
      }

    });

    setErrorsFlags(flags);
    return isValid;
  };

  const arr = ["تأجير خدمة الأطباء", "تأجير خدمة العيادات"];

  const sendData = (e) => {
    e.preventDefault()
    const formvalid = formValidation();
    if (formvalid) {

      let data = new FormData();
      data.append("title", service_Name);
      data.append("request_type", requestType);
      data.append("num_patients", patient_No);
      data.append("experience_years", experience_Years);
      data.append("doctor_name", doctor_Name);
      data.append("features", features);
      data.append("clinic_image", path1);
      data.append("payment_method", paid_Way);
      data.append("receive_date", handleDate(rent_Period));
      data.append("request_duration", rent_Days);
      data.append("doctor_profile", path2);
      data.append("price", price);
      data.append("num_patients_clinic", price);
      data.append("doctor_requirements", doctor_Requirment);
      data.append("doctor_achievements", achievements)
      data.append("doctor_num_operations", operation_No);

      data.append("pay_days", parseInt(period));


      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`
        }
      }

      axios.post(
        `/services/clinic_request/`, data,
        config
      ).then(() => {
        history.push("/confirmAddRequestForServices");
      }).catch(() => {
        alert('خطأ');
      });



    }
  }

  return (
    <div>
      <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest={'/collectServices'}
        style={"three_section"}
      />
      <div className="tender_FSHF">
        <div className="tender_FSHF_content" style={{ height: "870px" }}>
          <p className="title-info text-center">إضافة عرض</p>

          <div className="input-group">
            <div className="right-data">
              <div className="input-lable">
                <label htmlFor="name">العنوان</label>
                <input
                  type="text"
                  id="service_Name"
                  name="service_Name"
                  value={service_Name}
                  onChange={(e) => { setService_Name(e.target.value) }}
                  placeholder="اكتب العنوان"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
              <div className="input-lable">
                <label htmlFor="name">عدد المرضى المعالجين</label>
                <input
                  type="number"
                  id="patient_No"
                  name="patient_No"
                  value={patient_No}
                  onChange={(e) => { setPatient_No(e.target.value) }}
                  placeholder="اكتب عدد المرضى المعالجين "
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
              <div className="input-lable">
                <label htmlFor="name">
                  {requestType == "تأجير خدمة الأطباء"
                    ? "أكتب اسم الطبيب او الفني"
                    : "أكتب اسم العيادة"}
                </label>
                <input
                  type="text"
                  id="doctor_Name"
                  name="doctor_Name"
                  value={doctor_Name}
                  onChange={(e) => { setDoctor_Name(e.target.value) }}
                  placeholder={
                    requestType == "تأجير خدمة الأطباء"
                      ? "أكتب اسم الطبيب او الفني"
                      : "أكتب اسم العيادة"
                  }
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
              <div className="input-lable">
                <label htmlFor="name"> قيمة التأجير </label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={price}
                  onChange={(e) => { setPrice(e.target.value) }}
                  placeholder="قيمة العرض"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>


              <div className="input-lable w-100">
                <label htmlFor="date2">وقت التاجير</label>
                <DatePicker
                  selected={rent_Period}
                  onChange={(date) => setRent_Period(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableMonthYearDropdown

                  placeholderText="وقت التاجير"
                  popperPlacement="top"
                />
                <img src={date} alt="" />
              </div>
              <div className="input-lable">
                <label htmlFor="name"> مدة التاجير</label>
                <input
                  type="number"
                  id="rent_Days"
                  name="rent_Days"
                  value={rent_Days}
                  onChange={(e) => setRent_Days(e.target.value)}
                  placeholder="اكتب المدة بالايام  "
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>

              {requestType == "تأجير خدمة الأطباء" ? (
                <div className="input-lable textaria" style={{ height: "168px" }}>
                  <label htmlFor="name"> متطلبات الدكتور بحال خارج منطقته</label>
                  <textarea style={{ height: "100%" }}
                    rows="6"
                    cols="10"
                    type="text"
                    id="doctor_Requirment"
                    name="doctor_Requirment"
                    value={doctor_Requirment}
                    onChange={(e) => setDoctor_Requirment(e.target.value)}
                    placeholder="اكتب  متطلبات الدكتور بحال خارج منطقته "
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="left-data" style={{ marginRight: "73px" }}>
              <div className="input-lable">
                <label htmlFor="name">نوع العرض</label>
                <div className="select-city">
                  <div className="select-btn" onClick={selectClick}>
                    {placeHolder && (
                      <span style={{ color: "var(--lightgray-color)" }}>
                        نوع العرض
                      </span>
                    )}
                    <span>{requestType}</span>
                    <img src={rarrow} />
                  </div>
                  {IsDisplayed && (
                    <div className="select-content">
                      {arr.map((option) => (
                        <div
                          className="select-item"
                          onClick={(e) => {
                            changeSelect(e.target.textContent);
                          }}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="input-lable">
                <label htmlFor="name">
                  {requestType == "تأجير خدمة الأطباء"
                    ? "عدد سنوات خبرة الطبيب"
                    : "عدد سنوات تأسيس العيادة"}
                </label>
                <input
                  type="number"
                  id="experience_Years"
                  name="experience_Years"
                  value={experience_Years}
                  onChange={(e) => setExperience_Years(e.target.value)}
                  placeholder={
                    requestType == "تأجير خدمة الأطباء"
                      ? "أكتب عدد سنوات خبرة الطبيب"
                      : "أكتب عدد سنوات تأسيس العيادة"
                  }
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
              <div className="input-lable textaria" style={{ height: "168px" }}>
                <label htmlFor="name"> المميزات</label>
                <textarea style={{ height: "100%" }}
                  rows="6"
                  cols="10"
                  type="text"
                  id="features"
                  name="features"
                  value={features}
                  onChange={(e) => setFeatures(e.target.value)}
                  placeholder="اكتب المميزات  "
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
              <Row>
                <Col lg="6">
                  <div className="radio_input">
                    <p>طريق الدفع للخدمة </p>
                    <div className="radio_group" style={{ width: "181px" }}>
                      <label>
                        <input
                          type="radio"
                          name="paid_Way"
                          value="مباشر"
                          checked={paid_Way === "مباشر"}
                          onChange={(e) => setPaid_Way(e.target.value)}
                        />
                        مباشر
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="آجل"
                          name="paid_Way"
                          checked={paid_Way === "آجل"}
                          onChange={(e) => setPaid_Way(e.target.value)}
                        />
                        آجل
                      </label>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  {paid_Way === "آجل" ? (
                    <div className="input-lable narrow2 w-100">
                      <label htmlFor="offerPeriod">البيع بالآجل</label>
                      <select style={{ height: "40px", borderRadius: "10px" }}
                        type="number"
                        id="period"
                        name="period"
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)}
                        placeholder="مدة العرض"
                        onFocus={focusHandler}
                        onBlur={focusoutHandler}
                      >
                        <option value="30">30 يوم</option>
                        <option value="60">60 يوم</option>
                        <option value="90">90 يوم</option>
                        <option value="120">120 يوم</option>
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              {requestType == "تأجير خدمة الأطباء" ? (
                <div className="input-lable">
                  <label htmlFor="name"> عدد العمليات اذا جراح </label>
                  <input
                    type="number"
                    id="operation_No"
                    name="operation_No"
                    value={operation_No}
                    onChange={(e) => setOperation_No(e.target.value)}
                    placeholder="اكتب  عدد العمليات اذا جراح  "
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
              ) : (
                ""
              )}

              <Row>
                <Col slg="6" className="p-0">
                  {requestType !== "تأجير خدمة الأطباء" ? (
                    <div className="input-content">
                      <p>رفع صوره</p>
                      <label htmlFor="upload-photo1" className="w-100">
                        <div className="input">
                          <img src={upload} alt="" />
                          <div>{photo1}</div>
                        </div>
                      </label>
                      <input
                        onChange={onUpload}
                        type="file"
                        name="photo1"
                        id="upload-photo1"
                        data-path="path1"
                        data-wrong="wrong1"
                      />
                      {photo1 && (
                        <img
                          className="complete"
                          src={complete}
                          alt=""
                          style={{ left: "-50px", right: "unset" }}
                        />
                      )}
                      {wrong1 && (
                        <AiOutlineCloseCircle
                          style={{ left: "-50px", right: "unset" }}
                        />
                      )}
                    </div>
                  ) : ("")}

                  {requestType === "تأجير خدمة الأطباء" ? (
                    <div className="input-content ">
                      <p> بروفايل عن الطبيب كامل </p>
                      <label className='pdf w-100' htmlFor="upload-photo2">
                        <div className="input">
                          <img src={upload} alt="" />
                          <div>{photo2}</div>
                        </div>
                      </label>
                      <input
                        onChange={onUpload}
                        type="file"
                        name="photo2"
                        id="upload-photo2"
                        data-path="path2"
                        data-wrong="wrong2"
                      />
                      {photo2 && (
                        <img
                          className="complete"
                          src={complete}
                          alt=""
                          style={{ left: "-50px", right: "unset" }}
                        />
                      )}
                      {wrong2 && (
                        <AiOutlineCloseCircle
                          style={{ left: "-50px", right: "unset" }}
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
            <div className="text-center d-flex justify-content-center">{errorsFlags[0] && <div className='error'>{errors.allFieldRequired}</div>}
            </div>
          </div>

          <button onClick={sendData} className="btnNext">
            <img src={arrow} alt="" />
            اضف عرضك
          </button>
        </div>

      </div>
    </div>
  )
}

export default AddRequestDoctorsScreen
