import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Jmodal({ appear, link, dismess }) {
  const [show, setShow] = useState(true);
  return (
    <Modal
      style={{ direction: "rtl" }}
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          backgroundColor: "var(--main-color)",
          justifyContent: "center",
        }}
      >
        <Modal.Title className="text-white fw-bold">تعهد</Modal.Title>
      </Modal.Header>
      <Modal.Body className="h4 m-auto">
        اتعهد في حال ارفاق العرض بان يكون على ورقه بيضاء ولا استخدم مطبوعات
        المنشأة او ختمها{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          className="btn-main px-5"
          onClick={() => setShow(false)}
        >
          <span className="h4">قبول</span>
        </Button>
        <Link to={link}>
          <Button
            variant="danger"
            className="btn-red px-5"
            onClick={() => setShow(false)}
          >
            <span className="h4">رفـض</span>
          </Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
}
export default Jmodal;
