import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import "../styles/subscription_payment.css";
import card from '../imgs/Path 2330.png'
import bank from '../imgs/bank.png'

import ControlPanelHeader from '../component/ControlPanelHeader'
import Message from '../component/Message'
import {getFacilityType} from '../actions/facilityActions'
import Loader from '../component/Loader'


function SubscriptionPaymentForSmallFacilityScreen({ history }) {

    const dispatch = useDispatch()

    const facilityTypeDetails = useSelector(state => state.facilityTypeDetails)

    const {loading, error, success, facilityType } = facilityTypeDetails
   
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
        if (!userInfo) {
            history.push('/')
        } else {
            dispatch(getFacilityType())      
        }
    }, [dispatch, history, userInfo, success])

    return (
        <div>
            { loading
                ? (<Loader/>) 
                : error 
                ? (<Message variant='danger'>{error}</Message>)
                : (
                    <div>
                        <ControlPanelHeader exit1={true} notifi={true} msg={true} msgDest={"/Messages"}
                        notifidest={"/Notifications"} exitDest={"/"} style={"three_section"} />
                        <div className="subscription_payment_content">
                            <p className="heading"> اختيار عمليه الدفع </p>
                            <p className="sub-title">{facilityType.facility_type}</p>
                            <div style={{display:"flex"}}>
                                <div className="price-content">
                                    <p className="price text-center">المبلغ</p>
                                    <p className="price_no p-1">{(1000 + 1000 *0.15).toFixed(2)}</p>
                                    <p className="price_type text-center">ريال سعودي</p>
                                </div>
                                <div className="price_info">
                                    <div className="price_onfo_title">
                                    تفاصيل الطلب
                                    </div>

                                    <div className="price_info_item">
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">حالة الطلب</div>
                                        <div className="info_value">ترقية</div>
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">مبلغ الترقية</div>
                                        <div className="info_value"><span>1000 </span> <span className="coin"> ريال</span>   </div>
                                    </div>
                                

                                    <div className="price_info_item">
                                        <div className="info_title text-end">VAT ضريبة القيمة المضافة</div>
                                        <div className="info_value"><span>{(1000 * 0.15).toFixed(2)} </span> <span className="coin"> ريال</span></div>
                                    </div>

                                    <div className="price_info_item">
                                        <div className="info_title">المجموع</div>
                                        <div className="info_value"><span>{(1000 + 1000 *0.15).toFixed(2)} </span> <span className="coin"> ريال</span></div>
                                    </div>
                                </div>
                            </div>
                            <p className="choose text-center">اختر طريقة الدفع المناسبه</p>
                            <div className="group-btn">
                                <Link to="SubscriptionPaymentBankForSmallFacility">
                                    <button className="nextBtn">
                                        <img src={bank} alt="" />
                                        حساب بنكي
                                    </button>
                                </Link>

                        <Link to="/SubscriptionPaymentCard">
                        <button className="prevBtn">
                            <img src={card} alt="" />
                            دفع الكتروني
                        </button>
                        </Link>
                    </div>
                    </div>
                    </div>
                )}
      </div>
    )
}

export default SubscriptionPaymentForSmallFacilityScreen
