import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import Change_InsidePrices from "../component/powers/Change_InsidePrices";

import { getPrices, setPrices } from "./api/powers/AdminChangeInsidePricesApi";

function AdminChangePices({ history }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
      getPrices(userInfo)
        .then((data) => setApiData(data))
        .catch((err) => alert(err));
    } else {
      history.push("/");
    }
  }, []);

  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div
              style={{ width: "300px", textAlign: "center" }}
              className="heading"
            >
              الصلاحيات
            </div>
            {apiData.length > 0 && (
              <Change_InsidePrices
                data={apiData}
                userInfo={userInfo}
                setFunc={setPrices}
              />
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminChangePices;
