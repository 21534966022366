import { Table } from "react-bootstrap";
function Jtable({ title, array, data }) {
  function Ntable() {
    let rows = [];
    let numrows = Object.keys(array).length;
    for (let i = 0; i < numrows; i += 3) {
      console.log("i");
      rows.push(
        <tr key={i}>
          <td className="fw-bold grayBG">{Object.keys(array)[i]}:</td>
          <td>{Object.values(array)[i]}</td>
          {Object.keys(array)[i + 1] ? (
            <td className="fw-bold grayBG">{Object.keys(array)[i + 1]}:</td>
          ) : (
            ""
          )}
          {Object.keys(array)[i + 1] ? (
            <td>{Object.values(array)[i + 1]}</td>
          ) : (
            ""
          )}
          {Object.keys(array)[i + 2] ? (
            <td className="fw-bold grayBG">{Object.keys(array)[i + 2]}:</td>
          ) : (
            ""
          )}
          {Object.keys(array)[i + 2] ? (
            <td>{Object.values(array)[i + 2]}</td>
          ) : (
            ""
          )}
        </tr>
      );
    }
    return rows;
  }
  function Jdata() {
    return Object.keys(data).map((key) => {
      return (
        <>
          ( {key} ) ( {data[key]} )
        </>
      );
    });
  }

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="2">
            {title} {data ? Jdata() : ""}
          </th>
        </tr>
      </thead>
      <tbody>{Ntable()}</tbody>
    </Table>
  );
}
export default Jtable;
