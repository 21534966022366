import React, { useEffect } from 'react';
import {
  Card, Col,
  Row
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { listTenders } from '../../actions/tenderActions';
import ControlPanelHeader from "../../component/ControlPanelHeader";
import BestCard from "../../component/new/bestCard";
import Paginate from '../../component/Paginate';
import SearchBox from '../../component/SearchBox';
import "../../styles/tender_list.css";


function TendersListScreen({ history }) {

  const dispatch = useDispatch()

  const tenderList = useSelector(state => state.tenderList)
  const { tenders, page, pages } = tenderList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let keyword = history.location.search

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    } else {
      if (!userInfo.is_activated) {
        history.push('/services')
      } else {
        dispatch(listTenders(keyword))
      }
    }

  }, [dispatch, history, userInfo, keyword])

  return (
    <div className="tender_page">
      <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest={'/collectServices'}
        style={"threetwo_section"}
      />
      <div className="box_serach">
        <Link to="/chooseTypeOfTender">
          <button className="add_btn">إضافة عرض</button>
        </Link>
      </div>
      <div className="tender_list" style={{ height: "800px" }}>
        <div className="heading text-center">قائمة العروض</div>
        <div className="scroll" style={{ marginTop: "55px" }}>
          <Row>
            <Col lg="3" className="search_bar_parent">
              <Card className="search_bar text-end">
                <div className="input_group">
                  <SearchBox link='/TendersList' />
                </div>
              </Card>
            </Col>
            <Col lg="9" className="mt-auto">
              {Object.keys(tenders).length > 0 ?
                (tenders.map((tender) => (
                  <BestCard
                    title={tender.title}
                    descreption={tender.details}
                    bigImage={tender.image}
                    link={`OrderDetailsTender/${tender.id}`}
                  />
                ))) : <div className="text-danger"><h2 className='text-center'> ! لايوجد نتائج </h2></div>
              }
            </Col>
          </Row>
          <Paginate page={page} pages={pages} keyword={keyword} link="/TendersList" />
        </div>
      </div>
    </div>
  )
}

export default TendersListScreen
