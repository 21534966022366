import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/newOrder.css";

import InvoiceTable from "../../component/new/components/InvoiceTable";
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import QRCode from "react-qr-code";
import Hdate from "../../component/new/components/Hdate";
import NewCard from "../../component/new/components/newCard";
import logo from "../../assets/logo.svg";
import social1 from "../../assets/social1.svg";
import social2 from "../../assets/social2.svg";
import social3 from "../../assets/social3.svg";
import social4 from "../../assets/social4.svg";
import {updateMedicalTeam, getMedicalTeamDetails} from '../../actions/serviceActions'


function PrintMedicalTeamScreen({ match, history }) {

  let img = [
    "https://via.placeholder.com/70x70/",
    "https://via.placeholder.com/71x71/",
    "https://via.placeholder.com/72x72/",
    "https://via.placeholder.com/73x73/",
  ];


  const [radioValue, setRadioValue] = useState("sell");
  const [radioValueN, setRadioValueN] = useState("new");
  const [bigImage, setBigImage] = useState(img[0]);

  let title1 = ["بيانات مقدم العرض"];
  let descreption =
    "  وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية وصف واضح وصريح للمنتج مكون من عدة أسطر و تفاصيل كافية";

  let data1 = {
    الاسم: "أحمد",
    العمر: 29,
    الجنس: "ذكر",
    البلد: "السعودية",
    الاسم1: "أحمد",
    العمر1: 29,
    الجنس1: "ذكر",
    البلد1: "السعودية",
    الاسم2: "أحمد",
    العمر2: 29,
    الجنس2: "ذكر",
    البلد2: "السعودية",
  };
  function handleClick() {
    window.print();
  }

  const retrieveDate = (d) => {
    let date = new Date(d);
    return date
  }



    const teamId = match.params.id

    const [name, setName] = useState('')

    const [id, setID] = useState('')

    const [en_Name, setEn_Name] = useState('')

    const [licence_No, setLicence_No] = useState('')

    const [photo1, setPhoto1] = useState(false)

    const [path1, setPath1] = useState('')

    const [wrong1, setWrong1] = useState('')

    const [photo2, setPhoto2] = useState(false)

    const [path2, setPath2] = useState('')

    const [wrong2, setWrong2] = useState('')

    const [photo3, setPhoto3] = useState(false)

    const [path3, setPath3] = useState('')

    const [wrong3, setWrong3] = useState('')

    const [start_Date, setStart_Date] = useState("")

    const [end_Date, setEnd_Date] = useState('')

    const [specialization, setSpecialization] = useState('')

    const [birth_Date, setBirth_Date] = useState("")

    const [id_endDate, setId_endDate] = useState('')

    const [phone_No, setPhone_No] = useState('')

    const [gender, setGender] = useState('')

    const [nationality, setNationality] = useState('')

    const [address, setAddress] = useState('')

    const [city, setCity] = useState('')

    const [IsDisplayed, setIsDisplayed] = useState(false)

    const [palceHolder, setPalceHolder] = useState(true)

    const [IsDisplayed1, setIsDisplayed1] = useState(false)

    const [palceHolder1, setPalceHolder1] = useState(true)

    const [postal_Code, setPostal_Code] = useState('')

    const [region, setRegion] = useState('')

    const [email, setEmail] = useState(null)

    const [emcertificate_Specializationail, setEmcertificate_Specializationail] = useState('')

    const [certifi_StartDate, setCertifi_StartDate] = useState('')

    const [certifi_EndDate, setCertifi_EndDate] = useState('')

    const [cover_Period, setCover_Period] = useState('')

    const [cover_Price, setCover_Price] = useState('')

    const [university, setUniversity] = useState('')

    const [organization_Name, setOrganization_Name] = useState('')

    const [status, setStatus] = useState('')

    const [have_Certifi, setHave_Certifi] = useState('')

    const [graduated_Date, setGraduated_Date] = useState('')

    const [Insurance_CertificateEndDate, setInsurance_CertificateEndDate] = useState('') 

    const [errors] = useState({
      allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",
    })

    const [errorsFlags, setErrorsFlags] = useState([false])

    const [uploading1, setUploading1] = useState(false)
    const [uploading2, setUploading2] = useState(false)
    const [uploading3, setUploading3] = useState(false)


    const dispatch = useDispatch()

    const medicalTeamDetails
    = useSelector(state => state.medicalTeamDetails)

    const {loading, medicalTeam, error } = medicalTeamDetails

    const medicalTeamUpdate
    = useSelector(state => state.medicalTeamUpdate)

    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = medicalTeamUpdate

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        } else {
          if(userInfo.is_evaluated) {
            if (medicalTeam.id !== Number(teamId)){
              dispatch(getMedicalTeamDetails(match.params.id))
          } else {
              setName(medicalTeam.ar_applicant_name);
              setEn_Name(medicalTeam.en_applicant_name);
              setID(medicalTeam.id_num);
              setLicence_No(medicalTeam.practical_license);
              setPhoto1(medicalTeam.practical_license_image);
              setStart_Date(retrieveDate(medicalTeam.license_issue_date));  
              setEnd_Date(retrieveDate(medicalTeam.license_expire_date));
              setSpecialization(medicalTeam.medical_specialist);
              setPhoto2(medicalTeam.applicant_image);
              setBirth_Date(retrieveDate(medicalTeam.birth_date));
              setId_endDate(retrieveDate(medicalTeam.id_expire_date));
              setPhone_No(medicalTeam.phone);
              setGender(medicalTeam.gender);
              setNationality(medicalTeam.nationality);
              setAddress(medicalTeam.address);
              setCity(medicalTeam.city);
              setPostal_Code(medicalTeam.postal_code);
              setRegion(medicalTeam.state);
              setEmail(medicalTeam.applicant_email);
              setEmcertificate_Specializationail(medicalTeam.specialize_certificate_image);
              setCertifi_StartDate(retrieveDate(medicalTeam.certificate_issue_date));
              setCertifi_EndDate(retrieveDate(medicalTeam.certificate_expire_date));
              setCover_Period(medicalTeam.coverage_duration);
              setCover_Price(medicalTeam.coverage_price);
              setUniversity(medicalTeam.university_name);
              setOrganization_Name(medicalTeam.employer_name);
              setStatus(medicalTeam.marital_status);
              setGraduated_Date(retrieveDate(medicalTeam.graduation_date));
              setInsurance_CertificateEndDate(retrieveDate(medicalTeam.insurance_certificate_expire_date));
              setPhoto3(medicalTeam.insurance_certificate_image);
            }
          }
        }      
    }
    
  }, [dispatch, history, userInfo, match, medicalTeam])


    return (

      <div className="w-100">
      <Container className="inv_a4 screen">
        <Button
          onClick={() => handleClick()}
          className="print_hide m-4 inv_button-print bg-secondary"
        >
          طباعة
        </Button>
        <div className="header_c" />
        <h1 className="inv_h1">إضافة عرض</h1>
        <img className="inv_img" alt="logo" src={logo} />
        <Hdate
          className="inv_Hdate_table"
          date="22/5/2021"
          code="25G54"
          codeTitle="كود العرض:"
        />
        <p className="inv_QR_title">رمز العرض:</p>
        <QRCode className="inv_QR" value={window.location.href} size="75" />
        <div className="inv_data">
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
        </div>
        <div className="inv_table">
          <Row>
            <Col lg="12">
              <InvoiceTable
                className="inv_table_from"
                title={title1}
                data={data1}
                type="4"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4">
              <NewCard
                img={img}
                title="العنوان"
                descreption={descreption}
                price="800"
                weight="50"
                radioValue={radioValue}
                radioValueN={radioValueN}
                bigImage={bigImage}
                handleButtons={(e) => setRadioValue(e)}
                handleButtonsN={(e) => setRadioValueN(e)}
                handleImages={(e) => setBigImage(e)}
              />
            </Col>
          </Row>
          <div className="new_boxs">
            <Row>
              <Col lg="6">
                <p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
              <Col lg="6">
                <p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
            </Row>
            <Row className="new_sign">
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="inv_footer">
          <span className="footer_text">الإيميل : info@bayan.med.sa</span>
          <div className="social_neworder_div">
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social4} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social3} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social2} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social1} />
            </a>
          </div>
        </div>
      </Container>
      <page size="A4" className="print">
        <div className="header_c" />
        <h1 className="inv_h1">إضافة طلب</h1>
        <img className="inv_img" alt="logo" src={logo} />
        <Hdate
          className="inv_Hdate_table"
          date="22/5/2021"
          code="25G54"
          codeTitle="كود العرض:"
        />
        <p className="inv_QR_title">كود التسجيل:</p>
        <QRCode className="inv_QR" value={window.location.href} size="75" />
        <div className="inv_data">
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
          <p>بيانات الشركة</p>
        </div>
        <div className="inv_table">
          <Row>
            <Col lg="12">
              <InvoiceTable
                className="inv_table_from"
                title={title1}
                data={data1}
                type="4"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-4">
              <NewCard
                img={img}
                title="العنوان"
                descreption={descreption}
                price="800"
                weight="50"
                radioValue={radioValue}
                radioValueN={radioValueN}
                bigImage={bigImage}
                handleButtons={(e) => setRadioValue(e)}
                handleButtonsN={(e) => setRadioValueN(e)}
                handleImages={(e) => setBigImage(e)}
              />
            </Col>
          </Row>
          <div className="new_boxs">
            <Row>
              <Col lg="6">
                <p className="new_box_h">ملاحظة موظف العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
              <Col lg="6">
                <p className="new_box_h">ملاحظة مدير العمليات بعد المراجعة:</p>
                <div className="new_box"></div>
              </Col>
            </Row>
            <Row className="new_sign">
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
              <Col lg="1">التوقيع:</Col>
              <Col lg="5">
                <div className="border-bottom mt-4" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="inv_footer">
          <span className="footer_text">الإيميل : info@bayan.med.sa</span>
          <div className="social_neworder_div">
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social4} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social3} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social2} />
            </a>
            <a href="http://www.bayan.med.sa/">
              <img className="social_neworder" alt="social" src={social1} />
            </a>
          </div>
        </div>
      </page>
    </div>
    )
}

export default PrintMedicalTeamScreen
