import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import Suspend_Account from "../component/powers/Suspend_Account";

function AdminChangeSuspendAccount({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div
              style={{ width: "300px", textAlign: "center" }}
              className="heading"
            >
              الصلاحيات
            </div>

            <Suspend_Account />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminChangeSuspendAccount;
