import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../component/Loader";
import Message from "../component/Message";
import Paginate from "../component/Paginate";
import "../styles/TableNewDesign.css";
import AdminLayout from "./layouts/AdminLayout";

function OldBillsAdmin({ history }) {
	const dispatch = useDispatch();

	const oldBills = useSelector((state) => state.oldBills);

	const { userInfo } = useSelector((state) => state.userLogin);

	let keyword = history.location.search;

	const [bills, setBills] = useState([]);

	/*
	operation_type   ("B", "شراء"),  ("S", "بيع"), 
	service نوع الخدمة
	invoice_pay_date تاريخ الدفع
	collect_method طريقة الدفع P T
	قيمة الفاتورة invoice_price
	مدة السداد negotiation_duration
	حالة الفاتورة invoice_paid
	الاجراءات
	 */
	useEffect(() => {
		if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
			axios.get('services/collect_invoices_old', {
				headers: {
					Authorization: `Bearer ${userInfo.access}`,
				  }
			}).then(d => {

			});
		} else {
			history.push("/");
		}
	}, [dispatch, history, userInfo, keyword]);

	return (
		<AdminLayout>
			<Row>
				<Col xl={12} md={12}>
					{oldBills?.loading ? (
						<Loader />
					) : (
						<Card className="code-table">
							<Card.Header>
								<Card.Title as="h5">قائمة الفواتير القديمة</Card.Title>
							</Card.Header>
							<Card.Body className="pb-0">
								<Table responsive hover>
									<thead>
										<tr>
											<th>كود المنشأة</th>
											<th>مبلغ الفاتورة</th>
											<th>نوع الفاتورة</th>
											<th>نوع الخدمة</th>
											<th>الحالة</th>
											<th>اجراءات</th>
										</tr>
									</thead>
									<tbody>
										{bills.map((obj) => (
											<tr key={obj.id}>
												<td>
													<p className="mb-1">{obj.payNo}</p>
												</td>
												<td>
													<p className="mb-1">{obj.opertaionType}</p>
												</td>
												<td>
													<p className="mb-1">{obj.paymentPeriod}</p>
												</td>
												<td>
													<p className="mb-1">{obj.billCondition}</p>
												</td>

												<td>
													<Button
														variant={
															obj.is_deleted === true
																? "danger"
																: obj.approved === true
																	? "success"
																	: "warning"
														}
														className={
															obj.is_deleted === true
																? "label btn-danger btn-sm f-12 text-white"
																: obj.approved === true
																	? "label btn-success btn-sm f-12 text-white"
																	: "label btn-warning btn-sm f-12 text-white"
														}
													>
														{obj.is_deleted === true
															? "تم حذفه"
															: obj.approved === true
																? "مفعل"
																: "غير مفعل"}
													</Button>
												</td>

												{!obj.is_deleted && (
													<Button
														className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100">
														تنزيل الفاتورة</Button>)}
											</tr>
										))}
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					)}
				</Col>
			</Row>
			<Paginate
				page={oldBills?.page}
				pages={oldBills?.pages}
				keyword={keyword}
				link="/comparingMedicalMistakeWork"
			/>
		</AdminLayout>
	);
}

export default OldBillsAdmin;
