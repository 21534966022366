import {
    EVALUATION_DATA_ADD_REQUEST,
    EVALUATION_DATA_ADD_SUCCESS,
    EVALUATION_DATA_ADD_FAIL,

    EVALUATION_DETAILS_REQUEST,
    EVALUATION_DETAILS_SUCCESS,
    EVALUATION_DETAILS_FAIL,

    EVALUATION_UPGRADE_REQUEST,
    EVALUATION_UPGRADE_SUCCESS,
    EVALUATION_UPGRADE_FAIL,

    MEDICAL_DEVICE_ADD_REQUEST,
    MEDICAL_DEVICE_ADD_SUCCESS,
    MEDICAL_DEVICE_ADD_FAIL,

    MEDICAL_DEVICE_LIST_REQUEST,
    MEDICAL_DEVICE_LIST_SUCCESS,
    MEDICAL_DEVICE_LIST_FAIL,

    MEDICAL_DEVICE_DETAILS_REQUEST,
    MEDICAL_DEVICE_DETAILS_SUCCESS,
    MEDICAL_DEVICE_DETAILS_FAIL,

    MEDICAL_DEVICE_ADD_OFFER_REQUEST,
    MEDICAL_DEVICE_ADD_OFFER_SUCCESS,
    MEDICAL_DEVICE_ADD_OFFER_FAIL,

    DOCTORS_CLINICS_ADD_REQUEST,
    DOCTORS_CLINICS_ADD_SUCCESS,
    DOCTORS_CLINICS_ADD_FAIL,

    DOCTORS_CLINICS_LIST_REQUEST,
    DOCTORS_CLINICS_LIST_SUCCESS,
    DOCTORS_CLINICS_LIST_FAIL,

    DOCTORS_CLINICS_DETAILS_REQUEST, 
    DOCTORS_CLINICS_DETAILS_SUCCESS, 
    DOCTORS_CLINICS_DETAILS_FAIL,

    DOCTORS_CLINICS_ADD_OFFER_REQUEST,
    DOCTORS_CLINICS_ADD_OFFER_SUCCESS,
    DOCTORS_CLINICS_ADD_OFFER_FAIL,

    LABORATORIES_ADD_REQUEST,
    LABORATORIES_ADD_SUCCESS,
    LABORATORIES_ADD_FAIL,

    LABORATORIES_LIST_REQUEST,
    LABORATORIES_LIST_SUCCESS,
    LABORATORIES_LIST_FAIL,

    LABORATORIES_DETAILS_REQUEST,
    LABORATORIES_DETAILS_SUCCESS,
    LABORATORIES_DETAILS_FAIL,

    LABORATORIES_ADD_OFFER_REQUEST,
    LABORATORIES_ADD_OFFER_SUCCESS,
    LABORATORIES_ADD_OFFER_FAIL,

    RAYS_LIST_REQUEST,
    RAYS_LIST_SUCCESS,
    RAYS_LIST_FAIL,

    RAYS_ADD_OFFER_REQUEST, 
    RAYS_ADD_OFFER_SUCCESS, 
    RAYS_ADD_OFFER_FAIL,

    RAYS_ADD_REQUEST, 
    RAYS_ADD_SUCCESS, 
    RAYS_ADD_FAIL,

    RAYS_DETAILS_REQUEST,
    RAYS_DETAILS_SUCCESS,
    RAYS_DETAILS_FAIL,

    MEDICAL_TEAM_DATA_ADD_REQUEST,
    MEDICAL_TEAM_DATA_ADD_SUCCESS,
    MEDICAL_TEAM_DATA_ADD_FAIL,

    MEDICAL_TEAM_DATA_LIST_REQUEST,
    MEDICAL_TEAM_DATA_LIST_SUCCESS,
    MEDICAL_TEAM_DATA_LIST_FAIL,

    MEDICAL_TEAM_DATA_LIST_ALL_REQUEST,
    MEDICAL_TEAM_DATA_LIST_ALL_SUCCESS,
    MEDICAL_TEAM_DATA_LIST_ALL_FAIL,

    MEDICAL_TEAM_DETAILS_REQUEST,
    MEDICAL_TEAM_DETAILS_SUCCESS,
    MEDICAL_TEAM_DETAILS_FAIL,

    MEDICAL_TEAM_UPDATE_REQUEST,
    MEDICAL_TEAM_UPDATE_SUCCESS,
    MEDICAL_TEAM_UPDATE_FAIL,
    MEDICAL_TEAM_UPDATE_RESET,

    MEDICAL_TEAM_DELETE_REQUEST,
    MEDICAL_TEAM_DELETE_SUCCESS,
    MEDICAL_TEAM_DELETE_FAIL,

    MEDICAL_TEAM_INSURRENCE_REQUEST, 
    MEDICAL_TEAM_INSURRENCE_SUCCESS,
    MEDICAL_TEAM_INSURRENCE_FAIL,

    COLLECT_INVOICES_LIST_REQUEST,
    COLLECT_INVOICES_LIST_SUCCESS,
    COLLECT_INVOICES_LIST_FAIL,

    COLLECT_INVOICES_OLD_LIST_REQUEST,
    COLLECT_INVOICES_OLD_LIST_SUCCESS,
    COLLECT_INVOICES_OLD_LIST_FAIL,

    COLLECT_INVOICES_NEW_LIST_REQUEST,
    COLLECT_INVOICES_NEW_LIST_SUCCESS,
    COLLECT_INVOICES_NEW_LIST_FAIL,

    INSURRENCE_ADD_OFFER_REQUEST, 
    INSURRENCE_ADD_OFFER_SUCCESS, 
    INSURRENCE_ADD_OFFER_FAIL,

    INSURRENCE_OFFER_UPDATE_REQUEST,
    INSURRENCE_OFFER_UPDATE_SUCCESS,
    INSURRENCE_OFFER_UPDATE_FAIL,
    INSURRENCE_OFFER_UPDATE_RESET,

    INSURRENCE_OFFERS_LIST_REQUEST,
    INSURRENCE_OFFERS_LIST_SUCCESS, 
    INSURRENCE_OFFERS_LIST_FAIL,

    INSURRENCE_OFFER_DETAILS_REQUEST,
    INSURRENCE_OFFER_DETAILS_SUCCESS,
    INSURRENCE_OFFER_DETAILS_FAIL,
    INSURRENCE_OFFER_DETAILS_RESET,

    INVOICE_ADD_OFFER_REQUEST,
    INVOICE_ADD_OFFER_SUCCESS,
    INVOICE_ADD_OFFER_FAIL,

} from '../constants/serviceConstants'


export const serviceAddEvaluationReducer = (state = { }, action) => {
    switch (action.type) {

        case EVALUATION_DATA_ADD_REQUEST:
            return { loading: true }
        
        case EVALUATION_DATA_ADD_SUCCESS:
            return { loading: false, success: true, evaluationData:action.payload }
        
        case EVALUATION_DATA_ADD_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalDeviceAddReducer = (state = { }, action) => {
    switch (action.type) {

        case MEDICAL_DEVICE_ADD_REQUEST:
            return { loading: true }
        
        case MEDICAL_DEVICE_ADD_SUCCESS:
            return { loading: false, success: true, medicalDeviceAddData:action.payload }
        
        case MEDICAL_DEVICE_ADD_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const evaluationDetailsReducer = ( state = {loading:true, evaluationInfo:{}}, action) => {
    switch(action.type) {
        case EVALUATION_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case EVALUATION_DETAILS_SUCCESS:
            return {
                loading: false,
                evaluationInfo: action.payload
            }

        case EVALUATION_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
            
        default:
            return state
    }
}

export const evaluationUpgradeReducer = (state = { }, action) => {
    switch (action.type) {

        case EVALUATION_UPGRADE_REQUEST:
            return { loading: true }
        
        case EVALUATION_UPGRADE_SUCCESS:
            return { loading: false, success: true, upgradeData:action.payload }
        
        case EVALUATION_UPGRADE_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalDeviceListReducer = (state = { devices: [] }, action) => {
    switch (action.type) {

        case MEDICAL_DEVICE_LIST_REQUEST:
            return { loading: true, devices: [] }
        
        case MEDICAL_DEVICE_LIST_SUCCESS:
            return { 
                loading: false,
                devices: action.payload.devices,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case MEDICAL_DEVICE_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalDeviceDetailsReducer = (state = { loading:true, device: {}, facility:{} }, action) => {
    switch (action.type) {

        case MEDICAL_DEVICE_DETAILS_REQUEST:
            return { loading: true, ...state }
        
        case MEDICAL_DEVICE_DETAILS_SUCCESS:
            return { loading: false, device: action.payload }
        
        case MEDICAL_DEVICE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalDeviceAddOfferReducer = (state = { }, action) => {
    switch (action.type) {

        case MEDICAL_DEVICE_ADD_OFFER_REQUEST:
            return { loading: true }
        
        case MEDICAL_DEVICE_ADD_OFFER_SUCCESS:
            return { loading: false, success: true, offerData:action.payload }
        
        case MEDICAL_DEVICE_ADD_OFFER_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const doctorsClinicsAddReducer = (state = { }, action) => {
    switch (action.type) {

        case DOCTORS_CLINICS_ADD_REQUEST:
            return { loading: true }
        
        case DOCTORS_CLINICS_ADD_SUCCESS:
            return { loading: false, success: true, doctorsClinicsAddData:action.payload }
        
        case DOCTORS_CLINICS_ADD_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalDoctorsClinicsListReducer = (state = { doctorsClinics: [] }, action) => {
    switch (action.type) {

        case DOCTORS_CLINICS_LIST_REQUEST:
            return { loading: true, doctorsClinics: [] }
        
        case DOCTORS_CLINICS_LIST_SUCCESS:
            return { 
                loading: false,
                doctorsClinics: action.payload.doctorsClinics,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case DOCTORS_CLINICS_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const doctorsClinicsDetailsReducer = (state = {loading:true, doctorClinic: {}, facility: {}, sector_type:{} }, action) => {
    switch (action.type) {

        case DOCTORS_CLINICS_DETAILS_REQUEST:
            return { loading: true, ...state }
        
        case DOCTORS_CLINICS_DETAILS_SUCCESS:
            return { loading: false, doctorClinic: action.payload }
        
        case DOCTORS_CLINICS_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const doctorsClinicsAddOfferReducer = (state = { }, action) => {
    switch (action.type) {

        case DOCTORS_CLINICS_ADD_OFFER_REQUEST:
            return { loading: true }
        
        case DOCTORS_CLINICS_ADD_OFFER_SUCCESS:
            return { loading: false, success: true, offerData:action.payload }
        
        case DOCTORS_CLINICS_ADD_OFFER_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const laboratoriesAddReducer = (state = { }, action) => {
    switch (action.type) {

        case LABORATORIES_ADD_REQUEST:
            return { loading: true }
        
        case LABORATORIES_ADD_SUCCESS:
            return { loading: false, success: true, laboratoriesAddData:action.payload }
        
        case LABORATORIES_ADD_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const laboatoriesListReducer = (state = { laboratories: [] }, action) => {
    switch (action.type) {

        case LABORATORIES_LIST_REQUEST:
            return { loading: true, laboratories: [] }
        
        case LABORATORIES_LIST_SUCCESS:
            return { 
                loading: false,
                laboratories: action.payload.laboratories,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case LABORATORIES_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const laboratoryDetailsReducer = (state = {loading:true, laboratory: {}, facility: {}, sector_type:{} }, action) => {
    switch (action.type) {

        case LABORATORIES_DETAILS_REQUEST:
            return { loading: true, ...state }
        
        case LABORATORIES_DETAILS_SUCCESS:
            return { loading: false, laboratory: action.payload }
        
        case LABORATORIES_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const laboratoriesAddOfferReducer = (state = { }, action) => {
    switch (action.type) {

        case LABORATORIES_ADD_OFFER_REQUEST:
            return { loading: true }
        
        case LABORATORIES_ADD_OFFER_SUCCESS:
            return { loading: false, success: true, offerData:action.payload }
        
        case LABORATORIES_ADD_OFFER_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const raysListReducer = (state = { rays: [] }, action) => {
    switch (action.type) {

        case RAYS_LIST_REQUEST:
            return { loading: true, rays: [] }
        
        case RAYS_LIST_SUCCESS:
            return { 
                loading: false,
                rays: action.payload.rays,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case RAYS_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const raysAddReducer = (state = { }, action) => {
    switch (action.type) {

        case RAYS_ADD_REQUEST:
            return { loading: true }
        
        case RAYS_ADD_SUCCESS:
            return { loading: false, success: true, raysAddData:action.payload }
        
        case RAYS_ADD_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const rayDetailsReducer = (state = {loading:true, ray: {}, facility: {}, sector_type:{} }, action) => {
    switch (action.type) {

        case RAYS_DETAILS_REQUEST:
            return { loading: true, ...state }
        
        case RAYS_DETAILS_SUCCESS:
            return { loading: false, ray: action.payload }
        
        case RAYS_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const raysAddOfferReducer = (state = { }, action) => {
    switch (action.type) {

        case RAYS_ADD_OFFER_REQUEST:
            return { loading: true }
        
        case RAYS_ADD_OFFER_SUCCESS:
            return { loading: false, success: true, offerData:action.payload }
        
        case RAYS_ADD_OFFER_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalTeamAddReducer = (state = { }, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_DATA_ADD_REQUEST:
            return { loading: true }
        
        case MEDICAL_TEAM_DATA_ADD_SUCCESS:
            return { loading: false, success: true, medicalTeamData:action.payload }
        
        case MEDICAL_TEAM_DATA_ADD_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalTeamListReducer = (state = { medicalTeam: [] }, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_DATA_LIST_REQUEST:
            return { loading: true, medicalTeam: [] }
        
        case MEDICAL_TEAM_DATA_LIST_SUCCESS:
            return { 
                loading: false,
                medicalTeam: action.payload.medicalTeam,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case MEDICAL_TEAM_DATA_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalTeamAllListReducer = (state = { medicalTeam: [] }, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_DATA_LIST_ALL_REQUEST:
            return { loading: true, medicalTeam: [] }
        
        case MEDICAL_TEAM_DATA_LIST_ALL_SUCCESS:
            return { 
                loading: false,
                medicalTeam: action.payload.medicalTeam,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case MEDICAL_TEAM_DATA_LIST_ALL_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalTeamDetailsReducer = (state = { loading:true, medicalTeam: {} }, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_DETAILS_REQUEST:
            return { loading: true, ...state }
        
        case MEDICAL_TEAM_DETAILS_SUCCESS:
            return { loading: false, medicalTeam: action.payload }
        
        case MEDICAL_TEAM_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalTeamUpdateReducer = (state = { medicalTeam: {} }, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_UPDATE_REQUEST:
            return { loading: true}
        
        case MEDICAL_TEAM_UPDATE_SUCCESS:
            return { loading: false, success: true, medicalTeam:action.payload }
        
        case MEDICAL_TEAM_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        
        case MEDICAL_TEAM_UPDATE_RESET:
            return {}

        default:
            return state
    }
}

export const medicalTeamDeleteReducer = (state = {}, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_DELETE_REQUEST:
            return { loading: true}
        
        case MEDICAL_TEAM_DELETE_SUCCESS:
            return { loading: false, success: true }
        
        case MEDICAL_TEAM_DELETE_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalTeamRequestInsurrenceReducer = (state = {}, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_INSURRENCE_REQUEST:
            return { loading: true}
        
        case MEDICAL_TEAM_INSURRENCE_SUCCESS:
            return { loading: false, success: true }
        
        case MEDICAL_TEAM_INSURRENCE_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const collectInvoiceListReducer = (state = { collectInvoices: [] }, action) => {
    switch (action.type) {

        case COLLECT_INVOICES_LIST_REQUEST:
            return { loading: true, collectInvoices: [] }
        
        case COLLECT_INVOICES_LIST_SUCCESS:
            return { 
                loading: false,
                collectInvoices: action.payload.collectInvoices,
                page: action.payload.page,
                pages: action.payload.pages,
                collectInvoicesNewPaid: action.payload.collectInvoicesNewPaid,
                collectInvoicesNewNotPaid: action.payload.collectInvoicesNewNotPaid,
                collectInvoicesOldPaid: action.payload.collectInvoicesOldPaid,
                collectInvoicesOldNotPaid: action.payload.collectInvoicesOldNotPaid,
                collectInvoiceCount: action.payload.collectInvoiceCount
            }
        
        case COLLECT_INVOICES_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const collectInvoiceOldListReducer = (state = { collectInvoices: [] }, action) => {
    switch (action.type) {

        case COLLECT_INVOICES_OLD_LIST_REQUEST:
            return { loading: true, collectInvoices: [] }
        
        case COLLECT_INVOICES_OLD_LIST_SUCCESS:
            return { 
                loading: false,
                collectInvoices: action.payload.collectInvoices,
                page: action.payload.page,
                pages: action.payload.pages,
            }
        
        case COLLECT_INVOICES_OLD_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const collectInvoiceNewListReducer = (state = { collectInvoices: [] }, action) => {
    switch (action.type) {

        case COLLECT_INVOICES_NEW_LIST_REQUEST:
            return { loading: true, collectInvoices: [] }
        
        case COLLECT_INVOICES_NEW_LIST_SUCCESS:
            return { 
                loading: false,
                collectInvoices: action.payload.collectInvoices,
                page: action.payload.page,
                pages: action.payload.pages,
            }
        
        case COLLECT_INVOICES_NEW_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const insurrenceAddOfferReducer = (state = { }, action) => {
    switch (action.type) {

        case INSURRENCE_ADD_OFFER_REQUEST:
            return { loading: true }
        
        case INSURRENCE_ADD_OFFER_SUCCESS:
            return { loading: false, success: true, offerData:action.payload }
        
        case INSURRENCE_ADD_OFFER_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const insurrenceOfferUpdateReducer = (state = { insurrenceOffer: {} }, action) => {
    switch (action.type) {

        case INSURRENCE_OFFER_UPDATE_REQUEST:
            return { loading: true}
        
        case INSURRENCE_OFFER_UPDATE_SUCCESS:
            return { loading: false, success: true, insurrenceOffer:action.payload }
        
        case INSURRENCE_OFFER_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        
        case INSURRENCE_OFFER_UPDATE_RESET:
            return {}

        default:
            return state
    }
}

export const InsurrenceOffersListReducer = (state = { offers: [] }, action) => {
    switch (action.type) {

        case INSURRENCE_OFFERS_LIST_REQUEST:
            return { loading: true, offers: [] }
        
        case INSURRENCE_OFFERS_LIST_SUCCESS:
            return { 
                loading: false,
                offers: action.payload.offers,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case INSURRENCE_OFFERS_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const insurrenceOfferDetailsReducer = (state = { offer: {} }, action) => {
    switch (action.type) {

        case INSURRENCE_OFFER_DETAILS_REQUEST:
            return { loading: true}
        
        case INSURRENCE_OFFER_DETAILS_SUCCESS:
            return { loading: false, success: true, offer:action.payload }
        
        case INSURRENCE_OFFER_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        
        case INSURRENCE_OFFER_DETAILS_RESET:
            return {}

        default:
            return state
    }
}

export const invoiceAddForOfferReducer = (state = { }, action) => {
    switch (action.type) {

        case INVOICE_ADD_OFFER_REQUEST:
            return { loading: true }
        
        case INVOICE_ADD_OFFER_SUCCESS:
            return { loading: false, success: true, invoice:action.payload }
        
        case INVOICE_ADD_OFFER_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

