import React, { Component } from "react";
import "../../styles/change_endorsement.css";
import arrow from "../../imgs/arrow.png";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";
class Change_Endorsement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      endorsement: "",
      showModal: false,
    };
  }

  submitHandler = () => {
    this.setState({ showModal: true });
  };

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  render() {
    const { endorsement } = this.state;

    return (
      <div className="change_endorsement">
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">يمكنكم تعديل النص ادناه</label>
            <textarea
              type="text"
              id="endorsement"
              name="endorsement"
              value={endorsement}
              onChange={this.changeHandler}
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
        </div>

        <div className="text-center d-flex justify-content-center">
          <Button className="text-center btnNext" onClick={this.submitHandler}>
            <img src={arrow} alt="" />
            تنفيذ
          </Button>
        </div>
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Change_Endorsement;
