import React, { Component } from "react";
import arrow from "../../imgs/arrow.png";
import "../../styles/change_insidePrices.css";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../adminScreen/components/Modal/ConfirmationModal";
class Change_InsidePrices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      free_From: this.props.data[0]["amount"],
      free_To: this.props.data[0]["amount"],
      smallFacility_From: this.props.data[1]["amount"],
      smallFacility_to: this.props.data[1]["amount"],
      mediumFaciliry_From: this.props.data[4]["amount"],
      mediumFaciliry_To: this.props.data[4]["amount"],
      bigFacility_From: this.props.data[2]["amount"],
      bigFacility_To: this.props.data[2]["amount"],
      xlFacility_From: this.props.data[3]["amount"],
      xlFacility_To: this.props.data[3]["amount"],
      showModal: false,
    };
  }

  submitHandler = () => {
    console.log("submitted");
    const copiedData = [];
    copiedData[0] = +this.state.free_To;
    copiedData[1] = +this.state.smallFacility_to;
    copiedData[4] = +this.state.mediumFaciliry_To;
    copiedData[2] = +this.state.bigFacility_To;
    copiedData[3] = +this.state.xlFacility_To;

    for (let i = 0; i < this.props.data.length; i++) {
      console.log(this.props.data[i], copiedData[i]);
      if (this.props.data[i].amount !== copiedData[i]) {
        this.props.data[i].amount = copiedData[i];
        this.props.setFunc(this.props.data[i], this.props.userInfo);
      }
    }
  };

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  render() {
    const {
      free_From,
      free_To,
      smallFacility_From,
      smallFacility_to,
      mediumFaciliry_From,
      mediumFaciliry_To,
      bigFacility_From,
      bigFacility_To,
      xlFacility_From,
      xlFacility_To,
    } = this.state;

    const { data } = this.props;

    return (
      <div className="change_inside_prices">
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الي</label>
            <input
              type="text"
              id="free_To"
              name="free_To"
              value={free_To}
              onChange={this.changeHandler}
              placeholder="مجاني"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="input-data">
            <label htmlFor="name">من</label>
            <input
              readOnly={true}
              type="text"
              id="free_From"
              name="free_From"
              value={free_From}
              onChange={this.changeHandler}
              placeholder=" اكتب المطلوب"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>

          <div className="title">بلا مقابل</div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الي</label>
            <input
              type="text"
              id="smallFacility_to"
              name="smallFacility_to"
              value={smallFacility_to}
              onChange={this.changeHandler}
              placeholder="ريال سعودى 1,800"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="input-data">
            <label htmlFor="name">من</label>
            <input
              readOnly={true}
              type="text"
              id="smallFacility_From"
              name="smallFacility_From"
              value={smallFacility_From}
              onChange={this.changeHandler}
              placeholder=" اكتب المطلوب"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>

          <div className="title">منشأه صغيره</div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الي</label>
            <input
              type="text"
              id="mediumFaciliry_To"
              name="mediumFaciliry_To"
              value={mediumFaciliry_To}
              onChange={this.changeHandler}
              placeholder="ريال سعودى 4,200"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="input-data">
            <label htmlFor="name">من</label>
            <input
              readOnly={true}
              type="text"
              id="mediumFaciliry_From"
              name="mediumFaciliry_From"
              value={mediumFaciliry_From}
              onChange={this.changeHandler}
              placeholder=" اكتب المطلوب"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="title">منشأه متوسطه</div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الي</label>
            <input
              type="text"
              id="bigFacility_To"
              name="bigFacility_To"
              value={bigFacility_To}
              onChange={this.changeHandler}
              placeholder="ريال سعودى 9,000"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="input-data">
            <label htmlFor="name">من</label>
            <input
              readOnly={true}
              type="text"
              id="bigFacility_From"
              name="bigFacility_From"
              value={bigFacility_From}
              onChange={this.changeHandler}
              placeholder=" اكتب المطلوب"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="title">منشأه كبيره</div>
        </div>
        <div className="item reverse">
          <div className="input-data">
            <label htmlFor="name">الي</label>
            <input
              type="text"
              id="xlFacility_To"
              name="xlFacility_To"
              value={xlFacility_To}
              onChange={this.changeHandler}
              placeholder="ريال سعودى 12,000"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="input-data">
            <label htmlFor="name">من</label>
            <input
              readOnly={true}
              type="text"
              id="xlFacility_From"
              name="xlFacility_From"
              value={xlFacility_From}
              onChange={this.changeHandler}
              placeholder=" اكتب المطلوب"
              onFocus={this.focusHandler}
              onBlur={this.focusoutHandler}
            />
          </div>
          <div className="title">منشأه ضخمة</div>
        </div>

        <div className="text-center d-flex justify-content-center">
          <Button className="text-center btnNext" onClick={this.submitHandler}>
            <img src={arrow} alt="" />
            تنفيذ
          </Button>
        </div>
        {this.state.showModal ? (
          <ConfirmationModal
            title="الصلاحيات"
            body="تم انهاء العملية بنجاح"
            onClosing={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Change_InsidePrices;
