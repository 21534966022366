import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

function SearchBox({link}) {
    const [keyword, setKeyword] = useState('')

    let history = useHistory()

    const submitHandler = (e) => {
        e.preventDefault()

        if(keyword){
            history.push(`${link}?keyword=${keyword}&page=1`)
        } else {
            //history.push(history.push(history.location.pathname))
        }
    }

    return (
        <div className='search-box'>
            <form onSubmit={submitHandler}>
                <input
                    type="text"
                    placeholder="البحث "
                    name = 'q'
                    onChange = { (e) => setKeyword(e.target.value)}
                />
                <button className="btn btn-success">إبحث</button>
            </form>
        </div>
        
    )
}

export default SearchBox
