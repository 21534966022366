import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/negotiation.css";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import "../../styles/collect_service.css";
import { Link } from "react-router-dom";
import SearchBox from '../../component/SearchBox'
import {Button, Table, Row, Form} from "react-bootstrap";

import {listCollectInvoicesNew} from  '../../actions/serviceActions'


function NewBills({ history }) {

    const [selectValue, setSelectValue] = useState("");
      
    const titles = [
      "رقم الفاتورة",
      "نوع العملية",
      "الخدمة",
      "تاريخ الدفع",
      "طريقة الدفع",
      "قيمة الفاتورة",
      "مدة السداد",
      "حالة الفاتورة",
    ];

    const more = {
      title: "الاجراءات",
      actions: {
        طباعة: "",
        "اضافة سعر": "negotiate",
        تفاوض: "Negotiation",
      },
    };
  
  
        
    const dispatch = useDispatch()

    const collectInvoiceNewList = useSelector(state => state.collectInvoiceNewList)
    const {error, loading, collectInvoices, page, pages} = collectInvoiceNewList

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    let keyword = history.location.search

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        } else {
          dispatch(listCollectInvoicesNew(keyword))        
        }     
      }
    
    }, [dispatch, history, userInfo, keyword])

    const handleChange = (e) => {
      setSelectValue(e.target.value);
    }

    const handleMore = (id) => {
      return Object.keys(more.actions).map((key, index) => {
        let path = Object.values(more.actions)[index] + '/' + id;
        
        return <option value={path} key={index}>{key}</option>;
      });
    }

    return (
      <div className="negotiation_page">
        <ControlPanelHeader
          exit1={true}
          notifi={true}
          msg={true}
          files={false}
          stat={false}
          msgDest={"/Messages"}
          notifidest={"/Notifications"}
          exitDest={"/services"}
          fileDest={"/FacilityFiles"}
          statDest = {'/collectServices'}
          style={"threetwo_section"}
        />

        <div
            style={{
              position: "relative",
              backgroundColor: "#eee",
              height: "134px",
              marginTop: "0",
              paddingBottom: "20px",
              marginBottom: "0",
            }} 
          className="header_title"
        >
          <div className="input_group">
          <SearchBox link='/collectServices'/>

          </div>
          <div className="button_new">
          <Link to="/OldBills">
              <Button className="button_new_1" style={{ backgroundColor: "#ababab" }}>
                قائمة الفواتير القديمة
              </Button>
              </Link>
            <Link to="/NewBills">
              <Button className="button_new_2 " style={{ backgroundColor: "var(--main-color)" }}>قائمة الفواتير الجديدة</Button>
            </Link>
          </div>
        </div>

        <div
          className="nego_list"
          style={{ width: "1600px", height: "446px", margin: "55px auto 35px" }}
        >
          <div className="heading text-center">قائمة الفواتير الجديدة</div>

          <div
            className="nego_table scroll"
            style={{ width: "1525px", height: "339px" }}
          >
            <Table bordered hover className="TableNewDesign">
              <thead>
                <tr>
                {titles.map((title, x) => (
                    <th key={x+1}>{title}</th>
                  ))
                }
                
                  
                </tr>
              </thead>

              <tbody>
                  {collectInvoices.map((obj) => (
                      <tr key={obj.id}>
                        <td>IN-{obj.invoice_num}</td>
                        <td>
                          {
                            obj.operation_type === "B" ?
                            'بيع' : 'شراء'
                          }</td>
                        <td>{obj.service.service_name}</td>
                        <td>{obj.invoice_pay_date}</td>
                        <td>
                          {
                            obj.collect_method === "P" ?
                            'دفعة واحدة' : 'دفعتين'
                          }
                        </td>
                        <td>{obj.invoice_price} SR</td>
                        <td>{obj.negotiation_duration} سنوات</td>
                        <td>
                          {
                            obj.collect_invoice_type === true ?
                            ' مدفوعة' : ' غير مدفوعة'
                          }
                        </td>
                        {more ? (
                          <td className="actions">
                            <Row className="m-2">
                              <Form.Select
                                onChange={handleChange}
                                className="text-center px-1 pe-2"
                              >
                                <option>
                                  أختر
                                </option>
                                {handleMore(obj.id)}
                              </Form.Select>                              
                              <Link to={selectValue} className="btn-outline-main btn-outline-info btn">
                              تنفيذ
                              </Link>                                               
                            </Row>
                          </td>                          
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  }                
                </tbody>
            </Table>
          </div>
        </div>
      </div>
    )
}

export default NewBills
