export const MEDICAL_TEAM_LIST_FOR_ADMIN_REQUEST = 'MEDICAL_TEAM_LIST_FOR_ADMIN_REQUEST'
export const MEDICAL_TEAM_LIST_FOR_ADMIN_SUCCESS = 'MEDICAL_TEAM_LIST_FOR_ADMIN_SUCCESS'
export const MEDICAL_TEAM_LIST_FOR_ADMIN_FAIL = 'MEDICAL_TEAM_LIST_FOR_ADMIN_FAIL'

export const MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_REQUEST = 'MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_REQUEST'
export const MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_SUCCESS = 'MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_SUCCESS'
export const MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_FAIL = 'MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_FAIL'

export const FACILITY_LIST_FOR_ADMIN_REQUEST = 'FACILITY_LIST_FOR_ADMIN_REQUEST'
export const FACILITY_LIST_FOR_ADMIN_SUCCESS = 'FACILITY_LIST_FOR_ADMIN_SUCCESS'
export const FACILITY_LIST_FOR_ADMIN_FAIL = 'FACILITY_LIST_FOR_ADMIN_FAIL'

export const FACILITY_DETAILS_FOR_ADMIN_REQUEST = 'FACILITY_DETAILS_FOR_ADMIN_REQUEST'
export const FACILITY_DETAILS_FOR_ADMIN_SUCCESS = 'FACILITY_DETAILS_FOR_ADMIN_SUCCESS'
export const FACILITY_DETAILS_FOR_ADMIN_FAIL = 'FACILITY_DETAILS_FOR_ADMIN_FAIL'

export const FACILITY_UPDATE_FOR_ADMIN_REQUEST = 'FACILITY_UPDATE_FOR_ADMIN_REQUEST'
export const FACILITY_UPDATE_FOR_ADMIN_SUCCESS = 'FACILITY_DETAILS_FOR_ADMIN_SUCCESS'
export const FACILITY_UPDATE_FOR_ADMIN_FAIL = 'FACILITY_UPDATE_FOR_ADMIN_FAIL'

export const INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_REQUEST = 'INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_REQUEST'
export const INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_SUCCESS = 'INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_SUCCESS'
export const INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_FAIL = 'INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_FAIL'

export const OFFER_DETAILS_FOR_ADMIN_REQUEST = 'OFFER_DETAILS_FOR_ADMIN_REQUEST'
export const OFFER_DETAILS_FOR_ADMIN_SUCCESS = 'OFFER_DETAILS_FOR_ADMIN_SUCCESS'
export const OFFER_DETAILS_FOR_ADMIN_FAIL = 'OFFER_DETAILS_FOR_ADMIN_FAIL'

export const EVALUATION_DETAILS_FOR_ADMIN_REQUEST = 'EVALUATION_DETAILS_FOR_ADMIN_REQUEST'
export const EVALUATION_DETAILS_FOR_ADMIN_SUCCESS = 'EVALUATION_DETAILS_FOR_ADMIN_SUCCESS'
export const EVALUATION_DETAILS_FOR_ADMIN_FAIL = 'EVALUATION_DETAILS_FOR_ADMIN_FAIL'

export const EVALUATION_LIST_FOR_ADMIN_REQUEST = 'EVALUATION_LIST_FOR_ADMIN_REQUEST'
export const EVALUATION_LIST_FOR_ADMIN_SUCCESS = 'EVALUATION_LIST_FOR_ADMIN_SUCCESS'
export const EVALUATION_LIST_FOR_ADMIN_FAIL = 'EVALUATION_LIST_FOR_ADMIN_FAIL'
























export const BOX_LAYOUT = 'BOX_LAYOUT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const RESET = 'RESET';
export const RTL_LAYOUT = 'RTL_LAYOUT';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALISE = 'ACCOUNT_INITIALISE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';