import axios from "axios";

export const approve_DevicesRequest = async (id, user) => {
  const request = await axios.get(`/services/approve_DevicesRequest/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_DevicesOffer = async (id, user) => {
  const request = await axios.get(`/services/approve_DevicesOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_ClinicsRequest = async (id, user) => {
  const request = await axios.get(`/services/approve_ClinicsRequest/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_ClinicsOffer = async (id, user) => {
  const request = await axios.get(`/services/approve_ClinicsOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_LabsRequests = async (id, user) => {
  const request = await axios.get(`/services/approve_LabsRequests/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_LabsOffer = async (id, user) => {
  const request = await axios.get(`/services/approve_LabsOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_RadiologyRequestsService = async (id, user) => {
  const request = await axios.get(
    `/services/approve_RadiologyRequestsService/${id}/`,
    {
      headers: { Authorization: `Bearer ${user.access}` },
    }
  );
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_RadiologyOffer = async (id, user) => {
  const request = await axios.get(`/services/approve_RadiologyOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_Tender = async (id, user) => {
  const request = await axios.get(`/services/approve_Tender/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_TenderOffer = async (id, user) => {
  const request = await axios.get(`/services/approve_TenderOffer/${id}/`, {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_collecting_invoice = async (id, user) => {
  const request = await axios.get(
    `/services/approve_collecting_invoice/${id}/`,
    {
      headers: { Authorization: `Bearer ${user.access}` },
    }
  );
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const approve_evaluation_upgrade = async (id, user) => {
  const request = await axios.get(
    `/services/approve_evaluation_upgrade/${id}/`,
    {
      headers: { Authorization: `Bearer ${user.access}` },
    }
  );
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};
