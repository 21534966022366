import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import rarrow from "../../imgs/right-arrow.png";
import date from "../../imgs/date.png";
import DatePicker from "react-datepicker";
import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
import Paginate from '../../component/Paginate'
import SearchBox from '../../component/SearchBox'
import {
  Col,
  Row,
  Card,
  InputGroup,
  Form,
  FormControl,
  ToggleButton,
} from "react-bootstrap";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import BestCard from "../../component/new/bestCard";
import "../../styles/tender_list.css";
import searchicon from "../../imgs/search.png";
import menu from "../../imgs/menu.png";
import { Link } from "react-router-dom";
import {listMedicalDevices} from '../../actions/serviceActions'


function ListSellMedicalDevicesScreen({ history }) {

    const [radioValue, setRadioValue] = useState('sell')

    const [radioValueN, setRadioValueN] = useState('new')

    const [linkTo, setLinkTo] = useState('OrderMoreDetails')

    const [link, setLink] = useState('')

    const dispatch = useDispatch()

    const medicalDeviceList = useSelector(state => state.medicalDeviceList)
    const {error, loading, devices, page, pages} = medicalDeviceList
    

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin


    let keyword = history.location.search

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        } else {
          dispatch(listMedicalDevices(keyword))          
        }     
      }
    
    }, [dispatch, history, userInfo, keyword])

    const submitHandler = (e) => {
        e.preventDefault()

        if(keyword){
            history.push(`/?keyword=${keyword}&page=1`)
        } else {
            history.push(history.push(history.location.pathname))
        }
    }



    return (
      <div className="tender_page">
        <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest = {'/collectServices'}
        style={"threetwo_section"}
      />
        <div className="box_serach">
          <Link to="/addRequestForMedicalDevices">
            <button className="add_btn">إضافة عرض</button>
          </Link>
        </div>
        <div className="tender_list" style={{ height: "800px" }}>
          <div className="heading text-center">قائمة العروض</div>
          <div className="scroll" style={{ marginTop: "55px" }}>
            <Row>
              <Col lg="3" className="search_bar_parent">
                <Card className="search_bar text-end">
                  <div className="input_group">
                  
                  <SearchBox link='/ListSellMedicalDevices'/>
                  </div>
                  <Card.Title className="mt-4 pt-3 search_bar_title">
                    نوع العرض
                  </Card.Title>
                  <Card.Text className="mt-2 pt-4 border-top search_bar_list_parent_2">
                    <ToggleButton
                      className="m-2 px-4"
                      key="1"
                      id="sell"
                      type="radio"
                      variant={
                        radioValue === "sell"
                          ? "outline-success"
                          : "outline-danger"
                      }
                      name="radio"
                      value="sell"
                      checked={radioValue === "sell"}
                      onChange={(e) => setRadioValue(e.target.value)}
                    >
                      بـيـع
                    </ToggleButton>
                    <ToggleButton
                      className="m-2 px-4"
                      key="2"
                      id="rent"
                      type="radio"
                      variant={
                        radioValue === "rent"
                          ? "outline-success"
                          : "outline-danger"
                      }
                      name="radio"
                      value="rent"
                      checked={radioValue === "rent"}
                      onChange={(e) => setRadioValue(e.target.value)}
                    >
                      تأجـيـر
                    </ToggleButton>
                  </Card.Text>
                  <Card.Text>
                  <ToggleButton
                      className="m-2 px-4"
                      key="3"
                      id="new"
                      type="radio"
                      variant={
                        radioValue === "new"
                          ? "outline-success"
                          : "outline-danger"
                      }
                      name="radio"
                      value="new"
                      checked={radioValue === "new"}
                      onChange={(e) => setRadioValue(e.target.value)}
                    >
                      جديد
                    </ToggleButton>
                    <ToggleButton
                      className="m-2 px-3"
                      key="4"
                      id="used"
                      type="radio"
                      variant={
                        radioValue === "used"
                          ? "outline-success"
                          : "outline-danger"
                      }
                      name="radio"
                      value="used"
                      checked={radioValue === "used"}
                      onChange={(e) => setRadioValue(e.target.value)}
                    >
                      مستخدم
                    </ToggleButton>
                  </Card.Text>
                </Card>
              </Col>
              <Col lg="9" className="mt-auto">
                {Object.keys(devices).length>0 ?
                  (devices.map((device) => (
                    <BestCard
                      title={device.title}
                      descreption={device.details}
                      price={device.price}
                      weight={device.weight}
                      radioValue={device.request_type}
                      bigImage={device.image}
                      link={`OrderMoreDetails/${device.id}`}
                    />
                  ))) : <div className="text-danger"><h2 className='text-center'> ! لايوجد نتائج </h2></div>
                }
              </Col>
            </Row>
            <Paginate page = {page} pages = {pages} keyword= {keyword} link="/ListSellMedicalDevices" />

          </div>
        </div>
      </div>
    )
}

export default ListSellMedicalDevicesScreen
