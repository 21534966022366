import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrow from "../../imgs/arrow.png";
import rarrow from "../../imgs/right-arrow.png";
import upload from "../../imgs/upload (1).png";
import "react-datepicker/dist/react-datepicker.css";
import complete from "../../imgs/check1.png";
import "../../styles/info-ownr.css";
import "../../styles/tender_washing.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import check from "../../imgs/check.png";
import {
  Col,
  Row,
  Card,
} from "react-bootstrap";
import ControlPanelHeader from "../../component/ControlPanelHeader";
import "../../styles/tender_list.css";
import menu from "../../imgs/menu.png";
import { Link } from "react-router-dom";

import {AddTender} from '../../actions/tenderActions'
import {useLocation} from "react-router-dom";


function TenderEmploymentServicesScreen({ history }) {

  const search = useLocation().search;
  const sector = new URLSearchParams(search).get('sector');

    const [photo, setPhoto] = useState(false)

    const [path, setPath] = useState('ارفق صورة')

    const [image, setImage] = useState(null)
    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)
    const [image3, setImage3] = useState(null)

    const [wrong, setWrong] = useState('')

    const [offerPeriod, setOfferPeriod] = useState('')

    const [offerType, setOfferType] = useState('')

    const [deliveryTerm, setDeliveryTerm] = useState('')

    const [employeeType, setEmployeeType] = useState('')

    const [employee_No, setEmployeeNum] = useState('')

    

    const [IsDisplayed, setIsDisplayed] = useState(false)

    const [palceHolder, setPalceHolder] = useState(true)

    const [quantity, setQauntity] = useState('')

    const [pieceType, setPieceType] = useState('')

    const [title, setTitle] = useState("")

    const [period, setPeriod] = useState("")

    const [photos, setPhotos] = useState([
        "https://via.placeholder.com/150",
        "https://via.placeholder.com/150",
        "https://via.placeholder.com/150",
        "https://via.placeholder.com/150",
      ])

    const [details, setDetails] = useState('')
    
    const [checkBox, setCheckBox] = useState(false)
    
    const [errors, setErrors] = useState({
        check_msg: " يرجى الموافقة على الأقرار من فضلك ",
        imgErrors: "يجب عليك تحميل 4 صور للمنتج",
        allFieldRequired: "الرجاء ملئ كل البيانات لإكمال التسجيل",

      })
    
    const [errorsFlags, setErrorsFlags] = useState([false, false, false])

    const [isValid, setIsValid] = useState(true)

    const dispatch = useDispatch()

    const tenderAdd
    = useSelector(state => state.tenderAdd)

    const {error, tenderData, success } = tenderAdd

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
      if(!userInfo) {
        history.push('/')
      } else {
        if (!userInfo.is_activated){
          history.push('/services')
        }      
      }
    
    }, [dispatch, history, userInfo])

    const changeSelect = (title) => {
        setIsDisplayed(false)
        setPalceHolder(false)
        setEmployeeType(title)
    };

    const selectClick = () => {
      setIsDisplayed(IsDisplayed === true ? false : true);
    };


    const checkboxHandler  = () => {
      setCheckBox(checkBox === true ? false : true);
    };


    const focusHandler = (e) => {
        e.target.parentNode.style.color = "#0fb0a9";
        e.target.style.color = "#0fb0a9";
        e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
        e.target.style.borderColor = "#0fb0a9";
    };

    const focusoutHandler = (e) => {
        e.target.parentNode.style.color = "";
        e.target.style.color = "";
        e.target.style.boxShadow = "";
        e.target.style.borderColor = "";
    };

    const getExtension = (filename) => {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    };

    const onUpload = (e) => {
        if (e.target.value) {
            const ext = getExtension(e.target.value);
            if (ext === "png" || ext === "jpg" || ext === "pdf") {
                setPhoto(true)
                setPath(e.target.files[0])
                setWrong(false)
            } else {
                setPhoto(false)
                setWrong(true)
            }
        }
    };

    const onUploadN = (e) => {   
        let flags = [false, false, false];  
        let valid = false   
        const filesNumber = Array.from(e.target.files).length
        if (filesNumber !== 4) {
            flags[1] = true;
            valid = false;
        } else {
            setImage(e.target.files[0])
            setImage1(e.target.files[1])
            setImage2(e.target.files[2])
            setImage3(e.target.files[3])
            flags[1] = false;
            valid = true;
        }
        setErrorsFlags(flags);
        setIsValid(valid)
    };
    
    const  handleDate = (d) => {
        const offset = d.getTimezoneOffset();
        let newDate = new Date(d.getTime() - offset * 60 * 1000);
        return newDate.toISOString().split("T")[0];
    };

    const checkStyle = checkBox ? { display: "block" } : {};
    
    const formValidation = () => {
        let flags =  [false, false, false]
        let valid = true
        const nodes = document.querySelectorAll("input");
        nodes.forEach((e) => {
            if(e.value === '') {
                flags[2] = true;
                valid = false;
                setErrorsFlags(flags);
                console.log("hello")
                return valid;
            }
        });

       /* if (valid === true) {
          if (!checkBox) {
            console.log("hi")
            flags[0] = true;
            valid = false;
            setErrorsFlags(flags);
            return valid;
          }
          else {
            flags[0] = false;
            valid = true;
            return valid;
          }

        } */
        
        return valid
        
    };

    const sendData = (e) => {
        e.preventDefault()
        const formvalid = formValidation();
        console.log(formvalid)
        console.log(errorsFlags)
        if (formvalid) {
        
            let data = new FormData();
            data.append("title", title);
            data.append("details", details);
            data.append("employee_num", employee_No);
            data.append("employee_type", employeeType);
            data.append("offer_period", offerPeriod);
            data.append("offer_type", offerType);
            data.append("period", period);
            data.append("image", image);
            data.append("image1", image1);
            data.append("image2", image2);
            data.append("image3", image3);
            data.append("service", 16);
      
            dispatch(AddTender(data))

            history.push('/confirmAddRequestForServices')

        }
    }

    const employeeTypes = [
      "طبيب ",
      "موظف اشعة",
      "ممرضة ",
      "موظف مختبر",
      "عامل تنظيف",
      "عاملة تنظيف",
      "طبيب اختصاصى",
      "طبيب عام",
      "بروفسور",
      "حارس امن",
      "موظف ادارى",
      "محاسب",
      "مدير مالى",
      "مدير مشاريع",
      "مدير شئوون الموظفين",
      "ادارى شئون موظفين",
      "سكرتاريه",
      "مدير ادارة",
      "رئيس تنفيذى",
      "مدير تحصيل",
      "موظف تحصيل",
      "منسق عمليات",
      "مدير غرفة عمليات",
      "ممرضة عمليات",
      "مدير طوارئ",
      "موظف طوارئ",
      "ممرضة طوارئ",
      "طبيب طوارئ",
      "اخصائى تحليل",
      "اخصائى اشعة",
    ];

    return (
        <div>
        <ControlPanelHeader
        exit1={true}
        notifi={true}
        msg={true}
        files={false}
        stat={false}
        msgDest={"/Messages"}
        notifidest={"/Notifications"}
        exitDest={"/services"}
        fileDest={"/FacilityFiles"}
        statDest = {'/collectServices'}
        style={"threetwo_section"}
      />
        <div className="tender_washing">
        
        <div style={{ height: "720px" }} className="tender_washing_content">
          <p className="title-info text-center">إضافة عرض مناقصة</p>

          <div className="input-group">
            <div className="right-data">
              <div className="input-lable">
                <label htmlFor="name">العنوان</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e)=> setTitle(e.target.value)}
                  placeholder="اكتب عنوان العرض"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>

              <Row>
                <Col lg="6" style={{ padding: "0 5px" }}>
                  {" "} 
                  <div className="input-lable narrow2 w-100">
                    <label htmlFor="date2"> مدة العقد</label>
                    <input
                      type="number"
                      id="offerPeriod"
                      name="offerPeriod"
                      value={offerPeriod}
                      onChange={(e)=>setOfferPeriod(e.target.value)}
                      placeholder=" شهر"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
                <Col lg="6" style={{ padding: "0 5px" }}>
                  {" "}
                  <div className="input-lable narrow2 w-100">
                    <label htmlFor="date2"> نوع العقد</label>
                    <input
                      type="text"
                      id="offerType"
                      name="offerType"
                      value={offerType}
                      onChange={(e)=>setOfferType(e.target.value)}
                      placeholder="نوع العقد"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                </Col>
              </Row>

              <div className="input-lable">
                  <label htmlFor="date2"> مدة العرض</label>
                  <select value={period}
                    type="text"
                    id="period"
                    name="period"
                    onChange={(e)=> setPeriod(e.target.value)}
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  >
                    <option hidden disabled selected>
                      أختار
                    </option>
                    <option value="7"> 7 أيام</option>
                    <option value="14"> 14 يوم</option>
                    <option value="21"> 21 يوم</option>
                    <option value="30"> 30 يوم</option>
                  </select>
                </div>
              <div className="input-lable textaria" style={{ height: "150px" }}>
                <label htmlFor="name"> تفاصيل العرض </label>
                <textarea
                  rows="5"
                  cols="50"
                  type="text"
                  id="details"
                  name="details"
                  value={details}
                  onChange={(e)=> setDetails(e.target.value)}
                  placeholder="اكتب تفاصيل العرض"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
              {errorsFlags[0] && (
                <div className="error">
                  <img src={error} />
                  {errors.check_msg}
                </div>
              )}
              {errorsFlags[1] && (
                    <div className="error">
                    <img src={error} />
                    {errors.imgErrors}
                    </div>
                )}
                {errorsFlags[2] && (
                    <div className="error">
                    <img src={error} />
                    {errors.allFieldRequired}
                    </div>
                )}
            </div>
            
            <div className="left-data">
              <div className="input-lable">
                <label htmlFor="name">عدد الموظفين</label>
                <input
                  type="number"
                  id="employee_No"
                  name="employee_No"
                  value={employee_No}
                  onChange={(e)=>setEmployeeNum(e.target.value)}
                  placeholder="اكتب عدد الموظفين"
                  onFocus={focusHandler}
                  onBlur={focusoutHandler}
                />
              </div>
              
              <div className="input-lable">
                <label htmlFor="name">الموظف تابع لأي قسم</label>
                <div className="select-city">
                  <div className="select-btn" onClick={selectClick}>
                    {palceHolder && (
                      <span style={{ color: "var(--lightgray-color)" }}>
                        اختر قسم الموظف التابع له
                      </span>
                    )}
                    <span>{employeeType}</span>
                    <img src={rarrow} />
                  </div>
                  {IsDisplayed && (
                    <div className="select-content">
                      {employeeTypes.map((option) => (
                        <div
                          className="select-item"
                          onClick={(e) => {
                            changeSelect(e.target.textContent);
                          }}
                        >
                          {" "}
                          {option}{" "}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>


              <div className="input-content">
                <p>رفع صور العرض</p>
                <label for="upload-photo">
                  <div className="input">
                    <img src={upload} alt="" />
                    <div>{path}</div>
                  </div>
                </label>
                <input
                  onChange={onUploadN}
                  type="file"
                  name="photo"
                  id="upload-photo"
                  multiple
                      />
                {photo && <img className="complete" src={complete} alt="" />}
                {wrong && <AiOutlineCloseCircle />}
              </div>
              <div className="images_box">
                {photos.map((value) => {
                  return <img className="images_box_img" alt="img" src={value} />;
                })}
              </div>
            </div>
          </div>
          <button className="enter_service mt-3" onClick={sendData}>
          <img className="icon" src={arrow} alt="" />
          اضف العرض
        </button>
        </div>
      </div>
      </div>
    )
}

export default TenderEmploymentServicesScreen
