import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import "./stat/info-ownr.css";
import arrow from "../imgs/arrow.png";
import complete from "../imgs/check1.png";
import upload from "../imgs/upload (1).png";
import rarrow from "../imgs/right-arrow1.png";
import error from "../imgs/381599_error_icon.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Modal from "./components/Modal/ConfirmationModal";
import { fetchOneEmployee } from "./api/Users/EmployeesApi";

function EditEmployee({ history }) {
  const [photo, setPhoto] = useState(false);
  const [path, setPath] = useState("ارفق صورة الهوية");
  const [wrong, setWrong] = useState(false);
  const [IsDisplayed1, setIsDisplayed1] = useState(false);
  const [palceHolder1, setPalceHolder1] = useState(true);
  const [IsDisplayed2, setIsDisplayed2] = useState(false);
  const [palceHolder2, setPalceHolder2] = useState(true);
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [phone_number, setPhone_No] = useState("");
  const [address, setAddress] = useState("");
  const [region, setRegion] = useState("");
  const [postal_code, setPostal_Code] = useState("");
  const [identity_number, setId] = useState("");
  const [email, setEmail] = useState("");
  const [post_box, setMailBox] = useState("");
  const [user_type, setUserType] = useState("admin");
  const [errors, setErrors] = useState({
    id_msg: " رقم الهوية  يجب ان يكون 10 ارقام ",
    email_msg1:
      "بريد الكترونى خاطئ example@bayan.com و غير مسموح ب gmail,yahoo,hotmail,...",
    email_msg2: "",
  });
  const [errorsFlags, setErrorsFlags] = useState([false, false]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [confirmationModel, setConfirmationModel] = useState(false);

  const [submitionError, setsubmitionError] = useState("");

  const params = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !params.id) {
      history.push("/");
    }
    fetchOneEmployee(params.id, userInfo).then((data) => {
      setName(data.name);
    });
  }, []);

  const changeSelect1 = (title) => {
    setIsDisplayed1(false);
    setPalceHolder1(false);
    setRegion(title);
  };

  const changeSelect2 = (title) => {
    setIsDisplayed2(false);
    setPalceHolder2(false);
    setCity(title);
  };

  const selectClick1 = () => {
    setIsDisplayed1(!IsDisplayed1);
  };
  const selectClick2 = () => {
    setIsDisplayed2(!IsDisplayed2);
  };

  const focusHandler = (e) => {
    e.target.parentNode.style.color = "#0fb0a9";
    e.target.style.color = "#0fb0a9";
    e.target.style.boxShadow = "#0fb0a9 0px 1px 4px";
    e.target.style.borderColor = "#0fb0a9";
  };

  const focusoutHandler = (e) => {
    e.target.parentNode.style.color = "";
    e.target.style.color = "";
    e.target.style.boxShadow = "";
    e.target.style.borderColor = "";
  };

  const onChange = (e) => {
    if (e.target.value) {
      const ext = this.getExtension(e.target.value);
      if (ext === "png" || ext === "jpg" || ext === "pdf") {
        setPhoto(true);
        setPath(e.target.value);
        setWrong(false);
      } else {
        setPhoto(false);
        setPath(e.target.value);
        setWrong(true);
      }
    }
  };

  const formValidation = () => {
    let isValid = true;
    const flags = [false, false];

    if (identity_number.trim().length != 10) {
      flags[0] = true;
      isValid = false;
    } else {
      flags[0] = false;
    }
    if (!email.endsWith("@bayan.com")) {
      flags[1] = true;
      isValid = false;
    } else {
      flags[1] = false;
    }
    setErrorsFlags(flags);
    return isValid;
  };

  const onSubmit = (e) => {
    setsubmitionError("");
    console.log(e);
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      setErrorsFlags([false, false]);
      const employee = {
        photo,
        city,
        name,
        phone_number,
        address,
        state: region,
        postal_code,
        identity_number,
        email,
        post_box,
        user_type,
        password,
        commercial_registration_num: "0000",
      };
      // endpointOfAddingEmployees(employee, userInfo)
      //   .then(({ data }) => {
      //     setConfirmationModel(true);
      //   })
      //   .catch((error) => {
      //     console.log(error.response.data);
      //     for (let key in error.response.data) {
      //       setsubmitionError(error.response.data[key][0]);
      //     }
      //   });
    }
  };

  const regions = [
    "الرياض",
    "مكة",
    "المدينة المنورة",
    "القصيم",
    "الشرقية",
    "عسير",
    "تبوك",
    "حائل",
    "الحدود الشمالية",
    "جازان",
    "نجران",
    "الباحة",
    "الجوف",
  ];

  const cities = [
    "مكة المكرمة",
    "جدة",
    "الطائف",
    "القنفذة",
    "الجموم",
    "رابغ",
    "الليث",
    "خليص",
    "أضم",
    "تربة",
    "الخرمة",
    "رنية",
    "الكامل",
    "العرضيات",
    "الموية",
    "ميسان",
    "بحرة",
  ];

  return (
    <AdminLayout>
      <div className="owner-info">
        <form onSubmit={onSubmit}>
          <div className="info-owner-content" style={{ height: "910px" }}>
            <p className="title-info">أدخال معلومات الموظف</p>
            <p className="title-hint">فضلا اكمل البيانات الخاصه بكم</p>
            {submitionError.length > 0 && (
              <Alert variant="primary">{submitionError}</Alert>
            )}
            <div className="input-group" style={{ width: "90%" }}>
              <div className="right-data">
                <div className="input-lable">
                  <label htmlFor="name">الاسم</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(value) => setName(value.target.value)}
                    placeholder="اكتب الاسم هنا"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable">
                  <label htmlFor="phone_number">رقم الهاتف</label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    value={phone_number}
                    onChange={(value) => setPhone_No(value.target.value)}
                    placeholder="ادخل الرقم هنا"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable">
                  <label htmlFor="address">العنوان</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(value) => setAddress(value.target.value)}
                    placeholder="اكتب العنوان هنا"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable">
                  <label>المنطقة</label>
                  <div className="select-city">
                    <div className="select-btn" onClick={selectClick1}>
                      {palceHolder1 && (
                        <span style={{ color: "var(--lightgray-color)" }}>
                          اختر المنطقة
                        </span>
                      )}
                      <span>{region}</span>
                      <img src={rarrow} />
                    </div>
                    {IsDisplayed1 && (
                      <div className="select-content">
                        {regions.map((option) => (
                          <div
                            data-name="region"
                            className="select-item"
                            onClick={(e) => {
                              changeSelect1(e.target.textContent);
                            }}
                          >
                            {" "}
                            {option}{" "}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-lable">
                  <label htmlFor="postal_code">الرمز البريدى</label>
                  <input
                    type="text"
                    id="postal_code"
                    name="postal_code"
                    value={postal_code}
                    onChange={(value) => setPostal_Code(value.target.value)}
                    placeholder="ادخل الرمز البريدى"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                <div className="input-lable">
                  <label htmlFor="password">كلمة المرور</label>
                  <input
                    type="text"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(value) => setPassword(value.target.value)}
                    placeholder="ادخل كلمة المرور"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
              </div>
              <div className="left-data">
                <div className="input-lable">
                  <label htmlFor="identity_number">رقم الهوية</label>
                  <input
                    type="text"
                    id="identity_number"
                    name="identity_number"
                    value={identity_number}
                    onChange={(value) => setId(value.target.value)}
                    placeholder="اكتب رقم الهوية"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                {errorsFlags[0] && (
                  <div className="error">
                    <img src={error} />
                    {errors.id_msg}
                  </div>
                )}
                <div className="input-lable">
                  <label htmlFor="name">الايميل الرسمي</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(value) => setEmail(value.target.value)}
                    placeholder="اكتب الايميل الرسمى"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
                {errorsFlags[1] && (
                  <div className="error">
                    <img src={error} />
                    {errors.email_msg1}
                  </div>
                )}

                <div className="two-inpt" style={{ display: "flex" }}>
                  <div className="input-lable">
                    <label htmlFor="post_box">رقم ص.ب</label>
                    <input
                      type="text"
                      id="post_box"
                      name="post_box"
                      value={post_box}
                      onChange={(value) => setMailBox(value.target.value)}
                      placeholder="اكتب رقم ص . ب"
                      onFocus={focusHandler}
                      onBlur={focusoutHandler}
                    />
                  </div>
                  <div className="input-lable">
                    <label>تحديد مهام الموظف</label>
                    <div
                      className="input-group"
                      style={{ margin: "0", display: "flex" }}
                    >
                      <div
                        style={{
                          flexGrow: "1",
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="admin"
                          type="radio"
                          name="user_type"
                          value="admin"
                          style={{ marginLeft: "10px" }}
                          onChange={(value) => setUserType(value.target.value)}
                          checked={user_type === "admin"}
                        />
                        <label htmlFor="admin" style={{ marginTop: "10px" }}>
                          مدير
                        </label>
                      </div>
                      <div
                        style={{
                          flexGrow: "3",
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="employee"
                          type="radio"
                          name="user_type"
                          value="employee"
                          style={{ marginLeft: "10px" }}
                          onChange={(value) => setUserType(value.target.value)}
                          checked={user_type === "employee"}
                        />
                        <label htmlFor="employee" style={{ marginTop: "10px" }}>
                          موظف
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-lable">
                  <label htmlFor="name">المدينة</label>
                  <div className="select-city">
                    <div className="select-btn" onClick={selectClick2}>
                      {palceHolder2 && (
                        <span style={{ color: "var(--lightgray-color)" }}>
                          اختر المدينة
                        </span>
                      )}
                      <span>{city}</span>
                      <img src={rarrow} />
                    </div>
                    {IsDisplayed2 && (
                      <div className="select-content">
                        {cities.map((option) => (
                          <div
                            data-name="city"
                            className="select-item"
                            onClick={(e) => {
                              changeSelect2(e.target.textContent);
                            }}
                          >
                            {" "}
                            {option}{" "}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="input-content input-lable"
                  style={{ marginTop: "40px" }}
                >
                  <p style={{ marginTop: "-20px" }}>صورة الهوية</p>
                  <label for="upload-photo" className="wid">
                    {" "}
                    <div className="input">
                      <img src={upload} alt="" />
                      <div>{path}</div>
                    </div>{" "}
                  </label>
                  <input
                    onChange={onChange}
                    type="file"
                    name="photo"
                    id="upload-photo"
                  />
                  {photo && <img className="complete" src={complete} alt="" />}
                  {wrong && (
                    <AiOutlineCloseCircle
                      style={{
                        color: "red",
                        position: "absolute",
                        left: "-20px",
                        top: "30px",
                        width: "36px",
                        height: "36px",
                      }}
                    />
                  )}
                </div>
                <div className="input-lable" style={{ marginTop: "-15px" }}>
                  <label htmlFor="password">تأكيد كلمة المرور</label>
                  <input
                    type="text"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(value) => setConfirmPassword(value.target.value)}
                    placeholder="ادخل كلمة المرور"
                    onFocus={focusHandler}
                    onBlur={focusoutHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="group-btn" style={{ justifyContent: "center" }}>
            <button
              style={{ margin: "0 auto" }}
              type="submit"
              className="nextBtn"
            >
              <img src={arrow} alt="" />
              اضف موظف
            </button>
          </div>
        </form>
      </div>
      {confirmationModel && (
        <Modal
          title={"اضافة"}
          visibilty={true}
          body={"تم تعديل الموظف بنجاح!"}
          onClosing={() => setConfirmationModel(false)}
        />
      )}
    </AdminLayout>
  );
}

export default EditEmployee;
