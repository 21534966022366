import axios from "axios";

export const getPrices = async (user) => {
  const request = await axios.get("/management/subscription", {
    headers: { Authorization: `Bearer ${user.access}` },
  });
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};

export const setPrices = async (newPrices, user) => {
  const request = await axios.put(
    `/management/subscription/${newPrices.id}`,
    newPrices,
    {
      headers: { Authorization: `Bearer ${user.access}` },
    }
  );
  if (request.status === 200 || 201) {
    return request.data;
  } else {
    return new Error("ليس لديك الصلاحيات");
  }
};
