import {
    MEDICAL_TEAM_LIST_FOR_ADMIN_REQUEST,
    MEDICAL_TEAM_LIST_FOR_ADMIN_SUCCESS,
    MEDICAL_TEAM_LIST_FOR_ADMIN_FAIL,

    MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_REQUEST,
    MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_SUCCESS,
    MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_FAIL,

    FACILITY_LIST_FOR_ADMIN_REQUEST,
    FACILITY_LIST_FOR_ADMIN_SUCCESS,
    FACILITY_LIST_FOR_ADMIN_FAIL,

    FACILITY_DETAILS_FOR_ADMIN_REQUEST,
    FACILITY_DETAILS_FOR_ADMIN_SUCCESS,
    FACILITY_DETAILS_FOR_ADMIN_FAIL,

    FACILITY_UPDATE_FOR_ADMIN_REQUEST,
    FACILITY_UPDATE_FOR_ADMIN_SUCCESS,
    FACILITY_UPDATE_FOR_ADMIN_FAIL,

    INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_REQUEST,
    INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_SUCCESS,
    INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_FAIL,

    OFFER_DETAILS_FOR_ADMIN_REQUEST,
    OFFER_DETAILS_FOR_ADMIN_SUCCESS,
    OFFER_DETAILS_FOR_ADMIN_FAIL,

    EVALUATION_DETAILS_FOR_ADMIN_REQUEST,
    EVALUATION_DETAILS_FOR_ADMIN_SUCCESS,
    EVALUATION_DETAILS_FOR_ADMIN_FAIL,

    EVALUATION_LIST_FOR_ADMIN_REQUEST,
    EVALUATION_LIST_FOR_ADMIN_SUCCESS,
    EVALUATION_LIST_FOR_ADMIN_FAIL,


} from '../constants/adminConstants'

export const medicalTeamAllListForAdminReducer = (state = { medicalTeam: [] }, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_LIST_FOR_ADMIN_REQUEST:
            return { loading: true, medicalTeam: [] }
        
        case MEDICAL_TEAM_LIST_FOR_ADMIN_SUCCESS:
            return { 
                loading: false,
                medicalTeam: action.payload.medicalTeam,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case MEDICAL_TEAM_LIST_FOR_ADMIN_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const medicalTeamInsurrenceRequestForAdminReducer = (state = { insurrenceRequest: [] }, action) => {
    switch (action.type) {

        case MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_REQUEST:
            return { loading: true, insurrenceRequest: [] }
        
        case MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_SUCCESS:
            return { 
                loading: false,
                insurrenceRequest: action.payload.insurrenceRequest,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case MEDICAL_TEAM_LIST_INSURRENCE_REQUEST_FOR_ADMIN_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const facilityListForAdminReducer = (state = { facilities: [] }, action) => {
    switch (action.type) {

        case FACILITY_LIST_FOR_ADMIN_REQUEST:
            return { loading: true, facilities: [] }
        
        case FACILITY_LIST_FOR_ADMIN_SUCCESS:
            return { 
                loading: false,
                facilities: action.payload.facilities,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case FACILITY_LIST_FOR_ADMIN_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const facilityDetailsForAdminReducer = ( state = {loading:true, facilityInfo:{}}, action) => {
    switch(action.type) {
        case FACILITY_DETAILS_FOR_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FACILITY_DETAILS_FOR_ADMIN_SUCCESS:
            return {
                loading: false,
                success: true,
                facilityInfo: action.payload
            }

        case FACILITY_DETAILS_FOR_ADMIN_FAIL:
            return {
                loading: false,
                error: action.payload
            }
            
        default:
            return state
    }
}

export const facilityUpdateForAdminReducer = (state = { facility: {} }, action) => {
    switch (action.type) {

        case FACILITY_UPDATE_FOR_ADMIN_REQUEST:
            return { loading: true}
        
        case FACILITY_UPDATE_FOR_ADMIN_SUCCESS:
            return { loading: false, success: true, facility:action.payload }
        
        case FACILITY_UPDATE_FOR_ADMIN_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const insurrenceRequestUpdateForAdminReducer = (state = { insurrenceRequest: {} }, action) => {
    switch (action.type) {

        case INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_REQUEST:
            return { loading: true}
        
        case INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_SUCCESS:
            return { loading: false, success: true, insurrenceRequest:action.payload }
        
        case INSURRENCE_REQUEST_UPDATE_FOR_ADMIN_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

export const offerDetailsForAdminReducer = ( state = {loading:true, offer:{}}, action) => {
    switch(action.type) {
        case OFFER_DETAILS_FOR_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case OFFER_DETAILS_FOR_ADMIN_SUCCESS:
            return {
                loading: false,
                success: true,
                offer: action.payload
            }

        case OFFER_DETAILS_FOR_ADMIN_FAIL:
            return {
                loading: false,
                error: action.payload
            }
            
        default:
            return state
    }
}

export const evaluationDetailsForAdminReducer = ( state = {loading:true, evaluation:{}}, action) => {
    switch(action.type) {
        case EVALUATION_DETAILS_FOR_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case EVALUATION_DETAILS_FOR_ADMIN_SUCCESS:
            return {
                loading: false,
                success: true,
                evaluation: action.payload
            }

        case EVALUATION_DETAILS_FOR_ADMIN_FAIL:
            return {
                loading: false,
                error: action.payload
            }
            
        default:
            return state
    }
}

export const evaluationAllListForAdminReducer = (state = { evaluations: [] }, action) => {
    switch (action.type) {

        case EVALUATION_LIST_FOR_ADMIN_REQUEST:
            return { loading: true, medicalTeam: [] }
        
        case EVALUATION_LIST_FOR_ADMIN_SUCCESS:
            return { 
                loading: false,
                evaluations: action.payload.evaluations,
                page: action.payload.page,
                pages: action.payload.pages
            }
        
        case EVALUATION_LIST_FOR_ADMIN_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}

