import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import { Row, Col, Card, Table, Form, Button, Modal } from "react-bootstrap";
import "../styles/TableNewDesign.css";
import Paginate from "../component/Paginate";
import { Link } from "react-router-dom";
import Message from "../component/Message";
import Loader from "../component/Loader";
import {
  deleteMedicalTeam,
  updateMedicalTeam,
} from "../actions/serviceActions";
import { fetchInvoices, deleteInvoice } from "./api/invoicesApi";

function AdminNewBillsSubscription({ history }) {
  const SERVICE_FILTER_VALUE = "الشبكات";

  const [selectValue, setSelectValue] = useState("");
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const facilityListForAdmin = useSelector(
    (state) => state.facilityListForAdmin
  );
  const { loading, error, facilities, page, pages } = facilityListForAdmin;

  const medicalTeamDelete = useSelector((state) => state.medicalTeamDelete);
  const {
    error: errorDelete,
    loading: loadingDelete,
    success: successDelete,
  } = medicalTeamDelete;

  const medicalTeamUpdate = useSelector((state) => state.medicalTeamUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = medicalTeamUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let keyword = history.location.search;

  const [bills, setBills] = useState([]);

  useEffect(() => {
    fetchInvoices(userInfo).then((data) => setBills(data));
  }, []);

  // useEffect(() => {
  //   if (userInfo?.user?.startsWith("50") || userInfo?.user_type == 'employee') {
  //     dispatch(listAllFacilitiesForAdmin(keyword));
  //   } else {
  //     history.push("/");
  //   }
  // }, [dispatch, history, userInfo, keyword, successDelete, successUpdate]);

  const more = {
    title: "الاجراءات",
    actions: {
      تفعيل: "approve",
      طباعة: "/admin/print-facility",
      تعديل: "/admin/update-facility-info",
      حذف: "delete",
    },
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  const handleDelete = (id) => {
    deleteInvoice(id, userInfo).then(() =>
      setBills(bills.filter((b) => b.id != id))
    );
    setShow(false);
  };

  const handleUpdate = (id) => {
    let data = new FormData();
    data.append("approved", true);
    dispatch(updateMedicalTeam(data, id));
  };

  const showModel = () => {
    let teamId = selectValue.split("/")[1];
    return (
      <Modal
        style={{ direction: "rtl" }}
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "var(--main-color)",
            justifyContent: "center",
          }}
        >
          <Modal.Title className="text-white fw-bold">معلومة هامة</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h4">يرجى تأكيد الحذف</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-red"
            onClick={() => setShow(false)}
          >
            <span className="h4">إلغاء</span>
          </Button>
          <Button
            variant="success"
            className="btn-main px-5"
            onClick={() => handleDelete(teamId)}
          >
            <span className="h4">تأكيد</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <AdminLayout>
      <Row>
        <Col xl={12} md={12}>
          {errorUpdate && <Message varient="danger">{errorUpdate}</Message>}
          {errorDelete && <Message varient="danger">{errorDelete}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message varient="danger">{error}</Message>
          ) : (
            <Card className="code-table">
              <Card.Header>
                <Card.Title as="h5">قائمة الفواتير الجديدة</Card.Title>
              </Card.Header>
              <Card.Body className="pb-0">
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>كود المنشأة</th>
                      <th>نوع القطاع</th>
                      <th>الخدمة</th>
                      <th>يحتاج للشحن </th>
                      <th>مبلغ الفاتورة</th>
                      <th>نوع الفاتورة</th>
                      <th>نوع الدفع</th>
                      <th>الحالة</th>
                      <th>اجراءات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bills
                      .filter((b) => !b.service )
                      .map((obj) => (
                        <tr key={obj.id}>
                          <td>
                            <p className="mb-1">{obj.facility}</p>
                          </td>
                          <td>
                            <p className="mb-1">{obj.sector}</p>
                          </td>
                          <td>
                            <p className="mb-1">{obj.service}</p>
                          </td>
                          <td>
                            <p className="mb-1">
                              {obj.shipping_required ? "نعم" : "لا"}
                            </p>
                          </td>
                          <td>
                            <p className="mb-1">{obj.total}</p>
                          </td>
                          <td>
                            <p className="mb-1">أشتراك</p>
                          </td>
                          <td>
                            <p className="mb-1">
                              {obj.direct_payment ? "مباشر" : "اجل"}
                            </p>
                          </td>

                          <td>
                            <p>
                              {obj.bayan_interest > 0
                                ? "عمولة بيان"
                                : "فاتور عميل"}
                            </p>
                          </td>

                          {more && !obj.is_deleted ? (
                            <td>
                              <Row className="m-2 w-100">
                                <Form.Select
                                  as="select"
                                  onChange={handleChange}
                                  className="text-center px-1 pe-2"
                                >
                                  <option>أختر</option>
                                  <option value={`/invoice-all-type/${obj.id}`}>
                                    طباعة
                                  </option>
                                </Form.Select>
                                {selectValue.includes(
                                  "/editMedicalOrTechnicalTeam"
                                ) ? (
                                  <Button
                                    onClick={() => setShow(true)}
                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                  >
                                    تنفيذ
                                  </Button>
                                ) : selectValue.includes(
                                    "/invoice-all-type/"
                                  ) ? (
                                  <Link
                                    to={selectValue}
                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                  >
                                    تنفيذ
                                  </Link>
                                ) : selectValue.includes("delete") ? (
                                  <Button
                                    onClick={() => setShow(true)}
                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                  >
                                    تنفيذ
                                  </Button>
                                ) : selectValue.includes("approve") ? (
                                  <Button
                                    onClick={() => handleUpdate(obj.id)}
                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                  >
                                    تنفيذ
                                  </Button>
                                ) : selectValue.includes("update") ? (
                                  <Button
                                    onClick={() => handleUpdate(obj.id)}
                                    className="btn-outline-main btn-outline-info btn mt-1 mx-0 w-100"
                                  >
                                    تنفيذ
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <div>{showModel()}</div>
      <Paginate
        page={page}
        pages={pages}
        keyword={keyword}
        link="/comparingMedicalMistakeWork"
      />
    </AdminLayout>
  );
}

export default AdminNewBillsSubscription;
