import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layouts/AdminLayout";
import "../styles/TableNewDesign.css";
import "./stat/sell_medicalDevice.css";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import CountUp from "react-countup";

function Sell_MedicalDevices_Stat(props) {
  const [data1, setData1] = useState([
    { name: "سنوي", value: 40000, fill: "#0fb0a9" },
    { name: "شهري", value: 15000, fill: "#e64f39" },
  ]);
  const [data2, setData2] = useState([
    { name: "سنوي", value: 50000, fill: "#0fb0a9" },
    { name: "شهري", value: 22000, fill: "#e64f39" },
  ]);
  const [data3, setData3] = useState([
    { name: "سنوي", value: 33000, fill: "#0fb0a9" },
    { name: "شهري", value: 12500, fill: "#e64f39" },
  ]);
  const [data4, setData4] = useState([
    { name: "سنوي", value: 60000, fill: "#0fb0a9" },
    { name: "شهري", value: 23600, fill: "#e64f39" },
  ]);

  const [state, setState] = useState({
    activeIndex: 0,
  });
  const [state2, setState2] = useState({
    activeIndex2: 1,
  });
  const [state3, setState3] = useState({
    activeIndex3: 0,
  });
  const [state4, setState4] = useState({
    activeIndex4: 1,
  });

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      name,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={`${fill}`}
          fontSize="25px"
        >{` ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill={`${fill}`}
          fontWeight="bold"
        >
          {` ${name}`}
        </text>
      </g>
    );
  };

  const onPieEnter1 = (_, index) => {
    setState({
      activeIndex: index,
    });
  };

  const onPieEnter2 = (_, index) => {
    setState2({
      activeIndex2: index,
    });
  };

  const onPieEnter3 = (_, index) => {
    setState3({
      activeIndex3: index,
    });
  };

  const onPieEnter4 = (_, index) => {
    setState4({
      activeIndex4: index,
    });
  };

  return (
    <div className="sales_stat">
      <div className="header">تأجير أوبيع الأجهزة الطبية</div>
      <div className="chart">
        <div className="stat_item">
          <div className="pie_chart">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={state.activeIndex}
                  activeShape={renderActiveShape}
                  data={data1}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  dataKey="value"
                  onMouseEnter={onPieEnter1}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="stat_info">
            <div className="type monthly">
              <div className="title">شهري</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data1[0].value}
                  duration={2}
                  separator=","
                />
              </div>
            </div>
            <div className="type ">
              <div className="title">سنوي</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data1[1].value}
                  duration={2}
                  separator=","
                />
              </div>
            </div>
          </div>
          <div className="info_item">
            <p style={{ fontSize: "14px" }} className="title">
              عدد الأجهزة الطبية الغير مكتمل طلبها شهري وسنوي
            </p>
            <span className="list"></span>
          </div>
        </div>
        <div className="stat_item">
          <div className="pie_chart">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={state2.activeIndex2}
                  activeShape={renderActiveShape}
                  data={data2}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  dataKey="value"
                  onMouseEnter={onPieEnter2}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="stat_info">
            <div className="type monthly">
              <div className="title">شهري</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data2[0].value}
                  duration={2}
                  separator=","
                />
              </div>
            </div>
            <div className="type">
              <div className="title">سنوي</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data2[1].value}
                  duration={2}
                  separator=","
                />
              </div>
            </div>
          </div>
          <div className="info_item">
            <p className="title">عدد الأجهزة الطبية المرفوضة شهري وسنوي</p>
            <span className="list"></span>
          </div>
        </div>
        <div className="stat_item">
          <div className="pie_chart">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={state3.activeIndex3}
                  activeShape={renderActiveShape}
                  data={data3}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  dataKey="value"
                  onMouseEnter={onPieEnter3}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="stat_info">
            <div className="type monthly">
              <div className="title">شهري</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data3[0].value}
                  duration={2}
                  separator=","
                />
              </div>
            </div>
            <div className="type">
              <div className="title">سنوي</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data3[1].value}
                  duration={2}
                  separator=","
                />
              </div>
            </div>
          </div>
          <div className="info_item">
            <p className="title">عدد الاجهزة الطبية المستأجرة شهري وسنوي</p>
            <span className="list"></span>
          </div>
        </div>
        <div className="stat_item">
          <div className="pie_chart">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={state4.activeIndex4}
                  activeShape={renderActiveShape}
                  data={data4}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  dataKey="value"
                  onMouseEnter={onPieEnter4}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="stat_info">
            <div className="type monthly">
              <div className="title">شهري</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data4[0].value}
                  duration={2}
                  separator=","
                />
              </div>
            </div>
            <div className="type">
              <div className="title">سنوي</div>
              <div className="total_number">
                <CountUp
                  start={0}
                  end={data4[1].value}
                  duration={2}
                  separator=","
                />
              </div>
            </div>
          </div>
          <div className="info_item">
            <p className="title">عدد الأجهزة الطبية المباعة شهري وسنوي</p>
            <span className="list"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

function Rentalmedicaldevices({ history }) {
  return (
    <AdminLayout>
      <div className="manger_control" style={{ height: "100%", width: "100%" }}>
        <div className="charts">
          <div className="stat">
            <div style={{ width: "300px" }} className="heading text-center">
              الأحصائيات
            </div>

            <Sell_MedicalDevices_Stat />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default Rentalmedicaldevices;
